import { Injectable } from '@angular/core';
import { IconSettingsService } from '@progress/kendo-angular-icons';
import * as allSVGIcons from '@progress/kendo-svg-icons';
import { SVGIcon } from '@progress/kendo-svg-icons';

@Injectable()
/**
 * Used to override icons the Kendo Default theme applies
 */
export class KendoIconOverrideService extends IconSettingsService {
  private svgDictionary: { [key: string]: SVGIcon } = {
    // For drop downs and numeric rocker controls
    'caret-alt-down': allSVGIcons.chevronDownIcon,
    'caret-alt-up': allSVGIcons.chevronUpIcon,

    // For multiselect "chips"
    'x-circle': allSVGIcons.xIcon,
  };

  public getSvgIcon(svgIconName: string): SVGIcon {
    return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
  }
}
