import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { LabelsModule } from 'app/modules/labels/labels.module';

import { CapsaDialogsModule } from 'app/modules/capsa-dialogs/capsa-dialogs.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { ListMessagesComponent } from './common/list-messages/list-messages.component';
import { MessageEditDialogComponent } from './common/message-edit-dialog/message-edit-dialog.component';
import { MessageTemplateEditDialogComponent } from './common/message-template-edit-dialog/message-template-edit-dialog.component';
import { ListMessagesFromCartComponent } from './pages/list-messages-from-cart/list-messages-from-cart.component';
import { ListMessagesToCartComponent } from './pages/list-messages-to-cart/list-messages-to-cart.component';
import { ListMessagesToUserComponent } from './pages/list-messages-to-user/list-messages-to-user.component';
import { MessageTemplatesComponent } from './pages/message-templates/message-templates.component';
import { NewMessageComponent } from './pages/new-message/new-message.component';
import { SubjectPresetManageComponent } from './pages/subject-preset-manage/subject-preset-manage.component';

@NgModule({
  declarations: [
    NewMessageComponent,
    ListMessagesComponent,
    ListMessagesFromCartComponent,
    ListMessagesToCartComponent,
    MessageEditDialogComponent,
    ListMessagesToUserComponent,
    MessageTemplatesComponent,
    MessageTemplateEditDialogComponent,
    SubjectPresetManageComponent,
  ],
  exports: [
    NewMessageComponent,
    ListMessagesFromCartComponent,
    ListMessagesToCartComponent,
    ListMessagesToUserComponent,
    MessageTemplatesComponent,
  ],
  imports: [
    ApiModule,
    ButtonsModule,
    CapsaDropdownsModule,
    CommonModule,
    DateInputsModule,
    DialogsModule,
    FormsModule,
    GridModule,
    InputsModule,
    LabelsModule,
    LoadingIndicatorModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CapsaDialogsModule,
  ],
})
export class MessagingModule {}
