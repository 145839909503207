import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Map as ImmutableMap } from 'immutable';

@Injectable()
export abstract class AbstractCacheService<TCacheData> {
  private initializedBehSub = new BehaviorSubject<TCacheData>(null);
  private initializingBehSub = new BehaviorSubject<boolean>(false);

  protected cacheData = ImmutableMap<
    keyof TCacheData,
    TCacheData[keyof TCacheData]
  >();

  public initializing$ = this.initializingBehSub.asObservable();
  public initialized$ = this.initializedBehSub.asObservable();

  private hasInitialized = false;

  constructor() {}

  protected abstract loadApiData(): Observable<TCacheData>;

  public init() {
    if (this.hasInitialized) {
      // don't allow double inits.
      return;
    }
    this.initializingBehSub.next(true);

    this.loadApiData()
      .pipe(
        finalize(() => {
          this.initializingBehSub.next(false);
        })
      )
      .subscribe(
        (dataToCache) => {
          this.cacheData = ImmutableMap<
            keyof TCacheData,
            TCacheData[keyof TCacheData]
          >(dataToCache as any);
          this.initializedBehSub.next(dataToCache);
          this.hasInitialized = true;
        },
        (err) => {
          this.initializedBehSub.error(err);
        }
      );
  }

  protected transform(
    transformation: (
      currentCache: ImmutableMap<keyof TCacheData, TCacheData[keyof TCacheData]>
    ) => ImmutableMap<keyof TCacheData, TCacheData[keyof TCacheData]>
  ) {
    const updatedCache = transformation(this.cacheData);
    this.cacheData = updatedCache;

    this.initializedBehSub.next(this.cacheData.toObject() as any);
  }
}
