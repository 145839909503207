import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard  {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const data = route.data;
    if (data.permsRequired && data.permsRequired.length > 0) {
      return this.permissionService.hasAny$(data.permsRequired).pipe(
        tap((hasPerms) => {
          if (!hasPerms) {
            // If we don't do this, navigation will go "home", which is essentially a blank page (not the dashboard)
            this.router.navigate(['/dashboard']);
          }
        })
      );
    }

    return true;
  }
}
