// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faded-text-wrapper {
  text-align: center;
  font-size: 24px;
  color: #b5b5b5;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/labels/faded-text/faded-text.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".faded-text-wrapper {\n  text-align: center;\n  font-size: 24px;\n  color: #b5b5b5;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
