import { Component, Inject } from '@angular/core';
import { CartResponse } from '@capsa/api';

@Component({
  selector: 'app-trio-cart-management-debug-tab',
  templateUrl: './trio-cart-management-debug-tab.component.html',
  styleUrls: ['./trio-cart-management-debug-tab.component.scss'],
})
export class TrioCartManagementDebugTabComponent {
  constructor(
    @Inject('device')
    public device: CartResponse
  ) {}
}
// https://capsahealthcare.atlassian.net/browse/NS-1973
// to-do: review justifying continued existence of the trio-cart-management-debug-tab
// middleman, since there's no other types of devices on nsight that have debug tabs
