// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.row {
  min-height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/capsa-dropdowns-module/components/eof-drop-downs/eof-drop-downs.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EAIE,gBAAA;AAFF","sourcesContent":[":host {\n  display: block;\n}\n\n.row {\n  // reserves space on screen for the drop downs, while they're loading\n  // prevents jumping on the screen (e.g. first the row is short, then\n  // things load and it changes size, causing everything below it to shift down)\n  min-height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
