import { DeviceTypeIds, ProductLineIds } from '@capsa/api';
import { State } from '@progress/kendo-data-query';
import { Constants } from './constants';

export class Utils {
  static getLanguageToSet(): string {
    return (
      this.getCookie(Constants.CookieNames.preferredLanguage) ||
      Constants.defaultLanguage
    );
  }

  static getProductLine(deviceTypeId: DeviceTypeIds) {
    switch (deviceTypeId) {
      case DeviceTypeIds.Avalo:
        return ProductLineIds.Avalo;
      case DeviceTypeIds.CareLink_2:
      case DeviceTypeIds.CareLink_M38e:
      case DeviceTypeIds.CareLink_M48:
        return ProductLineIds.CareLink;
      default:
        throw new Error('Device Type not supported');
    }
  }

  // Using this for the app.module where we can't use a cookie service
  // source: https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
  private static getCookie(name): string {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  /**
   * We don't want language names translated, so no tags for them
   * Each native will be able to recognize their language from a list.
   * This list should match the text generated by the Trio cart (see CAC-418).
   */
  static getLanguageFromId(langId: string) {
    const cultureId = langId.toLowerCase();
    switch (cultureId) {
      case 'en-us':
        return 'English';
      case 'de-de':
        return 'Deutsch';
      case 'es-es':
        return 'español de España';
      case 'fr-ca':
        return 'français canadien';
      case 'fr-fr':
        return 'français';
      case 'nl-nl':
        return 'Nederlands';
      default:
        return 'NEW_LANGUAGE_NOT_IMPLEMENTED';
    }
  }

  static updateSideBarWidthForLanguage(lang: string) {
    lang = lang.toLowerCase();
    let sideBarWidth: number;
    switch (lang) {
      case 'de-de':
        sideBarWidth = 275;
        break;
      case 'es-es':
        sideBarWidth = 295;
        break;
      case 'fr-ca':
        sideBarWidth = 225;
        break;
      case 'fr-fr':
        sideBarWidth = 235;
        break;
      case 'nl-nl':
        sideBarWidth = 245;
        break;
      default:
        sideBarWidth = 215;
        break;
    }
    document.documentElement.style.setProperty(
      '--sidebarWidth',
      sideBarWidth + 'px'
    );
  }

  static setAppInitFeatureFlags() {
    const ffShortSession = localStorage.getItem('ff_short_session');
    const ffLongSession = localStorage.getItem('ff_long_session');
    const minutesInDay = 1440;
    if (ffShortSession !== null) {
      Constants.sessionTimeoutMinutes = 2;
      // eslint-disable-next-line no-console
      console.warn(
        '*** FEATURE FLAG ENABLED *** Short session feature flag enabled | session length is: ',
        Constants.sessionTimeoutMinutes,
        'minutes ***'
      );
    } else if (ffLongSession !== null) {
      Constants.sessionTimeoutMinutes = minutesInDay * 5; // 5 days
      // eslint-disable-next-line no-console
      console.warn(
        '*** FEATURE FLAG ENABLED *** LONG session feature flag enabled | session length is: ',
        Constants.sessionTimeoutMinutes,
        'minutes ***'
      );
    }
  }

  static getDefaultState(pageSize: number): State {
    return {
      skip: 0,
      take: pageSize,

      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
    };
  }
}
