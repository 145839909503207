import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceTypeIds } from '@capsa/api';
import { Subject, Subscription } from 'rxjs';

import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';

@Component({
  selector: 'app-lighting',
  templateUrl: './lighting.component.html',
  styleUrls: ['./lighting.component.scss'],
})
export class LightingComponent implements OnInit, OnDestroy {
  public facilityId: number;
  public deviceTypeId: DeviceTypeIds;
  public deviceTypeIdsType = DeviceTypeIds;

  private themeNeedsRefreshSubj = new Subject<void>();
  public themeNeedsRefresh$ = this.themeNeedsRefreshSubj.asObservable();

  private subs: Subscription = new Subscription();

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Facilities];

  constructor(private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.permissionService.permissionsUpdated$.subscribe(() => {
      if (this.permissionService.hasAny(this.pagePerms)) {
        this.canViewPage = true;
      } else {
        this.canViewPage = false;
      }
    });

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public facilityIdChanged(newValue: number) {
    this.facilityId = newValue;

    setTimeout(() => {
      // This delays the observable emission till after the facilityId value has propagated to the
      // lighting themes component, otherwise it will use the pervious facilityId value to get
      // the latest theme
      this.themeNeedsRefreshSubj.next();
    }, 0);
  }

  public onDeviceTypeChanged(newValue: DeviceTypeIds) {
    this.deviceTypeId = newValue;
    if (newValue === DeviceTypeIds.CareLink_2) {
      // only "Trio" components need this observable update
      this.themeNeedsRefreshSubj.next();
    }
  }
}
