import { Injectable } from '@angular/core';
import { AuthService } from '@capsa/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/common/constants';
import * as moment from 'moment';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, map, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private isInitialized = false;

  private readonly throttleActivityTimeMs = 1000 * 10; // Slow down how often the user activity observable emits
  private readonly timeToCancelSignOutMs = 1000 * 60; // Period of time "Extend Session" dialog is displayed for
  private readonly sessionTimeoutMs =
    Constants.oneMinuteInMs * Constants.sessionTimeoutMinutes;

  private readonly showModalBehSubj = new BehaviorSubject<boolean>(false);
  public readonly showModal$ = this.showModalBehSubj.asObservable();

  private readonly userActivityDetectedSubj = new Subject<void>();

  private gracePeriodTimerSub: Subscription;
  private secondsUpdaterSub: Subscription;

  private readonly secondsRemainingSub = new BehaviorSubject<number>(
    this.timeToCancelSignOutMs / 1000
  );
  public readonly secondsRemaining$ = this.secondsRemainingSub.asObservable();

  private readonly throttledUserActivity$ = this.userActivityDetectedSubj
    .asObservable()
    .pipe(throttleTime(this.throttleActivityTimeMs));

  // Emits once no activity is detected for given amount of time
  private readonly noUserActivityDetected$ = this.throttledUserActivity$.pipe(
    debounceTime(
      // Will only allow the last emit to go through if no emits have occured in X milliseconds
      this.sessionTimeoutMs - this.timeToCancelSignOutMs
    )
  );

  constructor(
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  /**
   * Starts the initial countdown. Will only run once per app lifecycle even if called multiple times.
   */
  public Init() {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;
    this.registerUserActivityListeners();
    this.startDetectingUserInactivity();
    this.startCheckingAuthCookieExpiration();
  }

  private startCheckingAuthCookieExpiration() {
    this.throttledUserActivity$.subscribe(() => {
      this.authService.setAuthCookie();
    });
  }

  private startDetectingUserInactivity() {
    const sub = this.noUserActivityDetected$.subscribe(() => {
      sub.unsubscribe();
      this.handleStartGracePeriod();
    });

    // Trigger initial timer
    this.userActivityDetectedSubj.next();
  }

  private handleStartGracePeriod() {
    if (!this.authService.hasAccessTokenCookie()) {
      // Cookie has expired or is not present
      // Abort grace period and send user to login page
      this.authService.logout();
    }

    const targetLogoutTime = moment(Date.now()).add(
      this.timeToCancelSignOutMs,
      'milliseconds'
    );

    const sessionEndingText = this.translateService.instant('SESSION_ENDING');
    this.secondsUpdaterSub = interval(250).subscribe(() => {
      let secondsRemain = moment(targetLogoutTime).diff(Date.now(), 'seconds');

      if (secondsRemain < 0) {
        // prevent negatives
        secondsRemain = 0;
      }

      let normalizedTime: string;

      if (secondsRemain >= 60) {
        const minutes = Math.floor(secondsRemain / 60);
        const seconds = secondsRemain - minutes * 60;
        const formattedSeconds =
          seconds > 9 ? seconds.toString() : `0${seconds}`;

        normalizedTime = `${minutes}:${formattedSeconds}`;
      } else {
        normalizedTime =
          '0:' +
          (secondsRemain > 9 ? secondsRemain.toString() : `0${secondsRemain}`);
      }

      document.title = `${normalizedTime} ${sessionEndingText}`;
      this.secondsRemainingSub.next(secondsRemain);
    });

    // Notes: because the timer uses a "target date" it is not prone to the
    // classic "timer is not counting down while computer asleep" issue
    this.gracePeriodTimerSub = timer(this.timeToCancelSignOutMs)
      .pipe(map(() => {}))
      .subscribe(() => {
        this.authService.logout();
      });

    // Show modal
    this.showModalBehSubj.next(true);
  }

  /**
   * This can be called during the grace period (while session timeout modal is shown to user), in order
   * to stop the auto-sign out and give another X minutes of inactivity before next sign out countdown.
   * (e.g. to be triggered by user clicking "OK" in session timeout modal)
   */
  public userRequestExtendSession() {
    if (this.secondsUpdaterSub !== null && !this.secondsUpdaterSub.closed) {
      this.secondsUpdaterSub.unsubscribe();
      this.secondsUpdaterSub = null;
      this.secondsRemainingSub.next(this.timeToCancelSignOutMs / 1000);
    }

    if (this.gracePeriodTimerSub !== null && !this.gracePeriodTimerSub.closed) {
      this.gracePeriodTimerSub.unsubscribe();
      this.gracePeriodTimerSub = null;

      document.title = Constants.AppPageTitle;
      this.startDetectingUserInactivity();
      this.authService.setAuthCookie();
    } else {
      console.warn(
        "unexpected execution path hit. Trying to cancel grace period timer, when it's not active"
      );
    }

    this.showModalBehSubj.next(false);
  }

  private registerUserActivityListeners() {
    window.addEventListener('scroll', (scrollEvent) => {
      this.userActivityDetectedSubj.next();
    });

    window.addEventListener('mousemove', (scrollEvent) => {
      this.userActivityDetectedSubj.next();
    });

    window.addEventListener('mousedown', (scrollEvent) => {
      this.userActivityDetectedSubj.next();
    });

    window.addEventListener('keypress', (scrollEvent) => {
      this.userActivityDetectedSubj.next();
    });
  }

  /**
   * This method essentially allows us to programatically extend a user's session
   * as if the user had triggered some activity on the page
   * this allows us to "reset the session timer" during long running processes
   * where N-Sight might be left unattended
   */
  public registerEmulatedUserActivity() {
    this.userActivityDetectedSubj.next();
  }
}
