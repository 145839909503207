import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Colors } from '@capsa/api';
import {
  ColorCombo,
  validColorCombos,
} from '@capsa/dropdowns/color-combo-drop-down/color-combo-helper';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-color-combo-drop-down',
  templateUrl: './color-combo-drop-down.component.html',
  styleUrls: ['./color-combo-drop-down.component.scss'],
})
export class ColorComboDropDownComponent implements OnInit, OnDestroy {
  @Input()
  public headerTextTag: string;

  @Input()
  public setColorCombo$: Observable<ColorCombo> | undefined;

  public selectedCombo: ColorCombo | undefined;

  public colorCombos: ColorCombo[] = [...validColorCombos];

  @Output()
  valueChanged: EventEmitter<ColorCombo> = new EventEmitter<ColorCombo>();

  private subs = new Subscription();

  onChange(newValue: ColorCombo | undefined) {
    this.valueChanged.emit(newValue);
  }

  ngOnInit(): void {
    this.selectedCombo = this.findColorCombo(Colors.Black, Colors.White);
    if (this.setColorCombo$) {
      this.subs.add(
        this.setColorCombo$.subscribe((x) => {
          if (x) {
            this.selectedCombo = this.findColorCombo(x.fgColor, x.bgColor);
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private findColorCombo(fgColor: number, bgColor: number) {
    return this.colorCombos.find(
      (i) => i.fgColor === fgColor && i.bgColor === bgColor
    );
  }
}
