import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorHex',
})
export class ColorHexPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (isNaN(value)) {
      console.warn(`pipe error: '${value}' is invalid input for ColorHexPipe`);
      return '#F00';
    } else {
      /* eslint-disable no-bitwise */
      const rgb = {
        r: (value & 0xff0000) >> 16,
        g: (value & 0x00ff00) >> 8,
        b: value & 0x0000ff,
      };
      /* eslint-enable */
      const red = rgb.r.toString(16).padStart(2, '0');
      const green = rgb.g.toString(16).padStart(2, '0');
      const blue = rgb.b.toString(16).padStart(2, '0');

      return '#' + red + green + blue;
    }
  }
}
