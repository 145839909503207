import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { FeatureFlagService } from '@capsa/services/feature-flag/feature-flag.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { FacilityAssignmentsGridService } from '@capsa/services/user';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import * as CustomValidators from '../../capsaCustomValidators';

@Component({
  selector: 'app-edit-user-metadata-and-facs',
  templateUrl: './edit-user-metadata-and-facs.component.html',
  styleUrls: ['./edit-user-metadata-and-facs.component.scss'],
  providers: [FacilityAssignmentsGridService],
})
export class EditUserMetadateAndFacsComponent implements OnInit, OnDestroy {
  get isFormValid() {
    return this.form.valid;
  }

  public get isPristineWebUser(): boolean {
    return this.editUserService.pristineHasNsightRole;
  }

  public get isRegistered(): boolean {
    return this.editUserService.pristineUser.get('HasValidSecurityQuestions');
  }

  public get isEmailConfirmed(): boolean {
    return this.editUserService.pristineUser.get('IsEmailConfirmed');
  }

  public get isPristinePasswordResetInProgress(): boolean {
    return this.editUserService.pristineUser.get('IsPasswordResetRequested');
  }

  public get isLockedOut(): boolean {
    return this.editUserService.pristineUser.get('IsLockedOut');
  }

  public isFormReady = false;

  public firstNameCtrl: UntypedFormControl;
  public lastNameCtrl: UntypedFormControl;
  public emailCtl: UntypedFormControl;
  public proxCardIdCtl: UntypedFormControl;
  public isActiveCtl: UntypedFormControl;
  public canEdit = false;
  public form: UntypedFormGroup;

  private subs = new Subscription();

  constructor(
    private permissionService: PermissionService,
    private capsaRoleService: CapsaRoleService,
    public editUserService: EditUserService,
    public facilityAssignmentGridService: FacilityAssignmentsGridService,
    public ffService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.loadPermissions();

    this.subs.add(
      this.capsaRoleService.rolesList$.subscribe(() => {
        this.setupForm();
        this.startWatchingRolesForEmailValidation();
        setTimeout(() => {
          // Trigger validation, in case we had bad data, this will force the user to correct
          // this will also allow all listeners to receive an initial "form valid status" that is reliable
          this.form.updateValueAndValidity();
        }, 0);
      })
    );

    if (this.editUserService.updatedUser) {
      this.facilityAssignmentGridService.reloadGridData();
    }
    this.subs.add(
      this.editUserService.userLoaded$.subscribe(() => {
        this.facilityAssignmentGridService.reloadGridData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private startWatchingRolesForEmailValidation() {
    this.subs.add(
      this.editUserService.userRolesChanged$.subscribe(() => {
        this.updateEmailRequiredValidator(
          this.editUserService.userHasNsightAccessAnyFacility
        );
        this.emailCtl.updateValueAndValidity();
      })
    );
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([
          Permissions.Manage_Users_ReadWrite,
          Permissions.CLI_Users_ReadWrite,
        ])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  private setupForm(): void {
    this.firstNameCtrl = new UntypedFormControl(
      this.editUserService.pristineUser.get('FirstName'),
      {
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ]),
      }
    );

    this.lastNameCtrl = new UntypedFormControl(
      this.editUserService.pristineUser.get('LastName'),
      {
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ]),
      }
    );

    this.emailCtl = new UntypedFormControl(
      this.editUserService.pristineUser.get('Email'),
      {
        validators: Validators.compose([
          Validators.email,
          Validators.maxLength(256),
          NsightCustomValidators.isEmailExtraChecksValid(),
        ]),
        asyncValidators: Validators.composeAsync([
          CustomValidators.isEmailRequired(
            this.editUserService.userHasNsightAccessAnyFacility
          ),
        ]),
      }
    );

    this.proxCardIdCtl = new UntypedFormControl(
      this.editUserService.pristineUser.get('ProxId')
    );

    this.isActiveCtl = new UntypedFormControl(
      this.editUserService.pristineUser.get('IsActive')
    );

    this.form = new UntypedFormGroup({
      firstName: this.firstNameCtrl,
      lastName: this.lastNameCtrl,
      email: this.emailCtl,
      isActive: this.isActiveCtl,
      proxCardId: this.proxCardIdCtl,
    });

    this.subs.add(
      this.form.valueChanges.subscribe(() => {
        this.editUserService.updateMetadata(
          this.firstNameCtrl.value as string,
          this.lastNameCtrl.value,
          this.emailCtl.value,
          this.isActiveCtl.value,
          this.proxCardIdCtl.value
        );
      })
    );
    this.isFormReady = true;

    this.subs.add(
      this.form.statusChanges.subscribe((statusInfo) => {
        switch (statusInfo) {
          case 'VALID':
          case 'DISABLED': // we'll probably never get 'DISABLED' on a form element, but adding this just in case...
            this.editUserService.updateMetadataValidation(true);
            break;
          case 'INVALID':
          case 'PENDING':
            this.editUserService.updateMetadataValidation(false);
            break;

          default:
            console.warn(
              'DEVELOPER: form.statusChanges value handling not handled, assuming form is invalid!'
            );
            this.editUserService.updateMetadataValidation(false);
        }
      })
    );
  }

  private updateEmailRequiredValidator(doNSightRolesExist: boolean) {
    this.emailCtl.setAsyncValidators(
      Validators.composeAsync([
        CustomValidators.isEmailRequired(doNSightRolesExist),
      ])
    );
  }
}
