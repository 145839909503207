import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BytePipe } from 'app/modules/pipes/byte.pipe';
import { ColorHexPipe } from 'app/modules/pipes/color-hex.pipe';
import { LocalDatePipe } from 'app/modules/pipes/local-date.pipe';
import { MonthYearDatePipe } from 'app/modules/pipes/monthYearDate.pipe';
import { PermissionsPipe } from 'app/modules/pipes/permissions.pipe';
import { ServicesModule } from 'app/modules/services-module/services.module';
import { ColorPipe } from './color.pipe';

@NgModule({
  exports: [
    BytePipe,
    ColorPipe,
    ColorHexPipe,
    PermissionsPipe,
    LocalDatePipe,
    MonthYearDatePipe,
  ],
  declarations: [
    BytePipe,
    ColorPipe,
    ColorHexPipe,
    PermissionsPipe,
    LocalDatePipe,
    MonthYearDatePipe,
  ],
  imports: [CommonModule, ServicesModule],
})
export class PipesModule {}
