import { Component } from '@angular/core';
import { MessageQueueUserType } from '@capsa/api';

@Component({
  selector: 'app-list-messages-from-cart',
  templateUrl: './list-messages-from-cart.component.html',
  styleUrls: ['./list-messages-from-cart.component.scss'],
})
export class ListMessagesFromCartComponent {
  public recipientType = MessageQueueUserType.RequestType;

  constructor() {}
}
