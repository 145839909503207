export enum Permissions {
  CareLinkActivity_Read = 'CareLinkActivity_Read',
  CareLinkActivity_ReadWrite = 'CareLinkActivity_ReadWrite',
  CartSearch = 'CartSearch',
  CLI_Carts_Read = 'CLI_Carts_Read',
  CLI_Carts_ReadWrite = 'CLI_Carts_ReadWrite',
  CLI_DeviceKeyValue_Read = 'CLI_DeviceKeyValue_Read',
  CLI_DeviceKeyValue_ReadWrite = 'CLI_DeviceKeyValue_ReadWrite',
  CLI_EncryptionKey_Read = 'CLI_EncryptionKey_Read',
  CLI_Facility_Read = 'CLI_Facility_Read',
  CLI_Facility_ReadWrite = 'CLI_Facility_ReadWrite',
  CLI_LightColorTheme_Read = 'CLI_LightColorTheme_Read',
  CLI_LightColorTheme_ReadWrite = 'CLI_LightColorTheme_ReadWrite',
  CLI_Messages_Read = 'CLI_Messages_Read',
  CLI_Messages_ReadWrite = 'CLI_Messages_ReadWrite',
  CLI_MessageTemplates_Read = 'CLI_MessageTemplates_Read',
  CLI_MessageTemplates_ReadWrite = 'CLI_MessageTemplates_ReadWrite',
  CLI_NotificationLightColors_Read = 'CLI_NotificationLightColors_Read',
  CLI_NotificationLightColors_ReadWrite = 'CLI_NotificationLightColors_ReadWrite',
  CLI_ProvideSecondaryWithnessAuth = 'CLI_ProvideSecondaryWithnessAuth',
  CLI_Users_Read = 'CLI_Users_Read',
  CLI_Users_ReadWrite = 'CLI_Users_ReadWrite',
  DeviceProfile_Read = 'DeviceProfile_Read',
  DeviceProfile_ReadWrite = 'DeviceProfile_ReadWrite',
  DeviceUpdate_Read = 'DeviceUpdate_Read',
  DeviceUpdate_ReadWrite = 'DeviceUpdate_ReadWrite',
  Manage_CrossEnterpiseData_Read = 'Manage_CrossEnterpiseData_Read',
  Manage_CrossEnterpiseData_ReadWrite = 'Manage_CrossEnterpiseData_ReadWrite',
  Manage_Departments_Read = 'Manage_Departments_Read',
  Manage_Departments_ReadWrite = 'Manage_Departments_ReadWrite',
  Manage_Enterprise_Read = 'Manage_Enterprise_Read',
  Manage_Enterprise_ReadWrite = 'Manage_Enterprise_ReadWrite',
  Manage_Facilities_Read = 'Manage_Facilities_Read',
  Manage_Facilities_ReadWrite = 'Manage_Facilities_ReadWrite',
  Manage_Notifications_Read = 'Manage_Notifications_Read',
  Manage_Notifications_ReadWrite = 'Manage_Notifications_ReadWrite',
  Manage_Organizations_Read = 'Manage_Organizations_Read',
  Manage_Organizations_ReadWrite = 'Manage_Organizations_ReadWrite',
  Manage_UserGroups_Read = 'Manage_UserGroups_Read',
  Manage_UserGroups_ReadWrite = 'Manage_UserGroups_ReadWrite',
  Manage_Users_Read = 'Manage_Users_Read',
  Manage_Users_ReadWrite = 'Manage_Users_ReadWrite',
  MessageQueueRequestType_Read = 'MessageQueueRequestType_Read',
  MessageQueueRequestType_ReadWrite = 'MessageQueueRequestType_ReadWrite',
  MessageQueueSubjectPreset_Read = 'MessageQueueSubjectPreset_Read',
  MessageQueueSubjectPreset_ReadWrite = 'MessageQueueSubjectPreset_ReadWrite',
  PowerLog_Read = 'PowerLog_Read',
  PowerLog_ReadWrite = 'PowerLog_ReadWrite',
  Manage_Cabinets_ReadWrite = 'Manage_Cabinets_ReadWrite',
  Manage_CabinetGroups_Read = 'Manage_CabinetGroups_Read',
  Manage_CabinetGroups_ReadWrite = 'Manage_CabinetGroups_ReadWrite',
  Manage_DeviceUpdates_Read = 'Manage_DeviceUpdates_Read',
  Manage_DeviceUpdates_ReadWrite = 'Manage_DeviceUpdates_ReadWrite',
  CAP_Manage_CrossEnterpiseData_DeviceUpdates_ReadWrite = 'CAP_Manage_CrossEnterpiseData_DeviceUpdates_ReadWrite',
  CAP_Manage_NetworkAccessPoints_Read = 'CAP_Manage_NetworkAccessPoints_Read',
  CAP_Manage_NetworkAccessPoints_ReadWrite = 'CAP_Manage_NetworkAccessPoints_ReadWrite',
  CAP_Dashboard_Analytic_Widget_Read = 'CAP_Dashboard_Analytic_Widget_Read',
  CAP_Dashboard_Analytic_Widget_ReadWrite = 'CAP_Dashboard_Analytic_Widget_ReadWrite',
  CLI_DeviceAccess = 'CLI_DeviceAccess',
  CAP_Support_RequestDeviceLogs = 'CAP_Support_RequestDeviceLogs',
  CLI_MenuAccess_Analytics = 'CLI_MenuAccess_Analytics',
  CLI_MenuAccess_Carts = 'CLI_MenuAccess_Carts',
  CLI_MenuAccess_Carts_Faults = 'CLI_MenuAccess_Carts_Faults',
  CLI_MenuAccess_Facilities = 'CLI_MenuAccess_Facilities',
  CLI_MenuAccess_Facilities_Add = 'CLI_MenuAccess_Facilities_Add',
  CLI_MenuAccess_Messages = 'CLI_MenuAccess_Messages',
  CLI_MenuAccess_Users = 'CLI_MenuAccess_Users',
  CLI_MenuAccess_Users_Add = 'CLI_MenuAccess_Users_Add',
}
