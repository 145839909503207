import { Component } from '@angular/core';
import { MessageQueueUserType } from '@capsa/api';

@Component({
  selector: 'app-list-messages-to-user',
  templateUrl: './list-messages-to-user.component.html',
  styleUrls: ['./list-messages-to-user.component.scss'],
})
export class ListMessagesToUserComponent {
  public recipientType = MessageQueueUserType.User;
  constructor() {}
}
