import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from '@capsa/api';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Represents a failed Capsa REST API call.
 */
export class CapsaApiError extends Error {
  /**
   * The ResponseDto<T> object returned from the API.
   */
  public apiResponse: ApiResponse<any>;

  /**
   * The raw HTTP Response message returned by the API. Usefull for inspecting response details like status code.
   */
  public rawResponse: HttpErrorResponse;

  constructor(message: string, rawResponse: HttpErrorResponse) {
    super(message);

    this.rawResponse = rawResponse;
    this.apiResponse = rawResponse.error;
  }
}

/**
 * Handles fully RESTful responses from the Capsa API by throwing a custom error with
 * the API ResponseDto included, or it casts the response value to be the correct
 * result type.
 */
export const processCapsaRestApiResponse =
  <TResponseData>() =>
  (source: Observable<any>) =>
    source.pipe(
      catchError((err: HttpErrorResponse) => {
        throw new CapsaApiError('API response error', err);
      }),
      map((resp) => resp as TResponseData)
    );
