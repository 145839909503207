import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CartLabelDisplayMode,
  DeviceTypeIds,
  DeviceUpdateType,
} from '@capsa/api';
import { DropDownItemGeneric } from '@capsa/dropdowns/enum';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { SharedDeviceUpdateService } from 'app/modules/device-update/device-update.service';
import { ApplyDeviceUpdateService } from 'app/modules/update-cart-firmware/apply-device-update.service';
import { DeviceUpdateMode } from 'app/modules/update-cart-firmware/update-firmware-interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-update-firmware',
  templateUrl: './update-firmware.component.html',
  styleUrls: ['./update-firmware.component.scss'],
  providers: [SharedDeviceUpdateService, ApplyDeviceUpdateService],
})
export class UpdateCartFirmwareComponent implements OnInit, OnDestroy {
  public updateMode = DeviceUpdateMode.Friendly;
  public DeviceUpdateMode = DeviceUpdateMode;
  public deviceTypeId = DeviceTypeIds.CareLink_2;
  public updateType = new BehaviorSubject<DeviceUpdateType>(
    DeviceUpdateType.Firmware
  );

  public displayMode = CartLabelDisplayMode;
  public selectedDisplayMode = CartLabelDisplayMode.Name;

  public canViewPage = false;
  public canEditFriendly = false;
  private viewPagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Carts];
  private advancedPagePerm: Permissions =
    Permissions.Manage_CrossEnterpiseData_ReadWrite;
  private editFriendlyPerm = Permissions.Manage_DeviceUpdates_ReadWrite;

  public isCapsaSupport = false;

  public facilityId: number | undefined;
  public organizationId: number | undefined;

  public deviceUpdateType = DeviceUpdateType;

  private subs: Subscription = new Subscription();

  constructor(private permissionService: PermissionService) {}

  public ngOnInit() {
    this.loadPermissions();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.has(this.advancedPagePerm)) {
          this.isCapsaSupport = true;
        }

        if (this.permissionService.has(this.editFriendlyPerm)) {
          this.canEditFriendly = true;
        }

        if (this.permissionService.hasAny(this.viewPagePerms)) {
          this.canViewPage = true;
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.viewPagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  public updateTypeChanged(newValue: DropDownItemGeneric<DeviceUpdateType>) {
    this.updateType.next(!newValue ? undefined : newValue.Value);
  }

  public orgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
  }

  public facilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
  }

  public onToggleMode() {
    if (this.updateMode === DeviceUpdateMode.Advanced) {
      this.updateMode = DeviceUpdateMode.Friendly;
    } else {
      this.updateMode = DeviceUpdateMode.Advanced;
    }
  }
}
