import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, DeviceTypeApiModel, ProductLineIds } from '..';
import { BaseApi } from '../base/base.api';

export interface DeviceTypeApiInstance {
  getDeviceTypes(productLine: ProductLineIds): Observable<DeviceTypeApiModel[]>;
  getDeviceTypesProductLines(
    productLines: ProductLineIds[]
  ): Observable<DeviceTypeApiModel[]>;
  // getDeviceTypeVersions(
  //   deviceTypeId: number,
  //   updateType: DeviceUpdateType
  // ): Observable<DeviceTypeVersionApiModel[]>;
  // putDeviceTypeVersion(
  //   deviceTypeId: number,
  //   data: FormData
  // ): Observable<DeviceTypeVersionApiModel>;
  // deleteDeviceVersion(
  //   deviceTypeId: number,
  //   updateVersionId: number
  // ): Observable<ApiResponse<void>>;
}

@Injectable()
export class DeviceTypeApi extends BaseApi implements DeviceTypeApiInstance {
  protected endpoints = {
    getAll: this.buildUrl('device/DeviceTypes'),
    getDeviceVersion: this.buildUrl('device/{deviceTypeId}/version/'),
    postDeviceVersion: this.buildUrl('device/{deviceTypeId}/version'),
    deleteDeviceVersion: this.buildUrl(
      'device/{deviceTypeId}/version/delete/{versionId}'
    ),
  };

  /**
   * Gets a list of all the device types for a given product line.
   * @param productLine The product line to fetch device types for.
   */
  public getDeviceTypes(productLine: ProductLineIds) {
    return this.http
      .get<ApiResponse<DeviceTypeApiModel[]>>(this.endpoints.getAll)
      .pipe(
        map((resp) => resp.Result || []),
        map((types) => {
          return types.filter((t) => t.ProductLineId === productLine);
        })
      );
  }

  /**
   * Gets a list of all the device types for a list of product lines.
   * @param productLines The product lines to fetch device types for.
   */
  public getDeviceTypesProductLines(productLines: ProductLineIds[]) {
    return this.http
      .get<ApiResponse<DeviceTypeApiModel[]>>(this.endpoints.getAll)
      .pipe(
        map((resp) => resp.Result || []),
        map((types) => {
          return types.filter((t) =>
            productLines.find((e) => e === t.ProductLineId)
          );
        })
      );
  }

  // /**
  //  * Gets the list of existing device versions.
  //  */
  // public getDeviceTypeVersions(
  //   deviceTypeId: number,
  //   updateType: DeviceUpdateType
  // ) {
  //   const resource = this.endpoints.getDeviceVersion.replace(
  //     '{deviceTypeId}',
  //     deviceTypeId.toString()
  //   );
  //   return this.http
  //     .get<ApiResponse<DeviceTypeVersionApiModel[]>>(
  //       resource + '?pageSize=1000&updateType=' + updateType
  //     )
  //     .pipe(map((resp) => resp.Result || []));
  // }

  // /**
  //  * Create a new device version
  //  */
  // public putDeviceTypeVersion(deviceTypeId: number, data: FormData) {
  //   const resource = this.endpoints.postDeviceVersion.replace(
  //     '{deviceTypeId}',
  //     deviceTypeId.toString()
  //   );
  //   return this.http
  //     .post<ApiResponse<DeviceTypeVersionApiModel>>(resource, data)
  //     .pipe(map((resp) => resp.Result));
  // }

  /**
   * Delete a device version
   */
  // public deleteDeviceVersion(
  //   deviceTypeId: number,
  //   updateVersionId: number
  // ): Observable<ApiResponse<void>> {
  //   const resource = this.endpoints.deleteDeviceVersion
  //     .replace('{deviceTypeId}', deviceTypeId.toString())
  //     .replace('{versionId}', updateVersionId.toString());
  //   return this.http
  //     .post<ApiResponse<void>>(resource, null)
  //     .pipe(throwIfBadRespFullResp());
  // }
}
