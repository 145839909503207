// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#btnSave {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/cart-profile/pages/create-or-edit/create-or-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;AACF","sourcesContent":["#btnSave {\n  position: absolute;\n  top: 0;\n  right: 15px;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
