import { Injectable } from '@angular/core';
import { EnterpriseApi } from '@capsa/api/enterprise';
import { AuthService } from '@capsa/services/auth/auth.service';

export interface IEnterpriseService {
  enterpriseId: number;
}

@Injectable()
export class EnterpriseService implements IEnterpriseService {
  public get enterpriseId() {
    return this.auth.currentUser ? this.auth.currentUser.EnterpriseId : -1;
  }

  constructor(
    private auth: AuthService,
    private enterpriseApi: EnterpriseApi
  ) {}

  public getCurrentUsersEnterprise() {
    return this.enterpriseApi.getById(this.enterpriseId);
  }
}
