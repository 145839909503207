import { Injectable } from '@angular/core';
import { BlobStorageApi } from '@capsa/api/blob-storage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  private readonly ContainerName = 'carelink-resources';

  private readonly acceptedFileNames: string[] = [
    'Trio_Cart_User_Import_Template.xlsx',
    'Avalo_Cart_User_Import_Template_v2.xlsx',
    'Access_Point_Import_Template.xlsx',
  ];

  constructor(private blobStorageApi: BlobStorageApi) {}

  public getUrl(fileName: string) {
    if (!this.acceptedFileNames.some((x) => x === fileName)) {
      throw new Error('Filename not yet supported. Please add it.');
    }

    return this.blobStorageApi
      .getFileUrl({
        ContainerName: this.ContainerName,
        FileName: fileName,
      })
      .pipe(map((apiResponse) => apiResponse.Result));
  }
}
