import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NameWithLongIdApiModel } from '@capsa/api';
import { EofCacheService } from '@capsa/services/eof-cache';
import { TranslateService } from '@ngx-translate/core';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { List as ImmutableList } from 'immutable';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface FacilityAssignmentGridRowData {
  organization: NameWithLongIdApiModel;
  facility: NameWithLongIdApiModel;
  roleNames;
}

const DtoRequired: ValidatorFn = (
  formControl: UntypedFormControl
): ValidationErrors | null => {
  const organization: NameWithLongIdApiModel = formControl.value;
  if (organization.Id === -1) {
    return { required: true };
  }

  return null;
};

// Will be added to User Manage component, since we want a new instance
// of this each time a new user is loaded
@Injectable()
export class FacilityAssignmentsGridService implements OnDestroy {
  private loadingOrgsBehSub = new BehaviorSubject<boolean>(true);
  private loadingFacsBehSub = new BehaviorSubject<boolean>(true);
  private ddlOrgsBehSub = new BehaviorSubject<NameWithLongIdApiModel[]>([]);
  private ddlFacsBehSub = new BehaviorSubject<NameWithLongIdApiModel[]>([]);
  private facilityAssignmentsBehSub = new BehaviorSubject<
    FacilityAssignmentGridRowData[]
  >([]);
  private loadingAssignmentsBehSub = new BehaviorSubject<boolean>(true);

  public ddlOrganizations$ = this.ddlOrgsBehSub.asObservable();
  public ddlFacilities$ = this.ddlFacsBehSub.asObservable();
  public loadingOrgs$ = this.loadingOrgsBehSub.asObservable();
  public loadingFacs$ = this.loadingFacsBehSub.asObservable();
  public facilityAssignments$ = this.facilityAssignmentsBehSub.asObservable();
  public loadingAssignments$ = this.loadingAssignmentsBehSub.asObservable();

  private subs = new Subscription();

  private pristineEnterpriseOrgs: ImmutableList<NameWithLongIdApiModel>;
  private pristineEnterpriseFacs: ImmutableList<NameWithLongIdApiModel>;

  constructor(
    private eofCacheService: EofCacheService,
    private translateService: TranslateService,
    private editUserService: EditUserService
  ) {
    // bind public interface since Kendo uses `this` context manipulation...
    this.reloadGridData = this.reloadGridData.bind(this);
    this.addAssignment = this.addAssignment.bind(this);
    this.updateAssignment = this.updateAssignment.bind(this);
    this.removeAssignment = this.removeAssignment.bind(this);
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public reloadGridData() {
    this.loadGridData();
  }

  public loadOrganizationDropdown() {
    this.loadingOrgsBehSub.next(true);

    let visibleOrgs = this.pristineEnterpriseOrgs.toArray();

    visibleOrgs =
      this.filterOutOrgsThatHaveAllFacilitiesAlreadyAssigned(visibleOrgs);

    this.ddlFacsBehSub.next([]);
    this.ddlOrgsBehSub.next(visibleOrgs);
    this.loadingOrgsBehSub.next(false);
  }

  private filterOutOrgsThatHaveAllFacilitiesAlreadyAssigned(
    ddlOrgs: NameWithLongIdApiModel[]
  ) {
    const assignedFacIds = this.editUserService.updatedUser.get('FacilityIds');
    const fullAssignedFacilities = this.pristineEnterpriseFacs.filter((x) =>
      assignedFacIds.some((y) => y === x.Id)
    );

    // Store ALL possible facilities that orgs from DDL can be assigned (so we don't
    // have to loop through the full list repeatedly)
    const allDdlOrgFacilities = this.pristineEnterpriseFacs.filter((x) =>
      ddlOrgs.some((z) => z.Id === x.ParentId)
    );

    const orgIdsToRemove: number[] = [];

    ddlOrgs.forEach((ddlOrg) => {
      const allCurOrgFacilities = allDdlOrgFacilities.filter(
        (x) => x.ParentId === ddlOrg.Id
      );
      const allCurOrgAssignedFacilities = fullAssignedFacilities.filter(
        (x) => x.ParentId === ddlOrg.Id
      );

      if (allCurOrgFacilities.count() === allCurOrgAssignedFacilities.count()) {
        orgIdsToRemove.push(ddlOrg.Id);
      }
    });

    return ddlOrgs.filter((x) => !orgIdsToRemove.some((y) => y === x.Id));
  }

  public loadFacilityDropdown(orgId: number, currentFacilityId: number | null) {
    this.loadingFacsBehSub.next(true);

    let visibleFacs = this.pristineEnterpriseFacs
      .filter((f) => f.ParentId === orgId)
      .toArray();

    const alreadyAssignedFacs = new Set(
      this.facilityAssignmentsBehSub.value.map((a) => a.facility.Id)
    );

    if (currentFacilityId !== null) {
      // filter out all facilities that are already assigned, but include the currently selected
      visibleFacs = visibleFacs.filter(
        (f) => f.Id === currentFacilityId || !alreadyAssignedFacs.has(f.Id)
      );
    } else {
      // filter already assigned orgs
      visibleFacs = visibleFacs.filter((f) => !alreadyAssignedFacs.has(f.Id));
    }

    this.ddlFacsBehSub.next(visibleFacs);
    this.loadingFacsBehSub.next(false);
  }

  private loadGridData() {
    this.loadingAssignmentsBehSub.next(true);

    this.subs.add(
      this.eofCacheService.initialized$.subscribe((eofCache) => {
        if (!eofCache) {
          return;
        }

        this.pristineEnterpriseOrgs = ImmutableList(eofCache.organizations);
        this.pristineEnterpriseFacs = ImmutableList(eofCache.facilities);

        this.loadAssignments(eofCache.organizations, eofCache.facilities);
      })
    );
  }

  public updateAssignment(
    pristineData: FacilityAssignmentGridRowData,
    toUpdate: FacilityAssignmentGridRowData,
    rowIdx: number
  ) {
    const gridData = this.facilityAssignmentsBehSub.value;
    gridData[rowIdx] = toUpdate;

    const orgsSet = new Set<number>(gridData.map((x) => x.organization.Id));
    const facsSet = new Set<number>(gridData.map((x) => x.facility.Id));

    this.editUserService.updateFacAssignment(
      Array.from(orgsSet),
      Array.from(facsSet)
    );
    this.facilityAssignmentsBehSub.next(gridData);
  }

  public addAssignment(toAdd: FacilityAssignmentGridRowData) {
    this.addAssignmentToDto(toAdd);

    const newGridData = this.facilityAssignmentsBehSub.value;
    newGridData.unshift(toAdd);

    this.facilityAssignmentsBehSub.next(newGridData);
  }

  public removeAssignment(
    toRemove: FacilityAssignmentGridRowData,
    rowIdx: number
  ) {
    this.removeAssignmentFromDto(toRemove);

    const newGridData = this.facilityAssignmentsBehSub.value;
    newGridData.splice(rowIdx, 1);

    this.facilityAssignmentsBehSub.next(newGridData);
  }

  private addAssignmentToDto(toAdd: FacilityAssignmentGridRowData) {
    const updatedOrgs = this.editUserService.updatedUser.get('OrganizationIds');
    const updatedFacs = this.editUserService.updatedUser.get('FacilityIds');

    // Only add org, if it's not already in the list
    if (!updatedOrgs.some((x) => x === toAdd.organization.Id)) {
      updatedOrgs.push(toAdd.organization.Id);
    }

    updatedFacs.push(toAdd.facility.Id);

    this.editUserService.updateFacAssignment(updatedOrgs, updatedFacs);
  }

  private removeAssignmentFromDto(toRemove: FacilityAssignmentGridRowData) {
    const updatedOrgs = this.editUserService.updatedUser.get('OrganizationIds');
    const updatedFacilities =
      this.editUserService.updatedUser.get('FacilityIds');

    const facToRemoveIdx = updatedFacilities.indexOf(toRemove.facility.Id);
    updatedFacilities.splice(facToRemoveIdx, 1);

    // look to see if there are any remaining facilities assigned to the user with the given organization
    const shouldRemoveOrg =
      this.pristineEnterpriseFacs
        .filter((f) => f.ParentId === toRemove.organization.Id)
        .filter((f) => updatedFacilities.includes(f.Id))
        .count() === 0;

    // If this the last facility in this org? if yes, then remove the org assignment as well
    if (shouldRemoveOrg) {
      const orgToRemoveIdx = updatedOrgs.indexOf(toRemove.organization.Id);
      updatedOrgs.splice(orgToRemoveIdx, 1);
    }

    this.editUserService.updateFacAssignment(updatedOrgs, updatedFacilities);
  }

  /**
   * Initial load of the users assigned facilities
   * @param entOrgs all orgs within an enterprise
   * @param entFacs all facilities within an enterprise
   */
  private loadAssignments(
    entOrgs: NameWithLongIdApiModel[],
    entFacs: NameWithLongIdApiModel[]
  ) {
    const rows: FacilityAssignmentGridRowData[] = [];

    const assignedRoles = this.buildRoleCsv();

    this.editUserService.updatedUser
      .get('FacilityIds')
      .forEach((usersAssignedFacId) => {
        const facMatch = entFacs.find((ddl) => ddl.Id === usersAssignedFacId);
        if (!facMatch) {
          // no selected facility in the list of available facilites for some reason...
          return;
        }

        // now lookup the org the facility belongs to.
        const orgMatch = entOrgs.find((o) => o.Id === facMatch.ParentId);
        if (!orgMatch) {
          // couldn't find the org for some reason...
          return;
        }

        const assignment: FacilityAssignmentGridRowData = {
          organization: orgMatch,
          facility: facMatch,
          roleNames: assignedRoles,
        };

        rows.push(assignment);
      });

    this.facilityAssignmentsBehSub.next(rows);
    this.loadingAssignmentsBehSub.next(false);
  }

  public buildRoleCsv() {
    return this.editUserService.pristineUser
      .get('UserRoleList')
      .filter((r) => r.Name.startsWith('CLI_'))
      .map((r) => this.translateService.instant(r.Name) as string)
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }

  public buildForm(assignment?: FacilityAssignmentGridRowData) {
    const formData = assignment || {
      organization: {
        Id: -1,
        Name: '',
        ParentId: undefined,
      },
      facility: {
        Id: -1,
        Name: '',
        ParentId: undefined,
      },
      roleNames: this.buildRoleCsv(),
    };

    return new UntypedFormGroup({
      organization: new UntypedFormControl(formData.organization, DtoRequired),
      facility: new UntypedFormControl(formData.facility, DtoRequired),
      roleNames: new UntypedFormControl(formData.roleNames),
    });
  }
}
