import { Injectable } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FacilityUserDropDownDataSource extends AbstractDropDownListDataSource<NameWithLongIdApiModel> {
  constructor(
    private api: FacilityApi,
    private translations: TranslateService
  ) {
    super();
  }

  get defaultItem(): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  public enterpriseId: number;
  public organizationId: number;
  public facilityId: number | undefined;
  public userFacilityList: NameWithLongIdApiModel[];

  protected callApi(): Observable<NameWithLongIdApiModel[]> {
    return this.api.getDropdown(this.organizationId).pipe(
      map((data) => {
        // The API call returns all facilities for a given organization
        // this filters then further by the users's facilities
        return data.filter((x) =>
          this.userFacilityList.find((f) => f.Id === x.Id)
        );
      })
    );
  }
  protected map(
    apiModel: NameWithLongIdApiModel
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      id: apiModel.Id.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<NameWithLongIdApiModel>[]
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    if (this.organizationId === undefined) {
      return undefined;
    }

    if (this.facilityId !== undefined) {
      return items.find((i) => i.apiModel.Id === this.facilityId);
    }
  }

  public reset() {
    super.reset();
    this.facilityId = undefined;
  }
}
