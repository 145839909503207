import { Injectable } from '@angular/core';
import {
  FacilityResponse,
  NameWithLongIdApiModel,
  OrganizationResponse,
} from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { OrganizationApi } from '@capsa/api/organization';
import { AbstractCacheService } from '@capsa/services/abstract-cache/abstract-cache.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

interface EofCacheData {
  organizations: NameWithLongIdApiModel[];
  facilities: NameWithLongIdApiModel[];
  facilitiesApiResponses: FacilityResponse[];
  organizationsApiResponses: OrganizationResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class EofCacheService extends AbstractCacheService<EofCacheData> {
  public get organizations(): NameWithLongIdApiModel[] {
    return this.cacheData.get('organizations') as NameWithLongIdApiModel[];
  }

  public get facilities(): NameWithLongIdApiModel[] {
    return this.cacheData.get('facilities') as NameWithLongIdApiModel[];
  }

  public get facilitiesApiResponses(): FacilityResponse[] {
    return this.cacheData.get('facilitiesApiResponses') as FacilityResponse[];
  }

  public get organizationsApiResponses(): OrganizationResponse[] {
    return this.cacheData.get(
      'organizationsApiResponses'
    ) as OrganizationResponse[];
  }

  constructor(
    private enterpriseService: EnterpriseService,
    private organizationApi: OrganizationApi,
    private facilityApi: FacilityApi
  ) {
    super();
  }

  protected loadApiData() {
    return forkJoin([
      this.getEnterpriseOrganizations(),
      this.getEnterpriseFacilities(),
    ]).pipe(
      map((apiResponses) => {
        const uniqueOrgIds = new Set<number>(
          apiResponses[0].map((x) => x.OrganizationId)
        );
        const uniqueFacIds = new Set<number>(
          apiResponses[1].map((x) => x.FacilityId)
        );

        const orgResponses: OrganizationResponse[] = [];
        const facResponses: FacilityResponse[] = [];

        // Prevents duplicates from leaking into app
        apiResponses[0].forEach((x) => {
          if (!uniqueOrgIds.has(x.OrganizationId)) {
            return;
          }
          orgResponses.push(x);
          uniqueOrgIds.delete(x.OrganizationId);
        });

        // Prevents duplicates from leaking into app
        apiResponses[1].forEach((x) => {
          if (!uniqueFacIds.has(x.FacilityId)) {
            return;
          }
          facResponses.push(x);
          uniqueFacIds.delete(x.FacilityId);
        });

        return {
          facilities: facResponses.map((fac) => ({
            Id: fac.FacilityId,
            Name: fac.Name,
            ParentId: fac.OrganizationId,
          })),
          facilitiesApiResponses: facResponses,
          organizationsApiResponses: orgResponses,
          organizations: orgResponses.map((org) => ({
            Id: org.OrganizationId,
            Name: org.Name,
            ParentId: org.EnterpriseId,
          })),
        } as EofCacheData;
      })
    );
  }

  public getDetailedOrgsFromIds(orgIds: number[]): NameWithLongIdApiModel[] {
    return this.organizations.filter((fullOrg) =>
      orgIds.some((argOrgId) => argOrgId === fullOrg.Id)
    );
  }

  public getDetailedFacsFromIds(facIds: number[]): NameWithLongIdApiModel[] {
    return this.facilities.filter((fullFac) =>
      facIds.some((argFacId) => argFacId === fullFac.Id)
    );
  }

  private getEnterpriseOrganizations() {
    return this.organizationApi.search({
      EnterpriseId: this.enterpriseService.enterpriseId,
    });
  }

  private getEnterpriseFacilities() {
    return this.facilityApi
      .search({
        EnterpriseId: this.enterpriseService.enterpriseId,
      })
      .pipe(
        map((apiResponse) => (apiResponse.Success ? apiResponse.Result : []))
      );
  }

  public addFacility(
    toAdd: NameWithLongIdApiModel,
    toAddResponse: FacilityResponse
  ) {
    this.transform((cache) => {
      const toMerge: Partial<EofCacheData> = {
        facilities: [toAdd],
        facilitiesApiResponses: [toAddResponse],
      };

      return cache.mergeDeep(toMerge);
    });
  }

  public updateFacilityName(facilityId: number, newName: string) {
    const fMatch = this.facilities.find((f) => f.Id === facilityId);
    const fRespMatch = this.facilitiesApiResponses.find((f) => f.FacilityId);

    if (!fMatch && !fRespMatch) {
      return;
    }

    const toMerge: Partial<EofCacheData> = {};

    if (fMatch) {
      fMatch.Name = newName;
      toMerge.facilities = [fMatch];
    }
    if (fRespMatch) {
      fRespMatch.Name = newName;
      toMerge.facilitiesApiResponses = [fRespMatch];
    }

    this.transform((cache) => {
      return cache.mergeDeep(toMerge);
    });
  }

  public isFacilityNameUniqueToOrg(
    facilityName: string,
    parentOrgId: number
  ): boolean {
    const orgFacilities = this.facilities.filter(
      (f) => f.ParentId === parentOrgId
    );

    if (orgFacilities && orgFacilities.length > 0) {
      return !orgFacilities.some(
        (f) => f.Name.trim().toLowerCase() === facilityName.trim().toLowerCase()
      );
    } else {
      // should NEVER happen... but let's do this
      return true;
    }
  }
}
