import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AnalyticsChartEntity,
  CartLabelDisplayMode,
  ChartEntityType,
  ChartQueryType,
  ChartTimeResolutionIntervalType,
} from '@capsa/api';
import { AnalyticsHelperService } from '@capsa/services/analytics-helper/analytics-helper.service';
import {
  CategoryAxisItemComponent,
  CategoryAxisLabelsComponent,
  CategoryBaseUnit,
  DateFormats,
  SeriesClickEvent,
} from '@progress/kendo-angular-charts';
import { SeriesLeaveEvent } from '@progress/kendo-angular-charts/events/series-leave-event';
import { SeriesOverEvent } from '@progress/kendo-angular-charts/events/series-over-event';
import {
  AnalyticsDataStructureType,
  AnalyticsQueryProperties,
  EntityItem,
} from 'app/modules/analytics/analytics-interfaces';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-analytics-bar-chart',
  templateUrl: './analytics-bar-chart.component.html',
  styleUrls: ['./analytics-bar-chart.component.scss'],
})
export class AnalyticsBarChartComponent implements OnInit, OnDestroy {
  @Input()
  public newData$: Observable<void>;

  @Input()
  public data: AnalyticsChartEntity[];

  @Input()
  public entities: EntityItem[];

  @Input()
  public selectedEntityIds: string[];

  @Input()
  public chartDateStart: Date;

  @Input()
  public chartDateEnd: Date;

  @Input()
  public xAxisDateFormats: DateFormats;

  @Input()
  public tooltipDateTimeFormat: string;

  @Input()
  private throttledResize$: Observable<Event>;

  @Input()
  public chartTitleTag: string;

  @Input()
  public tooltipValueDescriptionTag: string;

  @Input()
  public queryType: ChartQueryType;

  @Input()
  public queryByEntityType: ChartEntityType;

  @Input()
  public dateTimeInterval: ChartTimeResolutionIntervalType;

  @Input()
  public selectedDisplayMode: CartLabelDisplayMode;

  public get categoryBaseUnit(): CategoryBaseUnit {
    return this.analyticsHelperService.convertApiIntervalToKendoBaseUnit(
      this.dateTimeInterval
    );
  }

  public get currentQueryProps(): AnalyticsQueryProperties {
    return this.analyticsHelperService.props(this.queryType);
  }

  @ViewChild('chartAxisItem', { static: false })
  public axisItems: CategoryAxisItemComponent;

  @ViewChild('xAxisItemLabels', { static: false })
  public xAxisItemLabels: CategoryAxisLabelsComponent;

  // TODO, move to analytics helper service properties
  public get gapBetweenBarGroups(): number {
    switch (this.queryType) {
      case ChartQueryType.AccessSuccessfulPINs:
      case ChartQueryType.AccessFailedPINs:
      case ChartQueryType.AccessLockouts:
      case ChartQueryType.LoggedOutBatteryLevel:
      case ChartQueryType.LoggedOutLowBattery:
      case ChartQueryType.TimeLoggedIn:
        return 1;
      default:
        return 5;
    }
  }

  /**
   * Since the data is slightly transformed for some queries, we
   * need to tell the tooltip to NOT display the Subcategory
   * For these queries the "SubQuery" is the ID of the entity
   */
  public get showSubcategory(): boolean {
    return !this.currentQueryProps.hideSubcategory;
  }
  private currentComponentSelector: string;
  private lastHoverEvent: 'enter' | 'leave';
  private subs = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private analyticsHelperService: AnalyticsHelperService,
    private elem: ElementRef
  ) {
    this.currentComponentSelector =
      this.elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit() {
    this.subs.add(
      this.throttledResize$.subscribe(() => {
        this.analyticsHelperService.refreshChartSizeProperties(
          this.currentComponentSelector,
          this.axisItems,
          this.cdRef
        );
      })
    );
    this.subs.add(
      this.newData$.subscribe(() => {
        this.analyticsHelperService.refreshChartSizeProperties(
          this.currentComponentSelector,
          this.axisItems,
          this.cdRef
        );
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onSeriesClick(e: SeriesClickEvent) {
    this.analyticsHelperService.seriesClickedSubj.next(
      this.data[e.series.index]
    );
  }

  public onSeriesOver(e: SeriesOverEvent) {
    if (this.lastHoverEvent === 'enter') {
      // if you continue moving around inside the bar
      // this event continues to get fired like a torrential downpour
      // this essentially cancels any subsequent unnecessary events
      return;
    }
    this.lastHoverEvent = 'enter';
    this.itemMouseEnter(this.data[e.series.index]);
  }

  public onSeriesLeave(e: SeriesLeaveEvent) {
    if (this.lastHoverEvent === 'leave') {
      return;
    }
    this.lastHoverEvent = 'leave';
    this.itemMouseLeave(this.data[e.series.index]);
  }

  /**
   * Handles a click on the "eye" next to the legend icon
   */
  public visibilityToggled(item: AnalyticsChartEntity) {
    switch (this.currentQueryProps.dataStructureType) {
      case AnalyticsDataStructureType.stackedPerEntity:
        const newHiddenState = !item.Hidden;
        this.data.forEach((x) => {
          if (x.EntityKey === item.EntityKey) {
            x.Hidden = newHiddenState;
          }
        });

        break;
      default:
        item.Hidden = !item.Hidden;
        break;
    }

    this.data = [...this.data];
  }

  /**
   * Handles a click on the legend item text/marker. (Highlights the series and
   * leaves it highlighted until the user clicks on legend text/marker again)
   */
  public fixedHighlightToggled(item: AnalyticsChartEntity) {
    const newHighlightState = !item.FixedHighlight;
    switch (this.currentQueryProps.dataStructureType) {
      case AnalyticsDataStructureType.stackedPerEntity:
        this.data.forEach((x) => {
          if (x.EntityKey === item.EntityKey) {
            x.FixedHighlight = newHighlightState;
          }
        });

        break;
      default:
        item.FixedHighlight = !item.FixedHighlight;
        break;
    }

    const someItemFixedHighlight = this.data.some((x) => x.FixedHighlight);

    this.data.forEach((x) => {
      if (someItemFixedHighlight) {
        if (item.EntityKey === x.EntityKey) {
          this.applyHighlightToggle(x, newHighlightState, true);
        } else {
          this.applyHighlightToggle(x, x.FixedHighlight);
        }
      } else {
        // not a single "highlighted" item, reset all to defaults
        this.applyHighlightToggle(x, true);
      }
    });

    this.data = [...this.data];
  }

  private applyHighlightToggle(
    item: AnalyticsChartEntity,
    highlight: boolean,
    isMouseHover?: boolean
  ) {
    // On mouse hover we need to differentiate fixed highlited
    // items from "hover" highlighted items
    // for line charts that's done with a "bolder" line, and larger markers
    // here we just make the color lighter than others
    if (isMouseHover) {
      item.Opacity = undefined;
      item.Border = {
        color: 'black',
        width: 2,
      };
    } else if (highlight) {
      // restore default
      item.Opacity = undefined;
      item.Border = undefined;
    } else {
      item.Opacity = 0.15;
      item.Border = undefined;
    }
  }

  public clearAllFixedHighlights() {
    // In this case we "clear" by "resetting" all items to their defaults
    this.data.forEach((x) => {
      x.FixedHighlight = false;
      this.applyHighlightToggle(x, true);
    });
    this.data = [...this.data];
  }

  /**
   * Handles a temporary hover-over on the legend item (highlights series while mouse is over legend item)
   */
  public itemMouseEnter(item: AnalyticsChartEntity) {
    // fade out ALL non-fixed highlight series
    switch (this.currentQueryProps.dataStructureType) {
      case AnalyticsDataStructureType.stackedPerEntity:
        this.data.forEach((x) => {
          if (x.EntityKey === item.EntityKey) {
            this.applyHighlightToggle(x, true, true);
          } else {
            // If this is not highlighted series
            // then apply highlight only if "fixedHighlight" is
            // true for this series
            this.applyHighlightToggle(x, x.FixedHighlight);
          }
        });
        break;
      default:
        this.data.forEach((x) =>
          this.applyHighlightToggle(x, x.FixedHighlight)
        );
        this.applyHighlightToggle(item, true, true);
        break;
    }

    this.data = [...this.data];
  }

  public itemMouseLeave(item: AnalyticsChartEntity) {
    const someItemFixedHighlight = this.data.some((x) => x.FixedHighlight);

    switch (this.currentQueryProps.dataStructureType) {
      case AnalyticsDataStructureType.stackedPerEntity:
        this.data
          .filter((x) => x.EntityKey === item.EntityKey)
          .forEach((y) => {
            this.applyHighlightToggle(y, item.FixedHighlight);
          });
        break;
      default:
        this.applyHighlightToggle(item, item.FixedHighlight);
        break;
    }

    // If NO items are "fixed highlight", then a "mouse out" will restore opacity (full color)
    // to ALL items
    // IF there's at least one fixed highlight item, then DON'T restore opacity, so that
    // NON-highlighted series will remain "faded out"
    this.data.forEach((x) => {
      if (!x.FixedHighlight && !someItemFixedHighlight) {
        x.Opacity = undefined;
      }
    });
    this.data = [...this.data];
  }

  public legendHideAllToggled(show: boolean) {
    this.data.forEach((x) => (x.Hidden = !show));
    this.data = [...this.data];
  }
}
