import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceTypeIds } from '@capsa/api';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-access-points',
  templateUrl: 'access-points.component.html',
  styleUrls: ['access-points.component.scss'],
})
export class AccessPointsComponent implements OnInit, OnDestroy {
  public facilityId: number;
  public deviceTypeId: DeviceTypeIds;
  public deviceTypeIdsType = DeviceTypeIds;

  private subs: Subscription = new Subscription();

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Facilities];

  constructor(private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.permissionService.permissionsUpdated$.subscribe(() => {
      if (this.permissionService.hasAny(this.pagePerms)) {
        this.canViewPage = true;
      } else {
        this.canViewPage = false;
      }
    });

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public facilityIdChanged(newValue: number) {
    this.facilityId = newValue;
  }

  public onDeviceTypeChanged(newValue: DeviceTypeIds) {
    this.deviceTypeId = newValue;
  }
}
