import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  LightColorThemeResponse,
  LightColorThemeUpdateRequest,
} from '..';
import { BaseApi } from '../base/base.api';

export interface LightingApiInstance {
  getLedThemes(facilityId: number): Observable<LightColorThemeResponse[]>;
  updateLedTheme(
    facilityId: number,
    lightColorThemeId: number,
    updateRequest: LightColorThemeUpdateRequest
  ): Observable<ApiResponse<void>>;
}

@Injectable()
export class LightingApi extends BaseApi implements LightingApiInstance {
  protected endpoints = {
    getUrl: this.buildUrl('facility/{id}/lightingtheme'),
    updateUrl: this.buildUrl('facility/{facilityId}/lightingtheme/{themeId}'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getLedThemes(
    facilityId: number
  ): Observable<LightColorThemeResponse[]> {
    return this.http.get<LightColorThemeResponse[]>(
      this.endpoints.getUrl.replace('{id}', facilityId.toString())
    );
  }

  public updateLedTheme(
    facilityId: number,
    lightColorThemeId: number,
    updateRequest: LightColorThemeUpdateRequest
  ): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.updateUrl
        .replace('{themeId}', lightColorThemeId.toString())
        .replace('{facilityId}', facilityId.toString()),
      updateRequest
    );
  }
}
