import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public get analytics(): boolean {
    return !(localStorage.getItem('ff_analytics') === null);
  }

  constructor() {}
}
