import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CartPartialResponse, DeviceTypeIds } from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user-cart-access',
  templateUrl: './edit-user-cart-access-tab.component.html',
  styleUrls: ['./edit-user-cart-access-tab.component.scss'],
})
export class EditUserCartAccessComponent
  implements OnInit, OnDestroy, OnChanges
{
  public cartsListsLoading = false;

  public availableCarts: CartPartialResponse[] = [];
  public assignedCarts: CartPartialResponse[] = [];

  public availableCartData: GridDataResult;
  public assignedCartData: GridDataResult;

  public selectedAvailable: number[] = [];
  public selectedAssigned: number[] = [];

  public pageSize = 500;

  public availableState: State = {
    skip: 0,
    take: this.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  };

  public assignedState: State = {
    skip: 0,
    take: this.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  };

  @Input()
  public organizationId: number | undefined;

  @Input()
  public deviceTypeId: DeviceTypeIds | undefined;

  private assignedGridSelectionService: GridSelectionService;
  private availableGridSelectionService: GridSelectionService;

  private subs: Subscription = new Subscription();

  constructor(
    private cartApi: CartApi,
    private gridSelectionServiceFactory: GridSelectionServiceFactory,
    private editUserService: EditUserService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.assignedGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectRowIds
    );
    this.availableGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectRowIds
    );
    this.refreshCartsLists();

    this.subs.add(
      this.availableGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedAvailable = newSelection;
        }
      )
    );

    this.subs.add(
      this.assignedGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedAssigned = newSelection;
        }
      )
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const deviceTypeChanged =
      changes.deviceTypeId && !changes.deviceTypeId.isFirstChange();
    if (deviceTypeChanged) {
      this.refreshAllData();
    }
  }

  private selectRowIds(rows: RowArgs[]) {
    return rows.map((row) => row.dataItem.Id);
  }

  public onAvailableSelectionChanged(change: SelectionEvent) {
    this.availableGridSelectionService.updateSelection(change);
  }

  public onAssignedSelectionChanged(change: SelectionEvent) {
    this.assignedGridSelectionService.updateSelection(change);
  }

  private refreshAllData() {
    this.selectedAssigned.length = 0;
    this.selectedAvailable.length = 0;
    this.assignedCarts.length = 0;
    this.reprocess();
    this.refreshCartsLists();
  }

  private refreshCartsLists() {
    this.cartsListsLoading = true;
    const sub = this.cartApi
      .getCartsPartial(
        {
          PageNumber: 1,
          PageSize: 100000,
        },
        this.organizationId,
        this.editUserService.selectedFacilityId,
        this.deviceTypeId
      )
      .subscribe(
        (resp) => {
          this.refreshAvailableCartsList(resp);
          this.cartsListsLoading = false;
        },
        (err) => {
          this.toasterService.showError('GETTING_CARTS_LIST_FAILED');
        }
      );

    this.subs.add(sub);
  }

  private refreshAvailableCartsList(resp: CartPartialResponse[]) {
    resp.sort((a, b) => {
      return a.Id - b.Id;
    });
    this.availableCarts = resp;
    this.fillUsersFacilityCarts(
      this.editUserService.updatedUser.get('UserCartIds')
    );
  }

  private fillUsersFacilityCarts(allUsersCarts: number[]) {
    allUsersCarts.sort((a, b) => {
      return a - b;
    });
    const tempUsersCarts = [];
    const tempAvailCarts = this.availableCarts;

    allUsersCarts.forEach((x) => {
      const match = this.availableCarts.find((y) => y.Id === x);

      if (match) {
        tempUsersCarts.push({
          Id: match.Id,
          Name: match.Name,
          SerialNumber: match.SerialNumber,
        });
      }

      this.assignedCarts = tempUsersCarts;
    });

    this.availableCarts = [];

    this.assignedCarts.forEach((x) => {
      const matchedIndex = tempAvailCarts.findIndex((y) => y.Id === x.Id);
      if (matchedIndex >= 0) {
        tempAvailCarts.splice(matchedIndex, 1);
      }
    });

    this.availableCarts = tempAvailCarts;
    this.reprocess();
  }

  private reprocess() {
    this.assignedCartData = process(this.assignedCarts, this.assignedState);
    this.availableCartData = process(this.availableCarts, this.availableState);
  }

  public availableStateChange(state: DataStateChangeEvent): void {
    this.availableState = state;
    this.availableCartData = process(this.availableCarts, this.availableState);
  }

  public assignedStateChange(state: DataStateChangeEvent): void {
    this.assignedState = state;
    this.assignedCartData = process(this.assignedCarts, this.assignedState);
  }

  public btnTriggerMoveAllToAssigned() {
    if (!this.availableCarts.length) {
      return;
    }

    const tempAssigned = [...this.assignedCarts, ...this.availableCarts];

    tempAssigned.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = [];
    this.assignedCarts = tempAssigned;
    this.saveUserCartsToPendingUser();
    this.availableGridSelectionService.clearSelection();
    this.assignedGridSelectionService.clearSelection();
    this.reprocess();
  }

  public btnTriggerMoveAllToAvailable() {
    if (!this.assignedCarts.length) {
      return;
    }

    const tempAvailable = [...this.availableCarts, ...this.assignedCarts];

    tempAvailable.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = tempAvailable;
    this.assignedCarts = [];
    this.saveUserCartsToPendingUser();
    this.assignedGridSelectionService.clearSelection();
    this.availableGridSelectionService.clearSelection();
    this.reprocess();
  }

  public btnTriggerMoveSelectedToAssigned() {
    if (!this.selectedAvailable.length) {
      return;
    }

    this.selectedAvailable.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableCarts;
    const tempUserCarts = this.assignedCarts;

    this.availableCarts = [];
    this.assignedCarts = [];

    do {
      const iAv = this.selectedAvailable.pop();
      const cartToMoveIdx = tempAvail.findIndex((x) => x.Id === iAv);
      const cartToMove = tempAvail.splice(cartToMoveIdx, 1)[0];
      tempUserCarts.push(cartToMove);
    } while (this.selectedAvailable.length);

    tempUserCarts.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = tempAvail;
    this.assignedCarts = tempUserCarts;
    this.saveUserCartsToPendingUser();
    this.availableGridSelectionService.clearSelection();
    this.reprocess();
  }

  public btnTriggerMoveAssignedToAvailable() {
    if (!this.selectedAssigned.length) {
      return;
    }

    this.selectedAssigned.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableCarts;
    const tempUserCarts = this.assignedCarts;

    this.availableCarts = [];
    this.assignedCarts = [];

    do {
      const iAv = this.selectedAssigned.pop();
      const cartToMoveIdx = tempUserCarts.findIndex((x) => x.Id === iAv);
      const cartToMove = tempUserCarts.splice(cartToMoveIdx, 1)[0];
      tempAvail.push(cartToMove);
    } while (this.selectedAssigned.length);

    tempAvail.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = tempAvail;
    this.assignedCarts = tempUserCarts;
    this.saveUserCartsToPendingUser();
    this.assignedGridSelectionService.clearSelection();
    this.reprocess();
  }

  private saveUserCartsToPendingUser() {
    // remove any cart id's from user that are in the 'available' list
    const userCarts = this.editUserService.updatedUser.get('UserCartIds');

    // Go through each cart the user currently has in their list
    // Check if any item in the users list is now in the Facility Carts list (available carts)
    // If true, then the cart was moved from assigned --> available. This cart should be removed from the users list
    for (let i = userCarts.length - 1; i >= 0; i--) {
      const match = this.availableCarts.find((x) => x.Id === userCarts[i]);
      if (match) {
        userCarts.splice(i, 1);
      }
    }

    // add any cart id's to user that are in the assigned list BUT not yet in users list...
    this.assignedCarts.forEach((x) => {
      const match = userCarts.find((y) => y === x.Id);
      if (!match) {
        userCarts.push(x.Id);
      }
    });

    this.editUserService.updateUserAssignedCarts(userCarts);
  }
}
