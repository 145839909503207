import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NameWithLongIdApiModel } from '@capsa/api';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import {
  FacilityAssignmentGridRowData,
  FacilityAssignmentsGridService,
} from 'app/modules/users/user-management/facility-assignments-grid.service';
import { Record } from 'immutable';
import { Observable, Subscription } from 'rxjs';

const ImmutableFacilityAssignmentGridRowData =
  Record<FacilityAssignmentGridRowData>({
    roleNames: '',
    facility: {
      Id: 0,
      Name: '',
      ParentId: undefined,
    },
    organization: {
      Id: 0,
      Name: '',
      ParentId: undefined,
    },
  });

@Component({
  selector: 'app-facility-assignments-grid',
  templateUrl: './facility-assignments-grid.component.html',
  styleUrls: ['./facility-assignments-grid.component.scss'],
})
export class FacilityAssignmentsGridComponent implements OnInit, OnDestroy {
  // @Input()
  // public facilityAssignmentGridService: FacilityAssignmentsGridService;

  public facilityAssignments$: Observable<FacilityAssignmentGridRowData[]>;

  public loadingAssignments$: Observable<boolean>;

  public editForm: UntypedFormGroup;
  private rowIndexEditing: number;
  private isAddPending = false;

  public get isRowEditingOrAdding() {
    return !isNaN(this.rowIndexEditing) || this.isAddPending;
  }

  public canDeleteRecords = true;

  private subs = new Subscription();

  private pristineAssignment: Record<FacilityAssignmentGridRowData>;

  public get userHasMultipleOrgs() {
    return this.userEofCacheService.userHasMultipleOrgs;
  }

  constructor(
    private userEofCacheService: UserEofCacheService,
    public facilityAssignmentGridService: FacilityAssignmentsGridService
  ) {}

  ngOnInit() {
    this.facilityAssignments$ =
      this.facilityAssignmentGridService.facilityAssignments$;
    this.loadingAssignments$ =
      this.facilityAssignmentGridService.loadingAssignments$;

    this.subs.add(
      this.facilityAssignments$.subscribe((data) => {
        this.canDeleteRecords = data.length > 1;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public addHandler({ sender }) {
    this.closeEditor(sender);
    this.isAddPending = true;
    this.editForm = this.facilityAssignmentGridService.buildForm();

    if (this.userHasMultipleOrgs) {
      this.listenForOrgChanges();
      this.facilityAssignmentGridService.loadOrganizationDropdown();
    } else {
      // skip loading orgs, and only load facilities
      const onlyOrg = this.userEofCacheService.getFirstOrg();
      this.editForm.controls.organization.setValue(onlyOrg);
      this.facilityAssignmentGridService.loadFacilityDropdown(onlyOrg.Id, null);
    }

    sender.addRow(this.editForm);
  }

  private listenForOrgChanges() {
    this.subs.add(
      this.editForm.controls.organization.valueChanges.subscribe(
        (selectedOrg: NameWithLongIdApiModel) => {
          this.editForm.controls.facility.setValue({
            Id: -1,
            Name: '',
            ParentId: undefined,
          });
          this.facilityAssignmentGridService.loadFacilityDropdown(
            selectedOrg.Id,
            null
          );
        }
      )
    );
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.rowIndexEditing = rowIndex;

    const assignment: FacilityAssignmentGridRowData = dataItem;

    this.pristineAssignment =
      ImmutableFacilityAssignmentGridRowData(assignment);

    this.editForm = this.facilityAssignmentGridService.buildForm(assignment);

    this.listenForOrgChanges();

    this.loadDropdownsForAssignment(assignment);

    sender.editRow(rowIndex, this.editForm);
  }

  private loadDropdownsForAssignment(
    assignment: FacilityAssignmentGridRowData
  ) {
    this.facilityAssignmentGridService.loadOrganizationDropdown();

    this.facilityAssignmentGridService.loadFacilityDropdown(
      assignment.organization.Id,
      assignment.facility.Id
    );
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const updatedData: FacilityAssignmentGridRowData = formGroup.value;

    if (isNew) {
      this.facilityAssignmentGridService.addAssignment(updatedData);
    } else {
      this.facilityAssignmentGridService.updateAssignment(
        this.pristineAssignment.toObject(),
        updatedData,
        rowIndex
      );
    }

    this.closeEditor(sender, rowIndex);
  }

  public removeHandler({ dataItem, rowIndex }): void {
    this.facilityAssignmentGridService.removeAssignment(dataItem, rowIndex);
  }

  /**
   * Closes the specified "in progress" row
   */
  private closeEditor(grid, rowIndex = this.rowIndexEditing) {
    grid.closeRow(rowIndex);
    this.isAddPending = false;
    this.rowIndexEditing = undefined;
    this.pristineAssignment = undefined;
  }
}
