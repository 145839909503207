import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-indicator-circular',
  templateUrl: './loading-indicator-circular.component.html',
  styleUrls: ['./loading-indicator-circular.scss'],
})
export class LoadingIndicatorCircularComponent {
  @Input()
  public size = '20px';

  @Input()
  public loading: boolean;

  constructor() {}
}
