import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CapsaSettingsTypeIds,
  DeviceTypeIds,
  UserExportRequest,
  UserPartialResponse,
  UserSearchRequest,
} from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { GridFilterHelperService } from '@capsa/services/grid-filter-helper/grid-filter-helper.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { CapsaApiError } from 'app/modules/core/rxjs-operators';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, OnChanges, OnDestroy {
  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<UserPartialResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  private query: Partial<UserSearchRequest> = {
    SortInfo: [{ ColumnName: 'LastName', IsDescending: false }],
  };
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  public organizationId: number;
  public facilityId: number;
  public includeInactive = false;
  public filterDelayTime = 1500;
  private deviceUserRoleIds: number[] = [];
  public settings = [CapsaSettingsTypeIds.CLI_IsCartUserActive];
  public settingsDeviceTypeId?: number = DeviceTypeIds.CareLink_2;

  private subs: Subscription = new Subscription();
  private getUsersSub: Subscription = new Subscription();

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Users];

  constructor(
    private userApi: UserApi,
    private enterpriseService: EnterpriseService,
    private router: Router,
    private toasterService: ToasterService,
    private capsaRoleService: CapsaRoleService,
    private filterService: GridFilterHelperService,
    public loaderService: LoaderService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;

          this.resetPagingAndData();
          this.loadGridData();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.loadGridData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.resetPagingAndData();
      this.loadGridData();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.getUsersSub.unsubscribe();
  }

  public orgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.deviceUserRoleIds.length = 0;
  }

  public facilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    if (newValue !== undefined) {
      this.loadDeviceUserRoles(this.facilityId);
    }
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  public onActiveChanged(newValue: boolean | undefined) {
    this.includeInactive = !this.includeInactive;
    this.resetPagingAndData();
    this.loadGridData();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  /**
   * If Organization and Facility ID's are defined,
   * calls API for users list
   * Otherwise clears the list
   */
  private loadGridData() {
    this.getUsersSub.unsubscribe();
    if (!this.organizationId || !this.facilityId) {
      return;
    }
    this.resetFilters();

    const transformation = (fieldName: string, filter: FilterDescriptor) => {
      if (fieldName === 'Email') {
        this.query.Email = filter.value;
      } else if (fieldName === 'FirstName') {
        this.query.FirstName = filter.value;
      } else if (fieldName === 'LastName') {
        this.query.LastName = filter.value;
      } else if (fieldName === 'UserName') {
        this.query.UserName = filter.value;
      }
    };

    this.filterService.transform(this.gridFilter, transformation);

    this.gridDataLoading = true;

    this.getUsersSub = this.userApi
      .getUserList(
        this.organizationId,
        this.facilityId,
        this.includeInactive,
        this.pageSize,
        this.skip / this.pageSize + 1,
        this.settings,
        this.settingsDeviceTypeId,
        true, // OmitUnassignedUsers
        this.query.Email || undefined,
        this.query.FirstName || undefined,
        this.query.LastName || undefined,
        this.query.UserName || undefined,
        this.query.SortInfo
      )
      .subscribe(
        (x) => {
          this.totalItems = x.TotalRecordCount;
          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(x.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...x.Result,
            ]);
          }

          this.gridDataLoading = false;
        },
        () => {
          this.gridDataLoading = false;
          this.toasterService.showError('USER_LIST_LOAD_FAIL');
        }
      );
  }

  public onSelectUser(event: Event, item: UserPartialResponse) {
    this.router.navigate(['user', 'management', item.Id]);
  }

  public onExport() {
    this.loaderService.start();

    const exportRequest: UserExportRequest = {
      UserFilters: {
        EnterpriseId: this.enterpriseService.enterpriseId,
        OrganizationIds: [this.organizationId],
        FacilityIds: [this.facilityId],
        IncludeInactiveUsers: this.includeInactive,
        RoleIds: this.deviceUserRoleIds,
      },
      SettingFilters: {
        EnterpriseId: this.enterpriseService.enterpriseId,
        DeviceTypeId: DeviceTypeIds.CareLink_2,
        SettingTypes: [
          CapsaSettingsTypeIds.CLI_UserPinCode,
          CapsaSettingsTypeIds.CareLink_ITServicesMenuEnable,
          CapsaSettingsTypeIds.CareLink_DrawerAccessEnable,
          CapsaSettingsTypeIds.CareLink_LockoutOverrideEnabled,
          CapsaSettingsTypeIds.CLI_IsCartUserActive,
        ],
      },
    };

    const sub = this.userApi.exportUsers(exportRequest).subscribe(
      (result) => {
        window.open(result.Result, '_blank');
        this.loaderService.stop();
      },
      (_: CapsaApiError) => {
        this.toasterService.showError('EXPORT_USER_FAIL');
        this.loaderService.stop();
      }
    );
    this.subs.add(sub);
  }

  private loadDeviceUserRoles(facilityId: number) {
    this.subs.add(
      this.capsaRoleService
        .getDeviceUserRoleIds(facilityId)
        .subscribe((roles) => {
          this.deviceUserRoleIds = roles.map((r) => r.CapsaRoleId);
        })
    );
  }

  public onFilterChanged(data: CompositeFilterDescriptor) {
    this.gridFilter = data;
    this.resetPagingAndData();
    this.loadGridData();
  }

  private resetFilters() {
    this.query.FirstName = undefined;
    this.query.LastName = undefined;
    this.query.Email = undefined;
    this.query.UserName = undefined;
  }

  public sortChange(sort: SortDescriptor[]): void {
    if (this.gridDataLoading) {
      return;
    }
    this.sort = sort;
    this.query.SortInfo = [];
    this.sort.forEach((col) => {
      if (!col.dir) {
        return;
      }
      this.query.SortInfo.push({
        ColumnName: col.field,
        IsDescending: col.dir === 'desc',
      });
    });

    this.skip = 0;
    this.loadGridData();
  }
}
