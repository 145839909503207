import { Injectable } from '@angular/core';
import { DeviceTypeApiModel, DeviceTypeIds, ProductLineIds } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/common/constants';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DeviceTypeDropDownDataSource extends AbstractDropDownListDataSource<DeviceTypeApiModel> {
  constructor(private translations: TranslateService) {
    super();
  }

  get defaultItem(): AsyncDropDownValue<DeviceTypeApiModel> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  public get lastSelectedDeviceTypeId(): DeviceTypeIds | undefined {
    if (!this.retrievedLastDeviceTypeIdFromLocalStorage) {
      this.loadLastSelectedFromLocalStorage();
    }
    return this._lastSelectedDeviceTypeId;
  }

  public set lastSelectedDeviceTypeId(val: DeviceTypeIds | undefined) {
    localStorage.setItem(
      Constants.LocalStorageKeys.lastSelectedDeviceTypeId,
      val.toString()
    );

    this._lastSelectedDeviceTypeId = val;
  }

  /**
   * Returns true if the user has ever made a "Device Type" selection (based on never expiring localStorage)
   */
  public get hasStoredSelection() {
    return !isNaN(this.lastSelectedDeviceTypeId);
  }
  private deviceTypeWhitelist = [
    DeviceTypeIds.CareLink_2,
    // DeviceTypeIds.CareLink_M48,  // removed as part of https://capsahealthcare.atlassian.net/browse/NS-1593
    DeviceTypeIds.Avalo,
  ];

  private retrievedLastDeviceTypeIdFromLocalStorage = false;

  private _lastSelectedDeviceTypeId: DeviceTypeIds | undefined;

  public deviceTypeId: number | undefined;

  /**
   * To run once per app session to retrieve the users last selected device type
   */
  private loadLastSelectedFromLocalStorage(): void {
    this.retrievedLastDeviceTypeIdFromLocalStorage = true;

    const lsVal = localStorage.getItem(
      Constants.LocalStorageKeys.lastSelectedDeviceTypeId
    );

    const num = parseInt(lsVal, 10);

    if (!isNaN(num)) {
      this._lastSelectedDeviceTypeId = num;
    }
  }
  protected callApi(): Observable<DeviceTypeApiModel[]> {
    return of<DeviceTypeApiModel[]>([
      {
        DeviceTypeId: DeviceTypeIds.CareLink_2,
        Name: 'Trio',
        ProductLineId: ProductLineIds.CareLink,
      },
      {
        DeviceTypeId: DeviceTypeIds.Avalo,
        Name: 'Avalo',
        ProductLineId: ProductLineIds.Avalo,
      },
    ]);
  }
  protected map(
    apiModel: DeviceTypeApiModel
  ): AsyncDropDownValue<DeviceTypeApiModel> {
    return {
      id: apiModel.DeviceTypeId.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<DeviceTypeApiModel>[]
  ): AsyncDropDownValue<DeviceTypeApiModel> {
    return items.find((i) => i.apiModel.DeviceTypeId === this.deviceTypeId);
  }

  public load() {
    return super.load().pipe(
      map((items) => {
        return items
          .filter((dt) =>
            this.deviceTypeWhitelist.includes(dt.apiModel.DeviceTypeId)
          )
          .sort((a, b) => a.name.localeCompare(b.name));
      })
    );
  }
}
