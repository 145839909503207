import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  CartGroupCreateRequest,
  CartGroupMemberCreateRequest,
  CartGroupMemberResponse,
  CartGroupMembersUpdateRequest,
  CartGroupResponse,
  CartGroupSearchRequest,
  CartGroupUpdateRequest,
  throwIfBadRespFullResp,
} from '@capsa/api';
import { BaseApi } from '@capsa/api/base/base.api';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CartGroupApi extends BaseApi {
  protected endpoints = {
    getById: this.buildUrl('cartgroup/GetById'),
    create: this.buildUrl('cartgroup/Create'),
    update: this.buildUrl('cartgroup/Update'),
    delete: this.buildUrl('cartgroup/Delete'),
    search: this.buildUrl('cartgroup/Search'),
    createMember: this.buildUrl('cartgroupmember/Create'),
    deleteMember: this.buildUrl('cartgroupmember/Delete'),
    updateMembers: this.buildUrl('cartgroupmember/Update'),
    getMemberById: this.buildUrl('cartgroupmember/GetById'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getById(id: number): Observable<ApiResponse<CartGroupResponse>> {
    return this.http
      .post<ApiResponse<CartGroupResponse>>(this.endpoints.getById, { Id: id })
      .pipe(throwIfBadRespFullResp());
  }

  public create(
    request: CartGroupCreateRequest
  ): Observable<ApiResponse<number>> {
    return this.http
      .post<ApiResponse<number>>(this.endpoints.create, request)
      .pipe(throwIfBadRespFullResp());
  }

  public update(
    request: CartGroupUpdateRequest
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.update, request)
      .pipe(throwIfBadRespFullResp());
  }

  public delete(id: number): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.delete, { Id: id })
      .pipe(throwIfBadRespFullResp());
  }

  public search(
    request: CartGroupSearchRequest
  ): Observable<ApiResponse<CartGroupResponse[]>> {
    return this.http
      .post<ApiResponse<CartGroupResponse[]>>(this.endpoints.search, request)
      .pipe(
        throwIfBadRespFullResp(),
        map((resp) => {
          resp.Result.forEach((g) => {
            g.MemberCount = g.Members?.length || 0;
          });

          return resp;
        })
      );
  }

  public updateMembers(
    request: CartGroupMembersUpdateRequest
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.updateMembers, request)
      .pipe(throwIfBadRespFullResp());
  }

  public createMember(
    request: CartGroupMemberCreateRequest
  ): Observable<ApiResponse<number>> {
    return this.http
      .post<ApiResponse<number>>(this.endpoints.createMember, request)
      .pipe(throwIfBadRespFullResp());
  }

  public deleteMember(id: number): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.deleteMember, {
        Id: id,
      })
      .pipe(throwIfBadRespFullResp());
  }

  public getMemberById(
    id: number
  ): Observable<ApiResponse<CartGroupMemberResponse>> {
    return this.http
      .post<ApiResponse<CartGroupMemberResponse>>(
        this.endpoints.getMemberById,
        { Id: id }
      )
      .pipe(throwIfBadRespFullResp());
  }
}
