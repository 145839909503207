import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CoreModule } from 'app/modules/core/core.module';
import { DataDrivenControlsModule } from 'app/modules/data-driven-controls/data-driven-controls.module';
import { BatteryWarningsTrioComponent } from 'app/modules/facility/facility-manage-battery/battery-warnings-trio/battery-warnings-trio.component';
import { FacilityManageBatteryComponent } from 'app/modules/facility/facility-manage-battery/facility-manage-battery.component';
import { FacilityManageDisplayAndAlertsComponent } from 'app/modules/facility/facility-manage-display-and-alerts/facility-manage-display-and-alerts.component';
import { FacilityManageLoginRequiredComponent } from 'app/modules/facility/facility-manage-login-required/facility-manage-login-required.component';
import { FacilityManageNotificationsComponent } from 'app/modules/facility/facility-manage-notifications/facility-manage-notifications.component';
import { FacilityManagePinsComponent } from 'app/modules/facility/facility-manage-pins/facility-manage-pins.component';
import { FacilityManageProfileComponent } from 'app/modules/facility/facility-manage-profile/facility-manage-profile.component';
import { FacilityManageServiceRequestsComponent } from 'app/modules/facility/facility-manage-service-requests/facility-manage-service-requests.component';
import { FacilityManageTimeoutComponent } from 'app/modules/facility/facility-manage-timeout/facility-manage-timeout.component';
import { FacilityManageComponent } from 'app/modules/facility/facility-manage/facility-manage.component';
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { FacilityManageProfileImageComponent } from './facility-manage-profile/facility-manage-profile-image/facility-manage-profile-image.component';

@NgModule({
  declarations: [
    FacilityManageComponent,
    FacilityManagePinsComponent,
    FacilityManageTimeoutComponent,
    FacilityManageLoginRequiredComponent,
    FacilityManageNotificationsComponent,
    FacilityManageProfileComponent,
    FacilityManageDisplayAndAlertsComponent,
    FacilityManageServiceRequestsComponent,
    FacilityManageProfileImageComponent,
    FacilityManageBatteryComponent,
    BatteryWarningsTrioComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    CapsaDropdownsModule,
    LayoutModule,
    ButtonsModule,
    TranslateModule,
    LoadingIndicatorModule,
    DataDrivenControlsModule,
    LabelsModule,
    InputsModule,
    FormsModule,
    GridModule,
    DialogsModule,
    NotificationModule,
    ReactiveFormsModule,
    UploadsModule,
    CustomInputsModule,
  ],
})
export class FacilityModule {}
