import { Component } from '@angular/core';
import { ChartQueryType } from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-analytics-power',
  templateUrl: './analytics-power.component.html',
  styleUrls: ['./analytics-power.component.scss'],
})
export class AnalyticsPowerComponent {
  public queriesDropDownItems: DropDownItem[] = [
    {
      Label: this.translationService.instant('QUERY_TYPE_BATTERY_VOLATAGE'),
      Value: ChartQueryType.BatteryVoltage,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_BATTERY_CAPACITY'),
      Value: ChartQueryType.BatteryCapacity,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_EXTERNAL_LOAD'),
      Value: ChartQueryType.ExternalLoad,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_DC_POWER_USAGE'),
      Value: ChartQueryType.DCPowerUsage,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_CHARGING_MODE_ALL'),
      Value: ChartQueryType.ChargingModeAll,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_CHARGING_MODE_AC'),
      Value: ChartQueryType.ChargingModeAc,
    },
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_CHARGING_MODE_BATTERY'
      ),
      Value: ChartQueryType.ChargingModeBattery,
    },
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_CHARGING_MODE_NOT_REPORTING'
      ),
      Value: ChartQueryType.ChargingModeNotReporting,
    },
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_CHARGING_BEHAVIOR_BATTERY_REMAIN_AVG'
      ),
      Value: ChartQueryType.LoggedOutBatteryLevel,
    },
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_CHARGING_BEHAVIOR_BATTERY_REMAIN_LOW'
      ),
      Value: ChartQueryType.LoggedOutLowBattery,
    },
  ];

  constructor(private translationService: TranslateService) {}
}
