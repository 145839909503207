import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  DeviceTypeWarningApiModel,
  DeviceWarningCreateRequest,
  DeviceWarningUpdateApiModel,
} from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as CustomValidators from './validators';

export interface WarningGridRowItem {
  id?: number;
  text: string;
  percentSettingId: number | undefined;
  percent: number;
  flashingSettingId: number | undefined;
  flashing: boolean;
}

@Component({
  selector: 'app-cart-profile-trio-battery-warnings',
  templateUrl: './battery-warnings-trio.component.html',
  styleUrls: ['./battery-warnings-trio.component.scss'],
})
export class BatteryWarningsTrioComponent implements OnInit, OnDestroy {
  constructor(
    private toasterService: ToasterService,
    private facilityApi: FacilityApi,
    private permissionService: PermissionService
  ) {}

  public warnings: WarningGridRowItem[] = [];

  private subs = new Subscription();

  public isLoading = true;

  public ngOnInit() {
    this.reload();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Keeps track of which row is currently in "edit/add" mode
   * We use it for determining which row to close in case the
   * user clicks "Add" to add a new row (or edits another row)
   * without saving/cancelling an in progress row
   */
  private rowIndexEditing: number;

  /**
   * Used to mark this specific control as invalid, since the form level validation
   * of duplicate percentage does not always update the control state as "invalid"
   * This is essentially duplicate code, but we don't know of another way to do this (yet...)
   */
  private uniquePercentageCtrlLevel =
    () =>
    (ctrl: FormControl<number>): ValidationErrors | null => {
      const formVal = this.batteryWarningFg?.value;
      const enteredPercentage = ctrl.value;

      if (ctrl?.value === null || ctrl?.value === 0) {
        return null;
      }

      const otherPercentages = this.warnings
        .filter((w) => w.id !== formVal.id)
        .map((w) => w.percent);

      if (otherPercentages.some((p) => p === enteredPercentage)) {
        return { percentOverlapCtrlLevel: true };
      }

      return null;
    };

  public batteryWarningFg = new FormGroup(
    {
      id: new FormControl<number>(0),
      percent: new FormControl<number>(0, {
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.compose([this.uniquePercentageCtrlLevel()]),
        ],
      }),
      text: new FormControl<string>('', {
        validators: [Validators.required, Validators.maxLength(100)],
      }),
      flashing: new FormControl<boolean>(true),
      flashingSettingId: new FormControl<number>(0),
      percentSettingId: new FormControl<number>(0),
    },
    {
      validators: [
        // the warnings are passed by reference and don't appear to update
        // if warnings is reassigned a different/new array
        // so take care to ensure the warnings array gets cleared, and then
        // concatenated to, rather than just re-assigned a different array
        Validators.compose([CustomValidators.uniquePercentage(this.warnings)]),
      ],
    }
  );

  /**
   * Handles "Add New" button click
   */
  public addHandler({ sender }) {
    this.batteryWarningFg.reset();
    this.closeEditor(sender);

    sender.addRow(this.batteryWarningFg);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.rowIndexEditing = rowIndex;

    const typedDataItem = dataItem as WarningGridRowItem;
    this.batteryWarningFg.controls.id.setValue(typedDataItem.id);
    this.batteryWarningFg.controls.percent.setValue(typedDataItem.percent);
    this.batteryWarningFg.controls.percentSettingId.setValue(
      typedDataItem.percentSettingId
    );
    this.batteryWarningFg.controls.flashingSettingId.setValue(
      typedDataItem.flashingSettingId
    );
    this.batteryWarningFg.controls.flashing.setValue(typedDataItem.flashing);
    this.batteryWarningFg.controls.text.setValue(typedDataItem.text);

    sender.editRow(rowIndex, this.batteryWarningFg);
  }

  private reload() {
    // Clear all existing
    this.warnings.length = 0;

    // Get warnings
    if (this.permissionService.facilityId) {
      this.isLoading = true;
      this.subs.add(
        this.facilityApi
          .getWarnings(this.permissionService.facilityId)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            (warnings) => {
              // we 'push' instead of reassign new array to ensure the duplicate\
              // percentage validator doesn't lose the reference to latest warnings
              this.warnings.push(
                ...warnings.map((w) => this.respToGridItem(w))
              );
            },
            (err) => {
              this.toasterService.showError(
                'Failed to retrieve battery warnings'
              );
            }
          )
      );
    } else {
      this.toasterService.showError(
        'FacilityId not supplied. Could not get battery warnings'
      );
    }
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const item = formGroup.value as WarningGridRowItem;
    item.percent = Math.floor(item.percent);

    if (isNew) {
      this.subs.add(
        this.facilityApi
          .addWarning(
            this.permissionService.facilityId,
            this.gridItemCreateDto(item)
          )
          .subscribe(
            (resp) => {
              this.warnings.push(this.respToGridItem(resp));
              sender.closeRow(rowIndex);
              this.rowIndexEditing = undefined;
            },
            (error) => {
              this.toasterService.showError('CREATE_FAILED');
            }
          )
      );
    } else {
      this.subs.add(
        this.facilityApi
          .updateWarning(
            this.permissionService.facilityId,
            item.id,
            this.gridItemUpdateDto(item)
          )
          .subscribe(
            (resp) => {
              sender.closeRow(rowIndex);
              this.rowIndexEditing = undefined;
              const idx = this.warnings.findIndex((x) => x.id === item.id);
              this.warnings[idx] = item;
            },
            (error) => {
              this.toasterService.showError('UPDATE_FAILED');
            }
          )
      );
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.rowIndexEditing) {
    grid.closeRow(rowIndex);
    this.batteryWarningFg.controls.percent.reset();
    this.rowIndexEditing = undefined;
  }

  public removeHandler({ dataItem }): void {
    const typedDataItem: WarningGridRowItem = dataItem;

    this.subs.add(
      this.facilityApi
        .deleteWarning(this.permissionService.facilityId, typedDataItem.id)
        .subscribe(
          (resp) => {
            const deletedIdx = this.warnings.findIndex(
              (x) => x.id === typedDataItem.id
            );
            this.warnings.splice(deletedIdx, 1);
          },
          (error) => {
            this.toasterService.showError('DELETE_FAILED');
          }
        )
    );
  }

  private gridItemCreateDto(
    gridItem: WarningGridRowItem
  ): DeviceWarningCreateRequest {
    return {
      Text: gridItem.text,
      FacilityId: this.permissionService.facilityId,
      Settings: [
        {
          SettingType:
            CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Flashing,
          SettingValue: gridItem.flashing ? '1' : '0',
        },
        {
          SettingType:
            CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Percent,
          SettingValue: gridItem.percent.toString(),
        },
      ],
    };
  }

  private gridItemUpdateDto(
    gridItem: WarningGridRowItem
  ): DeviceWarningUpdateApiModel {
    return {
      DeviceWarningId: gridItem.id,
      Text: gridItem.text,
      FacilityId: this.permissionService.facilityId,
      Settings: [
        {
          CapsaSettingId: gridItem.flashingSettingId,
          SettingType:
            CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Flashing,
          SettingValue: gridItem.flashing ? '1' : '0',
        },
        {
          CapsaSettingId: gridItem.percentSettingId,
          SettingType:
            CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Percent,
          SettingValue: gridItem.percent.toString(),
        },
      ],
    };
  }

  private respToGridItem(apiModel: DeviceTypeWarningApiModel) {
    const percentSetting = apiModel.Settings.find(
      (s) =>
        s.SettingType ===
        CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Percent
    );

    const flashingSetting = apiModel.Settings.find(
      (s) =>
        s.SettingType ===
        CapsaSettingsTypeIds.DeviceProfile_BatteryWarning_Flashing
    );

    const gridItem: WarningGridRowItem = {
      id: apiModel.DeviceWarningId,
      text: apiModel.Text,
      percent: Number(percentSetting.SettingValue),
      percentSettingId: percentSetting.SettingId,
      flashing: flashingSetting.SettingValue === '1',
      flashingSettingId: flashingSetting.SettingId,
    };

    return gridItem;
  }
}
