import { Injectable } from '@angular/core';
import { CartSearchRequest, ChartEntityType, ProductLineIds } from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { CartGroupApi } from '@capsa/api/cart-group';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { UserApi } from '@capsa/api/user';
import {
  EntitiesAndSelections,
  EntityCache,
  EntityCacheGroups,
} from '@capsa/services/analytics-cache/entity-list-interfaces';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import {
  EntityGroupItem,
  EntityItem,
} from 'app/modules/analytics/analytics-interfaces';
import { forkJoin, Observable, of, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsCacheService {
  // 1000 * 60 * 2 = 2 minutes
  private readonly CACHE_TIME_MS = 1000 * 60 * 2;

  /**
   * Contains cart, user and AP lists for different org/facility combo's
   * The key format should be "{orgId}_{facilityId}" e.g.("4_13")
   */
  private cache = new Map<string, EntityCacheGroups>();
  private cacheTimer = timer(this.CACHE_TIME_MS);

  public userInputStartDate: Date;
  public userInputEndDate: Date;

  constructor(
    private cartApi: CartApi,
    private cartGroupApi: CartGroupApi,
    private userApi: UserApi,
    private accessPointApi: NetworkAccessPointApi,
    private enterpriseService: EnterpriseService
  ) {}

  private isCachedAndFresh(
    orgId: number,
    facilityId: number,
    entityType: ChartEntityType
  ) {
    const cached = this.cache.get(this.getKey(orgId, facilityId));

    if (
      cached &&
      cached[entityType] &&
      !cached[entityType].isStale &&
      cached[entityType].entityList
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getFromCache(
    orgId: number,
    facilityId: number,
    entityType: ChartEntityType
  ): EntityCache {
    const cached = this.cache.get(this.getKey(orgId, facilityId));
    if (cached) {
      return cached[entityType];
    } else {
      return null;
    }
  }

  private upsertToCache(
    orgId: number,
    facilityId: number,
    entityType: ChartEntityType,
    entities: EntityItem[],
    groups: EntityGroupItem[]
  ): EntityCache {
    const key = this.getKey(orgId, facilityId);
    let cached = this.cache.get(key);
    if (!cached) {
      cached = {};
      for (const typeKey in ChartEntityType) {
        // filtering out extra properties, and "Unknown"
        if (
          typeKey !== ChartEntityType.Unknown.toString() &&
          !isNaN(parseInt(typeKey, 10))
        ) {
          cached[typeKey] = undefined;
        }
      }
    } else if (cached[entityType] && cached[entityType].staleTimer) {
      // cancel any existing 'stale timers'
      cached[entityType].staleTimer.unsubscribe();
    }

    // Here we ensure we preserve user selection even if fresh list is coming in
    let selectedEntityIds: string[] = [];
    let selectedEntityGroupIds: string[] = [];
    if (cached[entityType]) {
      selectedEntityIds = cached[entityType].selectedEntityIds;
      selectedEntityGroupIds = cached[entityType].selectedEntityGroupIds;
    }

    cached[entityType] = {
      isStale: false,
      entityList: entities,
      groupList: groups,
      staleTimer: this.cacheTimer.subscribe(() => {
        this.cache.get(key)[entityType].isStale = true;
      }),
      selectedEntityIds,
      selectedEntityGroupIds,
    };

    this.cache.set(key, cached);
    return cached[entityType];
  }

  public getLists(
    orgId: number,
    facilityId: number,
    entityType: ChartEntityType
  ): Observable<EntitiesAndSelections> {
    if (!this.isCachedAndFresh(orgId, facilityId, entityType)) {
      switch (entityType) {
        case ChartEntityType.Cart:
          return this.getCartEntities(orgId, facilityId);
        case ChartEntityType.User:
          return this.getUserEntities(orgId, facilityId);
        case ChartEntityType.AccessPoint:
          return this.getAccessPointEntities(orgId, facilityId);
        default:
          throw new Error('Invalid entityType');
      }
    } else {
      const cached = this.getFromCache(orgId, facilityId, entityType);
      // Returning copy (not reference to cache arrays), to ensure cache data integrity
      return of({
        entityList: [...cached.entityList],
        groupList: [...cached.groupList],
        selectedEntityIds: [...cached.selectedEntityIds],
        selectedEntityGroupIds: [...cached.selectedEntityGroupIds],
      });
    }
  }

  public updateSelections(
    orgId: number,
    facilityId: number,
    entityType: ChartEntityType,
    selectedEntityIds: string[],
    selectedEntityGroupIds: string[]
  ) {
    const cached = this.getFromCache(orgId, facilityId, entityType);
    cached.selectedEntityIds = [...selectedEntityIds];
    cached.selectedEntityGroupIds = [...selectedEntityGroupIds];
  }

  private getCartEntities(
    orgId: number,
    facilityId: number
  ): Observable<EntitiesAndSelections> {
    const cartSearchRequest: Partial<CartSearchRequest> = {
      ProductLines: [ProductLineIds.CareLink],
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
    };

    return forkJoin([
      this.cartApi.searchCartsPartial(
        {
          PageNumber: 1,
          PageSize: 10000,
        },
        cartSearchRequest
      ),
      this.cartGroupApi.search({
        EnterpriseId: this.enterpriseService.enterpriseId,
        OrganizationIds: [orgId],
        FacilityIds: [facilityId],
        PageNumber: 1,
        PageSize: 10000,
      }),
    ]).pipe(
      tap((resp) => {
        if (!resp[1].Success || resp[0] === null || resp[1].Result === null) {
          // ** NOTE: maybe we can approach an error on groups more passively, since they're NOT as
          // critical as the entities themselves, you can continue selecting entities without groups
          // but not the other way around...
          // need to create a method to "bubble up" errors to a subscriber...
          throw new Error('Response success is false or Result is null');
        }
      }),
      map((responses) => {
        const entities = responses[0].map((x) => {
          return { Id: x.Id.toString(), Name: x.Name, SerialNumber: x.SerialNumber } as EntityItem;
        });

        const groups = responses[1].Result.map((x) => {
          const memberIds: string[] = x.Members.map((gMember) =>
            gMember.CartId.toString()
          );
          return {
            Id: x.CartGroupId.toString(),
            Name: x.Description,
            MemberIds: memberIds,
          } as EntityGroupItem;
        });

        const cached = this.upsertToCache(
          orgId,
          facilityId,
          ChartEntityType.Cart,
          entities,
          groups
        );

        // Returning copy (not reference to cache arrays), to ensure cache data integrity
        return {
          entityList: [...cached.entityList],
          groupList: [...cached.groupList],
          selectedEntityIds: [...cached.selectedEntityIds],
          selectedEntityGroupIds: [...cached.selectedEntityGroupIds],
        };
      })
    );
  }

  private getUserEntities(
    orgId: number,
    facilityId: number
  ): Observable<EntitiesAndSelections> {
    // using forkJoin here to be consistent with getting carts, and with the
    // anticipation that there will be a notion of "user groups"
    return forkJoin([this.userApi.getUserList(orgId, facilityId)]).pipe(
      map((responses) => {
        const entities = responses[0].Result.map((x) => {
          return {
            Id: x.Id,
            Name: `${x.FirstName} ${x.LastName}`,
          } as unknown as EntityItem;
        });

        // no "user" groups, yet
        const entityGroups: EntityGroupItem[] = [];

        const cached = this.upsertToCache(
          orgId,
          facilityId,
          ChartEntityType.User,
          entities,
          entityGroups
        );

        // Returning copy (not reference to cache arrays), to ensure cache data integrity
        return {
          entityList: [...cached.entityList],
          groupList: [...cached.groupList],
          selectedEntityIds: [...cached.selectedEntityIds],
          selectedEntityGroupIds: [...cached.selectedEntityGroupIds],
        };
      })
    );
  }

  private getAccessPointEntities(
    orgId: number,
    facilityId: number
  ): Observable<EntitiesAndSelections> {
    // using forkJoin here to be consistent with getting carts, and
    // with the anticipation that there will be a notion of "access point groups"
    return forkJoin([
      this.accessPointApi.search({
        EnterpriseId: this.enterpriseService.enterpriseId,
        PageSize: 10000,
        PageNumber: 1,
        FacilityIds: [facilityId],
      }),
    ]).pipe(
      tap((resp) => {
        if (!resp[0].Success || resp[0].Result === null) {
          throw new Error('Response success is false or Result is null');
        }
      }),
      map((responses) => {
        const entities = responses[0].Result.map((x) => {
          return {
            Id: x.NetworkAccessPointId.toString(),
            Name: x.Name,
          } as EntityItem;
        });

        // no "access point" groups, yet
        const entityGroups: EntityGroupItem[] = [];

        const cached = this.upsertToCache(
          orgId,
          facilityId,
          ChartEntityType.AccessPoint,
          entities,
          entityGroups
        );
        // Returning copy (not reference to cache arrays), to ensure cache data integrity
        return {
          entityList: [...cached.entityList],
          groupList: [...cached.groupList],
          selectedEntityIds: [...cached.selectedEntityIds],
          selectedEntityGroupIds: [...cached.selectedEntityGroupIds],
        };
      })
    );
  }

  private getKey(orgId, facilityId): string {
    return `${orgId}_${facilityId}`;
  }
}
