// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  width: 100%;
}

#btnSave {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/messaging/common/message-template-edit-dialog/message-template-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":["textarea {\n  width: 100%;\n}\n\n#btnSave {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
