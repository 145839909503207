import { Component } from '@angular/core';
import { ChartQueryType } from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-analytics-access',
  templateUrl: './analytics-access.component.html',
  styleUrls: ['./analytics-access.component.scss'],
})
export class AnalyticsAccessComponent {
  public queriesDropDownItems: DropDownItem[] = [
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_SUCCESSFUL_PIN_ENTRIES'
      ),
      Value: ChartQueryType.AccessSuccessfulPINs,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_FAILED_PIN_ENTRIES'),
      Value: ChartQueryType.AccessFailedPINs,
    },
    {
      Label: this.translationService.instant('QUERY_TYPE_ACCESS_LOCKOUTS'),
      Value: ChartQueryType.AccessLockouts,
    },
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_ACCESS_UTILIZATION_TIME_LOGGED_IN'
      ),
      Value: ChartQueryType.TimeLoggedIn,
    },
  ];

  constructor(private translationService: TranslateService) {}
}
