import { Component } from '@angular/core';
import { ActivitySearchRequest, CareLinkActivityTypes } from '@capsa/api';
import { ActivityLogColumnConfig } from 'app/modules/activity-log/activity-log-view/activity-log-view.component';

import { Permissions } from '@capsa/services/permission/permissions-enum';
@Component({
  selector: 'app-cart-update-logs',
  templateUrl: './cart-update-logs.component.html',
  styleUrls: ['./cart-update-logs.component.scss'],
})
export class CartUpdateLogsComponent {
  public perms = Permissions;

  public columns: ActivityLogColumnConfig[] = [
    {
      titleTag: 'COM_MESSAGE',
      field: 'Message',
      fieldType: 'string',
      filterable: true,
    },
    {
      titleTag: 'ACTIVITY_OTA_SENT_ON',
      field: 'ActivityDateUtc',
      fieldType: 'date',
      width: 230,
      filterable: true,
      sort: true,
    },
    {
      titleTag: 'ACTIVITY_OTA_RELEASE_VERSION',
      field: 'Description',
      fieldType: 'string',
      filterable: true,
    },
    {
      titleTag: 'COM_CART_NAME',
      field: 'CartName',
      fieldType: 'string',
      filterable: true,
    },
    {
      titleTag: 'COM_SERIAL_NUMBER',
      field: 'CartSerialNumber',
      fieldType: 'string',
      width: 200,
      filterable: true,
    },
  ];

  public query: Partial<ActivitySearchRequest> = {
    ActivityTypeIds: [
      CareLinkActivityTypes.OtaEvent_AccessoryBoardSent,
      CareLinkActivityTypes.OtaEvent_CCSBoardSent,
    ],
    SortInfo: [{ ColumnName: 'ActivityDateUtc', IsDescending: true }],
  };
}
