import { Injectable } from '@angular/core';
import { ListRowIdNameWithParent, NameWithLongIdApiModel } from '@capsa/api';
import { SetupApi } from '@capsa/api/setup';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FacilityEnterpriseDropDownDataSource extends AbstractDropDownListDataSource<NameWithLongIdApiModel> {
  constructor(private api: SetupApi, private translations: TranslateService) {
    super();
  }

  get defaultItem(): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  public enterpriseId: number;
  public organizationId: number;
  public facilityId: number | undefined;

  protected callApi(): Observable<ListRowIdNameWithParent[]> {
    if (!this.enterpriseId) {
      throw new Error(
        'EnterpriseId cannot be falsy when calling "getFacilitiesDropDown"'
      );
    }

    return this.api.getFacilitiesDropDown(this.enterpriseId).pipe(
      map((data) => {
        // The API call returns ALL facilities for a given enterprise
        // this filters them further by Org ID
        return data.filter((x) => x.ParentId === this.organizationId);
      })
    );
  }
  protected map(
    apiModel: ListRowIdNameWithParent
  ): AsyncDropDownValue<ListRowIdNameWithParent> {
    return {
      id: apiModel.Id.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<ListRowIdNameWithParent>[]
  ): AsyncDropDownValue<ListRowIdNameWithParent> {
    if (this.organizationId === undefined) {
      return undefined;
    }

    if (this.facilityId !== undefined) {
      return items.find((i) => i.apiModel.Id === this.facilityId);
    }
  }

  public reset() {
    super.reset();
    this.facilityId = undefined;
  }
}
