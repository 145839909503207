import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CareLinkLightColor,
  LightColorThemeResponse,
  LightColorThemeUpdateRequest,
  LightConfigurationResponse,
  LightConfigurationUpdateRequest,
} from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lighting-led-theme-config',
  templateUrl: './lighting-led-theme-config.component.html',
  styleUrls: ['./lighting-led-theme-config.component.scss'],
})
export class LightingLedThemeConfigComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  themeName: string;

  @Input()
  themeConfig: LightColorThemeResponse;

  @Input()
  public floorConfig: LightConfigurationResponse;

  @Input()
  public workSurfaceConfig: LightConfigurationResponse;

  @Input()
  public keyboardConfig: LightConfigurationResponse;

  @Output()
  public saveTheme = new EventEmitter<LightColorThemeUpdateRequest>();

  public themeNameInput: UntypedFormControl;
  public form: UntypedFormGroup;
  public colorListItems: DropDownItem[] = [];
  public canEdit = false;

  private subs: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {
    this.fillColors();

    this.themeNameInput = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ]),
    });

    this.form = new UntypedFormGroup({
      themeNameInput: this.themeNameInput,
    });
  }

  ngOnInit() {
    this.loadPermissions();
  }

  ngOnChanges(changes: SimpleChanges) {
    const themeName = changes.themeName;
    if (
      themeName &&
      (themeName.isFirstChange ||
        themeName.previousValue !== themeName.currentValue)
    ) {
      this.themeNameInput.setValue(themeName.currentValue as string);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  private loadPermissions() {
    const editPerm = Permissions.CLI_LightColorTheme_ReadWrite;
    this.canEdit = this.permissionService.has(editPerm);

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        this.canEdit = this.permissionService.has(editPerm);
      })
    );
  }

  get isFormValid() {
    return this.form.valid;
  }

  public colorChanged(
    lightConfig: LightConfigurationResponse,
    itemSelected: DropDownItem
  ) {
    lightConfig.Color = itemSelected.Value as number;
  }

  public btnSaveTheme(): void {
    const themeUpdateRequest: LightColorThemeUpdateRequest = {
      ThemeName: this.themeNameInput.value,
      Configurations: [
        this.getLightConfigUpdateRequest(this.floorConfig),
        this.getLightConfigUpdateRequest(this.workSurfaceConfig),
        this.getLightConfigUpdateRequest(this.keyboardConfig),
      ],
    };
    this.saveTheme.emit(themeUpdateRequest);
  }

  private getLightConfigUpdateRequest(
    config: LightConfigurationResponse
  ): LightConfigurationUpdateRequest {
    const lightConfigUpdateRequest: LightConfigurationUpdateRequest = {
      LightConfigurationId: config.LightConfigurationId,
      Intensity: config.Intensity,
      Color: config.Color,
    };
    return lightConfigUpdateRequest;
  }

  private fillColors(): void {
    for (const enumMember in CareLinkLightColor) {
      if (isNaN(enumMember as any)) {
        const ucName = enumMember.toUpperCase();
        this.colorListItems.push({
          Label: this.translateService.instant(
            `ENUM_CARELINKLIGHTCOLOR_${ucName}`
          ),
          Value: CareLinkLightColor[enumMember] as unknown as number,
        });
      }
    }
  }
}
