import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageQueueRequestTypeResponse } from '@capsa/api';
import { MessageRequestTypeApi } from '@capsa/api/message-request-type';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { RowArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user-request-types',
  templateUrl: './edit-user-request-types-tab.component.html',
  styleUrls: ['./edit-user-request-types-tab.component.scss'],
})
export class EditUserRequestTypesTabComponent implements OnInit, OnDestroy {
  public availableRequestTypes: MessageQueueRequestTypeResponse[] = [];
  public assignedRequestTypes: MessageQueueRequestTypeResponse[] = [];
  public availableListLoading = true;
  public userAssignedListLoading = true;
  public selectedAvailable: any[] = [];
  public selectedAssigned: any[] = [];
  private assignedTypesGridSelectionService: GridSelectionService;
  private availableTypesGridSelectionService: GridSelectionService;

  @Input()
  public organizationId: number;
  private subs: Subscription = new Subscription();
  constructor(
    private requestApi: MessageRequestTypeApi,
    private gridSelectionServiceFactory: GridSelectionServiceFactory,
    private editUserService: EditUserService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.assignedTypesGridSelectionService =
      this.gridSelectionServiceFactory.build(this.selectRowIds);

    this.availableTypesGridSelectionService =
      this.gridSelectionServiceFactory.build(this.selectRowIds);

    this.subs.add(
      this.availableTypesGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedAvailable = newSelection;
        }
      )
    );

    this.subs.add(
      this.assignedTypesGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedAssigned = newSelection;
        }
      )
    );

    this.refreshRequestTypeLists();
  }

  private selectRowIds(rows: RowArgs[]) {
    return rows.map((row) => row.dataItem.MessageQueueRequestTypeId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onAvailableSelectionChange(changes: SelectionEvent) {
    this.availableTypesGridSelectionService.updateSelection(changes);
  }

  public onAssignedSelectionChange(changes: SelectionEvent) {
    this.assignedTypesGridSelectionService.updateSelection(changes);
  }

  private refreshRequestTypeLists() {
    this.availableListLoading = true;
    const sub = this.requestApi
      .searchRequestTypes(
        this.organizationId,
        this.editUserService.selectedFacilityId
      )
      .subscribe(
        (resp) => {
          this.refreshAvailableTypesList(resp.Result);
        },
        (error) => {
          this.availableListLoading = false;
          this.toasterService.showError('GENERIC_GET_LIST_FAILED');
        }
      );
    this.subs.add(sub);
  }

  private refreshAvailableTypesList(list: MessageQueueRequestTypeResponse[]) {
    list.sort((a, b) => {
      return a.MessageQueueRequestTypeId - b.MessageQueueRequestTypeId;
    });

    // This should return SOMETHING IF assignements were modified during this "edit user instance"
    // e.g. an empty array will be returned for when user was unassigned from all request types for this facility
    // or partially filled if some items were assigned during this "edit user instance"
    const pendingRequestTypes = this.editUserService.getPendingRequestTypes();

    // Don't grab users request types from API, we have some pending already (must use those)
    if (pendingRequestTypes) {
      this.availableRequestTypes = list;
      this.availableListLoading = false;
      this.fillUsersRequestTypes(pendingRequestTypes);
    } else {
      this.userAssignedListLoading = true;
      this.subs.add(
        this.requestApi
          .getUserRequestTypes(this.editUserService.userId)
          .subscribe(
            (resp) => {
              // we fill the available request types later, so we don't see things jumping around the screen
              this.availableRequestTypes = list;
              this.availableListLoading = false;
              this.fillUsersRequestTypes(
                resp.Result.map((x) => x.MessageQueueRequestTypeId)
              );
              this.userAssignedListLoading = false;
            },
            (error) => {
              this.userAssignedListLoading = false;
              this.availableListLoading = false;
              this.toasterService.showError('USER_REQUEST_TYPES_FAIL');
            }
          )
      );
    }
  }

  private fillUsersRequestTypes(assignedRequestTypeIds: number[]) {
    assignedRequestTypeIds.sort((a, b) => {
      return a - b;
    });
    const tempUsersTypes = [];
    const tempAvailTypes = this.availableRequestTypes;

    assignedRequestTypeIds.forEach((x) => {
      const match = this.availableRequestTypes.find(
        (y) => y.MessageQueueRequestTypeId === x
      );

      if (match) {
        tempUsersTypes.push({
          MessageQueueRequestTypeId: match.MessageQueueRequestTypeId,
          Description: match.Description,
        });
      }

      this.assignedRequestTypes = tempUsersTypes;
    });

    this.availableRequestTypes = [];

    this.assignedRequestTypes.forEach((x) => {
      const matchedIndex = tempAvailTypes.findIndex(
        (y) => y.MessageQueueRequestTypeId === x.MessageQueueRequestTypeId
      );
      if (matchedIndex >= 0) {
        tempAvailTypes.splice(matchedIndex, 1);
      }
    });

    this.availableRequestTypes = tempAvailTypes;
  }

  public btnTriggerMoveSelectedToAssigned() {
    if (!this.selectedAvailable.length) {
      return;
    }

    this.selectedAvailable.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableRequestTypes;
    const tempUserTypes = this.assignedRequestTypes;

    this.availableRequestTypes = [];
    this.assignedRequestTypes = [];

    do {
      const iAv = this.selectedAvailable.pop();
      const typeToMoveIdx = tempAvail.findIndex(
        (x) => x.MessageQueueRequestTypeId === iAv
      );
      const typeToMove = tempAvail.splice(typeToMoveIdx, 1)[0];
      tempUserTypes.push(typeToMove);
    } while (this.selectedAvailable.length);

    tempUserTypes.sort((a, b) => {
      return a.MessageQueueRequestTypeId - b.MessageQueueRequestTypeId;
    });

    this.availableRequestTypes = tempAvail;
    this.assignedRequestTypes = tempUserTypes;
    this.availableTypesGridSelectionService.clearSelection();
    this.editUserService.savePendingRequestTypes(
      this.assignedRequestTypes.map((x) => x.MessageQueueRequestTypeId)
    );
  }

  public btnTriggerMoveAssignedToAvailable() {
    if (!this.selectedAssigned.length) {
      return;
    }

    this.selectedAssigned.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableRequestTypes;
    const tempUserTypes = this.assignedRequestTypes;

    this.availableRequestTypes = [];
    this.assignedRequestTypes = [];

    do {
      const iAv = this.selectedAssigned.pop();
      const typeToMoveIdx = tempUserTypes.findIndex(
        (x) => x.MessageQueueRequestTypeId === iAv
      );
      const typeToMove = tempUserTypes.splice(typeToMoveIdx, 1)[0];
      tempAvail.push(typeToMove);
    } while (this.selectedAssigned.length);

    tempAvail.sort((a, b) => {
      return a.MessageQueueRequestTypeId - b.MessageQueueRequestTypeId;
    });

    this.availableRequestTypes = tempAvail;
    this.assignedRequestTypes = tempUserTypes;
    this.assignedTypesGridSelectionService.clearSelection();
    this.editUserService.savePendingRequestTypes(
      this.assignedRequestTypes.map((x) => x.MessageQueueRequestTypeId)
    );
  }
}
