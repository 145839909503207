import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { ActivityLogViewComponent } from 'app/modules/activity-log/activity-log-view/activity-log-view.component';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';

@NgModule({
  declarations: [ActivityLogViewComponent],
  imports: [
    ButtonsModule,
    CommonModule,
    GridModule,
    PipesModule,
    CapsaDropdownsModule,
    TranslateModule.forChild(),
    CustomInputsModule,
    LabelsModule,
  ],
  exports: [ActivityLogViewComponent],
})
export class ActivityLogModule {}
