import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeZoneResponse } from '@capsa/api/common';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { ApiErrorResponse } from '..';
import { BaseApi } from '../base/base.api';

export interface CommonApiInstance {
  getTimeZones(): Observable<TimeZoneResponse[] | ApiErrorResponse>;
}

@Injectable()
export class CommonApi extends BaseApi implements CommonApiInstance {
  protected endpoints = {
    getTimeZones: this.buildUrl('common/timezone'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getTimeZones(): Observable<TimeZoneResponse[] | ApiErrorResponse> {
    return this.http.get<TimeZoneResponse[] | ApiErrorResponse>(
      this.endpoints.getTimeZones
    );
  }
}
