import { Injectable } from '@angular/core';
import { EnumApi } from '@capsa/api/enum/enum.api';
import { DropDownItem, EnumTypes } from '@capsa/dropdowns/enum';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  private enumCache = new Map<EnumTypes, Observable<DropDownItem[]>>();
  constructor(private enumApi: EnumApi) {}

  public getEnum(enumName: EnumTypes): Observable<DropDownItem[]> {
    if (this.enumCache.has(enumName)) {
      return this.enumCache.get(enumName);
    }
    const resp = this.enumApi.getEnum(enumName).pipe(
      map((response) => response.EnumValues),
      publishReplay(1), // this tells Rx to cache the latest emitted
      refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
    );
    this.enumCache.set(enumName, resp);

    return resp;
  }
}
