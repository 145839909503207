import { Injectable } from '@angular/core';
import { AuthService } from '@capsa/services/auth/auth.service';
import { Constants } from 'app/common/constants';

export interface UserMemory {
  orgId?: number;
  facilityId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserMemoryService {
  private get userKey() {
    return `uid_${this.authService.currentUser.UserId}`;
  }

  private cachedAllUserMemory: Map<string, UserMemory>;

  public get userMemory() {
    return this.getUserMemory();
  }

  constructor(private authService: AuthService) {}

  public setUserLastOrgFacility(orgId: number, facilityId: number): void {
    const userMemory = this.getUserMemory();

    userMemory.orgId = orgId;
    userMemory.facilityId = facilityId;

    this.saveUserMemory(userMemory);
  }

  private saveUserMemory(data: UserMemory): void {
    if (!this.cachedAllUserMemory) {
      this.refreshCache();
    }

    this.cachedAllUserMemory.set(this.userKey, data);
    this.saveCacheToStorage();
  }

  private getUserMemory(): UserMemory {
    if (!this.cachedAllUserMemory) {
      this.refreshCache();
    }

    const curUserMemory = this.cachedAllUserMemory.get(this.userKey);

    if (curUserMemory) {
      return curUserMemory;
    } else {
      return {} as UserMemory;
    }
  }

  private refreshCache(): void {
    try {
      this.cachedAllUserMemory = new Map<string, UserMemory>(
        JSON.parse(localStorage.getItem(Constants.LocalStorageKeys.userMemory))
      );
    } catch {
      this.cachedAllUserMemory = new Map<string, UserMemory>();
    }
  }

  private saveCacheToStorage(): void {
    localStorage.setItem(
      Constants.LocalStorageKeys.userMemory,
      JSON.stringify([...this.cachedAllUserMemory])
    );
  }
}
