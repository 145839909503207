import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CartAdvancedUpdateGridItem,
  CartFriendlyUpdateGridItem,
  CartLabelDisplayMode,
  CartResponse,
  DeviceUpdateType,
  FirmwareVersionDetailsResponse,
  FirmwareVersionDetailsResponseGridItem,
  UpdateStatusType,
} from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DataStateChangeEvent,
  GridDataResult,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Constants } from 'app/common/constants';
import { Utils } from 'app/common/utils';
import { SharedDeviceUpdateService } from 'app/modules/device-update/device-update.service';
import { ApplyDeviceUpdateService } from 'app/modules/update-cart-firmware/apply-device-update.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-update-cart-firmware-advanced',
  templateUrl: './update-advanced.component.html',
  styleUrls: ['./update-advanced.component.scss'],
})
export class UpdateCartFirmwareAdvancedComponent implements OnInit, OnDestroy {
  @Input()
  public cartDisplayMode: CartLabelDisplayMode;

  @Input()
  public updateType: BehaviorSubject<DeviceUpdateType>;

  public displayMode = CartLabelDisplayMode;

  public DeviceUpdateType = DeviceUpdateType;

  public cartsGridLoading = false;
  public fwVersionListLoading = false;

  private fmVersions: FirmwareVersionDetailsResponseGridItem[] = [];
  public dialogCartsList: CartAdvancedUpdateGridItem[] = [];

  /**
   * Carts with CCS 2.73 or later
   */
  public cartsAvailableToUpdateNewCcs: CartAdvancedUpdateGridItem[] = [];

  /**
   * Carts older than CCS 2.73
   */
  public cartsAvailableToUpdateOldCcs: CartAdvancedUpdateGridItem[] = [];
  public cartsInvalidData: CartAdvancedUpdateGridItem[] = [];
  public cartsUpdatePending: CartAdvancedUpdateGridItem[] = [];

  private get mainGridCarts(): CartAdvancedUpdateGridItem[] {
    if (this.isAccessoryBoardUpdateType) {
      return this.cartsAvailableToUpdateNewCcs;
    }

    return [
      ...this.cartsAvailableToUpdateNewCcs,
      ...this.cartsAvailableToUpdateOldCcs,
    ];
  }

  public get isAccessoryBoardUpdateType(): boolean {
    switch (this.updateType.value) {
      case DeviceUpdateType.AccessoryDistBoard:
      case DeviceUpdateType.AccessoryLockingBoard:
      case DeviceUpdateType.AccessoryTaskLightBoard:
        return true;
      default:
        return false;
    }
  }

  /**
   * Keeps track of which facilityId there's currently an API call pending for
   * If no API call is pending the value should be falsy
   */
  private loadingFacilityId = 0;

  public isDialogOpen = false;
  public dialogTitle: string;
  public dialogMsg: string;

  public readonly pageSize = 100;

  public availableGridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public fmVersionsGridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public availableState = Utils.getDefaultState(this.pageSize);
  public fmVersionState = Utils.getDefaultState(this.pageSize);

  public cartKeysSelected: number[] = [];
  public fmVersionKeysSelected: number[] = [];

  public get selectedCartId() {
    return this.cartKeysSelected.length > 0 ? this.cartKeysSelected[0] : null;
  }

  public get selectedFmVersionId() {
    return this.fmVersionKeysSelected.length > 0
      ? this.fmVersionKeysSelected[0]
      : null;
  }

  private subs: Subscription = new Subscription();

  constructor(
    private permissionService: PermissionService,
    private toasterService: ToasterService,
    private deviceUpdateService: SharedDeviceUpdateService,
    private applyDeviceUpdateService: ApplyDeviceUpdateService,
    private firmHardWareApi: FirmHardWareApi,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) {}

  public ngOnInit() {
    this.refreshFirmwareVersionList();

    // Because this component is nested in a parent component that will
    // show/hide it using *ngIf, it will be destroyed when the facility
    // drop down is set to "Select a value..."
    // This may cause it to miss the "facility changed" event below
    // And it will end up missing the facility changed event
    if (this.permissionService.facilityId) {
      this.refreshCartsList();
    }

    this.subs.add(
      this.permissionService.facilityChanged$.subscribe((x) => {
        this.refreshCartsList();
      })
    );

    this.subs.add(
      this.updateType.subscribe(() => {
        if (
          this.selectedCartId &&
          this.isAccessoryBoardUpdateType &&
          this.cartsAvailableToUpdateOldCcs.some(
            (oldCart) => oldCart.CartId === this.selectedCartId
          )
        ) {
          // if switching to an "Accessory" update type, and an "old CCS board" cart was selected, then clear the selection
          this.cartKeysSelected = [];
        }

        this.resetFmVersionGrid();
        this.refreshFirmwareVersionList();
      })
    );

    this.refreshCartsList();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private resetCartGrid(triggerReprocess = true) {
    // Reset lists
    this.cartsAvailableToUpdateNewCcs = [];
    this.cartsAvailableToUpdateOldCcs = [];
    this.cartsUpdatePending = [];
    this.cartsInvalidData = [];
    this.cartKeysSelected = [];

    this.availableState = Utils.getDefaultState(this.pageSize);

    if (triggerReprocess) {
      this.reprocess();
    }
  }

  private resetFmVersionGrid(triggerReprocess = true) {
    this.fmVersions = [];
    this.fmVersionKeysSelected = [];

    this.fmVersionState = Utils.getDefaultState(this.pageSize);

    if (triggerReprocess) {
      this.reprocess();
    }
  }

  private refreshCartsList() {
    if (
      this.loadingFacilityId &&
      this.loadingFacilityId === this.permissionService.facilityId
    ) {
      return;
    }

    this.resetCartGrid();

    if (!this.permissionService.orgId || !this.permissionService.facilityId) {
      this.availableGridData = { data: [], total: 0 };
      return;
    }

    this.cartsGridLoading = true;

    this.loadingFacilityId = this.permissionService.facilityId;
    this.subs.add(
      this.applyDeviceUpdateService
        .getCartsAndUpdatesList(true)
        .pipe(
          finalize(() => {
            this.cartsGridLoading = false;
            this.loadingFacilityId = 0;
          })
        )
        .subscribe(
          (resp) => {
            this.setGridData(resp);
          },
          (error) => {
            this.toasterService.showError('ERROR_GETTING_CARTS_AND_UPDATES');
          }
        )
    );
  }

  private isOldCcsVersion(c: CartAdvancedUpdateGridItem): boolean {
    const newMajor = 2;
    const newMinor = 74;

    const version = this.extractVersionUseBspFormat(c.bspVersion);
    const extractedMajor = version[0];
    const extractedMinor = version[1];

    if (extractedMajor > newMajor) {
      return false;
    } else if (extractedMajor < newMajor) {
      return true;
    } else if (extractedMinor < newMinor) {
      return true;
    }

    return false;
  }

  private setGridData(carts: CartFriendlyUpdateGridItem[]) {
    const gridCarts = this.setVersionsAndPartNumbers(carts);

    gridCarts.forEach((c) => {
      if (c.ccsStatus === UpdateStatusType.Unknown) {
        this.cartsInvalidData.push(c);
        return;
      }

      if (c.isUpdatePending) {
        this.cartsUpdatePending.push(c);
        return;
      }

      if (this.isOldCcsVersion(c)) {
        this.cartsAvailableToUpdateOldCcs.push(c);
      } else {
        this.cartsAvailableToUpdateNewCcs.push(c);
      }
    });
    this.reprocess();
  }

  private setVersionsAndPartNumbers(
    carts: CartResponse[]
  ): CartAdvancedUpdateGridItem[] {
    const gridCarts: CartAdvancedUpdateGridItem[] =
      carts as CartAdvancedUpdateGridItem[];

    gridCarts.forEach((c) => {
      c.bspVersion = c.ComponentValues?.get(Constants.Key_BSPVersion);
      c.distBoardVersion = c.ComponentValues?.get(
        Constants.Key_DistributionBoardApplicationVersion
      );

      c.taskLightBoardVersion = c.ComponentValues?.get(
        Constants.Key_TaskLightBoardApplicationVersion
      );

      c.lockBoard1Version = c.ComponentValues?.get(
        Constants.Key_LockBoard1ApplicationVersion
      );

      c.lockBoard2Version = c.ComponentValues?.get(
        Constants.Key_LockBoard2ApplicationVersion
      );

      c.lockBoard3Version = c.ComponentValues?.get(
        Constants.Key_LockBoard3ApplicationVersion
      );

      c.distBoardPn = c.ComponentValues?.get(Constants.Key_DistBoardPartNumber);

      c.taskLightBoardPn = c.ComponentValues?.get(
        Constants.Key_TaskBoardPartNumber
      );

      c.lockBoardPn = c.ComponentValues?.get(Constants.Key_LockBoardPartNumber);
    });

    return gridCarts;
  }

  private reprocess() {
    this.availableGridData = process(this.mainGridCarts, this.availableState);

    this.fmVersionsGridData = process(this.fmVersions, this.fmVersionState);
  }

  public availableStateChange(state: DataStateChangeEvent): void {
    this.availableState = state;
    this.availableGridData = process(this.mainGridCarts, this.availableState);
  }

  public fmVersionStateChange(state: DataStateChangeEvent): void {
    this.fmVersionState = state;
    this.fmVersionsGridData = process(this.fmVersions, this.fmVersionState);
  }

  public onCartSelectionChanged(change: SelectionEvent) {
    const prevCart = this.cartsAvailableToUpdateNewCcs.find(
      (x) => x.CartId === this.selectedCartId
    );
    let selectedCart: CartAdvancedUpdateGridItem;
    if (change.selectedRows.length > 0) {
      selectedCart = change.selectedRows[0]
        .dataItem as CartAdvancedUpdateGridItem;
      this.cartKeysSelected = [selectedCart.CartId];
    } else {
      this.cartKeysSelected = [];
      this.refreshFirmwareVersionList(true);
      return;
    }

    if (prevCart) {
      switch (this.updateType.value) {
        case DeviceUpdateType.AccessoryDistBoard:
          if (selectedCart.distBoardPn !== prevCart.distBoardPn) {
            this.refreshFirmwareVersionList();
          }
          break;
        case DeviceUpdateType.AccessoryTaskLightBoard:
          if (selectedCart.taskLightBoardPn !== prevCart.taskLightBoardPn) {
            this.refreshFirmwareVersionList();
          }
          break;
        case DeviceUpdateType.AccessoryLockingBoard:
          if (selectedCart.lockBoardPn !== prevCart.lockBoardPn) {
            this.refreshFirmwareVersionList();
          }
          break;
        case DeviceUpdateType.Firmware:
        default:
          break;
      }
      // If type was CCS, then don't refresh FirmwareVersionList
      // IF type was accessory board, copmare the PN's of the carts for the update type, if different, then only then refresh the firmware Version
    } else if (this.updateType.value !== DeviceUpdateType.Firmware) {
      this.refreshFirmwareVersionList();
    }
  }

  public onFmVersionSelectionChanged(change: SelectionEvent) {
    if (change.selectedRows.length > 0) {
      const selectedVersion = change.selectedRows[0]
        .dataItem as FirmwareVersionDetailsResponseGridItem;
      this.fmVersionKeysSelected = [selectedVersion.FirmwareVersionId];
    } else {
      this.fmVersionKeysSelected = [];
    }
  }

  private refreshFirmwareVersionList(preserveSelection = false) {
    this.fwVersionListLoading = true;

    if (!preserveSelection) {
      this.fmVersionKeysSelected = [];
    }
    this.deviceUpdateService.setUpdateType(this.updateType.value);
    this.subs.add(
      this.deviceUpdateService
        .getFwVersionList()
        .pipe(
          finalize(() => {
            this.fwVersionListLoading = false;
          })
        )
        .subscribe(
          (resp) => {
            let tempVersions = resp.map((x) => this.fmVersionToGridItem(x));

            // If cart selected, then filter by PN
            if (this.selectedCartId) {
              const selectedCart = this.mainGridCarts.find(
                (x) => x.CartId === this.selectedCartId
              );
              switch (this.updateType.value) {
                case DeviceUpdateType.AccessoryDistBoard:
                  tempVersions = tempVersions.filter(
                    (v) => v.PartNumber === selectedCart.distBoardPn
                  );
                  break;
                case DeviceUpdateType.AccessoryTaskLightBoard:
                  tempVersions = tempVersions.filter(
                    (v) => v.PartNumber === selectedCart.taskLightBoardPn
                  );
                  break;
                case DeviceUpdateType.AccessoryLockingBoard:
                  tempVersions = tempVersions.filter(
                    (v) => v.PartNumber === selectedCart.lockBoardPn
                  );
                  break;
                case DeviceUpdateType.Firmware:
                  // nothing to do, only one PN exists
                  break;
                default:
                  throw 'Unexpected UpdateType found - cannot continue';
              }
            }

            // If cart not selected, show ALL

            this.fmVersions = tempVersions;
            this.reprocess();
          },
          (error) => {
            this.toasterService.showError('COM_GET_LIST_FAILED');
          }
        )
    );
  }

  private fmVersionToGridItem(
    fmvResp: FirmwareVersionDetailsResponse
  ): FirmwareVersionDetailsResponseGridItem {
    const gridItem = fmvResp as FirmwareVersionDetailsResponseGridItem;

    gridItem.PartNumber = fmvResp.Firmware.PartNumber;

    return gridItem;
  }

  public updateCart() {
    this.loaderService.start();
    this.subs.add(
      this.firmHardWareApi
        .advancedUpdateCart([this.selectedCartId], this.selectedFmVersionId)
        .pipe(
          finalize(() => {
            this.loaderService.stop();
          })
        )
        .subscribe(
          () => {
            this.toasterService.showSuccess('CART_UPDATE_SENT_SUCCESS');
            this.refreshCartsList();
          },
          (error) => {
            this.toasterService.showError('CART_UPDATE_FAILED');
          }
        )
    );
  }

  private extractVersionUseBspFormat(
    deviceVersion: string
  ): [number, number, string | null] {
    const fallbackVersion: [number, number, string | null] = [-1, -1, null];
    if (!deviceVersion) {
      return fallbackVersion;
    }

    const versionRegex = /(\d*)\.(\d*)(.*)/;
    const matches = deviceVersion.match(versionRegex);

    if (!matches) {
      return fallbackVersion;
    }

    const major = parseInt(matches[1], 10);
    const minor = parseInt(matches[2], 10);
    const suffix = matches[4];

    return [major, minor, suffix];
  }

  public openDialogOldCcsVersion() {
    this.dialogTitle = this.translateService.instant(
      'CARTS_OLD_CCS_VERSION_TITLE'
    );
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_OLD_CCS_BOARD_VERSION'
    );
    this.dialogCartsList = this.cartsAvailableToUpdateOldCcs;
    this.isDialogOpen = true;
  }

  public openDialogUpdatePending() {
    this.dialogTitle = this.translateService.instant('CARTS_UPDATES_PENDING');
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_UPDATES_PENDING'
    );
    this.dialogCartsList = this.cartsUpdatePending;
    this.isDialogOpen = true;
  }

  public openDialogInvalidData() {
    this.dialogTitle = this.translateService.instant('CARTS_INVALID_DATA');
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_INVALID_DATA'
    );
    this.dialogCartsList = this.cartsInvalidData;
    this.isDialogOpen = true;
  }

  public onDialogClosed() {
    this.isDialogOpen = false;
    this.dialogTitle = null;
    this.dialogMsg = null;
    this.dialogCartsList = [];
  }
}
