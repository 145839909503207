import { Injectable } from '@angular/core';
import {
  ApiResponse,
  EnterpriseSearchRequest,
  SettingResponse,
} from '@capsa/api';
import { BaseApi } from '@capsa/api/base';

export interface EnterpriseResponse {
  State: string | undefined;
  Phone: string | undefined;
  Name: string;
  LanguageId: number;
  IsActive: boolean;
  EnterpriseId: number;
  DefaultUserPassword: string;
  CountryId: number;
  Country: string;
  ContactPerson: string | undefined;
  ContactEmail: string | undefined;
  City: string | undefined;
  Address2: string | undefined;
  Address1: string | undefined;
  ZipCode: string | undefined;
  Settings: SettingResponse[] | undefined;
}

@Injectable()
export class EnterpriseApi extends BaseApi {
  protected endpoints = {
    getById: '/enterprise/GetById',
    search: '/NexsysSetup/Search',
  };

  public getById(enterpriseId: number) {
    const body = {
      Id: enterpriseId,
    };
    const url = this.buildUrl(this.endpoints.getById);
    return this.http.post<ApiResponse<EnterpriseResponse>>(url, body);
  }

  public search(query: Partial<EnterpriseSearchRequest>) {
    const body = {
      ...query,
    };
    const url = this.buildUrl(this.endpoints.search);
    return this.http.post<ApiResponse<EnterpriseResponse[]>>(url, body);
  }
}
