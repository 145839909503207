import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DeviceTypeApiModel,
  DeviceTypeIds,
  NameWithLongIdApiModel,
  ProductLineIds,
} from '@capsa/api';
import { SetupApi } from '@capsa/api/setup';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';
import { EnterpriseDropDownDataSource } from '@capsa/dropdowns/enterprise-drop-down/enterprise-drop-down-data-source';
import { FacilityEnterpriseDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-enterprise-drop-down-data-source';
import { OrganizationEnterpriseDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-enterprise-drop-down-data-source';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-eof-drop-downs',
  templateUrl: './eof-drop-downs.component.html',
  styleUrls: ['./eof-drop-downs.component.scss'],
})
export class EofDropDownsComponent implements OnInit, OnDestroy {
  public get includeDeviceType() {
    return this.deviceTypeChanged.observers.length > 0;
  }

  public enterpriseOrgDropDownDataSource: OrganizationEnterpriseDropDownDataSource;
  public enterpriseFacilityDropDownDataSource: FacilityEnterpriseDropDownDataSource;

  constructor(
    public enterpriseDataSource: EnterpriseDropDownDataSource,
    public deviceTypeDataSource: DeviceTypeDropDownDataSource,
    private translateService: TranslateService,
    private setupApi: SetupApi
  ) {
    /**
     * In this case we don't want the data source as a singleton because there is another drop down
     * that uses the same data source but they need to be isolated from each other since one is
     * the "source org" and the other is the "target org" drop down
     */
    this.enterpriseOrgDropDownDataSource =
      new OrganizationEnterpriseDropDownDataSource(
        this.setupApi,
        this.translateService
      );

    this.enterpriseFacilityDropDownDataSource =
      new FacilityEnterpriseDropDownDataSource(
        this.setupApi,
        this.translateService
      );
  }

  @Output()
  public enterpriseIdChanged = new EventEmitter<number | undefined>();

  @Output()
  public organizationIdChanged = new EventEmitter<number | undefined>();

  @Output()
  public facilityIdChanged = new EventEmitter<number | undefined>();

  @Output()
  public deviceTypeChanged = new EventEmitter<number | undefined>();

  private subs = new Subscription();

  ngOnInit() {
    this.loadDeviceTypes();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public enterpriseChanged(
    newValue: AsyncDropDownValue<NameWithLongIdApiModel>
  ) {
    const newEntId = !newValue.apiModel ? undefined : newValue.apiModel.Id;
    this.enterpriseDataSource.enterpriseId = newEntId;
    this.enterpriseIdChanged.emit(newEntId);

    if (newEntId) {
      this.enterpriseOrgDropDownDataSource.enterpriseId = newEntId;
      this.enterpriseOrgDropDownDataSource.organizationId = undefined;
      this.subs.add(
        this.enterpriseOrgDropDownDataSource
          .resetAndLoad()
          .subscribe((items) => {
            this.enterpriseOrgDropDownDataSource.data = items;
            if (items.length === 1) {
              this.orgChanged(items[0]);
            }
          })
      );
      this.resetFacilityDropDown(newEntId, undefined);
    } else {
      this.enterpriseOrgDropDownDataSource.enterpriseId = undefined;
      this.enterpriseOrgDropDownDataSource.organizationId = undefined;
      this.enterpriseOrgDropDownDataSource.reset();
      this.resetFacilityDropDown(newEntId, undefined);
    }
  }

  public orgChanged(newValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    const newOrgId = !newValue.apiModel ? undefined : newValue.apiModel.Id;

    this.enterpriseOrgDropDownDataSource.organizationId = newOrgId;
    this.enterpriseFacilityDropDownDataSource.organizationId = newOrgId;

    if (newOrgId) {
      this.subs.add(
        this.enterpriseFacilityDropDownDataSource
          .resetAndLoad()
          .subscribe((items) => {
            this.enterpriseFacilityDropDownDataSource.data = items;
            if (items.length === 1) {
              this.facilityChanged(items[0]);
            }
          })
      );
    } else {
      this.enterpriseFacilityDropDownDataSource.reset();
      this.facilityIdChanged.emit(undefined);
    }

    this.organizationIdChanged.emit(
      this.enterpriseFacilityDropDownDataSource.organizationId
    );
  }

  public facilityChanged(newValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    this.enterpriseFacilityDropDownDataSource.facilityId = !newValue.apiModel
      ? undefined
      : newValue.apiModel.Id;

    this.facilityIdChanged.emit(
      this.enterpriseFacilityDropDownDataSource.facilityId
    );
  }

  private resetFacilityDropDown(
    entId: number | undefined,
    orgId: number | undefined
  ) {
    this.enterpriseFacilityDropDownDataSource.enterpriseId = entId;
    this.enterpriseFacilityDropDownDataSource.organizationId = orgId;
    this.enterpriseFacilityDropDownDataSource.reset();
  }

  public onDeviceTypeChanged(newValue: AsyncDropDownValue<DeviceTypeApiModel>) {
    const newDeviceType = !newValue.apiModel
      ? undefined
      : newValue.apiModel.DeviceTypeId;

    this.deviceTypeChanged.emit(newDeviceType);
  }

  private loadDeviceTypes() {
    this.subs.add(
      this.deviceTypeDataSource.load().subscribe((items) => {
        this.deviceTypeDataSource.data = [this.allDeviceTypeOption].concat(
          items
        );
        this.deviceTypeDataSource.selectItem(this.allDeviceTypeOption);
        this.deviceTypeChanged.emit(parseInt(this.allDeviceTypeOption.id, 10));
      })
    );
  }

  private get allDeviceTypeOption() {
    return {
      id: DeviceTypeIds.All.toString(),
      name: this.translateService.instant('COM_ALL'),
      apiModel: {
        DeviceTypeId: DeviceTypeIds.All,
        ProductLineId: ProductLineIds.CareLink,
      } as any,
    } as AsyncDropDownValue<DeviceTypeApiModel>;
  }
}
