import { Component } from '@angular/core';
import { ChartQueryType } from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-analytics-access-points',
  templateUrl: './analytics-access-points.component.html',
  styleUrls: ['./analytics-access-points.component.scss'],
})
export class AnalyticsAccessPointsComponent {
  public queriesDropDownItems: DropDownItem[] = [
    {
      Label: this.translationService.instant(
        'QUERY_TYPE_ACCESS_POINT_CONNECTIVITY'
      ),
      Value: ChartQueryType.LocationConnectivity,
    },
  ];

  constructor(private translationService: TranslateService) {}
}
