import { Component, OnDestroy } from '@angular/core';
import { NetworkAccessPointResponse } from '@capsa/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-tracking',
  templateUrl: './device-tracking.component.html',
  styleUrls: ['./device-tracking.component.scss'],
})
export class DeviceTrackingComponent implements OnDestroy {
  public accessPointId: number | undefined;
  private accessPoint: NetworkAccessPointResponse | undefined;

  private subs = new Subscription();

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onAccessPointChanged(
    newValue: NetworkAccessPointResponse | undefined
  ) {
    this.accessPoint = newValue;
    this.accessPointId = this.accessPoint
      ? this.accessPoint.NetworkAccessPointId
      : undefined;
  }
}
