import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  FacilityUpdateRequest,
  NameWithStringIdApiModel,
  NotificationGroupResponse,
  NotificationGroupUpdateRequest,
  NotificationType,
  SettingResponse,
} from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { TimezoneDropDownDataSource } from '@capsa/dropdowns/timezone-drop-down/timezone-drop-down-datasource';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-display-and-alerts',
  templateUrl: './facility-manage-display-and-alerts.component.html',
  styleUrls: ['./facility-manage-display-and-alerts.component.scss'],
})
export class FacilityManageDisplayAndAlertsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  @Input()
  orgId: number;

  @Input()
  facilityId: number;

  @Input()
  public notificationGroups: NotificationGroupResponse[];

  @Input()
  facilitySaving$: Observable<FacilityUpdateRequest>;

  @Output()
  public formValidStatus = new EventEmitter<boolean>();

  private get alertNotificationGroups(): NotificationGroupResponse[] {
    return this.notificationGroups.filter((ng) =>
      this.isAlertNotificationType(ng.NotificationType)
    );
  }

  public batteryAlertRed: boolean;
  public batteryAlertYellow: boolean;
  public connectivityAlertRed: boolean;
  public connectivityAlertYellow: boolean;
  public securityAlertRed: boolean;
  public securityAlertYellow: boolean;
  public utilizationAlertRed: boolean;
  public utilizationAlertYellow: boolean;

  public isLoading: boolean;
  public isLoadingTimeZones: boolean;
  public isSaving: boolean;

  public alertEmailForm: UntypedFormGroup;

  private subs = new Subscription();
  private alertEmailFormControl: UntypedFormControl;

  // To expose the enum to the template
  public alertTypesEnum = NotificationType;
  constructor(
    public timezoneDropDownDataSource: TimezoneDropDownDataSource,
    private toasterService: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      this.facilitySaving$.subscribe((request) => {
        if (!request) {
          return;
        }

        request.NotificationGroups = request.NotificationGroups.concat(
          this.generateAlertsToSave()
        );
      })
    );
    this.alertEmailFormControl = new UntypedFormControl(
      '',
      Validators.compose([
        Validators.email,
        this.alertEmailValidator,
        Validators.maxLength(256),
        NsightCustomValidators.isEmailExtraChecksValid(),
      ])
    );
    this.alertEmailForm = new UntypedFormGroup({
      alertEmailFormControl: this.alertEmailFormControl,
    });

    this.subs.add(
      this.alertEmailFormControl.statusChanges.subscribe(() => {
        this.formValidStatus.emit(this.alertEmailFormControl.valid);
      })
    );

    this.loadNotifications();
    this.loadTimeZones();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityId && !changes.facilityId.isFirstChange()) {
      this.timezoneDropDownDataSource.loading = true;
      this.timezoneDropDownDataSource.selectDefaultItem();

      this.clearData();
    }

    if (
      changes.notificationGroups &&
      !changes.notificationGroups.isFirstChange()
    ) {
      this.loadNotifications();
    }

    if (changes.settings && !changes.settings.isFirstChange()) {
      this.setSelectedTimeZoneFromSetting();
      // this is pulling from local cache, so we don't do another API call.
      const timezone = this.timezoneDropDownDataSource.data.find(
        (tz) =>
          tz.apiModel.Id === this.timezoneDropDownDataSource.selectedTimeZoneId
      );
      this.timezoneDropDownDataSource.selectItem(timezone);
      this.timezoneDropDownDataSource.loading = false;
    }
  }

  public loadTimeZones() {
    this.setSelectedTimeZoneFromSetting();

    const sub = this.timezoneDropDownDataSource.load().subscribe(
      (timeZones) => {
        this.timezoneDropDownDataSource.data = timeZones;
      },
      () => {
        this.toasterService.showError('LOAD_TIMEZONES_FAILED');
      }
    );
    this.subs.add(sub);
  }

  public onTimeZoneChange(
    newValue: AsyncDropDownValue<NameWithStringIdApiModel>
  ) {
    this.getTimeZoneSetting().SettingValue = newValue.apiModel.Id;
  }

  private setSelectedTimeZoneFromSetting() {
    this.timezoneDropDownDataSource.selectedTimeZoneId =
      this.getTimeZoneSetting().SettingValue;
  }

  private getTimeZoneSetting() {
    if (this.settings.has(CapsaSettingsTypeIds.CLI_UtcOffset)) {
      return this.settings.get(CapsaSettingsTypeIds.CLI_UtcOffset);
    }

    const newSetting = {
      SettingType: CapsaSettingsTypeIds.CLI_UtcOffset,
      SettingValue: '',
    } as SettingResponse;
    this.settings.set(CapsaSettingsTypeIds.CLI_UtcOffset, newSetting);
    return newSetting;
  }

  private alertEmailValidator = (
    formControl: AbstractControl
  ): ValidationErrors => {
    // if checkboxes selected, and no email value, error.
    return this.alertNotificationGroups.length > 0 && !formControl.value
      ? {
          emailRequired: true,
        }
      : undefined;
  };

  public onAlertChanged(type: NotificationType) {
    const existingAlertIdx = this.notificationGroups.findIndex(
      (g) => g.NotificationType === type
    );
    if (existingAlertIdx > -1) {
      // remove from list since it was toggled to the "off" position.
      this.notificationGroups.splice(existingAlertIdx, 1);
    } else {
      // add the item
      const alert = {
        Description: 'Trio Alert ' + type,
        NotificationType: type,
      } as NotificationGroupResponse;

      this.notificationGroups.push(alert);
    }

    switch (type) {
      case NotificationType.CLI_Battery_Level_Red:
        this.batteryAlertRed = !this.batteryAlertRed;
        break;
      case NotificationType.CLI_Battery_Level_Yellow:
        this.batteryAlertYellow = !this.batteryAlertYellow;
        break;
      case NotificationType.CLI_Connectivity_Red:
        this.connectivityAlertRed = !this.connectivityAlertRed;
        break;
      case NotificationType.CLI_Connectivity_Yellow:
        this.connectivityAlertYellow = !this.connectivityAlertYellow;
        break;
      case NotificationType.CLI_Security_Red:
        this.securityAlertRed = !this.securityAlertRed;
        break;
      case NotificationType.CLI_Security_Yellow:
        this.securityAlertYellow = !this.securityAlertYellow;
        break;
      case NotificationType.CLI_Utilization_Red:
        this.utilizationAlertRed = !this.utilizationAlertRed;
        break;
      case NotificationType.CLI_Utilization_Yellow:
        this.utilizationAlertYellow = !this.utilizationAlertYellow;
        break;
      default:
        throw new Error('Invalid NotificationType');
    }
    this.alertEmailFormControl.markAsTouched();
    this.alertEmailFormControl.updateValueAndValidity();
  }

  private loadNotifications() {
    this.alertNotificationGroups.forEach((alert) => {
      if (!this.alertEmailFormControl.value && alert.Members.length > 0) {
        this.alertEmailFormControl.setValue(alert.Members[0].MemberEmail);
      }
      switch (alert.NotificationType) {
        case NotificationType.CLI_Battery_Level_Red:
          this.batteryAlertRed = true;
          break;
        case NotificationType.CLI_Battery_Level_Yellow:
          this.batteryAlertYellow = true;
          break;
        case NotificationType.CLI_Connectivity_Red:
          this.connectivityAlertRed = true;
          break;
        case NotificationType.CLI_Connectivity_Yellow:
          this.connectivityAlertYellow = true;
          break;
        case NotificationType.CLI_Security_Red:
          this.securityAlertRed = true;
          break;
        case NotificationType.CLI_Security_Yellow:
          this.securityAlertYellow = true;
          break;
        case NotificationType.CLI_Utilization_Red:
          this.utilizationAlertRed = true;
          break;
        case NotificationType.CLI_Utilization_Yellow:
          this.utilizationAlertYellow = true;
          break;
        default:
          throw new Error('Invalid NotificationType');
      }
    });
  }

  private clearData() {
    this.batteryAlertRed = false;
    this.batteryAlertYellow = false;
    this.connectivityAlertRed = false;
    this.connectivityAlertYellow = false;
    this.securityAlertRed = false;
    this.securityAlertYellow = false;
    this.utilizationAlertRed = false;
    this.utilizationAlertYellow = false;
    this.alertEmailFormControl.markAsUntouched();
    this.alertEmailFormControl.setValue('');
    this.alertEmailFormControl.updateValueAndValidity();
  }

  private generateAlertsToSave(): NotificationGroupUpdateRequest[] {
    const alertsToSave = this.alertNotificationGroups.map((a) => {
      const alert = {
        ...a,
        Members: [
          {
            Email: this.alertEmailFormControl.value,
          },
        ],
      } as NotificationGroupUpdateRequest;

      return alert;
    });

    if (alertsToSave.length === 0) {
      this.alertEmailFormControl.setValue('');
      this.alertEmailForm.updateValueAndValidity();
    }

    return alertsToSave;
  }

  /**
   * Because we have different notification types (alerts and service requests)
   * this method can be used to quickly determine if a notification type is of
   * the "Alert" type
   */
  private isAlertNotificationType(notificationType: NotificationType) {
    switch (notificationType) {
      case NotificationType.CLI_Battery_Level_Red:
      case NotificationType.CLI_Battery_Level_Yellow:
      case NotificationType.CLI_Connectivity_Red:
      case NotificationType.CLI_Connectivity_Yellow:
      case NotificationType.CLI_Security_Red:
      case NotificationType.CLI_Security_Yellow:
      case NotificationType.CLI_Utilization_Red:
      case NotificationType.CLI_Utilization_Yellow:
        return true;
      default:
        return false;
    }
  }
}
