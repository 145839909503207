import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

@Injectable({
  providedIn: 'root',
})
export class NsightErrorHandler extends ApplicationinsightsAngularpluginErrorService {
  constructor(private router: Router) {
    super();
  }

  /**
   * Maximum number of errors to allow to be sent to AI in a session, in case other
   * checks fail, or a unique case arises that ends up going around the constraints
   */
  private readonly sessionMaxErrorReport = 10;

  /**
   * Keeps track of how many errors were sent to AI
   */
  private errorsReportedToAi = 0;

  /**
   * Keeps track of what the 'message' property was when the last error was recorded
   */
  private previousErrorMessageText = 'none';

  /**
   * Keeps track of what the URL route was when the last error occurred, in case user
   * navigates to a different page and gets the same exception
   */
  private previousErrorMessageRoute = 'none';

  override handleError(error: any): void {
    console.error(error);

    // Prevents spamming errors to Application Insights
    if (!this.isErrorDuplicate(error)) {
      this.previousErrorMessageText = error.message;
      this.previousErrorMessageRoute = this.router.url;

      if (this.errorsReportedToAi < this.sessionMaxErrorReport) {
        this.errorsReportedToAi++;

        // Passes error to AI for logging in Azure
        super.handleError(error);
      }
    }
  }

  private isErrorDuplicate(error: any): boolean {
    const errMsgSame =
      error.hasOwnProperty('message') &&
      error.message === this.previousErrorMessageText;

    const isRouteSame = this.router.url === this.previousErrorMessageRoute;

    return errMsgSame && isRouteSame;
  }
}
