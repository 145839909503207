import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CartGroupResponse, CartPartialResponse } from '@capsa/api';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';
import { Utils } from 'app/common/utils';
import { GridCartSelectionState } from 'app/modules/capsa-dialogs/capsa-dialogs-interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-selection-dialog',
  templateUrl: './cart-selection-dialog.component.html',
  styleUrls: ['./cart-selection-dialog.component.scss'],
})
export class CartSelectionDialogComponent implements OnInit, OnDestroy {
  // Adds a class to the "host" HTML wrapper of this component
  @HostBinding('class') class = 'responsiveKendoDialog';

  @Input()
  public cartGroups: CartGroupResponse[] = [];

  @Input()
  public cartsAvailable: CartPartialResponse[] = [];

  @Input()
  public cartsSelected: CartPartialResponse[] = [];

  @Output()
  public closed = new EventEmitter<GridCartSelectionState>();

  public icons = { xIcon: xIcon };

  // key = group ID
  // value = cart ID's array
  private cartGroupsMap = new Map<number, Set<number>>();

  private originalSelections: GridCartSelectionState;

  public availableKeysSelected: number[] = [];
  public selectedKeysSelected: number[] = [];

  public readonly pageSize = 250;

  public availableCartData: GridDataResult;
  public selectedCartData: GridDataResult;

  public availableState: State = Utils.getDefaultState(this.pageSize);
  public selectedState: State = Utils.getDefaultState(this.pageSize);

  private availableGridSelectionService: GridSelectionService;
  private selectedGridSelectionService: GridSelectionService;

  private subs = new Subscription();

  constructor(
    private gridSelectionServiceFactory: GridSelectionServiceFactory
  ) {}

  private selectCartRowIds(rows: RowArgs[]) {
    return rows.map((row) => {
      const sel = row.dataItem as CartPartialResponse;
      return sel.Id;
    });
  }

  public ngOnInit() {
    this.originalSelections = {
      avail: [...this.cartsAvailable],
      selected: [...this.cartsSelected],
    };

    this.availableGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectCartRowIds
    );

    this.selectedGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectCartRowIds
    );

    this.reprocess();

    this.subs.add(
      this.availableGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.availableKeysSelected = newSelection;
        }
      )
    );

    this.subs.add(
      this.selectedGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedKeysSelected = newSelection;
        }
      )
    );

    if (this.cartGroups) {
      this.cartGroups.forEach((group) => {
        const carts = group.Members.map((c) => c.CartId);
        this.cartGroupsMap.set(group.CartGroupId, new Set(carts));
      });
    }
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public saveSelection() {
    const selections: GridCartSelectionState = {
      avail: [...this.cartsAvailable],
      selected: [...this.cartsSelected],
    };

    this.closed.emit(selections);
  }

  public onClose() {
    this.closed.emit(this.originalSelections);
  }

  private reprocess() {
    this.availableCartData = process(this.cartsAvailable, this.availableState);
    this.selectedCartData = process(this.cartsSelected, this.selectedState);
  }

  public availableStateChange(state: DataStateChangeEvent): void {
    this.availableState = state;
    this.availableCartData = process(this.cartsAvailable, this.availableState);
  }

  public selectedStateChange(state: DataStateChangeEvent): void {
    this.selectedState = state;
    this.selectedCartData = process(this.cartsSelected, this.selectedState);
  }

  public onAvailableSelectionChanged(change: SelectionEvent) {
    this.availableGridSelectionService.updateSelection(change);
  }

  public onSelectedSelectionChanged(change: SelectionEvent) {
    this.selectedGridSelectionService.updateSelection(change);
  }

  public btnTriggerMoveAllToSelected() {
    if (!this.cartsAvailable.length) {
      return;
    }

    const tempSelected = [...this.cartsSelected, ...this.cartsAvailable];

    tempSelected.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.cartsAvailable.length = 0;
    this.cartsSelected = tempSelected;
    this.availableGridSelectionService.clearSelection();
    this.selectedGridSelectionService.clearSelection();

    this.availableState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }

  public btnTriggerMoveAvailableToSelected() {
    if (!this.availableKeysSelected.length) {
      return;
    }

    this.availableKeysSelected.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.cartsAvailable;
    const tempSelectedCarts = this.cartsSelected;

    this.cartsAvailable = [];
    this.cartsSelected = [];

    do {
      const iAv = this.availableKeysSelected.pop();
      const cartToMoveIdx = tempAvail.findIndex((x) => x.Id === iAv);
      const cartToMove = tempAvail.splice(cartToMoveIdx, 1)[0];
      tempSelectedCarts.push(cartToMove);
    } while (this.availableKeysSelected.length);

    tempSelectedCarts.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.cartsAvailable = tempAvail;
    this.cartsSelected = tempSelectedCarts;
    this.availableGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.cartsAvailable.length <= this.availableState.skip) {
      this.availableState.skip = this.availableState.skip - this.pageSize;
    }

    this.reprocess();
  }

  public btnTriggerMoveSelectedToAvailable() {
    if (!this.selectedKeysSelected.length) {
      return;
    }

    this.selectedKeysSelected.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.cartsAvailable;
    const tempSelectedCarts = this.cartsSelected;

    this.cartsAvailable = [];
    this.cartsSelected = [];

    do {
      const iAv = this.selectedKeysSelected.pop();
      const cartToMoveIdx = tempSelectedCarts.findIndex((x) => x.Id === iAv);
      const cartToMove = tempSelectedCarts.splice(cartToMoveIdx, 1)[0];
      tempAvail.push(cartToMove);
    } while (this.selectedKeysSelected.length);

    tempAvail.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.cartsAvailable = tempAvail;
    this.cartsSelected = tempSelectedCarts;
    this.selectedGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.cartsSelected.length <= this.selectedState.skip) {
      this.selectedState.skip = this.selectedState.skip - this.pageSize;
    }
    this.reprocess();
  }

  public btnTriggerMoveAllToAvailable() {
    if (!this.cartsSelected.length) {
      return;
    }

    const tempAvailable = [...this.cartsAvailable, ...this.cartsSelected];

    tempAvailable.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.cartsAvailable = tempAvailable;
    this.cartsSelected.length = 0;
    this.selectedGridSelectionService.clearSelection();
    this.availableGridSelectionService.clearSelection();

    this.selectedState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }

  public onBtnSelectGroupCarts(groupItem: CartGroupResponse) {
    const selectedCarts: CartPartialResponse[] = [];
    const groupCartIds = this.cartGroupsMap.get(groupItem.CartGroupId);

    // Create sets for performance
    // A list of ALL ID's currently in the "Available" grid
    const cartsAvailableIdSet = new Set(this.cartsAvailable.map((x) => x.Id));

    // A list of ALL ID's currently "Checkbox" selected in the "Available" grid
    const availableKeysSelectedSet = new Set(this.availableKeysSelected);

    groupCartIds.forEach((cartIdToSelect) => {
      if (
        cartsAvailableIdSet.has(cartIdToSelect) &&
        !availableKeysSelectedSet.has(cartIdToSelect)
      ) {
        // Only adds carts to the selection that exist in the "Available Grid" (i.e. they haven't yet been moved to the "Selected Grid")
        // and also not already selected
        selectedCarts.push({
          Id: cartIdToSelect,
          Name: null,
          SerialNumber: null,
        });
      }
    });

    this.availableGridSelectionService.forceChanges(
      selectedCarts.map((cartPartial) => ({
        dataItem: cartPartial,
        index: 0,
      })),
      []
    );
  }
}
