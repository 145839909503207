import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  DeviceTypeIds,
  FacilityResponse,
  SettingResponse,
} from '@capsa/api';
import { EofCacheService } from '@capsa/services/eof-cache';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-profile',
  templateUrl: './facility-manage-profile.component.html',
  styleUrls: ['./facility-manage-profile.component.scss'],
})
export class FacilityManageProfileComponent implements OnInit, OnDestroy {
  @Input()
  facilityInfo: FacilityResponse;

  @Input()
  public settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  @Input()
  deviceTypeId: number;

  @Output()
  public formValidStatus = new EventEmitter<boolean>();

  public profileForm: UntypedFormGroup;
  public facilityNameCtrl: UntypedFormControl;
  public contactEmailCtrl: UntypedFormControl;

  // set once and should never change
  private initialFacilityName: string;

  private subs = new Subscription();

  public get isAvalo(): boolean {
    return this.deviceTypeId === DeviceTypeIds.Avalo;
  }

  public get imageWidth() {
    return this.getIntSettingValue(
      CapsaSettingsTypeIds.CLI_CustomerLogoMaxWidth
    );
  }

  public get imageHeight() {
    return this.getIntSettingValue(
      CapsaSettingsTypeIds.CLI_CustomerLogoMaxHeight
    );
  }

  constructor(private eofCacheService: EofCacheService) {}

  ngOnInit(): void {
    this.initialFacilityName = this.facilityInfo.Name;

    this.facilityNameCtrl = new UntypedFormControl(
      this.facilityInfo.Name,
      Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        this.uniqueFacilityNamePerOrgValidator,
      ])
    );

    this.contactEmailCtrl = new UntypedFormControl(
      this.facilityInfo.ContactEmail,
      Validators.compose([
        Validators.email,
        Validators.maxLength(256),
        NsightCustomValidators.isEmailExtraChecksValid(),
      ])
    );

    this.profileForm = new UntypedFormGroup({
      facilityNameCtrl: this.facilityNameCtrl,
      contactEmailCtrl: this.contactEmailCtrl,
    });

    this.subs.add(
      this.profileForm.statusChanges.subscribe(() => {
        this.formValidStatus.emit(this.profileForm.valid);
      })
    );

    this.subs.add(
      this.facilityNameCtrl.statusChanges.subscribe(() => {
        if (this.facilityNameCtrl.valid) {
          this.facilityInfo.Name = this.facilityNameCtrl.value.trim();
        }
      })
    );

    this.subs.add(
      this.contactEmailCtrl.statusChanges.subscribe(() => {
        if (this.contactEmailCtrl.valid) {
          this.facilityInfo.ContactEmail = this.contactEmailCtrl.value.trim();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getIntSettingValue(settingType: CapsaSettingsTypeIds) {
    const setting = this.settings.get(settingType);
    return Number.parseInt(setting.SettingValue, 10);
  }

  private uniqueFacilityNamePerOrgValidator = (
    ctl: AbstractControl
  ): ValidationErrors => {
    // Always return valid if the input is exactly what was initially loaded
    // this way the validation won't "falsely claim" that the facility name already
    // exists.
    if (
      this.initialFacilityName.trim().toLowerCase() ===
      ctl.value.trim().toLowerCase()
    ) {
      return null;
    }

    if (ctl.dirty) {
      const isUnique = this.eofCacheService.isFacilityNameUniqueToOrg(
        ctl.value,
        this.facilityInfo.OrganizationId
      );

      return isUnique ? null : { isNotUniqueFacilityName: true };
    }
    return null;
  };
}
