import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncDropDownListComponent } from '@capsa/dropdowns/async-drop-down-list/async-drop-down-list.component';
import { AsyncMultiSelectListComponent } from '@capsa/dropdowns/async-multi-select-list/async-multi-select-list.component';
import { CapsaRoleMultiSelectDataSource } from '@capsa/dropdowns/capsa-role-multi-select/capsa-role-multi-select-data-source';
import { CapsaRoleMultiSelectComponent } from '@capsa/dropdowns/capsa-role-multi-select/capsa-role-multi-select.component';
import { ColorComboDropDownComponent } from '@capsa/dropdowns/color-combo-drop-down/color-combo-drop-down.component';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';
import { EnterpriseDropDownDataSource } from '@capsa/dropdowns/enterprise-drop-down/enterprise-drop-down-data-source';
import { EnterpriseDropDownComponent } from '@capsa/dropdowns/enterprise-drop-down/enterprise-drop-down.component';
import { EofDropDownsComponent } from '@capsa/dropdowns/eof-drop-downs/eof-drop-downs.component';
import { FacilityDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-drop-down-data-source';
import { FacilityEnterpriseDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-enterprise-drop-down-data-source';
import { FacilitySupportDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-support-drop-down-data-source';
import { FacilityUserDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-user-drop-down-data-source';
import { FacilityMultiSelectDataSource } from '@capsa/dropdowns/facility-multi-select/facility-multi-select-data-source';
import { FacilityMultiSelectComponent } from '@capsa/dropdowns/facility-multi-select/facility-multi-select.component';
import { MessageTemplateDropDownDataSource } from '@capsa/dropdowns/message-templates-drop-down/message-templates-drop-down-datasource';
import { OrganizationDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-drop-down-data-source';
import { OrganizationEnterpriseDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-enterprise-drop-down-data-source';
import { OrganizationSupportDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-support-drop-down-data-source';
import { OrganizationUserDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-user-drop-down-data-source';
import { OrganizationMultiSelectDataSource } from '@capsa/dropdowns/organization-multi-select/organization-multi-select-data-source';
import { OrganizationMultiSelectComponent } from '@capsa/dropdowns/organization-multi-select/organization-multi-select.component';
import { TimezoneDropDownDataSource } from '@capsa/dropdowns/timezone-drop-down/timezone-drop-down-datasource';
import { TranslateModule } from '@ngx-translate/core';
import {
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { ApiModule } from 'app/modules/api-module/api.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { DeviceProfileDropDownComponent } from './components/device-profile-drop-down/device-profile-drop-down.component';
import { DeviceTypeDropDownComponent } from './components/device-type-drop-down/device-type-drop-down.component';
import { DeviceUpdateTypeDropDownComponent } from './components/device-update-type-drop-down/device-update-type-drop-down.component';
import { EnumDropDownComponent } from './components/enum/enum-drop-down.component';
import { FacilityDropDownComponent } from './components/facility-drop-down/facility-drop-down.component';
import { MessageTemplatesDropDownComponent } from './components/message-templates-drop-down/message-templates-drop-down.component';
import { OrgFacilityDropDownsComponent } from './components/org-facility-drop-downs/org-facility-drop-downs.component';
import { OrganizationDropDownComponent } from './components/organization-drop-down/organization-drop-down.component';
import { SimpleDropDownComponent } from './components/simple-drop-down/simple-drop-down.component';
import { TimezoneDropDownComponent } from './components/timezone-drop-down/timezone-drop-down.component';
import { YesNoDropdownComponent } from './components/yes-no-dropdown/yes-no-dropdown.component';

@NgModule({
  declarations: [
    DeviceTypeDropDownComponent,
    OrganizationDropDownComponent,
    EnumDropDownComponent,
    TimezoneDropDownComponent,
    YesNoDropdownComponent,
    FacilityDropDownComponent,
    AsyncDropDownListComponent,
    SimpleDropDownComponent,
    AsyncMultiSelectListComponent,
    CapsaRoleMultiSelectComponent,
    FacilityMultiSelectComponent,
    OrganizationMultiSelectComponent,
    OrgFacilityDropDownsComponent,
    DeviceProfileDropDownComponent,
    EnterpriseDropDownComponent,
    MessageTemplatesDropDownComponent,
    DeviceUpdateTypeDropDownComponent,
    ColorComboDropDownComponent,
    EofDropDownsComponent,
  ],
  exports: [
    DeviceTypeDropDownComponent,
    OrganizationDropDownComponent,
    EnumDropDownComponent,
    TimezoneDropDownComponent,
    YesNoDropdownComponent,
    FacilityDropDownComponent,
    SimpleDropDownComponent,
    CapsaRoleMultiSelectComponent,
    FacilityMultiSelectComponent,
    OrganizationMultiSelectComponent,
    OrgFacilityDropDownsComponent,
    DeviceProfileDropDownComponent,
    EnterpriseDropDownComponent,
    MessageTemplatesDropDownComponent,
    DeviceUpdateTypeDropDownComponent,
    ColorComboDropDownComponent,
    EofDropDownsComponent,
  ],
  imports: [
    CommonModule,
    LabelsModule,
    ApiModule,
    DropDownsModule,
    MultiSelectModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  providers: [
    FacilityDropDownDataSource,
    FacilityEnterpriseDropDownDataSource,
    FacilityUserDropDownDataSource,
    OrganizationDropDownDataSource,
    OrganizationEnterpriseDropDownDataSource,
    OrganizationUserDropDownDataSource,
    DeviceTypeDropDownDataSource,
    CapsaRoleMultiSelectDataSource,
    FacilityMultiSelectDataSource,
    OrganizationMultiSelectDataSource,
    EnterpriseDropDownDataSource,
    MessageTemplateDropDownDataSource,
    TimezoneDropDownDataSource,
    OrganizationSupportDropDownDataSource,
    FacilitySupportDropDownDataSource,
  ],
})
export class CapsaDropdownsModule {}
