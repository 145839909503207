// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.targetFacilityContainer {
  display: block;
  margin-top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/support/support-transfer-carts/support-transfer-carts.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;AACF","sourcesContent":[".targetFacilityContainer {\n  display: block;\n  margin-top: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
