import { Injectable } from '@angular/core';
import { NameWithStringIdApiModel, throwIfApiError } from '@capsa/api';
import { CommonApi } from '@capsa/api/common';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TimezoneDropDownDataSource extends AbstractDropDownListDataSource<NameWithStringIdApiModel> {
  constructor(
    private commonApi: CommonApi,
    private translations: TranslateService
  ) {
    super();
  }

  public selectedTimeZoneId: string;

  public get defaultItem(): AsyncDropDownValue<NameWithStringIdApiModel> {
    return null;
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  protected callApi(): Observable<NameWithStringIdApiModel[]> {
    return this.commonApi.getTimeZones().pipe(
      throwIfApiError(),
      map((timezones) => {
        return timezones.map((tz) => {
          return {
            Id: tz.Key,
            Name: tz.Value,
          } as NameWithStringIdApiModel;
        });
      })
    );
  }
  protected map(
    apiModel: NameWithStringIdApiModel
  ): AsyncDropDownValue<NameWithStringIdApiModel> {
    return {
      id: apiModel.Id,
      name: this.translations.instant(apiModel.Name),
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<NameWithStringIdApiModel>[]
  ): AsyncDropDownValue<NameWithStringIdApiModel> {
    return items.find((v) => v.apiModel.Id === this.selectedTimeZoneId);
  }
}
