import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { LightingApi } from '@capsa/api/lighting';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserMemoryService } from '@capsa/services/user-memory/user-memory.service';
import { SettingMap } from 'app/modules/cart-profile/helpers';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cart-profile-v2-lights',
  templateUrl: './lights.component.html',
  styleUrls: ['./lights.component.scss'],
})
export class V2LightsComponent implements OnInit, OnDestroy {
  @Input()
  public settings: SettingMap;

  @Input()
  public themeSettings: SettingMap;

  public colorThemeValues: DropDownItem[] = [];

  get colorTheme() {
    return this.themeSettings.getSetting('ColorTheme');
  }

  private _colorThemeId: string = null;

  get colorThemeSettingValue() {
    return this._colorThemeId;
  }

  get dateTimeFormat() {
    return this.settings.getSetting('DateTimeFormat');
  }

  get keyboardBrightness() {
    return this.settings.getSetting('Keyboard');
  }

  get floorBrightness() {
    return this.settings.getSetting('Floor');
  }

  get workSurface() {
    return this.settings.getSetting('WorkSurface');
  }

  public onChange(setting: CapsaSettingApiModel, newValue: number) {
    setting.SettingValue = newValue.toString();
  }

  public stringValueChanged(
    setting: CapsaSettingApiModel,
    selectedFormat: DropDownItem
  ) {
    setting.SettingValue = selectedFormat.Value as string;
  }

  private subs = new Subscription();

  constructor(
    private lightingApi: LightingApi,
    private userMemoryService: UserMemoryService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    const fId = this.userMemoryService.userMemory.facilityId;

    this.subs.add(
      this.lightingApi
        .getLedThemes(fId)
        .pipe(
          finalize(() => {
            // Setting this after finishing setting drop down values array, so that
            // the selected value is set AFTER the list is ready, otherwise it won't select...
            this._colorThemeId =
              this.themeSettings.getSetting('ColorTheme').SettingValue;
          })
        )
        .subscribe(
          (resp) => {
            this.colorThemeValues = resp.map((x) => {
              return {
                Label: x.Name,
                Value: x.Id.toString(10),
              };
            });
          },
          (_error) => {
            this.toasterService.showError('LIGHTING_THEME_GET_THEMES_FAIL');
            this.colorThemeValues = [
              { Label: 'Default', Value: '1' },
              { Label: 'Theme1', Value: '2' },
              { Label: 'Theme2', Value: '3' },
              { Label: 'Theme3', Value: '4' },
              { Label: 'Theme4', Value: '5' },
              { Label: 'Theme5', Value: '6' },
              { Label: 'Theme6', Value: '7' },
              { Label: 'Theme7', Value: '8' },
              { Label: 'Theme8', Value: '9' },
              { Label: 'Theme9', Value: '10' },
            ];
          }
        )
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
