import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceProfileSearchResponseApiModel } from '@capsa/api';
import { GridFilterConfiguration } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent {
  constructor(private router: Router) {}

  public onAdd(currentFilters: GridFilterConfiguration) {
    // route
    this.router.navigate(['facilities', 'profiles', 'new'], {
      state: currentFilters,
    });
  }

  public onEdit(toEdit: DeviceProfileSearchResponseApiModel) {
    this.router.navigate([
      'facilities',
      'profiles',
      'edit',
      toEdit.DeviceProfileId,
    ]);
  }
}
