import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractAsyncMultiSelectDataSource } from '@capsa/dropdowns/async-multi-select-list/abstract-async-multi-select-data-source';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

const RequiredMultiSelect: ValidatorFn = (ctrl: UntypedFormControl) => {
  const val = ctrl.value as any[];
  if (val.length === 0) {
    return { required: true };
  }
};

/**
 * Represents and async dropdown list. Used to set/maintain standard settings
 * for kendo async dropdown lists.
 */
@Component({
  selector: 'app-async-multi-select-list',
  templateUrl: './async-multi-select-list.component.html',
  styleUrls: ['./async-multi-select-list.component.css'],
})
export class AsyncMultiSelectListComponent<TModel>
  implements OnInit, OnDestroy
{
  constructor(private translateService: TranslateService) {}
  @Input()
  public dataSource: AbstractAsyncMultiSelectDataSource<TModel>;

  @Input()
  public readonly: boolean;

  @Input()
  public readonlyText: string;

  @Input()
  public titleTag: string;

  @Input()
  public forceValidate$: Observable<void>;

  @Input()
  public required = false;

  @Input()
  public placeholder;

  /**
   * When the value of the dropdown changes, this event is fired
   * with the new @type {TModel} value.
   */
  @Output()
  public valueChanged = new EventEmitter<AsyncDropDownValue<TModel>[]>();

  public formControl: UntypedFormControl;

  private subs = new Subscription();

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = this.translateService.instant(
        'SELECT_ONE_OR_MORE_VALUES'
      );
    }
    const validators = this.required ? RequiredMultiSelect : undefined;
    this.formControl = new UntypedFormControl([], validators);

    if (this.forceValidate$) {
      this.subs.add(
        this.forceValidate$.subscribe(() => {
          // For some reason the "value" of the control is not updated "quickly enough"
          // for validation to catch empty selections...
          // since dataSource.selection is the source of the form value anyways, we
          // explicitly set the form control's value, then mark the control as "changed/dirty"
          // after the first two stemps, when we run "updateValueAndValidity" the control accurately
          // displays its valid/invalid status
          this.formControl.setValue(this.dataSource.selection);
          this.formControl.markAsDirty();
          this.formControl.updateValueAndValidity();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChange = (newValue: AsyncDropDownValue<TModel>[]) => {
    this.valueChanged.emit(newValue);
  };
}
