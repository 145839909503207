import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BooleanDropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-yes-no-dropdown',
  templateUrl: './yes-no-dropdown.component.html',
  styleUrls: ['./yes-no-dropdown.component.scss'],
})
export class YesNoDropdownComponent implements OnInit {
  public items: BooleanDropDownItem[];

  @Input()
  public headerText: string;

  @Input()
  public value: string;

  get valueAsBoolean() {
    return this.value === '1';
  }

  @Output()
  valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange(newValue: BooleanDropDownItem | undefined) {
    this.valueChanged.emit(newValue !== undefined && newValue.Value === true);
  }

  constructor(private translations: TranslateService) {}
  ngOnInit(): void {
    this.items = [
      { Label: this.translations.instant('COM_YES'), Value: true },
      { Label: this.translations.instant('COM_NO'), Value: false },
    ];
  }
}
