import { Injectable } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { AuthService } from '@capsa/services/auth/auth.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { UserMemoryService } from '@capsa/services/user-memory/user-memory.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class FacilityDropDownDataSource extends AbstractDropDownListDataSource<NameWithLongIdApiModel> {
  constructor(
    private translations: TranslateService,
    private userMemoryService: UserMemoryService,
    private authService: AuthService,
    private eofCacheService: EofCacheService,
    private userEofCacheService: UserEofCacheService
  ) {
    super();
  }

  get defaultItem(): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  public organizationId: number;
  public facilityId: number | undefined;

  protected callApi(): Observable<NameWithLongIdApiModel[]> {
    const data = this.authService.currentUser.EnterpriseAdmin
      ? this.eofCacheService.facilities
      : this.userEofCacheService.facilities;

    return of(data.filter((f) => f.ParentId === this.organizationId));
  }

  protected map(
    apiModel: NameWithLongIdApiModel
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      id: apiModel.Id.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }

  protected select(
    items: AsyncDropDownValue<NameWithLongIdApiModel>[]
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    if (this.organizationId === undefined) {
      return undefined;
    }

    if (this.facilityId !== undefined) {
      return items.find((i) => i.apiModel.Id === this.facilityId);
    } else if (this.autoSelect) {
      const memoryMatch = items.find(
        (x) => x.apiModel.Id === this.userMemoryService.userMemory.facilityId
      );

      if (memoryMatch) {
        return memoryMatch;
      }
    }
  }

  public reset() {
    super.reset();
    this.facilityId = undefined;
  }
}
