import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { NsightErrorHandler } from './app-insights/error-logger';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: NsightErrorHandler,
    },
  ],
})
export class LoggingModule {}
