import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  DeviceTypeFirmwareVersionResponse,
  DeviceTypeIds,
  DeviceUpdateType,
  FirmwareResponse,
  FirmwareUpdateCartsResponse,
  throwIfApiError,
  throwIfBadRespFullResp,
} from '..';
import { BaseApi } from '../base/base.api';

export interface FirmHarwWareApiInstance {
  getFirmwareByDeviceTypeId(
    deviceId: DeviceTypeIds
  ): Observable<FirmwareResponse[]>;
}

@Injectable()
export class FirmHardWareApi
  extends BaseApi
  implements FirmHarwWareApiInstance
{
  protected endpoints = {
    firmwareVersionGetByDtUrl: this.buildUrl('firmware/dt-version-list/'),
    firmwareGetByDtUrl: this.buildUrl('firmware/dt-list/'),
    baseFirmwareUrl: this.buildUrl('firmware'),
    autoUpdateInfoUrl: this.buildUrl('firmware/autoupdate/info'),
    autoUpdateUrl: this.buildUrl('firmware/autoupdate'),
    advancedUpdateUrl: this.buildUrl(
      'firmware/updatedevices/{firmwareVersionId}'
    ),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public uploadFirmware(
    newVersion: string,
    releaseDate: Date,
    updateType: DeviceUpdateType,
    description: string,
    file: File,
    firmwareId: number
  ) {
    const formData = new FormData();
    formData.append('version', newVersion);
    formData.append('active-date', releaseDate.toJSON());
    formData.append('updatetype', updateType.toString());
    formData.append('description', description);
    formData.append('file', file, file.name);
    formData.append('firmware-id', firmwareId.toString());

    return this.http
      .post<ApiResponse<FirmwareResponse>>(
        this.endpoints.baseFirmwareUrl,
        formData
      )
      .pipe(map((resp) => resp.Result));
  }

  /**
   * Requests list of devices to be updated to different version
   */
  public getFirmwareByDeviceTypeId(
    dtId: DeviceTypeIds
  ): Observable<FirmwareResponse[]> {
    return this.http
      .get<FirmwareResponse[]>(this.endpoints.firmwareGetByDtUrl + dtId)
      .pipe(throwIfApiError());
  }

  public getFirmwareVersionsByDeviceTypeId(
    dtId: DeviceTypeIds
  ): Observable<DeviceTypeFirmwareVersionResponse> {
    return this.http
      .get<DeviceTypeFirmwareVersionResponse>(
        this.endpoints.firmwareVersionGetByDtUrl + dtId
      )
      .pipe(throwIfApiError());
  }

  public deleteFirmwareById(fwId: number) {
    return this.http
      .delete<ApiResponse<void>>(this.endpoints.baseFirmwareUrl + '/' + fwId)
      .pipe(throwIfBadRespFullResp());
  }

  public getAutoUpdateInfo(
    cartIds: number[]
  ): Observable<FirmwareUpdateCartsResponse> {
    return this.http
      .post<FirmwareUpdateCartsResponse>(this.endpoints.autoUpdateInfoUrl, {
        CartIds: cartIds,
      })
      .pipe(throwIfApiError());
  }

  public autoUpdateCarts(
    cartIds: number[]
  ): Observable<FirmwareUpdateCartsResponse> {
    return this.http
      .post<FirmwareUpdateCartsResponse>(this.endpoints.autoUpdateUrl, {
        CartIds: cartIds,
      })
      .pipe(throwIfApiError());
  }

  public advancedUpdateCart(
    cartIds: number[],
    fmVersionId: number
  ): Observable<void> {
    return this.http
      .post<ApiResponse<void>>(
        this.endpoints.advancedUpdateUrl.replace(
          '{firmwareVersionId}',
          fmVersionId.toString()
        ),
        {
          Ids: cartIds,
        }
      )
      .pipe(
        throwIfBadRespFullResp(),
        map((resp) => {
          return;
        })
      );
  }
}
