import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  DeviceTypeWarningApiModel,
  DeviceWarningCreateRequest,
  DeviceWarningUpdateApiModel,
  FacilityBatterySettingsStateResponse,
  FacilityResponse,
  FacilitySearchRequest,
  FacilityUpdateRequest,
  NameWithLongIdApiModel,
  PagingRequestDto,
  throwIfApiError,
  throwIfBadRespFullResp,
  throwIfBadRespReturnPayloadOnly,
} from '..';
import { BaseApi } from '../base/base.api';

export interface FacilityApiInstance {
  getDropdown(organizationId: number): Observable<NameWithLongIdApiModel[]>;
  generatePinCode(facilityId: string): Observable<ApiResponse<string>>;
  search(
    request: Partial<FacilitySearchRequest>
  ): Observable<ApiResponse<FacilityResponse[]>>;
  getFacility(
    facilityId: number,
    productLine: string
  ): Observable<ApiResponse<FacilityResponse>>;
  update(request: FacilityUpdateRequest): Observable<ApiResponse<void>>;
  uploadLogoUrl(facilityId: number): string;
  getLogo(facilityId: number): Observable<ApiResponse<string>>;
  getAllFacilitiesForCurrentUser(): Observable<ApiResponse<FacilityResponse[]>>;
}

@Injectable()
export class FacilityApi extends BaseApi implements FacilityApiInstance {
  protected endpoints = {
    dropDown: this.buildUrl('facility/GetFacilitiesDropdown'),
    search: this.buildUrl('facility/Search'),
    getPincode: this.buildUrl('facility/{id}/pincode'),
    getFacility: this.buildUrl('facility/{id}?productLine={productLine}'),
    update: this.buildUrl('facility/update'),
    logo: this.buildUrl('facility/{id}/brand-logo'),
    getAllForCurrentUser: this.buildUrl('facility'),
    getFacilityBatterySettingsState: this.buildUrl(
      'facility/{id}/battery-settings-state'
    ),
    clearCartLevelBatteryRangeSettings: this.buildUrl(
      'facility/{id}/clear-cart-level-battery-range-settings'
    ),
    warnings: this.buildUrl('facility/{id}/device-warnings'),
    singleWarning: this.buildUrl('facility/{id}/device-warnings/{warningId}'),
  };

  private enterpriseId: number;

  constructor(
    client: HttpClient,
    enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
    this.enterpriseId = enterpriseService.enterpriseId;
  }

  public getFacility(
    facilityId: number,
    productLine: string
  ): Observable<ApiResponse<FacilityResponse>> {
    return this.http.get<ApiResponse<FacilityResponse>>(
      this.endpoints.getFacility
        .replace('{id}', facilityId.toString())
        .replace('{productLine}', productLine)
    );
  }

  public getAllFacilitiesForCurrentUser(): Observable<
    ApiResponse<FacilityResponse[]>
  > {
    return this.http.get<ApiResponse<FacilityResponse[]>>(
      this.endpoints.getAllForCurrentUser
    );
  }

  public update(request: FacilityUpdateRequest): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(this.endpoints.update, request);
  }

  /**
   * Gets a list of all the organizations meeting the search criteria
   */
  public getDropdown(organizationId: number) {
    const paging: PagingRequestDto = {
      PageNumber: 1,
      PageSize: 100,
    };

    const url = `${this.endpoints.dropDown}?${this.buildPagingParams(
      paging
    )}&organizationId=${organizationId}`;

    return this.http
      .get<ApiResponse<NameWithLongIdApiModel[]>>(url)
      .pipe(map((resp) => resp.Result || []));
  }

  public search(search: Partial<FacilitySearchRequest>) {
    const request: FacilitySearchRequest = {
      PageNumber: 1,
      PageSize: 100,
      EnterpriseId: this.enterpriseId,
      IncludeCapsaSettings: false,
      ...search,
    };

    return this.http.post<ApiResponse<FacilityResponse[]>>(
      this.endpoints.search,
      request
    );
  }

  public generatePinCode(facilityId: string) {
    return this.http.get<ApiResponse<string>>(
      this.endpoints.getPincode.replace('{id}', facilityId)
    );
  }

  public uploadLogoUrl(facilityId: number): string {
    return this.endpoints.logo.replace('{id}', facilityId.toString());
  }

  public getLogo(facilityId: number): Observable<ApiResponse<string>> {
    return this.http.get<ApiResponse<string>>(
      this.endpoints.logo.replace('{id}', facilityId.toString())
    );
  }

  public getFacilityBatterySettingsState(
    facilityId: number
  ): Observable<FacilityBatterySettingsStateResponse> {
    return this.http
      .get<FacilityBatterySettingsStateResponse>(
        this.endpoints.getFacilityBatterySettingsState.replace(
          '{id}',
          facilityId.toString()
        )
      )
      .pipe(throwIfApiError());
  }

  public clearCartLevelBatteryRangeSettings(
    facilityId: number
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(
        this.endpoints.clearCartLevelBatteryRangeSettings.replace(
          '{id}',
          facilityId.toString()
        ),
        null
      )
      .pipe(throwIfBadRespFullResp());
  }

  public getWarnings(
    facilityId: number
  ): Observable<DeviceTypeWarningApiModel[]> {
    const url = this.endpoints.warnings.replace('{id}', facilityId.toString());
    return this.http
      .get<ApiResponse<DeviceTypeWarningApiModel[]>>(url)
      .pipe(throwIfBadRespReturnPayloadOnly());
  }

  public addWarning(
    facilityId: number,
    values: DeviceWarningCreateRequest
  ): Observable<DeviceTypeWarningApiModel> {
    const url = this.endpoints.warnings.replace('{id}', facilityId.toString());

    return this.http
      .post<ApiResponse<DeviceTypeWarningApiModel>>(url, values)
      .pipe(throwIfBadRespReturnPayloadOnly());
  }

  public updateWarning(
    facilityId: number,
    warningId: number,
    updatedValues: DeviceWarningUpdateApiModel
  ): Observable<ApiResponse<DeviceTypeWarningApiModel>> {
    const url = this.endpoints.singleWarning
      .replace('{id}', facilityId.toString())
      .replace('{warningId}', warningId.toString());

    return this.http
      .put<ApiResponse<DeviceTypeWarningApiModel>>(url, updatedValues)
      .pipe(throwIfBadRespFullResp());
  }

  public deleteWarning(
    facilityId: number,
    warningId: number
  ): Observable<ApiResponse<DeviceTypeWarningApiModel>> {
    const url = this.endpoints.singleWarning
      .replace('{id}', facilityId.toString())
      .replace('{warningId}', warningId.toString());

    return this.http
      .delete<ApiResponse<DeviceTypeWarningApiModel>>(url)
      .pipe(throwIfBadRespFullResp());
  }
}
