// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --sidebarWidth: 180px;
}

/* Colors */
.requestSent {
  color: #13995f;
}`, "",{"version":3,"sources":["webpack://./src/styles/_variables.scss","webpack://./src/app/modules/cart-management/cart-management-debug/cart-management-debug.component.scss"],"names":[],"mappings":"AAAA;EAIE,qBAAA;ACFF;;ADKA,WAAA;ACLA;EACE,cDgBM;ACZR","sourcesContent":[":root {\n  // css variables / custom properties\n  // This property should be updated every time a lanugage\n  // change occurs, via Typescript/JS\n  --sidebarWidth: 180px;\n}\n\n/* Colors */\n$black: #000;\n$white: #fff;\n$primaryBlue: #0076a8;\n$secondaryBlue: #0078bf;\n$darkBlue: #006996;\n$babyBlue: #93d4f3;\n$babyBlueVivid: #2ba3d7;\n$veryLightBlue: #c8e6f4;\n$midDarkblue: #227597;\n$blueishGray: #d5dde0;\n$errorRed: #dc3545;\n$green: #13995f;\n$orange: #ff6e27;\n\n$gray: #5a595c;\n$lightergray: #545456;\n$gray_six_five: #656565;\n$gray_seven_six: #767676;\n$gray_nine: #999;\n$gray_c_five: #c5c5c5;\n$gray_c: #ccc;\n$gray_d: #ddd;\n$gray_d8: #d8d8d8;\n$gray_e_two: #e2e2e2;\n$offwhite: #f1f1f1;\n\n// Media Query Breakpoints\n$small-bp: 400px;\n$medium-bp: 960px;\n$large-bp: 1200px;\n\n$headerBarHeight: 36px;\n$sidebarWidth-fallback: 220px;\n$routerContentPadding: 25px;\n\n// Represents the vertical space reserved for components that are on every page\n// Header and top/bottom padding on \"#routerContent\" element where the views are\n// displayed\n$reservedViewportHeightSpace: $headerBarHeight + ($routerContentPadding * 2);\n\n// **********  z-index  **********\n// ALL z-index values should be variables stored here\n$sessionTimeoutDialogZindex: 10100;\n$toastZindex: 10020;\n$fixedHeaderZindex: 1000;\n$loadingOverlayZindex: 10010;\n\n// Handling bug:\n// https://github.com/telerik/kendo-themes/issues/834\n// https://github.com/telerik/kendo-themes/issues/846\n$switch-container-border-width: 0px;\n$switch-container-padding-x: 0px;\n\n$halfGutterWidth: 10px;\n","@import '../../../../styles/variables';\n\n.requestSent {\n  color: $green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
