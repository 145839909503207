import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiResponse,
  CapsaSettingsTypeIds,
  CartResponse,
  CartSearchRequest,
  DeviceTypeIds,
  throwIfBadRespReturnPayloadOnly,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { GridFilterHelperService } from '@capsa/services/grid-filter-helper/grid-filter-helper.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { Constants } from 'app/common/constants';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-cart-management',
  templateUrl: './cart-management.component.html',
  styleUrls: ['./cart-management.component.scss'],
})
export class CartManagementComponent implements OnInit, OnDestroy {
  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<CartResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;
  private query: Partial<CartSearchRequest> = {
    SortInfo: [{ ColumnName: 'Name', IsDescending: false }],
  };

  public deviceTypeId: number | undefined;
  public organizationId: number | undefined;
  public facilityId: number | undefined;
  public includeInactive = false;
  public sort: SortDescriptor[] = [];
  public cartListFilter: CompositeFilterDescriptor;
  public Constants = Constants;
  private allFacilities: number[];

  public get showTrioCols() {
    return this.deviceTypeId === DeviceTypeIds.CareLink_2;
  }

  public get canExport() {
    return this.facilityId !== undefined;
  }

  public get canExportAll() {
    return this.allFacilities !== undefined && this.allFacilities.length > 1;
  }

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Carts];

  public loadingError: ApiResponse<any> | null;

  private apiSearchSub: Subscription = new Subscription();
  private subs: Subscription = new Subscription();

  constructor(
    private cartApi: CartApi,
    private userEofCache: UserEofCacheService,
    private router: Router,
    private filterService: GridFilterHelperService,
    private toasterService: ToasterService,
    private permissionService: PermissionService,
    public loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.allFacilities = this.userEofCache.facilities.map((f) => f.Id);

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.skip = 0;
    this.resetPagingAndData();
    this.loadGridData();
  }

  onFacilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    this.skip = 0;
    this.resetPagingAndData();
    this.loadGridData();
  }

  onDeviceTypeChanged(newValue: number | undefined) {
    this.deviceTypeId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  onActiveChanged() {
    this.includeInactive = !this.includeInactive;
    this.resetPagingAndData();
    this.loadGridData();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  private loadGridData() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a 'deselect', we want to make sure data doesn't come in
    // after we've went back to an 'unselected' filter (prevents race condition bugs)
    this.apiSearchSub.unsubscribe();

    if (
      this.deviceTypeId === undefined ||
      !this.organizationId ||
      !this.facilityId
    ) {
      return;
    }

    this.gridDataLoading = true;

    this.query = {
      FacilityIds: [this.facilityId],
      OrganizationIds: [this.organizationId],
      DeviceTypeId: this.deviceTypeId === 0 ? undefined : this.deviceTypeId,
      IsActive: true,
      IncludeCapsaSettings: true,
      CapsaSettings: [],
      IncludeOnlyTheseSettings: [
        CapsaSettingsTypeIds.CLI_CartIsActive,
        CapsaSettingsTypeIds.CLI_SyncIntervalTimeInMinutes,
      ],
      DeviceKeyValues: [
        Constants.Key_AppVersion,
        Constants.Key_BSPVersion,
        Constants.Key_DistributionBoardApplicationVersion,
      ],
      SortInfo: this.query.SortInfo,
    };

    if (!this.includeInactive) {
      // When searching ONLY for active carts, we explicitly ask the API for those carts
      // but we don't care to see the settings returned because we assume the API is correct in
      // returning ONLY active carts
      this.query.CapsaSettings.push({
        Key: CapsaSettingsTypeIds.CLI_CartIsActive,
        Value: '1',
      });
    }

    const transformation = (fieldName: string, filter: FilterDescriptor) => {
      if (fieldName === 'Name') {
        this.query.Name = filter.value;
      } else if (fieldName === 'SerialNumber') {
        this.query.SerialNumber = filter.value;
      }
    };

    this.filterService.transform(this.cartListFilter, transformation);

    this.apiSearchSub = this.cartApi
      .searchCarts(
        {
          PageNumber: this.skip / this.pageSize + 1,
          PageSize: this.pageSize,
        },
        this.query
      )
      .pipe(first())
      .subscribe(
        (x) => {
          this.totalItems = x.TotalRecordCount;

          if (this.includeInactive) {
            x.Result.forEach((cart) => {
              if (cart.Settings) {
                const isActiveSetting = cart.Settings.find(
                  (setting) =>
                    setting.SettingType ===
                    CapsaSettingsTypeIds.CLI_CartIsActive
                );
                cart.CLI_CartIsActive = isActiveSetting.SettingValue === '1';
              }
              cart.IsConnected = this.getConnectionStatus(cart);
            });
          } else {
            x.Result.forEach((cart) => {
              cart.CLI_CartIsActive = true;
              cart.IsConnected = this.getConnectionStatus(cart);
            });
          }
          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(x.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...x.Result,
            ]);
          }

          this.gridDataLoading = false;
        },
        (_error) => {
          this.toasterService.showError('GETTING_CARTS_LIST_FAILED');
          this.gridDataLoading = false;
        }
      );
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  public onExportAll() {
    if (!this.canExportAll) {
      return;
    }
    this.exportCarts(this.allFacilities);
  }

  public onExportFacility() {
    if (!this.facilityId) {
      return;
    }
    this.exportCarts([this.facilityId]);
  }

  private exportCarts(facilityIds: number[]) {
    this.loaderService.start();
    const sub = this.cartApi
      .getCartExport(facilityIds)
      .pipe(throwIfBadRespReturnPayloadOnly())
      .subscribe(
        (url) => {
          window.open(url);
          this.loaderService.stop();
        },
        (error) => {
          this.toasterService.showError('GET_CART_EXPORT_FAIL');
          this.loaderService.stop();
        }
      );
    this.subs.add(sub);
  }

  public onSelectCart(event: Event, item: CartResponse) {
    this.router.navigate(['carts', 'manage', item.CartId]);
  }

  public onCartListFilterChanged(data: CompositeFilterDescriptor) {
    this.cartListFilter = data;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public getConnectionStatus(cart: CartResponse): boolean {
    const syncInterval = cart.Settings.find(
      (s) =>
        s.SettingType === CapsaSettingsTypeIds.CLI_SyncIntervalTimeInMinutes
    );

    if (!cart.LastImAliveUtc) {
      return false;
    }
    let parsedSyncInterval: number;
    if (syncInterval) {
      parsedSyncInterval = Number.parseInt(syncInterval.SettingValue, 10);
    } else {
      parsedSyncInterval = 10;
    }
    // a device is considered not connected if a whole power sync interval
    // has lapsed with no communication from the device.
    const expectedConnectionTime = moment
      .utc()
      .add(parsedSyncInterval * -2, 'minutes');

    const lastConnectionTime = moment.utc(cart.LastImAliveUtc);

    return expectedConnectionTime.isBefore(lastConnectionTime.local().toDate());
  }

  public sortChange(sort: SortDescriptor[]): void {
    if (this.gridDataLoading) {
      return;
    }
    this.sort = sort;
    this.query.SortInfo = [];
    this.sort.forEach((col) => {
      if (!col.dir) {
        return;
      }
      this.query.SortInfo.push({
        ColumnName: col.field,
        IsDescending: col.dir === 'desc',
      });
    });

    this.skip = 0;
    this.loadGridData();
  }
}
