import { Injectable } from '@angular/core';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractDropDownListDataSource<TModel> {
  get selection() {
    return this.currentSelection;
  }

  public debugName: string;

  public abstract get defaultItem(): AsyncDropDownValue<TModel> | undefined;

  constructor() {
    this.map = this.map.bind(this);
    this.callApi = this.callApi.bind(this);
    this.select = this.select.bind(this);
    this.load = this.load.bind(this);
  }
  public loading: boolean;

  public autoSelect = false;

  private currentSelection: AsyncDropDownValue<TModel> | undefined;

  public items$: Observable<AsyncDropDownValue<TModel>[]>;

  public data: AsyncDropDownValue<TModel>[] = [];

  protected abstract callApi(): Observable<TModel[]>;

  protected abstract map(apiModel: TModel): AsyncDropDownValue<TModel>;
  protected abstract select(
    items: AsyncDropDownValue<TModel>[]
  ): AsyncDropDownValue<TModel> | undefined;

  public reset(): void {
    this.selectDefaultItem();
    this.data = [];
    this.items$ = of<AsyncDropDownValue<TModel>[]>([]);
  }

  public selectDefaultItem(): void {
    this.currentSelection = this.defaultItem;
  }

  public selectItem(newItem: AsyncDropDownValue<TModel>): void {
    this.currentSelection = newItem;
  }

  public resetAndLoad(): Observable<AsyncDropDownValue<TModel>[]> {
    this.reset();
    return this.load();
  }

  public load(): Observable<AsyncDropDownValue<TModel>[]> {
    this.loading = true;
    return (this.items$ = this.callApi().pipe(
      // This takes care of issues that arose from how the eof drop downs are built.
      // basically, many components update their public state in the ngInit()
      // method, which can cause issues with Angular component state checking.
      // To fix this in all the areas we use these types of drop-downs, we can add
      // this little delay bit to fix the problem.
      delay(0),
      map((apiModels) =>
        apiModels
          .map((model) => this.map(model))
          .sort((a, b) => a.name.localeCompare(b.name))
      ),
      tap((items) => {
        this.currentSelection = this.select(items);
        if (!this.currentSelection) {
          this.currentSelection = this.defaultItem;
        }
      }),
      tap((items) => {
        if (items.length === 1) {
          this.currentSelection = items[0];
        }
      }),
      tap(() => {
        this.loading = false;
      })
    ));
  }
}
