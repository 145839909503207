import { Component } from '@angular/core';
import { ActivitySearchRequest, CareLinkActivityCategories } from '@capsa/api';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ActivityLogColumnConfig } from 'app/modules/activity-log/activity-log-view/activity-log-view.component';

@Component({
  selector: 'app-user-cart-activity',
  templateUrl: './user-cart-activity.component.html',
  styleUrls: ['./user-cart-activity.component.scss'],
})
export class UserCartActivityListComponent {
  public perms = Permissions;
  public columns: ActivityLogColumnConfig[] = [
    {
      titleTag: 'COM_DESCRIPTION',
      field: 'Description',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_USER',
      field: 'UserDisplayName',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_CART_NAME',
      field: 'CartName',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_SERIAL_NUMBER',
      field: 'CartSerialNumber',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_DATE',
      field: 'ActivityDateUtc',
      fieldType: 'date',
      sort: true,
      filterable: true,
      width: 230,
    },
    {
      titleTag: 'COM_MESSAGE',
      field: 'Message',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
  ];

  public query: Partial<ActivitySearchRequest> = {
    CareLinkActivityCategories: [CareLinkActivityCategories.CartAccessEvent],
    SortInfo: [{ ColumnName: 'ActivityDateUtc', IsDescending: true }],
  };
}
