import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
} from '@capsa/api';
import { CartManagementService } from 'app/modules/cart-management/cart-management-single/cart-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-management-debug-settings',
  templateUrl: './cart-management-debug-settings.component.html',
  styleUrls: ['./cart-management-debug-settings.component.scss'],
})
export class CartManagementDebugSettingsComponent implements OnInit, OnDestroy {
  public syncUrlCtrl: UntypedFormControl;
  public syncIntervalCtrl: UntypedFormControl;
  public logFileSizeCtrl: UntypedFormControl;
  public numberOfLogFilesCtrl: UntypedFormControl;
  public formGroup: UntypedFormGroup;

  private subs = new Subscription();

  public get syncInterval() {
    return this.device.Settings.find(
      (s) =>
        s.SettingType === CapsaSettingsTypeIds.CLI_SyncIntervalTimeInMinutes
    );
  }

  // syncUrl got moved elsewhere from the expected return
  // disabling the form field as a quick-fix
  // public get syncUrl() {
  //   return this.device.Settings.find(
  //     s => s.SettingType === CapsaSettingsTypeIds.CLI_SyncUrl
  //   );
  // }

  public get logLevel() {
    return this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartLogLevel
    );
  }

  public get logFileSize() {
    return this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartLogFileSizeInMb
    );
  }

  public get numberOfLogFiles() {
    return this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartMaxNumberOfLogFiles
    );
  }

  public get isPowerLogActive() {
    return this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_PowerLog_IsActive
    );
  }
  constructor(
    @Inject('device')
    public device: CartResponse,
    private cartManagementService: CartManagementService
  ) {
    this.replaceLogLevelsPerNs2463(device);
  }

  public ngOnInit() {
    this.setupForm();
    this.formGroup.updateValueAndValidity();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * NS-2463
   * Removes the "None" option
   * Renames label of "Error" to "Critical"
   */
  private replaceLogLevelsPerNs2463(cart: CartResponse) {
    const logLevelSetting = cart.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartLogLevel
    );

    logLevelSetting.ListItems = logLevelSetting.ListItems.filter(
      (x) => x.Name !== 'None'
    );

    const errorItem = logLevelSetting.ListItems.find((x) => (x.Name = 'Error'));
    errorItem.NameTranslationTag = 'LOG_LEVEL_CRITICAL';
  }

  private getValueFromSetting(setting: CapsaSettingApiModel) {
    return setting.SettingValue.toString();
  }

  private isTrioDebugSizeValid(): ValidatorFn {
    return (ctl: UntypedFormControl) => {
      if (!ctl.dirty) {
        return null;
      }
      if (!this.logFileSizeCtrl.value || !this.numberOfLogFilesCtrl.value) {
        if (!this.logFileSizeCtrl.value) {
          this.logFileSizeCtrl.setErrors({ required: true });
        }

        if (!this.numberOfLogFilesCtrl.value) {
          this.numberOfLogFilesCtrl.setErrors({ required: true });
        }
        return;
      }
      if (this.logFileSizeCtrl.value * this.numberOfLogFilesCtrl.value > 500) {
        this.logFileSizeCtrl.setErrors({ invalidLogSpace: true });
        this.numberOfLogFilesCtrl.setErrors({ invalidLogSpace: true });
        return { invalidLogSpace: true };
      } else {
        this.logFileSizeCtrl.setErrors(null);
        this.numberOfLogFilesCtrl.setErrors(null);
        return null;
      }
    };
  }

  private applySettingsChanges(): void {
    // this.device.Settings.find(
    //   s => s.SettingType === CapsaSettingsTypeIds.CLI_SyncUrl
    // ).SettingValue = this.syncUrlCtrl.value;
    this.device.Settings.find(
      (s) =>
        s.SettingType === CapsaSettingsTypeIds.CLI_SyncIntervalTimeInMinutes
    ).SettingValue = Number.parseInt(
      this.syncIntervalCtrl.value,
      10
    ).toString();
    this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartLogFileSizeInMb
    ).SettingValue = Number.parseInt(this.logFileSizeCtrl.value, 10).toString();
    this.device.Settings.find(
      (s) => s.SettingType === CapsaSettingsTypeIds.CLI_CartMaxNumberOfLogFiles
    ).SettingValue = Number.parseInt(
      this.numberOfLogFilesCtrl.value,
      10
    ).toString();
  }

  private setupForm(): void {
    this.syncIntervalCtrl = new UntypedFormControl(
      this.getValueFromSetting(this.syncInterval),
      {
        validators: Validators.compose([
          Validators.required,
          Validators.min(2),
          Validators.max(20),
        ]),
      }
    );

    this.logFileSizeCtrl = new UntypedFormControl(
      this.getValueFromSetting(this.logFileSize),
      {
        validators: Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(250),
          this.isTrioDebugSizeValid(),
        ]),
      }
    );

    this.numberOfLogFilesCtrl = new UntypedFormControl(
      this.getValueFromSetting(this.numberOfLogFiles),
      {
        validators: Validators.compose([
          Validators.required,
          Validators.min(2),
          this.isTrioDebugSizeValid(),
        ]),
      }
    );

    // this.syncUrlCtrl = new FormControl(this.getValueFromSetting(this.syncUrl), {
    //   validators: Validators.compose([
    //     Validators.required,
    //     Validators.minLength(1),
    //     Validators.maxLength(100),
    //   ]),
    // });

    this.formGroup = new UntypedFormGroup({
      // syncUrl: this.syncUrlCtrl,
      syncInterval: this.syncIntervalCtrl,
      logFileSize: this.logFileSizeCtrl,
      numberOfLogFiles: this.numberOfLogFilesCtrl,
    });

    this.subs.add(
      this.logFileSizeCtrl.valueChanges.subscribe(() => {
        this.logFileSizeCtrl.markAsTouched();
        this.logFileSizeCtrl.markAsDirty();
        this.formGroup.updateValueAndValidity();
      })
    );

    this.subs.add(
      this.numberOfLogFilesCtrl.valueChanges.subscribe(() => {
        this.numberOfLogFilesCtrl.markAsTouched();
        this.numberOfLogFilesCtrl.markAsDirty();
        this.formGroup.updateValueAndValidity();
      })
    );

    this.subs.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.applySettingsChanges();
        this.formGroup.markAllAsTouched();
      })
    );

    this.subs.add(
      this.formGroup.statusChanges.subscribe((statusInfo) => {
        switch (statusInfo) {
          case 'VALID':
            this.cartManagementService.updateDebugSettingsValidation(true);
            break;
          default:
            this.cartManagementService.updateDebugSettingsValidation(false);
        }
      })
    );
  }
}
