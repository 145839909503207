import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  InputsModule,
  SwitchModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CartManagementDebugSettingsComponent } from 'app/modules/cart-management/cart-management-debug/cart-management-debug-settings/cart-management-debug-settings.component';
import { CartManagementDebugComponent } from 'app/modules/cart-management/cart-management-debug/cart-management-debug.component';
import { CartManagementSingleComponent } from 'app/modules/cart-management/cart-management-single/cart-management-single.component';
import { AvaloCartManagementSoundsTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/avalo-cart-management-tabs/avalo-cart-management-sounds-tab/avalo-cart-management-sounds-tab.component';
import { SharedCartManagementUsersTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/shared-cart-management-tabs/shared-cart-management-users-tab.component';
import { CartManagementComponent } from 'app/modules/cart-management/cart-management.component';
import { DataDrivenControlsModule } from 'app/modules/data-driven-controls/data-driven-controls.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { AvaloCartManagementGeneralTabComponent } from './cart-management-single/device-types/avalo-cart-management-tabs/avalo-cart-management-general-tab/avalo-cart-management-general-tab.component';
import { TrioCartManagementDebugTabComponent } from './cart-management-single/device-types/trio-cart-management-tabs/trio-cart-management-debug-tab/trio-cart-management-debug-tab.component';
import { TrioCartManagementGeneralTabComponent } from './cart-management-single/device-types/trio-cart-management-tabs/trio-cart-management-general-tab/trio-cart-management-general-tab.component';
import { TrioCartManagementLightingTabComponent } from './cart-management-single/device-types/trio-cart-management-tabs/trio-cart-management-lighting-tab/trio-cart-management-lighting-tab.component';

@NgModule({
  declarations: [
    CartManagementComponent,
    CartManagementSingleComponent,
    CartManagementDebugComponent,
    CartManagementDebugSettingsComponent,
    TrioCartManagementDebugTabComponent,
    TrioCartManagementGeneralTabComponent,
    SharedCartManagementUsersTabComponent,
    AvaloCartManagementGeneralTabComponent,
    AvaloCartManagementSoundsTabComponent,
    TrioCartManagementLightingTabComponent,
  ],
  imports: [
    DialogsModule,
    WindowModule,
    LoadingIndicatorModule,
    LabelsModule,
    DataDrivenControlsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    GridModule,
    ButtonsModule,
    ApiModule,
    FormsModule,
    SwitchModule,
    TabStripModule,
    CapsaDropdownsModule,
    TranslateModule,
    ReactiveFormsModule,
    TextBoxModule,
    PipesModule,
    InputsModule,
  ],
})
export class CartManagementModule {}
