import { Component, Input, Output, ViewChild } from '@angular/core';
import { DateRangePopupComponent } from '@progress/kendo-angular-dateinputs';
import {
  BaseFilterCellComponent,
  FilterService,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';

import { filterClearIcon } from '@progress/kendo-svg-icons';
import { EventEmitter } from 'events';

// Copied (and modified) directly from Kendo example:
// https://www.telerik.com/kendo-angular-ui/components/grid/filtering/reusable-filter/#toc-filtering-by-date-ranges
@Component({
  selector: 'app-date-range-filter-cell',
  styleUrls: ['./date-range-filter-cell.component.scss'],
  templateUrl: './date-range-filter-cell.component.html',
})
export class DateRangeFilterCellComponent extends BaseFilterCellComponent {
  @Input()
  public filter: CompositeFilterDescriptor;

  @Input()
  public field: string;

  @Output()
  public clearDateFilter = new EventEmitter();

  @ViewChild('dateRangePopup', { static: false })
  public drPopup: DateRangePopupComponent;

  public icons = { filterClearIcon: filterClearIcon };

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get start(): Date {
    const first = this.findByOperator('gte');

    return (first || ({} as FilterDescriptor)).value;
  }

  public get end(): Date {
    const end = this.findByOperator('lte');
    return (end || ({} as FilterDescriptor)).value;
  }

  public get hasFilter(): boolean {
    return this.filtersByField(this.field).length > 0;
  }

  public clearFilter(): void {
    this.filterService.filter(this.removeFilter(this.field));
  }

  public filterRange(startDate: Date, endDate: Date): void {
    this.filter = this.removeFilter(this.field);
    const filters = [];
    if (startDate) {
      filters.push({
        field: this.field,
        operator: 'gte',
        value: startDate,
      });
    }
    if (endDate) {
      filters.push({
        field: this.field,
        operator: 'lte',
        value: endDate,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }

    if (this.filterHasBothDates(root)) {
      this.drPopup.toggle(false);
      this.filterService.filter(root);
    }
  }

  private filterHasBothDates(filter: CompositeFilterDescriptor) {
    if (!filter || filter.filters.length === 0) {
      return false;
    }

    const dateFilters = filter.filters.filter((x) => {
      if (this.isFilterDescriptor(x)) {
        const f = x as FilterDescriptor;
        if (f.field === this.field) {
          return true;
        }
      } else {
        throw new Error(
          'Manging composite filter descriptors has not yet been implemented. Check N-Sight codebase.'
        );
      }
    });

    return dateFilters.length === 2;
  }

  private isFilterDescriptor(
    filter: FilterDescriptor | CompositeFilterDescriptor
  ) {
    return filter.hasOwnProperty('operator');
  }

  private findByOperator(op: string): FilterDescriptor {
    return this.filtersByField(this.field).filter(
      ({ operator }) => operator === op
    )[0];
  }
}
