import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonsModule,
  DropDownButtonModule,
} from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { SupportFacilityAddComponent } from 'app/modules/support/support-facility-add/support-facility-add.component';
import { CartTransferDialogComponent } from 'app/modules/support/support-transfer-carts/cart-transfer-dialog/cart-transfer-dialog.component';
import { SupportTransferCartEnterpriseComponent } from 'app/modules/support/support-transfer-carts/support-transfer-cart-enterprise/support-transfer-cart-enterprise.component';
import { SupportTransferCartFacilityComponent } from 'app/modules/support/support-transfer-carts/support-transfer-cart-facility/support-transfer-cart-facility.component';
import { SupportTransferCartsComponent } from 'app/modules/support/support-transfer-carts/support-transfer-carts.component';
import { SupportTransferCartOrgComponent } from './support-transfer-carts/support-transfer-cart-org/support-transfer-cart-org.component';

@NgModule({
  declarations: [
    SupportFacilityAddComponent,
    SupportTransferCartsComponent,
    SupportTransferCartFacilityComponent,
    SupportTransferCartEnterpriseComponent,
    CartTransferDialogComponent,
    SupportTransferCartOrgComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    SwitchModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputsModule,
    LabelsModule,
    ButtonsModule,
    ApiModule,
    DropDownButtonModule,
    CapsaDropdownsModule,
    DropDownListModule,
    GridModule,
    LoadingIndicatorModule,
    DialogModule,
    ProgressBarModule,
  ],
})
export class SupportModule {}
