import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MessageQueueResponse } from '@capsa/api';
import { MessageApi } from '@capsa/api/message';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message-edit-dialog',
  templateUrl: './message-edit-dialog.component.html',
  styleUrls: ['./message-edit-dialog.component.scss'],
})
export class MessageEditDialogComponent implements OnDestroy {
  @Input()
  public selectedMessage: MessageQueueResponse;

  @Output()
  public cancel = new EventEmitter();

  @Output()
  public saved = new EventEmitter();

  private subs = new Subscription();

  constructor(private api: MessageApi, public loaderService: LoaderService) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onClose() {
    this.cancel.emit();
  }

  public ackMessage() {
    this.loaderService.start();
    const sub = this.api
      .ackMessage(this.selectedMessage.MessageQueueId)
      .subscribe(
        (result) => {
          this.loaderService.stop();
          this.saved.emit();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
    this.subs.add(sub);
  }
}
