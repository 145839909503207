import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropDownItem } from '@capsa/dropdowns/enum';

@Component({
  selector: 'app-simple-drop-down',
  templateUrl: './simple-drop-down.component.html',
  styleUrls: ['./simple-drop-down.component.scss'],
})
export class SimpleDropDownComponent implements OnInit, OnChanges {
  @Input()
  public items: DropDownItem[];

  @Input()
  public headerText: string;

  @Input()
  public value: string;

  @Input()
  public isDisabled = false;

  public selectedValue: DropDownItem | undefined;

  @Output()
  valueChanged: EventEmitter<DropDownItem> = new EventEmitter<DropDownItem>();

  public onChange(newValue: DropDownItem | undefined) {
    this.valueChanged.emit(newValue);
  }

  public ngOnInit(): void {
    if (this.value !== undefined) {
      this.selectedValue = this.findItem(this.value);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const valueChange = changes.value;
    if (valueChange && valueChange.currentValue) {
      this.selectedValue = this.findItem(valueChange.currentValue);
    }
  }

  private findItem(val: string | number) {
    return this.items.find((i) => i.Value.toString() === val.toString());
  }
}
