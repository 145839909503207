import { Injectable } from '@angular/core';
import { DeviceTypeIds, DeviceUpdateType, FirmwareResponse } from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceUpdateCacheService {
  constructor(
    private firmHardWareApi: FirmHardWareApi,
    private toasterService: ToasterService
  ) {}

  private cachedFw = new Map<
    DeviceTypeIds,
    Map<DeviceUpdateType, FirmwareResponse[]>
  >();

  public getFwList(
    deviceTypeId: DeviceTypeIds,
    updateType?: DeviceUpdateType
  ): Observable<FirmwareResponse[]> {
    if (!deviceTypeId) {
      this.toasterService.showError('DeviceTypeId not specified');
      throw new Error('DeviceTypeId not specified');
    }

    if (this.cachedFw.has(deviceTypeId)) {
      return of(this.getFwFromCache(deviceTypeId, updateType));
    }

    // Since data was not in cache, call API
    // fill cache
    return this.firmHardWareApi.getFirmwareByDeviceTypeId(deviceTypeId).pipe(
      tap((hwList) => this.fillFwCache(hwList)),
      map(() => this.getFwFromCache(deviceTypeId, updateType))
    );
  }

  private getFwFromCache(
    deviceTypeId: DeviceTypeIds,
    updateType?: DeviceUpdateType
  ): FirmwareResponse[] {
    const dtMap = this.cachedFw.get(deviceTypeId);

    if (!dtMap) {
      return [];
    }

    // Returns only items for the given dtID and updateType
    if (updateType) {
      const updateTypeMap = dtMap.get(updateType);
      return updateTypeMap ? updateTypeMap : [];
    }

    const allHwForDt: FirmwareResponse[] = [];

    // Combines all update types and returns them
    for (const val of dtMap.values()) {
      allHwForDt.push(...val);
    }

    return allHwForDt;
  }

  private fillFwCache(fwList: FirmwareResponse[]) {
    const deviceTypeId = fwList[0].DeviceTypeId;

    // Ensure we have all the same DeviceTypeIds
    if (!fwList.every((h) => h.DeviceTypeId === deviceTypeId)) {
      throw new Error(
        'More than one DeviceTypeId detected in hardware response list'
      );
    }

    if (this.cachedFw.has(deviceTypeId)) {
      return;
    }

    const dtMap = new Map<DeviceUpdateType, FirmwareResponse[]>();

    fwList.forEach((f) => {
      const existList = dtMap.get(f.UpdateType);
      if (!existList) {
        dtMap.set(f.UpdateType, [f]);
      } else {
        existList.push(f);
      }
    });

    this.cachedFw.set(deviceTypeId, dtMap);
  }
}
