import { Component, Input } from '@angular/core';
import { SetPasswordForm } from '../set-password.component';

@Component({
  selector: 'app-set-password-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
})
export class InputsComponent {
  @Input()
  public form: SetPasswordForm;

  @Input()
  public save: () => void;

  @Input()
  public requireCurrentPassword: boolean;
}
