import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@progress/kendo-angular-icons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { PermissionStatusMsgComponent } from 'app/modules/labels/permission-status-msg/permission-status-msgcomponent';
import { BoldLabelComponent } from './bold/bold.component';
import { FadedInfoTextComponent } from './faded-text/faded-text.component';
import { HelpIconComponent } from './help-icon/help-icon.component';

@NgModule({
  declarations: [
    BoldLabelComponent,
    HelpIconComponent,
    FadedInfoTextComponent,
    PermissionStatusMsgComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    TooltipModule,
    IconsModule,
  ],
  exports: [
    BoldLabelComponent,
    HelpIconComponent,
    FadedInfoTextComponent,
    PermissionStatusMsgComponent,
  ],
})
export class LabelsModule {}
