import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Constants } from 'app/common/constants';
import { appendPathToBaseUrl } from 'app/modules/cart-profile/helpers';
import { processCapsaRestApiResponse } from 'app/modules/core/rxjs-operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  CapsaSettingsTypeIds,
  CartBulkAddUsersRequest,
  ColumnSortInfo,
  DeviceTypeIds,
  EnterprisePermissionTree,
  FacilityPermissionTree,
  GeneratePinRequest,
  OrganizationPermissionTree,
  PasswordForceResetRequest,
  SavePinRequest,
  throwIfBadRespFullResp,
  UserCheckIsUserNameUniqueRequestDto,
  UserCreateRequest,
  UserExportRequest,
  UserGenerateCodeRequestBody,
  UserGetByIdRequest,
  UserImportRequest,
  UserPartialResponse,
  UserPasswordResetRequest,
  UserPasswordUpdateRequest,
  UserResponse,
  UserSearchRequest,
  UserSecurityTokenType,
  UserUpdateRequest,
} from '..';
import { BaseApi } from '../base/base.api';

export interface UserApiInstance {
  createUser(request: UserCreateRequest): Observable<ApiResponse<string>>;

  isUsernameUnique(
    request: UserCheckIsUserNameUniqueRequestDto
  ): Observable<ApiResponse<boolean>>;

  getCode(
    userId: string,
    codeType: UserSecurityTokenType
  ): Observable<ApiResponse<string>>;

  getUserList(
    orgId: number,
    facilityId: number,
    includeInactive: boolean,
    pageSize: number,
    pageNumber: number
  ): Observable<ApiResponse<UserPartialResponse[]>>;

  getUserDetails(
    userId: string,
    includeLists: boolean
  ): Observable<ApiResponse<UserResponse>>;

  /**
   * Does an update on ALL user properties, to modify a single property,
   * change it but then pass all other user properties that were
   * received from the "getUserDetails" API endpoint
   */
  updateUser(
    userUpdateRequest: UserUpdateRequest
  ): Observable<ApiResponse<void>>;

  generatePin(
    userId: string,
    facilityId: number,
    deviceType: DeviceTypeIds,
    setting: CapsaSettingsTypeIds
  ): Observable<ApiResponse<string>>;

  importUsers(request: UserImportRequest): Observable<ApiResponse<any>>;

  savePin(
    userId: string,
    facilityId: number,
    pin: string,
    deviceType: DeviceTypeIds
  ): Observable<ApiResponse<string>>;

  resetPasswordToDefault(userId: string): Observable<ApiResponse<any>>;

  forceResetPassword(userId: string): Observable<ApiResponse<any>>;

  updatePassword(
    details: Partial<UserPasswordUpdateRequest>
  ): Observable<ApiResponse<any>>;

  importUsersV2(request: UserImportRequest): Observable<UserResponse[]>;

  exportUsers(request: UserExportRequest): Observable<ApiResponse<string>>;

  resetPasswordUsingSecurityQuestion(
    userId: string
  ): Observable<ApiResponse<any>>;

  bulkAssignCarts(
    request: CartBulkAddUsersRequest
  ): Observable<ApiResponse<void>>;
}

// Not exporting, private
interface EnterprisePermissionTreeResp {
  EnterpriseId: number;
  EnterprisePermissions: string[];
  Organizations: {
    [Id: number]: OrganizationPermissionTreeResp;
  };
}

// Not exporting, private
interface OrganizationPermissionTreeResp {
  OrganizationId: number;
  OrganizationPermissions: string[];
  Facilities: {
    [Id: number]: FacilityPermissionTreeResp;
  };
}

// Not exporting, private
interface FacilityPermissionTreeResp {
  FacilityId: number;
  FacilityPermissions: string[];
}

@Injectable()
export class UserApi extends BaseApi implements UserApiInstance {
  protected endpoints = {
    create: this.buildUrl('user/create'),
    usernameUnique: this.buildUrl('user/CheckIsUserNameUnique'),
    getSecurityCode: this.buildUrl('user/{id}/codes'),
    searchPartial: this.buildUrl('user/searchpartial'),
    getUserById: this.buildUrl('user/GetById'),
    updateUser: this.buildUrl('user/update'),
    generatePin: this.buildUrl('user/{userId}/create-pin'),
    savePin: this.buildUrl('user/{userId}/pin'),
    resetPasswordToDefault: this.buildUrl('user/ResetDefaultPassword'),
    forceResetPassword: this.buildUrl('user/force-reset-password'),
    updatePassword: this.buildUrl('user/UpdatePassword'),
    importUsers: this.buildUrl('user/ImportUsers'),
    importUsersV2: this.buildUrl('users/import'),
    exportUsers: this.buildUrl('user/CareLinkUserExport'),
    resetUsingSecurityQuestion: this.buildUrl('user/password-reset'),
    resendNewEmailConfirmEmail: this.buildUrl(
      'user/{id}/resend-new-email-confirm-email'
    ),
    sendEmailAndSecurityQuestionReset: this.buildUrl(
      'user/{id}/resend-user-registration-email'
    ),

    bulkAssignCarts: this.buildUrl('users/assign-carts'),
    unlockUser: this.buildUrl('user/{id}/unlock'),
    getPermissionTree: this.buildUrl('user/permissions'),
  };
  constructor(
    client: HttpClient,
    private enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
  }

  public createUser(
    request: UserCreateRequest
  ): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(this.endpoints.create, request);
  }

  public isUsernameUnique(
    request: UserCheckIsUserNameUniqueRequestDto
  ): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(
      this.endpoints.usernameUnique,
      request
    );
  }

  public getCode(userId: string, codeType: UserSecurityTokenType) {
    const body: UserGenerateCodeRequestBody = {
      CodeType: codeType,
    };
    return this.http.post<ApiResponse<string>>(
      this.endpoints.getSecurityCode.replace('{id}', userId),
      body
    );
  }

  public getCartAssignedUsers(
    request: Partial<UserSearchRequest>
  ): Observable<ApiResponse<UserPartialResponse[]>> {
    const body: UserSearchRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      PageSize: request.PageSize,
      PageNumber: request.PageNumber,
      CartIds: request.CartIds,
      FirstName: request.FirstName,
      LastName: request.LastName,
      UserName: request.UserName,
      SortInfo: request.SortInfo,
      OrganizationIds: [],
      FacilityIds: request.FacilityIds,
      IncludeInactiveUsers: false,
      Settings: request.Settings,
      OmitUnassignedUsers: true,
      SettingsDeviceTypeId: request.SettingsDeviceTypeId,
    };

    return this.http.post<ApiResponse<UserPartialResponse[]>>(
      this.endpoints.searchPartial,
      body
    );
  }

  public getUserList(
    orgId: number,
    facilityId: number,
    includeInactive: boolean = false,
    pageSize: number = 10000,
    pageNumber: number = 1,
    settings: CapsaSettingsTypeIds[] = [],
    settingsDeviceTypeId?: number,
    omitUnassignedUsers = true,
    email?: string,
    firstName?: string,
    lastName?: string,
    userName?: string,
    sort: ColumnSortInfo[] = []
  ): Observable<ApiResponse<UserPartialResponse[]>> {
    const body: UserSearchRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
      IncludeInactiveUsers: includeInactive,
      PageSize: pageSize,
      PageNumber: pageNumber,
      Settings: settings,
      SettingsDeviceTypeId: settingsDeviceTypeId,
      OmitUnassignedUsers: omitUnassignedUsers,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      UserName: userName,
      SortInfo: sort,
    };

    return this.http.post<ApiResponse<UserPartialResponse[]>>(
      this.endpoints.searchPartial,
      body
    );
  }

  public getUserDetails(
    userId: string,
    includeLists: boolean = false
  ): Observable<ApiResponse<UserResponse>> {
    const body: UserGetByIdRequest = {
      Id: userId,
      IncludeLists: includeLists,
    };

    return this.http
      .post<ApiResponse<UserResponse>>(this.endpoints.getUserById, body)
      .pipe(throwIfBadRespFullResp());
  }

  public updateUser(
    userUpdateRequest: UserUpdateRequest
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.updateUser, userUpdateRequest)
      .pipe(throwIfBadRespFullResp());
  }

  /**
   * Instantly unlocks a user that was locked out due to too many failed login attempts
   */
  public unlockUser(targetUserId: string): Observable<ApiResponse<void>> {
    return this.http
      .put<ApiResponse<void>>(
        this.endpoints.unlockUser.replace('{id}', targetUserId),
        null
      )
      .pipe(throwIfBadRespFullResp());
  }

  public resendNewEmailConfirmEmail(targetUserId: string) {
    return this.http
      .post<ApiResponse<void>>(
        this.endpoints.resendNewEmailConfirmEmail.replace('{id}', targetUserId),
        null
      )
      .pipe(throwIfBadRespFullResp());
  }

  public sendEmailAndSecurityQuestionReset(targetUserId: string) {
    return this.http
      .post<ApiResponse<void>>(
        this.endpoints.sendEmailAndSecurityQuestionReset.replace(
          '{id}',
          targetUserId
        ),
        null
      )
      .pipe(throwIfBadRespFullResp());
  }

  public generatePin(
    userId: string,
    facilityId: number,
    deviceType: DeviceTypeIds,
    setting: CapsaSettingsTypeIds
  ): Observable<ApiResponse<string>> {
    const request: GeneratePinRequest = {
      FacilityId: facilityId,
      DeviceType: deviceType,
      SettingType: setting,
    };
    return this.http.post<ApiResponse<string>>(
      this.endpoints.generatePin.replace('{userId}', userId),
      request
    );
  }

  public savePin(
    userId: string,
    facilityId: number,
    pin: string,
    deviceType: DeviceTypeIds
  ): Observable<ApiResponse<string>> {
    const request: SavePinRequest = {
      FacilityId: facilityId,
      Pin: pin,
      DeviceType: deviceType,
    };
    return this.http.put<ApiResponse<string>>(
      this.endpoints.savePin.replace('{userId}', userId),
      request
    );
  }

  public resetPasswordToDefault(userId: string): Observable<ApiResponse<any>> {
    const request: UserPasswordResetRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      UserId: userId,
    };
    return this.http.post<ApiResponse<any>>(
      this.endpoints.resetPasswordToDefault,
      request
    );
  }

  public forceResetPassword(userId: string): Observable<ApiResponse<any>> {
    const request: PasswordForceResetRequest = {
      UserId: userId,
      ReturnUrl: appendPathToBaseUrl(this.loginPortalBaseUrl, 'reset-password'),
      TranslationRequest: {
        ProductLine: Constants.portalProductLine,
        Context: Constants.portalContext,
      },
    };

    return this.http
      .post<ApiResponse<any>>(this.endpoints.forceResetPassword, request)
      .pipe(throwIfBadRespFullResp());
  }

  public updatePassword(
    details: Partial<UserPasswordUpdateRequest>
  ): Observable<ApiResponse<void>> {
    const request: UserPasswordUpdateRequest = {
      ...(details as any),
      EnterpriseId: this.enterpriseService.enterpriseId,
    };
    return this.http
      .post<ApiResponse<void>>(this.endpoints.updatePassword, request)
      .pipe(throwIfBadRespFullResp());
  }

  public importUsers(request: UserImportRequest): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(
      this.endpoints.importUsers,
      request
    );
  }

  public importUsersV2(request: UserImportRequest): Observable<UserResponse[]> {
    return this.http.post<UserResponse[]>(
      this.endpoints.importUsersV2,
      request
    );
  }

  public exportUsers(
    request: UserExportRequest
  ): Observable<ApiResponse<string>> {
    return this.http
      .post(this.endpoints.exportUsers, request)
      .pipe(processCapsaRestApiResponse());
  }
  public resetPasswordUsingSecurityQuestion(
    userId: string
  ): Observable<ApiResponse<any>> {
    const request: UserPasswordResetRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      UserId: userId,
    };
    return this.http.post<ApiResponse<any>>(
      this.endpoints.resetPasswordToDefault,
      request
    );
  }

  public bulkAssignCarts(
    request: CartBulkAddUsersRequest
  ): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.bulkAssignCarts,
      request
    );
  }

  public getPermissionTree(): Observable<EnterprisePermissionTree> {
    return this.http
      .get<EnterprisePermissionTreeResp>(this.endpoints.getPermissionTree)

      .pipe(
        // Since the API serializes Hashmaps and Dictionaries, we need to "transform" them into the Typescript
        // Set/Hashset Dictionary/Map equivalents
        map((resp) => this.transformPermTreeResponseToTypedObj(resp))
      );
  }

  private transformPermTreeResponseToTypedObj(
    entPermTreeResp: EnterprisePermissionTreeResp
  ): EnterprisePermissionTree {
    const entPermTreeFinal: EnterprisePermissionTree = {
      EnterpriseId: entPermTreeResp.EnterpriseId,
      EnterprisePermissions: new Set(entPermTreeResp.EnterprisePermissions),
      Organizations: new Map<number, OrganizationPermissionTree>(),
    };

    if (
      entPermTreeResp.Organizations !== null &&
      Object.keys(entPermTreeResp.Organizations).length > 0
    ) {
      // Note: we disable the es-lint rule since we know we're getting
      // data from the API that will only have numeric keys, and
      // nothing else (i.e. no functions)
      // eslint-disable-next-line guard-for-in
      for (const orgId in entPermTreeResp.Organizations) {
        const orgPermRawData = entPermTreeResp.Organizations[orgId];
        const orgPermTree = this.getOrgPermTrees(orgPermRawData);
        entPermTreeFinal.Organizations.set(
          orgPermTree.OrganizationId,
          orgPermTree
        );
      }
    }

    return entPermTreeFinal;
  }

  private getOrgPermTrees(
    rawData: OrganizationPermissionTreeResp
  ): OrganizationPermissionTree {
    const orgPermTree: OrganizationPermissionTree = {
      OrganizationId: rawData.OrganizationId,
      OrganizationPermissions: new Set<string>(rawData.OrganizationPermissions),
      Facilities: this.getFacilityPermTreesMap(rawData.Facilities),
    };

    return orgPermTree;
  }

  private getFacilityPermTreesMap(rawFacilityData: {
    [Id: number]: FacilityPermissionTreeResp;
  }): Map<number, FacilityPermissionTree> {
    const facMap = new Map<number, FacilityPermissionTree>();

    if (rawFacilityData === null || Object.keys(rawFacilityData).length === 0) {
      return facMap;
    }

    // Note: we disable the es-lint rule since we know we're getting
    // data from the API that will only have numeric keys, and
    // nothing else (i.e. no functions)
    // eslint-disable-next-line guard-for-in
    for (const fId in rawFacilityData) {
      const facPermRawData = rawFacilityData[fId];
      facMap.set(facPermRawData.FacilityId, {
        FacilityId: facPermRawData.FacilityId,
        FacilityPermissions: new Set<string>(
          facPermRawData.FacilityPermissions
        ),
      });
    }

    return facMap;
  }
}
