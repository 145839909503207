import { Injectable } from '@angular/core';
import { ApiResponse, UserCreateRequest } from '@capsa/api';
import { UserApi } from '@capsa/api/user/user.api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IUserCreateResponse {
  userId: string;
  response: ApiResponse<any>;
}

export interface IUserService {
  createUser(
    user: UserCreateRequest,
    isGeneratePin: boolean
  ): Observable<IUserCreateResponse>;
  checkUsernameUniqueness(username: string): Observable<ApiResponse<boolean>>;
}

@Injectable()
export class UserService implements IUserService {
  constructor(private userApi: UserApi) {}

  /**
   * Throws an error if the "Success" property is false
   * @returns exactly what was passed in
   */
  private processSuccess = <TData>() =>
    map<ApiResponse<TData>, ApiResponse<TData>>((m) => {
      if (m.Success) {
        return m;
      }
      throw m;
    });

  public createUser(user: UserCreateRequest): Observable<IUserCreateResponse> {
    const finalResponse = this.userApi
      .createUser(user)
      .pipe(this.processSuccess())
      .pipe(map((resp) => ({ userId: resp.Result, response: resp })));

    return finalResponse;
  }

  public checkUsernameUniqueness(username: string) {
    return this.userApi
      .isUsernameUnique({ UserName: username })
      .pipe(this.processSuccess());
  }
}
