import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CapsaRoleResponseDto } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { CapsaRoleMultiSelectDataSource } from '@capsa/dropdowns/capsa-role-multi-select/capsa-role-multi-select-data-source';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-capsa-role-multi-select',
  templateUrl: './capsa-role-multi-select.component.html',
  styleUrls: ['./capsa-role-multi-select.component.css'],
})
export class CapsaRoleMultiSelectComponent {
  @Input()
  readonly: boolean;

  @Input()
  readonlyText: string;

  @Input()
  public dataSource: CapsaRoleMultiSelectDataSource;

  @Input()
  public required = false;

  @Input()
  public forceValidate$: Observable<void>;

  @Output()
  valueChanged = new EventEmitter<AsyncDropDownValue<CapsaRoleResponseDto>[]>();

  constructor() {}
}
