import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LanguageChangeComponent } from 'app/modules/language-change/language-change.component';

@NgModule({
  declarations: [LanguageChangeComponent],
  imports: [CommonModule, TranslateModule, DropDownsModule],
  exports: [LanguageChangeComponent],
})
export class LanguageChangeModule {}
