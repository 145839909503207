import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NameWithStringIdApiModel } from '@capsa/api';
import { CommonApi } from '@capsa/api/common';
import { AsyncDropDownListComponent } from '@capsa/dropdowns/async-drop-down-list/async-drop-down-list.component';
import { TimezoneDropDownDataSource } from '@capsa/dropdowns/timezone-drop-down/timezone-drop-down-datasource';

@Component({
  selector: 'app-timezone-drop-down',
  templateUrl: './timezone-drop-down.component.html',
  styleUrls: ['./timezone-drop-down.component.scss'],
})
export class TimezoneDropDownComponent extends AsyncDropDownListComponent<NameWithStringIdApiModel> {
  dataSource: TimezoneDropDownDataSource;

  constructor(service: CommonApi, translations: TranslateService) {
    super(translations);
    this.dataSource = new TimezoneDropDownDataSource(service, translations);
  }
}
