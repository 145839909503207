import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AppInsightsSettingsInstance,
  AppSettingsService,
} from '@capsa/services/app-settings/app-settings.service';
import { AuthService } from '@capsa/services/auth/auth.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IPageViewTelemetry,
} from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { APP_VERSION } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
// Typescript wrapper for App Insights library
export class AppInsightsService {
  private isEnabled: boolean;
  private appInsights: ApplicationInsights;

  constructor(
    private appSettingsService: AppSettingsService,
    private authService: AuthService,
    private router: Router
  ) {
    this.appSettingsService.loaded$.subscribe((loaded) => {
      if (loaded) {
        this.init();
      }
    });
  }

  private init() {
    const config =
      this.appSettingsService.get<AppInsightsSettingsInstance>('appInsights');

    const angularPlugin = new AngularPlugin();

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: config.connectionString,

        // False is the default, but leaving here to make it clear that
        // we're tracking route changes manually
        enableAutoRouteTracking: false,
        extensions: [angularPlugin],
        enableDebug: true,
        loggingLevelConsole: 2, // logs internal App Insights errors and warnings to console
        loggingLevelTelemetry: 1, // Send App Insights critical errors as telemetry
        // /* ...Other Configuration Options...
        // https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=snippet#configuration
        //  */
      },
    });

    this.isEnabled = !!config.connectionString;
    if (!this.isEnabled) {
      return;
    }

    this.authService.loadedCurrentUser$.subscribe(() => {
      if (this.authService.currentUser?.UserName) {
        this.setUser(this.authService.currentUser?.UserName);
      }
    });

    this.appInsights.loadAppInsights();

    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe((_ev: NavigationEnd) => {
        const data: IPageViewTelemetry = {
          properties: {
            appVersion: APP_VERSION,
          },
        };

        this.appInsights.trackPageView(data);
      });
  }

  private setUser(user: string) {
    if (!this.isEnabled) {
      return;
    }

    this.appInsights.setAuthenticatedUserContext(user);
  }

  /**
   * Records a button click event
   * @param name button name
   * @param customProps any other details that would be helpful in regards to this button click
   */
  public btnClickTrace(name: string, customProps: ICustomProperties = {}) {
    const event: IEventTelemetry = {
      name: 'btnClick',
    };

    if (customProps.hasOwnProperty('buttonName')) {
      throw new Error('Cannot use "buttonName", reserved custom property');
    }

    customProps['buttonName'] = name;
    this.setCustomPropsUserDetails(customProps);
    this.appInsights.trackEvent(event, customProps);
  }

  /**
   * Records a tab click event
   * @param tabIndex tab index
   * @param customProps any other details that would be helpful in regards to this button click
   */
  public tabClickTrace(tabIndex: number, customProps: ICustomProperties = {}) {
    const event: IEventTelemetry = {
      name: 'tabClick',
    };

    if (customProps.hasOwnProperty('tabIndex')) {
      throw new Error('Cannot use "tabIndex", reserved custom property');
    }

    customProps['tabIndex'] = tabIndex;
    this.setCustomPropsUserDetails(customProps);
    this.appInsights.trackEvent(event, customProps);
  }

  /**
   * Records a drop down list value change event
   * @param name button name
   * @param customProps any other details that would be helpful in regards to this button click
   */
  public ddlSelectVal(name: string, customProps: ICustomProperties = {}) {
    const event: IEventTelemetry = {
      name: 'ddlSelect',
    };

    if (customProps.hasOwnProperty('dropDownName')) {
      throw new Error('Cannot use "dropDownName", reserved custom property');
    }

    customProps['dropDownName'] = name;
    this.setCustomPropsUserDetails(customProps);
    this.appInsights.trackEvent(event, customProps);
  }

  private setCustomPropsUserDetails(customProps: ICustomProperties) {
    if (!this.authService.currentUser) {
      return;
    }

    if (customProps.hasOwnProperty('capsaEnterpriseId')) {
      throw new Error(
        'Cannot use "capsaEnterpriseId", reserved custom property'
      );
    }

    if (customProps.hasOwnProperty('capsaUserName')) {
      throw new Error('Cannot use "capsaUserName", reserved custom property');
    }

    if (customProps.hasOwnProperty('capsaUserId')) {
      throw new Error('Cannot use "capsaUserId", reserved custom property');
    }

    customProps['capsaEnterpriseId'] =
      this.authService.currentUser.EnterpriseId;
    customProps['capsaUserName'] = this.authService.currentUser.UserName;
    customProps['capsaUserId'] = this.authService.currentUser.UserId;
  }
}
