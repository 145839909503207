import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DeviceProfileCreateRequestApiModel,
  DeviceProfileSettingRequestDto,
} from '@capsa/api';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';
import {
  DeviceProfileIdToken,
  DynamicTab,
  GenericSettingsByApiModelProperty,
  GenericSettingsToken,
  GridFilterConfiguration,
  SettingsHelperToken,
} from 'app/modules/cart-profile/helpers';
import { DeviceProfileTabDefinitions } from 'app/modules/cart-profile/profile-definitions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-profile-create-or-edit',
  templateUrl: './create-or-edit.component.html',
  styleUrls: ['./create-or-edit.component.scss'],
})
export class CreateOrEditComponent implements OnInit, OnDestroy {
  get tabs(): DynamicTab[] {
    if (!this.isReady) {
      return [];
    }

    return DeviceProfileTabDefinitions[this.filters.deviceTypeId];
  }

  get canSave() {
    return (
      this.canEdit &&
      this.form.valid &&
      !this.saving &&
      this.filters.deviceTypeId !== undefined &&
      !!this.filters.facilityId
    );
  }
  public canEdit = false;
  private subs = new Subscription();

  constructor(
    injector: Injector,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.genericSettings = {} as any;

    this.settingInjector = Injector.create({
      parent: injector,
      providers: [
        {
          provide: DeviceProfileIdToken,
          useFactory: () => this.deviceProfileId,
          deps: [],
        },
        {
          provide: SettingsHelperToken,
          useFactory: () => this.settingsFormHelper,
          deps: [],
        },
        {
          provide: GenericSettingsToken,
          useFactory: () => this.genericSettings,
          deps: [],
        },
      ],
    });
  }
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public isReady: boolean;

  @Input()
  public saving = false;

  @Input()
  public readonly: boolean;

  @Output()
  public loadData = new EventEmitter<GridFilterConfiguration>();

  @Input()
  public settingsFormHelper: ApiSettingsFormHelper;

  @Input()
  public filters: GridFilterConfiguration | undefined;

  @Input()
  public deviceProfileId: number | undefined;

  @Output()
  public save = new EventEmitter<DeviceProfileCreateRequestApiModel>();

  private genericSettings: GenericSettingsByApiModelProperty<DeviceProfileCreateRequestApiModel>;

  public settingInjector: Injector;

  private buildModel(): DeviceProfileCreateRequestApiModel {
    const allSettings = this.settingsFormHelper.flatten();
    return {
      DeviceTypeId: this.filters.deviceTypeId,
      FacilityId: this.filters.facilityId,
      OrganizationId: this.filters.organizationId,
      Name: this.form.controls.name.value,
      Settings: allSettings.map(
        (s) =>
          ({
            SettingId: s.SettingId,
            SettingType: s.SettingType,
            SettingValue: s.SettingValue,
          } as DeviceProfileSettingRequestDto)
      ),
      ...this.genericSettings,
    };
  }

  onSave() {
    const model = this.buildModel();
    this.save.emit(model);
  }

  ngOnInit() {
    this.loadPermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFilterChanged(newFilters: GridFilterConfiguration) {
    this.loadData.emit(newFilters);
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([Permissions.DeviceProfile_ReadWrite])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  public onBackClicked() {
    this.router.navigate(['facilities', 'profiles']);
  }
}
