import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Constants } from 'app/common/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  CartPartialResponse,
  CartSearchRequest,
  CartTransferRequest,
  FacilityCreateRequest,
  ListRowIdNameWithParent,
  NameWithLongIdApiModel,
  throwIfBadRespFullResp,
  throwIfBadRespReturnPayloadOnly,
} from '..';
import { BaseApi } from '../base/base.api';

export interface SetupApiInstance {
  facilityCreate(
    request: Partial<FacilityCreateRequest>
  ): Observable<ApiResponse<number>>;

  getEnterpriseDropDown(): Observable<NameWithLongIdApiModel[]>;
  getOrgDropDown(enterpriseId: number): Observable<NameWithLongIdApiModel[]>;
  getFacilitiesDropDown(
    enterpriseId: number
  ): Observable<NameWithLongIdApiModel[]>;

  enterpriseTransferCart(
    request: CartTransferRequest
  ): Observable<ApiResponse<void>>;

  crossEnterpriseCartSearchPartial(
    request: Partial<CartSearchRequest>
  ): Observable<CartPartialResponse[]>;
}

@Injectable()
export class SetupApi extends BaseApi implements SetupApiInstance {
  protected endpoints = {
    createFacility: this.buildUrl('facility/create'),
    enterpriseDropDown: this.buildUrl(
      'nexsyssetup/enterprise/GetAllForDropdown'
    ),
    orgDropDown: this.buildUrl(
      'nexsyssetup/enterprise/{enterpriseId}/organizations'
    ),
    facilityDropDown: this.buildUrl(
      'nexsyssetup/enterprise/{enterpriseId}/facilities'
    ),
    enterpriseCartTransfer: this.buildUrl('NexsysSetup/CartTransfer'),

    enterpriseCartSearchPartial: this.buildUrl('NexsysSetup/CartSearchPartial'),
  };

  constructor(
    client: HttpClient,
    private enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
  }

  public facilityCreate(
    request: Partial<FacilityCreateRequest>
  ): Observable<ApiResponse<number>> {
    request.EnterpriseId = this.enterpriseService.enterpriseId;
    request.IsActive = true;
    return this.http
      .post<ApiResponse<number>>(this.endpoints.createFacility, request)
      .pipe(throwIfBadRespFullResp());
  }

  public getEnterpriseDropDown(): Observable<NameWithLongIdApiModel[]> {
    return this.http
      .get<ApiResponse<NameWithLongIdApiModel[]>>(
        this.endpoints.enterpriseDropDown
      )
      .pipe(map((resp) => resp.Result || []));
  }

  public getOrgDropDown(
    enterpriseId: number
  ): Observable<NameWithLongIdApiModel[]> {
    return this.http
      .get<ApiResponse<NameWithLongIdApiModel[]>>(
        this.endpoints.orgDropDown.replace(
          '{enterpriseId}',
          enterpriseId.toString()
        )
      )
      .pipe(map((resp) => resp.Result || []));
  }

  public getFacilitiesDropDown(
    enterpriseId: number
  ): Observable<ListRowIdNameWithParent[]> {
    return this.http
      .get<ApiResponse<ListRowIdNameWithParent[]>>(
        this.endpoints.facilityDropDown.replace(
          '{enterpriseId}',
          enterpriseId.toString()
        )
      )
      .pipe(map((resp) => resp.Result || []));
  }

  public enterpriseTransferCart(
    request: CartTransferRequest
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.enterpriseCartTransfer, request)
      .pipe(throwIfBadRespFullResp());
  }

  public crossEnterpriseCartSearchPartial(
    request: Partial<CartSearchRequest>
  ): Observable<CartPartialResponse[]> {
    request.ProductLines = Constants.supportedProductLines;
    return this.http
      .post<ApiResponse<CartPartialResponse[]>>(
        this.endpoints.enterpriseCartSearchPartial,
        request
      )
      .pipe(throwIfBadRespReturnPayloadOnly());
  }
}
