// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  margin-top: 10px;
}

.saveBtn {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/users/user-management/edit-user-cart-settings-tab/edit-user-cart-settings-tab.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".card {\n  margin-top: 10px;\n}\n\n.saveBtn {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
