import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GridFilterConfiguration } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-general-details',
  templateUrl: './general-details.component.html',
  styleUrls: ['./general-details.component.scss'],
})
export class GeneralDetailsComponent implements OnInit {
  constructor() {}

  @Input()
  public filters: GridFilterConfiguration;

  @Input()
  public readonly = false;

  @Input()
  public form: UntypedFormControl;

  @Output()
  public ready = new EventEmitter<GridFilterConfiguration>();

  onOrgChanged(newValue: number | undefined) {
    this.filters.organizationId = newValue;
  }

  onFacilityChanged(newValue: number | undefined) {
    this.filters.facilityId = newValue;
  }

  ngOnInit() {
    if (this.filters.deviceTypeId !== undefined) {
      // Because this sets public state on the component from within ngOnInit,
      // we need to run this code on the next render check cycle instead of during
      // initial rending. If we don't do this, we get a change detection error.
      setTimeout(() => this.ready.emit(this.filters), 0);
    }
  }
}
