import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { FacilityMultiSelectDataSource } from '@capsa/dropdowns/facility-multi-select/facility-multi-select-data-source';

@Component({
  selector: 'app-facility-multi-select',
  templateUrl: './facility-multi-select.component.html',
  styleUrls: ['./facility-multi-select.component.css'],
})
export class FacilityMultiSelectComponent {
  @Input()
  public dataSource: FacilityMultiSelectDataSource;

  @Output()
  valueChanged = new EventEmitter<
    AsyncDropDownValue<NameWithLongIdApiModel>[]
  >();

  constructor() {}
}
