import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsChartRequest, AnalyticsChartResponse } from '..';
import { BaseApi } from '../base/base.api';

export interface AnalyticsApiInstance {
  getChart(request: AnalyticsChartRequest): Observable<AnalyticsChartResponse>;
}

@Injectable()
export class AnalyticsApi extends BaseApi implements AnalyticsApiInstance {
  protected endpoints = {
    getChartUrl: this.buildUrl('analytics/chart'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getChart(
    request: AnalyticsChartRequest
  ): Observable<AnalyticsChartResponse> {
    return this.http
      .post<AnalyticsChartResponse>(this.endpoints.getChartUrl, request)
      .pipe(
        map((resp) => {
          resp.StartDateUtc = this.utcToMoment(resp.StartDateUtc);
          resp.EndDateUtc = this.utcToMoment(resp.EndDateUtc);

          resp.Entities.forEach((x) => {
            x.Values.forEach((val) => {
              val.DateUtc = this.utcToMoment(val.DateUtc);
            });
          });
          return resp;
        })
      );
  }
  private utcToMoment(date: Date) {
    return moment.utc(date as unknown as string).toDate();
  }
}
