import { Component, Input } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { SettingMap } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-light-brightness',
  templateUrl: './light-brightness.component.html',
  styleUrls: ['./light-brightness.component.scss'],
})
export class LightBrightnessComponent {
  @Input()
  public settings: SettingMap;

  get keyboardBrightness() {
    return this.settings.getSetting('Keyboard');
  }

  get floorBrightness() {
    return this.settings.getSetting('Floor');
  }

  get workSurface() {
    return this.settings.getSetting('WorkSurface');
  }

  onChange(setting: CapsaSettingApiModel, newValue: number) {
    setting.SettingValue = newValue.toString();
  }

  constructor() {}
}
