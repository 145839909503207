import { Component, Input } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { DataDrivenSwitchConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-switch',
  templateUrl: './data-driven-switch.component.html',
  styleUrls: ['./data-driven-switch.component.scss'],
})
export class DataDrivenSwitchComponent {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public config: DataDrivenSwitchConfig;

  @Input()
  public disabled = false;

  get valueAsBool(): boolean {
    return this.setting.SettingValue === '1' ? true : false;
  }

  public get onLabelTag(): string {
    if (this.config && this.config.textTagTrue) {
      return this.config.textTagTrue;
    } else {
      return 'COM_YES';
    }
  }

  public get offLabelTag(): string {
    if (this.config && this.config.textTagFalse) {
      return this.config.textTagFalse;
    } else {
      return 'COM_NO';
    }
  }

  constructor() {}

  onValueChanged(value: boolean) {
    this.setting.SettingValue = value ? '1' : '0';
  }
}
