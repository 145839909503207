import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Colors,
  MessageQueueMessageType,
  MessageTemplateResponse,
} from '@capsa/api';
import { MessageTemplateApi } from '@capsa/api/message-template/message-template.api';
import {
  ColorCombo,
  isValidColorCombo,
} from '@capsa/dropdowns/color-combo-drop-down/color-combo-helper';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-message-template-edit-dialog',
  templateUrl: './message-template-edit-dialog.component.html',
  styleUrls: ['./message-template-edit-dialog.component.scss'],
})
export class MessageTemplateEditDialogComponent implements OnInit, OnDestroy {
  @Input()
  private template: MessageTemplateResponse;

  public tempTemplate: MessageTemplateResponse;

  @Input()
  public organizationId: number;

  @Input()
  public facilityId: number;

  @Output()
  public cancel = new EventEmitter();

  @Output()
  public saved = new EventEmitter();

  public form: UntypedFormGroup;
  public name: UntypedFormControl;

  public subject: UntypedFormControl;

  private subs = new Subscription();
  public readonly = false;

  public messageType: MessageQueueMessageType;

  private setColorComboSubj = new BehaviorSubject<ColorCombo | undefined>(
    undefined
  );
  public setColorCombo$ = this.setColorComboSubj.asObservable();

  // To expose the enum to the template
  public messageQueueMessageType = MessageQueueMessageType;

  public messageTypeValues: DropDownItem[] = [
    {
      Label: this.translations.instant('Notification'),
      Value: MessageQueueMessageType.Notification,
    },
    {
      Label: this.translations.instant('Alert'),
      Value: MessageQueueMessageType.Alert,
    },
  ];

  constructor(
    private api: MessageTemplateApi,
    private translations: TranslateService,
    public loaderService: LoaderService
  ) {}

  ngOnInit() {
    // Clone template (to prevent editing original object that's part of the grid)
    this.tempTemplate = { ...this.template };

    this.verifyAndSetValidColors();

    this.name = new UntypedFormControl(this.tempTemplate.Name, {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2),
      ]),
    });
    this.subject = new UntypedFormControl(this.tempTemplate.Subject, {
      validators: Validators.compose([Validators.minLength(2)]),
    });

    this.form = new UntypedFormGroup({
      name: this.name,
      subject: this.subject,
    });

    this.messageType =
      MessageQueueMessageType[this.tempTemplate.MessageQueueMessageType];

    this.setColorComboSubj.next({
      fgColor: this.tempTemplate.ForegroundColor,
      bgColor: this.tempTemplate.BackgroundColor,
    });
    this.tempTemplate = { ...this.tempTemplate };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Handles cases where the database is storing values that
   * are no longer supported by the colors drop down, or if the type is
   * "Notification" and has non default colors (old functionality)
   */
  private verifyAndSetValidColors(): void {
    if (
      !isValidColorCombo(
        this.tempTemplate.ForegroundColor,
        this.tempTemplate.BackgroundColor
      ) ||
      MessageQueueMessageType[this.tempTemplate.MessageQueueMessageType] ===
        MessageQueueMessageType.Notification
    ) {
      this.tempTemplate.ForegroundColor = Colors.Black;
      this.tempTemplate.BackgroundColor = Colors.White;
    }
  }

  public get isFormValid(): boolean {
    return this.form.valid;
  }

  public alertColorChanged(colorCombo: ColorCombo): void {
    this.tempTemplate.ForegroundColor = colorCombo.fgColor;
    this.tempTemplate.BackgroundColor = colorCombo.bgColor;
  }

  public onTypeChanged(newValue: DropDownItem) {
    this.tempTemplate.MessageQueueMessageType = newValue.Label;
    this.messageType = MessageQueueMessageType[newValue.Label];
    if (this.messageType === MessageQueueMessageType.Notification) {
      this.tempTemplate.BackgroundColor = Colors.White;
      this.tempTemplate.ForegroundColor = Colors.Black;
    }
  }

  public onBodyChanged(newValue: string) {
    this.tempTemplate.Body = newValue;
  }

  public onClose() {
    this.cancel.emit();
  }

  public saveTemplate() {
    const saveTemplateTaskId = 'saveTemplateTask';
    this.loaderService.start(saveTemplateTaskId);
    if (this.tempTemplate.MessageQueueTemplateId > 0) {
      // update
      const sub = this.api
        .updateTemplate(this.tempTemplate.MessageQueueTemplateId, {
          MessageQueueTemplateId: this.tempTemplate.MessageQueueTemplateId,
          OrganizationId: this.organizationId,
          FacilityId: this.facilityId,
          Name: this.name.value,
          MessageType:
            MessageQueueMessageType[this.tempTemplate.MessageQueueMessageType],
          Subject: this.subject.value,
          Body: this.tempTemplate.Body,
          BackgroundColor: this.tempTemplate.BackgroundColor,
          ForegroundColor: this.tempTemplate.ForegroundColor,
        })
        .subscribe(
          (result) => {
            this.saved.emit();
            this.loaderService.stop(saveTemplateTaskId);
          },
          (error) => {
            this.loaderService.stop(saveTemplateTaskId);
          }
        );
      this.subs.add(sub);
    } else {
      // create
      const sub = this.api
        .createTemplate({
          OrganizationId: this.organizationId,
          FacilityId: this.facilityId,
          Name: this.name.value,
          MessageType:
            MessageQueueMessageType[this.tempTemplate.MessageQueueMessageType],
          Subject: this.subject.value,
          Body: this.tempTemplate.Body,
          BackgroundColor: this.tempTemplate.BackgroundColor,
          ForegroundColor: this.tempTemplate.ForegroundColor,
        })
        .subscribe(
          (result) => {
            this.saved.emit();
            this.loaderService.stop(saveTemplateTaskId);
          },
          (error) => {
            this.loaderService.stop(saveTemplateTaskId);
          }
        );
      this.subs.add(sub);
    }
  }

  public deleteTemplate() {
    if (this.tempTemplate.MessageQueueTemplateId > 0) {
      const deleteTemplateTaskId = 'deleteTemplateTask';
      this.loaderService.start(deleteTemplateTaskId);
      const sub = this.api
        .deleteTemplate(this.tempTemplate.MessageQueueTemplateId)
        .subscribe(
          (result) => {
            this.loaderService.stop(deleteTemplateTaskId);
            this.saved.emit();
          },
          (error) => {
            this.loaderService.stop(deleteTemplateTaskId);
          }
        );
      this.subs.add(sub);
    } else {
      this.cancel.emit();
    }
  }
}
