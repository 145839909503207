import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeviceTypeIds } from '@capsa/api';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { ErrorBlockComponent } from './error-block/error-block.component';

export type DynamicDeviceTypeTabs = {
  [p in DeviceTypeIds]: DynamicTab[];
};

@NgModule({
  declarations: [ErrorBlockComponent],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [ErrorBlockComponent],
})
export class CoreModule {}
