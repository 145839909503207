import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceTypeIds } from '@capsa/api';

@Component({
  selector: 'app-user-cart-settings',
  templateUrl: './user-cart-settings.component.html',
  styleUrls: ['./user-cart-settings.component.scss'],
})
export class UserCartSettingsComponent {
  constructor() {}
  @Input()
  public deviceTypeId: DeviceTypeIds;

  @Input()
  public dataToProvide: {
    witnessAuthEnabled?: boolean;
  };

  @Output()
  public witnessAuthChanged = new EventEmitter<boolean>();

  public get deviceTypeIds() {
    return DeviceTypeIds;
  }

  public onWitnessAuthChanged(val: boolean) {
    this.witnessAuthChanged.emit(val);
  }
}
