import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnalyticsCacheService } from '@capsa/services/analytics-cache/analytics-cache.service';
import { AnalyticsHelperService } from '@capsa/services/analytics-helper/analytics-helper.service';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { AuthGuardService } from '@capsa/services/auth/auth.guard.service';
import { BlobStorageService } from '@capsa/services/blob-storage/blob-storage.service';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { DeviceUpdateCacheService } from '@capsa/services/device-update-cache/device-update-cache.service';
import { FeatureFlagService } from '@capsa/services/feature-flag/feature-flag.service';
import { NativeWindowService } from '@capsa/services/native-window/native-window.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { SessionTimeoutService } from '@capsa/services/session-timeout/session-timeout.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { UserMemoryService } from '@capsa/services/user-memory/user-memory.service';
import { UserRegistrationService } from '@capsa/services/user/user-registration.service';
import { UserService } from '@capsa/services/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './services/auth/auth.guard';
import { AuthService } from './services/auth/auth.service';
import { EnterpriseService } from './services/enterprise/enterprise.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AnalyticsCacheService,
    AnalyticsHelperService,
    AuthGuard,
    AuthGuardService,
    AuthService,
    AppInsightsService,
    BlobStorageService,
    CapsaRoleService,
    CookieService,
    EnterpriseService,
    FeatureFlagService,
    NativeWindowService,
    PermissionService,
    UserMemoryService,
    UserRegistrationService,
    UserService,
    UserEofCacheService,
    AppSettingsService,
    SessionTimeoutService,
    ToasterService,
    DeviceUpdateCacheService,
  ],
})
export class ServicesModule {}
