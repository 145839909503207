import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { CartManagementService } from 'app/modules/cart-management/cart-management-single/cart-management.service';
import { CartManagementDeviceTypeTabs } from 'app/modules/cart-management/cart-management-single/device-types/device-type-tabs';
import { TrioCartManagementDebugTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/trio-cart-management-tabs/trio-cart-management-debug-tab/trio-cart-management-debug-tab.component';
import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { Subscription } from 'rxjs';
import { finalize, first, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-cart-management-single',
  templateUrl: './cart-management-single.component.html',
  styleUrls: ['./cart-management-single.component.scss'],
  providers: [CartManagementService],
})
export class CartManagementSingleComponent implements OnInit, OnDestroy {
  constructor(
    private cartApi: CartApi,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private rootInjector: Injector,
    private cartManagementService: CartManagementService,
    public loaderService: LoaderService,
    private toasterService: ToasterService
  ) {}

  public canEdit = false;
  public canSeeDebug = false;
  public currentCart: CartResponse;
  public settings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();
  public debugSettings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();

  public deviceTypeTabs: DynamicTab[];
  public deviceTypeTabInjector: Injector;

  private validDebugSettings = true;
  private validGeneralSettings = true;

  public get validSettings() {
    return this.validDebugSettings && this.validGeneralSettings;
  }

  private subs: Subscription = new Subscription();
  private staticCartNamePrivate: string;

  private setInjectedDeviceTokenValue() {
    this.deviceTypeTabInjector = Injector.create({
      parent: this.rootInjector,
      providers: [
        {
          provide: 'device',
          useValue: this.currentCart,
          deps: [],
        },
      ],
    });
  }

  public get staticCartName() {
    return this.staticCartNamePrivate;
  }
  ngOnInit() {
    this.loadPermissions();

    this.subs.add(
      this.cartManagementService.validDebugSettings$.subscribe(
        (value) => (this.validDebugSettings = value)
      )
    );

    this.subs.add(
      this.cartManagementService.validGeneralSettings$.subscribe(
        (value) => (this.validGeneralSettings = value)
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService.isReady$.subscribe(() => {
        this.canEdit = this.permissionService.hasAny([
          Permissions.Manage_Cabinets_ReadWrite,
          Permissions.CLI_Carts_ReadWrite,
        ]);

        this.canSeeDebug = this.permissionService.has(
          Permissions.CAP_Support_RequestDeviceLogs
        );

        this.loadCartInfo();
      })
    );
  }

  private loadCartInfo() {
    this.loaderService.start();
    const sub = this.currentRoute.params
      .pipe(
        map((values) => values.id as number),
        first(),
        mergeMap((id) => this.cartApi.getById(id)),
        finalize(() => this.loaderService.stop())
      )
      .subscribe((resp) => {
        this.refreshCartDetails(resp.Result);
      });

    this.subs.add(sub);
  }

  private refreshCartDetails(cartResponse: CartResponse) {
    // set dynamic tab content
    this.currentCart = cartResponse;

    this.staticCartNamePrivate = this.currentCart.Name;

    this.currentCart.Settings.forEach((s) => {
      this.settings.set(s.SettingType, s);
    });

    // before updating the tabs, we need to update the injected 'device' token
    // so that the new values obtained from the API get injected properly into the
    // dynamic tabs.
    this.setInjectedDeviceTokenValue();

    let tempTabs: DynamicTab[] =
      CartManagementDeviceTypeTabs[cartResponse.DeviceTypeId];

    if (!this.canSeeDebug) {
      tempTabs = tempTabs.filter(
        (x) => x.component !== TrioCartManagementDebugTabComponent
      );
    }

    this.deviceTypeTabs = tempTabs;
  }

  public btnSaveAll() {
    this.save();
  }

  private save() {
    this.loaderService.start();

    const updateSub = this.cartApi
      .update(this.currentCart)
      .pipe(finalize(() => this.loaderService.stop()))
      .subscribe(
        (resp) => {
          this.toasterService.showSuccess('CART_UPDATE_SUCCESS');
          this.loadCartInfo();
        },
        (error) => {
          this.toasterService.showError('CART_UPDATE_FAIL');
        }
      );

    this.subs.add(updateSub);
  }

  public onBackClicked() {
    this.router.navigate(['carts', 'manage']);
  }

  // TODO.... Why is this here? could be to cause "observer count of > 0"... let's leave till it's figured out
  public facilityChanged(newValue: number | undefined) {}
}
