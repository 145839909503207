import { Component } from '@angular/core';
import {
  ActivitySearchRequest,
  AvaloActivityTypes,
  CareLinkActivityTypes,
  DeviceTypeIds,
} from '@capsa/api';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ActivityLogColumnConfig } from 'app/modules/activity-log/activity-log-view/activity-log-view.component';

@Component({
  selector: 'app-cart-fault-logs',
  templateUrl: './cart-fault-logs.component.html',
  styleUrls: ['./cart-fault-logs.component.scss'],
})
export class CartFaultLogsComponent {
  constructor() {}

  public perms = Permissions;

  public columns: ActivityLogColumnConfig[] = [
    {
      titleTag: 'COM_MESSAGE',
      field: 'Message',
      fieldType: 'string',
      filterable: true,
      sort: true,
    },
    {
      titleTag: 'COM_DESCRIPTION',
      field: 'Description',
      fieldType: 'string',
      filterable: true,
      sort: true,
    },
    {
      titleTag: 'COM_DATE',
      field: 'ActivityDateUtc',
      fieldType: 'date',
      width: 230,
      filterable: true,
      sort: true,
    },
    {
      titleTag: 'COM_CART_NAME',
      field: 'CartName',
      fieldType: 'string',
      filterable: true,
      sort: true,
    },
    {
      titleTag: 'COM_SERIAL_NUMBER',
      field: 'CartSerialNumber',
      fieldType: 'string',
      width: 200,
      filterable: true,
      sort: true,
    },
  ];

  private carelinkErrorTypes: number[] = [
    CareLinkActivityTypes.OtaEvent_UpdateFailed,
    CareLinkActivityTypes.RecoveryEvent_PartitionCorrupt,
    CareLinkActivityTypes.RecoveryEvent_PartitionCrash,
    CareLinkActivityTypes.SyncEvent_SyncFailure,
    CareLinkActivityTypes.CartAccessEvent_SerialPortInvalidCommand,
    CareLinkActivityTypes.FaultEvent_PowerSupplyFault1,
    CareLinkActivityTypes.FaultEvent_PowerSupplyFault2,
    CareLinkActivityTypes.FaultEvent_PowerSupplyFault3,
    CareLinkActivityTypes.FaultEvent_PowerSupplyFault4,
    CareLinkActivityTypes.FaultEvent_SdCardFailure,
    CareLinkActivityTypes.Unknown,
  ];

  private avaloErrorTypes: number[] = [
    AvaloActivityTypes.SecureFault,
    AvaloActivityTypes.Unknown,
  ];

  public query: Partial<ActivitySearchRequest> = {
    SortInfo: [{ ColumnName: 'ActivityDateUtc', IsDescending: true }],
  };

  public onDeviceTypeChanged(deviceTypeId: number) {
    if (deviceTypeId === DeviceTypeIds.Avalo) {
      this.query = {
        ...this.query,
        ActivityTypeIds: this.avaloErrorTypes,
      };
    } else {
      this.query = {
        ...this.query,
        ActivityTypeIds: this.carelinkErrorTypes,
      };
    }
  }
}
