import { CapsaSettingApiModel } from '@capsa/api';

import { InjectionToken } from '@angular/core';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';

export interface GridFilterConfiguration {
  deviceTypeId: number | undefined;
  organizationId: number | undefined;
  facilityId: number | undefined;
}

export interface DynamicTab {
  titleTag: string;
  component: any;
}

export const DeviceProfileIdToken = new InjectionToken<number | undefined>(
  'device-profile.deviceProfileId'
);

export const SettingsHelperToken = new InjectionToken<ApiSettingsFormHelper>(
  'device-profile.settingsHelper'
);

export const GenericSettingsToken = new InjectionToken<
  GenericSettingsByApiModelProperty<any>
>('device-profile.genericSettings');

/**
 * To be used specifically to add a path to an existing base URL. Helps avoid a
 * missing or extra slash being added between the splice.
 * This method isn't meant to append a query parameter correctly
 * @param baseUrl (e.g. http://www.google.com)
 * @param toAppend a path to add on to an existing base URL, not meant to be solely a query string
 * @returns a spliced URL with a single slash between the base and "toAppend" parts
 */
export function appendPathToBaseUrl(baseUrl: string, toAppend: string) {
  const baseEndsWithSlash = baseUrl.endsWith('/');
  const toAppendStartsWithSlash = toAppend.startsWith('/');

  if (baseEndsWithSlash && toAppendStartsWithSlash) {
    return baseUrl.substr(0, baseUrl.length - 1) + toAppend;
  } else if (baseEndsWithSlash && !toAppendStartsWithSlash) {
    return baseUrl + toAppend;
  } else if (!baseEndsWithSlash && toAppendStartsWithSlash) {
    return baseUrl + toAppend;
  } else {
    return `${baseUrl}/${toAppend}`;
  }
}

export type GenericSettingsByApiModelProperty<TApiModel> = {
  [apiModelPropertyName in keyof TApiModel]: any;
};

export class SettingMap {
  private settings: {
    [p: string]: CapsaSettingApiModel;
  };

  constructor(public settingPrefix: string) {
    this.settings = {};
  }

  private supportsSetting(key: string): boolean {
    return key.startsWith(this.settingPrefix);
  }

  public tryAddSetting(
    fullKey: string,
    setting: CapsaSettingApiModel
  ): boolean {
    if (!this.supportsSetting(fullKey)) {
      return false;
    }
    this.settings[fullKey] = setting;
    return true;
  }

  public getSetting(key: string): CapsaSettingApiModel | undefined {
    const setting = this.settings[this.makeKey(key)] as CapsaSettingApiModel;
    if (setting) {
      (setting as any).update = true;
    }
    return setting;
  }

  public flatten(): CapsaSettingApiModel[] {
    return Object.keys(this.settings).reduce((allSettings, keyName) => {
      const setting = this.settings[this.makeKey(keyName)];

      if (!(setting as any).update) {
        return allSettings;
      }
      allSettings.push(setting);
      return allSettings;
    }, [] as CapsaSettingApiModel[]);
  }

  private makeKey(key: string) {
    return key.startsWith(this.settingPrefix)
      ? key
      : `${this.settingPrefix}_${key}`;
  }
}
