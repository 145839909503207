import { Injectable } from '@angular/core';
import { FacilityResponse, NameWithLongIdApiModel } from '@capsa/api';
import { EnterpriseApi, EnterpriseResponse } from '@capsa/api/enterprise';
import { FacilityApi } from '@capsa/api/facility';
import { OrganizationApi } from '@capsa/api/organization';
import { AbstractCacheService } from '@capsa/services/abstract-cache/abstract-cache.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

interface UserEofCacheData {
  enterprise: EnterpriseResponse;
  organizations: NameWithLongIdApiModel[];
  facilities: NameWithLongIdApiModel[];
}

@Injectable({
  providedIn: 'root',
})
export class UserEofCacheService extends AbstractCacheService<UserEofCacheData> {
  public get facilities(): NameWithLongIdApiModel[] {
    return this.cacheData.get('facilities') as NameWithLongIdApiModel[];
  }

  public get organizations(): NameWithLongIdApiModel[] {
    return this.cacheData.get('organizations') as NameWithLongIdApiModel[];
  }

  public get enterprise(): EnterpriseResponse {
    return this.cacheData.get('enterprise') as EnterpriseResponse;
  }

  public get userHasMultipleOrgs() {
    return this.organizations.length > 1;
  }

  public getFirstOrg() {
    return (
      this.organizations[0] || {
        Id: -1,
      }
    );
  }

  constructor(
    private enterpriseService: EnterpriseService,
    private enterpriseApi: EnterpriseApi,
    private organizationApi: OrganizationApi,
    private facilityApi: FacilityApi
  ) {
    super();
  }

  public addFacility(toAdd: NameWithLongIdApiModel) {
    this.transform((cache) => {
      return cache.set('facilities', this.facilities.concat(toAdd));
    });
  }

  protected loadApiData() {
    const enterprise$ = this.enterpriseApi
      .search({
        EnterpriseIds: [this.enterpriseService.enterpriseId],
      })
      .pipe(
        map((apiResponse) =>
          apiResponse.Success ? apiResponse.Result[0] : undefined
        )
      );

    const orgs$ = this.organizationApi.getDropdown();

    const facilities$ = this.facilityApi.getAllFacilitiesForCurrentUser().pipe(
      map((apiResponse) => (apiResponse.Success ? apiResponse.Result : [])),
      map((facs) => {
        const addedFacs = new Set<number>();
        const distinctFacs: FacilityResponse[] = [];
        // Sometimes the API returns duplicates (possibly due to the CapsaUserFacility table not having constraints [i.e. duplicate dat]))
        facs.forEach((fac) => {
          if (!addedFacs.has(fac.FacilityId)) {
            distinctFacs.push(fac);
            addedFacs.add(fac.FacilityId);
          }
        });

        return distinctFacs.map((f) => {
          return {
            Id: f.FacilityId,
            Name: f.Name,
            ParentId: f.OrganizationId,
          } as NameWithLongIdApiModel;
        });
      })
    );

    return forkJoin([enterprise$, orgs$, facilities$]).pipe(
      map((apiData) => {
        const userEnterprise = apiData[0];
        const userOrgs = apiData[1];
        const userFacs = apiData[2];
        return {
          enterprise: userEnterprise,
          facilities: userFacs,
          organizations: userOrgs,
        } as UserEofCacheData;
      })
    );
  }
}
