// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-device-update-list-view {
  margin-top: 20px;
  display: block;
}

#btnUploadNewVersion {
  margin-top: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/device-update/list-page/list-page.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":["app-device-update-list-view {\n  margin-top: 20px;\n  display: block;\n}\n\n#btnUploadNewVersion {\n  margin-top: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
