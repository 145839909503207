import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  MessageQueueCreateRequest,
  MessageQueueResponse,
  MessageQueueSearchRequest,
  throwIfBadRespFullResp,
} from '..';
import { BaseApi } from '../base/base.api';

export interface MessageApiInstance {
  sendMessage(
    request: MessageQueueCreateRequest
  ): Observable<ApiResponse<MessageQueueResponse[]>>;

  searchMessages(
    request: MessageQueueSearchRequest
  ): Observable<ApiResponse<MessageQueueResponse[]>>;

  ackMessage(id: number): Observable<ApiResponse<any>>;
}

@Injectable()
export class MessageApi extends BaseApi implements MessageApiInstance {
  protected endpoints = {
    postMessageQueue: this.buildUrl('message-queue'),
    searchMessageQueue: this.buildUrl('message-queue/search'),
    ackMessageQueue: this.buildUrl('message-queue/{Id}/ack'),
  };

  sendMessage(
    request: MessageQueueCreateRequest
  ): Observable<ApiResponse<MessageQueueResponse[]>> {
    return this.http.post<ApiResponse<MessageQueueResponse[]>>(
      this.endpoints.postMessageQueue,
      request
    );
  }

  searchMessages(
    request: MessageQueueSearchRequest
  ): Observable<ApiResponse<MessageQueueResponse[]>> {
    return this.http
      .post<ApiResponse<MessageQueueResponse[]>>(
        this.endpoints.searchMessageQueue,
        request
      )
      .pipe(throwIfBadRespFullResp());
  }

  ackMessage(id: number): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(
      this.endpoints.ackMessageQueue.replace('{Id}', id.toString()),
      null
    );
  }
}
