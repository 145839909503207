import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CartLabelDisplayMode, ChartEntityType } from '@capsa/api';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { RowArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  filterBy,
  orderBy,
} from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';
import {
  DialogSelections,
  EntityGroupItem,
  EntityItem,
} from 'app/modules/analytics/analytics-interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-selection-dialog',
  templateUrl: './entity-selection-dialog.component.html',
  styleUrls: ['./entity-selection-dialog.component.scss'],
})
export class EntitySelectionDialogComponent implements OnInit, OnDestroy {
  // Adds a class to the "host" HTML wrapper of this component
  @HostBinding('class') class = 'responsiveKendoDialog';

  @Input()
  public entities: EntityItem[];

  @Input()
  public entityGroups: EntityGroupItem[] = [];

  @Input()
  public queryByEntityType: ChartEntityType;

  @Input()
  private selectedDisplayMode: CartLabelDisplayMode;

  public icons = { xIcon: xIcon };

  public get displayModeDetected(): CartLabelDisplayMode {
    if (this.queryByEntityType === ChartEntityType.Cart) {
      return this.selectedDisplayMode;
    } else {
      return CartLabelDisplayMode.Name;
    }
  }

  @Output()
  public saved = new EventEmitter<DialogSelections>();

  @Output()
  public cancel = new EventEmitter();

  /**
   * The parent component keeps track of entities already selected (if any)
   * This should not be modified. This array is what we use to restore the users
   * original selection in case they close the dialog without saving
   */
  @Input()
  private readonly selectedEntityIds: string[];

  /**
   * The parent component keeps track of entity groups already selected (if any)
   * This should not be modified. This array is what we use to restore the users
   * original selection in case they close the dialog without saving
   * Since it's been requested to not "infer" which groups are selected (or partially selected)
   * based on the entity selection... we need to keep track of the selected groups when selection gets saved
   * so that it can be restored if the dialog gets re-opened
   */
  @Input()
  private readonly selectedEntityGroupIds: string[];

  public displayMode = CartLabelDisplayMode;

  public get selectedEntities() {
    return this.entities.filter((x) =>
      this.gridSelectedEntityIds.some((id) => id === x.Id)
    );
  }

  public gridSelectedEntityIds: string[];
  public gridSelectedEntityGroupIds: string[] = [];

  // Exposes enum to the template
  public get ChartEntityType(): typeof ChartEntityType {
    return ChartEntityType;
  }

  public get graphTheseEntitiesTag(): string {
    switch (this.queryByEntityType) {
      case ChartEntityType.Cart:
        return 'BTN_TEXT_GRAPH_CARTS';
      case ChartEntityType.User:
        return 'BTN_TEXT_GRAPH_USERS';
      case ChartEntityType.AccessPoint:
        return 'BTN_TEXT_GRAPH_ACCESS_POINTS';
      default:
        throw new Error('Invalid queryByEntityType');
    }
  }

  public entityFilter: CompositeFilterDescriptor;
  public entityGroupFilter: CompositeFilterDescriptor;

  public entitySort: SortDescriptor[];
  public entityGroupSort: SortDescriptor[];

  public gridEntities: EntityItem[];
  public gridEntityGroups: EntityGroupItem[];

  private subs = new Subscription();
  private groupGridSelectionService: GridSelectionService;
  private entityGridSelectionService: GridSelectionService;

  constructor(
    private gridSelectionServiceFactory: GridSelectionServiceFactory
  ) {}

  private selectGroupRowIds(rows: RowArgs[]) {
    return rows.map((row) => {
      const sel = row.dataItem as EntityGroupItem;
      return sel.Id;
    });
  }

  private selectEntityRowIds(rows: RowArgs[]) {
    return rows.map((row) => {
      const sel = row.dataItem as EntityItem;
      return sel.Id;
    });
  }

  ngOnInit() {
    this.gridEntities = this.entities;
    this.gridSelectedEntityIds = [...this.selectedEntityIds];
    this.gridSelectedEntityGroupIds = [...this.selectedEntityGroupIds];
    this.gridEntityGroups = this.entityGroups;

    this.groupGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectGroupRowIds
    );

    this.entityGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectEntityRowIds
    );

    this.subs.add(
      this.groupGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.gridSelectedEntityGroupIds = [...newSelection];
          this.processGroupSelectionChange();
        }
      )
    );

    this.subs.add(
      this.entityGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.gridSelectedEntityIds = [...newSelection];
          this.refreshGroupSelection();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public saveSelection() {
    this.saved.emit({
      entityIds: this.gridSelectedEntityIds,
      entityGroupIds: this.gridSelectedEntityGroupIds,
    });
  }

  public onClose() {
    this.cancel.emit();
  }

  public onEntityListSortChanged(data: SortDescriptor[]) {
    this.gridEntities = orderBy(this.entities, data);
    this.entitySort = data;
  }

  public onEntityGroupListSortChanged(data: SortDescriptor[]) {
    this.gridEntityGroups = orderBy(this.entityGroups, data);
    this.entityGroupSort = data;
  }

  public onEntityListFilterChanged(data: CompositeFilterDescriptor) {
    this.gridEntities = filterBy(this.entities, data);
    this.entityFilter = data;
  }

  public onEntityGroupListFilterChanged(data: CompositeFilterDescriptor) {
    this.gridEntityGroups = filterBy(this.entityGroups, data);
    this.entityGroupFilter = data;
  }

  public groupSelectionChange(event: SelectionEvent) {
    this.groupGridSelectionService.updateSelection(event);
  }

  private processGroupSelectionChange() {
    const deselectedEntities: EntityItem[] = [];
    const selectedEntities: EntityItem[] = [];

    this.gridSelectedEntityGroupIds.forEach((selectedGroupId) => {
      const group = this.entityGroups.find((g) => g.Id === selectedGroupId);
      group.MemberIds.forEach((entityIdToSelect) => {
        const indexToSelect = this.gridSelectedEntityIds.findIndex(
          (x) => x === entityIdToSelect
        );
        // Select member if not already selected
        if (indexToSelect === -1) {
          selectedEntities.push({
            Id: entityIdToSelect,
            Name: null,
            SerialNumber: null,
          });
        }
      });
    });

    this.entityGroups
      .filter((group) => !this.gridSelectedEntityGroupIds.includes(group.Id))
      .forEach((group) => {
        // deselect each "member entity"
        group.MemberIds.forEach((toDeselectId) => {
          const indexToDeselect = this.gridSelectedEntityIds.findIndex(
            (x) => x === toDeselectId
          );
          if (indexToDeselect >= 0) {
            deselectedEntities.push({
              Id: toDeselectId,
              Name: null,
              SerialNumber: null,
            });
          }
        });
      });

    // we want to force the changes below, and not rely on
    // any grid previous selection persistence. 90% of the time
    // we wouldn't want to use this method, but since analytics
    // is funky with groups and such, we have to do it this way.
    this.entityGridSelectionService.forceChanges(
      selectedEntities.map((entity) => ({
        dataItem: entity,
        index: 0,
      })),
      deselectedEntities.map((entity) => ({
        dataItem: entity,
        index: 0,
      }))
    );
  }

  public entitySelectionChanged(selection: SelectionEvent) {
    this.entityGridSelectionService.updateSelection(selection);
  }

  /**
   * Manages the appearance of "group" checkboxes
   */
  private refreshGroupSelection() {
    const deselectedGroups: EntityGroupItem[] = [];

    this.gridEntityGroups.forEach((group) => {
      if (!this.gridSelectedEntityGroupIds.some((x) => x === group.Id)) {
        group.Indeterminate = false;
        return;
      }

      let atLeastOneMatch = false;
      let atleastOneMissing = false;

      group.MemberIds.forEach((memberId) => {
        if (this.gridSelectedEntityIds.some((x) => x === memberId)) {
          atLeastOneMatch = true;
        } else {
          atleastOneMissing = true;
        }
      });

      if (atLeastOneMatch && atleastOneMissing) {
        group.Indeterminate = true;
      } else if (atLeastOneMatch && !atleastOneMissing) {
        // All entities of a group are selected
        group.Indeterminate = false;
      } else if (!atLeastOneMatch && atleastOneMissing) {
        group.Indeterminate = false;
        // if no entities are selected for a group, deselect the group
        const idxMatch = this.gridSelectedEntityGroupIds.indexOf(group.Id);
        if (idxMatch >= 0) {
          deselectedGroups.push(group);
        }
      }
    });

    if (deselectedGroups.length > 0) {
      this.groupGridSelectionService.updateSelection({
        ctrlKey: false,
        deselectedRows: deselectedGroups.map((g) => ({
          dataItem: g,
          index: 0,
        })),
        selectedRows: [],
      });
    }
  }

  public pillRemoveClick(entity: EntityItem) {
    this.entityGridSelectionService.forceChanges(
      [],
      [{ dataItem: entity, index: 0 }]
    );
  }

  public btnClearAll() {
    this.groupGridSelectionService.clearSelection();
    this.entityGridSelectionService.clearSelection();
  }
}
