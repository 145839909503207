import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppHeaderComponent } from './app-header.component';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [CommonModule, TranslateModule, ButtonsModule, ClickOutsideModule],
  exports: [AppHeaderComponent],
})
export class AppHeaderModule {}
