import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AnalyticsChartEntity,
  CartLabelDisplayMode,
  ChartEntityType,
  ChartQueryType,
  ChartTimeResolutionIntervalType,
} from '@capsa/api';
import { AnalyticsHelperService } from '@capsa/services/analytics-helper/analytics-helper.service';
import {
  AxisLabelsPosition,
  CategoryAxisItemComponent,
  CategoryAxisLabelsComponent,
  CategoryBaseUnit,
  DateFormats,
  SeriesClickEvent,
  SeriesMarkers,
} from '@progress/kendo-angular-charts';
import { EntityItem } from 'app/modules/analytics/analytics-interfaces';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-analytics-line-chart',
  templateUrl: './analytics-line-chart.component.html',
  styleUrls: ['./analytics-line-chart.component.scss'],
})
export class AnalyticsLineChartComponent implements OnInit, OnDestroy {
  @Input()
  public newData$: Observable<void>;

  @Input()
  public data: AnalyticsChartEntity[];

  @Input()
  public entities: EntityItem[];

  @Input()
  public selectedEntityIds: string[];

  @Input()
  public chartDateStart: Date;

  @Input()
  public chartDateEnd: Date;

  @Input()
  public xAxisDateFormats: DateFormats;

  @Input()
  public missingValsSetting: string;

  @Input()
  public lineStyle: string;

  @Input()
  public isMarkersVisible = true;

  @Input()
  public tooltipDateTimeFormat: string;

  @Input()
  private throttledResize$: Observable<Event>;

  @Input()
  public chartTitleTag: string;

  @Input()
  public tooltipValueDescriptionTag: string;

  @ViewChild('chartAxisItem', { static: false })
  public axisItems: CategoryAxisItemComponent;

  @ViewChild('xAxisItemLabels', { static: false })
  public xAxisItemLabels: CategoryAxisLabelsComponent;

  @Input()
  public queryType: ChartQueryType;

  @Input()
  public queryByEntityType: ChartEntityType;

  @Input()
  public dateTimeInterval: ChartTimeResolutionIntervalType;

  @Input()
  public selectedDisplayMode: CartLabelDisplayMode;

  public get categoryBaseUnit(): CategoryBaseUnit {
    return this.analyticsHelperService.convertApiIntervalToKendoBaseUnit(
      this.dateTimeInterval
    );
  }

  public xAxisPosition: AxisLabelsPosition = 'start';
  private currentComponentSelector: string;

  private readonly hoveredLineWidth = 4;
  public readonly defaultMarkerSize = 10;
  private readonly highlightedMarkerSize = 15;

  public readonly defaultMarkers: SeriesMarkers = {
    visible: this.isMarkersVisible,
    size: this.defaultMarkerSize,
  };
  public readonly hoveredMarkers: SeriesMarkers = {
    visible: this.isMarkersVisible,
    size: this.highlightedMarkerSize,
  };

  private subs = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private analyticsHelperService: AnalyticsHelperService,
    private elem: ElementRef
  ) {
    this.currentComponentSelector =
      this.elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit() {
    this.subs.add(
      this.throttledResize$.subscribe(() => {
        this.analyticsHelperService.refreshChartSizeProperties(
          this.currentComponentSelector,
          this.axisItems,
          this.cdRef
        );
      })
    );
    this.subs.add(
      this.newData$.subscribe(() => {
        this.analyticsHelperService.refreshChartSizeProperties(
          this.currentComponentSelector,
          this.axisItems,
          this.cdRef
        );
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onSeriesClick(e: SeriesClickEvent) {
    this.analyticsHelperService.seriesClickedSubj.next(
      this.data[e.series.index]
    );
  }

  /**
   * Handles a click on the "eye" next to the legend icon
   */
  public visibilityToggled(item: AnalyticsChartEntity) {
    item.Hidden = !item.Hidden;
    this.data = [...this.data];
  }

  /**
   * Handles a click on the legend item text/marker. (Highlights the series and
   * leaves it highlighted until the user clicks on legend text/marker again)
   */
  public fixedHighlightToggled(item: AnalyticsChartEntity) {
    item.FixedHighlight = !item.FixedHighlight;

    const someItemFixedHighlight = this.data.some((x) => x.FixedHighlight);
    if (someItemFixedHighlight) {
      this.data.forEach((x) => {
        this.applyFixedHighlightToggle(x, x.FixedHighlight, false);
      });
    } else {
      // if none of the items are "fixed highlight", then make them all visible
      this.data.forEach((x) => {
        this.applyFixedHighlightToggle(x, true, true);
      });
    }
  }

  /**
   *
   * @param item target series item
   * @param highlight if true, will make/keep series visible, if false
   * then will "fade out" series
   * @param resetZIndex if "highlight" is true and THIS parameter is
   * true, then will flip the z-index to default
   */ private applyFixedHighlightToggle(
    item: AnalyticsChartEntity,
    highlight: boolean,
    resetZIndex: boolean
  ) {
    if (highlight) {
      // restore default
      item.Opacity = undefined;

      // Even though other series will be faded out, they can be overlapping
      // the highlighted series, when many "non-highlighted" series overlap
      // they prevent the highlighted item from popping out as expected
      item.ZIndex = resetZIndex
        ? undefined
        : this.analyticsHelperService.fixedHighlightZIndex;

      // Since non-highlighted items will have "faded out" markers and lines
      // we just leave "Markers" to use defaults (100% opacity, full color etc.)
      item.Markers = undefined;
    } else {
      item.Opacity = 0.15;
      item.ZIndex = undefined;
      item.Markers = {
        ...this.defaultMarkers,
        ...{ border: { color: item.BgColor + '0F' } },
      };
    }
  }

  public clearAllFixedHighlights() {
    // In this case we "clear" by "resetting" all items to their defaults
    this.data.forEach((x) => {
      x.FixedHighlight = false;
      this.applyFixedHighlightToggle(x, true, true);
    });
    this.data = [...this.data];
  }

  public chartItemMouseEnter(item: AnalyticsChartEntity) {
    this.data.forEach((x) => {
      if (!x.FixedHighlight) {
        x.Opacity = 0.15;
      }
    });
    item.Opacity = undefined;
  }

  /**
   * Handles a temporary hover-over on the legend item (highlights series while mouse is over legend item)
   */
  public legendItemMouseEnter(item: AnalyticsChartEntity) {
    this.data.forEach((x) => {
      if (!x.FixedHighlight) {
        x.Opacity = 0.15;
        x.Markers = {
          ...this.defaultMarkers,
          ...{ border: { color: x.BgColor + '0F' } },
        };
      }
    });
    item.LineWidth = this.hoveredLineWidth;
    item.ZIndex = this.analyticsHelperService.hoverHighlightZIndex;
    item.Opacity = undefined;
    item.Markers = {
      ...this.hoveredMarkers,
      ...{ background: item.BgColor, border: { color: 'white', width: 2 } },
    };
  }
  public legendItemMouseLeave(item: AnalyticsChartEntity) {
    const someItemFixedHighlight = this.data.some((x) => x.FixedHighlight);

    item.LineWidth = undefined;
    this.applyFixedHighlightToggle(
      item,
      item.FixedHighlight,
      !item.FixedHighlight
    );

    if (!someItemFixedHighlight) {
      this.data.forEach((x) => {
        x.Opacity = undefined;
        x.Markers = undefined;
      });
    }
  }

  public legendHideAllToggled(show: boolean) {
    this.data.forEach((x) => (x.Hidden = !show));
    this.data = [...this.data];
  }
}
