import { Injectable } from '@angular/core';
export interface NativeWindowServiceInstance {
  history: History;
  currentOrigin(): string;
  redirect(url: string): void;
  encodeURIComponent(component: string): string;
}

@Injectable()
export class NativeWindowService implements NativeWindowServiceInstance {
  currentOrigin() {
    return window.location.origin;
  }

  redirect(url: string) {
    window.location.href = url;
  }

  encodeURIComponent(component: string) {
    return encodeURIComponent(component);
  }

  decodeURIComponent(component: string) {
    return decodeURIComponent(component);
  }

  get history(): History {
    return window.history;
  }
}
