import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActivityApi } from '@capsa/api/activity';
import { AnalyticsApi } from '@capsa/api/analytics';
import { BlobStorageApi } from '@capsa/api/blob-storage';
import { CapsaRoleApi } from '@capsa/api/capsa-role';
import { CartGroupApi } from '@capsa/api/cart-group';
import { CartProfileApi } from '@capsa/api/cart-profile';
import { CartApi } from '@capsa/api/cart/cart.api';
import { CommonApi } from '@capsa/api/common';
import { DashboardApi } from '@capsa/api/dashboard';
import { DeviceVersionApi } from '@capsa/api/device-version/device-version.api';
import { EmailApi } from '@capsa/api/email';
import { EnterpriseApi } from '@capsa/api/enterprise';
import { FacilityApi } from '@capsa/api/facility';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { LightingApi } from '@capsa/api/lighting';
import { MessageApi } from '@capsa/api/message';
import { MessageRequestTypeApi } from '@capsa/api/message-request-type';
import { MessageSubjectPresetApi } from '@capsa/api/message-subject-preset';
import { MessageTemplateApi } from '@capsa/api/message-template/message-template.api';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { NotificationGroupApi } from '@capsa/api/notification-group';
import { SettingsApi } from '@capsa/api/settings';
import { SetupApi } from '@capsa/api/setup';
import { UserApi } from '@capsa/api/user/user.api';
import { AuthApi } from './api/auth/auth.api';
import { DeviceTypeApi } from './api/device-type';
import { EnumApi } from './api/enum/enum.api';
import { AuthenticateInterceptor } from './api/interceptors/authenticate.interceptor';
import { OrganizationApi } from './api/organization';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthApi,
    BlobStorageApi,
    EnumApi,
    DeviceTypeApi,
    OrganizationApi,
    UserApi,
    FacilityApi,
    CartApi,
    DeviceVersionApi,
    CartProfileApi,
    CartGroupApi,
    SettingsApi,
    CapsaRoleApi,
    EmailApi,
    MessageApi,
    MessageRequestTypeApi,
    MessageTemplateApi,
    NotificationGroupApi,
    LightingApi,
    MessageSubjectPresetApi,
    ActivityApi,
    SetupApi,
    NetworkAccessPointApi,
    EnterpriseApi,
    DashboardApi,
    AnalyticsApi,
    FirmHardWareApi,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticateInterceptor,
      multi: true,
    },
    CommonApi,
  ],
})
export class ApiModule {}
