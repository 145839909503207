// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  margin-top: 0px;
  width: auto;
}

div.header-container {
  display: flex;
  flex-direction: row;
}

div.header-text {
  width: auto;
}

div.header-help-icon {
  padding-top: 5px;
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/access-points/device-tracking/device-tracking.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF","sourcesContent":["h2 {\n  margin-top: 0px;\n  width: auto;\n}\n\ndiv.header-container {\n  display: flex;\n  flex-direction: row;\n}\n\ndiv.header-text {\n  width: auto;\n}\n\ndiv.header-help-icon {\n  padding-top: 5px;\n  padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
