import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MessageQueueDefaultColors,
  MessageQueueMessageType,
  MessageTemplateResponse,
} from '@capsa/api';
import { MessageTemplateApi } from '@capsa/api/message-template/message-template.api';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-message-templates',
  templateUrl: './message-templates.component.html',
  styleUrls: ['./message-templates.component.scss'],
})
export class MessageTemplatesComponent implements OnInit, OnDestroy {
  public organizationId: number | undefined;
  public facilityId: number | undefined;

  public selectedIds: number[] = [];
  public selected: MessageTemplateResponse;
  public canEdit = false;

  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<MessageTemplateResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;

  private subs = new Subscription();
  private apiSearchSub = new Subscription();
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Messages];

  constructor(
    private api: MessageTemplateApi,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.CLI_MessageTemplates_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(editPerm);
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.canEdit = this.permissionService.has(editPerm);
  }

  onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  onFacilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  onTemplateSelected(ev: SelectionEvent) {
    this.selected = ev.selectedRows[0].dataItem;
  }
  onModalClose() {
    this.selected = null;
    this.selectedIds = [];
  }

  onModalSaved() {
    this.selected = null;
    this.selectedIds = [];
    this.resetPagingAndData();
    this.loadGridData();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }
  // Applies a class to each Grid row to aid overriding CSS
  public rowCallback(context: RowClassArgs) {
    return { gridRowOverride: true };
  }

  public createTemplate() {
    this.selected = {
      MessageQueueTemplateId: 0,
      Name: '',
      ForegroundColor: MessageQueueDefaultColors.Foreground,
      BackgroundColor: MessageQueueDefaultColors.Background,
      MessageQueueMessageType:
        MessageQueueMessageType[MessageQueueMessageType.Notification],
    };
  }

  private loadGridData() {
    if (!this.organizationId || !this.facilityId) {
      return;
    }
    this.gridDataLoading = true;
    this.apiSearchSub = this.api
      .searchTemplate({
        FacilityId: this.facilityId,
        OrganizationId: this.organizationId,
        PageNumber: this.skip / this.pageSize + 1,
        PageSize: this.pageSize,
      })
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }

          this.gridDataLoading = false;
        },
        (error) => {
          // TODO handle error of list loading
          this.gridDataLoading = false;
        }
      );
  }
}
