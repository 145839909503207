import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { LoginRequest } from '@capsa/services/auth/login-request';
import { LoginResponse } from '@capsa/services/auth/login-response';
import { BaseApi } from '../base/base.api';

export interface AuthApiInstance {
  login(request: LoginRequest): Promise<LoginResponse>;
}

@Injectable()
export class AuthApi extends BaseApi implements AuthApiInstance {
  protected endpoints = {
    getAuth: this.buildUrl('/auth/getauth'),
  };

  constructor(http: HttpClient, appSettingService: AppSettingsService) {
    super(http, appSettingService);
  }

  public login(request: LoginRequest): Promise<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.endpoints.getAuth, request)
      .toPromise();
  }
}
