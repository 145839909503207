import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';

import { AuthGuard } from '@capsa/services/auth/auth.guard';
import { PermissionGuard } from '@capsa/services/permission/permission.guard';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { FaultEventsLogComponent } from 'app/menu/carts/fault-events-log/fault-events-log.component';
import { SoftwareUpdatesSentComponent } from 'app/menu/carts/software-updates-sent/software-updates-sent.component';
import { UserProfileComponent } from 'app/menu/user/user-profile/user-profile.component';
import { AccessPointsComponent } from 'app/modules/access-points/access-points.component';
import { AnalyticsAccessPointsComponent } from 'app/modules/analytics/analytics-access-points/analytics-access-points.component';
import { AnalyticsAccessComponent } from 'app/modules/analytics/analytics-access/analytics-access.component';
import { AnalyticsPowerComponent } from 'app/modules/analytics/analytics-power/analytics-power.component';
import { CartGroupEditPageComponent } from 'app/modules/cart-group/pages/edit-page/edit-page.component';
import { CartGroupManagePageComponent } from 'app/modules/cart-group/pages/manage-page/manage-page.component';
import { CartManagementSingleComponent } from 'app/modules/cart-management/cart-management-single/cart-management-single.component';
import { CartManagementComponent } from 'app/modules/cart-management/cart-management.component';
import { CreatePageComponent as CreateCartProfilePageComponent } from 'app/modules/cart-profile/pages/create-page/create-page.component';
import { EditPageComponent as EditCartProfilePageComponent } from 'app/modules/cart-profile/pages/edit-page/edit-page.component';
import { ListPageComponent as CartProfileListPageComponent } from 'app/modules/cart-profile/pages/list-page/list-page.component';
import { ListPageComponent as DeviceUpdateListPageComponent } from 'app/modules/device-update/list-page/list-page.component';
import { FacilityManageComponent } from 'app/modules/facility/facility-manage/facility-manage.component';
import { LightingComponent } from 'app/modules/lighting/lighting/lighting.component';
import { ListMessagesFromCartComponent } from 'app/modules/messaging/pages/list-messages-from-cart/list-messages-from-cart.component';
import { ListMessagesToCartComponent } from 'app/modules/messaging/pages/list-messages-to-cart/list-messages-to-cart.component';
import { ListMessagesToUserComponent } from 'app/modules/messaging/pages/list-messages-to-user/list-messages-to-user.component';
import { MessageTemplatesComponent } from 'app/modules/messaging/pages/message-templates/message-templates.component';
import { NewMessageComponent } from 'app/modules/messaging/pages/new-message/new-message.component';
import { SubjectPresetManageComponent } from 'app/modules/messaging/pages/subject-preset-manage/subject-preset-manage.component';
import { SupportFacilityAddComponent } from 'app/modules/support/support-facility-add/support-facility-add.component';
import { SupportTransferCartsComponent } from 'app/modules/support/support-transfer-carts/support-transfer-carts.component';
import { UpdateCartFirmwareComponent } from 'app/modules/update-cart-firmware/update-firmware.component';
import { CreateUserComponent } from 'app/modules/users/create-user/create-user.component';
import { UserCartActivityListComponent } from 'app/modules/users/user-cart-activity/user-cart-activity.component';
import { EditUserWrapperComponent } from 'app/modules/users/user-management/edit-user-wrapper/edit-user-wrapper.component';
import { ImportUsersComponent } from 'app/modules/users/user-management/import-users/import-users.component';
import { UserManagementComponent } from 'app/modules/users/user-management/user-management.component';
import { UserWebActivityListComponent } from 'app/modules/users/user-web-activity/user-web-activity.component';
import { UsersBulkAssignComponent } from 'app/modules/users/users-bulk-assign/users-bulk-assign.component';
import { TranslationKeys } from './common/translation-keys';

const menuKeys = TranslationKeys.menu;

const guards = [AuthGuard, PermissionGuard];

const routes: Route[] = [
  {
    data: {
      title: menuKeys.home.root,
      icon: 'icon-dashboard-new.svg',
    },
    path: 'dashboard',
    canActivate: guards,
    component: DashboardComponent,
  },
  {
    data: {
      title: menuKeys.analytics.root,
      icon: 'icon-analytics-new.svg',
    },
    path: 'analytics',
    children: [
      {
        path: 'power',
        canActivate: guards,
        component: AnalyticsPowerComponent,
        data: {
          title: menuKeys.analytics.power,
          permsRequired: [Permissions.CLI_MenuAccess_Analytics],
        },
      },
      {
        path: 'access',
        canActivate: guards,
        component: AnalyticsAccessComponent,
        data: {
          title: menuKeys.analytics.access,
          permsRequired: [Permissions.CLI_MenuAccess_Analytics],
        },
      },
      {
        path: 'access-points',
        canActivate: guards,
        component: AnalyticsAccessPointsComponent,
        data: {
          title: menuKeys.analytics.accessPoints,
          featureFlag: 'analytics',
          permsRequired: [Permissions.CLI_MenuAccess_Analytics],
        },
      },
    ],
  },
  {
    data: { title: menuKeys.messaging.root, icon: 'icon-messages-new.svg' },
    path: 'messages',
    children: [
      {
        path: 'service-requests',
        canActivate: guards,
        component: ListMessagesFromCartComponent,
        data: {
          title: menuKeys.messaging.serviceRequests,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
      {
        path: 'subject-presets',
        canActivate: guards,
        component: SubjectPresetManageComponent,
        data: {
          title: menuKeys.messaging.subjectPresets,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
      {
        path: 'new-notification',
        canActivate: guards,
        component: NewMessageComponent,
        data: {
          title: menuKeys.messaging.newNotifications,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
      {
        path: 'notification-template',
        canActivate: guards,
        component: MessageTemplatesComponent,
        data: {
          title: menuKeys.messaging.notificationTemplate,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
      {
        path: 'sent-notifications',
        canActivate: guards,
        component: ListMessagesToCartComponent,
        data: {
          title: menuKeys.messaging.sentNotifications,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
      {
        path: 'sent-messages',
        canActivate: guards,
        component: ListMessagesToUserComponent,
        data: {
          title: menuKeys.messaging.sentMessages,
          permsRequired: [Permissions.CLI_MenuAccess_Messages],
        },
      },
    ],
  },
  {
    data: {
      title: menuKeys.facilities.root,
      icon: 'icon-facilities-new.svg',
    },
    path: 'facilities',
    children: [
      {
        path: 'manage',
        canActivate: guards,
        component: FacilityManageComponent,
        data: {
          title: menuKeys.facilities.manage,
          permsRequired: [Permissions.CLI_MenuAccess_Facilities],
        },
      },
      {
        path: 'add-facility',
        canActivate: guards,
        component: SupportFacilityAddComponent,
        data: {
          title: menuKeys.support.addFacility,
          permsRequired: [Permissions.CLI_MenuAccess_Facilities_Add],
        },
      },
      {
        path: 'profiles',
        canActivate: guards,
        component: CartProfileListPageComponent,
        data: {
          title: menuKeys.facilities.profiles,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'profiles/new',
        canActivate: guards,
        data: {
          title: menuKeys.facilities.profiles,
          renderMenuItem: false,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
        component: CreateCartProfilePageComponent,
      },
      {
        path: 'profiles/edit/:id',
        canActivate: guards,
        data: {
          title: menuKeys.facilities.profiles,
          renderMenuItem: false,

          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
        component: EditCartProfilePageComponent,
      },
      {
        path: 'access-points',
        canActivate: guards,
        component: AccessPointsComponent,
        data: {
          title: menuKeys.facilities.accessPoints,
          permsRequired: [Permissions.CLI_MenuAccess_Facilities],
        },
      },

      {
        path: 'lighting',
        canActivate: guards,
        component: LightingComponent,
        data: {
          title: menuKeys.facilities.lighting,
          permsRequired: [Permissions.CLI_MenuAccess_Facilities],
        },
      },
    ],
  },
  {
    data: { title: menuKeys.carts.root, icon: 'icon-carts-new.svg' },
    path: 'carts',
    children: [
      {
        path: 'manage',
        canActivate: guards,
        component: CartManagementComponent,
        data: {
          title: menuKeys.carts.manage,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'manage/:id',
        canActivate: guards,
        component: CartManagementSingleComponent,
        data: {
          title: menuKeys.carts.manage,
          renderMenuItem: false,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'groups',
        canActivate: guards,
        component: CartGroupManagePageComponent,
        data: {
          title: menuKeys.carts.groups,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'groups/edit/:id',
        canActivate: guards,
        data: {
          title: menuKeys.carts.groups,
          renderMenuItem: false,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
        component: CartGroupEditPageComponent,
      },
      {
        // Only Capsa Support can see fault logs
        path: 'faults',
        canActivate: guards,
        component: FaultEventsLogComponent,
        data: {
          title: menuKeys.carts.faults,
          permsRequired: [Permissions.CLI_MenuAccess_Carts_Faults],
        },
      },
      {
        path: 'update',
        canActivate: guards,
        component: UpdateCartFirmwareComponent,
        data: {
          title: menuKeys.carts.updates,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'updates-sent',
        canActivate: guards,
        component: SoftwareUpdatesSentComponent,
        data: {
          title: menuKeys.carts.updatesSent,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
      {
        path: 'transfer-carts',
        canActivate: guards,
        component: SupportTransferCartsComponent,
        data: {
          title: menuKeys.support.transferCarts,
          permsRequired: [Permissions.CLI_MenuAccess_Carts],
        },
      },
    ],
  },
  {
    data: { title: menuKeys.user.root, icon: 'icon-users-new.svg' },
    path: 'user',
    children: [
      {
        // Cart admins have read only access to manage users.
        path: 'management',
        canActivate: guards,
        component: UserManagementComponent,
        data: {
          title: menuKeys.user.management,
          permsRequired: [Permissions.CLI_MenuAccess_Users],
        },
      },
      {
        path: 'management/:id',
        canActivate: guards,
        data: {
          title: menuKeys.user.management,
          renderMenuItem: false,
          permsRequired: [Permissions.CLI_MenuAccess_Users],
        },
        component: EditUserWrapperComponent,
      },
      {
        path: 'create-user',
        canActivate: guards,
        component: CreateUserComponent,
        data: {
          title: menuKeys.user.createUser,
          permsRequired: [Permissions.CLI_MenuAccess_Users_Add],
        },
      },
      {
        // Only users that can create users can bulk assign users to carts
        path: 'bulk-assign',
        canActivate: guards,
        component: UsersBulkAssignComponent,
        data: {
          title: menuKeys.user.bulkAssign,
          permsRequired: [Permissions.CLI_MenuAccess_Users_Add],
        },
      },
      {
        path: 'cartactivity',
        component: UserCartActivityListComponent,
        canActivate: guards,
        data: {
          title: menuKeys.user.cartActivity,
          permsRequired: [Permissions.CLI_MenuAccess_Users],
        },
      },
      {
        // Only users that can create users should have access to the web logs.
        path: 'webactivity',
        component: UserWebActivityListComponent,
        canActivate: guards,
        data: {
          title: menuKeys.user.webActivity,
          permsRequired: [Permissions.CLI_MenuAccess_Users_Add],
        },
      },
      {
        path: 'user-import',
        component: ImportUsersComponent,
        canActivate: guards,
        data: {
          title: menuKeys.user.importUsers,
          permsRequired: [Permissions.CLI_MenuAccess_Users_Add],
        },
      },
    ],
  },
  {
    data: { title: menuKeys.support.root, icon: 'icon-support-new.svg' },
    path: 'support',
    children: [
      {
        path: 'releases',
        canActivate: guards,
        component: DeviceUpdateListPageComponent,
        data: {
          title: menuKeys.support.releases,
          permsRequired: [
            Permissions.CAP_Manage_CrossEnterpiseData_DeviceUpdates_ReadWrite,
          ],
        },
      },
    ],
  },
  {
    data: { title: null, renderMenuItem: false },
    path: 'account',
    children: [
      {
        path: 'profile',
        canActivate: guards,
        component: UserProfileComponent,
        data: { title: menuKeys.account.profile, renderMenuItem: false },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
