import { Component, Inject, OnInit } from '@angular/core';
import {
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
} from '@capsa/api';

@Component({
  selector: 'app-trio-cart-management-lighting-tab',
  templateUrl: './trio-cart-management-lighting-tab.component.html',
  styleUrls: ['./trio-cart-management-lighting-tab.component.scss'],
})
export class TrioCartManagementLightingTabComponent implements OnInit {
  public settings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();
  public settingTypeEnum = CapsaSettingsTypeIds;

  public frequencySettingCopy: CapsaSettingApiModel;

  public get isNotificationLightOn(): boolean {
    return this.settings.get(CapsaSettingsTypeIds.CLI_IsNotificationLightOn)
      .SettingValue === '1'
      ? true
      : false;
  }

  public get isBlinkingEnabled(): boolean {
    return this.settings.get(CapsaSettingsTypeIds.CLI_BlinkNotificationLight)
      .SettingValue === '1'
      ? true
      : false;
  }

  constructor(
    @Inject('device')
    public device: CartResponse
  ) {}

  public onFrequencyChanged(newValue: number) {
    // blink frequency includes the lights "off" time, so we multiply the overall time by 2
    // we also do a copy so that when we modify this, it doesn't update the UI to the multiplied
    // value.
    this.settings.get(
      CapsaSettingsTypeIds.CLI_NotificationLightBlinkFrequency
    ).SettingValue = (newValue * 2).toString();
  }

  ngOnInit() {
    const lightingSettings = new Set([
      CapsaSettingsTypeIds.CLI_IsNotificationLightOn,
      CapsaSettingsTypeIds.CLI_NotificationLightIntensity,
      CapsaSettingsTypeIds.CLI_NotificationLightColor,
      CapsaSettingsTypeIds.CLI_BlinkNotificationLight,
      CapsaSettingsTypeIds.CLI_NotificationLightBlinkFrequency,
    ]);
    this.device.Settings.forEach((s) => {
      if (
        s.SettingType ===
        CapsaSettingsTypeIds.CLI_NotificationLightBlinkFrequency
      ) {
        this.frequencySettingCopy = {
          ...s,
          // blink frequency includes the lights "off" time, so we divide the overall time by 2
          // we do this to the copy so we don't modify the actual setting value so if the user saves
          // without making changes, the setting will remain the same value.
          SettingValue: (Number.parseFloat(s.SettingValue) / 2).toString(),
        };
      }
      if (lightingSettings.has(s.SettingType)) {
        this.settings.set(s.SettingType, s);
      }
    });
  }
}
