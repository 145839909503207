import { Component, Input, OnDestroy } from '@angular/core';
import { ApiResponse, CapsaSettingApiModel } from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-pin',
  templateUrl: './edit-user-pin.component.html',
  styleUrls: ['./edit-user-pin.component.scss'],
})
export class EditUserPinComponent implements OnDestroy {
  @Input()
  public pinSetting: CapsaSettingApiModel;

  public generatePinResponse: ApiResponse<any>;
  public generatingPin: boolean;

  private subs: Subscription = new Subscription();

  constructor(
    private userApi: UserApi,
    private editUserService: EditUserService
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public generateNewPin() {
    this.generatePinResponse = undefined;
    this.generatingPin = true;

    const generatePinSub = this.userApi
      .generatePin(
        this.editUserService.userId,
        this.editUserService.selectedFacilityId,
        this.pinSetting.DeviceTypeId,
        this.pinSetting.SettingType
      )
      .pipe(
        tap((r) => (this.generatePinResponse = r.Success ? undefined : r)),
        map((r) => r.Result),
        tap(() => (this.generatingPin = false))
      )
      .subscribe((pin) => (this.pinSetting.SettingValue = pin));

    this.subs.add(generatePinSub);
  }
}
