import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import * as CustomValidators from './validators';

interface SetPasswordControls {
  currentPassword: AbstractControl;
  newPassword: AbstractControl;
  repeatNewPassword: AbstractControl;
}

export interface SavePasswordChangeEvent {
  oldPassword: string;
  newPassword: string;
}

export type SetPasswordForm = {
  controls: SetPasswordControls;
} & UntypedFormGroup;

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  @Input()
  public requireCurrentPassword: boolean;

  public form: SetPasswordForm;

  @Input()
  public onSave: (saveEvent: SavePasswordChangeEvent) => void;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    const fields: any = {
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.StrongPassword,
        ]),
      ],
      repeatNewPassword: ['', Validators.compose([Validators.required])],
    };

    if (this.requireCurrentPassword) {
      fields.currentPassword = ['', Validators.compose([Validators.required])];
    }

    this.form = this.formBuilder.group(fields, {
      validators: CustomValidators.PasswordsMatch<SetPasswordControls>(
        'newPassword',
        'repeatNewPassword'
      ),
    }) as SetPasswordForm;
  }

  public save = () => {
    const saveEvent: SavePasswordChangeEvent = {
      newPassword: this.form.value.newPassword,
      oldPassword: this.form.value.currentPassword,
    };
    this.onSave(saveEvent);
  };
}
