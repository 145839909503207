import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  FacilityCreateRequest,
  FacilityResponse,
  NameWithLongIdApiModel,
} from '@capsa/api';
import { SetupApi } from '@capsa/api/setup';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { TranslateService } from '@ngx-translate/core';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-facility-add',
  templateUrl: './support-facility-add.component.html',
  styleUrls: ['./support-facility-add.component.scss'],
})
export class SupportFacilityAddComponent implements OnInit, OnDestroy {
  public orgId: number;

  public get hasMultipleOrgs() {
    return this.userEofCacheService.userHasMultipleOrgs;
  }

  public facilityDisplayName: UntypedFormControl;
  public contactName: UntypedFormControl;
  public contactEmail: UntypedFormControl;
  public form: UntypedFormGroup;
  public canEdit = false;

  get isFormValid() {
    return this.form.valid;
  }
  private subs: Subscription = new Subscription();

  public get disableCreate(): boolean {
    return !this.canEdit || !this.orgId || !this.isFormValid;
  }

  constructor(
    private setupApi: SetupApi,
    private userEofCacheService: UserEofCacheService,
    private eofCacheService: EofCacheService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    public loaderService: LoaderService,
    private toasterService: ToasterService,
    private capsaRoleService: CapsaRoleService
  ) {}

  ngOnInit() {
    this.loadPermissions();
    this.setupForm();
    if (!this.userEofCacheService.userHasMultipleOrgs) {
      // Because this sets public state on the component from within ngOnInit,
      // we need to run this code on the next render check cycle instead of during
      // initial rending. If we don't do this, we get a change detection error.
      setTimeout(() => {
        const availableOrg = this.userEofCacheService.getFirstOrg();
        this.orgId = availableOrg.Id;
      }, 0);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([
          Permissions.Manage_Facilities_ReadWrite,
          Permissions.CLI_Facility_ReadWrite,
        ])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  public orgIdChanged(newValue: number | undefined) {
    this.orgId = newValue;
  }

  public btnCreateFacility(): void {
    const taskId = 'createFacility';
    this.loaderService.startWithText(
      this.translateService.instant('CREATING_FACILITY_MESSAGE'),
      taskId
    );

    const createRequest: Partial<FacilityCreateRequest> = {
      ContactEmail: this.contactEmail.value,
      ContactPerson: this.contactName.value,
      Name: this.facilityDisplayName.value,
      OrganizationId: this.orgId,
    };

    this.subs.add(
      this.setupApi.facilityCreate(createRequest).subscribe(
        (resp) => {
          this.form.reset();

          const facilityDdlOption: NameWithLongIdApiModel = {
            Id: resp.Result,
            Name: createRequest.Name,
            ParentId: createRequest.OrganizationId,
          };

          const apiResponse: Partial<FacilityResponse> = {
            FacilityId: resp.Result,
            Name: createRequest.Name,
            OrganizationId: createRequest.OrganizationId,
            ContactEmail: createRequest.ContactEmail,
            ContactPerson: createRequest.ContactPerson,
            IsActive: true,
          };

          this.userEofCacheService.addFacility(facilityDdlOption);
          this.eofCacheService.addFacility(
            facilityDdlOption,
            apiResponse as FacilityResponse
          );
          this.toasterService.showSuccess('FACILITY_CREATE_SUCCESS');
          this.capsaRoleService.refreshRolesFromApi();
          this.permissionService.refreshFromApi();
          this.loaderService.stop(taskId);
        },
        (_error) => {
          this.toasterService.showError('FACILITY_CREATE_FAIL');
          this.loaderService.stop(taskId);
        }
      )
    );
  }

  private setupForm(): void {
    this.facilityDisplayName = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(250),
        this.uniqueFacilityNamePerOrgValidator,
      ]),
    });
    this.contactName = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(250),
      ]),
    });
    this.contactEmail = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.required,
        Validators.email,
        Validators.maxLength(256),
        NsightCustomValidators.isEmailExtraChecksValid(),
      ]),
    });

    this.form = new UntypedFormGroup({
      facilityDisplayName: this.facilityDisplayName,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
    });
  }

  private uniqueFacilityNamePerOrgValidator = (
    ctl: AbstractControl
  ): ValidationErrors => {
    if (ctl.dirty) {
      const isUnique = this.eofCacheService.isFacilityNameUniqueToOrg(
        ctl.value,
        this.orgId
      );
      return isUnique ? null : { isNotUniqueFacilityName: true };
    }
    return null;
  };
}
