import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { EnterpriseDropDownDataSource } from '@capsa/dropdowns/enterprise-drop-down/enterprise-drop-down-data-source';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-enterprise-drop-down',
  templateUrl: './enterprise-drop-down.component.html',
  styleUrls: ['./enterprise-drop-down.component.scss'],
})
export class EnterpriseDropDownComponent implements OnInit, OnDestroy {
  @Input()
  readonly: boolean;

  @Input()
  omitId: number | undefined;

  @Input()
  public readonlyText: string;

  @Input()
  public disabled = false;

  @Output()
  valueChanged = new EventEmitter<AsyncDropDownValue<NameWithLongIdApiModel>>();

  @Input()
  public dataSource: EnterpriseDropDownDataSource;

  private subs: Subscription = new Subscription();

  ngOnInit() {
    this.subs.add(
      this.dataSource.load().subscribe((items) => {
        if (this.omitId) {
          items = items.filter((x) => x.id !== this.omitId.toString());
        }
        this.dataSource.data = items;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
