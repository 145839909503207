import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ActivityResponse,
  ActivitySearchRequest,
  ApiActivityResponse,
  DeviceTypeIds,
} from '@capsa/api';
import { ActivityApi } from '@capsa/api/activity';
import { GridFilterHelperService } from '@capsa/services/grid-filter-helper/grid-filter-helper.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { TranslateService } from '@ngx-translate/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { BehaviorSubject, Subscription } from 'rxjs';
import { utils, writeFile } from 'xlsx';

import { PermissionService } from '@capsa/services/permission/permission.service';

export interface ColumnConfig {
  field: string;
  titleTag: string;
  fieldType: 'boolean' | 'string' | 'date' | 'number';
  width?: number;
  sort?: boolean;
  filterable: boolean;
}

export interface ActivityLogColumnConfig extends ColumnConfig {
  field: keyof ActivityResponse;
}

export interface ApiActivityLogColumnConfig extends ColumnConfig {
  field: keyof ApiActivityResponse;
}

@Component({
  selector: 'app-activity-log-view',
  templateUrl: './activity-log-view.component.html',
  styleUrls: ['./activity-log-view.component.scss'],
})
export class ActivityLogViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public columns: ActivityLogColumnConfig[];

  @Input()
  public query: Partial<ActivitySearchRequest> = {};

  @Input()
  public pagePerm: Permissions;

  @Output()
  public deviceTypeChanged = new EventEmitter<number>();

  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  public filterDelayTime = 1500;

  public deviceTypeId: DeviceTypeIds;

  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<ActivityResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;

  public sort: SortDescriptor[] = [];

  private orgId: number;
  public facilityId: number;
  private subs = new Subscription();

  // Meant to specifically store the Activity Search API call, this allows us to always cancel any existing
  // calls before making a new one, while not accidentally clearing out other subs (e.g. if we were to do this.subs.unsubscribe()
  // then we risk clearing out other future subs unintentionally)
  private apiSearchSub = new Subscription();

  public canViewPage = false;

  constructor(
    private activityApi: ActivityApi,
    private filterService: GridFilterHelperService,
    private translate: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.resetPagingAndData();
      this.loadGridData();
    }
  }

  ngOnInit(): void {
    if (this.pagePerm === undefined || this.pagePerm === null) {
      throw new Error(
        "Page permission must be set in 'Activity Log View Compoment'"
      );
    }

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.has(this.pagePerm)) {
          this.canViewPage = true;
          this.resetPagingAndData();
          this.loadGridData();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.has(this.pagePerm)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  public orgIdChanged(newValue: number | undefined): void {
    this.orgId = newValue;
  }

  public facilityIdChanged(newValue: number | undefined): void {
    this.facilityId = newValue;
  }

  public onDeviceTypeChanged(newValue: number | undefined) {
    this.deviceTypeId = newValue;

    if (this.deviceTypeChanged.observers.length > 0) {
      // DO NOT REFRESH the activity list here, the cart-fault-logs.component already causes this
      // i.e. The cart fault logs component listens for device type changed event
      // It changes the `query` property, which causes the ngOnChanges handler (in this component) to fire,
      // causing a "pageChanged" event... which in turn refreshes the Activity List. Otherwise we'd get
      // multiple API calls fired at the same time
      this.deviceTypeChanged.emit(newValue);
    } else {
      this.resetPagingAndData();
      this.loadGridData();
    }
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  /**
   * If Organization and Facility ID's are defined,
   * calls API for users list
   * Otherwise clears the list
   */
  public loadGridData(): void {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a "deselect", we want to make sure data doesn't come in
    // after we've went back to an "unselected" filter (prevents race condition bugs)
    this.apiSearchSub.unsubscribe();

    if (
      !this.orgId ||
      !this.facilityId ||
      this.deviceTypeId === undefined ||
      !this.canViewPage
    ) {
      return;
    }
    this.resetFilters();
    const transformation = (fieldName: string, filter: FilterDescriptor) => {
      if (typeof filter.value === 'string') {
        filter.value = filter.value.trim();
      }

      switch (fieldName) {
        case 'Message':
          this.query.Message = filter.value;
          break;
        case 'Description':
          this.query.Description = filter.value;
          break;
        case 'CartName':
          this.query.CartNames = [filter.value];
          break;
        case 'CartSerialNumber':
          this.query.CartSerialNumbers = [filter.value];
          break;
        case 'UserDisplayName':
        case 'UserName':
          this.query.UserNames = [filter.value];
          break;
        case 'ActivityDateUtc':
          if (filter.operator === 'gte') {
            this.query.DateRangeUtcStart = filter.value;
          } else if (filter.operator === 'lte') {
            this.query.DateRangeUtcEnd = filter.value;
          }
          break;
        default:
          throw new Error('Unrecognized field name');
      }
    };

    this.filterService.transform(this.gridFilter, transformation);

    // Org/Facility changed, reload list of users?
    this.gridDataLoading = true;
    this.apiSearchSub = this.activityApi
      .search(
        {
          PageNumber: this.skip / this.pageSize + 1,
          PageSize: this.pageSize,
        },
        this.orgId,
        this.facilityId,
        this.deviceTypeId,
        this.query
      )
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }

          this.gridDataLoading = false;
        },
        () => {
          this.gridDataLoading = false;
          // TODO trigger fail toast
        }
      );
  }

  /**
   * Ensures we always build filters from scratch
   * This prevents a previous filter value that's been cleared
   * from still being set on subsequent calls
   * Note: this needs to be updated each time we add a new filter
   */
  private resetFilters() {
    this.query.Message = undefined;
    this.query.Description = undefined;
    this.query.CartNames = undefined;
    this.query.CartSerialNumbers = undefined;
    this.query.UserNames = undefined;
    this.query.DateRangeUtcEnd = undefined;
    this.query.DateRangeUtcStart = undefined;
  }

  public sortChange(sort: SortDescriptor[]): void {
    if (this.gridDataLoading) {
      return;
    }

    this.sort = sort;
    this.query.SortInfo = [];
    this.sort.forEach((col) => {
      if (!col.dir) {
        return;
      }
      this.query.SortInfo.push({
        ColumnName: col.field,
        IsDescending: col.dir === 'desc',
      });
    });
    this.loadGridData();
  }

  public onFilterChanged(data: CompositeFilterDescriptor) {
    this.gridFilter = data;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public onExport() {
    this.exportData();
  }

  private exportData() {
    const data = this.gridDataBehSub.value;
    const row = [];
    // column headers
    this.columns.forEach((col) => {
      row.push(this.translate.instant(col.titleTag));
    });
    const rows = [];
    rows.push(row);
    data.forEach((line) => {
      const newRow = [];
      this.columns.forEach((col) => {
        newRow.push(line[col.field]);
      });
      rows.push(newRow);
    });
    const sheet = utils.aoa_to_sheet(rows);
    const workbook = utils.book_new();
    const sheetname = this.translate.instant('USER_CART_ACTIVITY');
    utils.book_append_sheet(workbook, sheet, sheetname);
    const filename = sheetname + '.xlsx';
    writeFile(workbook, filename);
  }
}
