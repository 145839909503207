import { AbstractControl } from '@angular/forms';
import { UserService } from '@capsa/services/user/user.service';
import { catchError, map } from 'rxjs/operators';

export const listHasItems = (ctl: AbstractControl) => {
  if ((ctl.value as []).length === 0) {
    return { emptyList: true };
  }
  return null;
};

/**
 *
 * @param userService user service
 * @param skipCheckUsername if the control value matches this value, then skip checking and return valid. This
 * allows us to use this validator when editing a username, when a textbox is already pre-filled with
 * the existing users username, then this will prevent a click into the textbox from incorrectly being
 * marked as invalid because the username already exists... of course it exists
 */
export const uniqueUsername =
  (userService: UserService, skipCheckUsername: string = null) =>
  (ctl: AbstractControl) => {
    if (!ctl.dirty) {
      return Promise.resolve(null);
    }

    if (typeof skipCheckUsername === 'string') {
      const skipUn = skipCheckUsername.toLowerCase();
      const ctlUn =
        typeof ctl.value === 'string' ? ctl.value.toLowerCase() : '';

      if (skipUn === ctlUn) {
        return Promise.resolve(null);
      }
    }

    /**
     * If username not unique, returns { isNotUniqueUsername: true }
     */
    return userService.checkUsernameUniqueness(ctl.value).pipe(
      map((r) => (r.Result ? null : { isNotUniqueUsername: true })),
      catchError((e) => {
        return Promise.resolve({ isNotUniqueUsername: true });
      })
    );
  };

export const isEmailRequired =
  (doNSightRolesExist: boolean) => (ctl: AbstractControl) => {
    if (doNSightRolesExist) {
      const emailVal =
        typeof ctl.value === 'string' ? ctl.value.toLowerCase() : '';
      if (emailVal.length === 0) {
        return Promise.resolve({
          isEmailRequiredBecauseNsightRolesExist: true,
        });
      } else {
        return Promise.resolve(null);
      }
    } else {
      // Either email is filled out, or no N-Sight roles assigned
      return Promise.resolve(null);
    }
  };
