import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { ApiResponse, DeviceUpdateRequest } from '..';
import { BaseApi } from '../base/base.api';

export interface DeviceVersionApiInstance {
  updateDevices(
    deviceVersionId: number,
    deviceIds: number[]
  ): Observable<ApiResponse<null>>;
}

@Injectable()
export class DeviceVersionApi
  extends BaseApi
  implements DeviceVersionApiInstance
{
  protected endpoints = {
    deviceUpdateUrl: this.buildUrl('device/updatedevices'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  /**
   * Requests list of devices to be updated to different version
   */
  public updateDevices(
    deviceVersionId: number,
    deviceIds: number[]
  ): Observable<ApiResponse<null>> {
    const deviceUpdate: DeviceUpdateRequest = {
      Ids: deviceIds,
    };

    return this.http.post<ApiResponse<null>>(
      this.endpoints.deviceUpdateUrl + `/${deviceVersionId}`,
      deviceUpdate
    );
  }
}
