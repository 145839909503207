import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CapsaSettingApiModel, DataDrivenListItem } from '@capsa/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-driven-drop-down',
  templateUrl: './data-driven-drop-down.component.html',
  styleUrls: ['./data-driven-drop-down.component.scss'],
})
export class DataDrivenDropDownComponent implements OnInit, OnChanges {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public disabled = false;

  @Output()
  public valueChanged = new EventEmitter();

  public visibleItems: DataDrivenListItem[] = [];
  public isFilterable: boolean;

  get value() {
    return Number.parseInt(this.setting.SettingValue, 10);
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.setControlProperties();
  }

  private setControlProperties() {
    this.visibleItems = (this.setting.ListItems || [])
      .filter((li) => li.IsVisible)
      .sort((a, b) => a.Value - b.Value);

    this.isFilterable = this.visibleItems.length > 30;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setting && !changes.setting.isFirstChange()) {
      this.setControlProperties();
    }
  }

  onValueChanged(value: number) {
    this.setting.SettingValue = value.toString();
    this.valueChanged.emit();
  }

  public handleFilterChange(search: string): void {
    this.visibleItems = (this.setting.ListItems || [])
      .filter((li) => {
        const translated = this.translateService.instant(li.NameTranslationTag);
        const isSearchMatch =
          translated.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        return li.IsVisible && isSearchMatch;
      })
      .sort((a, b) => a.Value - b.Value);
  }
}
