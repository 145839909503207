import { Injectable } from '@angular/core';
import { CapsaRoleResponseDto } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractAsyncMultiSelectDataSource } from '@capsa/dropdowns/async-multi-select-list/abstract-async-multi-select-data-source';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';
import { Constants } from '../../../../common/constants';

@Injectable()
export class CapsaRoleMultiSelectDataSource extends AbstractAsyncMultiSelectDataSource<CapsaRoleResponseDto> {
  public get canLoad(): boolean {
    return true;
  }
  public get defaultItem(): AsyncDropDownValue<CapsaRoleResponseDto>[] {
    return [];
  }

  /**
   * Required property
   */
  public currentFacilityId: number;

  constructor(
    private translationService: TranslateService,
    private roleService: CapsaRoleService
  ) {
    super();
  }

  public roleIds: number[] = [];

  public changeFacilityId(newFacilityId: number) {
    this.currentFacilityId = newFacilityId;
    this.reset();
  }

  /**
   * Returns a users assigned roles that are either NEVER to be displayed
   * or are part of a different facility
   */
  public get hiddenRoleIds$() {
    return this.roleService.rolesList$.pipe(
      map((x) =>
        x
          .filter(
            (y) =>
              Constants.hiddenRoleNames.some((z) => z === y.Name) ||
              y.FacilityId !== this.currentFacilityId
          )
          .map((role) => role.CapsaRoleId)
      )
    );
  }

  protected callApi(): Observable<CapsaRoleResponseDto[]> {
    if (isNaN(this.currentFacilityId)) {
      throw new Error(
        '"currentFacilityId" is required to be set when initializing "capsa-role-multi-select-data-source"'
      );
    }

    // wait for both observables to return (via forkJoin, and then just return the filtered search results)
    return forkJoin([
      this.roleService.rolesList$.pipe(take(1)),
      this.hiddenRoleIds$.pipe(take(1)),
    ]).pipe(
      map((responses) => {
        const allRoles = responses[0];
        const hiddenRoles = responses[1];

        const filtered = allRoles.filter(
          (x) => !hiddenRoles.find((y) => y === x.CapsaRoleId)
        );
        return filtered;
      }),
      delay(0)
    );
  }

  protected map(
    apiModel: CapsaRoleResponseDto
  ): AsyncDropDownValue<CapsaRoleResponseDto> {
    return {
      apiModel,
      id: apiModel.CapsaRoleId.toString(),
      name: this.translationService.instant(apiModel.Name),
    };
  }
  protected select(
    items: AsyncDropDownValue<CapsaRoleResponseDto>[]
  ): AsyncDropDownValue<CapsaRoleResponseDto>[] {
    return this.roleIds.length === 0
      ? []
      : items.filter((r) => this.roleIds.indexOf(r.apiModel.CapsaRoleId) > -1);
  }
}
