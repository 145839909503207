import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { SectionHeaderComponent } from './section-header/section-header.component';

@NgModule({
  declarations: [SectionHeaderComponent],
  imports: [CommonModule, TranslateModule, LabelsModule],
  exports: [SectionHeaderComponent],
})
export class HeadersModule {}
