export interface ColorCombo {
  fg: string;
  bg: string;
  bg2: string;
  bg3: string;
  name?: string;
}

export const ColorsList: ColorCombo[] = [
  { fg: 'white', bg: '#FF6358', bg2: '#EB867F', bg3: '#EBC1BE' },
  { fg: 'black', bg: '#FFD246', bg2: '#FFE28C', bg3: '#FFF1C7' },
  { fg: 'black', bg: '#78D237', bg2: '#ABEA7E', bg3: '#D0E9BF' },
  { fg: 'black', bg: '#28B4C8', bg2: '#70DAEA', bg3: '#B1E1E9' },
  { fg: 'white', bg: '#2D73F5', bg2: '#6C98EA', bg3: '#ACC1E9' },
  { fg: 'white', bg: '#AA46BE', bg2: '#D257EB', bg3: '#DB97E9' },
  { fg: 'white', bg: '#8D6E63', bg2: '#B58D7F', bg3: '#E3D1CA' },
  { fg: 'black', bg: '#FFAB91', bg2: '#EDD2CA', bg3: '#FCEDE8' },
  { fg: 'black', bg: '#00E676', bg2: '#42ED9B', bg3: '#8EFEC8' },
  { fg: 'white', bg: '#E91E63', bg2: '#ED6190', bg3: '#FEAFC9' },
  // { fg: 'black', bg: '#C8E6C9', bg2: '#E4F2E4', bg3: '#EDFCED' },
  { fg: 'white', bg: '#0091EA', bg2: '#42ACED', bg3: '#8ED3FE' },
  { fg: 'white', bg: '#212121', bg2: '#616161', bg3: '#D4D4D4' },
  { fg: 'black', bg: '#1DE9B6', bg2: '#5FEDCA', bg3: '#ACFEE9' },
  { fg: 'black', bg: '#90CAF9', bg2: '#B6DCFA', bg3: '#DCEDFA' },
  { fg: 'black', bg: '#C6FF00', bg2: '#E1FF75', bg3: '#F2FFC2' },
  { fg: 'black', bg: '#42A5F5', bg2: '#82BDED', bg3: '#D2EAFE' },
  { fg: 'white', bg: '#F44336', bg2: '#F58078', bg3: '#FEC7C3' },
  { fg: 'white', bg: '#827717', bg2: '#9C955A', bg3: '#D4CFA7' },
  { fg: 'white', bg: '#D500F9', bg2: '#D442ED', bg3: '#ED8EFE' },
  { fg: 'white', bg: '#388E3C', bg2: '#62B366', bg3: '#D2FED5' },
  { fg: 'white', bg: '#F50057', bg2: '#FF4586', bg3: '#FE8BB3' },
  { fg: 'black', bg: '#F4FF81', bg2: '#E9EDBB', bg3: '#F3F5DC' },
  { fg: 'white', bg: '#0288D1', bg2: '#45B2ED', bg3: '#91D7FE' },
  { fg: 'white', bg: '#7986CB', bg2: '#9BA6DE', bg3: '#D3D8F2' },
  { fg: 'black', bg: '#76FF03', bg2: '#91ED45', bg3: '#C2FE91' },
  { fg: 'white', bg: '#C2185B', bg2: '#ED5F98', bg3: '#FEACCD' },
  { fg: 'white', bg: '#33691E', bg2: '#55AD32', bg3: '#B0FC92' },
  { fg: 'white', bg: '#7E57C2', bg2: '#8D6BC7', bg3: '#C9ACFC' },
  { fg: 'black', bg: '#FF9800', bg2: '#E0A03F', bg3: '#FCD292' },
  { fg: 'black', bg: '#BCAAA4', bg2: '#D6C1BA', bg3: '#FCE4DC' },
  { fg: 'white', bg: '#311B92', bg2: '#644BD6', bg3: '#B1A0FE' },
  { fg: 'black', bg: '#FFEB3B', bg2: '#E9DD77', bg3: '#FEF8C8' },
  { fg: 'white', bg: '#536DFE', bg2: '#7A8EFF', bg3: '#C1CAFE' },
  { fg: 'white', bg: '#827717', bg2: '#AB9D1F', bg3: '#E8DB64' },
  { fg: 'white', bg: '#FF3D00', bg2: '#FF784F', bg3: '#FEAE96' },
  { fg: 'white', bg: '#0D47A1', bg2: '#1160D6', bg3: '#5B9CFE' },
  { fg: 'black', bg: '#CCFF90', bg2: '#D7E9C4', bg3: '#E9FCD4' },
  { fg: 'white', bg: '#757575', bg2: '#D6D6D6', bg3: '#FCFCFC' },
  { fg: 'white', bg: '#E040FB', bg2: '#D87BE9', bg3: '#F6CDFE' },
  { fg: 'white', bg: '#3E2723', bg2: '#734840', bg3: '#FEDBD5' },
  { fg: 'black', bg: '#C0CA33', bg2: '#D7E05A', bg3: '#F8FEAC' },
  { fg: 'black', bg: '#64FFDA', bg2: '#9CE9D7', bg3: '#C2FCEF' },
  { fg: 'black', bg: '#01579B', bg2: '#027AD6', bg3: '#81C7FC' },
  { fg: 'white', bg: '#FFA726', bg2: '#FFC266', bg3: '#F0D9B6' },
  { fg: 'white', bg: '#424242', bg2: '#7D7D7D', bg3: '#D6D6D6' },
  { fg: 'black', bg: '#64DD17', bg2: '#90E958', bg3: '#C3E9AC' },
  { fg: 'white', bg: '#607D8B', bg2: '#94C0D6', bg3: '#BCD9E8' },
  { fg: 'white', bg: '#E65100', bg2: '#E67437', bg3: '#E9AC8C' },
  { fg: 'white', bg: '#006064', bg2: '#1A868A', bg3: '#80E5E9' },
  { fg: 'black', bg: '#FFD54F', bg2: '#FFE58F', bg3: '#FFF1C4' },
  { fg: 'white', bg: '#004D40', bg2: '#219683', bg3: '#A7E8DD' },
  { fg: 'black', bg: '#EEFF41', bg2: '#F5FF8A', bg3: '#FAFFC7' },
  { fg: 'black', bg: '#90A4AE', bg2: '#B2CAD6', bg3: '#DAECF5' },
];
