import { Injectable } from '@angular/core';
import {
  ApiResponse,
  MessageSubjectPresetCreateRequest,
  MessageSubjectPresetResponse,
  MessageSubjectPresetSearchRequest,
  MessageSubjectPresetUpdateRequest,
} from '@capsa/api';
import { BaseApi } from '@capsa/api/base/base.api';
import { Observable } from 'rxjs';

export interface MessageSubjectPresetApiInstance {
  createPreset(
    request: MessageSubjectPresetCreateRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse>>;

  searchPreset(
    request: MessageSubjectPresetSearchRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse[]>>;

  deletePreset(id: number): Observable<ApiResponse<any>>;

  updatePreset(
    id: number,
    request: MessageSubjectPresetUpdateRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse>>;
}

@Injectable()
export class MessageSubjectPresetApi
  extends BaseApi
  implements MessageSubjectPresetApiInstance
{
  protected endpoints = {
    postMessageSubjectPreset: this.buildUrl('message-queue-subject-preset'),
    searchMessageSubjectPreset: this.buildUrl(
      'message-queue-subject-preset/search'
    ),
    deleteMessageSubjectPreset: this.buildUrl('message-queue-subject-preset/'),
    updateMessageSubjectPreset: this.buildUrl('message-queue-subject-preset/'),
  };

  createPreset(
    request: MessageSubjectPresetCreateRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse>> {
    return this.http.post<ApiResponse<MessageSubjectPresetResponse>>(
      this.endpoints.postMessageSubjectPreset,
      request
    );
  }

  searchPreset(
    request: MessageSubjectPresetSearchRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse[]>> {
    return this.http.post<ApiResponse<MessageSubjectPresetResponse[]>>(
      this.endpoints.searchMessageSubjectPreset,
      request
    );
  }

  deletePreset(id: number): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(
      this.endpoints.deleteMessageSubjectPreset + id
    );
  }

  updatePreset(
    id: number,
    request: MessageSubjectPresetUpdateRequest
  ): Observable<ApiResponse<MessageSubjectPresetResponse>> {
    return this.http.post<ApiResponse<MessageSubjectPresetResponse>>(
      this.endpoints.updateMessageSubjectPreset + id,
      request
    );
  }
}
