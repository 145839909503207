import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { DataDrivenCheckBoxConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-checkbox',
  templateUrl: './data-driven-checkbox.component.html',
  styleUrls: ['./data-driven-checkbox.component.scss'],
})
export class DataDrivenCheckboxComponent {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public config: DataDrivenCheckBoxConfig;

  @Input()
  public disabled = false;

  @Output()
  public valueChanged = new EventEmitter<boolean>();

  get valueAsBool() {
    if (this.config) {
      return this.setting.SettingValue === this.config.trueValue ? true : false;
    }
    return this.setting.SettingValue === '1' ? true : false;
  }

  onValueChanged(ev: Event) {
    if (this.config) {
      this.setting.SettingValue = (ev.currentTarget as any).checked
        ? this.config.trueValue
        : this.config.falseValue;
    } else {
      this.setting.SettingValue = (ev.currentTarget as any).checked ? '1' : '0';
    }

    this.valueChanged.emit(this.valueAsBool);
  }

  constructor() {}
}
