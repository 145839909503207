import { Component, Input } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { SettingMap } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-battery-display',
  templateUrl: './battery-display.component.html',
  styleUrls: ['./battery-display.component.scss'],
})
export class BatteryDisplayComponent {
  @Input()
  public settings: SettingMap;

  get redBetween() {
    return this.settings.getSetting('Red');
  }

  get yellowBetween() {
    return this.settings.getSetting('Yellow');
  }

  onChange(setting: CapsaSettingApiModel, newValue: number) {
    if (
      setting === this.yellowBetween &&
      newValue <= Number(this.redBetween.SettingValue)
    ) {
      this.redBetween.SettingValue = newValue.toString();
    }
    if (
      setting === this.redBetween &&
      newValue >= Number(this.yellowBetween.SettingValue)
    ) {
      this.yellowBetween.SettingValue = newValue.toString();
    }
    setting.SettingValue = newValue.toString();
  }

  constructor() {}
}
