import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DeviceTypeIds,
  UserCreateRequest,
  UserImportConflictResponse,
  UserResponse,
} from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { SessionTimeoutService } from '@capsa/services/session-timeout/session-timeout.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserImportBatch } from 'app/modules/users/user-management/import-users/user-import-batch';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-import-users-dialog',
  templateUrl: './import-users-dialog.component.html',
  styleUrls: ['./import-users-dialog.component.scss'],
})
export class ImportUsersDialogComponent implements OnInit, OnDestroy {
  @Input() usersToImport: UserCreateRequest[];
  @Input() generatePins: boolean;
  @Input() deviceTypeId: DeviceTypeIds;
  @Output() public cancel = new EventEmitter();
  public dataConflicts = {
    HasConflicts: false,
    ConflictingPINs: [],
    ConflictingSecondaryPINs: [],
    ConflictingUsernames: [],
  } as UserImportConflictResponse;
  private batchesToTransfer: UserImportBatch[] = [];
  private subs = new Subscription();
  private unknownErrorEncountered = false;
  private gridBatchesToTransferBehSub = new BehaviorSubject<UserImportBatch[]>(
    []
  );
  /**
   * Minimum time between "request cycles" (i.e. at least X milliseconds must elapse between beginning
   * of request and the next request)
   */
  private readonly minThrottleTimeMs = 1250;

  private throttleTimerSub: Subscription;

  // If true, no new requests should be issued. Any in progress ones will be
  // allowed to complete (not like we can cancel them anyways...)
  public isPaused = false;

  /**
   * If true, then a batch transfer request has been made to the API and hasn't returned ANY response yet.
   */
  public requestPending = false;

  /**
   * If this is true, then it's ok to issue the next transfer request, if false, then
   * some condition is preventing the next request from being issued (e.g. minimum time
   * between requests hasn't elapsed)
   */
  public isThrottleTimeElapsed = true;

  // BatchName means group ###-###
  public processingBatchName: string;

  public totalBatchCount = 0;
  public successBatchCount = 0;
  public failBatchCount = 0;

  public isStarted = false;

  public get doBatchesRemainInQueue() {
    return this.gridBatchesToTransferBehSub.value.length > 0;
  }

  public get isAllProcessingComplete() {
    return !this.doBatchesRemainInQueue && !this.requestPending;
  }

  public userCount: number;

  constructor(
    private api: UserApi,
    private sessionTimeoutService: SessionTimeoutService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.createBatches();
    this.gridBatchesToTransferBehSub.next(this.batchesToTransfer);
    this.totalBatchCount = this.batchesToTransfer.length;
    this.userCount = this.usersToImport.length;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    if (this.throttleTimerSub) {
      this.throttleTimerSub.unsubscribe();
    }
  }

  private createBatches() {
    const batchSize = 200;
    for (let i = 0; i < this.usersToImport.length; i += batchSize) {
      const batch = this.usersToImport.slice(i, i + batchSize);
      const importBatch: UserImportBatch = {
        Batch: batch,
        BatchStart: i + 1,
        BatchEnd: i + batch.length,
        ImportSuccess: true,
      };
      this.batchesToTransfer.push(importBatch);
    }
  }

  public onClose() {
    this.cancel.emit(this.dataConflicts);
  }

  private transferNextBatch() {
    if (this.isPaused || !this.doBatchesRemainInQueue) {
      return;
    }
    this.sessionTimeoutService.registerEmulatedUserActivity();
    this.requestPending = true;

    const nextBatch = this.gridBatchesToTransferBehSub.value.shift();
    this.gridBatchesToTransferBehSub.next(
      this.gridBatchesToTransferBehSub.value
    );

    this.processingBatchName =
      nextBatch.BatchStart + ' - ' + nextBatch.BatchEnd;

    const importObs$: Observable<UserResponse[]> = this.api.importUsersV2({
      Users: nextBatch.Batch,
      GeneratePINs: this.generatePins,
      DeviceTypeForUserPins: this.deviceTypeId,
    });

    const processedBatch: UserImportBatch = {
      BatchStart: nextBatch.BatchStart,
      BatchEnd: nextBatch.BatchEnd,
      Batch: nextBatch.Batch,
      ImportSuccess: true, // will flip to false in case fail
    };

    this.isThrottleTimeElapsed = false;
    this.throttleTimerSub = timer(this.minThrottleTimeMs).subscribe(() => {
      if (
        !this.requestPending &&
        this.doBatchesRemainInQueue &&
        !this.isPaused
      ) {
        this.transferNextBatch();
      } else {
        this.isThrottleTimeElapsed = true;
      }
    });

    importObs$
      .pipe(
        finalize(() => {
          this.processingBatchName = null;
          this.requestPending = false;

          // don't spam failure toast, only show when completed or after user pauses import
          if (
            this.unknownErrorEncountered &&
            (!this.doBatchesRemainInQueue || this.isPaused)
          ) {
            this.toasterService.showError('COM_UNKNOWN_API_ERROR');
          }

          if (
            this.doBatchesRemainInQueue &&
            this.isThrottleTimeElapsed &&
            !this.isPaused
          ) {
            this.transferNextBatch();
          }
        })
      )
      .subscribe(
        () => {
          this.successBatchCount++;
        },
        (error) => {
          if (error.status !== 400) {
            // if not bad request, we don't get a decent error message
            // that can cast to UserImportResponse, so just provide
            // generic error to user without spamming the toaster
            processedBatch.ImportSuccess = false;
            this.failBatchCount++;
            this.unknownErrorEncountered = true;
            return;
          }

          processedBatch.ImportSuccess = false;
          const conflictResponse = error.error
            .Result as UserImportConflictResponse;
          this.dataConflicts.HasConflicts = true;
          if (conflictResponse.ConflictingPINs.length > 0) {
            this.dataConflicts.ConflictingPINs =
              this.dataConflicts.ConflictingPINs.concat(
                conflictResponse.ConflictingPINs
              );
          }
          if (conflictResponse.ConflictingSecondaryPINs.length > 0) {
            this.dataConflicts.ConflictingSecondaryPINs =
              this.dataConflicts.ConflictingSecondaryPINs.concat(
                conflictResponse.ConflictingSecondaryPINs
              );
          }
          if (conflictResponse.ConflictingUsernames.length > 0) {
            this.dataConflicts.ConflictingUsernames =
              this.dataConflicts.ConflictingUsernames.concat(
                conflictResponse.ConflictingUsernames
              );
          }
          this.failBatchCount++;
        }
      );
  }

  public onTogglePauseResume() {
    if (this.isPaused) {
      this.isPaused = false;

      if (
        !this.requestPending &&
        this.doBatchesRemainInQueue &&
        this.isThrottleTimeElapsed
      ) {
        this.transferNextBatch();
      }
    } else {
      this.isPaused = true;
    }
  }

  public onStartImport() {
    this.isStarted = true;
    this.transferNextBatch();
  }
}
