import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CapsaRoleResponseDto, CapsaSettingApiModel } from '@capsa/api';
import { SettingsApi } from '@capsa/api/settings';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-cart-settings-tab',
  templateUrl: './edit-user-cart-settings-tab.component.html',
  styleUrls: ['./edit-user-cart-settings-tab.component.scss'],
})
export class EditUserCartSettingsTabComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  public organizationId: number;

  @Input()
  public deviceTypeId: number;

  public loadingSettings = true;

  // Carelink/Trio role based "setting"
  public witnessAuthEnabled: boolean;

  private subs = new Subscription();

  // The "Witness Auth" roleId for the current facility
  // used for quick lookup/updates of role ID lists
  private witnessAuthRoleId: number;

  private flatSettingsList: CapsaSettingApiModel[] = [];

  constructor(
    private settingsApi: SettingsApi,
    private capsaRoleService: CapsaRoleService,
    private editUserService: EditUserService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.editUserService.selectedFacilityChanged$.subscribe((fId) => {
        this.loadSettingsForSelectedDeviceType();
        this.loadRoleBasedSettingValues();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceTypeId && !changes.deviceTypeId.isFirstChange()) {
      this.loadSettingsForSelectedDeviceType();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadSettingsForSelectedDeviceType() {
    const existingDeviceSettings =
      this.editUserService.getUserFacilityDeviceSettings(this.deviceTypeId);

    if (existingDeviceSettings) {
      this.loadingSettings = false;
      return;
    }

    this.loadingSettings = true;
    this.flatSettingsList.length = 0;

    const sub = this.settingsApi
      .getUserFacilitySettings({
        CapsaUserId: this.editUserService.userId,
        FacilityId: this.editUserService.selectedFacilityId,
        DeviceType: this.deviceTypeId,
      })
      .pipe(finalize(() => (this.loadingSettings = false)))
      .subscribe(
        (response) => {
          this.editUserService.importSingleDeviceTypeFacilitySettingsFromApi(
            response.Result
          );
        },
        (err) => {
          this.toasterService.showError('GET_USER_SETTINGS_FAIL');
        }
      );
    this.subs.add(sub);
  }
  public onWitnessAuthChanged(witnessAuth: boolean) {
    this.witnessAuthEnabled = witnessAuth;
    let allUsersRoleIds = this.editUserService.updatedUser.get('RoleIds');
    allUsersRoleIds = this.addOrRemoveTrioWitnessAuthRole(allUsersRoleIds);
    this.editUserService.updateUserRoles(allUsersRoleIds);
  }

  private addOrRemoveTrioWitnessAuthRole(updatedRoles: number[]) {
    const assignedWitnessAuthRoleIdx = updatedRoles.indexOf(
      this.witnessAuthRoleId
    );

    if (this.witnessAuthEnabled && assignedWitnessAuthRoleIdx < 0) {
      // doesn't exists, so add.
      updatedRoles = updatedRoles.concat(this.witnessAuthRoleId);
    }

    if (!this.witnessAuthEnabled && assignedWitnessAuthRoleIdx > -1) {
      // exists, so remove.
      updatedRoles.splice(assignedWitnessAuthRoleIdx, 1);
    }
    return updatedRoles;
  }

  private loadRoleBasedSettingValues() {
    this.subs.add(
      this.capsaRoleService.rolesList$.subscribe((roles) => {
        this.setTrioRoleBasedSettingValues(roles);
      })
    );
  }

  /**
   * Runs only once when component loads
   * Sets the roleID for later use
   * Sets the UI elements enabled/disabled state
   */
  private setTrioRoleBasedSettingValues(roles: CapsaRoleResponseDto[]) {
    this.witnessAuthRoleId = roles.find(
      (x) =>
        x.Name === 'CLI_DeviceSupervisor' &&
        x.FacilityId === this.editUserService.selectedFacilityId
    ).CapsaRoleId;

    this.witnessAuthEnabled = this.editUserService.updatedUser
      .get('RoleIds')
      .some((rId) => rId === this.witnessAuthRoleId);
  }
}
