import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticQueryType, DashboardWidgetResponse } from '@capsa/api';
import { DashboardApi } from '@capsa/api/dashboard';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public orgId: number;
  public facilityId: number;
  public lastRefreshedTime: number;
  public totalCarts: number;
  public widgetTimeRangeHours = 24;
  public widgetTimeOverride = false;

  private lockedCartsSub = new BehaviorSubject<DashboardWidgetResponse>(null);
  public lockedCarts$ = this.lockedCartsSub.asObservable();

  private cartsNotSyncedSub = new BehaviorSubject<DashboardWidgetResponse>(
    null
  );
  public cartsNotSynced$ = this.cartsNotSyncedSub.asObservable();

  private underutilizedCartsSub = new BehaviorSubject<DashboardWidgetResponse>(
    null
  );
  public underutilizedCarts$ = this.underutilizedCartsSub.asObservable();

  private cartPowerNotChargedSub = new BehaviorSubject<DashboardWidgetResponse>(
    null
  );
  public cartPowerNotCharged$ = this.cartPowerNotChargedSub.asObservable();

  private cartPowerStationarySub = new BehaviorSubject<DashboardWidgetResponse>(
    null
  );
  public cartPowerStationary$ = this.cartPowerStationarySub.asObservable();

  private noSignInsSub = new BehaviorSubject<DashboardWidgetResponse>(null);
  public noSignIns$ = this.noSignInsSub.asObservable();

  private cartLocationSub = new BehaviorSubject<DashboardWidgetResponse>(null);
  public cartLocation$ = this.cartLocationSub.asObservable();

  private subs = new Subscription();
  private widgetSubs: Subscription[] = [];
  public canViewPage = false;
  private pagePerms: Permissions[] = [
    Permissions.CAP_Dashboard_Analytic_Widget_Read,
    Permissions.CAP_Dashboard_Analytic_Widget_ReadWrite,
  ];

  constructor(
    private dashboardApi: DashboardApi,
    private permissionService: PermissionService,
    private toasterService: ToasterService,
    private aiService: AppInsightsService
  ) {}

  ngOnInit() {
    this.handleTimeRangeOverride();

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.requestAndFillDashboardStats();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.requestAndFillDashboardStats();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.unsubscribeWidgetSubs();
  }

  /**
   * Provides ability to override the widget time range and set it to something other than 24 hours
   * useful for testing purposes
   */
  private handleTimeRangeOverride() {
    const val = (localStorage.getItem('override_widget_time_range') as any) * 1;
    if (!isNaN(val) && val > 0) {
      this.widgetTimeRangeHours = val;
      this.widgetTimeOverride = true;
    }
  }

  private unsubscribeWidgetSubs() {
    while (this.widgetSubs.length > 0) {
      this.widgetSubs.pop().unsubscribe();
    }
  }

  public orgIdChanged(newValue: number | undefined): void {
    this.orgId = newValue;
  }

  public facilityIdChanged(newValue: number | undefined): void {
    this.facilityId = newValue;
  }

  public refreshBtnClicked() {
    this.aiService.btnClickTrace('dashboard-refresh', {
      facilityId: this.facilityId,
    });
    this.requestAndFillDashboardStats();
  }

  private requestAndFillDashboardStats(): void {
    this.unsubscribeWidgetSubs();
    this.lockedCartsSub.next(null);
    this.noSignInsSub.next(null);
    this.cartPowerNotChargedSub.next(null);
    this.cartPowerStationarySub.next(null);
    this.cartLocationSub.next(null);
    this.underutilizedCartsSub.next(null);
    this.cartsNotSyncedSub.next(null);

    this.lastRefreshedTime = 0;

    if (!this.orgId || !this.facilityId) {
      this.lastRefreshedTime = 0;
      return;
    }
    const requestMadeTime = Date.now();
    const sub = this.dashboardApi
      .getDashboardStats([
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartLockouts,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsNoLogins,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsOnBatteryPower,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsStationary,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsChangedWifiZones,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsUnderutilized,
        },
        {
          OrganizationId: this.orgId,
          FacilityId: this.facilityId,
          DeviceType: 9,
          SinceHoursAgo: this.widgetTimeRangeHours,
          QueryType: AnalyticQueryType.CartsNotSynced,
        },
      ])
      .subscribe(
        (resp) => {
          // For now just use the first instance of the Denominator (in the future we may smiply remove
          // this and get the number of carts a different way)
          this.totalCarts = resp[0].Denominator;

          resp.forEach((x) => {
            switch (x.QueryType) {
              case AnalyticQueryType.CartLockouts:
                this.lockedCartsSub.next(x);
                break;
              case AnalyticQueryType.CartsNoLogins:
                this.noSignInsSub.next(x);
                break;
              case AnalyticQueryType.CartsOnBatteryPower:
                this.cartPowerNotChargedSub.next(x);
                break;
              case AnalyticQueryType.CartsStationary:
                this.cartPowerStationarySub.next(x);
                break;
              case AnalyticQueryType.CartsChangedWifiZones:
                this.cartLocationSub.next(x);
                break;
              case AnalyticQueryType.CartsUnderutilized:
                this.underutilizedCartsSub.next(x);
                break;
              case AnalyticQueryType.CartsNotSynced:
                this.cartsNotSyncedSub.next(x);
                break;
              default:
                break;
            }
          });
          this.lastRefreshedTime = requestMadeTime;
        },
        (error) => {
          this.toasterService.showError('DASHBOARD_WIDGETS_FAILED');
        }
      );
    this.widgetSubs.push(sub);
  }
}
