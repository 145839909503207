// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderVal {
  display: block;
  width: 45px;
  float: left;
  margin-top: 4px;
  font-weight: bold;
}

kendo-slider {
  width: calc(100% - 45px);
  max-width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/data-driven-controls/data-driven-switch/data-driven-switch.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,WAJe;EAKf,WAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,wBAAA;EACA,gBAAA;AADF","sourcesContent":["$sliderValWidth: 45px;\n\n.sliderVal {\n  display: block;\n  width: $sliderValWidth;\n  float: left;\n  margin-top: 4px;\n  font-weight: bold;\n}\n\nkendo-slider {\n  width: calc(100% - #{$sliderValWidth});\n  max-width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
