import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { AuthService } from '@capsa/services/auth/auth.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { SessionTimeoutService } from '@capsa/services/session-timeout/session-timeout.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { APP_VERSION } from 'src/environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('rootAllContent', { read: ViewContainerRef })
  public vcrRootAllContent: ViewContainerRef;

  title = 'N-Sight 2';
  private authAndPermissions$ = combineLatest([
    this.authService.isAuthenticated$,
    this.permissionService.isReady$,
  ]);

  private servicesReadyBehSubj = new BehaviorSubject<boolean>(false);
  public servicesReady$ = this.servicesReadyBehSubj.asObservable();

  private get authAndPermissionsReady$() {
    return this.authAndPermissions$.pipe(
      map((results) => results[0] && results[1])
    );
  }
  public currentYear: number;

  public appVersion: string = APP_VERSION;
  public get loaderServiceText() {
    return this.loaderService.text;
  }

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private eofCacheService: EofCacheService,
    private userEofCacheService: UserEofCacheService,
    public loaderService: LoaderService,
    public sessionTimeoutService: SessionTimeoutService,

    // Do not remove, we  need this to initialize things
    public aiService: AppInsightsService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    combineLatest([
      this.eofCacheService.initialized$,
      this.userEofCacheService.initialized$,
    ])
      .pipe(
        filter((caches) => {
          // Filter out emits that don't have both caches loaded
          return !!caches[0] && !!caches[1];
        })
      )
      .subscribe((cacheData) => {
        this.servicesReadyBehSubj.next(true);
      });

    this.authAndPermissionsReady$.subscribe((ready) => {
      if (!ready) {
        return;
      }

      this.eofCacheService.init();
      this.userEofCacheService.init();
      this.sessionTimeoutService.Init();
    });
  }

  public extendSession() {
    this.sessionTimeoutService.userRequestExtendSession();
  }
}
