import { Injectable } from '@angular/core';
import {
  DeviceTypeFirmwareVersionResponse,
  DeviceTypeIds,
  DeviceUpdateType,
  FirmwareVersionDetailsResponse,
} from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Observable, Subject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SharedDeviceUpdateService {
  constructor(
    private firmHardWareApi: FirmHardWareApi,
    private toasterService: ToasterService
  ) {}

  public get deviceTypeId(): DeviceTypeIds {
    return DeviceTypeIds.CareLink_2;
  }

  private _updateType = DeviceUpdateType.Firmware;

  public setUpdateType(value: DeviceUpdateType) {
    this._updateType = value;
  }

  public get updateType(): DeviceUpdateType {
    return this._updateType;
  }

  private cachedFwVersion = new Map<
    DeviceTypeIds,
    Map<DeviceUpdateType, FirmwareVersionDetailsResponse[]>
  >();

  public isUploadDialogOpenSubj = new Subject<boolean>();
  public isUploadDialogOpen$ = this.isUploadDialogOpenSubj.asObservable();

  public getFwVersionList(): Observable<FirmwareVersionDetailsResponse[]> {
    if (!this.deviceTypeId) {
      this.toasterService.showError('DeviceTypeId not specified');
      throw new Error('DeviceTypeId not specified');
    }

    if (!this.updateType) {
      this.toasterService.showError('updateType not specified');
      throw new Error('updateType not specified');
    }

    if (this.cachedFwVersion.has(this.deviceTypeId)) {
      return of(this.getFwVersionFromCache(this.deviceTypeId, this.updateType));
    }

    // Since data was not in cache, call API
    // fill cache
    return this.firmHardWareApi
      .getFirmwareVersionsByDeviceTypeId(this.deviceTypeId)
      .pipe(
        tap((resp) => this.fillFwVersionCache(resp)),
        map(() =>
          this.getFwVersionFromCache(this.deviceTypeId, this.updateType)
        )
      );
  }

  private getFwVersionFromCache(
    deviceTypeId: DeviceTypeIds,
    updateType?: DeviceUpdateType
  ): FirmwareVersionDetailsResponse[] {
    const dtMap = this.cachedFwVersion.get(deviceTypeId);

    if (!dtMap) {
      return [];
    }

    // Returns only items for the given dtID and updateType
    if (updateType) {
      const updateTypeMap = dtMap.get(updateType);
      return updateTypeMap ? updateTypeMap : [];
    }

    const allFwForDt: FirmwareVersionDetailsResponse[] = [];

    // Combines all update types and returns them
    for (const val of dtMap.values()) {
      allFwForDt.push(...val);
    }

    return allFwForDt;
  }

  private fillFwVersionCache(fwResp: DeviceTypeFirmwareVersionResponse) {
    if (this.cachedFwVersion.has(fwResp.DeviceTypeId)) {
      return;
    }

    const dtMap = new Map<DeviceUpdateType, FirmwareVersionDetailsResponse[]>();

    fwResp.FirmwareVersions.forEach((f) => {
      const existList = dtMap.get(f.UpdateType);
      if (!existList) {
        dtMap.set(f.UpdateType, [f]);
      } else {
        existList.push(f);
      }
    });

    this.cachedFwVersion.set(fwResp.DeviceTypeId, dtMap);
  }

  public clearFwVersionCacheForCurrentDeviceType() {
    this.cachedFwVersion.delete(this.deviceTypeId);
  }
}
