import { Component, OnDestroy } from '@angular/core';
import { UserPasswordUpdateRequest } from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { AuthService } from '@capsa/services/auth/auth.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { SavePasswordChangeEvent } from 'app/menu/user/set-password/set-password.component';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy {
  private subs = new Subscription();

  constructor(
    private userApi: UserApi,
    private authService: AuthService,
    private toasterService: ToasterService,
    private loaderService: LoaderService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public savePassword = (saveEvent: SavePasswordChangeEvent) => {
    const request: Partial<UserPasswordUpdateRequest> = {
      UserName: this.authService.currentUser.unique_name,
      NewPassword: saveEvent.newPassword,
      OldPassword: saveEvent.oldPassword,
    };
    const taskId = 'update-pass';
    this.loaderService.start(taskId);
    const sub = this.userApi
      .updatePassword(request)
      .pipe(finalize(() => this.loaderService.stop(taskId)))
      .subscribe(
        (_resp) => {
          this.toasterService.showSuccess('PASSWORD_CHANGE_SUCCESS');
        },
        (_error) => {
          this.toasterService.showError('PASSWORD_CHANGE_FAILED');
        }
      );

    this.subs.add(sub);
  };
}
