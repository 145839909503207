import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthYearDate',
})
export class MonthYearDatePipe implements PipeTransform {
  transform(value: string): any {
    return value ? value.substring(0, 7) : 'unreleased';
  }
}
