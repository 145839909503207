import { Injectable } from '@angular/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class GridFilterHelperService {
  constructor() {}

  public transform(
    descriptor: CompositeFilterDescriptor,
    transformation: (fieldName: string, rawFilter: FilterDescriptor) => void
  ) {
    if (descriptor && descriptor.filters.length > 0) {
      descriptor.filters.forEach((x) => {
        if (x as FilterDescriptor) {
          const rawFilter = x as FilterDescriptor;
          const fieldName = rawFilter.field as string;

          transformation(fieldName, rawFilter);
        }
      });
    }
  }
}
