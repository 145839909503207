import { Component } from '@angular/core';

@Component({
  selector: 'app-fault-events-log',
  templateUrl: './fault-events-log.component.html',
  styleUrls: ['./fault-events-log.component.scss'],
})
export class FaultEventsLogComponent {
  constructor() {}
}
