import { Component, Input } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';
import { SettingMap } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent {
  @Input()
  public settings: SettingMap;

  public dateFormatValues: DropDownItem[] = [
    { Label: this.translations.instant('None'), Value: '' },
    { Label: 'M/d/yy', Value: 'M/d/yy' },
    { Label: 'M/d/yy HH:mm', Value: 'M/d/yy HH:mm' },
    { Label: 'M/d/yy h:mm tt', Value: 'M/d/yy h:mm tt' },
    { Label: 'M/d/yy hh:mm tt', Value: 'M/d/yy hh:mm tt' },
    { Label: 'MM-DD-YY', Value: 'MM-dd-yy' },
    { Label: 'MM-DD-YY HH:mm', Value: 'MM-dd-yy HH:mm' },
    { Label: 'MM-DD-YY h:mm tt', Value: 'MM-dd-yy h:mm tt' },
    { Label: 'MM-DD-YY hh:mm tt', Value: 'MM-dd-yy hh:mm tt' },
    { Label: 'DD-MM-YY', Value: 'dd-MM-yy' },
    { Label: 'DD-MM-YY HH:mm', Value: 'dd-MM-yy HH:mm' },
    { Label: 'DD-MM-YY h:mm tt', Value: 'dd-MM-yy h:mm tt' },
    { Label: 'DD-MM-YY hh:mm tt', Value: 'dd-MM-yy hh:mm tt' },
    { Label: 'HH:mm', Value: 'HH:mm' },
    { Label: 'hh:mm tt', Value: 'hh:mm tt' },
  ];

  get dateTimeFormat() {
    return this.settings.getSetting('DateTimeFormat');
  }

  get displayDepartment() {
    return this.settings.getSetting('Department');
  }

  get displayCartName() {
    return this.settings.getSetting('CartName');
  }

  get displayUserName() {
    return this.settings.getSetting('UserName');
  }

  get displayLoginStatus() {
    return this.settings.getSetting('LoginStatus');
  }

  booleanValueChanged(setting: CapsaSettingApiModel, selected: boolean) {
    setting.SettingValue = selected === true ? '1' : '0';
  }

  stringValueChanged(
    setting: CapsaSettingApiModel,
    selectedFormat: DropDownItem
  ) {
    setting.SettingValue = selectedFormat.Value as string;
  }

  constructor(private translations: TranslateService) {}
}
