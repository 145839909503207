import { Component, OnDestroy, OnInit } from '@angular/core';
import { read, utils } from 'xlsx';

import {
  accessPointImportColumns,
  AccessPointImportRecord,
  NetworkApImportRequest,
} from '@capsa/api';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { BlobStorageService } from '@capsa/services/blob-storage/blob-storage.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from '@progress/kendo-angular-upload';
import { Constants } from 'app/common/constants';
import { ErrorBlockMessage } from 'app/modules/core/error-block/error-block.component';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-import-access-points',
  templateUrl: './facility-import-access-points.component.html',
  styleUrls: ['./facility-import-access-points.component.scss'],
})
export class ImportAccessPointsComponent implements OnInit, OnDestroy {
  public data: AccessPointImportRecord[];
  private arrayBuffer: ArrayBuffer;
  public validatingData: boolean;
  public canEdit = false;
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Facilities];

  public get isSaveButtonDisabled() {
    return (
      !this.canEdit ||
      this.loaderService.isLoading ||
      !this.data ||
      (this.data && this.data.length === 0) ||
      !this.permissionService.facilityId ||
      this.hasErrors
    );
  }

  public validationErrors: ErrorBlockMessage[] = [];

  public get hasErrors(): boolean {
    return this.validationErrors.length > 0;
  }

  private subs: Subscription = new Subscription();

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv', '.xlsx'],
  };

  constructor(
    private api: NetworkAccessPointApi,
    private enterpriseService: EnterpriseService,
    private translateService: TranslateService,
    public blobStorageService: BlobStorageService,
    private permissionService: PermissionService,
    public loaderService: LoaderService,
    public toasterService: ToasterService,
    private aiService: AppInsightsService
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.CAP_Manage_NetworkAccessPoints_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(editPerm);
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;

    this.canEdit = this.permissionService.has(editPerm);
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.resetAllDataInputs();
    setTimeout(() => {
      // this can lock the UI, so schedule it after we trigger the loading indicator
      this.data = undefined;
      this.validatingData = false;
    }, 100);
  }

  private resetAllDataInputs() {
    this.clearValidationErrors();
    this.validatingData = true;
  }

  public selectEventHandler(e: SelectEvent): void {
    this.resetAllDataInputs();

    e.files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file.rawFile);
      fileReader.onload = () => {
        this.arrayBuffer = fileReader.result as ArrayBuffer;
        const data = new Uint8Array(this.arrayBuffer);
        const arr = new Array();
        for (let i = 0; i < data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = read(bstr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const records = utils.sheet_to_json(worksheet, { raw: true });

        if (records.length > 0) {
          const firstRecord = records[0] as any;
          if (
            !firstRecord.hasOwnProperty(accessPointImportColumns.macAddress) ||
            !firstRecord.hasOwnProperty(accessPointImportColumns.Name)
          ) {
            this.validationErrors = [
              {
                titleTag: 'IMPORT_FILE_ERRORS',
                subtitle: this.translateService.instant(
                  'PLEASE_USE_TEMPLATE_FILE'
                ),
              },
            ];
            this.validatingData = false;
            this.data = [];
            return;
          }
        }
        this.data = [];
        const validData = this.processInputFile(records);

        this.validatingData = false;
        this.data = validData;
      };
    });
  }

  private processInputFile(records: unknown[]): AccessPointImportRecord[] {
    const validMacAddresses = new Set<string>();
    const validNames = new Set<string>();

    const invalidMacAddresses = new Set<string>();
    const duplicateMacAddresses = new Set<string>();
    const namesTooLong = new Set<string>();

    const validData: AccessPointImportRecord[] = [];

    for (const row of records) {
      // safely "cast" to a string on both field
      const macAddress = (
        row[accessPointImportColumns.macAddress] + ''
      ).toUpperCase();
      const name = row[accessPointImportColumns.Name] + '';

      if (!Constants.validMacAddressPattern.test(macAddress)) {
        invalidMacAddresses.add(macAddress);
      } else if (validMacAddresses.has(macAddress)) {
        // MAC already exists in uploaded file
        duplicateMacAddresses.add(macAddress);
      } else {
        // valid MAC address
        validMacAddresses.add(macAddress);
      }

      if (name.length > 250) {
        namesTooLong.add(name.substring(0, 50) + '...');
      } else {
        validNames.add(name);
      }

      validData.push({
        MacAddress: macAddress,
        Name: name,
      } as AccessPointImportRecord);
    }

    this.setValidationErrors(
      Array.from(invalidMacAddresses),
      Array.from(duplicateMacAddresses),
      Array.from(namesTooLong)
    );

    return validData;
  }

  public onGetTemplate() {
    this.aiService.btnClickTrace('ImportAccessPointsGetTemplate');
    const sub = this.blobStorageService
      .getUrl('Access_Point_Import_Template.xlsx')
      .subscribe(
        (url) => {
          window.open(url);
        },
        (error) => {
          this.toasterService.showError('GET_TEMPLATE_FAIL');
        }
      );
    this.subs.add(sub);
  }

  private clearValidationErrors() {
    this.validationErrors.length = 0;
  }

  public onImport() {
    if (!this.data) {
      return;
    }
    this.loaderService.start();

    const importRequest: NetworkApImportRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      FacilityId: this.permissionService.facilityId,
      OrganizationId: this.permissionService.orgId,
      NetworkAccessPoints: [],
    };
    this.clearValidationErrors();

    this.data.forEach((record) => {
      importRequest.NetworkAccessPoints.push({
        MacAddress: record.MacAddress,
        Name: record.Name,
      });
    });

    const sub = this.api
      .bulkImport(importRequest)
      .pipe(finalize(() => this.loaderService.stop()))
      .subscribe(
        (response) => {
          this.toasterService.showSuccess('IMPORT_SUCCESS');
        },
        (error) => {
          this.toasterService.showError('COM_UNKNOWN_API_ERROR');
        }
      );
    this.subs.add(sub);
  }

  private setValidationErrors(
    invalidMacAddresses: string[],
    duplicateMacAddresses: string[],
    namesExeedMaxLength: string[]
  ) {
    const errors: ErrorBlockMessage[] = [];

    if (invalidMacAddresses.length > 0) {
      errors.push({
        titleTag: 'MAC_ADDRESS_INVALID_FORMAT',
        subtitle: invalidMacAddresses.join(', '),
      });
    }

    if (duplicateMacAddresses.length > 0) {
      errors.push({
        titleTag: 'MAC_ADDRESS_DUPLICATE_DETECTED',
        subtitle: duplicateMacAddresses.join(', '),
      });
    }

    if (namesExeedMaxLength.length > 0) {
      errors.push({
        titleTag: 'NETWORK_ACCESS_POINT_NAMES_TOO_LONG',
        subtitle: namesExeedMaxLength.join(', '),
      });
    }

    this.validationErrors = errors;
  }
}
