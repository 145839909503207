import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageQueueResponse, MessageQueueUserType } from '@capsa/api';
import { MessageApi } from '@capsa/api/message';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-list-messages',
  templateUrl: './list-messages.component.html',
  styleUrls: ['./list-messages.component.scss'],
})
export class ListMessagesComponent implements OnInit, OnDestroy {
  @Input()
  private senderType: MessageQueueUserType;

  @Input()
  private recipientType: MessageQueueUserType;

  @Input()
  public hideTypeCol = false;

  public organizationId: number | undefined;
  public facilityId: number | undefined;

  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<MessageQueueResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;

  public selectedIds: number[] = [];
  public selected: MessageQueueResponse;

  private subs = new Subscription();
  private apiSearchSub = new Subscription();
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Messages];

  constructor(
    private api: MessageApi,
    private toasterService: ToasterService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  public onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public onFacilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public onMessageSelected(ev: SelectionEvent) {
    this.selected = ev.selectedRows[0].dataItem;
  }

  public onModalClose() {
    this.selected = null;
    this.selectedIds = [];
  }

  public onModalSaved() {
    this.selected = null;
    this.selectedIds = [];
    this.resetPagingAndData();
    this.loadGridData();
  }

  // Applies a class to each Grid row to aid overriding CSS
  public rowCallback(context: RowClassArgs) {
    return { gridRowOverride: true };
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  private loadGridData() {
    this.apiSearchSub.unsubscribe();

    if (!this.organizationId || !this.facilityId) {
      return;
    }

    this.gridDataLoading = true;
    this.apiSearchSub = this.api
      .searchMessages({
        FacilityIds: [this.facilityId],
        OrganizationIds: [this.organizationId],
        SenderType: this.senderType,
        RecipientType: this.recipientType,
        PageNumber: this.skip / this.pageSize + 1,
        PageSize: this.pageSize,
      })
      .pipe(finalize(() => (this.gridDataLoading = false)))
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }
        },
        (error) => {
          this.toasterService.showError('MESSAGES_LOAD_FAILED');
        }
      );
  }
}
