import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CareLinkLightType,
  LightColorThemeResponse,
  LightColorThemeUpdateRequest,
  LightConfigurationResponse,
} from '@capsa/api';
import { LightingApi } from '@capsa/api/lighting';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-lighting-led-color-themes',
  templateUrl: './lighting-led-color-themes.component.html',
  styleUrls: ['./lighting-led-color-themes.component.scss'],
})
export class LightingLedColorThemesComponent implements OnInit, OnDestroy {
  @Input()
  public facilityId: number;

  @Input()
  private themeNeedsRefresh$: Observable<void>;

  public themes: LightColorThemeResponse[];
  public selectedTheme: LightColorThemeResponse;

  public floorConfig: LightConfigurationResponse;
  public keyboardConfig: LightConfigurationResponse;
  public workSurfaceConfig: LightConfigurationResponse;

  private subs: Subscription = new Subscription();

  constructor(
    private lightingApi: LightingApi,
    public loaderService: LoaderService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (!this.themeNeedsRefresh$) {
      throw new Error('themeNeedsRefresh$ is required');
    }

    if (this.facilityId > 0) {
      this.refreshThemes();
    }

    this.subs.add(
      this.themeNeedsRefresh$.subscribe(() => {
        this.refreshThemes();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public saveTheme(request: LightColorThemeUpdateRequest) {
    this.loaderService.start();
    this.subs.add(
      this.lightingApi
        .updateLedTheme(this.facilityId, this.selectedTheme.Id, request)
        .subscribe(
          (resp) => {
            this.toasterService.showSuccess('LIGHTING_THEME_UPDATE_SUCCESS');
            this.refreshThemes();
          },
          (error) => {
            this.toasterService.showError('LIGHTING_THEME_UPDATE_FAIL');
            this.loaderService.stop();
          }
        )
    );
  }

  public onThemeChanged(selectedTheme: LightColorThemeResponse) {
    this.setLightConfigs(selectedTheme);
  }

  public refreshThemes() {
    if (!this.facilityId) {
      this.themes = undefined;
      return;
    }
    this.loaderService.start();

    this.subs.add(
      this.lightingApi.getLedThemes(this.facilityId).subscribe(
        (resp) => {
          if (!this.selectedTheme) {
            // on initial load, just select the default theme
            this.selectedTheme = resp.find((x) => x.IsDefaultTheme);
          } else {
            // re-select theme that was being saved
            this.selectedTheme = resp.find(
              (x) => x.Id === this.selectedTheme.Id
            );
          }

          this.setLightConfigs(this.selectedTheme);
          this.themes = resp;
          this.loaderService.stop();
        },
        (error) => {
          this.toasterService.showError('LIGHTING_THEME_GET_THEMES_FAIL');
          this.loaderService.stop();
        }
      )
    );
  }

  private setLightConfigs(selectedTheme: LightColorThemeResponse): void {
    this.floorConfig = Object.assign(
      {},
      selectedTheme.Configurations.find(
        (x) => x.LightType === CareLinkLightType.Ground
      )
    );

    this.keyboardConfig = Object.assign(
      {},
      selectedTheme.Configurations.find(
        (x) => x.LightType === CareLinkLightType.Keyboard
      )
    );

    this.workSurfaceConfig = Object.assign(
      {},
      selectedTheme.Configurations.find(
        (x) => x.LightType === CareLinkLightType.WorkSurface
      )
    );
  }
}
