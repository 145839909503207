import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  ImmutableNetworkAccessPointResponse,
  NetworkAccessPointCreateRequest,
  NetworkAccessPointResponse,
  NetworkAccessPointSearchRequest,
} from '@capsa/api';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogCloseResult,
  DialogRef,
  DialogResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process } from '@progress/kendo-data-query';
import { AppComponent } from 'app/app.component';
import { Record } from 'immutable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-access-point-manage',
  templateUrl: './access-point-manage.component.html',
  styleUrls: ['./access-point-manage.component.scss'],
})
export class AccessPointManageComponent implements OnInit, OnDestroy {
  public pageSize = 100;
  public apData: NetworkAccessPointResponse[] = [];
  public gridData: GridDataResult;

  public gridState: State = this.initialGridState;

  public loadingData = false;
  private idToDelete: number;

  /**
   * Keeps track of which row is currently in "edit/add" mode
   * We use it for determining which row to close in case the
   * user clicks "Add" to add a new row without saving/cancelling
   * an in progress row
   */
  private rowIndexEditing: number;

  private subs: Subscription = new Subscription();

  /**
   * Temporarily holds an unmodified "dataItem" while the actual dataItem is edited by user
   * If the user cancels, we can restore this clean object
   */
  private pristineNetworkAccessPoint: Record<NetworkAccessPointResponse>;

  constructor(
    private networkAccessPointApi: NetworkAccessPointApi,
    private enterpriseService: EnterpriseService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private appRef: ApplicationRef,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.refreshNetworkAccessPoints();

    this.subs.add(
      this.permissionService.facilityChanged$.subscribe(() => {
        // reset paging/filters
        this.resetGridState();
        this.refreshNetworkAccessPoints();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private refreshNetworkAccessPoints(): void {
    if (!this.permissionService.facilityId) {
      this.apData = [];
      this.reprocessGridState();
      return;
    }
    this.loadingData = true;

    const request: NetworkAccessPointSearchRequest = {
      OrganizationIds: [this.permissionService.orgId],
      FacilityIds: [this.permissionService.facilityId],
      EnterpriseId: this.enterpriseService.enterpriseId,
      MacAddresses: [],
      Names: [],
      PageNumber: 1,
      PageSize: 20000,
    };

    const sub = this.networkAccessPointApi.search(request).subscribe(
      (resp) => {
        this.apData = resp.Result;
        this.reprocessGridState();
        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
      }
    );

    this.subs.add(sub);
  }

  public addHandler({ sender }, formInstance) {
    formInstance.reset();
    this.closeEditor(sender);

    const newRow: NetworkAccessPointResponse = {
      NetworkAccessPointId: undefined,
      Name: '',
      MacAddress: '',
    };

    sender.addRow(newRow);
  }

  public isDuplicate(changeData: NetworkAccessPointResponse): boolean {
    const match = this.apData.find((x) => {
      return (
        x.MacAddress.toLowerCase() === changeData.MacAddress.toLowerCase() &&
        x.NetworkAccessPointId !== changeData.NetworkAccessPointId
      );
    });

    return !!match;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.rowIndexEditing = rowIndex;
    this.pristineNetworkAccessPoint =
      ImmutableNetworkAccessPointResponse(dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({
    sender,
    rowIndex,
    dataItem,
    isNew,
  }: {
    sender: any;
    rowIndex: number;
    dataItem: NetworkAccessPointResponse;
    isNew: boolean;
  }) {
    if (this.isDuplicate(dataItem)) {
      this.notificationService.show({
        content: this.translateService.instant('DUPLICATE_MAC_ADDRESS'),
        type: {
          style: 'error',
          icon: true,
        },
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
        closable: false,
        hideAfter: 3000,
      });
      return;
    }

    this.loadingData = true;
    if (isNew) {
      const createRequest: NetworkAccessPointCreateRequest = {
        Name: dataItem.Name,
        MacAddress: dataItem.MacAddress,
        EnterpriseId: this.enterpriseService.enterpriseId,
        OrganizationId: this.permissionService.orgId,
        FacilityId: this.permissionService.facilityId,
      };

      const sub = this.networkAccessPointApi.create(createRequest).subscribe(
        (resp) => {
          this.refreshNetworkAccessPoints();
        },
        (error) => {
          this.toasterService.showError('CREATE_FAILED');
          this.loadingData = false;
        }
      );

      this.subs.add(sub);
    } else {
      const sub = this.networkAccessPointApi
        .update(dataItem.NetworkAccessPointId, {
          Name: dataItem.Name,
          MacAddress: dataItem.MacAddress,
        })
        .subscribe(
          (resp) => {
            this.refreshNetworkAccessPoints();
          },
          (error) => {
            this.toasterService.showError('UPDATE_FAILED');
            this.loadingData = false;
          }
        );

      this.subs.add(sub);
    }

    sender.closeRow(rowIndex);
    this.rowIndexEditing = undefined;
    this.pristineNetworkAccessPoint = undefined;
  }

  public removeHandler({ dataItem }: { dataItem: NetworkAccessPointResponse }) {
    this.idToDelete = dataItem.NetworkAccessPointId;
    this.openDeleteConfirmDialog();
  }

  private restoreBackedUpItem() {
    if (!this.pristineNetworkAccessPoint) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.apData.find(
      (item) =>
        item.NetworkAccessPointId ===
        this.pristineNetworkAccessPoint.get('NetworkAccessPointId')
    );

    // revert changes
    Object.assign(originalDataItem, this.pristineNetworkAccessPoint.toObject());
  }

  private closeEditor(grid, rowIndex = this.rowIndexEditing) {
    grid.closeRow(rowIndex);

    this.restoreBackedUpItem();

    this.rowIndexEditing = undefined;
    this.pristineNetworkAccessPoint = undefined;
  }

  private openDeleteConfirmDialog() {
    const appComp = this.appRef.components[0]
      .instance as unknown as AppComponent;

    const dialog = this.dialogService.open({
      // Append the dialog to near the root DOM element, otherwise z-indexing issues will cause other elements to overlap this dialog
      appendTo: appComp.vcrRootAllContent,
      title: this.translateService.instant('DELETE_NETWORK_TITLE'),
      actions: [
        {
          text: this.translateService.instant('COM_NO'),
        },
        { text: this.translateService.instant('COM_YES') },
      ],
      content: this.translateService.instant('DELETE_NETWORK_DIALOG_BODY'),
    });

    this.subs.add(
      dialog.result.subscribe((result) => {
        this.dialogConfirmDelete(result, dialog);
      })
    );
  }

  public dialogConfirmDelete(result: DialogResult, dialogRef: DialogRef) {
    if (
      result instanceof DialogCloseResult ||
      result.text === this.translateService.instant('COM_NO')
    ) {
      dialogRef.close();
      this.idToDelete = undefined;
    } else {
      this.loadingData = true;
      dialogRef.close();

      this.loadingData = true;
      const sub = this.networkAccessPointApi.delete(this.idToDelete).subscribe(
        (resp) => {
          this.refreshNetworkAccessPoints();
        },
        (error) => {
          this.toasterService.showError('DELETE_FAILED');
          this.loadingData = false;
        }
      );
      this.subs.add(sub);
    }
  }

  public gridStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.gridData = process(this.apData, this.gridState);
  }

  private reprocessGridState() {
    this.gridData = process(this.apData, this.gridState);
  }

  private resetGridState() {
    this.gridState = this.initialGridState;
  }

  private get initialGridState(): State {
    return {
      skip: 0,
      take: this.pageSize,

      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
    };
  }
}
