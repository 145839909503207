import { Injectable } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class OrganizationUserDropDownDataSource extends AbstractDropDownListDataSource<NameWithLongIdApiModel> {
  get defaultItem(): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  constructor(private translations: TranslateService) {
    super();
  }
  public organizationId: number | undefined;
  public userOrgList: NameWithLongIdApiModel[];

  protected callApi(): Observable<NameWithLongIdApiModel[]> {
    return of(this.userOrgList);
  }
  protected map(
    apiModel: NameWithLongIdApiModel
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      id: apiModel.Id.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<NameWithLongIdApiModel>[]
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    return items.find((i) => i.apiModel.Id === this.organizationId);
  }
}
