import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  DeviceProfileCreateRequestApiModel,
  DeviceProfileSearchRequestApiModel,
  DeviceProfileSearchResponseApiModel,
  DeviceProfileUpdateRequestApiModel,
  GetDeviceProfileByIdResponse,
  throwIfBadRespFullResp,
} from '..';
import { BaseApi } from '../base/base.api';

export interface CartProfileApiInstance {
  gridSearch(
    search: DeviceProfileSearchRequestApiModel
  ): Observable<ApiResponse<DeviceProfileSearchResponseApiModel[]>>;

  create(
    toCreate: DeviceProfileCreateRequestApiModel
  ): Observable<ApiResponse<number>>;

  getById(
    deviceProfileId: number
  ): Observable<ApiResponse<GetDeviceProfileByIdResponse>>;
  update(
    deviceProfileId: number,
    toUpdate: DeviceProfileUpdateRequestApiModel
  ): Observable<ApiResponse<DeviceProfileSearchResponseApiModel>>;
}

@Injectable()
export class CartProfileApi extends BaseApi implements CartProfileApiInstance {
  protected endpoints = {
    create: this.buildUrl('device-profile'),
    search: this.buildUrl('device-profile/search'),
    getById: this.buildUrl('device-profile'),
    update: this.buildUrl('device-profile/{id}'),
  };

  public gridSearch(
    search: DeviceProfileSearchRequestApiModel
  ): Observable<ApiResponse<DeviceProfileSearchResponseApiModel[]>> {
    const url = `${this.endpoints.search}`;
    return this.http
      .post<ApiResponse<DeviceProfileSearchResponseApiModel[]>>(url, search)
      .pipe(throwIfBadRespFullResp());
  }

  public getById(
    deviceProfileId: number
  ): Observable<ApiResponse<GetDeviceProfileByIdResponse>> {
    const url = `${this.endpoints.getById}/${deviceProfileId}`;

    return this.http
      .get<ApiResponse<GetDeviceProfileByIdResponse>>(url)
      .pipe(throwIfBadRespFullResp());
  }

  public create(
    toCreate: DeviceProfileCreateRequestApiModel
  ): Observable<ApiResponse<number>> {
    return this.http
      .post<ApiResponse<number>>(this.endpoints.create, toCreate)
      .pipe(throwIfBadRespFullResp());
  }

  public update(
    deviceProfileId: number,
    toUpdate: DeviceProfileUpdateRequestApiModel
  ): Observable<ApiResponse<DeviceProfileSearchResponseApiModel>> {
    const url = this.endpoints.update.replace(
      '{id}',
      deviceProfileId.toString()
    );

    return this.http
      .put<ApiResponse<DeviceProfileSearchResponseApiModel>>(url, toUpdate)
      .pipe(throwIfBadRespFullResp());
  }
}
