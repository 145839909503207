import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, EmailRequestDto } from '..';
import { BaseApi } from '../base/base.api';

export interface EmailApiInstance {
  send(request: EmailRequestDto): Observable<ApiResponse<void>>;
}

@Injectable()
export class EmailApi extends BaseApi implements EmailApiInstance {
  protected endpoints = {
    sendGeneric: this.buildUrl('SendEmail/SendGenericEmail'),
  };

  public send(request: EmailRequestDto) {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.sendGeneric,
      request
    );
  }
}
