import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartGroupResponse, CartPartialResponse } from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { CartGroupApi } from '@capsa/api/cart-group';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

export interface CartWithGroups extends CartPartialResponse {
  Groups: string;
}

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
})
export class CartGroupEditPageComponent implements OnInit, OnDestroy {
  public loading = true;
  public saving = false;
  public cartGroup: CartGroupResponse;
  private allGroups: CartGroupResponse[];

  public allCarts: CartWithGroups[];

  public availableCarts: CartWithGroups[];
  public availData: GridDataResult;

  public groupCarts: CartWithGroups[];
  public groupData: GridDataResult;

  public cartsToAdd: number[] = [];
  public cartsToRemove: number[] = [];

  public availState: State = {
    skip: 0,
    take: 10000,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  };

  public groupState: State = {
    skip: 0,
    take: 10000,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  };

  private subs: Subscription = new Subscription();

  private assignedGridSelectionService: GridSelectionService;
  private availableGridSelectionService: GridSelectionService;

  constructor(
    private cartGroupApi: CartGroupApi,
    private cartApi: CartApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gridSelectionServiceFactory: GridSelectionServiceFactory,
    private toasterService: ToasterService
  ) {}

  private selectRowIds(rows: RowArgs[]) {
    return rows.map((row) => row.dataItem.Id);
  }

  ngOnInit() {
    this.assignedGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectRowIds
    );
    this.availableGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectRowIds
    );

    this.load();

    this.subs.add(
      this.availableGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.cartsToAdd = newSelection;
        }
      )
    );

    this.subs.add(
      this.assignedGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.cartsToRemove = newSelection;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get id(): number {
    const idString = this.activatedRoute.snapshot.paramMap.get('id');
    return Number(idString);
  }

  public onAvailableCartsSelectionChange(changes: SelectionEvent) {
    this.availableGridSelectionService.updateSelection(changes);
  }

  public onAssignedCartsSelectionChange(changes: SelectionEvent) {
    this.assignedGridSelectionService.updateSelection(changes);
  }

  public onBackClicked() {
    this.router.navigate(['carts', 'groups']);
  }

  public availStateChange(state: DataStateChangeEvent): void {
    this.availState = state;
    this.availData = process(this.availableCarts, this.availState);
  }

  public groupStateChange(state: DataStateChangeEvent): void {
    this.groupState = state;
    this.groupData = process(this.groupCarts, this.groupState);
  }

  public btnTriggerMoveSelectedToAssigned() {
    if (!this.cartsToAdd.length) {
      return;
    }

    this.cartsToAdd.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableCarts;
    const tempGroupCarts = this.groupCarts;

    this.availableCarts = [];
    this.groupCarts = [];

    do {
      const iAv = this.cartsToAdd.pop();
      const cartToMoveIdx = tempAvail.findIndex((x) => x.Id === iAv);
      const cartToMove = tempAvail.splice(cartToMoveIdx, 1)[0];
      tempGroupCarts.push(cartToMove);
    } while (this.cartsToAdd.length);

    tempGroupCarts.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = tempAvail;
    this.groupCarts = tempGroupCarts;
    this.availableGridSelectionService.clearSelection();
    this.reprocess();
  }

  public btnTriggerMoveAssignedToAvailable() {
    if (!this.cartsToRemove.length) {
      return;
    }

    this.cartsToRemove.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.availableCarts;
    const tempGroupCarts = this.groupCarts;

    this.availableCarts = [];
    this.groupCarts = [];

    do {
      const iAv = this.cartsToRemove.pop();
      const cartToMoveIdx = tempGroupCarts.findIndex((x) => x.Id === iAv);
      const cartToMove = tempGroupCarts.splice(cartToMoveIdx, 1)[0];
      tempAvail.push(cartToMove);
    } while (this.cartsToRemove.length);

    tempAvail.sort((a, b) => {
      return a.Id - b.Id;
    });

    this.availableCarts = tempAvail;
    this.groupCarts = tempGroupCarts;
    this.assignedGridSelectionService.clearSelection();
    this.reprocess();
  }

  public save() {
    this.saving = true;
    const cartIds: number[] = this.cartGroup.Members.map((x) => x.CartId);

    // Go through each cart the group currently has in their list
    // Check if any item in the group's list is now in the Available Carts list
    // If true, then the cart was moved from assigned --> available. This cart should be removed from the group's list
    for (let i = cartIds.length - 1; i >= 0; i--) {
      const match = this.availableCarts.find((x) => x.Id === cartIds[i]);
      if (match) {
        cartIds.splice(i, 1);
      }
    }

    // add any cart ids to group that are in the assigned list BUT not yet in group's list...
    this.groupCarts.forEach((x) => {
      const match = cartIds.find((y) => y === x.Id);
      if (!match) {
        cartIds.push(x.Id);
      }
    });

    const sub = this.cartGroupApi
      .updateMembers({
        CartGroupId: this.cartGroup.CartGroupId,
        CartIds: cartIds,
      })
      .subscribe(
        (resp) => {
          this.toasterService.showSuccess('CART_GROUP_UPDATE_SUCCESS');
          this.assignedGridSelectionService.clearSelection();
          this.availableGridSelectionService.clearSelection();
          this.load();
          this.saving = false;
        },
        (error) => {
          this.toasterService.showError('COM_UNKNOWN_API_ERROR');
          this.saving = false;
        }
      );
    this.subs.add(sub);
  }

  private load() {
    this.loading = true;
    const sub = this.cartGroupApi.getById(this.id).subscribe((resp) => {
      this.cartGroup = resp.Result;
      this.loadGroups();
    });
    this.subs.add(sub);
  }

  private loadCarts() {
    const sub = this.cartApi
      .getOrgFacilityCarts(
        {
          PageNumber: 1,
          PageSize: 1000,
        },
        this.cartGroup.OrganizationId,
        this.cartGroup.FacilityId
      )
      .subscribe((resp) => {
        this.allCarts = resp.Result.map((x) => {
          return {
            Groups: '',
            SerialNumber: x.SerialNumber,
            Name: x.Name,
            Id: x.Id,
          };
        });
        this.allGroups.forEach((cg) => {
          cg.Members.forEach((cart) => {
            const found = this.allCarts.find((x) => x.Id === cart.CartId);
            if (found) {
              if (found.Groups.length > 0) {
                found.Groups = found.Groups + ', ' + cg.Description;
              } else {
                found.Groups = cg.Description;
              }
            }
          });
        });

        this.cartsToAdd = [];
        this.cartsToRemove = [];
        this.groupCarts = [];
        this.availableCarts = [];
        this.allCarts.forEach((cart) => {
          const found = this.cartGroup.Members.find(
            (c) => c.CartId === cart.Id
          );
          if (found) {
            this.groupCarts.push(cart);
          } else {
            this.availableCarts.push(cart);
          }
        });
        this.reprocess();
        this.loading = false;
      });

    this.subs.add(sub);
  }

  private loadGroups() {
    const sub = this.cartGroupApi
      .search({
        EnterpriseId: this.cartGroup.EnterpriseId,
        OrganizationIds: [this.cartGroup.OrganizationId],
        FacilityIds: [this.cartGroup.FacilityId],
        PageNumber: 1,
        PageSize: 1000,
        CartGroupIds: [],
      })
      .subscribe(
        (resp) => {
          this.allGroups = resp.Result;
          this.loadCarts();
        },
        (error) => {
          this.toasterService.showError('COM_GET_LIST_FAILED');
        }
      );

    this.subs.add(sub);
  }

  private reprocess() {
    this.availData = process(this.availableCarts, this.availState);
    this.groupData = process(this.groupCarts, this.groupState);
  }
}
