import { AvaloCartManagementSoundsTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/avalo-cart-management-tabs/avalo-cart-management-sounds-tab/avalo-cart-management-sounds-tab.component';
import { SharedCartManagementUsersTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/shared-cart-management-tabs/shared-cart-management-users-tab.component';
import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { AvaloCartManagementGeneralTabComponent } from './avalo-cart-management-general-tab/avalo-cart-management-general-tab.component';

export const AvaloCartManagementTabs: DynamicTab[] = [
  {
    titleTag: 'CART_DETAILS',
    component: AvaloCartManagementGeneralTabComponent,
  },
  {
    titleTag: 'CART_SOUNDS',
    component: AvaloCartManagementSoundsTabComponent,
  },
  {
    titleTag: 'CART_USERS',
    component: SharedCartManagementUsersTabComponent,
  },
];
