import { Component } from '@angular/core';
import { CapsaSettingsTypeIds, DeviceTypeIds } from '@capsa/api';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';

@Component({
  selector: 'app-avalo-user-settings',
  templateUrl: './avalo-user-settings.component.html',
  styleUrls: ['./avalo-user-settings.component.scss'],
})
export class AvaloUserSettingsComponent {
  public get isActive() {
    return this.editUserService.getSettingByDeviceType(
      DeviceTypeIds.Avalo,
      CapsaSettingsTypeIds.CLI_IsCartUserActive
    );
  }

  public get pinCode() {
    return this.editUserService.getSettingByDeviceType(
      DeviceTypeIds.Avalo,
      CapsaSettingsTypeIds.CLI_UserPinCode
    );
  }

  public get secondaryPinCode() {
    return this.editUserService.getSettingByDeviceType(
      DeviceTypeIds.Avalo,
      CapsaSettingsTypeIds.AVO_SecondaryPinCode
    );
  }

  public get narcOne() {
    return this.editUserService.getSettingByDeviceType(
      DeviceTypeIds.Avalo,
      CapsaSettingsTypeIds.AVO_NarcOneDrawerAccess
    );
  }

  public get narcTwo() {
    return this.editUserService.getSettingByDeviceType(
      DeviceTypeIds.Avalo,
      CapsaSettingsTypeIds.AVO_NarcTwoDrawerAccess
    );
  }

  constructor(private editUserService: EditUserService) {}
}
