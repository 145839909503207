import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { FacilityDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-drop-down-data-source';

@Component({
  selector: 'app-facility-drop-down',
  templateUrl: './facility-drop-down.component.html',
  styleUrls: ['./facility-drop-down.component.scss'],
})
export class FacilityDropDownComponent {
  @Input()
  readonly: boolean;

  @Input()
  public readonlyText: string;

  @Input()
  public disabled = false;

  @Output()
  valueChanged = new EventEmitter<AsyncDropDownValue<NameWithLongIdApiModel>>();

  @Input()
  public dataSource: FacilityDropDownDataSource;
}
