import { Component, Input } from '@angular/core';
import { SetPasswordForm } from '../set-password.component';

@Component({
  selector: 'app-set-password-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
})
export class RequirementsComponent {
  @Input()
  public form: SetPasswordForm;

  get newPassword() {
    return this.form.controls.newPassword;
  }

  constructor() {}
}
