// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridErrorMsg {
  margin-left: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/facility/facility-manage-battery/battery-warnings-trio/battery-warnings-trio.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".gridErrorMsg {\n  margin-left: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
