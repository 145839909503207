import { Component, Input } from '@angular/core';

export interface ErrorBlockMessage {
  titleTag: string;
  subtitle?: string;
}

@Component({
  selector: 'app-error-block',
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss'],
})
export class ErrorBlockComponent {
  @Input()
  public headerTag: string;

  @Input()
  public errors: ErrorBlockMessage[];

  public get hasErrors(): boolean {
    return (this.errors || []).length > 0;
  }

  constructor() {}
}
