import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UserPartialResponse } from '@capsa/api';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';
import { Utils } from 'app/common/utils';
import { GridUserSelectionState } from 'app/modules/capsa-dialogs/capsa-dialogs-interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-selection-dialog',
  templateUrl: './user-selection-dialog.component.html',
  styleUrls: ['./user-selection-dialog.component.scss'],
})
export class UserSelectionDialogComponent implements OnInit, OnDestroy {
  // Adds a class to the "host" HTML wrapper of this component
  @HostBinding('class') class = 'responsiveKendoDialog';

  @Input()
  public usersAvailable: UserPartialResponse[] = [];

  @Input()
  public usersSelected: UserPartialResponse[] = [];

  @Output()
  public closed = new EventEmitter<GridUserSelectionState>();

  public icons = { xIcon: xIcon };

  private originalSelections: GridUserSelectionState;

  public availableKeysSelected: string[] = [];
  public selectedKeysSelected: string[] = [];

  public readonly pageSize = 250;

  public availableUserData: GridDataResult;
  public selectedUserData: GridDataResult;

  public availableState: State = Utils.getDefaultState(this.pageSize);

  public selectedState: State = Utils.getDefaultState(this.pageSize);

  private availableGridSelectionService: GridSelectionService;
  private selectedGridSelectionService: GridSelectionService;

  private subs = new Subscription();

  constructor(
    private gridSelectionServiceFactory: GridSelectionServiceFactory
  ) {}

  private selectUserRowIds(rows: RowArgs[]) {
    return rows.map((row) => {
      const sel = row.dataItem as UserPartialResponse;
      return sel.Id;
    });
  }

  public ngOnInit() {
    this.originalSelections = {
      avail: [...this.usersAvailable],
      selected: [...this.usersSelected],
    };

    this.availableGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectUserRowIds
    );

    this.selectedGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectUserRowIds
    );

    this.reprocess();

    this.subs.add(
      this.availableGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.availableKeysSelected = newSelection;
        }
      )
    );

    this.subs.add(
      this.selectedGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedKeysSelected = newSelection;
        }
      )
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public saveSelection() {
    const selections: GridUserSelectionState = {
      avail: [...this.usersAvailable],
      selected: [...this.usersSelected],
    };

    this.closed.emit(selections);
  }

  public onClose() {
    this.closed.emit(this.originalSelections);
  }

  private reprocess() {
    this.availableUserData = process(this.usersAvailable, this.availableState);
    this.selectedUserData = process(this.usersSelected, this.selectedState);
  }

  public availableStateChange(state: DataStateChangeEvent): void {
    this.availableState = state;
    this.availableUserData = process(this.usersAvailable, this.availableState);
  }

  public selectedStateChange(state: DataStateChangeEvent): void {
    this.selectedState = state;
    this.selectedUserData = process(this.usersSelected, this.selectedState);
  }

  public onAvailableSelectionChanged(change: SelectionEvent) {
    this.availableGridSelectionService.updateSelection(change);
  }

  public onSelectedSelectionChanged(change: SelectionEvent) {
    this.selectedGridSelectionService.updateSelection(change);
  }

  public btnTriggerMoveAllToSelected() {
    if (!this.usersAvailable.length) {
      return;
    }

    const tempSelected = [...this.usersSelected, ...this.usersAvailable];

    tempSelected.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });

    this.usersAvailable.length = 0;
    this.usersSelected = tempSelected;
    this.availableGridSelectionService.clearSelection();
    this.selectedGridSelectionService.clearSelection();

    this.availableState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }

  public btnTriggerMoveAvailableToSelected() {
    if (!this.availableKeysSelected.length) {
      return;
    }

    this.availableKeysSelected.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    const tempAvail = this.usersAvailable;
    const tempSelectedCarts = this.usersSelected;

    this.usersAvailable = [];
    this.usersSelected = [];

    do {
      const iAv = this.availableKeysSelected.pop();
      const userToMoveIdx = tempAvail.findIndex((x) => x.Id === iAv);
      const userToMove = tempAvail.splice(userToMoveIdx, 1)[0];
      tempSelectedCarts.push(userToMove);
    } while (this.availableKeysSelected.length);

    tempSelectedCarts.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });

    this.usersAvailable = tempAvail;
    this.usersSelected = tempSelectedCarts;
    this.availableGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.usersAvailable.length <= this.availableState.skip) {
      this.availableState.skip = this.availableState.skip - this.pageSize;
    }
    this.reprocess();
  }

  public btnTriggerMoveSelectedToAvailable() {
    if (!this.selectedKeysSelected.length) {
      return;
    }

    this.selectedKeysSelected.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    const tempAvail = this.usersAvailable;
    const tempSelectedCarts = this.usersSelected;

    this.usersAvailable = [];
    this.usersSelected = [];

    do {
      const iAv = this.selectedKeysSelected.pop();
      const userToMoveIdx = tempSelectedCarts.findIndex((x) => x.Id === iAv);
      const userToMove = tempSelectedCarts.splice(userToMoveIdx, 1)[0];
      tempAvail.push(userToMove);
    } while (this.selectedKeysSelected.length);

    tempAvail.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });

    this.usersAvailable = tempAvail;
    this.usersSelected = tempSelectedCarts;
    this.selectedGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.usersSelected.length <= this.selectedState.skip) {
      this.selectedState.skip = this.selectedState.skip - this.pageSize;
    }

    this.reprocess();
  }

  public btnTriggerMoveAllToAvailable() {
    if (!this.usersSelected.length) {
      return;
    }

    const tempAvailable = [...this.usersAvailable, ...this.usersSelected];

    tempAvailable.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });

    this.usersAvailable = tempAvailable;
    this.usersSelected.length = 0;
    this.selectedGridSelectionService.clearSelection();
    this.availableGridSelectionService.clearSelection();
    this.selectedState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }
}
