import { Component, Input, OnInit } from '@angular/core';
import {
  CapsaSettingApiModel,
  CareLinkLightColorNotificationLightOnly,
  DataDrivenListItem,
} from '@capsa/api';
@Component({
  selector: 'app-data-driven-color-drop-down',
  templateUrl: './data-driven-color-drop-down.component.html',
  styleUrls: ['./data-driven-color-drop-down.component.scss'],
})
export class DataDrivenColorDropDownComponent implements OnInit {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public disabled = false;

  public colorListItems: DataDrivenListItem[] = [];

  public get value() {
    return Number.parseInt(this.setting.SettingValue, 10);
  }

  constructor() {}

  public ngOnInit() {
    this.fillColorsList();
  }

  public onValueChanged(value: number) {
    const oldValueWasNone = this.setting.SettingValue === '0';
    this.setting.SettingValue = value.toString();

    if (oldValueWasNone && value !== 0) {
      this.colorListItems = [];
      this.fillColorsList();
    }
  }

  private fillColorsList(): void {
    for (const enumMember in CareLinkLightColorNotificationLightOnly) {
      if (isNaN(enumMember as any)) {
        const ucName = enumMember.toUpperCase();

        // NS-648 - if the current value isn't already "None", then don't
        // add it as an option, we're trying to get away from this confusing "color"
        if (ucName === 'NONE' && this.setting.SettingValue !== '0') {
          continue;
        }

        this.colorListItems.push({
          Name: ucName,
          NameTranslationTag: `ENUM_CARELINKLIGHTCOLOR_${ucName}`,
          Value: CareLinkLightColorNotificationLightOnly[
            enumMember
          ] as unknown as number,
          IsVisible: true,
        });
      }
    }
  }
}
