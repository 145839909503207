import { Injectable } from '@angular/core';
import {
  ApiResponse,
  MessageTemplateCreateRequest,
  MessageTemplateResponse,
  MessageTemplateSearchRequest,
  MessageTemplateUpdateRequest,
} from '@capsa/api';
import { BaseApi } from '@capsa/api/base/base.api';
import { Observable } from 'rxjs';

export interface MessageTemplateApiInstance {
  createTemplate(
    request: MessageTemplateCreateRequest
  ): Observable<ApiResponse<MessageTemplateResponse>>;

  searchTemplate(
    request: MessageTemplateSearchRequest
  ): Observable<ApiResponse<MessageTemplateResponse[]>>;

  deleteTemplate(id: number): Observable<ApiResponse<any>>;

  updateTemplate(
    id: number,
    request: MessageTemplateUpdateRequest
  ): Observable<ApiResponse<MessageTemplateResponse>>;
}

@Injectable()
export class MessageTemplateApi
  extends BaseApi
  implements MessageTemplateApiInstance
{
  protected endpoints = {
    postMessageTemplate: this.buildUrl('message-queue-template'),
    searchMessageTemplate: this.buildUrl('message-queue-template/search'),
    deleteMessageTemplate: this.buildUrl('message-queue-template/'),
    updateMessageTemplate: this.buildUrl('message-queue-template/'),
  };

  createTemplate(
    request: MessageTemplateCreateRequest
  ): Observable<ApiResponse<MessageTemplateResponse>> {
    return this.http.post<ApiResponse<MessageTemplateResponse>>(
      this.endpoints.postMessageTemplate,
      request
    );
  }

  searchTemplate(
    request: MessageTemplateSearchRequest
  ): Observable<ApiResponse<MessageTemplateResponse[]>> {
    return this.http.post<ApiResponse<MessageTemplateResponse[]>>(
      this.endpoints.searchMessageTemplate,
      request
    );
  }

  deleteTemplate(id: number): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(
      this.endpoints.deleteMessageTemplate + id
    );
  }

  updateTemplate(
    id: number,
    request: MessageTemplateUpdateRequest
  ): Observable<ApiResponse<MessageTemplateResponse>> {
    return this.http.put<ApiResponse<MessageTemplateResponse>>(
      this.endpoints.updateMessageTemplate + id,
      request
    );
  }
}
