import { Injectable, OnDestroy } from '@angular/core';
import {
  CapsaRoleResponseDto,
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  DeviceTypeIds,
  ImmutableUserResponse,
  MessageQueueUserRequestTypesUpdateRequest,
  NameWithLongIdApiModel,
  SettingUpdateRequest,
  UserResponse,
  UserUpdateRequest,
} from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Constants } from 'app/common/constants';
import { UserHelpers } from 'app/modules/users/user-helpers';
import { generateImmutableUpdateUserRequest } from 'app/modules/users/user-management/helpers';
import { Map as ImmutableMap, Record } from 'immutable';
import { BehaviorSubject, Subject, Subscription, forkJoin } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

@Injectable()
/**
 * Helps manage state/updates/saves and validation on the Edit User page (between all the components)
 */
export class EditUserService implements OnDestroy {
  // Immutable original user DTO
  private _pristineUser: Record<UserResponse>;
  public get pristineUser() {
    return !this._pristineUser ? undefined : this._pristineUser.asImmutable();
  }

  // Pending save object that will be sent to the API
  private _updatedUser: Record<UserUpdateRequest>;

  public get updatedUser() {
    return !this._updatedUser ? undefined : this._updatedUser.asImmutable();
  }

  public get userId() {
    return this.pristineUser.get('Id');
  }

  /**
   * Key is facilityId
   * Value is list of request type ID's
   */
  private _pendingAssignedRequestTypes = new Map<number, number[]>();

  private loadingUserBehSubj = new BehaviorSubject<boolean>(false);

  /**
   * Loading user from API
   */
  public loadingUser$ = this.loadingUserBehSubj.asObservable();

  private savingUserBehSubj = new BehaviorSubject<boolean>(false);

  /**
   * API call to save user is in progress
   */
  public savingUser$ = this.savingUserBehSubj.asObservable();

  private userLoadedSubj = new Subject<void>();

  private processingUserManageActionBehSubj = new BehaviorSubject<boolean>(
    false
  );

  /**
   * API call to resend registration, e-mail confirmation, password reset or unlock user is in process
   */
  public processingUserManageAction$ =
    this.processingUserManageActionBehSubj.asObservable();

  private registrationSentBehSubj = new BehaviorSubject<boolean>(false);
  /**
   * A registration e-mail was sent during THIS page load to the currently loaded user
   */
  public registrationSent$ = this.registrationSentBehSubj.asObservable();

  private emailValidationSentBehSubj = new BehaviorSubject<boolean>(false);
  /**
   * An e-mail validation/confirmation e-mail was sent during THIS page load to the currently loaded user
   */
  public emailValidationSent$ = this.emailValidationSentBehSubj.asObservable();

  private passwordResetSentBehSubj = new BehaviorSubject<boolean>(false);
  /**
   * A password reset e-mail was sent during THIS page load to the currently loaded user
   */
  public passwordResetSent$ = this.passwordResetSentBehSubj.asObservable();

  private userUnlockSuccessBehSubj = new BehaviorSubject<boolean>(false);
  /**
   * Loaded user was successfully unlocked
   */
  public userUnlockSuccess$ = this.userUnlockSuccessBehSubj.asObservable();

  /**
   * fresh user data came in from API
   */
  public userLoaded$ = this.userLoadedSubj.asObservable();

  private facAssignmentsChangedSubj = new Subject<void>();

  /**
   * Emits every time a users "facility" assignments have changed
   */
  public facAssignmentsChanged$ = this.facAssignmentsChangedSubj.asObservable();

  private metadataValidSubj = new Subject<boolean>();

  /**
   * Emits the latest "form valid" status of the "metadata" part of the user (e.g. name, email, username)
   */
  public metadataValid$ = this.metadataValidSubj.asObservable();

  private areCliRolesInEveryFacilitySubj = new Subject<boolean>();

  /**
   * Emits the latest "CLI role in every assigned facility" status
   */
  public areCliRolesInEveryFacility$ =
    this.areCliRolesInEveryFacilitySubj.asObservable();

  private userRolesChangedSubj = new Subject<void>();

  /**
   * Emits the latest user roles list (e.g. email needs to be marked required/not depending on
   * whether the roles contain an N-Sight role or not)
   */
  public userRolesChanged$ = this.userRolesChangedSubj.asObservable();

  private selectedFacilityChangedBehSubj = new BehaviorSubject<number>(-1);

  /**
   * Emits the "Facility ID" for the facility that's been selected to edit a
   * user's roles, cart settings, cart assignemnts etc.
   */
  public selectedFacilityChanged$ =
    this.selectedFacilityChangedBehSubj.asObservable();

  private hasDeviceAccessRoleThisFacilityBehSubj = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Emits "true" if user has a role with device access for the currently selected facility
   */
  public hasDeviceAccessRoleThisFacility$ =
    this.hasDeviceAccessRoleThisFacilityBehSubj.asObservable();

  private hasNsightAccessRoleThisFacilityBehSubj = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Emits "true" if user has a role with N-Sight access for the currently selected facility
   */
  public hasNsightAccessRoleThisFacility$ =
    this.hasNsightAccessRoleThisFacilityBehSubj.asObservable();

  private pristineEnterpriseFacs: ImmutableMap<number, NameWithLongIdApiModel>;

  /**
   * What facility (if any is currently selected in the lower drop downs for setting a users facility settings)
   */
  public get selectedFacilityId() {
    return this.selectedFacilityChangedBehSubj.getValue();
  }

  /**
   * Returns true if the user has cart access in ANY facility
   */
  public get userHasDeviceAccessAnyFacility(): boolean {
    return UserHelpers.roleHasDeviceAccessPermission(
      this.allCliRoles,
      this.updatedUser.get('RoleIds')
    );
  }

  /**
   * Returns true if the user has NSight access to ANY facility
   */
  public get userHasNsightAccessAnyFacility(): boolean {
    return UserHelpers.roleHasNsightPermission(
      this.allCliRoles,
      this.updatedUser.get('RoleIds')
    );
  }

  public get pristineHasNsightRole(): boolean {
    return UserHelpers.roleHasNsightPermission(
      this.allCliRoles,
      this.pristineUser.get('UserRoleList').map((role) => role.Id)
    );
  }

  public get canSave() {
    // is form valid?
    return true;
  }

  private servicesReadyBehSub = new BehaviorSubject<boolean>(false);
  private servicesReady$ = this.servicesReadyBehSub.asObservable();

  /**
   * First Key is facilityId
   */
  private allSettings: Map<
    number,
    Map<DeviceTypeIds, Map<CapsaSettingsTypeIds, CapsaSettingApiModel>>
  > = new Map();

  private allCliRoles: CapsaRoleResponseDto[];
  private deviceUserRoles: CapsaRoleResponseDto[] = [];

  private subs = new Subscription();

  constructor(
    private userApi: UserApi,
    private capsaRoleService: CapsaRoleService,
    private toasterService: ToasterService,
    private eofCacheService: EofCacheService,
    private enterpriseService: EnterpriseService,
    public loaderService: LoaderService
  ) {
    this.subs.add(
      forkJoin([
        this.capsaRoleService.rolesList$.pipe(take(1)),
        this.eofCacheService.initialized$.pipe(
          filter((x) => x !== null),
          take(1)
        ),
      ]).subscribe(
        (results) => {
          this.allCliRoles = results[0];
          this.pristineEnterpriseFacs = ImmutableMap(
            results[1].facilities.map((item, index) => [item.Id, item])
          );

          this.servicesReadyBehSub.next(true);
        },
        (error) => {
          this.toasterService.showError('UNKNOWN_ERROR');
        }
      )
    );

    this.subs.add(
      this.processingUserManageAction$.subscribe((isProcessing) => {
        const taskId = 'processingUserManageAction';
        isProcessing
          ? this.loaderService.start(taskId)
          : this.loaderService.stop(taskId);
      })
    );
  }

  /**
   * To be called by same component that provides this service, since
   * this service is "Component" level
   */
  public ngOnDestroy(): void {
    this.allSettings.clear();
    this.subs.unsubscribe();
  }

  /**
   * Attempts to retrieve cached users facility settings for the currently selected
   * facility and for the given device type
   * If null is returned, then an API call hasn't been made yet to retrieve them
   */
  public getUserFacilityDeviceSettings(deviceTypeId: DeviceTypeIds) {
    const facilitySettings = this.allSettings.get(this.selectedFacilityId);
    if (!facilitySettings) {
      return undefined;
    }

    const deviceTypeSettings = facilitySettings.get(deviceTypeId);
    if (!deviceTypeSettings) {
      return undefined;
    }

    return deviceTypeSettings;
  }

  public getSettingByDeviceType(
    deviceTypeId: DeviceTypeIds,
    settingType: CapsaSettingsTypeIds
  ) {
    const settings = this.getUserFacilityDeviceSettings(deviceTypeId);

    if (!settings) {
      return undefined;
    }

    return settings.get(settingType);
  }

  public importSingleDeviceTypeFacilitySettingsFromApi(
    facilitySettingsToImport: CapsaSettingApiModel[]
  ) {
    const deviceTypeId = facilitySettingsToImport[0].DeviceTypeId;

    if (!this.allSettings.get(this.selectedFacilityId)) {
      this.allSettings.set(this.selectedFacilityId, new Map());
    }

    const curFacilityAllSettings = this.allSettings.get(
      this.selectedFacilityId
    );
    if (!curFacilityAllSettings.get(deviceTypeId)) {
      curFacilityAllSettings.set(deviceTypeId, new Map());
    }

    const curFacDeviceSettings = curFacilityAllSettings.get(deviceTypeId);

    facilitySettingsToImport.forEach((settingToImport) => {
      if (settingToImport.DeviceTypeId !== deviceTypeId) {
        throw new Error(
          'Attempting to import setting from different device type than map key'
        );
      }

      curFacDeviceSettings.set(settingToImport.SettingType, settingToImport);
    });
  }

  public loadUserFromApi(userId: string) {
    this.allSettings.clear();
    this.resetUserStatusSuccessMsgs();
    this.loadingUserBehSubj.next(true);
    this.loaderService.start();
    this.subs.add(
      forkJoin([
        // ensure "essential services" are ready to be used, before filling in API data
        // we use forkJoin instead of "switchMap" in order to allow BOTH observables to "execute"
        // at the same time, rather than waiting for services to be ready, and THEN firing off
        // an API call to get user details
        this.servicesReady$.pipe(
          filter((isReady) => isReady),
          take(1)
        ),
        this.capsaRoleService.rolesListReady$.pipe(
          filter((isReady) => isReady),
          take(1)
        ),
        this.userApi.getUserDetails(userId, true),
      ]).subscribe(
        (combinedResp) => {
          const userDetailsResp = combinedResp[2];
          this._pendingAssignedRequestTypes = new Map<number, number[]>();
          this._pristineUser = ImmutableUserResponse(userDetailsResp.Result);
          this._updatedUser = generateImmutableUpdateUserRequest(
            userDetailsResp.Result
          );
          this.autoFixOrgAssignments();

          this.refreshUserNsightDeviceAccessStatus();
          this.refreshRoleInEveryFacilityStatus();
          this.userLoadedSubj.next();
          this.loadingUserBehSubj.next(false);
          this.loaderService.stopAll();
        },
        (error) => {
          this.toasterService.showError('GET_USER_DETAILS_FAIL');
          this.loadingUserBehSubj.next(false);
          this.loaderService.stopAll();
        }
      )
    );
  }

  private refreshRoleInEveryFacilityStatus() {
    const userRoleIds = this.updatedUser.get('RoleIds');

    // Temp storing a list of users FULL roles (since they tell us the facility they are part of)
    const usersFullRoles = this.capsaRoleService.unsafeRolesList.filter(
      (role) =>
        userRoleIds.some((uRoleId) => uRoleId === role.CapsaRoleId) &&
        !Constants.hiddenRoleNames.some((hRoleName) => hRoleName === role.Name)
    );

    const someFacilityHasMissingCliRoles = this.updatedUser
      .get('FacilityIds')
      .some((fId) => !usersFullRoles.some((uRole) => uRole.FacilityId === fId));
    this.areCliRolesInEveryFacilitySubj.next(!someFacilityHasMissingCliRoles);
  }

  /**
   * Ensures we prevent assigning/keeping roles the user should not have anymore
   * (i.e. the user is no longer assigned a certain facility, so remove all
   * roles that belong to that facility)
   */
  private removeGhostFacilityRoles() {
    const assignedFacilities = this.updatedUser.get('FacilityIds');
    const allUsersRoleIds = this.updatedUser.get('RoleIds');

    const allUsersFullRoles = this.capsaRoleService.unsafeRolesList.filter(
      (x) => allUsersRoleIds.some((y) => y === x.CapsaRoleId)
    );

    // Ensure "non facility specific" roles are untouched
    const roleIdsNonFacility = allUsersFullRoles
      .filter((x) => !x.FacilityId)
      .map((x) => x.CapsaRoleId);

    const cleanFacilityRoleIds = allUsersFullRoles
      .filter((x) => assignedFacilities.some((y) => y === x.FacilityId))
      .map((x) => x.CapsaRoleId);

    this.updateUserRoles([...cleanFacilityRoleIds, ...roleIdsNonFacility]);
  }

  private removeGhostServiceRequestTypesBeforeSave() {
    const assignedFacilities = this.updatedUser.get('FacilityIds');

    // Go through PRISTINE user's facility list, and explicitly remove request types for facilities that are being removed
    this.pristineUser.get('FacilityList').forEach((fac) => {
      if (
        !assignedFacilities.some((assignedFacId) => assignedFacId === fac.Id)
      ) {
        // Facility assignment was removed, ensure we clear any service requests for this user
        this._pendingAssignedRequestTypes.set(fac.Id, []);
      }
    });
  }

  public saveUser() {
    this.savingUserBehSubj.next(true);
    const taskId = 'saveUser';
    this.loaderService.start(taskId);

    // TODO: ensure this is working correctly
    this.removeGhostServiceRequestTypesBeforeSave();

    this.flattenAndSaveSettingsToUser();

    const request = this.updatedUser.toObject();

    request.GenerateMissingPinCodes =
      request.RoleIds.some((roleId) =>
        this.deviceUserRoles.find((r) => r.CapsaRoleId === roleId)
      ) &&
      !this.pristineUser
        .get('UserRoleList')
        .some((role) =>
          this.deviceUserRoles.find((r) => r.CapsaRoleId === role.Id)
        );

    this.subs.add(
      this.userApi.updateUser(request).subscribe(
        (resp) => {
          this.toasterService.showSuccess('UPDATE_SUCCESS');
          this.savingUserBehSubj.next(false);
          this.loadUserFromApi(request.Id);
        },
        (error) => {
          this.savingUserBehSubj.next(false);
          this.loaderService.stop(taskId);
          this.toasterService.showError('USER_DETAILS_UPDATE_FAIL');
        }
      )
    );
  }

  private flattenAndSaveSettingsToUser() {
    const upsertSettings: SettingUpdateRequest[] = [];
    this.allSettings.forEach((facilitySettings) => {
      facilitySettings.forEach((deviceTypeSettings) => {
        deviceTypeSettings.forEach((apiModel) => {
          if (
            apiModel.SettingType ===
            CapsaSettingsTypeIds.CLI_SecondaryDrawerAccessEnabled
          ) {
            const drawerAccessSettingVal = this.getDrawerAccessSettingVal(
              apiModel.FacilityId,
              apiModel.DeviceTypeId
            );
            if (drawerAccessSettingVal !== undefined) {
              // NS-485
              // Whatever access (true/false) the user has for the main drawer
              // assign that for the secondary drawer
              apiModel.SettingValue = drawerAccessSettingVal;
            }
          }

          const isThisPinSetting = Constants.pinSettingTypes.some(
            (pinSetting) => pinSetting === apiModel.SettingType
          );

          // Prevent API error, don't submit PIN settings with NULL value
          if (!(isThisPinSetting && apiModel.SettingValue === null)) {
            upsertSettings.push(this.settingApiModelToUpdateRequest(apiModel));
          }
        });
      });
    });

    this.updateUser({ UpsertSettings: upsertSettings });
  }

  /**
   * @returns Latest setting value for "CareLink_DrawerAccessEnable" in the given
   * facility/deviceType, or undefined if it could not be found
   */
  private getDrawerAccessSettingVal(
    facilityId: number,
    deviceType: DeviceTypeIds
  ): string | undefined {
    const fSettings = this.allSettings.get(facilityId);

    if (!fSettings) {
      return undefined;
    }

    const deviceSettings = fSettings.get(deviceType);

    if (!deviceSettings) {
      return undefined;
    }

    const drawerAccessSetting = deviceSettings.get(
      CapsaSettingsTypeIds.CareLink_DrawerAccessEnable
    );

    if (!drawerAccessSetting) {
      return undefined;
    }

    return drawerAccessSetting.SettingValue;
  }

  private settingApiModelToUpdateRequest(
    apiModel: CapsaSettingApiModel
  ): SettingUpdateRequest {
    return {
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationId: apiModel.OrganizationId,
      FacilityId: apiModel.FacilityId,
      CapsaUserId: this.userId,
      SettingId: apiModel.SettingId,
      DeviceTypeId: apiModel.DeviceTypeId,
      SettingType: apiModel.SettingType,
      SettingValue: apiModel.SettingValue,
    };
  }

  /**
   * Should only run as soon as we get a new use object from the API to "auto-repair" the users org assignements
   */
  private autoFixOrgAssignments() {
    const assignedOrgIds = this.updatedUser.get('OrganizationIds');
    const assignedFacIds = this.updatedUser.get('FacilityIds');
    let autoFixHappened = false;

    assignedFacIds.forEach((facId) => {
      const pristineFullFac = this.pristineEnterpriseFacs.get(facId);

      if (!pristineFullFac) {
        // The signed in user likely isn't assigned to the facility that
        // this user is assigned
        return;
      }

      const orgIdAssignedMatch = assignedOrgIds.indexOf(
        pristineFullFac.ParentId
      );

      if (orgIdAssignedMatch === -1) {
        // Facility assigned, but it's org is NOT
        // Let's auto fix this...
        assignedOrgIds.push(pristineFullFac.ParentId);
        autoFixHappened = true;
      }
    });

    if (autoFixHappened) {
      this._updatedUser = this._updatedUser.merge({
        OrganizationIds: assignedOrgIds,
      });
    }
  }

  public updateFacAssignment(updatedOrgs: number[], updatedFacs: number[]) {
    this.updateUser({
      OrganizationIds: updatedOrgs,
      FacilityIds: updatedFacs,
    });

    this.removeGhostFacilityRoles();
    this.facAssignmentsChangedSubj.next();
  }

  public updateUserRoles(newRoleIds: number[]) {
    this.updateUser({
      RoleIds: newRoleIds,
    });

    this.userRolesChangedSubj.next();
    this.refreshUserNsightDeviceAccessStatus();
    this.refreshRoleInEveryFacilityStatus();
  }

  public updateMetadataValidation(isValid: boolean) {
    this.metadataValidSubj.next(isValid);
  }

  public updateMetadata(
    firstName: string,
    lastName: string,
    email: string,
    isActive: boolean,
    proxId: string
  ) {
    this.updateUser({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      IsActive: isActive,
      ProxId: proxId,
    });
  }

  public updateUserAssignedCarts(updatedCartIds: number[]) {
    this.updateUser({ UserCartIds: updatedCartIds });
  }

  private updateUser(updatedUserPartial: Partial<UserUpdateRequest>) {
    this._updatedUser = this._updatedUser.merge(updatedUserPartial);
  }

  /**
   * Returns list of service request type ID's for the given facility
   * NOTE: If null is returned that means no changes are pending
   */
  public getPendingRequestTypes() {
    return this._pendingAssignedRequestTypes.get(this.selectedFacilityId);
  }

  public savePendingRequestTypes(requestTypeIds: number[]) {
    this._pendingAssignedRequestTypes.set(
      this.selectedFacilityId,
      requestTypeIds
    );

    const pendingRequestTypes: MessageQueueUserRequestTypesUpdateRequest[] = [];

    this._pendingAssignedRequestTypes.forEach((ids, facId) => {
      pendingRequestTypes.push({
        EnterpriseId: this.enterpriseService.enterpriseId,
        FacilityId: facId,
        UserId: this.pristineUser.get('Id'),
        RequestTypeIds: ids,
      });
    });

    this.updateUser({ AssignedRequestTypes: pendingRequestTypes });
  }

  public onResendAccountSetupEmail() {}

  public forcePasswordReset() {}

  public save() {
    // Maybe add some extra checks... logging before actually hitting API
    this.saveUser();
  }

  public reportUserFacilityConfigFacIdChanged(facilityId: number) {
    this.selectedFacilityChangedBehSubj.next(facilityId);
    this.refreshUserNsightDeviceAccessStatus();
  }

  private refreshUserNsightDeviceAccessStatus() {
    if (this.selectedFacilityId < 1) {
      // no facility selected, this function does not apply
      return;
    }

    const allUsersRoleIds = this.updatedUser.get('RoleIds');

    const selectedFacilityUsersFullRoles =
      this.capsaRoleService.unsafeRolesList.filter((x) =>
        allUsersRoleIds.some(
          (y) => y === x.CapsaRoleId && x.FacilityId === this.selectedFacilityId
        )
      );

    const anyRoleNsightPerms = UserHelpers.anyRoleNsightPermission(
      selectedFacilityUsersFullRoles
    );
    const anyRoleDevicePerms = UserHelpers.anyRoleHasDeviceAccessPermission(
      selectedFacilityUsersFullRoles
    );

    this.hasNsightAccessRoleThisFacilityBehSubj.next(anyRoleNsightPerms);
    this.hasDeviceAccessRoleThisFacilityBehSubj.next(anyRoleDevicePerms);
  }

  public requestNewRegistrationEmail() {
    if (this.processingUserManageActionBehSubj.value) {
      return;
    }

    this.processingUserManageActionBehSubj.next(true);
    this.subs.add(
      this.userApi
        .sendEmailAndSecurityQuestionReset(this.userId)
        .pipe(
          finalize(() => this.processingUserManageActionBehSubj.next(false))
        )
        .subscribe(
          (resp) => {
            this.registrationSentBehSubj.next(true);
            this.toasterService.showSuccess(
              'RESEND_NEW_ACCOUNT_EMAIL_SUCCESS',
              { hideAfterMs: 5000 }
            );
          },
          (error) => {
            this.toasterService.showError('RESEND_NEW_ACCOUNT_EMAIL_FAIL', {
              hideAfterMs: 5000,
            });
          }
        )
    );
  }

  public requestNewEmailValidationEmail() {
    if (this.processingUserManageActionBehSubj.value) {
      return;
    }
    this.processingUserManageActionBehSubj.next(true);
    this.subs.add(
      this.userApi
        .resendNewEmailConfirmEmail(this.userId)
        .pipe(
          finalize(() => this.processingUserManageActionBehSubj.next(false))
        )
        .subscribe(
          (resp) => {
            this.emailValidationSentBehSubj.next(true);
            this.toasterService.showSuccess(
              'RESEND_NEW_EMAIL_CONFIRMATION_EMAIL_SUCCESS',
              { hideAfterMs: 5000 }
            );
          },
          (error) => {
            this.toasterService.showError(
              'RESEND_NEW_EMAIL_CONFIRMATION_EMAIL_FAIL',
              { hideAfterMs: 8000 }
            );
          }
        )
    );
  }

  public unlockUser() {
    if (this.processingUserManageActionBehSubj.value) {
      return;
    }
    this.processingUserManageActionBehSubj.next(true);

    return this.subs.add(
      this.userApi
        .unlockUser(this.userId)
        .pipe(
          finalize(() => this.processingUserManageActionBehSubj.next(false))
        )
        .subscribe(
          (resp) => {
            this.userUnlockSuccessBehSubj.next(true);
            this.toasterService.showSuccess('USER_UNLOCK_SUCCESS');
          },
          (error) => {
            this.toasterService.showError('USER_UNLOCK_FAIL');
          }
        )
    );
  }

  public requestPasswordResetEmail() {
    if (this.processingUserManageActionBehSubj.value) {
      return;
    }
    this.processingUserManageActionBehSubj.next(true);

    this.subs.add(
      this.userApi
        .forceResetPassword(this.userId)
        .pipe(
          finalize(() => this.processingUserManageActionBehSubj.next(false))
        )
        .subscribe(
          (resp) => {
            this.passwordResetSentBehSubj.next(true);
            this.toasterService.showSuccess('USER_PASSWORD_RESET_SUCCESS', {
              hideAfterMs: 8000,
            });
            // Update user record to 
            this.updateUser({
              IsPasswordResetRequested: true
            });
          },
          (error) => {
            this.toasterService.showError('USER_PASSWORD_RESET_FAIL');
          }
        )
    );
  }

  private resetUserStatusSuccessMsgs() {
    this.registrationSentBehSubj.next(false);
    this.emailValidationSentBehSubj.next(false);
    this.passwordResetSentBehSubj.next(false);
    this.userUnlockSuccessBehSubj.next(false);
  }
}
