import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import {
  DateInputsModule,
  DateRangeModule,
} from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AnalyticsAccessPointsComponent } from 'app/modules/analytics/analytics-access-points/analytics-access-points.component';
import { AnalyticsAccessComponent } from 'app/modules/analytics/analytics-access/analytics-access.component';
import { AnalyticsBarChartComponent } from 'app/modules/analytics/analytics-bar-chart/analytics-bar-chart.component';
import { AnalyticsLegendComponent } from 'app/modules/analytics/analytics-legend/analytics-legend.component';
import { AnalyticsLineChartComponent } from 'app/modules/analytics/analytics-line-chart/analytics-line-chart.component';
import { AnalyticsPowerComponent } from 'app/modules/analytics/analytics-power/analytics-power.component';
import { AnalyticsTemplateComponent } from 'app/modules/analytics/analytics-template/analytics-template.component';
import { EntitySelectionDialogComponent } from 'app/modules/analytics/entity-selection-dialog/entity-selection-dialog.component';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';

@NgModule({
  declarations: [
    AnalyticsTemplateComponent,
    AnalyticsPowerComponent,
    AnalyticsAccessComponent,
    AnalyticsAccessPointsComponent,
    AnalyticsLineChartComponent,
    AnalyticsBarChartComponent,
    EntitySelectionDialogComponent,
    AnalyticsLegendComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    TranslateModule,
    CapsaDropdownsModule,
    ApiModule,
    DateInputsModule,
    ButtonsModule,
    LabelsModule,
    DateRangeModule,
    DialogsModule,
    GridModule,
    InputsModule,
    LoadingIndicatorModule,
    FormsModule,
  ],
})
export class AnalyticsModule {}
