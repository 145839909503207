// external modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';

// internal modules
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';

// internal components
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FirmwarePnDropDownComponent } from 'app/modules/device-update/firmware-pn-drop-down/firmware-pn-drop-down.component';
import { FirmwareUploadDialogComponent } from 'app/modules/device-update/firmware-upload-dialog/firmware-upload-dialog.component';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { ListPageComponent } from './list-page/list-page.component';

@NgModule({
  declarations: [
    ListPageComponent,
    FirmwareUploadDialogComponent,
    FirmwarePnDropDownComponent,
  ],
  exports: [ListPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DialogModule,
    ButtonsModule,
    UploadsModule,
    InputsModule,
    DateInputsModule,
    LabelsModule,
    CapsaDropdownsModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
})
export class DeviceUpdateModule {}
