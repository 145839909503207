import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AsyncDropDownListComponent } from '@capsa/dropdowns/async-drop-down-list/async-drop-down-list.component';
import { DropDownItem, EnumTypes } from '@capsa/dropdowns/enum';
import { EnumDropDownDataSource } from '@capsa/dropdowns/enum/enum-drop-down-data-source';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-enum-drop-down',
  templateUrl: './enum-drop-down.component.html',
  styleUrls: ['./enum-drop-down.component.scss'],
  providers: [EnumDropDownDataSource],
})
export class EnumDropDownComponent
  extends AsyncDropDownListComponent<DropDownItem>
  implements OnInit, OnDestroy
{
  @Input()
  public label: string;

  @Input()
  public enumName: EnumTypes;

  @Input()
  public defaultValue: number | string | undefined;

  @Input()
  public setValue$: Observable<string>;

  private subs = new Subscription();

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  constructor(
    translations: TranslateService,
    public dataSource: EnumDropDownDataSource
  ) {
    super(translations);
  }

  ngOnInit() {
    this.dataSource.enumName = this.enumName;
    this.subs.add(
      this.dataSource.load().subscribe((items) => {
        this.dataSource.data = items;
        this.dataSource.setDefaultValue(this.defaultValue);
        this.dataSource.selectDefaultItem();
      })
    );
    if (this.setValue$) {
      this.subs.add(
        this.setValue$.subscribe((item) => {
          const match = this.dataSource.data.find((x) => x.id === item);
          if (match) {
            this.dataSource.selectItem(match);
          }
        })
      );
    }
  }
}
