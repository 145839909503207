import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  CapsaRoleResponseDto,
  CapsaRoleSearchRequestDto,
} from '..';
import { BaseApi } from '../base/base.api';

export interface CapsaRoleApiInstance {
  search(
    query: CapsaRoleSearchRequestDto
  ): Observable<ApiResponse<CapsaRoleResponseDto[]>>;
}

@Injectable()
export class CapsaRoleApi extends BaseApi implements CapsaRoleApiInstance {
  protected endpoints = {
    search: this.buildUrl('capsarole/search'),
  };

  /**
   * Gets a list of all the roles meeting the search criteria
   */
  public search(query: Partial<CapsaRoleSearchRequestDto>) {
    return this.http.post<ApiResponse<CapsaRoleResponseDto[]>>(
      this.endpoints.search,
      query
    );
  }
}
