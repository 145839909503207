import { Pipe, PipeTransform } from '@angular/core';
import { UpdateStatusType } from '@capsa/api';

@Pipe({
  name: 'updateStatusTooltipTag',
})
export class UpdateStatusTooltipTagPipe implements PipeTransform {
  transform(value: UpdateStatusType, ...args: any[]): any {
    switch (value) {
      case UpdateStatusType.UpToDate:
        return 'UPDATE_ICON_STATUS_UP_TO_DATE';
      case UpdateStatusType.NeedsUpdate:
        return 'UPDATE_ICON_STATUS_UP_NEEDS_UPDATE';
      case UpdateStatusType.UpdatePending:
        return 'UPDATE_ICON_STATUS_UP_UPDATE_PENDING';
      default:
        return 'UPDATE_ICON_STATUS_UP_NA';
    }
  }
}
