import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { AuthGuardService } from '@capsa/services/auth/auth.guard.service';
import { AuthService } from '@capsa/services/auth/auth.service';
import { NativeWindowService } from '@capsa/services/native-window/native-window.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  // Inject Router so we can hand off the user to the Login Page
  constructor(
    private guardService: AuthGuardService,
    private windowService: NativeWindowService,
    private router: Router,
    private authService: AuthService,
    private appSettingService: AppSettingsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let token;
    if (route.queryParamMap) {
      token = route.queryParamMap.get('token');
    }

    if (token) {
      this.guardService.saveTokenToAuthCookie(token);
      // Removes "token" query parameter from route (essentially a redirect to the same route without the "token" parameter)
      this.router.navigate([], {
        queryParams: {
          token: null,
        },
        queryParamsHandling: 'merge',
      });
      // returning false to cancel this navigation
      // allowing 'navigate' above to do its thing
      return false;
    } else if (this.guardService.isLoggedIn()) {
      this.authService.updateIsAuthenticated(true);
      return of(true);
    } else {
      const authUrl =
        `${this.appSettingService.get<string>('loginPortalUrl')}?returnUrl=` +
        encodeURIComponent(this.windowService.currentOrigin());
      this.windowService.redirect(authUrl);
      return of(false);
    }
  }
}
