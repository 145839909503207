import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  AnalyticsChartEntity,
  ChartEntityType,
  ChartQueryType,
  ChartTimeResolutionIntervalType,
} from '@capsa/api';
import {
  BaseUnit,
  CategoryAxisItemComponent,
} from '@progress/kendo-angular-charts';
import {
  AnalyticsDataStructureType,
  AnalyticsQueryProperties,
  ChartType,
} from 'app/modules/analytics/analytics-interfaces';
import { ColorsList } from 'app/modules/analytics/color-combos';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsHelperService {
  private chartProperties: Map<ChartQueryType, AnalyticsQueryProperties>;

  /**
   * There are basically three stacking levels, "default", "fixed" and "hovered"
   * default: each series is stacked in the order it is rendered (with last rendered
   * lines appearing on top)
   * fixed: when we apply a "fixed highlight" to a series, we want to make sure it shows up
   * above all NON highlighted series
   * hovered: when hovering over a legend item even if it's not highlighted we want it to
   * show above ALL series, including highlighted ones
   * Note: Magic number warning, if you go over 100 then hovering over markers
   * will cause the "highlight marker" to show UNDERNEATH the current marker
   * (Apparently the line zIndex is separate from its markers...)
   * Kendo appears to generate two markers and swapping their visibility on hover
   * instead of updating a single marker's properties
   */
  public readonly fixedHighlightZIndex = 10;
  public readonly hoverHighlightZIndex = this.fixedHighlightZIndex + 1;

  public readonly seriesClickedSubj = new Subject<AnalyticsChartEntity>();
  public readonly seriesClicked$ = this.seriesClickedSubj.asObservable();

  public props(queryType: ChartQueryType): AnalyticsQueryProperties {
    return this.chartProperties.get(queryType);
  }

  constructor() {
    this.initChartProperties();
  }

  /**
   * Converts our API "time interval enum" to a "BaseUnit" which is the Kendo equivalant
   */
  public convertApiIntervalToKendoBaseUnit(
    interval: ChartTimeResolutionIntervalType
  ): BaseUnit {
    switch (interval) {
      case ChartTimeResolutionIntervalType.QuarterHour:
        return 'minutes';
      case ChartTimeResolutionIntervalType.Hour:
        return 'hours';
      case ChartTimeResolutionIntervalType.Day:
        return 'days';
      case ChartTimeResolutionIntervalType.Week:
        return 'weeks';
      case ChartTimeResolutionIntervalType.Month:
        return 'months';
      case ChartTimeResolutionIntervalType.Unknown:
      default:
        return undefined;
    }
  }

  public refreshChartSizeProperties(
    componentSelector: string,
    axisItems: CategoryAxisItemComponent,
    cdRef: ChangeDetectorRef
  ) {
    /**
     * This function needs to run AFTER the chart element has rendered so that it can
     * get the chart "width", if it runs BEFORE chart renders, it will get a premature
     * value of 0, and divisions will not be applied correctly
     */
    setTimeout(() => {
      const chartEl = document.querySelector(componentSelector);
      const chartWidth = chartEl.clientWidth;
      let maxDivisions = 0;

      if (chartWidth > 1600) {
        maxDivisions = 30;
      } else if (chartWidth > 1400) {
        maxDivisions = 28;
      } else if (chartWidth > 1200) {
        maxDivisions = 25;
      } else if (chartWidth > 1000) {
        maxDivisions = 20;
      } else if (chartWidth > 800) {
        maxDivisions = 15;
      } else if (chartWidth > 600) {
        maxDivisions = 12;
      } else {
        maxDivisions = 10;
      }

      axisItems.notifyChanges({
        maxDivisions,
      });
      cdRef.detectChanges();
    }, 0);
  }

  public sortAndSetColors(
    entities: AnalyticsChartEntity[],
    structureType: AnalyticsDataStructureType
  ) {
    let primaryColorCounter: number;
    switch (structureType) {
      case AnalyticsDataStructureType.regular:
        entities.sort(this.sortByKeyThenSubcategory);
        for (let i = 0; entities.length > i; i++) {
          // if there are more entities than color combo's, it will "loop around"
          // to the beginning of the colors array, and continiue
          const colorIndex = i % ColorsList.length;
          entities[i].TextColor = ColorsList[colorIndex].fg;
          entities[i].BgColor = ColorsList[colorIndex].bg;
          entities[i].BaseBgColor = ColorsList[colorIndex].bg;
        }
        break;
      case AnalyticsDataStructureType.stackedPerEntity:
        entities.sort(this.sortByKeyThenSubcategory);
        let lastKey = '';
        primaryColorCounter = -1;
        for (let i = 0; entities.length > i; i++) {
          const current = entities[i];
          if (lastKey !== current.EntityKey) {
            lastKey = current.EntityKey;
            primaryColorCounter++;
          }
          const colorIndex = primaryColorCounter % ColorsList.length;

          if (current.SubCategory === 'ChargingModeAc') {
            current.TextColor = ColorsList[colorIndex].fg;
            current.BgColor = ColorsList[colorIndex].bg;
            current.BaseBgColor = ColorsList[colorIndex].bg;
          } else if (current.SubCategory === 'ChargingModeBattery') {
            current.TextColor = ColorsList[colorIndex].fg;
            current.BgColor = ColorsList[colorIndex].bg2;
            current.BaseBgColor = ColorsList[colorIndex].bg;
          } else {
            current.TextColor = ColorsList[colorIndex].fg;
            current.BgColor = ColorsList[colorIndex].bg3;
            current.BaseBgColor = ColorsList[colorIndex].bg;
          }
        }

        break;
      case AnalyticsDataStructureType.stackedPerDateTime:
        this.transformEntitiesForDateStacking(entities);
        this.applyColorForEachUniquePropertyVal(entities, 'SubCategory');
        break;
      case AnalyticsDataStructureType.stackedEntityBarStacksSubEntities:
        entities.sort(this.sortBySubcategory);
        this.applyColorForEachUniquePropertyVal(entities, 'SubCategory');
        entities.sort(this.sortByKeyThenSubcategory);
        this.applyBorderColorForEachUniquePropertyVal(entities, 'EntityKey');
        break;
      default:
        throw new Error(
          'ATTENTION DEVELOPER: AnalyticsDataStructureType Type not yet supported'
        );
    }
  }

  private applyBorderColorForEachUniquePropertyVal(
    entities: AnalyticsChartEntity[],
    propName: 'EntityKey' | 'SubCategory'
  ) {
    let lastPropVal = '';
    let primaryColorCounter = ColorsList.length;
    for (let i = 0; entities.length > i; i++) {
      const current = entities[i];
      if (lastPropVal !== current[propName]) {
        lastPropVal = current[propName];
        primaryColorCounter--;
      }

      // if there are more entities than color combo's, it will "loop around"
      // to the beginning of the colors array, and continiue
      const colorIndex = primaryColorCounter % ColorsList.length;
      entities[i].Border = { color: ColorsList[colorIndex].bg, width: 2 };
    }
  }

  private applyColorForEachUniquePropertyVal(
    entities: AnalyticsChartEntity[],
    propName: 'EntityKey' | 'SubCategory'
  ) {
    let lastPropVal = '';
    let primaryColorCounter = -1;
    for (let i = 0; entities.length > i; i++) {
      const current = entities[i];
      if (lastPropVal !== current[propName]) {
        lastPropVal = current[propName];
        primaryColorCounter++;
      }

      // if there are more entities than color combo's, it will "loop around"
      // to the beginning of the colors array, and continiue
      const colorIndex = primaryColorCounter % ColorsList.length;
      entities[i].TextColor = ColorsList[colorIndex].fg;
      entities[i].BgColor = ColorsList[colorIndex].bg;
      entities[i].BaseBgColor = ColorsList[colorIndex].bg;
    }
  }

  private sortByKeyThenSubcategory(
    e1: AnalyticsChartEntity,
    e2: AnalyticsChartEntity
  ) {
    if (e1.EntityKey > e2.EntityKey) {
      return 1;
    }
    if (e1.EntityKey < e2.EntityKey) {
      return -1;
    }

    // For "Charging Mode All" Ultimately the goal is to get AC to show up first, then battery, followed by "not reporing"
    // this will result in the kendo stacked bars to have "AC power" at the base, with
    // "battery" stacked on top of that, and finally "not reporting" on top of that.
    // It's a coincidence that the categories are alphabetic in the order we want them.
    // If a different stack order is required, then a custom sort will be necessary
    if (e1.SubCategory > e2.SubCategory) {
      return 1;
    }
    if (e1.SubCategory < e2.SubCategory) {
      return -1;
    }
  }

  private sortBySubcategory(
    e1: AnalyticsChartEntity,
    e2: AnalyticsChartEntity
  ) {
    if (e1.SubCategory > e2.SubCategory) {
      return 1;
    }
    if (e1.SubCategory < e2.SubCategory) {
      return -1;
    }
  }

  private transformEntitiesForDateStacking(entities: AnalyticsChartEntity[]) {
    entities.forEach((entity) => {
      entity.SubCategory = entity.EntityKey;

      // this ensures that one bar will be generated for each unique date/time
      // rather than one for each entity. but this also means we need to
      // display the tooltips differently for these (map to SubCategory, rather than Key)
      // also the function that sets colors for these entities will need
      // to run differently
      entity.EntityKey = 'same-key-for-all';
    });
    entities.sort(this.sortBySubcategory);
  }

  private initChartProperties() {
    this.chartProperties = new Map<ChartQueryType, AnalyticsQueryProperties>([
      [
        ChartQueryType.BatteryVoltage,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_BatteryVoltage',
          chartType: ChartType.line,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.BatteryCapacity,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_BatteryCapacity',
          chartType: ChartType.line,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        // power usage
        ChartQueryType.ExternalLoad,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_ExternalLoad',
          chartType: ChartType.line,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        // battery current
        ChartQueryType.DCPowerUsage,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_DCPowerUsage',
          chartType: ChartType.line,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.ChargingModeAll,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_ChargingModeAll',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.stackedPerEntity,
        },
      ],
      [
        ChartQueryType.ChargingModeAc,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_ChargingModeAc',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.ChargingModeBattery,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_ChargingModeBattery',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.ChargingModeNotReporting,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_ChargingModeNotReporting',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.AccessSuccessfulPINs,
        {
          allowedEntityTypes: [ChartEntityType.Cart, ChartEntityType.User],
          chartTileTag: 'TITLE_ChartQueryType_AccessSuccessfulPINs',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.stackedPerDateTime,
          hideSubcategory: true,
        },
      ],
      [
        ChartQueryType.AccessFailedPINs,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_AccessFailedPINs',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.stackedPerDateTime,
          hideSubcategory: true,
        },
      ],
      [
        ChartQueryType.AccessLockouts,
        {
          allowedEntityTypes: [ChartEntityType.Cart],
          chartTileTag: 'TITLE_ChartQueryType_AccessLockouts',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.stackedPerDateTime,
          hideSubcategory: true,
        },
      ],
      [
        ChartQueryType.LocationConnectivity,
        {
          allowedEntityTypes: [
            ChartEntityType.Cart,
            ChartEntityType.AccessPoint,
          ],
          chartTileTag: 'TITLE_ChartQueryType_LocationConnectivity',
          chartType: ChartType.bar,
          dataStructureType:
            AnalyticsDataStructureType.stackedEntityBarStacksSubEntities,
        },
      ],
      [
        ChartQueryType.TimeLoggedIn,
        {
          allowedEntityTypes: [ChartEntityType.Cart, ChartEntityType.User],
          chartTileTag: 'TITLE_ChartQueryType_TimeLoggedIn',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.stackedPerDateTime,
          hideSubcategory: true,
        },
      ],
      [
        ChartQueryType.LoggedOutBatteryLevel,
        {
          allowedEntityTypes: [ChartEntityType.User],
          chartTileTag: 'TITLE_ChartQueryType_LoggedOutBatteryLevel',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
      [
        ChartQueryType.LoggedOutLowBattery,
        {
          allowedEntityTypes: [ChartEntityType.User],
          chartTileTag: 'TITLE_ChartQueryType_LoggedOutLowBattery',
          chartType: ChartType.bar,
          dataStructureType: AnalyticsDataStructureType.regular,
        },
      ],
    ]);
  }
}
