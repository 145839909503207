import { Injectable } from '@angular/core';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractAsyncMultiSelectDataSource<TModel> {
  get selection() {
    return this.currentSelection;
  }
  public abstract get canLoad(): boolean;

  public abstract get defaultItem(): AsyncDropDownValue<TModel>[] | undefined;

  constructor() {
    this.map = this.map.bind(this);
    this.callApi = this.callApi.bind(this);
    this.select = this.select.bind(this);
    this.load = this.load.bind(this);
  }
  public loading: boolean;

  protected currentSelection: AsyncDropDownValue<TModel>[] | undefined;

  public items: AsyncDropDownValue<TModel>[];

  protected required: boolean;

  protected abstract callApi(): Observable<TModel[]>;

  protected abstract map(apiModel: TModel): AsyncDropDownValue<TModel>;
  protected abstract select(
    items: AsyncDropDownValue<TModel>[]
  ): AsyncDropDownValue<TModel>[] | undefined;

  public reset(): void {
    this.currentSelection = this.defaultItem;
    this.items = [];
  }

  public load(): Observable<AsyncDropDownValue<TModel>[]> {
    if (!this.canLoad) {
      return of<AsyncDropDownValue<TModel>[]>([]);
    }

    this.loading = true;

    return this.callApi().pipe(
      map((apiModels) => apiModels.map((model) => this.map(model))),
      map((viewModels) =>
        viewModels.sort((a, b) => a.name.localeCompare(b.name))
      ),
      tap((items) => {
        this.currentSelection = this.select(items);
        if (!this.currentSelection) {
          this.currentSelection = this.defaultItem;
        }
        this.items = items;
      }),
      tap(() => (this.loading = false))
    );
  }
}
