import { Component, OnInit } from '@angular/core';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Utils } from 'app/common/utils';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../common/constants';

@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss'],
})
export class LanguageChangeComponent implements OnInit {
  public supportedLanguages: DropDownItem[];
  public current: DropDownItem;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.generateValues();
  }

  private generateValues() {
    const supported = this.translateService.getLangs();
    this.supportedLanguages = supported
      .map((langId) => {
        const languageTag = Utils.getLanguageFromId(langId);
        const translatedLangName = this.translateService.instant(languageTag);
        const item: DropDownItem = {
          Label: translatedLangName,
          Value: langId,
        };
        if (langId === this.translateService.currentLang) {
          this.current = item;
        }
        return item;
      })
      .sort((a, b) => a.Label.localeCompare(b.Label));
  }

  public valueChanged(newLanguage: DropDownItem) {
    if (this.translateService.currentLang === newLanguage.Value) {
      return;
    }

    this.current = newLanguage;
    const newLang = newLanguage.Value as string;

    this.translateService.use(newLang).subscribe({
      next: () => {
        this.writeLanguageCookie(newLang);
      },
      error: () => {},
    });
  }

  private writeLanguageCookie(newLang: string) {
    this.cookieService.set(
      Constants.CookieNames.preferredLanguage,
      newLang,
      null,
      '/',
      null,
      true,
      'Strict'
    );
  }
}
