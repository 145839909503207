import { DeviceTypeIds } from '@capsa/api';
import { AvaloCartManagementTabs } from 'app/modules/cart-management/cart-management-single/device-types/avalo-cart-management-tabs';
import { TrioCartManagementTabs } from 'app/modules/cart-management/cart-management-single/device-types/trio-cart-management-tabs';
import { DynamicDeviceTypeTabs } from 'app/modules/core/core.module';

export const CartManagementDeviceTypeTabs: DynamicDeviceTypeTabs = {
  [DeviceTypeIds.CareLink_2]: TrioCartManagementTabs,
  [DeviceTypeIds.CareLink_M48]: TrioCartManagementTabs,
  [DeviceTypeIds.CareLink_M38e]: TrioCartManagementTabs,
  [DeviceTypeIds.Avalo]: AvaloCartManagementTabs,
  [DeviceTypeIds.All]: [],
};
