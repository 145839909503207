import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { CarelinkV1BatterySettingsTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-battery-settings-tab/carelink-v1-battery-settings-tab.component';
import { CarelinkV1DeviceFeaturesTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-device-features-tab/carelink-v1-device-features-tab.component';
import { CarelinkV1DisplayTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-display-tab/carelink-v1-display-tab.component';

export const CareLinkV1Tabs: DynamicTab[] = [
  {
    titleTag: 'DISPLAY_SETTINGS',
    component: CarelinkV1DisplayTabComponent,
  },
  {
    titleTag: 'BATTERY_SETTINGS',
    component: CarelinkV1BatterySettingsTabComponent,
  },
  {
    titleTag: 'DEVICE_FEATURES',
    component: CarelinkV1DeviceFeaturesTabComponent,
  },
];
