import { Injectable } from '@angular/core';
import { UserRegistrationDetails } from '@capsa/api';
import { NativeWindowService } from '@capsa/services/native-window/native-window.service';

@Injectable()
export class UserRegistrationService {
  constructor(private nativeWindowService: NativeWindowService) {}

  public encodeRegistrationDetails(
    registrationDetails: UserRegistrationDetails
  ) {
    return this.nativeWindowService.encodeURIComponent(
      btoa(JSON.stringify(registrationDetails))
    );
  }

  public decodeRegistrationDetails(encoded: string) {
    return JSON.parse(
      atob(this.nativeWindowService.decodeURIComponent(encoded))
    ) as UserRegistrationDetails;
  }
}
