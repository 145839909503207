import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Constants } from 'app/common/constants';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ApiResponse,
  CartPartialResponse,
  CartReportRequest,
  CartResponse,
  CartSearchRequest,
  CartTransferRequest,
  DeviceTypeIds,
  GetCartByIdRequest,
  PagingRequestDto,
  throwIfBadRespFullResp,
  throwIfBadRespReturnPayloadOnly,
} from '..';
import { BaseApi } from '../base/base.api';

export interface CartApiInstance {
  getCarts(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceTypeId: number,
    includeInactive: boolean
  ): Observable<ApiResponse<CartResponse[]>>;

  getCartsPartial(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceTypeId: number
  ): Observable<CartPartialResponse[]>;

  getOrgFacilityCarts(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number
  ): Observable<ApiResponse<CartPartialResponse[]>>;

  transferCart(request: CartTransferRequest): Observable<ApiResponse<void>>;

  returnToCapsa(cartId: number): Observable<ApiResponse<void>>;

  getAdminPin(cartId: number): Observable<ApiResponse<string>>;

  getCartExport(facilityIds: number[]): Observable<ApiResponse<string>>;
}

@Injectable()
export class CartApi extends BaseApi implements CartApiInstance {
  protected endpoints = {
    cartSearchUrl: this.buildUrl('cart/admin-search'),
    cartSearchPartialUrl: this.buildUrl('cart/admin-search/partial'),
    getById: this.buildUrl('cart/GetById'),
    update: this.buildUrl('cart/Update'),
    transferCart: this.buildUrl('cart/transfercart'),
    returnToCapsa: this.buildUrl('NexsysSetup/ReturnToCapsa/'),
    getAdminPin: this.buildUrl('cart/{cartId}/admin-pin'),
    exportReport: this.buildUrl('cart/export'),
    requestDebugLogs: this.buildUrl('device/{cartId}/request-logs'),
  };

  constructor(
    client: HttpClient,
    private enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
  }

  public searchCarts(
    paging: PagingRequestDto,
    searchQuery: Partial<CartSearchRequest>
  ): Observable<ApiResponse<CartResponse[]>> {
    const request: CartSearchRequest = {
      ...searchQuery,
      EnterpriseId: this.enterpriseService.enterpriseId,
      ProductLines: Constants.supportedProductLines,
      PageNumber: paging.PageNumber,
      PageSize: paging.PageSize,
    } as CartSearchRequest;

    return this.http
      .post<ApiResponse<CartResponse[]>>(this.endpoints.cartSearchUrl, request)
      .pipe(
        throwIfBadRespFullResp(),
        map((resp) => {
          if (!resp.Result || resp.Result.length === 0) {
            return resp;
          }

          resp.Result.forEach((c) => {
            const tempMap = new Map<string, string>();
            const tempKvps = c.ComponentValues as any;
            for (const ix of Object.keys(tempKvps)) {
              tempMap.set(ix, tempKvps[ix]);
            }

            c.ComponentValues = tempMap;
            c.LastImAliveUtc = c.LastImAliveUtc
              ? moment.utc(c.LastImAliveUtc).toDate()
              : null;
          });

          return resp;
        })
      );
  }

  /**
   * Gets a list of all the carts meeting the search criteria
   */
  public getCarts(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceTypeId: number,
    includeInactive: boolean
  ): Observable<ApiResponse<CartResponse[]>> {
    const searchRequest: CartSearchRequest = {
      IncludeCapsaSettings: false,
      EnterpriseId: this.enterpriseService.enterpriseId,
      ProductLines: Constants.supportedProductLines,
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
      IsActive: includeInactive ? undefined : true,
      PageNumber: paging.PageNumber,
      PageSize: paging.PageSize,
    };

    if (deviceTypeId !== DeviceTypeIds.All) {
      searchRequest.DeviceTypeId = deviceTypeId;
    }

    return this.http.post<ApiResponse<CartResponse[]>>(
      this.endpoints.cartSearchUrl,
      searchRequest
    );
  }

  public getCartsPartial(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceTypeId: number
  ): Observable<CartPartialResponse[]> {
    const search: Partial<CartSearchRequest> = {
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
      IsActive: true,
      ProductLines: Constants.supportedProductLines,
      EnterpriseId: this.enterpriseService.enterpriseId,
    };

    if (deviceTypeId !== DeviceTypeIds.All) {
      search.DeviceTypeId = deviceTypeId;
    }

    return this.searchCartsPartial(paging, search);
  }

  public searchCartsPartial(
    paging: PagingRequestDto,
    search: Partial<CartSearchRequest>
  ): Observable<CartPartialResponse[]> {
    const entId = search.EnterpriseId
      ? search.EnterpriseId
      : this.enterpriseService.enterpriseId;
    const searchRequest: CartSearchRequest = {
      ...paging,
      ...search,
      IsActive: true,
      EnterpriseId: entId,
    } as CartSearchRequest;

    if (!searchRequest.ProductLines) {
      searchRequest.ProductLines = Constants.supportedProductLines;
    }

    return this.http
      .post<ApiResponse<CartPartialResponse[]>>(
        this.endpoints.cartSearchPartialUrl,
        searchRequest
      )
      .pipe(throwIfBadRespReturnPayloadOnly());
  }

  public getOrgFacilityCarts(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number
  ): Observable<ApiResponse<CartPartialResponse[]>> {
    const searchRequest: CartSearchRequest = {
      IncludeCapsaSettings: false,
      EnterpriseId: this.enterpriseService.enterpriseId,
      ProductLines: Constants.supportedProductLines,
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
      IsActive: true,
      PageNumber: paging.PageNumber,
      PageSize: paging.PageSize,
    };

    return this.http.post<ApiResponse<CartPartialResponse[]>>(
      this.endpoints.cartSearchPartialUrl,
      searchRequest
    );
  }

  public getById(cartId: number): Observable<ApiResponse<CartResponse>> {
    const request: GetCartByIdRequest = {
      Id: cartId,
    };
    return this.http
      .post<ApiResponse<CartResponse>>(this.endpoints.getById, request)
      .pipe(
        tap((resp) => {
          if (resp.Success) {
            const tempMap = new Map<string, string>();
            const tempKvps = resp.Result.ComponentValues as any;
            for (const ix of Object.keys(tempKvps)) {
              tempMap.set(ix, tempKvps[ix]);
            }

            resp.Result.ComponentValues = tempMap;
          }
        })
      );
  }

  public update(
    updatedCart: CartResponse
  ): Observable<ApiResponse<CartResponse>> {
    const request = {
      ...updatedCart,
      UpdateSettings: true,
    };
    return this.http.post<ApiResponse<CartResponse>>(
      this.endpoints.update,
      request
    );
  }

  public transferCart(
    request: CartTransferRequest
  ): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(this.endpoints.transferCart, request)
      .pipe(throwIfBadRespFullResp());
  }

  public returnToCapsa(cartId: number): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.returnToCapsa + cartId,
      null
    );
  }

  public getAdminPin(cartId: number): Observable<ApiResponse<string>> {
    return this.http.get<ApiResponse<string>>(
      this.endpoints.getAdminPin.replace('{cartId}', cartId.toString())
    );
  }

  public getCartExport(facilityIds: number[]): Observable<ApiResponse<string>> {
    const request: CartReportRequest = {
      OrganizationIds: [],
      FacilityIds: facilityIds,
      CartIds: [],
    };
    return this.http.post<ApiResponse<string>>(
      this.endpoints.exportReport,
      request
    );
  }

  public requestDebugLogs(cartId: number): Observable<ApiResponse<any>> {
    return this.http
      .post<ApiResponse<any>>(
        this.endpoints.requestDebugLogs.replace('{cartId}', cartId.toString()),
        ''
      )
      .pipe(throwIfBadRespFullResp());
  }
}
