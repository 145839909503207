import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DeviceProfileSearchResponseApiModel } from '@capsa/api';
import { CartProfileApi } from '@capsa/api/cart-profile';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-profile-drop-down',
  templateUrl: './device-profile-drop-down.component.html',
  styleUrls: ['./device-profile-drop-down.component.scss'],
})
export class DeviceProfileDropDownComponent implements OnInit, OnDestroy {
  @Input()
  public facilityId: number;

  @Input()
  public organizationId: number;

  @Input()
  public deviceProfileId: number;

  @Input()
  public deviceTypeId: number;

  public data: DeviceProfileSearchResponseApiModel[] = [];

  public loading = true;

  @Output()
  public valueChanged = new EventEmitter<number>();

  private subs = new Subscription();

  constructor(private deviceProfileApi: CartProfileApi) {}

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onValueChanged(deviceProfileId: number) {
    this.valueChanged.emit(deviceProfileId);
  }

  private loadData() {
    this.loading = true;
    const sub = this.deviceProfileApi
      .gridSearch({
        DeviceTypeId: this.deviceTypeId,
        FacilityId: this.facilityId,
        OrganizationId: this.organizationId,
        PageNumber: 1,
        PageSize: 1000,
      })
      .subscribe((response) => {
        this.data = response.Result;
        this.loading = false;
      });

    this.subs.add(sub);
  }
}
