import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { DashboardWidgetComponent } from 'app/modules/dashboard/dashboard-widget/dashboard-widget.component';
import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';
import { LabelsModule } from 'app/modules/labels/labels.module';
import 'hammerjs';

@NgModule({
  declarations: [DashboardComponent, DashboardWidgetComponent],
  imports: [
    CommonModule,
    ChartsModule,
    TranslateModule,
    ApiModule,
    CapsaDropdownsModule,
    LabelsModule,
  ],
})
export class DashboardModule {}
