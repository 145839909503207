// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-upload-container {
  width: 100%;
  height: 300px;
}

.img-container {
  padding: 14px;
  display: inline-block;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  border-style: solid;
  background-color: white;
}

.img-placeholder {
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 10%;
}

.upload-container {
  width: 430px;
  padding-top: 10px;
}

.left {
  float: left;
}

.logo-reqs {
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/facility/facility-manage-profile/facility-manage-profile-image/facility-manage-profile-image.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAGA,aAAA;AADF;;AAIA;EACE,aAAA;EACA,qBAAA;EAEA,iBAAA;EACA,iCAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;AAFF;;AAKA;EACE,YAAA;EACA,iBAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":[".profile-upload-container {\n  width: 100%;\n  // Image is 113px + 17px worth of text/padding for the header.\n  // this prevents jumpiness when a file is being uploaded.\n  height: 300px;\n}\n\n.img-container {\n  padding: 14px;\n  display: inline-block;\n\n  border-width: 1px;\n  border-color: rgba(0, 0, 0, 0.08);\n  border-style: solid;\n  background-color: white;\n}\n\n.img-placeholder {\n  width: 100%;\n  height: 100%;\n  background-color: gray;\n  opacity: 10%;\n}\n\n.upload-container {\n  width: 430px;\n  padding-top: 10px;\n}\n\n.left {\n  float: left;\n}\n\n.logo-reqs {\n  font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
