import { Component, Input } from '@angular/core';
import { SettingMap } from 'app/modules/cart-profile/helpers';

@Component({
  selector: 'app-cart-profile-cart-features',
  templateUrl: './cart-features.component.html',
  styleUrls: ['./cart-features.component.scss'],
})
export class CartFeaturesComponent {
  @Input()
  public settings: SettingMap;

  get calculator() {
    return this.settings.getSetting('Calculator');
  }

  get lights() {
    return this.settings.getSetting('Lights');
  }

  get serviceRequests() {
    return this.settings.getSetting('ServiceRequests');
  }

  get preferences() {
    return this.settings.getSetting('Preferences');
  }

  get notificationAlerts() {
    return this.settings.getSetting('NotificationAlerts');
  }

  get notificationMessages() {
    return this.settings.getSetting('NotificationMessages');
  }

  constructor() {}
}
