import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { UploadsModule } from '@progress/kendo-angular-upload';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ActivityLogModule } from 'app/modules/activity-log/activity-log.module';
import { CapsaDialogsModule } from 'app/modules/capsa-dialogs/capsa-dialogs.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CartLogsModule } from 'app/modules/cart-logs/cart-logs.module';
import { CoreModule } from 'app/modules/core/core.module';
import { DataDrivenControlsModule } from 'app/modules/data-driven-controls/data-driven-controls.module';
import { HeadersModule } from 'app/modules/headers/headers.module';
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { CreateUserComponent } from 'app/modules/users/create-user/create-user.component';
import { UserCartActivityListComponent } from 'app/modules/users/user-cart-activity/user-cart-activity.component';
import { EditUserPinComponent } from 'app/modules/users/user-management/edit-user-cart-settings-tab/edit-user-pin/edit-user-pin.component';
import { EditUserMetadateAndFacsComponent } from 'app/modules/users/user-management/edit-user-metadata-and-facs/edit-user-metadata-and-facs.component';
import { FacilityAssignmentsGridComponent } from 'app/modules/users/user-management/facility-assignments-grid/facility-assignments-grid.component';
import { UserManagementComponent } from 'app/modules/users/user-management/user-management.component';
import { UserWebActivityListComponent } from 'app/modules/users/user-web-activity/user-web-activity.component';
import { EditUserCartAccessComponent } from './user-management/edit-user-cart-access-tab/edit-user-cart-access-tab.component';
import { AvaloUserSettingsComponent } from './user-management/edit-user-cart-settings-tab/avalo-user-settings/avalo-user-settings.component';
import { CarelinkUserSettingsComponent } from './user-management/edit-user-cart-settings-tab/carelink-user-settings/carelink-user-settings.component';
import { EditUserCartSettingsTabComponent } from './user-management/edit-user-cart-settings-tab/edit-user-cart-settings-tab.component';
import { TrioUserSettingsComponent } from './user-management/edit-user-cart-settings-tab/trio-user-settings/trio-user-settings.component';
import { UserCartSettingsComponent } from './user-management/edit-user-cart-settings-tab/user-cart-settings/user-cart-settings.component';
import { EditUserPermissionsTabComponent } from './user-management/edit-user-permissions-tab/edit-user-permissions-tab.component';
import { EditUserRequestTypesTabComponent } from './user-management/edit-user-request-types-tab/edit-user-request-types-tab.component';
import { EditUserWrapperComponent } from './user-management/edit-user-wrapper/edit-user-wrapper.component';
import { ImportUsersDialogComponent } from './user-management/import-users/import-users-dialog/import-users-dialog.component';
import { ImportUsersComponent } from './user-management/import-users/import-users.component';
import { UsersBulkAssignComponent } from './users-bulk-assign/users-bulk-assign.component';
@NgModule({
  declarations: [
    UserManagementComponent,
    UserCartActivityListComponent,
    UserWebActivityListComponent,
    EditUserMetadateAndFacsComponent,
    EditUserWrapperComponent,
    EditUserCartAccessComponent,
    EditUserPinComponent,
    EditUserCartSettingsTabComponent,
    EditUserPermissionsTabComponent,
    EditUserRequestTypesTabComponent,
    ImportUsersComponent,
    CreateUserComponent,
    FacilityAssignmentsGridComponent,
    TrioUserSettingsComponent,
    CarelinkUserSettingsComponent,
    AvaloUserSettingsComponent,
    UserCartSettingsComponent,
    UsersBulkAssignComponent,
    ImportUsersDialogComponent,
  ],
  imports: [
    ActivityLogModule,
    CoreModule,
    DataDrivenControlsModule,
    LabelsModule,
    CommonModule,
    GridModule,
    TabStripModule,
    ButtonsModule,
    TranslateModule,
    CapsaDropdownsModule,
    LoadingIndicatorModule,
    PipesModule,
    UploadsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    DropDownsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HeadersModule,
    CartLogsModule,
    CustomInputsModule,
    DialogModule,
    ProgressBarModule,
    CapsaDialogsModule,
  ],
  exports: [
    EditUserWrapperComponent,
    ImportUsersComponent,
    UsersBulkAssignComponent,
  ],
})
export class UsersModule {}
