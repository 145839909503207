import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CapsaSettingApiModel } from '@capsa/api';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { DataDrivenTextConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-textbox',
  templateUrl: './data-driven-textbox.component.html',
  styleUrls: ['./data-driven-textbox.component.scss'],
})
export class DataDrivenTextboxComponent implements OnInit {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public config: DataDrivenTextConfig;

  @Output()
  public valueChanged = new EventEmitter<string>();

  @Output()
  public validState = new EventEmitter<boolean>();

  @Input()
  private disabled = false;

  public textFormControl: UntypedFormControl;

  // Some nearly unreasonable max length for when a max length is not passed in
  private readonly HUGE_MAX_LENGTH = 10000;

  public get maxLength() {
    return this.config && this.config.maxLength > 0
      ? this.config.maxLength
      : this.HUGE_MAX_LENGTH;
  }

  public get minLength() {
    return this.config && this.config.minLength > 0 ? this.config.minLength : 0;
  }

  public onValueChanged(_ev: Event) {
    if (!this.textFormControl.valid) {
      this.validState.emit(false);
      this.setting.SettingValue = this.textFormControl.value;
      return;
    }

    this.setting.SettingValue = this.textFormControl.value;
    this.validState.emit(true);
    this.valueChanged.emit(this.setting.SettingValue);
  }

  constructor() {}

  ngOnInit() {
    const validators = [];

    if (this.config) {
      if (this.config.required) {
        validators.push(Validators.required);
      }

      if (this.config.minLength > 0) {
        validators.push(Validators.minLength(this.config.minLength));
      }

      if (this.config.maxLength > 0) {
        validators.push(Validators.maxLength(this.config.maxLength));
      }

      if (!this.config.allowNullOrWhiteSpace) {
        validators.push(NsightCustomValidators.dontAllowOnlyWhiteSpace());
      }
    }

    this.textFormControl = new UntypedFormControl(this.setting.SettingValue, {
      validators,
    });

    this.disabled
      ? this.textFormControl.disable()
      : this.textFormControl.enable();
  }
}
