import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { FacilitySupportDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-support-drop-down-data-source';
import { OrganizationSupportDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-support-drop-down-data-source';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { EofInfo } from 'app/modules/support/support-transfer-carts/eof-transfer-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-transfer-cart-org',
  templateUrl: './support-transfer-cart-org.component.html',
  styleUrls: ['./support-transfer-cart-org.component.scss'],
})
export class SupportTransferCartOrgComponent implements OnInit, OnDestroy {
  @Input()
  sourceOrgId: number;

  @Input()
  sourceFacilityId: number;

  @Input()
  areCartsSelected: boolean;

  @Output()
  transferTriggered = new EventEmitter<EofInfo>();

  public sourceEnterpriseId: number | undefined;
  public targetOrgId: number | undefined;
  public targetFacilityId: number | undefined;
  private subs = new Subscription();

  constructor(
    public orgDropDownDataSource: OrganizationSupportDropDownDataSource,
    public facilityDropDownDataSource: FacilitySupportDropDownDataSource,
    public enterpriseService: EnterpriseService
  ) {}

  ngOnInit() {
    this.sourceEnterpriseId = this.enterpriseService.enterpriseId;
    this.loadOrganizations();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadOrganizations() {
    this.orgDropDownDataSource.enterpriseId = this.sourceEnterpriseId;
    this.subs.add(
      this.orgDropDownDataSource.resetAndLoad().subscribe((data) => {
        this.orgDropDownDataSource.data = data;
        const selectedId = parseInt(
          this.orgDropDownDataSource.selection.id,
          10
        );
        if (selectedId > 0) {
          this.targetOrgId = selectedId;
          this.loadFacilities();
        }
      })
    );
  }

  public targetOrgChanged(ddValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    this.targetFacilityId = undefined;
    this.targetOrgId = ddValue.apiModel ? ddValue.apiModel.Id : undefined;
    if (this.targetOrgId) {
      this.loadFacilities();
    }
  }

  private loadFacilities() {
    this.facilityDropDownDataSource.enterpriseId = this.sourceEnterpriseId;
    this.facilityDropDownDataSource.organizationId = this.targetOrgId;
    this.subs.add(
      this.facilityDropDownDataSource.resetAndLoad().subscribe((data) => {
        this.facilityDropDownDataSource.data = data;
        const selectedId = parseInt(
          this.facilityDropDownDataSource.selection.id,
          10
        );
        if (selectedId > 0) {
          this.targetFacilityId = selectedId;
        }
      })
    );
  }

  public targetFacilityChanged(
    newValue: AsyncDropDownValue<NameWithLongIdApiModel>
  ) {
    this.targetFacilityId = newValue.apiModel
      ? newValue.apiModel.Id
      : undefined;
  }

  public onBtnStartCartTransfer() {
    // If somehow button was clicked when it wasn't supposed to - stop things!
    if (
      !this.sourceEnterpriseId ||
      !this.sourceOrgId ||
      !this.sourceFacilityId ||
      !this.targetOrgId ||
      !this.targetFacilityId ||
      !this.areCartsSelected
    ) {
      return;
    }
    this.transferTriggered.emit({
      EnterpriseId: this.sourceEnterpriseId,
      OrgId: this.targetOrgId,
      FacilityId: this.targetFacilityId,
    });
  }

  public get disableTransferButton(): boolean {
    return (
      !this.sourceEnterpriseId ||
      !this.sourceOrgId ||
      !this.sourceFacilityId ||
      !this.targetOrgId ||
      !this.targetFacilityId ||
      !this.areCartsSelected ||
      // don't allow attempting to transfer to the same facility
      this.targetFacilityId === this.sourceFacilityId
    );
  }
}
