import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { DataDrivenNumberConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-number',
  templateUrl: './data-driven-number.component.html',
  styleUrls: ['./data-driven-number.component.scss'],
})
export class DataDrivenNumberComponent implements OnInit {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public config: DataDrivenNumberConfig;

  @Input()
  public disabled = false;

  @Output()
  public validState = new EventEmitter<boolean>();

  public get value(): number {
    return Number.parseInt(this.setting.SettingValue, 10);
  }

  public get settingMin(): number {
    if (this.config && !isNaN(this.config.min)) {
      return this.config.min;
    }

    return this.setting.MinValue;
  }

  public isValid = true;

  public get settingMax(): number {
    if (this.config && !isNaN(this.config.max)) {
      return this.config.max;
    }

    return this.setting.MaxValue;
  }

  public get step(): number {
    return this.config && this.config.step ? this.config.step : 1;
  }

  public get autoCorrect(): boolean {
    return this.config && typeof this.config.autoCorrect === 'boolean'
      ? this.config.autoCorrect
      : true;
  }

  public get decimals(): number {
    return this.config &&
      this.config.decimals !== null &&
      this.config.decimals !== undefined
      ? this.config.decimals
      : 0;
  }

  constructor() {}

  ngOnInit() {
    // Trigger validation by faking an "onchange when value loads"
    // in "setTimeout" to properly trigger change detection
    setTimeout(() => {
      this.onValueChanged(this.value);
    }, 0);
  }

  public onValueChanged(value: number) {
    if (value === null || isNaN(value)) {
      // don't save null number...
      this.validState.emit(false);
      this.isValid = false;

      // Go ahead and update the setting (we'll prevent user from saving till this is fixed
      // otherwise switching settings tabs and coming back to this element, will cause a
      // revert back to the last valid value... which might be doing more for the user
      // than needed and also complicates resetting the valid state of the control)
      this.setting.SettingValue = null;
      return;
    }

    const intToSave = parseInt(value.toString(), 10);

    this.setting.SettingValue = intToSave.toString();
    this.isValid = true;
    this.validState.emit(true);
  }
}
