import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  NameWithLongIdApiModel,
  OrganizationResponse,
  OrganizationSearchRequest,
  PagingRequestDto,
} from '..';
import { BaseApi } from '../base/base.api';

export interface OrganizationApiInstance {
  getDropdown(): Observable<NameWithLongIdApiModel[]>;
}

@Injectable()
export class OrganizationApi
  extends BaseApi
  implements OrganizationApiInstance
{
  protected endpoints = {
    dropDown: this.buildUrl('organization/GetUserOrganizationsDropDown'),
    search: this.buildUrl('organization/search'),
  };

  /**
   *
   */
  constructor(
    http: HttpClient,
    private enterpiseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(http, appSettingService);
  }

  /**
   * Gets a list of all the organizations meeting the search criteria
   */
  public getDropdown(): Observable<NameWithLongIdApiModel[]> {
    const paging: PagingRequestDto = {
      PageNumber: 1,
      PageSize: 100,
    };

    const url = `${this.endpoints.dropDown}?${this.buildPagingParams(paging)}`;
    return this.http
      .get<ApiResponse<NameWithLongIdApiModel[]>>(url)
      .pipe(map((resp) => resp.Result || []));
  }

  public search(
    search: Partial<OrganizationSearchRequest>
  ): Observable<OrganizationResponse[]> {
    const payload = {
      ...search,
      EnterpriseId: this.enterpiseService.enterpriseId,
      PageNumber: 1,
      PageSize: 1000,
    };

    const url = `${this.endpoints.search}`;
    return this.http
      .post<ApiResponse<OrganizationResponse[]>>(url, payload)
      .pipe(map((resp) => resp.Result || []));
  }
}
