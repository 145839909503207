import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';

@Pipe({
  name: 'hasPerm',
})
export class PermissionsPipe implements PipeTransform {
  constructor(private permissionService: PermissionService) {}
  transform(value: any, ...args: any[]): boolean {
    if (value instanceof Array) {
      return value.some((p) => {
        if (!this.isValidPermission(p)) {
          throw new Error(
            `PermissionsPipe error. "${p}" is not a recognized permission.`
          );
        }
        return this.permissionService.has(Permissions[p as string]);
      });
    } else if (typeof value === 'string') {
      if (!this.isValidPermission(value)) {
        throw new Error(
          `PermissionsPipe error. "${value}" is not a recognized permission.`
        );
      }

      return this.permissionService.has(Permissions[value as string]);
    } else {
      throw new Error(
        `PermissionsPipe: "${value}" if of an unrecognized type. Expected string or array of strings`
      );
    }
  }

  private isValidPermission(p: string): boolean {
    return Permissions[p] ? true : false;
  }
}
