import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
})
export class NumberRangeComponent implements OnInit {
  get valueAsNumber() {
    return Number(this.value);
  }
  get minValueAsNumber() {
    if (this.minValue !== undefined) {
      return Number(this.minValue);
    }

    return undefined;
  }

  constructor() {}

  public ngOnInit() {
    this.lastValidInput = Number(this.value);
  }

  @Input()
  public title: string;

  @Input()
  public isReadonly = false;

  @Input()
  public value: string;

  @Input()
  public minValue: string | undefined;

  @Output()
  public valueChanged = new EventEmitter<number>();

  public onChange(value: number) {
    if (value !== null && !isNaN(value)) {
      this.lastValidInput = value;
    }
  }

  public onBlur() {
    // This is to trigger change detection in case the input value is cleared
    // when followed by the setTimeout below, this will trigger change detection
    // to actually update the value in the input box to the lastValidInput
    // in case the input box was empty
    this.value = '-1';

    this.valueChanged.emit(this.lastValidInput);

    // See note above
    // If you step through this cade, you'll see that even if you clear the
    // input box, the value will still be a valid one, but the input
    // box will still be empty, it doesn't auto-refresh it
    // So if we try to do a this.value = this.lastValidInput, the change
    // detection doesn't detect the change, and doesn't refresh the view
    // i.e. the textbox remains empty
    setTimeout(() => {
      this.value = this.lastValidInput.toString();
    }, 0);
  }

  private lastValidInput: number;
}
