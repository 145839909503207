// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

.chartTooltip {
  text-align: center;
}
.chartTooltip > span {
  display: inline-block;
  margin-top: 7px;
  font-weight: bold;
}

kendo-chart {
  flex: 1 1;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/analytics/analytics-line-chart/analytics-line-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,qBAAA;EACA,eAAA;EACA,iBAAA;AAEJ;;AAEA;EACE,SAAA;EACA,YAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  align-items: stretch;\n  overflow: hidden;\n}\n\n.chartTooltip {\n  text-align: center;\n  & > span {\n    display: inline-block;\n    margin-top: 7px;\n    font-weight: bold;\n  }\n}\n\nkendo-chart {\n  flex: 1 1;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
