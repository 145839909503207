import { ChartEntityType } from '@capsa/api';

export interface EntityItem {
  Id: string; // Can be number or GUID (user ID's)
  Name: string;
  SerialNumber: string;
}

export interface EntityGroupItem extends EntityItem {
  MemberIds: string[];

  // Should this be partially selected?
  Indeterminate?: boolean;
}

export interface DialogSelections {
  entityIds: string[];
  entityGroupIds: string[];
}

export enum ChartType {
  bar,
  line,
}

/**
 *
 * Used to tell "color algorithm" how to apply colors, so that each entity
 * will have a single unique color
 * If this is incorrectly used, it could result in multiple colors
 * used to represent the same entity
 */
export enum AnalyticsDataStructureType {
  // Apply colors in "regular" mode
  regular,

  /** A bar will represent an entity, with subcategories stacked
   *  e.g. Charging mode - All. A bar is rendered for each entity for each date/time
   * the bar is broken up into different subcategories of that entity
   */
  stackedPerEntity,

  /** A bar will represent an entity (Access Point), with another type of
   *  entity stacked to make up the bar.
   *  e.g. Connectivity by access point - A bar is rendered for each AP, for each
   *  date (i.e. each provided date)
   *  the bar consists of stacked carts (amount of time each cart was connected
   *  to that access point during that time period)
   */
  stackedEntityBarStacksSubEntities,

  /** A bar will represent a day, with stacked items representing entities
   * e.g. total PIN entries for November 1st are 100, so a single bar, 100 units tall is
   * rendered for that date, with it consisting of multicolored stacked
   * bar "chunks" that each represent entities and how many PIN entries
   * occured for that entity
   */
  stackedPerDateTime,
}

/**
 * Used to track query specific properties in one place, to
 * make switch statements smaller
 */
export interface AnalyticsQueryProperties {
  chartTileTag: string;
  allowedEntityTypes: ChartEntityType[];
  chartType: ChartType;
  dataStructureType: AnalyticsDataStructureType;
  hideSubcategory?: boolean;
}
