import { Injectable } from '@angular/core';
import { MessageTemplateResponse } from '@capsa/api';
import { MessageTemplateApi } from '@capsa/api/message-template/message-template.api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MessageTemplateDropDownDataSource extends AbstractDropDownListDataSource<MessageTemplateResponse> {
  constructor(
    private messageTemplateApi: MessageTemplateApi,
    private translations: TranslateService
  ) {
    super();
  }

  get defaultItem(): AsyncDropDownValue<MessageTemplateResponse> {
    return {
      name: this.translations.instant('COM_SELECT_A_VALUE'),
      id: '-1',
      apiModel: undefined,
    };
  }

  public organizationId: number;
  public facilityId: number | undefined;
  public template: string;

  protected callApi(): Observable<MessageTemplateResponse[]> {
    return this.messageTemplateApi
      .searchTemplate({
        OrganizationId: this.organizationId,
        FacilityId: this.facilityId,
        PageNumber: 1,
        PageSize: 100,
      })
      .pipe(map((resp) => resp.Result || []));
  }

  protected map(
    apiModel: MessageTemplateResponse
  ): AsyncDropDownValue<MessageTemplateResponse> {
    return {
      id: apiModel.MessageQueueTemplateId.toString(),
      name: apiModel.Name,
      apiModel,
    };
  }
  protected select(
    items: AsyncDropDownValue<MessageTemplateResponse>[]
  ): AsyncDropDownValue<MessageTemplateResponse> {
    return items.find((i) => i.apiModel.Name === this.template);
  }
}
