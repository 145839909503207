import { Component, OnDestroy, OnInit } from '@angular/core';
import { CapsaRoleResponseDto } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { CapsaRoleMultiSelectDataSource } from '@capsa/dropdowns/capsa-role-multi-select/capsa-role-multi-select-data-source';
import { Subject, Subscription } from 'rxjs';

import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { List as ImmutableList } from 'immutable';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-permissions-tab',
  templateUrl: './edit-user-permissions-tab.component.html',
  styleUrls: ['./edit-user-permissions-tab.component.scss'],
  providers: [CapsaRoleMultiSelectDataSource],
})
export class EditUserPermissionsTabComponent implements OnInit, OnDestroy {
  private allAssignedRoleIds: ImmutableList<number>;
  private displayedRoleIds: ImmutableList<number>;

  private forceValidateSubj = new Subject<void>();

  public forceValidate$ = this.forceValidateSubj.asObservable();

  private subs = new Subscription();

  constructor(
    public capsaRoleDataSource: CapsaRoleMultiSelectDataSource,
    private editUserService: EditUserService
  ) {}

  ngOnInit() {
    this.refreshAllAssignedRoles();
    this.refreshRoles();

    this.subs.add(
      this.editUserService.selectedFacilityChanged$
        .pipe(filter((facilityId) => facilityId !== -1))
        .subscribe((facilityId) => {
          this.refreshAllAssignedRoles();
          this.refreshRoles();
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private refreshAllAssignedRoles() {
    this.allAssignedRoleIds = ImmutableList(
      this.editUserService.updatedUser.get('RoleIds')
    );
  }

  private refreshRoles() {
    this.capsaRoleDataSource.changeFacilityId(
      this.editUserService.selectedFacilityId
    );

    this.subs.add(
      this.capsaRoleDataSource.hiddenRoleIds$.subscribe((hiddenRoleIdsList) => {
        this.displayedRoleIds = this.allAssignedRoleIds.filter(
          (asRoleId) => !hiddenRoleIdsList.includes(asRoleId)
        );
        this.capsaRoleDataSource.roleIds = this.displayedRoleIds.toArray();

        this.subs.add(
          this.capsaRoleDataSource.load().subscribe(() => {
            this.forceValidateSubj.next();
          })
        );
      })
    );
  }

  public onCapsaRolesChanged(
    roles: AsyncDropDownValue<CapsaRoleResponseDto>[]
  ) {
    this.displayedRoleIds = ImmutableList(
      roles.map((r) => r.apiModel.CapsaRoleId)
    );

    this.subs.add(
      this.capsaRoleDataSource.hiddenRoleIds$.subscribe((hiddenRoles) => {
        this.editUserService.updateUserRoles(
          this.allAssignedRoleIds
            // not sure what this was supposed to be doing
            .filter((x) => hiddenRoles.includes(x))
            .toArray()
            .concat(this.displayedRoleIds.toArray())
        );
      })
    );
  }
}
