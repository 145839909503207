import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  CapsaSettingApiModel,
  FacilitySettingsRequest,
  GetDeviceProfileSettingsRequestApiModel,
  GetUserFacilitySettingsRequest,
  SettingResponse,
  throwIfBadRespFullResp,
} from '..';
import { BaseApi } from '../base/base.api';

export interface SettingsApiInstance {
  getFacilitySettings(
    facilityId: number
  ): Observable<ApiResponse<SettingResponse[]>>;

  getUserFacilitySettings(
    request: GetUserFacilitySettingsRequest
  ): Observable<ApiResponse<CapsaSettingApiModel[]>>;

  upsertUserFacilitySettings(
    userId: string,
    facilityId: number,
    settings: CapsaSettingApiModel[]
  ): Observable<ApiResponse<CapsaSettingApiModel[]>>;

  getDeviceTypeSettings(
    deviceTypeId: number
  ): Observable<ApiResponse<CapsaSettingApiModel[]>>;
}

@Injectable()
export class SettingsApi extends BaseApi implements SettingsApiInstance {
  protected endpoints = {
    getDeviceTypeSettings: this.buildUrl('setting/getdevicetypesettings'),
    getFacilitySettings: this.buildUrl('setting/getfacilitysettings'),
    getUserFacilitySettings: this.buildUrl(
      'facility/{facilityId}/user/{userId}/settings'
    ),
  };

  private enterpriseId: number;

  /**
   *
   */
  constructor(
    client: HttpClient,
    enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
    this.enterpriseId = enterpriseService.enterpriseId;
  }

  public getDeviceTypeSettings(
    deviceTypeId: number
  ): Observable<ApiResponse<CapsaSettingApiModel[]>> {
    const request: GetDeviceProfileSettingsRequestApiModel = {
      DeviceTypeId: deviceTypeId,
    };

    return this.http
      .post<ApiResponse<CapsaSettingApiModel[]>>(
        this.endpoints.getDeviceTypeSettings,
        request
      )
      .pipe(throwIfBadRespFullResp());
  }

  public getFacilitySettings(
    facilityId: number
  ): Observable<ApiResponse<SettingResponse[]>> {
    const request: FacilitySettingsRequest = {
      FacilityId: facilityId,
      EnterpriseId: this.enterpriseId,
      ProductLine: 'CareLink',
    };

    return this.http.post<ApiResponse<SettingResponse[]>>(
      this.endpoints.getFacilitySettings,
      request
    );
  }

  public getUserFacilitySettings(
    request: GetUserFacilitySettingsRequest
  ): Observable<ApiResponse<CapsaSettingApiModel[]>> {
    const urlParameters: Partial<GetUserFacilitySettingsRequest> = {
      DeviceType: request.DeviceType,
    };

    const resourceString = this.endpoints.getUserFacilitySettings
      .replace('{userId}', request.CapsaUserId)
      .replace('{facilityId}', request.FacilityId.toString());

    const paramString = this.buildParamString(urlParameters);
    const url = `${resourceString}?${paramString}`;

    return this.http
      .get<ApiResponse<CapsaSettingApiModel[]>>(url)
      .pipe(throwIfBadRespFullResp());
  }

  public upsertUserFacilitySettings(
    userId: string,
    facilityId: number,
    settings: CapsaSettingApiModel[]
  ): Observable<ApiResponse<CapsaSettingApiModel[]>> {
    const resourceString = this.endpoints.getUserFacilitySettings
      .replace('{userId}', userId)
      .replace('{facilityId}', facilityId.toString());
    return this.http
      .post<ApiResponse<CapsaSettingApiModel[]>>(resourceString, settings)
      .pipe(throwIfBadRespFullResp());
  }
}
