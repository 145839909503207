import { Component, Inject, OnInit } from '@angular/core';
import {
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
  DataDrivenSettingDataType,
} from '@capsa/api';
import { CartManagementService } from 'app/modules/cart-management/cart-management-single/cart-management.service';

@Component({
  selector: 'app-avalo-cart-management-general-tab',
  templateUrl: './avalo-cart-management-general-tab.component.html',
  styleUrls: ['./avalo-cart-management-general-tab.component.scss'],
})
export class AvaloCartManagementGeneralTabComponent implements OnInit {
  public nameSetting: Partial<CapsaSettingApiModel>;
  public settingTypeEnum = CapsaSettingsTypeIds;
  public settings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();

  // Key is "setting identifier", can be string, or enum value
  public validatedSettings = new Map<any, boolean>([
    [CapsaSettingsTypeIds.AVO_AutoSecureTime, true],
  ]);

  constructor(
    @Inject('device')
    public device: CartResponse,
    private cartManagementService: CartManagementService
  ) {}

  ngOnInit() {
    this.nameSetting = {
      SettingValue: this.device.Name,
      DataType: DataDrivenSettingDataType.String,
      NameTranslationTag: 'Setting_CLI_CartName',
      InfoTranslationTag: 'Setting_CLI_CartName_Info',
    };

    this.loadSettings();
  }

  private loadSettings() {
    const settingsToUpdate = [
      CapsaSettingsTypeIds.AVO_NumberOfDrawers,
      CapsaSettingsTypeIds.AVO_MotionDetectSensitivity,
      CapsaSettingsTypeIds.AVO_LightTimeoutMinutes,
      CapsaSettingsTypeIds.AVO_UserCodeAlwaysRequired,
      CapsaSettingsTypeIds.AVO_AutoSecureTime,
    ];

    this.device.Settings.forEach((s) => {
      if (settingsToUpdate.some((x) => x === s.SettingType)) {
        this.settings.set(s.SettingType, s);
      }
    });
  }

  public onNameChange(newValue: string) {
    this.device.Name = newValue.trim();
  }

  public validStateUpdated(isValid: boolean, setting: any) {
    this.validatedSettings.set(setting, isValid);

    let allValid = true;

    this.validatedSettings.forEach((isSettingValid) => {
      if (!isSettingValid) {
        allValid = false;
        return;
      }
    });

    this.cartManagementService.updateGeneralSettingsValidation(allValid);
  }
}
