import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  FacilityUpdateRequest,
  ImmutabeMessageQueueRequestTypeResponse,
  MessageQueueRequestTypeResponse,
  NotificationGroupResponse,
  NotificationGroupUpdateRequest,
  NotificationMemberCreateRequest,
  NotificationType,
  SettingResponse,
} from '@capsa/api';
import { MessageRequestTypeApi } from '@capsa/api/message-request-type';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogCloseResult,
  DialogRef,
  DialogResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { AppComponent } from 'app/app.component';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { Record } from 'immutable';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-service-requests',
  templateUrl: './facility-manage-service-requests.component.html',
  styleUrls: ['./facility-manage-service-requests.component.scss'],
})
export class FacilityManageServiceRequestsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  @Input()
  orgId: number;

  @Input()
  facilityId: number;

  @Input()
  public notificationGroups: NotificationGroupResponse[];

  @Input()
  facilitySaving$: Observable<FacilityUpdateRequest>;

  @Output()
  public formValidStatus = new EventEmitter<boolean>();

  public data: MessageQueueRequestTypeResponse[] = [];
  public loadingData = true;
  private idToDelete: number;

  private subs: Subscription = new Subscription();
  public serviceRequestEmailForm: UntypedFormGroup;
  private serviceRequestEmailFormControl: UntypedFormControl;

  public settingTypeEnum = CapsaSettingsTypeIds;
  private editedRowIndex: number;

  /**
   * Temporarily holds an unmodified "dataItem" while the actual dataItem is edited by user
   * If the user cancels, we can restore this clean object
   */
  private pristineRequestType: Record<MessageQueueRequestTypeResponse>;

  constructor(
    private appRef: ApplicationRef,
    private messageRequestTypeApi: MessageRequestTypeApi,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.refreshRequestTypes();

    this.subs.add(
      this.facilitySaving$.subscribe((request) => {
        if (!request) {
          return;
        }

        request.NotificationGroups = request.NotificationGroups.concat(
          this.generateServiceRequestNotificationGroup()
        );
      })
    );

    this.serviceRequestEmailFormControl = new UntypedFormControl(
      this.settings.get(
        CapsaSettingsTypeIds.CLI_ServiceRequestEmailAddress
      ).SettingValue,
      Validators.compose([
        Validators.email,
        Validators.maxLength(256),
        NsightCustomValidators.isEmailExtraChecksValid(),
      ])
    );

    this.serviceRequestEmailForm = new UntypedFormGroup({
      serviceRequestEmailFormControl: this.serviceRequestEmailFormControl,
    });

    this.subs.add(
      this.serviceRequestEmailFormControl.statusChanges.subscribe(() => {
        this.formValidStatus.emit(this.serviceRequestEmailFormControl.valid);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityId && !changes.facilityId.isFirstChange()) {
      this.refreshRequestTypes();
    }
  }

  private generateServiceRequestNotificationGroup(): NotificationGroupUpdateRequest[] {
    const ngServiceRequestResponse = this.notificationGroups.find(
      (ng) => ng.NotificationType === NotificationType.CLI_ServiceRequest
    );

    let currentInputEmail = this.serviceRequestEmailFormControl.value as string;

    if (currentInputEmail === undefined || currentInputEmail === null) {
      currentInputEmail = '';
    } else {
      currentInputEmail = currentInputEmail.trim();
    }

    // saving the email to capsa settings for backwards compatibility
    // (since this is where it's also pulled from)
    const srEmailSetting = this.settings.get(
      CapsaSettingsTypeIds.CLI_ServiceRequestEmailAddress
    );

    srEmailSetting.SettingValue = currentInputEmail;

    if (currentInputEmail.length === 0) {
      return [];
    }

    // check if notification group of this type already exists
    if (ngServiceRequestResponse) {
      return [
        this.convertSRResponseToUpdateRequest(
          ngServiceRequestResponse,
          currentInputEmail
        ),
      ];
    }

    return [
      {
        Description: 'Trio Service Request Email',
        OrganizationId: this.orgId,
        FacilityId: this.facilityId,
        // This is how we tell the API to create a new NG
        NotificationGroupId: 0,
        Members: [
          {
            Email: currentInputEmail,
          } as NotificationMemberCreateRequest,
        ],
        NotificationType: NotificationType.CLI_ServiceRequest,
      } as NotificationGroupUpdateRequest,
    ];
  }

  private convertSRResponseToUpdateRequest(
    resp: NotificationGroupResponse,
    upsertEmail: string
  ): NotificationGroupUpdateRequest {
    const updateRequest: NotificationGroupUpdateRequest = {
      ...resp,
      Members: [
        {
          CapsaUserId: null,
          Description: null,
          Email: upsertEmail,
          EnterpriseId: resp.EnterpriseId,
          OrganizationId: resp.OrganizationId,
          FacilityId: resp.FacilityId,
        },
      ],
    };
    return updateRequest;
  }

  private refreshRequestTypes(): void {
    this.loadingData = true;
    const sub = this.messageRequestTypeApi
      .searchRequestTypes(this.orgId, this.facilityId)
      .subscribe(
        (resp) => {
          this.data = resp.Result;
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;
        }
      );

    this.subs.add(sub);
  }

  public addHandler({ sender }, formInstance) {
    formInstance.reset();
    this.closeEditor(sender);

    const newRow: MessageQueueRequestTypeResponse = {
      Description: '',
      AcknowledgementThreshold: 15,
      MessageQueueRequestTypeId: undefined,
      EnterpriseId: undefined,
      OrganizationId: undefined,
      FacilityId: undefined,
      DateModifiedUtc: undefined,
    };

    sender.addRow(newRow);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.pristineRequestType =
      ImmutabeMessageQueueRequestTypeResponse(dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({
    sender,
    rowIndex,
    dataItem,
    isNew,
  }: {
    sender: any;
    rowIndex: number;
    dataItem: MessageQueueRequestTypeResponse;
    isNew: boolean;
  }) {
    this.loadingData = true;
    if (isNew) {
      const sub = this.messageRequestTypeApi
        .createRequestType(
          this.orgId,
          this.facilityId,
          dataItem.Description,
          dataItem.AcknowledgementThreshold
        )
        .subscribe(
          (resp) => {
            this.refreshRequestTypes();
          },
          (error) => {
            this.toasterService.showError('CREATE_FAILED');
            this.loadingData = false;
          }
        );

      this.subs.add(sub);
    } else {
      const sub = this.messageRequestTypeApi
        .updateRequestType(
          dataItem.MessageQueueRequestTypeId,
          dataItem.Description,
          dataItem.AcknowledgementThreshold
        )
        .subscribe(
          (resp) => {
            this.refreshRequestTypes();
          },
          (error) => {
            this.toasterService.showError('UPDATE_FAILED');
            this.loadingData = false;
          }
        );

      this.subs.add(sub);
    }

    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.pristineRequestType = undefined;
  }

  public removeHandler(
    {
      dataItem,
    }: {
      dataItem: MessageQueueRequestTypeResponse;
    },
    template: TemplateRef<unknown>
  ) {
    this.idToDelete = dataItem.MessageQueueRequestTypeId;
    this.openDeleteConfirmDialog(template);
  }

  private openDeleteConfirmDialog(template: TemplateRef<unknown>) {
    const appComp = this.appRef.components[0]
      .instance as unknown as AppComponent;

    const dialog = this.dialogService.open({
      // Append the dialog to near the root DOM element, otherwise z-indexing issues will cause other elements to overlap this dialog
      appendTo: appComp.vcrRootAllContent,
      cssClass: 'dialogWarn',
      title: this.translateService.instant('DELETE_REQUEST_TYPE_TITLE'),
      minWidth: 250,
      width: 560,
      actions: [
        {
          text: this.translateService.instant('COM_NO'),
        },
        { text: this.translateService.instant('COM_YES') },
      ],
      content: template,
    });

    this.subs.add(
      dialog.result.subscribe((result) => {
        this.dialogConfirmDelete(result, dialog);
      })
    );
  }

  private restoreBackedUpItem() {
    if (!this.pristineRequestType) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.data.find(
      (item) =>
        item.MessageQueueRequestTypeId ===
        this.pristineRequestType.get('MessageQueueRequestTypeId')
    );

    // revert changes
    Object.assign(originalDataItem, this.pristineRequestType.toObject());
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);

    this.restoreBackedUpItem();

    this.editedRowIndex = undefined;
    this.pristineRequestType = undefined;
  }

  public dialogConfirmDelete(result: DialogResult, dialogRef: DialogRef) {
    if (
      result instanceof DialogCloseResult ||
      result.text === this.translateService.instant('COM_NO')
    ) {
      dialogRef.close();
      this.idToDelete = undefined;
    } else {
      this.loadingData = true;
      dialogRef.close();
      const sub = this.messageRequestTypeApi
        .deleteRequestType(this.idToDelete)
        .subscribe(
          (resp) => {
            this.refreshRequestTypes();
          },
          (error) => {
            this.toasterService.showError('DELETE_FAILED');
            this.loadingData = false;
          }
        );
      this.subs.add(sub);
    }
  }
}
