import { Component, Inject, OnInit } from '@angular/core';
import {
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
} from '@capsa/api';

@Component({
  selector: 'app-avalo-cart-management-sounds-tab',
  templateUrl: './avalo-cart-management-sounds-tab.component.html',
  styleUrls: ['./avalo-cart-management-sounds-tab.component.scss'],
})
export class AvaloCartManagementSoundsTabComponent implements OnInit {
  public settings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();
  public settingTypeEnum = CapsaSettingsTypeIds;

  public get numDrawers() {
    const numDrawersStr = this.settings.get(
      CapsaSettingsTypeIds.AVO_NumberOfDrawers
    ).SettingValue;
    return parseInt(numDrawersStr, 10);
  }

  constructor(
    @Inject('device')
    public device: CartResponse
  ) {}

  ngOnInit() {
    const soundSettings = new Set([
      CapsaSettingsTypeIds.AVO_KeyBeep,
      CapsaSettingsTypeIds.AVO_Narc1AlarmEnable,
      CapsaSettingsTypeIds.AVO_Narc2AlarmEnable,
      CapsaSettingsTypeIds.AVO_AlarmEnable,
      CapsaSettingsTypeIds.AVO_AlarmVolume,

      // We pull this to check the setting value to enable/disable other settings
      CapsaSettingsTypeIds.AVO_NumberOfDrawers,
    ]);

    this.device.Settings.forEach((s) => {
      if (soundSettings.has(s.SettingType)) {
        this.settings.set(s.SettingType, s);
      }
    });
  }
}
