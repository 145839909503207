import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CapsaSettingsTypeIds,
  CartBulkAddUsersRequest,
  CartGroupResponse,
  CartPartialResponse,
  UserPartialResponse,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { CartGroupApi } from '@capsa/api/cart-group';
import { UserApi } from '@capsa/api/user';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  State,
  process,
} from '@progress/kendo-data-query';
import { Utils } from 'app/common/utils';
import {
  GridCartSelectionState,
  GridUserSelectionState,
} from 'app/modules/capsa-dialogs/capsa-dialogs-interfaces';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-users-bulk-assign',
  templateUrl: './users-bulk-assign.component.html',
  styleUrls: ['./users-bulk-assign.component.scss'],
})
export class UsersBulkAssignComponent implements OnInit, OnDestroy {
  public cartFilter: CompositeFilterDescriptor;
  public cartSort: SortDescriptor[];
  public canEdit = false;

  public userFilter: CompositeFilterDescriptor;
  public userSort: SortDescriptor[];

  public deviceTypeId: number | undefined;

  public cartsAvailable: CartPartialResponse[] = [];
  public cartsSelected: CartPartialResponse[] = [];

  public cartGroups: CartGroupResponse[];

  public cartDataLoading = false;

  public usersAvailable: UserPartialResponse[] = [];
  public usersSelected: UserPartialResponse[] = [];

  public usersLoading = false;

  public selectedCartsGridData: GridDataResult;
  public selectedUsersGridData: GridDataResult;

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Users_Add];

  public isCartsDialogOpen = false;
  public isUsersDialogOpen = false;

  public readonly pageSize = 250;

  public selectedCartsState: State = Utils.getDefaultState(this.pageSize);

  public selectedUsersState: State = Utils.getDefaultState(this.pageSize);

  get isFormValid() {
    return (
      this.cartsSelected &&
      this.cartsSelected.length > 0 &&
      this.usersSelected &&
      this.usersSelected.length > 0
    );
  }

  private refreshCartsSubj = new Subject();
  private refreshCarts$ = this.refreshCartsSubj.asObservable();

  private subs = new Subscription();
  private cartsSub = new Subscription();
  private usersSub = new Subscription();

  constructor(
    private cartApi: CartApi,
    private userApi: UserApi,
    public permissionService: PermissionService,
    public loaderService: LoaderService,
    private toasterService: ToasterService,
    private cartGroupApi: CartGroupApi,
    private enterpriseService: EnterpriseService
  ) {}

  public ngOnInit() {
    this.loadPermissions();

    this.subs.add(
      this.refreshCarts$.subscribe(() => {
        this.refreshLists();
      })
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    this.cartsSub.unsubscribe();
    this.usersSub.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.Manage_Users_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(editPerm);

          this.clearLists();
          this.refreshCartsSubj.next();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.canEdit = this.permissionService.has(editPerm);
  }

  public onOrgChanged(orgId: number) {
    // Here just to make sure the "org drop down" displays
  }

  public onFacilityChanged(facId: number) {
    // Here just to make sure the "facility drop down" displays
  }

  public onDeviceTypeChanged(newvalue: number | undefined) {
    this.deviceTypeId = newvalue;
    this.clearLists();
    this.refreshCartsSubj.next();
  }

  // Applies a class to each Grid row to aid overriding CSS
  public rowCallback(context: RowClassArgs) {
    return { gridRowOverride: true };
  }

  private refreshLists() {
    this.refreshCartList();
    this.refreshUserList();
  }

  private clearLists() {
    this.cartGroups = [];
    this.cartsSelected = [];
    this.cartsAvailable = [];
    this.usersAvailable = [];
    this.usersSelected = [];
    this.reprocessCarts();
    this.reprocessUsers();
  }

  private refreshCartList() {
    this.cartsSub.unsubscribe();

    // Clears selection and refresh grid
    this.cartsSelected = [];
    this.reprocessCarts();

    if (!this.permissionService.facilityId || !this.deviceTypeId) {
      return;
    }

    this.cartDataLoading = true;
    this.cartsSub = forkJoin([this.getCarts(), this.getCartGroups()])
      .pipe(
        finalize(() => {
          this.cartDataLoading = false;
        })
      )
      .subscribe(
        (responses) => {
          this.cartsAvailable = responses[0];

          // Refresh grid with latest data
          this.reprocessCarts();

          this.cartGroups = responses[1].Result;
        },
        (_error) => {
          this.toasterService.showError(
            'ERR_GETTING_LISTS_OF_CARTS_AND_GROUPS'
          );
        }
      );
  }

  private getCarts() {
    return this.cartApi.searchCartsPartial(
      { PageNumber: 1, PageSize: 20000 },
      {
        OrganizationIds: [this.permissionService.orgId],
        FacilityIds: [this.permissionService.facilityId],
        DeviceTypeId: this.deviceTypeId,
        CapsaSettings: [
          {
            Key: CapsaSettingsTypeIds.CLI_CartIsActive,
            Value: '1',
          },
        ],
      }
    );
  }

  private getCartGroups() {
    return this.cartGroupApi.search({
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationIds: [this.permissionService.orgId],
      FacilityIds: [this.permissionService.facilityId],
      PageNumber: 1,
      PageSize: 10000,
    });
  }

  private refreshUserList() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a "deselect", we want to make sure data doesn't come in
    // after we've went back to an "unselected" filter (prevents race condition bugs)

    this.usersSub.unsubscribe();

    // Clears selection and refresh grid
    this.usersSelected = [];
    this.reprocessUsers();

    if (!this.permissionService.facilityId || !this.deviceTypeId) {
      return;
    }

    this.usersLoading = true;

    this.usersSub = this.userApi
      .getUserList(
        this.permissionService.orgId,
        this.permissionService.facilityId,
        false, // includeInactive
        undefined, // pageSize
        undefined, // pageNumber
        [CapsaSettingsTypeIds.CLI_IsCartUserActive],
        this.deviceTypeId
        // optional filters and sorting below
        // firstName = undefined
        // lastName = undefined
        // userName = undefined
      )
      .pipe(
        finalize(() => {
          this.usersLoading = false;
        })
      )
      .subscribe(
        (resp) => {
          this.usersAvailable = resp.Result.filter((u) => {
            return u.IsDeviceUser;
          }).map((r) => ({
            ...r,
          }));

          // Refresh grid with latest data
          this.reprocessUsers();
        },
        (error) => {
          this.toasterService.showError('USER_LIST_LOAD_FAIL');
        }
      );
  }

  public onSavedCartSelection(selections: GridCartSelectionState) {
    this.cartsAvailable = selections.avail;
    this.cartsSelected = selections.selected;
    this.selectedCartsState = Utils.getDefaultState(this.pageSize);
    this.isCartsDialogOpen = false;
    this.reprocessCarts();
  }

  public onSavedUserSelection(selections: GridUserSelectionState) {
    this.usersAvailable = selections.avail;
    this.usersSelected = selections.selected;
    this.selectedUsersState = Utils.getDefaultState(this.pageSize);
    this.isUsersDialogOpen = false;
    this.reprocessUsers();
  }

  public selectedCartsStateChange(state: DataStateChangeEvent): void {
    this.selectedCartsState = state;
    this.selectedCartsGridData = process(
      this.cartsSelected,
      this.selectedCartsState
    );
  }

  public selectedUsersStateChange(state: DataStateChangeEvent): void {
    this.selectedUsersState = state;
    this.selectedUsersGridData = process(
      this.usersSelected,
      this.selectedUsersState
    );
  }

  public onAssignUsers() {
    if (!this.isFormValid) {
      return;
    }
    const request: CartBulkAddUsersRequest = {
      CartIds: this.cartsSelected.map((x) => x.Id),
      UserIds: this.usersSelected.map((x) => x.Id),
    };
    this.loaderService.start();
    const sub = this.userApi.bulkAssignCarts(request).subscribe(
      (resp) => {
        this.toasterService.showSuccess('ASSIGN_USERS_SUCCESS');
        this.loaderService.stop();
        this.refreshCartList();
        this.refreshUserList();
      },
      (error) => {
        this.toasterService.showError('ASSIGN_USERS_FAIL');
        this.loaderService.stop();
      }
    );
    this.subs.add(sub);
  }

  public openCartsDialog() {
    this.isCartsDialogOpen = true;
  }

  public openUsersDialog() {
    this.isUsersDialogOpen = true;
  }

  /**
   * updates the grid with latest data
   */
  private reprocessCarts() {
    this.selectedCartsGridData = process(
      this.cartsSelected,
      this.selectedCartsState
    );
  }

  /**
   * updates the grid with latest data
   */
  private reprocessUsers() {
    this.selectedUsersGridData = process(
      this.usersSelected,
      this.selectedUsersState
    );
  }
}
