import { Injectable } from '@angular/core';
import { CapsaSettingsTypeIds, ProductLineIds } from '@capsa/api';

@Injectable()
export abstract class Constants {
  public static readonly AppPageTitle = 'N-Sight';
  public static readonly defaultLanguage = 'en-US';
  public static readonly validMacAddressPattern =
    /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
  public static readonly portalProductLine = ProductLineIds.CareLink;
  public static readonly portalContext = 'portal';

  // Limited by Avalo firmware
  public static readonly avoSecondaryPinLength = 4;

  // Not-readonly since a feature flag needs to be able to tweak this
  public static sessionTimeoutMinutes = 20;
  public static readonly oneMinuteInMs = 60000;
  public static readonly filterDelayTime = 1500;

  public static readonly Key_AppVersion = 'AppVersion';
  public static readonly Key_DistBoardPartNumber = 'DistBoardPartNumber';
  public static readonly Key_LockBoardPartNumber = 'LockBoardPartNumber';
  public static readonly Key_TaskBoardPartNumber = 'TaskBoardPartNumber';
  public static readonly Key_BSPVersion = 'BSPVersion';
  public static readonly Key_DistributionBoardApplicationVersion =
    'DistributionBoardApplicationVersion';
  public static readonly Key_TaskLightBoardApplicationVersion =
    'TaskLightBoardApplicationVersion';
  public static readonly Key_LockBoard1ApplicationVersion =
    'LockBoard1ApplicationVersion';
  public static readonly Key_LockBoard2ApplicationVersion =
    'LockBoard2ApplicationVersion';
  public static readonly Key_LockBoard3ApplicationVersion =
    'LockBoard3ApplicationVersion';

  public static readonly CCS_BOARD_PN: string = '900180';

  public static get LocalStorageKeys() {
    return {
      userMemory: 'userMemory',
      lastSelectedDeviceTypeId: 'lastSelectedDeviceTypeId',
    };
  }

  public static get CookieNames() {
    return {
      preferredLanguage: 'capsa-lang',
      chUser: 'ch-user',
    };
  }
  public static get availableLangs() {
    return ['en-US', 'de-DE', 'es-ES', 'fr-CA', 'fr-FR', 'nl-NL'];
  }

  public static get hiddenRoleNames() {
    return ['CLI_DeviceSupervisor', 'CLI_SupportUser'];
  }

  public static get supportedProductLines() {
    return [ProductLineIds.Avalo, ProductLineIds.CareLink];
  }

  public static get pinSettingTypes() {
    return [
      CapsaSettingsTypeIds.CLI_UserPinCode,
      CapsaSettingsTypeIds.AVO_SecondaryPinCode,
    ];
  }
}
