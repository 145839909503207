import { Pipe, PipeTransform } from '@angular/core';
import { Colors } from '@capsa/api';

@Pipe({
  name: 'color',
})
export class ColorPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    return Colors[value];
  }
}
