import { ImmutableUserUpdateRequest, UserResponse } from '@capsa/api';

export function generateImmutableUpdateUserRequest(user: UserResponse) {
  // NOTE: we're passing in a user RESPONSE into something that's really expecting
  // a user REQUEST, and assuming all the properties will be identical
  const record = ImmutableUserUpdateRequest(user);

  const orgList = user.OrganizationList.map((org) => org.Id);
  const facList = user.FacilityList.map((fac) => fac.Id);
  const roleList = user.UserRoleList.map((role) => role.Id);
  const cartGroupList = user.UserCartGroupList.map((gp) => gp.Id);
  const cartList = user.UserCartList.map((gp) => gp.Id);
  const notificationGroupList = user.UserNotificationGroupList.map(
    (gp) => gp.Id
  );

  return record.merge({
    RoleIds: roleList,
    OrganizationIds: orgList,
    FacilityIds: facList,
    UserCartGroupIds: cartGroupList,
    UserCartIds: cartList,
    NotificationGroupIds: notificationGroupList,
    UpsertSettings: [],
  });
}
