import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { CartPartialResponse } from '@capsa/api';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-device-tracking-connected-devices',
  templateUrl: './device-tracking-connected-devices.component.html',
  styleUrls: ['./device-tracking-connected-devices.component.scss'],
})
export class DeviceTrackingConnectedDevicesComponent
  implements OnDestroy, OnChanges
{
  @Input()
  public accessPointId: number | undefined;

  public loadingConnectedDevices = false;

  public connectedDevices: CartPartialResponse[] = [];

  private subs = new Subscription();

  constructor(
    private networkAccessPointApi: NetworkAccessPointApi,
    private toasterService: ToasterService
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accessPointId) {
      this.startLoadingConnectedDevices();
    }
  }

  private startLoadingConnectedDevices() {
    this.loadingConnectedDevices = true;

    if (this.accessPointId < 0 || this.accessPointId === undefined) {
      this.connectedDevices = [];
      this.loadingConnectedDevices = false;
      return;
    }

    this.loadConnectedDevices();
  }

  private loadConnectedDevices() {
    const sub = this.networkAccessPointApi
      .getConnectedDevices(this.accessPointId)
      .pipe(finalize(() => (this.loadingConnectedDevices = false)))
      .subscribe(
        (deviceList) => {
          this.connectedDevices = deviceList.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
        },
        (_error) => {
          this.toasterService.showError('ACCESS_POINT_COULD_NOT_LOAD_DEVICES');
        }
      );

    this.subs.add(sub);
  }
}
