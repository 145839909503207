import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CartGroupCreateRequest, CartGroupResponse } from '@capsa/api';
import { CartGroupApi } from '@capsa/api/cart-group';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-page',
  templateUrl: './manage-page.component.html',
  styleUrls: ['./manage-page.component.scss'],
})
export class CartGroupManagePageComponent implements OnInit, OnDestroy {
  public organizationId: number | undefined;
  public facilityId: number | undefined;

  public gridGroupList: GridDataResult = { data: [], total: 0 };
  public loadingData = true;
  public canEdit = false;

  private subs: Subscription = new Subscription();

  public pageSize = 10;
  public skip = 0;
  public pageSizes = [10, 25, 50];
  private get hasMultipleOrgs() {
    return this.userEofCacheService.userHasMultipleOrgs;
  }

  @ViewChild('cartGroupGrid', { static: false })
  public cartGroupGrid: GridComponent;
  private editedRowIndex: number;
  private editedGroup: CartGroupResponse;

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Carts];

  constructor(
    private api: CartGroupApi,
    private enterpriseService: EnterpriseService,
    private router: Router,
    private userEofCacheService: UserEofCacheService,
    private permissionService: PermissionService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (!this.hasMultipleOrgs) {
      // Because this sets public state on the component from within ngOnInit,
      // we need to run this code on the next render check cycle instead of during
      // initial rending. If we don't do this, we get a change detection error.
      setTimeout(() => {
        const availableOrg = this.userEofCacheService.getFirstOrg();
        this.organizationId = availableOrg.Id;
      }, 0);
    } else {
      this.refreshCartGroupList();
    }
    this.loadPermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.Manage_CabinetGroups_ReadWrite;

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(editPerm);
          this.refreshCartGroupList();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.canEdit = this.permissionService.has(editPerm);
  }

  onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
  }

  onFacilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
  }

  private cancelEditOrAdd() {
    // Cart Group Grid component doesn't exist yet, skip this time...
    if (!this.cartGroupGrid) {
      return;
    }

    if (this.editedRowIndex) {
      this.cartGroupGrid.closeRow(this.editedRowIndex);
    } else {
      this.cartGroupGrid.closeRow();
    }
  }

  private refreshCartGroupList() {
    this.gridGroupList = { data: [], total: 0 };
    if (!this.organizationId || !this.facilityId || !this.canViewPage) {
      this.cancelEditOrAdd();
      this.loadingData = false;
      return;
    }
    this.loadingData = true;

    const sub = this.api
      .search({
        OrganizationIds: [this.organizationId],
        FacilityIds: [this.facilityId],
        CartGroupIds: [],
        EnterpriseId: this.enterpriseService.enterpriseId,
        PageNumber: this.skip / this.pageSize + 1,
        PageSize: this.pageSize,
      })
      .subscribe(
        (resp) => {
          this.gridGroupList = {
            data: resp.Result,
            total: resp.TotalRecordCount,
          };
          this.loadingData = false;
        },
        (error) => {
          this.toasterService.showError('GENERIC_GET_LIST_FAILED');
          this.loadingData = false;
        }
      );
    this.subs.add(sub);
  }

  public addHandler({ sender }) {
    this.closeEditor(sender);

    const newRow: CartGroupCreateRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      Description: '',
      OrganizationId: this.organizationId,
      FacilityId: this.facilityId,
    };
    sender.addRow(newRow);
  }

  public onSelectGroup(event: Event, item: CartGroupResponse) {
    this.router.navigate(['carts', 'groups', 'edit', item.CartGroupId]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedGroup = Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }) {
    this.loadingData = true;
    if (isNew) {
      const sub = this.api
        .create({
          EnterpriseId: dataItem.EnterpriseId,
          Description: dataItem.Description,
          OrganizationId: dataItem.OrganizationId,
          FacilityId: dataItem.FacilityId,
        })
        .subscribe(
          (resp) => {
            this.refreshCartGroupList();
          },
          (error) => {
            this.toasterService.showError('CREATE_FAILED');
            this.loadingData = false;
          }
        );
      this.subs.add(sub);
    } else {
      const sub = this.api
        .update({
          EnterpriseId: dataItem.EnterpriseId,
          CartGroupId: dataItem.CartGroupId,
          Description: dataItem.Description,
          OrganizationId: dataItem.OrganizationId,
          FacilityId: dataItem.FacilityId,
        })
        .subscribe(
          (resp) => {
            this.refreshCartGroupList();
          },
          (error) => {
            this.toasterService.showError('UPDATE_FAILED');
            this.loadingData = false;
          }
        );
      this.subs.add(sub);
    }
    sender.closeRow(rowIndex);

    this.editedRowIndex = undefined;
    this.editedGroup = undefined;
  }

  public removeHandler({ dataItem }: { dataItem: CartGroupResponse }) {
    this.loadingData = true;
    const sub = this.api.delete(dataItem.CartGroupId).subscribe(
      (resp) => {
        this.refreshCartGroupList();
      },
      (error) => {
        this.toasterService.showError('DELETE_FAILED');
        this.loadingData = false;
      }
    );
    this.subs.add(sub);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    // Restore row data (just in case an edit happened but wasn't saved)
    this.gridGroupList.data[rowIndex] = Object.assign({}, this.editedGroup);

    this.editedRowIndex = undefined;
    this.editedGroup = undefined;
  }
}
