import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserResponse } from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { AuthService } from '@capsa/services/auth/auth.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Utils } from 'app/common/utils';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  public firstName: string;
  public lastName: string;

  public preferredLanguage: string;

  public emailAddress: string;

  private subs = new Subscription();

  constructor(
    private authService: AuthService,
    private userApi: UserApi,
    public loaderService: LoaderService,
    public toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadUserDetailsFromApi();
  }

  private loadUserDetailsFromApi() {
    this.loaderService.start();

    const sub = this.userApi
      .getUserDetails(this.authService.currentUser.UserId, false)
      .pipe(finalize(() => this.loaderService.stop()))
      .subscribe(
        (response) => {
          this.populateUserDetails(response.Result);
        },
        (_error) => {
          this.toasterService.showError('ERROR_FAILED_TO_LOAD_USER');
        }
      );
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private populateUserDetails(user: UserResponse) {
    this.firstName = user.FirstName;
    this.lastName = user.LastName;
    this.emailAddress = user.Email;

    this.preferredLanguage = Utils.getLanguageFromId(Utils.getLanguageToSet());
  }
}
