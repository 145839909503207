// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  font-weight: bold;
  padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/labels/bold/bold.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF","sourcesContent":["label {\n  font-weight: bold;\n  padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
