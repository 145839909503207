import {
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirmwareResponse } from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  FileRestrictions,
  FileSelectComponent,
} from '@progress/kendo-angular-upload';
import { xIcon } from '@progress/kendo-svg-icons';
import { SharedDeviceUpdateService } from 'app/modules/device-update/device-update.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-firmware-upload-dialog',
  templateUrl: './firmware-upload-dialog.component.html',
  styleUrls: ['./firmware-upload-dialog.component.scss'],
})
export class FirmwareUploadDialogComponent implements OnDestroy {
  constructor(
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private firmHardWareApi: FirmHardWareApi,
    private deviceUpdateService: SharedDeviceUpdateService
  ) {}

  public icons = { xIcon: xIcon };

  public isLoading = true;

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @Output()
  public cancel = new EventEmitter();

  @Output()
  public uploadSuccess = new EventEmitter();

  @ViewChild('uploadFileSelect', { static: false })
  public uploadFileSelectRef: FileSelectComponent;

  public uploadedFile: File;
  public selectedFirmwareId = -1;

  private minYear = 2018;
  private maxYear = new Date().getFullYear() + 2;

  public minDate = new Date(this.minYear, 0, 1);
  public maxDate = new Date(this.maxYear, 11, 31);

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.zip'],
  };

  public newVersionCtrl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(200),
    ])
  );

  public descriptionCtrl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(500),
    ])
  );

  public releaseDateCtrl = new FormControl<Date>(
    null,
    Validators.compose([Validators.required])
  );

  public formGroup = new FormGroup({
    newVersionCtrl: this.newVersionCtrl,
    releaseDateCtrl: this.releaseDateCtrl,
    descriptionCtrl: this.descriptionCtrl,
  });

  private subs = new Subscription();

  public get isFormValid() {
    return (
      this.uploadedFile && this.formGroup.valid && this.selectedFirmwareId > 0
    );
  }

  public doUpload() {
    this.loaderService.start();

    this.firmHardWareApi
      .uploadFirmware(
        this.newVersionCtrl.value,
        this.releaseDateCtrl.value,
        this.deviceUpdateService.updateType,
        this.descriptionCtrl.value,
        this.uploadedFile,
        this.selectedFirmwareId
      )
      .pipe(
        finalize(() => {
          this.loaderService.stop();
        })
      )
      .subscribe(
        (resp) => {
          this.newVersionCtrl.reset();
          this.uploadedFile = undefined;
          this.uploadFileSelectRef.clearFiles();
          this.uploadSuccess.emit();
        },
        (error) => {
          this.toasterService.showError('Upload Failed');
        }
      );
  }

  public onClose() {
    this.cancel.emit();
  }

  public fileValueChange(files: File[]) {
    if (files && files.length === 1) {
      this.uploadedFile = files[0];
    } else {
      this.uploadedFile = undefined;
    }
  }

  public onFirmwareChanged(fw: FirmwareResponse) {
    if (fw) {
      this.selectedFirmwareId = fw.FirmwareId;
    } else {
      this.selectedFirmwareId = -1;
    }
  }
}
