/**
 * Enums available from the api
 */
export enum EnumTypes {
  LanguageCodeType = 'LanguageCodeType',
  TimeZoneIdType = 'TimeZoneIdType',
  UserType = 'UserType',
  DeviceUpdateType = 'DeviceUpdateType',
}

/**
 * Simple structure for basic dropdowns, especially those based on enums.
 */
export interface DropDownItem {
  Label: string;
  Value: string | number;
}

export interface DropDownItemWithData<T> extends DropDownItem {
  Data: T;
}

export interface DropDownItemGeneric<T> {
  Label: string;
  Value: T;
}

export interface DropDownItemNumber {
  Label: string;
  Value: number;
}

/**
 * Simple structure for basic dropdowns, especially those based on enums.
 */
export interface BooleanDropDownItem {
  Label: string;
  Value: boolean;
}
