import { Component, Input } from '@angular/core';
import { AbstractAsyncDropDownDirective } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { TranslateService } from '@ngx-translate/core';

/**
 * Represents and async dropdown list. Used to set/maintain standard settings
 * for kendo async dropdown lists.
 */
@Component({
  styles: [],
  selector: 'app-async-drop-down-list',
  templateUrl: './async-drop-down-list.component.html',
})
export class AsyncDropDownListComponent<
  TModel
> extends AbstractAsyncDropDownDirective<TModel> {
  @Input()
  public readonly: boolean;

  @Input()
  public readonlyText: string;

  @Input()
  public titleTag: string;

  @Input()
  public dataSource: AbstractDropDownListDataSource<TModel>;

  @Input()
  public disabled = false;

  constructor(protected translations: TranslateService) {
    super();
  }
}
