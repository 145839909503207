import { Component } from '@angular/core';

@Component({
  selector: 'app-cart-profile-carelink-v2-battery-settings-tab',
  templateUrl: './carelink-v2-battery-settings-tab.component.html',
  styleUrls: ['./carelink-v2-battery-settings-tab.component.scss'],
})
export class CarelinkV2BatterySettingsTabComponent {
  constructor() {}
}
