import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AnalyticsChartEntity,
  AnalyticsChartRequest,
  CartLabelDisplayMode,
  ChartEntityType,
  ChartQueryType,
  ChartTimeResolutionIntervalType,
  DeviceTypeIds,
} from '@capsa/api';
import { AnalyticsApi } from '@capsa/api/analytics';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { AnalyticsCacheService } from '@capsa/services/analytics-cache/analytics-cache.service';
import { AnalyticsHelperService } from '@capsa/services/analytics-helper/analytics-helper.service';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { DateFormats } from '@progress/kendo-angular-charts';
import { encodeBase64, saveAs } from '@progress/kendo-file-saver';
import {
  AnalyticsQueryProperties,
  ChartType,
  DialogSelections,
  EntityGroupItem,
  EntityItem,
} from 'app/modules/analytics/analytics-interfaces';
import * as moment from 'moment';
import { Subject, Subscription, fromEvent, interval } from 'rxjs';
import { finalize, throttle } from 'rxjs/operators';

@Component({
  selector: 'app-analytics-template',
  templateUrl: './analytics-template.component.html',
  styleUrls: ['./analytics-template.component.scss'],
})
export class AnalyticsTemplateComponent implements OnInit, OnDestroy {
  @Input()
  chartType: string;

  public data: AnalyticsChartEntity[] = [];

  @Input()
  public pageTitleTag: string;

  @Output()
  orgFacChanged = new EventEmitter();

  @Input()
  queriesDropDownItems: DropDownItem[];

  public selectedEntityIds: string[] = [];
  public selectedEntityGroupIds: string[] = [];

  private newDataSubj = new Subject<void>();
  public newData$ = this.newDataSubj.asObservable();
  public chartEntityType = ChartEntityType;

  public displayMode = CartLabelDisplayMode;
  public selectedDisplayMode = CartLabelDisplayMode.Name;

  public chartTitleTag: string;
  public tooltipValueDescriptionTag: string;

  private readonly xAxisHourlyDateFormat12hours = 'h:mma - MMM d';
  private readonly xAxisHourlyDateFormat24hours = 'H:mm - MMM d';

  public xAxisDateFormats: DateFormats;

  public get isFillGapsSettingAvailable(): boolean {
    return this.isLineChart;
  }

  public get is24hourFormatSettingAvailable(): boolean {
    return this.isLineChart;
  }

  public get isSmoothLineSettingAvailable(): boolean {
    return this.isLineChart;
  }

  public get isShowmarkersSettingAvailable(): boolean {
    return this.isLineChart;
  }

  public get is24HourToggleDisabled(): boolean {
    return !(
      this.dateTimeInterval === ChartTimeResolutionIntervalType.QuarterHour ||
      this.dateTimeInterval === ChartTimeResolutionIntervalType.Hour
    );
  }

  public get isLineChart(): boolean {
    return this.currentQueryProps.chartType === ChartType.line;
  }

  public get isBarChart(): boolean {
    return this.currentQueryProps.chartType === ChartType.bar;
  }

  public get isQueryByCartAllowed(): boolean {
    return this.currentQueryProps.allowedEntityTypes.some(
      (x) => x === ChartEntityType.Cart
    );
  }
  public get isQueryByUserAllowed(): boolean {
    return this.currentQueryProps.allowedEntityTypes.some(
      (x) => x === ChartEntityType.User
    );
  }
  public get isQueryByAccessPointAllowed(): boolean {
    return this.currentQueryProps.allowedEntityTypes.some(
      (x) => x === ChartEntityType.AccessPoint
    );
  }

  public get currentQueryProps(): AnalyticsQueryProperties {
    return this.analyticsHelperService.props(this.currentQuery);
  }

  public get canShowChart(): boolean {
    return this.data && this.data.length && !this.loading;
  }

  public chooseEntitiesDialogOpen = false;

  public dateRangeInputs = {
    start: undefined,
    end: undefined,
  };

  public chartDateStart: Date;
  public chartDateEnd: Date;

  public dateRangeMin: Date = moment().subtract(5, 'years').toDate();

  public dateRangeMax: Date = moment()
    .add(1, 'seconds') // Removing this messes with kendo, and it will reset the pre-set "end date"
    .toDate();

  public loading = false;

  private subs = new Subscription();

  public organizationId: number | undefined;
  public facilityId: number | undefined;

  public entities: EntityItem[] = [];
  public groups: EntityGroupItem[] = [];

  public queryByEntityType: ChartEntityType;

  // Exposes enum to the template
  public get ChartEntityType(): typeof ChartEntityType {
    return ChartEntityType;
  }

  public skipNumLabels = 0;

  /**
   * User toggle, allows the user to decide if they want to
   * see things formatted in 24 hour format or AM/PM
   */
  public is24Hour = true;

  /**
   * User toggle, sets how a lines points should connect if there's a missing value between points
   * 'interpolate' will "fill" the missing points with a line connecting the points before/after the gap
   * 'gap' will leave a "gap" in the line
   */
  public missingValsSetting = 'interpolate';

  public lineStyle = 'smooth';

  /**
   * User toggle, shows or hides the data points (hollow circles)
   */
  public isMarkersVisible = true;

  /**
   * The date time interval for the current chart
   */
  public dateTimeInterval: ChartTimeResolutionIntervalType;

  private evResizeWindow = fromEvent(window, 'resize');

  public throttledResize$ = this.evResizeWindow.pipe(
    throttle(() => interval(500))
  );

  public tooltipDateTimeFormat: string;

  private apiCallEntityGroupListInProgress = false;
  public currentQuery: ChartQueryType;

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Analytics];

  constructor(
    private analyticsCacheService: AnalyticsCacheService,
    private analyticsApi: AnalyticsApi,
    private translationService: TranslateService,
    private analyticsHelperService: AnalyticsHelperService,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToasterService,
    private permissionService: PermissionService,
    private aiService: AppInsightsService
  ) {}

  ngOnInit() {
    this.currentQuery = this.queriesDropDownItems[0].Value as ChartQueryType;
    this.queryByEntityType = this.currentQueryProps.allowedEntityTypes[0];

    this.initDateRange();
    this.refreshXAxisDateFormats();

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public queryByEntityTypeChanged(event: Event) {
    this.data.length = 0;

    // The view will automatically update the button value "Select Carts", "Select Users" or "Select Access Points"...
    this.refreshEntityAndGroupList();
  }

  private initDateRange() {
    if (
      !this.analyticsCacheService.userInputStartDate ||
      !this.analyticsCacheService.userInputEndDate
    ) {
      const todaysDate = new Date();
      const year = todaysDate.getFullYear();
      const month = todaysDate.getMonth() + 1;
      const day = todaysDate.getDate();

      const today = moment(`${year}-${month}-${day}`);
      const someDaysAgo = today.clone().subtract(2, 'day');

      this.dateRangeInputs = {
        start: someDaysAgo.toDate(),
        end: today.toDate(),
      };

      this.analyticsCacheService.userInputStartDate =
        this.dateRangeInputs.start;
      this.analyticsCacheService.userInputEndDate = this.dateRangeInputs.end;
    } else {
      this.dateRangeInputs = {
        start: this.analyticsCacheService.userInputStartDate,
        end: this.analyticsCacheService.userInputEndDate,
      };
    }
  }

  public orgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
  }

  public facilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    this.clearChartData();
    this.refreshEntityAndGroupList();
    this.refreshAnalytics();
  }

  public btnClickChooseEntities() {
    this.aiService.btnClickTrace('AnalyticsEntities');
    this.chooseEntitiesDialogOpen = true;
  }

  public btnClickRefresh() {
    this.aiService.btnClickTrace('AnalyticsRefresh');
    this.refreshAnalytics();
  }

  public btnClickExport() {
    this.aiService.btnClickTrace('AnalyticsExport');
    this.getCsv();
  }

  private clearChartData() {
    this.selectedEntityIds.length = 0;
    this.data.length = 0;
    this.data = [];
  }

  private refreshEntityAndGroupList() {
    this.selectedEntityIds.length = 0;
    this.selectedEntityGroupIds.length = 0;
    this.entities.length = 0;
    this.groups.length = 0;

    if (
      !this.organizationId ||
      !this.facilityId ||
      this.apiCallEntityGroupListInProgress
    ) {
      return;
    }

    this.apiCallEntityGroupListInProgress = true;
    this.loading = true;

    this.subs.add(
      this.analyticsCacheService
        .getLists(this.organizationId, this.facilityId, this.queryByEntityType)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.apiCallEntityGroupListInProgress = false;
          })
        )
        .subscribe(
          (entitiesAndSelections) => {
            this.entities = entitiesAndSelections.entityList;
            this.groups = entitiesAndSelections.groupList;
            this.selectedEntityIds = entitiesAndSelections.selectedEntityIds;
            this.selectedEntityGroupIds =
              entitiesAndSelections.selectedEntityGroupIds;
          },
          () => {
            let tagName: string;
            switch (this.queryByEntityType) {
              case ChartEntityType.Cart:
                tagName = 'ERR_GETTING_LISTS_OF_CARTS_AND_GROUPS';
                break;
              case ChartEntityType.User:
                tagName = 'ERR_GETTING_LISTS_OF_USERS_AND_GROUPS';
                break;
              case ChartEntityType.AccessPoint:
                tagName = 'ERR_GETTING_LISTS_OF_APS_AND_GROUPS';
                break;
              default:
                throw new Error('Invalid queryByEntityType');
            }

            this.toasterService.showError(tagName);
          }
        )
    );
  }

  private refreshAnalytics() {
    if (!this.organizationId || !this.facilityId) {
      this.loading = false;
      this.entities.length = 0;
      this.groups.length = 0;
      this.data.length = 0;
      return;
    }

    if (this.entities.length === 0) {
      return;
    }

    this.getChart();
  }

  private getCsvHeaders(): string[] {
    const headers = [];
    switch (this.queryByEntityType) {
      case ChartEntityType.AccessPoint:
        headers.push(this.translationService.instant('COM_ACCESS_POINT'));
        break;
      case ChartEntityType.Cart:
        headers.push(this.translationService.instant('COM_CART'));
        break;
      case ChartEntityType.User:
        headers.push(this.translationService.instant('COM_USER'));
        break;
      default:
        headers.push(this.translationService.instant('KEY'));
        break;
    }

    switch (this.dateTimeInterval) {
      case ChartTimeResolutionIntervalType.QuarterHour:
        headers.push(this.translationService.instant('UnitsType_QuarterHour'));
        break;
      case ChartTimeResolutionIntervalType.Hour:
        headers.push(this.translationService.instant('UnitsType_Hours'));
        break;
      case ChartTimeResolutionIntervalType.Day:
        headers.push(this.translationService.instant('UnitsType_Day'));
        break;
      case ChartTimeResolutionIntervalType.Week:
        headers.push(this.translationService.instant('WEEK_OF'));
        break;
      case ChartTimeResolutionIntervalType.Month:
        headers.push(this.translationService.instant('MONTH'));
        break;
      default:
        headers.push(this.translationService.instant('COM_DATE'));
        break;
    }

    if (this.isLineChart || this.currentQueryProps.hideSubcategory) {
      headers.push(
        this.translationService.instant(this.tooltipValueDescriptionTag)
      );
    } else {
      this.data.forEach((element) => {
        const headerCandidate = this.translationService.instant(
          element.SubCategoryName || element.SubCategory
        );
        if (headers.indexOf(headerCandidate) === -1) {
          headers.push(headerCandidate);
        }
      });
    }
    return headers;
  }

  private getCsvDateString(date: Date): string {
    return moment(date).format(this.getCsvDateFormat()).toString();
  }

  private getFormattedCsvData(headers: string[]): string[] {
    const formattedDataRows = [];
    const formattedDataRow = [];
    const utcDates = [];
    const dateStrings = [];
    const entityNames = [];

    this.data.forEach((element) => {
      element.Values.forEach((subElement) => {
        const dateString = this.getCsvDateString(subElement.DateUtc);
        if (!dateStrings.includes(dateString)) {
          utcDates.push(subElement.DateUtc);
          dateStrings.push(dateString);
        }

        if (!entityNames.includes(element.EntityName)) {
          entityNames.push(element.EntityName);
        }
      });
    });
    entityNames.sort((a, b) => (a > b ? 1 : -1));
    utcDates.sort((a, b) => (a > b ? 1 : -1));

    for (const entityName of entityNames) {
      for (const utcDate of utcDates) {
        const dateString = this.getCsvDateString(utcDate);
        formattedDataRow.length = 0;
        formattedDataRow.push(entityName, dateString);
        // [name, date, cat1, ...]
        for (let headerIndex = 2; headerIndex < headers.length; headerIndex++) {
          let found = false;
          for (const element of this.data) {
            if (found) {
              break;
            }
            // only real difference when exporting CSVs is that
            // lineCharts and access analytics don't use subcategories
            const categoryString =
              this.isLineChart || this.currentQueryProps.hideSubcategory
                ? headers[headerIndex]
                : this.translationService.instant(
                    element.SubCategoryName || element.SubCategory
                  );
            // order must be "element.SubCategoryName || element.SubCategory"
            // SubCategory(Name) order seems to matter, per the barChart tooltip html commentary
            for (const subElement of element.Values) {
              const subElementDateString = this.getCsvDateString(
                subElement.DateUtc
              );
              if (
                element.EntityName === formattedDataRow[0] &&
                subElementDateString === formattedDataRow[1] &&
                categoryString === headers[headerIndex]
              ) {
                formattedDataRow.push(subElement.Value.toString());
                found = true;
                break;
              }
            }
          }
          if (!found && formattedDataRow[headerIndex] !== '0') {
            formattedDataRow.push('0');
          }
        }
        formattedDataRows.push(formattedDataRow.join(', '));
      }
    }
    return formattedDataRows;
  }

  public getCsv() {
    if (!this.data || this.data.length === 0) {
      return;
    }
    const csvTitle =
      this.translationService.instant(this.chartTitleTag) +
      ' ' +
      this.getCsvDateString(this.chartDateStart) +
      ' - ' +
      this.getCsvDateString(this.chartDateEnd) +
      '.csv';
    const headers = this.getCsvHeaders();
    const processedData = this.getFormattedCsvData(headers);
    const exportData = headers
      .join(', ')
      .concat('\n', processedData.join('\n'));
    const dataURI = 'data:text/plain;base64,' + encodeBase64(exportData);
    saveAs(dataURI, csvTitle);
  }

  // momentjs uses different capitalization for date format than kendo
  // switch is overkill but kept here in case we want more formats
  private getCsvDateFormat(): string {
    switch (this.dateTimeInterval) {
      case ChartTimeResolutionIntervalType.Day:
      case ChartTimeResolutionIntervalType.Week:
        return 'YYYY-MMM-D';
      case ChartTimeResolutionIntervalType.QuarterHour:
      case ChartTimeResolutionIntervalType.Hour:
      case ChartTimeResolutionIntervalType.Unknown:
      default:
        return 'YYYY-MMM-D H:mm';
    }
  }

  public onFillGapsToggle() {
    if (this.missingValsSetting === 'interpolate') {
      this.missingValsSetting = 'gap';
    } else {
      this.missingValsSetting = 'interpolate';
    }
  }

  public on24HoursToggle() {
    this.is24Hour = !this.is24Hour;
    this.refreshTooltipDateTimeFormat();

    this.refreshXAxisDateFormats();
  }

  public onLineStyleToggle() {
    if (this.lineStyle === 'smooth') {
      this.lineStyle = 'normal';
    } else {
      this.lineStyle = 'smooth';
    }
  }

  public onMarkersToggle() {
    this.isMarkersVisible = !this.isMarkersVisible;
  }

  private refreshXAxisDateFormats() {
    this.xAxisDateFormats = {
      hours: this.is24Hour
        ? this.xAxisHourlyDateFormat24hours
        : this.xAxisHourlyDateFormat12hours,
      days: 'MMM d',
      weeks: 'MMM d',
      months: 'MMMM yyyy',
    };
  }

  public queryDropDownChanged(selected: DropDownItem) {
    this.currentQuery = selected.Value as ChartQueryType;
    const prevQueryByEntity = this.queryByEntityType;
    const allowedEntityTypes = this.currentQueryProps.allowedEntityTypes;

    if (allowedEntityTypes.length === 1) {
      this.queryByEntityType = allowedEntityTypes[0];
    } else if (allowedEntityTypes.length > 1) {
      // If multiple allowed entity types exist
      // check to see the currently selected one is allowed
      // and if not, then switch to the first one that is
      if (!allowedEntityTypes.some((x) => x === prevQueryByEntity)) {
        this.queryByEntityType = allowedEntityTypes[0];
      }
    }

    // If switching to a query that is causing a change
    // in "entity type", clear the selected entity ID's
    if (prevQueryByEntity !== this.queryByEntityType) {
      this.data.length = 0;
      this.refreshEntityAndGroupList();
    }

    this.refreshAnalytics();
  }

  public startDateChanged(newStartDate: Date) {
    this.data.length = 0;

    this.dateRangeInputs = {
      start: newStartDate,
      end: this.dateRangeInputs.end,
    };
    this.analyticsCacheService.userInputStartDate = newStartDate;
  }

  public endDateChanged(newEndDate: Date) {
    this.data.length = 0;

    this.dateRangeInputs = {
      start: this.dateRangeInputs.start,
      end: newEndDate,
    };
    this.analyticsCacheService.userInputEndDate = newEndDate;
  }

  private getChart() {
    if (this.entities.length === 0 || this.selectedEntityIds.length === 0) {
      return;
    }

    this.loading = true;
    this.data.length = 0;

    // Adding one day to end date to ensure "end day" is inclusive in response
    const endPlusOneDay = moment(this.dateRangeInputs.end)
      .add(1, 'days')
      .toDate();

    // It appears that Kendo will use the current local time zone offset, and we
    // don't need to change this based on the users selection (i.e. it doesn't matter if
    // the selected range spans over a DST change or not)
    const currentLocalMinutesOffset = moment().utcOffset();

    const request: AnalyticsChartRequest = {
      OrganizationId: this.organizationId,
      FacilityId: this.facilityId,
      DeviceType: DeviceTypeIds.CareLink_2,
      ChartQuery: this.currentQuery,
      GraphyByEntity: this.queryByEntityType,
      Ids: this.selectedEntityIds,
      StartDateUtc: this.dateRangeInputs.start,
      EndDateUtc: endPlusOneDay,
      LocalMinutesOffset: currentLocalMinutesOffset,
    };
    this.subs.add(
      this.analyticsApi.getChart(request).subscribe((resp) => {
        this.chartDateStart = resp.StartDateUtc;
        this.chartDateEnd = resp.EndDateUtc;
        this.chartTitleTag = this.currentQueryProps.chartTileTag;
        this.tooltipValueDescriptionTag = resp.YAxisLabel;

        // If none of the entity names are provided (all queries except "Access Point Connectivity")
        if (!resp.Entities.some((x) => x.EntityName !== null)) {
          // Add "Name" for each entity (so chart doesn't have to display by Id)
          resp.Entities.forEach((x) => {
            x.EntityName = this.entities.find(
              (entity) => entity.Id === x.EntityKey
            ).Name;
          });
        }
        // If cart query, add Serial Number for display
        if (this.queryByEntityType === ChartEntityType.Cart) {
          resp.Entities.forEach((x) => {
            x.EntitySerialNumber = this.entities.find(
              (entity) => entity.Id === x.EntityKey
            ).SerialNumber;
          });
        }

        this.dateTimeInterval = resp.DateInterval;
        this.refreshTooltipDateTimeFormat();

        this.analyticsHelperService.sortAndSetColors(
          resp.Entities,
          this.currentQueryProps.dataStructureType
        );
        this.data = resp.Entities;
        this.loading = false;
        this.newDataSubj.next();
        this.cdRef.detectChanges();
      })
    );
  }

  private refreshTooltipDateTimeFormat(): void {
    switch (this.dateTimeInterval) {
      case ChartTimeResolutionIntervalType.QuarterHour:
        this.tooltipDateTimeFormat = this.is24Hour
          ? 'H:mm MMM d, y'
          : 'h:mm a MMM d, y';
        break;
      case ChartTimeResolutionIntervalType.Hour:
        this.tooltipDateTimeFormat = this.is24Hour
          ? 'H:mm MMM d, y'
          : 'h:mm a MMM d, y';
        break;
      case ChartTimeResolutionIntervalType.Day:
        this.tooltipDateTimeFormat = 'MMM d';
        break;
      case ChartTimeResolutionIntervalType.Week:
        this.tooltipDateTimeFormat = 'MMM d, y';
        break;
      case ChartTimeResolutionIntervalType.Month:
        this.tooltipDateTimeFormat = 'MMM, y';
        break;
      case ChartTimeResolutionIntervalType.Unknown:
      default:
        throw new Error('setTooltipDateTimeFormat invalid date interval');
    }
  }

  public onSelectEntitiesModalClose() {
    this.chooseEntitiesDialogOpen = false;
  }

  public onSavedEntitiesSelection(selections: DialogSelections) {
    this.selectedEntityIds = selections.entityIds;
    this.selectedEntityGroupIds = selections.entityGroupIds;

    this.analyticsCacheService.updateSelections(
      this.organizationId,
      this.facilityId,
      this.queryByEntityType,
      this.selectedEntityIds,
      this.selectedEntityGroupIds
    );

    this.chooseEntitiesDialogOpen = false;
    this.refreshAnalytics();
  }
}
