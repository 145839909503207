import { SharedCartManagementUsersTabComponent } from 'app/modules/cart-management/cart-management-single/device-types/shared-cart-management-tabs/shared-cart-management-users-tab.component';
import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { TrioCartManagementDebugTabComponent } from './trio-cart-management-debug-tab/trio-cart-management-debug-tab.component';
import { TrioCartManagementGeneralTabComponent } from './trio-cart-management-general-tab/trio-cart-management-general-tab.component';
import { TrioCartManagementLightingTabComponent } from './trio-cart-management-lighting-tab/trio-cart-management-lighting-tab.component';

export const TrioCartManagementTabs: DynamicTab[] = [
  {
    titleTag: 'CART_DETAILS',
    component: TrioCartManagementGeneralTabComponent,
  },
  {
    titleTag: 'LIGHTING_TAB_NOTIFICATION_LIGHT',
    component: TrioCartManagementLightingTabComponent,
  },
  {
    titleTag: 'CART_USERS',
    component: SharedCartManagementUsersTabComponent,
  },
  {
    titleTag: 'DEBUG',
    component: TrioCartManagementDebugTabComponent,
  },
];
