import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NativeWindowService } from '@capsa/services/native-window/native-window.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  InputsModule,
  SliderModule,
  SwitchModule,
} from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';
import { ListPageComponent } from 'app/modules/cart-profile/pages/list-page/list-page.component';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { CheckboxComponent } from './common/checkbox/checkbox.component';
import { GeneralDetailsComponent } from './common/general-details/general-details.component';
import { ListViewComponent } from './common/list-view/list-view.component';
import { NameComponent } from './common/name/name.component';
import { CreateOrEditComponent } from './pages/create-or-edit/create-or-edit.component';
import { CreatePageComponent } from './pages/create-page/create-page.component';
import { EditPageComponent } from './pages/edit-page/edit-page.component';
// Tabs
import { CarelinkV1BatterySettingsTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-battery-settings-tab/carelink-v1-battery-settings-tab.component';
import { CarelinkV1DeviceFeaturesTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-device-features-tab/carelink-v1-device-features-tab.component';
import { CarelinkV1DisplayTabComponent } from 'app/modules/cart-profile/profile-definitions/carelink-v1/carelink-v1-display-tab/carelink-v1-display-tab.component';
import { CarelinkV2BatterySettingsTabComponent } from './profile-definitions/carelink-v2/carelink-v2-battery-settings-tab/carelink-v2-battery-settings-tab.component';
import { CarelinkV2DeviceFeaturesTabComponent } from './profile-definitions/carelink-v2/carelink-v2-device-features-tab/carelink-v2-device-features-tab.component';
import { CarelinkV2DisplayTabComponent } from './profile-definitions/carelink-v2/carelink-v2-display-tab/carelink-v2-display-tab.component';
// Subcomponents
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { BatteryDisplayComponent } from './profile-definitions/carelink-v1/carelink-v1-battery-settings-tab/battery-display/battery-display.component';
import { CartFeaturesComponent } from './profile-definitions/carelink-v1/carelink-v1-device-features-tab/cart-features/cart-features.component';
import { DisplayComponent } from './profile-definitions/carelink-v1/carelink-v1-display-tab/display/display.component';
import { LightBrightnessComponent } from './profile-definitions/carelink-v1/carelink-v1-display-tab/light-brightness/light-brightness.component';
import { V2CartFeaturesComponent } from './profile-definitions/carelink-v2/carelink-v2-device-features-tab/cart-features/cart-features.component';
import { V2DisplayComponent } from './profile-definitions/carelink-v2/carelink-v2-display-tab/display/display.component';
import { V2LightsComponent } from './profile-definitions/carelink-v2/carelink-v2-display-tab/lights/lights.component';

@NgModule({
  declarations: [
    ListPageComponent,
    DisplayComponent,
    V2DisplayComponent,
    LightBrightnessComponent,
    V2LightsComponent,
    BatteryDisplayComponent,
    CartFeaturesComponent,
    CheckboxComponent,
    ListViewComponent,
    CreatePageComponent,
    NameComponent,
    GeneralDetailsComponent,
    CarelinkV1DisplayTabComponent,
    CarelinkV1BatterySettingsTabComponent,
    CarelinkV1DeviceFeaturesTabComponent,
    CreateOrEditComponent,
    EditPageComponent,
    CarelinkV2DisplayTabComponent,
    CarelinkV2BatterySettingsTabComponent,
    CarelinkV2DeviceFeaturesTabComponent,
    V2CartFeaturesComponent,
  ],
  exports: [ListPageComponent, CreatePageComponent, EditPageComponent],
  imports: [
    LabelsModule,
    ReactiveFormsModule,
    LayoutModule,
    ButtonModule,
    ButtonsModule,
    CommonModule,
    ApiModule,
    CapsaDropdownsModule,
    SliderModule,
    GridModule,
    BrowserAnimationsModule,
    InputsModule,
    SwitchModule,
    TranslateModule.forChild(),
    CustomInputsModule,
  ],
  providers: [NativeWindowService, ApiSettingsFormHelper],
})
export class CartProfileModule {}
