import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AnalyticsChartEntity,
  CartLabelDisplayMode,
  ChartEntityType,
  ChartQueryType,
} from '@capsa/api';
import { AnalyticsHelperService } from '@capsa/services/analytics-helper/analytics-helper.service';
import {
  AnalyticsDataStructureType,
  AnalyticsQueryProperties,
  ChartType,
  EntityItem,
} from 'app/modules/analytics/analytics-interfaces';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-analytics-legend',
  templateUrl: './analytics-legend.component.html',
  styleUrls: ['./analytics-legend.component.scss'],
})
export class AnalyticsLegendComponent implements OnInit, OnDestroy {
  @Input()
  public newData$: Observable<void>;

  @Input()
  private data: AnalyticsChartEntity[];

  // All entities (selected or not, data or no data)
  // used to get "display name"
  @Input()
  public entities: EntityItem[];

  // List of Entity Id's that the user requested to see
  @Input()
  public selectedEntityIds: string[];

  @Input()
  public queryType: ChartQueryType;

  @Input()
  private selectedDisplayMode: CartLabelDisplayMode;

  @Input()
  public queryByEntityType: ChartEntityType;

  public get displayModeDetected(): CartLabelDisplayMode {
    if (this.queryByEntityType === ChartEntityType.Cart) {
      return this.selectedDisplayMode;
    } else {
      return CartLabelDisplayMode.Name;
    }
  }

  @Output()
  public hideAllToggled = new EventEmitter<boolean>();

  @Output()
  public visibilityToggled = new EventEmitter<AnalyticsChartEntity>();

  @Output()
  public fixedHighlightToggled = new EventEmitter<AnalyticsChartEntity>();

  @Output()
  public clearAllFixedHighlights = new EventEmitter();

  @Output()
  public itemMouseEnter = new EventEmitter<AnalyticsChartEntity>();

  @Output()
  public itemMouseLeave = new EventEmitter<AnalyticsChartEntity>();

  private get currentQueryProps(): AnalyticsQueryProperties {
    return this.analyticsHelperService.props(this.queryType);
  }

  public displayMode = CartLabelDisplayMode;

  public filteredData: AnalyticsChartEntity[];

  public noDataEntities: EntityItem[];

  // Keeps track of "show/hide all" button state
  public isShowAllOption = true;

  /**
   * Should be set to 'true' when one or more legend items are
   * clicked and have fixed highlight = true
   * Should be 'false' when all legend items have fixed highlight = false
   */
  public isHighlightMode = false;

  private subs = new Subscription();

  constructor(private analyticsHelperService: AnalyticsHelperService) {}

  ngOnInit() {
    this.transformData();

    this.subs.add(
      this.newData$.subscribe(() => {
        setTimeout(() => {
          this.isHighlightMode = false;
          this.transformData();
        }, 0);
      })
    );

    this.subs.add(
      this.analyticsHelperService.seriesClicked$.subscribe((series) =>
        this.fixedHighlightToggle(series)
      )
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private transformData() {
    // If query type X
    // then filter data differently... otherwise
    // just move forward with default behavior
    if (this.currentQueryProps.chartType === ChartType.bar) {
      switch (this.currentQueryProps.dataStructureType) {
        case AnalyticsDataStructureType.stackedPerEntity:
          // "Charging Mode - All"
          const keys = new Set<string>();
          const tempFiltered: AnalyticsChartEntity[] = [];

          // In Charging Mode, we can have 2 or even 3 duplicate EntityKey's
          // the subcategories is what differentiates (breaks down) the
          // entity into... well subcategories.
          // For the legend we just need any ONE of those items
          // The legend won't be displaying subcategories, but all of the
          // items have the same "base bg color". We also can't always pick
          // a specific subcategory because there it's a guarantee that
          // "not reporting" or "a/c power" will exist for a certain date
          this.data.forEach((x) => {
            if (!keys.has(x.EntityKey)) {
              keys.add(x.EntityKey);
              tempFiltered.push(x);
            }
          });

          this.setNoDataEntities();
          this.filteredData = tempFiltered;
          break;
        case AnalyticsDataStructureType.stackedEntityBarStacksSubEntities:
        case AnalyticsDataStructureType.regular:
        case AnalyticsDataStructureType.stackedPerDateTime:
        default:
          this.setNoDataEntities();
          break;
      }
    } else {
      this.setNoDataEntities();
    }
  }

  public setNoDataEntities(filteredData?: AnalyticsChartEntity[]) {
    // filtered data, entities with data
    this.filteredData = filteredData ? filteredData : this.data;

    // Set of ID's that have data returned
    const allIdsWithDataReturned = new Set<string>(
      this.filteredData.map((x) => x.EntityKey)
    );

    // get set of entities that were selected
    const allRequestedIds = new Set<string>([...this.selectedEntityIds]);

    const tempEntitiesNoData: EntityItem[] = [];

    // get all entities that were selected but didn't return data
    this.entities.forEach((x) => {
      if (allRequestedIds.has(x.Id) && !allIdsWithDataReturned.has(x.Id)) {
        tempEntitiesNoData.push(x);
      }
    });

    this.noDataEntities = tempEntitiesNoData;
  }

  public toggleHideAll() {
    this.isShowAllOption = !this.isShowAllOption;
    this.hideAllToggled.emit(this.isShowAllOption);
  }

  public visibilityToggle(item: AnalyticsChartEntity) {
    this.visibilityToggled.emit(item);
  }

  public btnClickClearAllFixedHighlights() {
    this.clearAllFixedHighlights.emit();
    this.isHighlightMode = false;
  }

  public fixedHighlightToggle(item: AnalyticsChartEntity) {
    if (item.Hidden) {
      // ignore highlights when item is hidden
      return;
    }

    if (item.FixedHighlight) {
      // Item is already highlighted, it's about to be "unhighlighted"
      // Check to see if any other items are highlighted, if not
      // then set "isHighlightMode" = false
      const someOtherIsHighlighted = this.data.some((x) => {
        return x.EntityKey !== item.EntityKey && x.FixedHighlight
          ? true
          : false;
      });
      if (!someOtherIsHighlighted) {
        this.isHighlightMode = false;
      }
    } else {
      this.isHighlightMode = true;
    }

    this.fixedHighlightToggled.emit(item);
  }

  public mouseEnter(item: AnalyticsChartEntity) {
    if (!item.Hidden) {
      this.itemMouseEnter.emit(item);
    }
  }

  public mouseLeave(item: AnalyticsChartEntity) {
    this.itemMouseLeave.emit(item);
  }
}
