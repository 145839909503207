import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DeviceUpdateType } from '@capsa/api';
import { EnumApi } from '@capsa/api/enum/enum.api';
import { DropDownItemGeneric, EnumTypes } from '@capsa/dropdowns/enum';
import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Component({
  selector: 'app-device-update-type-drop-down',
  templateUrl: './device-update-type-drop-down.component.html',
  styleUrls: ['./device-update-type-drop-down.component.scss'],
})
export class DeviceUpdateTypeDropDownComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public updateTypes: DropDownItemGeneric<DeviceUpdateType>[];

  public value: DropDownItemGeneric<DeviceUpdateType>;

  private subs = new Subscription();

  @Output()
  public selectionChange = new EventEmitter<
    DropDownItemGeneric<DeviceUpdateType>
  >();

  @Input()
  public defaultUpdateType: DeviceUpdateType | undefined;

  // This may need to be removed completely for non-Capsa-support users...
  private updateTypeWhitelist: DeviceUpdateType[] = [
    DeviceUpdateType.Firmware,
    DeviceUpdateType.AccessoryDistBoard,
    DeviceUpdateType.AccessoryTaskLightBoard,
    DeviceUpdateType.AccessoryLockingBoard,
  ];

  constructor(private enumApi: EnumApi) {}

  ngOnInit() {
    this.loadDeviceUpdateTypes();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectionChanged(value: DropDownItemGeneric<DeviceUpdateType>) {
    this.selectionChange.emit(value);
  }

  valueChanged(value: DropDownItemGeneric<DeviceUpdateType>) {
    this.value = value;
  }

  public makeTranslationTag(label: string) {
    return `DeviceUpdateType_${label}`;
  }

  private loadDeviceUpdateTypes() {
    this.loading = true;

    const sub = this.enumApi
      .getEnum(EnumTypes.DeviceUpdateType)
      .pipe(
        map((e) =>
          e.EnumValues.filter((value) =>
            this.updateTypeWhitelist.includes(value.Value as number)
          ).sort((a, b) => a.Label.localeCompare(b.Label))
        ),
        finalize(() => (this.loading = false))
      )
      .subscribe((results) => {
        this.updateTypes = results.map((x) => ({
          Label: x.Label,
          Value: x.Value as DeviceUpdateType,
        }));

        this.value =
          this.updateTypes.find((s) => s.Value === this.defaultUpdateType) ||
          this.updateTypes[0];

        this.selectionChange.emit(this.value);
      });
    this.subs.add(sub);
  }
}
