import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LightingLedThemeConfigComponent } from 'app/modules/lighting/lighting-led-theme-config/lighting-led-theme-config.component';
import { LightingComponent } from 'app/modules/lighting/lighting/lighting.component';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { LightingLedColorThemesComponent } from './lighting-led-color-themes/lighting-led-color-themes.component';

@NgModule({
  declarations: [
    LightingComponent,
    LightingLedColorThemesComponent,
    LightingLedThemeConfigComponent,
  ],
  imports: [
    CommonModule,
    CapsaDropdownsModule,
    TabStripModule,
    TranslateModule,
    ApiModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    InputsModule,
    LoadingIndicatorModule,
    LabelsModule,
  ],
})
export class LightingModule {}
