import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ApiResponse,
  DeviceProfileCreateRequestApiModel,
  DeviceProfileUpdateRequestApiModel,
} from '@capsa/api';
import { CartProfileApi } from '@capsa/api/cart-profile';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';
import { GridFilterConfiguration } from 'app/modules/cart-profile/helpers';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cart-profile-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
})
export class EditPageComponent implements OnInit, OnDestroy {
  public filters: GridFilterConfiguration = {
    deviceTypeId: undefined,
    facilityId: undefined,
    organizationId: undefined,
  };
  public form: UntypedFormGroup;
  public saving = false;

  // Note this is just for the initial load, and not for the save
  public isLoadingPage = true;

  public apiResponse: ApiResponse<any> | undefined;

  public get id(): number {
    const idString = this.route.snapshot.paramMap.get('id');
    return Number(idString);
  }

  private subs = new Subscription();

  constructor(
    private api: CartProfileApi,
    public settingsFormHelper: ApiSettingsFormHelper,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });

    this.load();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private load() {
    const taskId = 'loadProfile';
    this.loaderService.start(taskId);
    this.isLoadingPage = true;

    this.subs.add(
      this.api
        .getById(this.id)
        .pipe(
          finalize(() => {
            this.loaderService.stop(taskId);
            this.isLoadingPage = false;
          })
        )
        .subscribe(
          (resp) => {
            const profile = resp.Result.DeviceProfile;
            this.settingsFormHelper.load(resp.Result.Settings);

            this.form.controls.name.setValue(profile.ProfileName);
            this.filters = {
              deviceTypeId: profile.DeviceTypeId,
              organizationId: profile.OrganizationId,
              facilityId: profile.FacilityId,
            };
          },
          (error) => {
            this.toasterService.showError('DEVICE_PROFILE_LOAD_ERROR');
          }
        )
    );
  }

  public save(model: DeviceProfileCreateRequestApiModel) {
    const taskId = 'loadProfile';
    this.loaderService.start(taskId);
    this.saving = true;

    const updateModel: DeviceProfileUpdateRequestApiModel = {
      Name: model.Name,
      Settings: model.Settings,
    };

    this.subs.add(
      this.api
        .update(this.id, updateModel)
        .pipe(
          finalize(() => {
            this.loaderService.stop(taskId);
            this.saving = false;
          })
        )
        .subscribe(
          (resp) => {
            this.toasterService.showSuccess('DEVICE_PROFILE_UPDATED_SUCCESS');
          },
          (error) => {
            this.toasterService.showError('DEVICE_PROFILE_SAVE_ERROR');
          }
        )
    );
  }
}
