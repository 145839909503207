import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DeviceTypeApiModel,
  DeviceTypeIds,
  NameWithLongIdApiModel,
  ProductLineIds,
} from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';
import { FacilityDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-drop-down-data-source';
import { OrganizationDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-drop-down-data-source';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { UserMemoryService } from '@capsa/services/user-memory/user-memory.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-org-facility-drop-downs',
  templateUrl: './org-facility-drop-downs.component.html',
  styleUrls: ['./org-facility-drop-downs.component.scss'],
})
export class OrgFacilityDropDownsComponent implements OnInit, OnDestroy {
  public get includeDeviceType() {
    return this.deviceTypeChanged.observers.length > 0;
  }

  public get includeFacility() {
    return this.facilityIdChanged.observers.length > 0;
  }

  constructor(
    public orgDataSource: OrganizationDropDownDataSource,
    public facilityDataSource: FacilityDropDownDataSource,
    public deviceTypeDataSource: DeviceTypeDropDownDataSource,
    private userMemoryService: UserMemoryService,
    private translateService: TranslateService,
    private userEofCacheService: UserEofCacheService,
    private permissionService: PermissionService
  ) {}

  @Output()
  public organizationIdChanged = new EventEmitter<number | undefined>();

  @Output()
  public facilityIdChanged = new EventEmitter<number | undefined>();

  @Output()
  public deviceTypeChanged = new EventEmitter<number | undefined>();

  @Output()
  public facilitiesListUpdated = new EventEmitter<
    AsyncDropDownValue<NameWithLongIdApiModel>[]
  >();

  @Input()
  public allReadonly = false;

  @Input()
  public includeContent = false;

  /**
   * If "includeContent" is true, this gives developer flexibility to change the content width
   * For example if only Org/Facility drop-downs are shown (3 cols each), then there's another 6 columns available
   * if org/facility and device type are shown, then only 3 columns would be left
   */
  @Input()
  public contentColWidth = 3;

  @Input()
  public organizationId: number | undefined;

  @Input()
  public facilityId: number | undefined;

  @Input()
  public organizationReadonly: boolean;

  @Input()
  public facilityReadonly: boolean;

  @Input()
  public deviceTypeReadonly: boolean;

  @Input()
  public deviceType: number | undefined;

  @Input()
  public autoSelectFacility = true;

  @Input()
  public autoSelectOrg = true;

  @Input()
  public includeDeviceTypeAllOption = true;

  public facilityDisabled = true;

  private subs = new Subscription();

  public facilityName(): string {
    return this.facilityDataSource.selection
      ? this.facilityDataSource.selection.name
      : 'N/A';
  }

  public get orgDropDownHidden() {
    return !this.userEofCacheService.userHasMultipleOrgs;
  }

  public orgName(): string {
    return this.orgDataSource.selection
      ? this.orgDataSource.selection.name
      : 'N/A';
  }

  public deviceTypeName(): string {
    return this.deviceTypeDataSource.selection
      ? this.deviceTypeDataSource.selection.name
      : 'N/A';
  }

  private orgIdChangedSubj = new Subject<number>();
  public orgIdChanged$ = this.orgIdChangedSubj.asObservable();

  private facilityIdChangedSubj = new Subject<number>();
  public facilityIdChanged$ = this.facilityIdChangedSubj.asObservable().pipe(
    // This helps prevent double "emits" from happening when a page is loaded
    // the problem is that when a page is loaded first the orgId is "auto-selected"
    // which briefly (≈10ms) causes a "facility changed to undefined" emit immediately
    // followed by the auto-selected facilityId
    // in the Permission Service it causes duplicate 'facility changed' emits with the same facilityId (since it's cached)
    debounceTime(50)
  );

  ngOnInit() {
    this.subs.add(
      this.orgIdChanged$.subscribe((oId) =>
        this.organizationIdChanged.next(oId)
      )
    );

    this.subs.add(
      this.facilityIdChanged$.subscribe((fId) => {
        this.facilityIdChanged.next(fId);
        this.permissionService.notifyOfFacilityChangeSubj.next(fId);
      })
    );

    this.orgDataSource.autoSelect = this.autoSelectOrg;
    this.orgDataSource.organizationId = this.facilityDataSource.organizationId =
      this.organizationId;

    this.facilityDataSource.facilityId = this.facilityId;
    this.facilityDataSource.autoSelect = this.autoSelectFacility;

    this.deviceTypeDataSource.deviceTypeId = this.deviceType;

    this.loadOrgs();
    this.loadDeviceTypes();

    if (this.facilityDataSource.organizationId) {
      this.loadFacilities();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public orgChanged(newValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    this.orgDataSource.organizationId = !newValue.apiModel
      ? undefined
      : newValue.apiModel.Id;

    this.facilityDataSource.organizationId = this.orgDataSource.organizationId;
    this.facilityDataSource.reset();

    if (this.facilityDataSource.organizationId) {
      this.loadFacilities();
    } else {
      this.facilityDisabled = true;
    }

    // only update local storage if orgId has changed, otherwise
    // we will overwrite a valid facilityId
    if (
      this.userMemoryService.userMemory.orgId !==
      this.orgDataSource.organizationId
    ) {
      this.userMemoryService.setUserLastOrgFacility(
        this.orgDataSource.organizationId,
        undefined
      );
    }

    this.orgIdChangedSubj.next(this.facilityDataSource.organizationId);
    this.facilityIdChangedSubj.next(undefined);
  }

  public facilityChanged(newValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    this.facilityDataSource.facilityId = !newValue.apiModel
      ? undefined
      : newValue.apiModel.Id;

    this.userMemoryService.setUserLastOrgFacility(
      this.orgDataSource.organizationId,
      this.facilityDataSource.facilityId
    );

    this.facilityIdChangedSubj.next(this.facilityDataSource.facilityId);
  }

  public onDeviceTypeChanged(newValue: AsyncDropDownValue<DeviceTypeApiModel>) {
    this.deviceType = this.deviceTypeDataSource.deviceTypeId =
      !newValue.apiModel ? undefined : newValue.apiModel.DeviceTypeId;

    this.deviceTypeChanged.emit(this.deviceType);

    if (this.deviceType !== undefined) {
      this.deviceTypeDataSource.lastSelectedDeviceTypeId = this.deviceType;
    }
  }

  private loadOrgs() {
    this.subs.add(
      this.orgDataSource.load().subscribe((items) => {
        this.orgDataSource.data = items;
        if (items.length === 1) {
          this.orgChanged(items[0]);
        } else if (
          this.orgDataSource.autoSelect &&
          this.userMemoryService.userMemory.orgId
        ) {
          const match = items.find(
            (x) => x.id === this.userMemoryService.userMemory.orgId.toString()
          );
          if (match) {
            this.orgChanged(match);
          }
        }
      })
    );
  }

  private loadFacilities() {
    if (this.includeFacility) {
      this.subs.add(
        this.facilityDataSource.load().subscribe((items) => {
          this.facilityDataSource.data = items;
          this.facilitiesListUpdated.emit(items);
          this.facilityDisabled = false;

          if (items.length === 1) {
            this.facilityChanged(items[0]);
          } else if (
            this.facilityDataSource.autoSelect &&
            this.userMemoryService.userMemory.facilityId
          ) {
            const match = items.find(
              (x) =>
                x.id === this.userMemoryService.userMemory.facilityId.toString()
            );
            if (match) {
              this.facilityChanged(match);
            }
          }
        })
      );
    } else {
      this.facilityDisabled = true;
    }
  }

  private loadDeviceTypes() {
    if (this.includeDeviceType) {
      this.subs.add(
        this.deviceTypeDataSource.load().subscribe((items) => {
          // filter for the "all" product line stuff.
          let visibleDeviceTypes = items;
          const allDeviceTypeOption: AsyncDropDownValue<DeviceTypeApiModel> = {
            id: DeviceTypeIds.All.toString(),
            name: this.translateService.instant('COM_ALL'),
            apiModel: {
              DeviceTypeId: DeviceTypeIds.All,
              ProductLineId: ProductLineIds.CareLink,
            } as any,
          };

          if (this.includeDeviceTypeAllOption) {
            visibleDeviceTypes = [allDeviceTypeOption].concat(
              visibleDeviceTypes
            );
          }

          this.deviceTypeDataSource.data = visibleDeviceTypes;

          if (this.includeDeviceTypeAllOption) {
            if (this.deviceTypeDataSource.hasStoredSelection) {
              this.autoSelectDeviceFromLastStored(visibleDeviceTypes);
            } else {
              this.autoSetDeviceType(allDeviceTypeOption);
            }
          } else {
            if (this.deviceTypeDataSource.hasStoredSelection) {
              if (
                this.deviceTypeDataSource.lastSelectedDeviceTypeId ===
                DeviceTypeIds.All
              ) {
                this.autoSelectTrio(visibleDeviceTypes);
              } else {
                this.autoSelectDeviceFromLastStored(visibleDeviceTypes);
              }
            } else {
              this.autoSelectTrio(visibleDeviceTypes);
            }
          }
        })
      );
    }
  }

  private autoSelectTrio(
    deviceTypesList: AsyncDropDownValue<DeviceTypeApiModel>[]
  ) {
    const match = deviceTypesList.find(
      (x) => x.id === DeviceTypeIds.CareLink_2.toString()
    );

    this.autoSetDeviceType(match);
  }

  private autoSelectDeviceFromLastStored(
    deviceTypesList: AsyncDropDownValue<DeviceTypeApiModel>[]
  ) {
    const match = deviceTypesList.find(
      (x) =>
        x.id === this.deviceTypeDataSource.lastSelectedDeviceTypeId.toString()
    );

    this.autoSetDeviceType(match);
  }

  private autoSetDeviceType(newValue: AsyncDropDownValue<DeviceTypeApiModel>) {
    this.deviceType = this.deviceTypeDataSource.deviceTypeId =
      !newValue.apiModel ? undefined : newValue.apiModel.DeviceTypeId;

    this.deviceTypeDataSource.selectItem(newValue);
    this.deviceTypeChanged.emit(this.deviceType);
  }
}
