import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DropDownItemNumber } from '@capsa/dropdowns/enum';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { TranslateService } from '@ngx-translate/core';
import { EofInfo } from 'app/modules/support/support-transfer-carts/eof-transfer-info';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-support-transfer-cart-facility',
  templateUrl: './support-transfer-cart-facility.component.html',
  styleUrls: ['./support-transfer-cart-facility.component.scss'],
})
export class SupportTransferCartFacilityComponent implements OnInit, OnDestroy {
  @Input()
  allFacilities$: Observable<DropDownItemNumber[]>;

  @Input()
  areCartsSelected: boolean;

  @Output()
  transferTriggered = new EventEmitter<EofInfo>();

  public get disableTransfer(): boolean {
    return (
      !this.canEdit ||
      !this.areCartsSelected ||
      !this.sourceFacilityId ||
      !this.selectedTargetFacility ||
      !this.selectedTargetFacility.Value
    );
  }

  private sourceOrgId: number;
  public sourceFacilityId: number;
  public canEdit = false;

  private allFacilities: DropDownItemNumber[] = [];
  public targetFacilities: DropDownItemNumber[];

  public selectedTargetFacility: DropDownItemNumber;

  private subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.loadPermissions();

    this.subs.add(
      this.allFacilities$.subscribe((facilities) => {
        this.handleAllFacilitiesChanged(facilities);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.Manage_Cabinets_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        this.sourceOrgId = this.permissionService.orgId;
        this.sourceFacilityId = this.permissionService.facilityId;
        this.canEdit = this.permissionService.has(editPerm);
        this.updateTargetFacilitiesDropDown();
      })
    );

    this.sourceOrgId = this.permissionService.orgId;
    this.sourceFacilityId = this.permissionService.facilityId;
    this.canEdit = this.permissionService.has(editPerm);
    this.updateTargetFacilitiesDropDown();
  }

  private handleAllFacilitiesChanged(facilities: DropDownItemNumber[]) {
    4;
    this.allFacilities = facilities;
    this.updateTargetFacilitiesDropDown();
  }

  private updateTargetFacilitiesDropDown(): void {
    this.targetFacilities = [
      {
        Label: this.translateService.instant('COM_SELECT_FACILITY'),
        Value: null,
      },
      ...this.allFacilities.filter((x) => x.Value !== this.sourceFacilityId),
    ];
    4;
    this.selectedTargetFacility = this.targetFacilities[0];
  }

  public targetFacilityChanged(selectedItem: DropDownItemNumber) {
    this.selectedTargetFacility = selectedItem;
  }

  public onBtnStartCartTransfer(): void {
    // If somehow button was clicked when it wasn't supposed to - stop things!
    if (
      !this.sourceOrgId ||
      !this.sourceFacilityId ||
      !this.selectedTargetFacility.Value ||
      !this.areCartsSelected
    ) {
      return;
    }

    this.transferTriggered.emit({
      OrgId: this.sourceOrgId, // Changing org not supported?....
      FacilityId: this.selectedTargetFacility.Value,
    });
  }
}
