import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CapsaSettingsTypeIds,
  DeviceTypeIds,
  FacilityResponse,
  FacilityUpdateRequest,
  ProductLineIds,
  SettingResponse,
  SettingUpdateRequest,
  getProductLine,
} from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { AppInsightsService } from '@capsa/services/app-insights/app-insights.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import {
  AllFacilitySettings,
  FacilitySettingHelper,
} from 'app/modules/facility/facility-setting-helper';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-facility-manage',
  templateUrl: './facility-manage.component.html',
  styleUrls: ['./facility-manage.component.scss'],
})
export class FacilityManageComponent implements OnInit, OnDestroy {
  @ViewChild('tabStrip', { static: false })
  public tabStrip: TabStripComponent;
  private lastTabIndexSelected = 0;

  public facilityInfo: FacilityResponse;
  public allSettings: AllFacilitySettings;

  public get formValid(): boolean {
    return this.alertsValid && this.serviceRequestsValid && this.profileValid;
  }
  public get isAvalo(): boolean {
    return this.deviceTypeId === DeviceTypeIds.Avalo;
  }

  public get canSave(): boolean {
    return (
      this.canEdit &&
      !this.loaderService.isLoading &&
      this.formValid &&
      !!this.deviceTypeId &&
      !!this.facilityId
    );
  }
  private alertsValid = true;
  private serviceRequestsValid = true;
  private profileValid = true;

  private subs: Subscription = new Subscription();
  public orgId: number | undefined;
  public facilityId: number | undefined;
  public deviceTypeId: number | undefined;
  public canEdit = false;

  private pristineSettings = new Map<CapsaSettingsTypeIds, string>();
  private productLineId: number | undefined;

  private facilitySavingSub = new Subject<FacilityUpdateRequest>();

  public facilitySaving$ = this.facilitySavingSub.asObservable();
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Facilities];

  constructor(
    private facilityApi: FacilityApi,
    private permissionService: PermissionService,
    private eofCacheService: EofCacheService,
    private translateService: TranslateService,
    public loaderService: LoaderService,
    private toasterService: ToasterService,
    private aiService: AppInsightsService
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public get areEssentialsReady() {
    return (
      this.facilityInfo && this.allSettings && !this.loaderService.isLoading
    );
  }

  public get isUnknownProduct() {
    return getProductLine(this.deviceTypeId) === ProductLineIds.Unknown;
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(
            Permissions.Manage_Facilities_ReadWrite
          );

          this.refreshFacilityDetails();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;

    this.canEdit = this.permissionService.has(
      Permissions.Manage_Facilities_ReadWrite
    );
  }

  public orgIdChanged(newValue: number | undefined) {
    this.orgId = newValue;
  }

  public facilityIdChanged(newValue: number | undefined) {
    this.facilityId = newValue;
  }

  public onDeviceTypeChanged(newValue: number | undefined) {
    this.deviceTypeId = newValue;
    const oldProductLine = this.productLineId;

    this.productLineId = getProductLine(this.deviceTypeId);

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    if (this.productLineId !== oldProductLine) {
      // When product line changes, reselect default tab.
      this.tabStrip.selectTab(0);

      if (this.productLineId !== ProductLineIds.Unknown) {
        // reload since our product line changed.
        this.refreshFacilityDetails();
      }
    }
  }

  public formValidStatusAlerts(valid: boolean) {
    this.alertsValid = valid;
  }

  public formValidStatusServiceRequests(valid: boolean) {
    this.serviceRequestsValid = valid;
  }

  public formValidStatusProfile(valid: boolean) {
    this.profileValid = valid;
  }

  public btnSaveAll() {
    this.saveAll();
  }

  private saveAll() {
    this.aiService.btnClickTrace('FacilitySaveAllSettings');
    this.loaderService.start();
    const updateRequest: FacilityUpdateRequest = {
      ...(this.facilityInfo as any),
      Settings: undefined, // set later in this method
      UpdateSettings: undefined, // set later in this method
      UpdateNotificationGroups: true,
      NotificationGroups: [],
    };

    this.facilitySavingSub.next(updateRequest);
    const settingsToSave: SettingUpdateRequest[] = [];

    this.allSettings.displayAndAlerts.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.pins.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.timeout.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.loginRequired.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.notifications.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.serviceRequests.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    this.allSettings.battery.forEach((val, key, map) => {
      settingsToSave.push(this.mapSettingToUpdateRequest(val));
    });

    // now exclude all settings whose values did not actually update.
    const updatedSettings = settingsToSave.filter(
      (s) => this.pristineSettings[s.SettingType] !== s.SettingValue
    );

    updateRequest.Settings = updatedSettings;
    updateRequest.UpdateSettings = updatedSettings.length > 0;

    const sub = this.facilityApi.update(updateRequest).subscribe(
      (resp) => {
        if (!resp.Success) {
          resp.Message = this.translateService.instant(resp.Message);
        }

        this.toasterService.showSuccess('SETTINGS_UPDATED_SUCCESS');

        // In case facility was renamed, we update the cache
        this.eofCacheService.updateFacilityName(
          this.facilityId,
          updateRequest.Name
        );

        this.refreshFacilityDetails();
      },
      (error) => {
        this.loaderService.stop();
      }
    );

    this.subs.add(sub);
  }

  public updateSelectedTabIndex(ev: SelectEvent) {
    this.aiService.tabClickTrace(ev.index, { name: ev.title });
    this.lastTabIndexSelected = ev.index;
  }

  private mapSettingToUpdateRequest(
    setting: SettingResponse
  ): SettingUpdateRequest {
    const updateSettingRequest: SettingUpdateRequest = {
      SettingId: setting.SettingId,
      EnterpriseId: setting.EnterpriseId,
      OrganizationId: setting.OrganizationId,
      FacilityId: setting.FacilityId,
      CartId: setting.CartId,
      DeviceProfileId: null,
      DeviceTypeId: setting.DeviceTypeId,
      CapsaUserId: setting.CapsaUserId,
      SettingType: setting.SettingType,
      SettingValue: setting.SettingValue,
      Version: setting.Version,
      IsOverridable: setting.IsOverridable,
      IsEncrypted: setting.IsEncrypted,
    };
    return updateSettingRequest;
  }

  private refreshFacilityDetails() {
    if (this.orgId && this.facilityId && this.deviceTypeId) {
      this.loaderService.start();
      const getFacilitySub = this.facilityApi
        .getFacility(this.facilityId, ProductLineIds[this.productLineId])
        .subscribe(
          (resp) => {
            this.facilityInfo = resp.Result;
            this.allSettings = FacilitySettingHelper.getSortedFacilitySettings(
              resp.Result.Settings
            );

            this.facilityInfo.Settings.forEach((s) => {
              this.pristineSettings[s.SettingType] = s.SettingValue;
            });

            this.loaderService.stop();

            this.reselectLastTab();
          },
          (error) => {
            this.loaderService.stop();
          }
        );

      this.subs.add(getFacilitySub);
    } else {
      this.loaderService.stop();
      this.facilityInfo = undefined;
      this.allSettings = undefined;
    }
  }

  public migrateBatteryRange() {
    const fId = this.permissionService.facilityId;
    const taskId = 'migrateBatterySettings';
    this.loaderService.start(taskId);
    this.subs.add(
      this.facilityApi
        .clearCartLevelBatteryRangeSettings(fId)
        .pipe(
          finalize(() => {
            this.loaderService.stop(taskId);
            this.reselectLastTab();
          })
        )
        .subscribe(
          (resp) => {
            // this.hasNonDefaultBatteryRangeSettings = false;
            this.toasterService.showSuccess('Migration success', {
              hideAfterMs: 8000,
            });
          },
          (error) => {
            this.toasterService.showError(
              'Migration failed. Try again or contact support.',
              { hideAfterMs: 10000 }
            );
          }
        )
    );
  }

  private reselectLastTab() {
    // If we don't do "setTimeout - 0", then the selectTab will be too soon and nothing will be selected
    // this way we allow anything pending in the stack/queue to complete (re-init of tabs) and then
    // do the tab select
    setTimeout(() => {
      this.tabStrip.selectTab(this.lastTabIndexSelected);
    }, 0);
  }
}
