import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CartGroupResponse,
  CartPartialResponse,
  CartSearchRequest,
  DeviceTypeIds,
  NameWithLongIdApiModel,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { CartGroupApi } from '@capsa/api/cart-group';
import { SetupApi } from '@capsa/api/setup';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DropDownItemNumber } from '@capsa/dropdowns/enum';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { RowArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import {
  EofInfo,
  EofTransferInfo,
} from 'app/modules/support/support-transfer-carts/eof-transfer-info';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-support-transfer-carts',
  templateUrl: './support-transfer-carts.component.html',
  styleUrls: ['./support-transfer-carts.component.scss'],
})
export class SupportTransferCartsComponent implements OnInit, OnDestroy {
  private allFacilitiesBehSubj = new BehaviorSubject<DropDownItemNumber[]>([]);
  public allFacilities$ = this.allFacilitiesBehSubj.asObservable();
  public deviceTypeId: DeviceTypeIds;
  public isCartTransferDialogOpen = false;
  public eofTransferInfo: EofTransferInfo;

  public loading = false;
  private cartListUnfiltered: CartPartialResponse[];
  public selectedCarts: CartPartialResponse[] = [];
  public numSelectedCarts = 0;
  private gridDataBehSub = new BehaviorSubject<CartPartialResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();

  private gridCartGroupDataBehSub = new BehaviorSubject<CartGroupResponse[]>(
    []
  );
  public gridCartGroupData$ = this.gridCartGroupDataBehSub.asObservable();
  public loadingGroups = false;

  // key = group ID
  // value = cart ID's array
  private cartGroupsMap = new Map<number, Set<number>>();

  public sourceEnterpriseId: number;
  public sourceOrgId: number;
  public sourceFacilityId: number;
  public gridSelectedCartIds: number[] = [];

  public canManageEnterprise: boolean;
  public canManageOrgs: boolean;
  public hasMultipleOrgs: boolean;

  public cartListFilter: CompositeFilterDescriptor;
  private subs = new Subscription();
  private getSourceCartsSub = new Subscription();
  private getCartGroupsSub = new Subscription();

  private cartGridSelectionService: GridSelectionService;
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Carts];

  constructor(
    private cartApi: CartApi,
    private setupApi: SetupApi,
    private permissionService: PermissionService,
    private userEofCache: UserEofCacheService,
    private cartGroupApi: CartGroupApi,
    private enterpriseService: EnterpriseService,
    private toasterService: ToasterService,
    private gridSelectionServiceFactory: GridSelectionServiceFactory
  ) {}

  ngOnInit() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.refreshGroupsList();
          this.refreshCartsList();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;

    this.canManageEnterprise = this.permissionService.has(
      Permissions.Manage_CrossEnterpiseData_ReadWrite
    );

    this.canManageOrgs = this.permissionService.has(
      Permissions.Manage_Organizations_ReadWrite
    );

    this.hasMultipleOrgs = this.userEofCache.organizations.length > 1;

    this.cartGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectRowIds
    );

    this.subs.add(
      this.cartGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.gridSelectedCartIds = newSelection;

          this.selectedCarts = this.gridDataBehSub.value.filter((x) =>
            this.gridSelectedCartIds.some((y) => y === x.Id)
          );
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.getSourceCartsSub.unsubscribe();
    this.getCartGroupsSub.unsubscribe();
  }

  private selectRowIds(rows: RowArgs[]) {
    return rows.map((row) => row.dataItem.Id);
  }

  public get areCartsSelected() {
    return this.selectedCarts && this.selectedCarts.length > 0;
  }
  public sourceEnterpriseIdChanged(newValue: number | undefined) {
    this.sourceEnterpriseId = newValue;
    this.sourceOrgId = undefined;
    this.sourceFacilityId = undefined;
    this.refreshCartsList();
  }

  public sourceOrgIdChanged(newValue: number | undefined) {
    this.sourceOrgId = newValue;
    this.sourceFacilityId = undefined;
    if (this.canManageEnterprise) {
      this.refreshCartsList();
    }
  }

  public sourceFacilityIdChanged(newValue: number | undefined) {
    this.sourceFacilityId = newValue;
    if (this.canManageEnterprise) {
      this.refreshCartsList();
    }
  }

  public facilityListUpdated(
    dataItems: AsyncDropDownValue<NameWithLongIdApiModel>[]
  ) {
    const tempList: DropDownItemNumber[] = [];
    dataItems.forEach((item) => {
      tempList.push({ Label: item.name, Value: parseInt(item.id, 10) });
    });

    this.allFacilitiesBehSubj.next(tempList);
  }

  public onCartSelectionChange(event: SelectionEvent) {
    this.cartGridSelectionService.updateSelection(event);
  }

  private refreshGroupsList(): void {
    this.gridCartGroupDataBehSub.next([]);
    this.cartGroupsMap = new Map<number, Set<number>>();

    // Not supporting any cart groups in "cross enterprise" mode
    if (
      this.canManageEnterprise ||
      !this.sourceOrgId ||
      !this.sourceFacilityId
    ) {
      return;
    }

    // TODO, add validation

    // Cancel any pending API calls to cart groups endpoint
    this.getCartGroupsSub.unsubscribe();
    this.loadingGroups = true;

    this.getCartGroupsSub = this.cartGroupApi
      .search({
        EnterpriseId: this.enterpriseService.enterpriseId,
        OrganizationIds: [this.sourceOrgId],
        FacilityIds: [this.sourceFacilityId],
        PageNumber: 1,
        PageSize: 10000,
      })
      .subscribe(
        (resp) => {
          this.gridCartGroupDataBehSub.next(resp.Result);

          resp.Result.forEach((group) => {
            const carts = group.Members.map((c) => c.CartId);
            this.cartGroupsMap.set(group.CartGroupId, new Set(carts));
          });

          this.loadingGroups = false;
        },
        (error) => {
          this.toasterService.showError(
            'ERR_GETTING_LISTS_OF_CARTS_AND_GROUPS'
          );

          this.loadingGroups = false;
        }
      );
  }

  public refreshCartsList(): void {
    this.selectedCarts = [];
    this.gridSelectedCartIds = [];
    this.cartGridSelectionService.clearSelection();
    this.gridDataBehSub.next([]);

    if (
      this.deviceTypeId === undefined ||
      (!this.sourceEnterpriseId && this.canManageEnterprise) ||
      !this.sourceOrgId ||
      !this.sourceFacilityId
    ) {
      return;
    }

    this.getSourceCartsSub.unsubscribe();
    this.loading = true;

    let getCartsApiCall: Observable<CartPartialResponse[]>;

    if (this.canManageEnterprise) {
      getCartsApiCall = this.getCartsCrossEnterprise();
    } else {
      getCartsApiCall = this.getCartsUserEnterprise();
    }

    this.getSourceCartsSub = getCartsApiCall.subscribe(
      (partialCarts) => {
        this.gridDataBehSub.next(partialCarts);
        this.cartListUnfiltered = partialCarts;
        this.loading = false;
      },
      (error) => {
        this.toasterService.showError('GETTING_CARTS_LIST_FAILED');
        this.loading = false;
      }
    );
  }

  private getCartsUserEnterprise(): Observable<CartPartialResponse[]> {
    return this.cartApi.getCartsPartial(
      { PageNumber: 1, PageSize: 10000 },
      this.sourceOrgId,
      this.sourceFacilityId,
      this.deviceTypeId ? this.deviceTypeId : undefined
    );
  }

  private getCartsCrossEnterprise(): Observable<CartPartialResponse[]> {
    const request: Partial<CartSearchRequest> = {
      EnterpriseId: this.sourceEnterpriseId,
      OrganizationIds: [this.sourceOrgId],
      FacilityIds: [this.sourceFacilityId],
      DeviceTypeId: this.deviceTypeId ? this.deviceTypeId : undefined,
    };

    return this.setupApi.crossEnterpriseCartSearchPartial(request);
  }

  public onDeviceTypeChanged(newValue: number | undefined) {
    this.deviceTypeId = newValue;
    this.refreshCartsList();
  }

  public onCartListFilterChanged(data: CompositeFilterDescriptor) {
    this.gridDataBehSub.next(filterBy(this.cartListUnfiltered, data));
    this.cartListFilter = data;
  }

  public openTransferDialog(targetEofInfo: EofInfo) {
    this.isCartTransferDialogOpen = true;
    this.gridSelectedCartIds = [];
    this.eofTransferInfo = {
      source: {
        EnterpriseId: this.sourceEnterpriseId,
        OrgId: this.sourceOrgId,
        FacilityId: this.sourceFacilityId,
      },
      target: targetEofInfo,
    };
  }

  public onCartTransferDialogClose() {
    this.isCartTransferDialogOpen = false;
    this.refreshGroupsList();
    this.refreshCartsList();
  }

  public onBtnSelectGroupCarts(groupItem: CartGroupResponse) {
    const selectedCarts: CartPartialResponse[] = [];
    const groupCartIds = this.cartGroupsMap.get(groupItem.CartGroupId);

    groupCartIds.forEach((cartIdToSelect) => {
      const indexToSelect = this.gridSelectedCartIds.findIndex(
        (x) => x === cartIdToSelect
      );
      // Select cart if not already selected
      if (indexToSelect === -1) {
        selectedCarts.push({
          Id: cartIdToSelect,
          Name: null,
          SerialNumber: null,
        });
      }
    });

    this.cartGridSelectionService.forceChanges(
      selectedCarts.map((cartPartial) => ({
        dataItem: cartPartial,
        index: 0,
      })),
      []
    );
  }
}
