import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';

export interface AsyncDropDownValue<TModel> {
  id: string;
  name: string;
  apiModel: TModel;
}

/**
 * Represents and async dropdown list. Used to set/maintain standard settings
 * for kendo async dropdown lists.
 */
@Directive()
export abstract class AbstractAsyncDropDownDirective<TModel> {
  /**
   *
   */
  constructor() {}

  @Input()
  public dataSource: AbstractDropDownListDataSource<TModel>;

  @Input()
  public readonly: boolean;

  /**
   * When the value of the dropdown changes, this event is fired
   * with the new @type {TModel} value.
   */
  @Output()
  public valueChanged = new EventEmitter<
    AsyncDropDownValue<TModel> | AsyncDropDownValue<TModel>[]
  >();

  onChange = (
    newValue: AsyncDropDownValue<TModel> | AsyncDropDownValue<TModel>[]
  ) => {
    this.valueChanged.emit(newValue);
  };
}
