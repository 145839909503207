import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { WarningGridRowItem } from './battery-warnings-trio.component';

export const uniquePercentage =
  (warnings: WarningGridRowItem[]) =>
  (form: UntypedFormGroup): ValidationErrors | null => {
    const gridItem = form.value as WarningGridRowItem;

    if (
      warnings.length === 0 ||
      gridItem === null ||
      gridItem.percent === null
    ) {
      return null;
    }

    const allOtherPercentages = warnings
      .filter((w) => w.id !== gridItem.id)
      .map((w) => w.percent);

    if (allOtherPercentages.some((p) => p === gridItem.percent)) {
      return { percentOverlap: true };
    }

    return null;
  };
