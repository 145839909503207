import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';

import { ApiModule } from 'app/modules/api-module/api.module';
import { LabelsModule } from 'app/modules/labels/labels.module';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CartGroupManagePageComponent } from 'app/modules/cart-group/pages/manage-page/manage-page.component';
import { CartGroupEditPageComponent } from './pages/edit-page/edit-page.component';

@NgModule({
  declarations: [CartGroupManagePageComponent, CartGroupEditPageComponent],
  exports: [CartGroupManagePageComponent, CartGroupEditPageComponent],
  imports: [
    ApiModule,
    ButtonsModule,
    CapsaDropdownsModule,
    CommonModule,
    FormsModule,
    GridModule,
    InputsModule,
    LabelsModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class CartGroupModule {}
