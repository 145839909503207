// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-preview {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid gray;
  margin-right: 8px;
}

.color-NONE {
  visibility: hidden;
}
.color-WHITE {
  background-color: #ffffff;
}
.color-GREEN {
  background-color: #00ff00;
}
.color-POWDERBLUE {
  background-color: #00b0ff;
}
.color-LEMON {
  background-color: #ffd700;
}
.color-PLUM {
  background-color: #ff1493;
}
.color-DEEPBLUE {
  background-color: #0000ff;
}
.color-RED {
  background-color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/data-driven-controls/data-driven-color-drop-down/data-driven-color-drop-down.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAGE;EACE,kBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".color-preview {\n  display: inline-block;\n  width: 1em;\n  height: 1em;\n  border: 1px solid gray;\n  margin-right: 8px;\n}\n\n.color- {\n  &NONE {\n    visibility: hidden;\n  }\n  &WHITE {\n    background-color: #ffffff;\n  }\n  &GREEN {\n    background-color: #00ff00;\n  }\n  &POWDERBLUE {\n    background-color: #00b0ff;\n  }\n  &LEMON {\n    background-color: #ffd700;\n  }\n  &PLUM {\n    background-color: #ff1493;\n  }\n  &DEEPBLUE {\n    background-color: #0000ff;\n  }\n  &RED {\n    background-color: #ff0000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
