import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  CartFriendlyUpdateGridItem,
  CartLabelDisplayMode,
  DeviceUpdateType,
  UpdateStatusType,
} from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { GridSelectionServiceFactory } from '@capsa/services/grid-selection';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Utils } from 'app/common/utils';
import { ApplyDeviceUpdateService } from 'app/modules/update-cart-firmware/apply-device-update.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-update-cart-firmware-friendly',
  templateUrl: './update-friendly.component.html',
  styleUrls: ['./update-friendly.component.scss'],
})
export class UpdateCartFirmwareFriendlyComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  public cartDisplayMode: CartLabelDisplayMode;

  @Input()
  public canEdit: boolean;

  public displayMode = CartLabelDisplayMode;

  public UpdateStatusType = UpdateStatusType;
  public DeviceUpdateType = DeviceUpdateType;

  public CartLabelDisplayMode = CartLabelDisplayMode;
  public cartLabelDisplayMode = CartLabelDisplayMode.Name;

  public gridLoading = false;

  private availableGridSelectionService: GridSelectionService;
  private selectedGridSelectionService: GridSelectionService;

  /**
   * Keeps track of which facilityId ther's currently an API call pending for
   * If no API call is pending the value should be falsy
   */
  private loadingFacilityId = 0;

  public readonly pageSize = 100;

  public get canSubmitUpdate(): boolean {
    return !!this.cartsSelectedToUpdate.length && this.canEdit;
  }

  public cartsAvailableToUpdate: CartFriendlyUpdateGridItem[] = [];
  public cartsSelectedToUpdate: CartFriendlyUpdateGridItem[] = [];

  public cartsFullyUpToDate: CartFriendlyUpdateGridItem[] = [];
  public cartsInvalidData: CartFriendlyUpdateGridItem[] = [];
  public cartsUpdatePending: CartFriendlyUpdateGridItem[] = [];

  public dialogCartsList: CartFriendlyUpdateGridItem[] = [];

  public isDialogOpen = false;
  public dialogTitle: string;
  public dialogMsg: string;

  public availableGridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public selectedGridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public availableState = Utils.getDefaultState(this.pageSize);
  public selectedState = Utils.getDefaultState(this.pageSize);

  public availableKeysSelected: number[] = [];
  public selectedKeysSelected: number[] = [];

  private subs: Subscription = new Subscription();

  private selectCartRowIds(rows: RowArgs[]) {
    return rows.map((row) => {
      const sel = row.dataItem as CartFriendlyUpdateGridItem;
      return sel.CartId;
    });
  }

  constructor(
    private permissionService: PermissionService,
    private firmHardWareApi: FirmHardWareApi,
    private toasterService: ToasterService,
    private gridSelectionServiceFactory: GridSelectionServiceFactory,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private applyDeviceUpdateService: ApplyDeviceUpdateService
  ) {}

  public ngOnInit() {
    this.availableGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectCartRowIds
    );

    this.selectedGridSelectionService = this.gridSelectionServiceFactory.build(
      this.selectCartRowIds
    );

    // Because this component is nested in a parent component that will
    // show/hide it using *ngIf, it will be destroyed when the facility
    // drop down is set to "Select a value..."
    // This may cause it to miss the "facility changed" event below
    // And it will end up missing the facility changed event
    if (this.permissionService.facilityId) {
      this.refreshList();
    }

    this.subs.add(
      this.permissionService.facilityChanged$.subscribe((x) => {
        this.refreshList();
      })
    );

    this.subs.add(
      this.availableGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.availableKeysSelected = newSelection;
        }
      )
    );

    this.subs.add(
      this.selectedGridSelectionService.currentSelection$.subscribe(
        (newSelection) => {
          this.selectedKeysSelected = newSelection;
        }
      )
    );

    this.refreshList();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.cartDisplayMode) {
      // Reset filters/sorting/paging when changing display mode
      // otherwise a user can filter in one mode, then switch
      // to a different mode and not be able to clear the
      // previous filter
      this.availableState = Utils.getDefaultState(this.pageSize);
      this.selectedState = Utils.getDefaultState(this.pageSize);
      this.reprocess();
    }
  }

  private reprocess() {
    this.availableGridData = process(
      this.cartsAvailableToUpdate,
      this.availableState
    );
    this.selectedGridData = process(
      this.cartsSelectedToUpdate,
      this.selectedState
    );
  }

  public availableStateChange(state: DataStateChangeEvent): void {
    this.availableState = state;
    this.availableGridData = process(
      this.cartsAvailableToUpdate,
      this.availableState
    );
  }

  public selectedStateChange(state: DataStateChangeEvent): void {
    this.selectedState = state;
    this.selectedGridData = process(
      this.cartsSelectedToUpdate,
      this.selectedState
    );
  }

  public onAvailableSelectionChanged(change: SelectionEvent) {
    this.availableGridSelectionService.updateSelection(change);
  }

  public onSelectedSelectionChanged(change: SelectionEvent) {
    this.selectedGridSelectionService.updateSelection(change);
  }

  private resetGrids() {
    // Reset lists
    this.cartsAvailableToUpdate = [];
    this.cartsSelectedToUpdate = [];
    this.cartsFullyUpToDate = [];
    this.cartsInvalidData = [];
    this.cartsUpdatePending = [];

    this.selectedGridSelectionService.clearSelection();
    this.availableGridSelectionService.clearSelection();

    this.availableState = Utils.getDefaultState(this.pageSize);
    this.selectedState = Utils.getDefaultState(this.pageSize);

    // Update the grids to clear any previous items, before we go try to get new data
    this.reprocess();
  }

  private refreshList() {
    if (
      this.loadingFacilityId &&
      this.loadingFacilityId === this.permissionService.facilityId
    ) {
      return;
    }

    this.resetGrids();

    if (!this.permissionService.orgId || !this.permissionService.facilityId) {
      this.availableGridData = { data: [], total: 0 };
      return;
    }

    this.gridLoading = true;
    this.loadingFacilityId = this.permissionService.facilityId;
    this.subs.add(
      this.applyDeviceUpdateService
        .getCartsAndUpdatesList()
        .pipe(
          finalize(() => {
            this.gridLoading = false;
            this.loadingFacilityId = 0;
          })
        )
        .subscribe(
          (resp) => {
            this.setGridData(resp);
          },
          (error) => {
            this.toasterService.showError('ERROR_GETTING_CARTS_AND_UPDATES');
          }
        )
    );
  }

  private setGridData(gridCarts: CartFriendlyUpdateGridItem[]): void {
    gridCarts.forEach((c) => {
      if (c.isFullyUpToDate) {
        this.cartsFullyUpToDate.push(c);
        return;
      }

      if (c.ccsStatus === UpdateStatusType.Unknown) {
        this.cartsInvalidData.push(c);
        return;
      }

      if (c.isUpdatePending) {
        this.cartsUpdatePending.push(c);
        return;
      }

      this.cartsAvailableToUpdate.push(c);
    });

    this.cartsSelectedToUpdate = [];

    this.reprocess();
  }

  public updateCarts() {
    this.loaderService.start();

    this.subs.add(
      this.firmHardWareApi
        .autoUpdateCarts(this.cartsSelectedToUpdate.map((c) => c.CartId))
        .pipe(
          finalize(() => {
            this.loaderService.stop();
          })
        )
        .subscribe(
          (resp) => {
            this.toasterService.showSuccess('CART_AUTO_UPDATE_SENT_SUCCESS');
            this.refreshList();
          },
          (error) => {
            this.toasterService.showError('CART_AUTO_UPDATE_FAILED');
          }
        )
    );
  }

  public btnTriggerMoveAllToSelected() {
    if (!this.cartsAvailableToUpdate.length) {
      return;
    }

    const tempSelected = [
      ...this.cartsSelectedToUpdate,
      ...this.cartsAvailableToUpdate,
    ];

    tempSelected.sort((a, b) => {
      return a.CartId - b.CartId;
    });

    this.cartsAvailableToUpdate.length = 0;
    this.cartsSelectedToUpdate = tempSelected;
    this.availableGridSelectionService.clearSelection();
    this.selectedGridSelectionService.clearSelection();

    this.availableState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }

  public btnTriggerMoveAvailableToSelected() {
    if (!this.availableKeysSelected.length) {
      return;
    }

    this.availableKeysSelected.sort((a, b) => {
      return a - b;
    });

    const tempAvail = this.cartsAvailableToUpdate;
    const tempSelectedCarts = this.cartsSelectedToUpdate;

    this.cartsAvailableToUpdate = [];
    this.cartsSelectedToUpdate = [];

    do {
      const iAv = this.availableKeysSelected.pop();
      const cartToMoveIdx = tempAvail.findIndex((x) => x.CartId === iAv);
      const cartToMove = tempAvail.splice(cartToMoveIdx, 1)[0];
      tempSelectedCarts.push(cartToMove);
    } while (this.availableKeysSelected.length);

    tempSelectedCarts.sort((a, b) => {
      return a.CartId - b.CartId;
    });

    this.cartsAvailableToUpdate = tempAvail;
    this.cartsSelectedToUpdate = tempSelectedCarts;
    this.availableGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.cartsAvailableToUpdate.length <= this.availableState.skip) {
      this.availableState.skip = this.availableState.skip - this.pageSize;
    }

    this.reprocess();
  }

  public btnTriggerMoveSelectedToAvailable() {
    if (!this.selectedKeysSelected.length) {
      return;
    }

    this.selectedKeysSelected.sort((a, b) => {
      return a - b;
    });

    // const tempAvail = this.cartsAvailableToUpdate;
    // const tempSelectedCarts = this.cartsSelectedToUpdate;

    const tempAvail = this.cartsAvailableToUpdate;
    const tempSelectedCarts = this.cartsSelectedToUpdate;

    this.cartsAvailableToUpdate = [];
    this.cartsSelectedToUpdate = [];

    do {
      const iAv = this.selectedKeysSelected.pop();
      const cartToMoveIdx = tempSelectedCarts.findIndex(
        (x) => x.CartId === iAv
      );
      const cartToMove = tempSelectedCarts.splice(cartToMoveIdx, 1)[0];
      tempAvail.push(cartToMove);
    } while (this.selectedKeysSelected.length);

    tempAvail.sort((a, b) => {
      return a.CartId - b.CartId;
    });

    this.cartsAvailableToUpdate = tempAvail;
    this.cartsSelectedToUpdate = tempSelectedCarts;
    this.selectedGridSelectionService.clearSelection();

    // If no more items on last page, jump to "new" last page
    if (this.cartsSelectedToUpdate.length <= this.selectedState.skip) {
      this.selectedState.skip = this.selectedState.skip - this.pageSize;
    }
    this.reprocess();
  }

  public btnTriggerMoveAllToAvailable() {
    if (!this.cartsSelectedToUpdate.length) {
      return;
    }

    const tempAvailable = [
      ...this.cartsAvailableToUpdate,
      ...this.cartsSelectedToUpdate,
    ];

    tempAvailable.sort((a, b) => {
      return a.CartId - b.CartId;
    });

    this.cartsAvailableToUpdate = tempAvailable;
    this.cartsSelectedToUpdate.length = 0;
    this.selectedGridSelectionService.clearSelection();
    this.availableGridSelectionService.clearSelection();

    this.selectedState = Utils.getDefaultState(this.pageSize);
    this.reprocess();
  }

  public openDialogFullyUpToDate() {
    this.dialogTitle = this.translateService.instant('CARTS_FULLY_UP_TO_DATE');
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_FULLY_UP_TO_DATE'
    );
    this.dialogCartsList = this.cartsFullyUpToDate;
    this.isDialogOpen = true;
  }

  public openDialogInvalidData() {
    this.dialogTitle = this.translateService.instant('CARTS_INVALID_DATA');
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_INVALID_DATA'
    );
    this.dialogCartsList = this.cartsInvalidData;
    this.isDialogOpen = true;
  }

  public openDialogUpdatePending() {
    this.dialogTitle = this.translateService.instant('CARTS_UPDATES_PENDING');
    this.dialogMsg = this.translateService.instant(
      'DIALOG_MSG_CARTS_UPDATES_PENDING'
    );
    this.dialogCartsList = this.cartsUpdatePending;
    this.isDialogOpen = true;
  }

  public onDialogClosed() {
    this.isDialogOpen = false;
    this.dialogTitle = null;
    this.dialogMsg = null;
    this.dialogCartsList = [];
  }
}
