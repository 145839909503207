import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-label-bold',
  templateUrl: './bold.component.html',
  styleUrls: ['./bold.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoldLabelComponent {
  @Input()
  public textTag: string;

  @Input()
  public infoTag: string | undefined;

  constructor() {}
}
