import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { DashboardWidgetRequest, DashboardWidgetResponse } from '..';
import { BaseApi } from '../base/base.api';

export interface DashboardApiInstance {
  getDashboardStats(
    requestList: DashboardWidgetRequest[]
  ): Observable<DashboardWidgetResponse[]>;
}

@Injectable()
export class DashboardApi extends BaseApi implements DashboardApiInstance {
  protected endpoints = {
    dashboardStatsUrl: this.buildUrl('dashboard/stats'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getDashboardStats(
    requestList: DashboardWidgetRequest[]
  ): Observable<DashboardWidgetResponse[]> {
    return this.http.post<DashboardWidgetResponse[]>(
      this.endpoints.dashboardStatsUrl,
      requestList
    );
  }
}
