import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ApiActivityResponse, ApiActivitySearchRequest } from '@capsa/api';
import { ActivityApi } from '@capsa/api/activity';
import { GridFilterHelperService } from '@capsa/services/grid-filter-helper/grid-filter-helper.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { TranslateService } from '@ngx-translate/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { ApiActivityLogColumnConfig } from 'app/modules/activity-log/activity-log-view/activity-log-view.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { utils, writeFile } from 'xlsx';

@Component({
  selector: 'app-user-web-activity',
  templateUrl: './user-web-activity.component.html',
  styleUrls: ['./user-web-activity.component.scss'],
})
export class UserWebActivityListComponent
  implements OnInit, OnChanges, OnDestroy
{
  public columns: ApiActivityLogColumnConfig[] = [
    {
      titleTag: 'COM_ACTIVITY',
      field: 'Endpoint',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_USER',
      field: 'UserDisplayName',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_DATE',
      field: 'ActivityDateUtc',
      fieldType: 'date',
      sort: true,
      filterable: true,
    },
    {
      titleTag: 'COM_SUCCESS',
      field: 'Result',
      fieldType: 'string',
      sort: true,
      filterable: true,
    },
  ];

  public query: Partial<ApiActivitySearchRequest> = {
    SortInfo: [{ ColumnName: 'ActivityDateUtc', IsDescending: true }],
  };

  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  public filterDelayTime = 1500;

  public gridDataLoading = false;

  private gridDataBehSub = new BehaviorSubject<ApiActivityResponse[]>([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;
  public sort: SortDescriptor[] = [];

  public orgId: number;
  public facilityId: number;

  private subs: Subscription = new Subscription();
  private apiSearchSub = new Subscription();
  public canViewPage = false;

  // Only users that can create users should have access to the web logs.
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Users_Add];

  constructor(
    private activityApi: ActivityApi,
    private filterService: GridFilterHelperService,
    private translate: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.resetPagingAndData();
          this.loadGridData();
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.loadGridData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.resetPagingAndData();
      this.loadGridData();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  public orgIdChanged(newValue: number | undefined): void {
    this.orgId = newValue;
    this.facilityId = undefined;
  }

  public facilityIdChanged(newValue: number | undefined): void {
    this.query.FacilityId = this.facilityId = newValue;
  }

  private loadGridData() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a "deselect", we want to make sure data doesn't come in
    // after we've went back to an "unselected" filter (prevents race condition bugs)
    this.apiSearchSub.unsubscribe();

    if (
      !this.orgId ||
      !this.query.FacilityId ||
      this.query.FacilityId !== this.facilityId
    ) {
      return;
    }

    this.resetFilters();

    const transformation = (fieldName: string, filter: FilterDescriptor) => {
      if (typeof filter.value === 'string') {
        filter.value = filter.value.trim();
      }

      switch (fieldName) {
        case 'Endpoint':
          this.query.Endpoints = [filter.value];
          break;
        case 'UserDisplayName':
          this.query.UserDisplayNames = [filter.value];
          break;
        case 'ActivityDateUtc':
          if (filter.operator === 'gte') {
            this.query.DateRangeUtcStart = filter.value;
          } else if (filter.operator === 'lte') {
            this.query.DateRangeUtcEnd = filter.value;
          }
          break;
        case 'Result':
          this.query.Result = [filter.value];
          break;
        default:
          throw new Error('Invalid fieldName');
      }
    };

    this.filterService.transform(this.gridFilter, transformation);

    this.gridDataLoading = true;
    const sub = this.activityApi
      .searchWebActivity(
        {
          PageNumber: 1 + this.skip / this.pageSize,
          PageSize: this.pageSize,
        },
        this.query
      )
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }
          this.gridDataLoading = false;
        },
        (error) => {
          this.gridDataLoading = false;
        }
      );
    this.apiSearchSub = sub;
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  private resetFilters() {
    this.query.UserDisplayNames = undefined;
    this.query.DateRangeUtcEnd = undefined;
    this.query.DateRangeUtcStart = undefined;
    this.query.Endpoints = undefined;
    this.query.Result = undefined;
  }

  public sortChange(sort: SortDescriptor[]): void {
    if (this.gridDataLoading) {
      return;
    }
    this.sort = sort;
    this.query.SortInfo = [];
    this.sort.forEach((col) => {
      if (!col.dir) {
        return;
      }
      this.query.SortInfo.push({
        ColumnName: col.field,
        IsDescending: col.dir === 'desc',
      });
    });

    this.skip = 0;
    this.loadGridData();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  public onFilterChanged(data: CompositeFilterDescriptor) {
    this.gridFilter = data;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public onExport() {
    this.exportData();
  }

  private exportData() {
    const data = this.gridDataBehSub.value;
    const row = [];
    // column headers
    this.columns.forEach((col) => {
      row.push(this.translate.instant(col.titleTag));
    });
    const rows = [];
    rows.push(row);
    data.forEach((line) => {
      const newRow = [];
      this.columns.forEach((col) => {
        newRow.push(line[col.field]);
      });
      rows.push(newRow);
    });
    const sheet = utils.aoa_to_sheet(rows);
    const workbook = utils.book_new();
    const sheetname = this.translate.instant('USER_WEB_ACTIVITY');
    utils.book_append_sheet(workbook, sheet, sheetname);
    const filename = sheetname + '.xlsx';
    writeFile(workbook, filename);
  }
}
