import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { FirmwareResponse } from '@capsa/api';
import { DropDownItemWithData } from '@capsa/dropdowns/enum';
import { DeviceUpdateCacheService } from '@capsa/services/device-update-cache/device-update-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedDeviceUpdateService } from 'app/modules/device-update/device-update.service';

@Component({
  selector: 'app-firmware-pn-drop-down',
  templateUrl: './firmware-pn-drop-down.component.html',
  styleUrls: ['./firmware-pn-drop-down.component.scss'],
})
export class FirmwarePnDropDownComponent implements OnInit, OnDestroy {
  constructor(
    private deviceUpdateService: SharedDeviceUpdateService,
    private deviceUpdateCacheService: DeviceUpdateCacheService,
    private translateService: TranslateService
  ) {}

  @Input()
  private refreshList$: Observable<void>;

  @Output()
  public firmwareChanged = new EventEmitter<FirmwareResponse>();

  public selectedFirmwareId = -1;

  public firmwareItems: DropDownItemWithData<FirmwareResponse>[] = [
    this.getDefaultDdlItem(),
  ];

  private subs = new Subscription();

  public ngOnInit() {
    if (this.refreshList$) {
      this.subs.add(
        this.refreshList$.subscribe(() => {
          this.refreshItemsList();
        })
      );
    } else {
      this.refreshItemsList();
    }
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onFirmwareChanged(item: DropDownItemWithData<FirmwareResponse>) {
    this.selectedFirmwareId = item.Value as number;
    this.firmwareChanged.emit(item.Data);
  }

  private refreshItemsList(): void {
    this.firmwareItems = [this.getDefaultDdlItem()];
    this.selectedFirmwareId = -1;

    this.subs.add(
      this.deviceUpdateCacheService
        .getFwList(
          this.deviceUpdateService.deviceTypeId,
          this.deviceUpdateService.updateType
        )
        .subscribe((fw) => {
          fw.forEach((f) => {
            this.firmwareItems.push({
              Value: f.FirmwareId,
              Label: f.PartNumber + ' ' + f.Description,
              Data: f,
            });
          });

          if (fw.length === 1) {
            this.selectedFirmwareId = fw[0].FirmwareId;
            this.firmwareChanged.emit(fw[0]);
          } else {
            this.firmwareChanged.emit(null);
          }
        })
    );
  }

  private getDefaultDdlItem(): DropDownItemWithData<FirmwareResponse> {
    return {
      Label: this.translateService.instant('COM_SELECT_A_VALUE'),
      Value: -1,
      Data: null,
    };
  }
}
