import { RowArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { List as ImmutableList } from 'immutable';
import { Subject } from 'rxjs';

export class GridSelectionService {
  private currentSelectionBehSub = new Subject<any[]>();

  private previousSelection: ImmutableList<any> = ImmutableList();
  public currentSelection$ = this.currentSelectionBehSub.asObservable();

  constructor(private idSelector: (data: RowArgs[]) => number[]) {}

  public forceChanges(toSelect: RowArgs[], toDeselect: RowArgs[]) {
    // Because we want to force the changes of the rows here, we set these
    // to propreties to "true", which basically says "Just deselect them disregarding the previous selection.".
    this.updateSelection({
      ctrlKey: true,
      shiftKey: true,
      deselectedRows: toDeselect,
      selectedRows: toSelect,
    });
  }

  public updateSelection(change: SelectionEvent) {
    const selectedIds = this.idSelector(change.selectedRows);
    const deselectedIds = this.idSelector(change.deselectedRows);

    if (change.deselectedRows.length > 0 && change.selectedRows.length > 0) {
      // when trying to keep rows selected, the event will indicate the newly selected item in the 'selectedRows'
      // property, while the previous selection will be marked in the 'deselectedRows'. To maintain state,
      // we ignore the deselected row and just add the newly selected one to the list.

      this.previousSelection = this.previousSelection.concat(selectedIds);
    } else if (
      change.deselectedRows.length > 0 &&
      change.selectedRows.length === 0
    ) {
      // simply removed selections
      const recordsToRemove =
        this.previousSelection.count() > 1 &&
        !(change.ctrlKey || change.shiftKey)
          ? this.previousSelection
              .filter((id) => !deselectedIds.includes(id))
              .toArray()
          : deselectedIds;

      recordsToRemove.forEach((idToRemove) => {
        const idxToRemove = this.previousSelection.indexOf(idToRemove);
        if (idxToRemove > -1) {
          this.previousSelection = this.previousSelection.remove(idxToRemove);
        }
      });
    } else if (
      change.deselectedRows.length === 0 &&
      change.selectedRows.length > 0
    ) {
      // add rows
      this.previousSelection = this.previousSelection.concat(selectedIds);
    }

    this.currentSelectionBehSub.next(this.previousSelection.toArray());
  }

  public clearSelection() {
    this.previousSelection = ImmutableList();
    this.currentSelectionBehSub.next(this.previousSelection.toArray());
  }
}
