import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localDate',
})
/**
 * Converts a UTC date time into the users browser local time zone.
 *
 * @usageNotes
 * If the date provided is falsy, it will return an empty string.
 *
 * @publicApi
 */
export class LocalDatePipe implements PipeTransform {
  transform(value: moment.MomentInput, ...args: any[]): any {
    return value ? moment.utc(value).local().format() : '';
  }
}
