import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import {
  FileSelectModule,
  UploadsModule,
} from '@progress/kendo-angular-upload';
import { AccessPointManageComponent } from 'app/modules/access-points/access-point-manage/access-point-manage.component';
import { DeviceTrackingConnectedDevicesComponent } from 'app/modules/access-points/device-tracking/device-tracking-connected-devices/device-tracking-connected-devices.component';
import { DeviceTrackingComponent } from 'app/modules/access-points/device-tracking/device-tracking.component';
import { ImportAccessPointsComponent } from 'app/modules/access-points/facility-import-access-points/facility-import-access-points.component';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CoreModule } from 'app/modules/core/core.module';
import { DataDrivenControlsModule } from 'app/modules/data-driven-controls/data-driven-controls.module';
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { AccessPointsComponent } from './access-points.component';
import { DeviceTrackingAccessPointListComponent } from './device-tracking/device-tracking-access-point-list/device-tracking-access-point-list.component';

@NgModule({
  declarations: [
    DeviceTrackingComponent,
    DeviceTrackingAccessPointListComponent,
    DeviceTrackingConnectedDevicesComponent,
    AccessPointsComponent,
    ImportAccessPointsComponent,
    AccessPointManageComponent,
  ],
  imports: [
    LabelsModule,
    CommonModule,
    GridModule,
    ButtonModule,
    UploadsModule,
    FileSelectModule,
    LoadingIndicatorModule,
    CapsaDropdownsModule,
    TabStripModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    //
    CoreModule,
    LayoutModule,
    DataDrivenControlsModule,
    InputsModule,
    NotificationModule,
    CustomInputsModule,
  ],
})
export class AccessPointsModule {}
