import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MessageTemplateResponse } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { MessageTemplateDropDownDataSource } from '@capsa/dropdowns/message-templates-drop-down/message-templates-drop-down-datasource';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message-templates-drop-down',
  templateUrl: './message-templates-drop-down.component.html',
  styleUrls: ['./message-templates-drop-down.component.scss'],
})
export class MessageTemplatesDropDownComponent implements OnDestroy {
  @Input()
  readonly: boolean;

  @Input()
  public readonlyText: string;

  @Input()
  public disabled = false;

  @Output()
  valueChanged = new EventEmitter<
    AsyncDropDownValue<MessageTemplateResponse>
  >();

  @Input()
  public dataSource: MessageTemplateDropDownDataSource;

  constructor() {}

  private subs = new Subscription();

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
