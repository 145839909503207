import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-permission-status-msg',
  templateUrl: './permission-status-msg.component.html',
  styleUrls: ['./permission-status-msg.component.scss'],
})
export class PermissionStatusMsgComponent {
  @Input()
  public facilityId: number;

  constructor() {}
}
