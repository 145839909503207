// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#btnExport {
  margin-top: 2em;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/users/user-web-activity/user-web-activity.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":["#btnExport {\n  margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
