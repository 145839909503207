import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CapsaSettingsTypeIds, SettingResponse } from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-battery',
  templateUrl: './facility-manage-battery.component.html',
  styleUrls: ['./facility-manage-battery.component.scss'],
})
export class FacilityManageBatteryComponent implements OnInit, OnDestroy {
  @Input()
  settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  @Output()
  public migrateBatteryRange = new EventEmitter();

  // To expose the enum to the template
  public settingTypeEnum = CapsaSettingsTypeIds;
  private subs = new Subscription();
  public hasNonDefaultBatteryRangeSettings = false;
  public isReady = false;

  constructor(
    private facilityApi: FacilityApi,
    private toasterService: ToasterService,
    private permissionService: PermissionService
  ) {}

  get red() {
    return this.settings.get(
      CapsaSettingsTypeIds.DeviceProfile_BatteryRange_Red
    );
  }

  get yellow() {
    return this.settings.get(
      CapsaSettingsTypeIds.DeviceProfile_BatteryRange_Yellow
    );
  }

  public ngOnInit(): void {
    const fId = this.permissionService.facilityId;

    this.subs.add(
      this.facilityApi.getFacilityBatterySettingsState(fId).subscribe(
        (resp) => {
          this.hasNonDefaultBatteryRangeSettings = resp.hasAnyNonDefault;
          this.isReady = true;
        },
        (error) => {
          this.toasterService.showError(
            'Failed to retrieve battery settings state'
          );
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onNumRangeChange(setting: SettingResponse, newValue: number) {
    if (setting === this.yellow && newValue <= Number(this.red.SettingValue)) {
      this.red.SettingValue = newValue.toString();
    }
    if (setting === this.red && newValue >= Number(this.yellow.SettingValue)) {
      this.yellow.SettingValue = newValue.toString();
    }

    setting.SettingValue = newValue.toString();
  }

  public migrateBatteryRangeSettings() {
    this.migrateBatteryRange.emit();
  }
}
