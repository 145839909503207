import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byte',
})
export class BytePipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    if (!args || !args.length || args.length !== 1) {
      throw new Error('Invalid arguments for "Byte Pipe"');
    }

    const units: string = args[0];

    if (!this.validUnits.some((vu) => vu === units)) {
      throw new Error(`Unit "${units}" is invalid`);
    }

    switch (units) {
      case 'KB':
        return (value = +(value / 2 ** 10).toFixed(2));
      case 'MB':
        return (value = +(value / 2 ** 20).toFixed(2));
      case 'GB':
        return (value = +(value / 2 ** 30).toFixed(2));
      default:
        return -1;
    }
  }

  private validUnits: string[] = ['KB', 'MB', 'GB'];
}
