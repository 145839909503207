import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DataDrivenColorDropDownComponent } from 'app/modules/data-driven-controls/data-driven-color-drop-down/data-driven-color-drop-down.component';
import { DataDrivenNumberComponent } from 'app/modules/data-driven-controls/data-driven-number/data-driven-number.component';
import { DataDrivenSliderComponent } from 'app/modules/data-driven-controls/data-driven-slider/data-driven-slider.component';
import { DataDrivenSwitchComponent } from 'app/modules/data-driven-controls/data-driven-switch/data-driven-switch.component';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { DataDrivenCheckboxComponent } from './data-driven-checkbox/data-driven-checkbox.component';
import { DataDrivenControlComponent } from './data-driven-control/data-driven-control.component';
import { DataDrivenDropDownComponent } from './data-driven-drop-down/data-driven-drop-down.component';
import { DataDrivenTextboxComponent } from './data-driven-textbox/data-driven-textbox.component';

@NgModule({
  declarations: [
    DataDrivenCheckboxComponent,
    DataDrivenControlComponent,
    DataDrivenDropDownComponent,
    DataDrivenTextboxComponent,
    DataDrivenColorDropDownComponent,
    DataDrivenSliderComponent,
    DataDrivenSwitchComponent,
    DataDrivenNumberComponent,
  ],
  imports: [
    LabelsModule,
    DropDownsModule,
    TranslateModule.forChild(),
    CommonModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [DataDrivenControlComponent],
})
export class DataDrivenControlsModule {}
