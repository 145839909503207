import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CartSelectionDialogComponent } from 'app/modules/capsa-dialogs/cart-selection-dialog/cart-selection-dialog.component';
import { UserSelectionDialogComponent } from 'app/modules/capsa-dialogs/user-selection-dialog/user-selection-dialog.component';
import { CoreModule } from 'app/modules/core/core.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
@NgModule({
  declarations: [CartSelectionDialogComponent, UserSelectionDialogComponent],
  imports: [
    CoreModule,
    LabelsModule,
    CommonModule,
    GridModule,
    ButtonsModule,
    TranslateModule,
    PipesModule,
    InputsModule,
    DropDownsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
  ],
  exports: [CartSelectionDialogComponent, UserSelectionDialogComponent],
})
export class CapsaDialogsModule {}
