// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnUpdateSubmit {
  float: right;
  margin-top: 20px;
}

.cols-wrapper {
  height: auto;
}

.leftGrid {
  flex: 1 1 60%;
}

.rightGrid {
  flex: 1 1 40%;
}

.dialogButtons button {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/update-cart-firmware/update-advanced/update-advanced.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".btnUpdateSubmit {\n  float: right;\n  margin-top: 20px;\n}\n\n.cols-wrapper {\n  height: auto;\n}\n\n.leftGrid {\n  flex: 1 1 60%;\n}\n\n.rightGrid {\n  flex: 1 1 40%;\n}\n\n.dialogButtons button {\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
