import { Component } from '@angular/core';

@Component({
  selector: 'app-software-updates-sent',
  templateUrl: './software-updates-sent.component.html',
  styleUrls: ['./software-updates-sent.component.scss'],
})
export class SoftwareUpdatesSentComponent {
  constructor() {}
}
