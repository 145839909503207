import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { OrganizationDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-drop-down-data-source';

@Component({
  selector: 'app-organization-drop-down',
  templateUrl: './organization-drop-down.component.html',
  styleUrls: ['./organization-drop-down.component.scss'],
})
export class OrganizationDropDownComponent {
  @Input()
  readonly: boolean;

  @Input()
  readonlyText: string;

  @Input()
  disabled = false;

  @Output()
  valueChanged = new EventEmitter<AsyncDropDownValue<NameWithLongIdApiModel>>();

  @Input()
  public dataSource: OrganizationDropDownDataSource;

  constructor() {}
}
