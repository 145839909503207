import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@capsa/services/auth/auth.service';
import { Observable } from 'rxjs';

/**
 * Used to add authentication to every request when a user is currently
 * signed in.
 */
@Injectable()
export class AuthenticateInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.currentUser && this.auth.currentUser.AccessToken) {
      // authenticated, so add our access token.
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.currentUser.AccessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
