import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingIndicatorCircularComponent } from 'app/modules/loading-indicator/loading-indicator-circular/loading-indicator-circular.component';

@NgModule({
  declarations: [LoadingIndicatorCircularComponent],
  imports: [CommonModule],
  exports: [LoadingIndicatorCircularComponent],
})
export class LoadingIndicatorModule {}
