import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationStart,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { FeatureFlagService } from '@capsa/services/feature-flag/feature-flag.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/common/utils';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

interface NavMenuItem {
  tagName: string;
  path: string;
  selected?: boolean;
  index: number;
  hasPerms: boolean;
  icon?: string;

  // This is necessary to use accordion style animation
  // "auto" heights cannot be used
  height?: number;
  submenu?: NavMenuItem[];
  hide: boolean;
}

@Component({
  selector: 'app-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
})
export class GlobalNavComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: Array<NavMenuItem> = [];
  private readonly DEFAULT_NAV_ITEM_HEIGHT: number = 40;
  private subs = new Subscription();

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private permissionService: PermissionService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.menuItems = this.mapMenuItems(this.router.config);

    this.subs.add(
      this.translateService.onLangChange.pipe(delay(10)).subscribe((x) => {
        Utils.updateSideBarWidthForLanguage(x.lang);

        // On language change, recalculate the subnav heights
        // Since we use an explicit subnav height to enable animation - when language changes after pageload
        // the translated versions of menu items may take up more/less height (1-2 lines)
        // this means the subnav container may need to expand or shrink slightly - this code covers that
        this.updateSelectedNavItems(
          this.GetRouteSegmentsByUrl(document.location.pathname)
        );
      })
    );

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        // Refresh nav
        this.menuItems = this.mapMenuItems(this.router.config);

        // Ensure "selected" item remains selected
        const urlTree = this.router.parseUrl(this.router.url);
        this.updateSelectedNavItems(this.GetRouteSegments(urlTree));
      })
    );

    this.subs.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationStart) {
          // Update navigation selected item
          const nav = this.router.getCurrentNavigation();
          this.updateSelectedNavItems(this.GetRouteSegments(nav.extractedUrl));
        }
      })
    );
  }

  ngAfterViewInit() {
    // Moving execution of this function to the end of the queue, to allow HTML/CSS to render
    // so that we can get subnav wrapper height when this function runs
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop
    // https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      this.updateSelectedNavItems(
        this.GetRouteSegmentsByUrl(document.location.pathname)
      );
    }, 0);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private deselectAllNavItems(): void {
    this.menuItems.forEach((mi) => {
      mi.selected = false;
      mi.height = this.DEFAULT_NAV_ITEM_HEIGHT;
      if (mi.submenu) {
        mi.submenu.forEach((smi) => (smi.selected = false));
      }
    });
  }

  private GetRouteSegmentsByUrl(urlPath: string): UrlSegment[] {
    const segments: UrlSegment[] = [];

    if (urlPath.length > 0) {
      const arPath = urlPath.split('/');
      arPath.forEach((x) => {
        if (x.length) {
          segments.push(new UrlSegment(x, null));
        }
      });
    }

    return segments;
  }

  private GetRouteSegments(urlTree: UrlTree): UrlSegment[] {
    if (
      !urlTree.root.children ||
      !urlTree.root.children.primary ||
      !urlTree.root.children.primary.segments
    ) {
      return [];
    }

    const segments: UrlSegment[] = urlTree.root.children.primary.segments;
    return segments;
  }

  private updateSelectedNavItems(segments: UrlSegment[]): void {
    this.deselectAllNavItems();

    if (segments && segments.length > 0) {
      const mainNav = segments[0];
      const mainNavMatch = this.menuItems.find((x) => x.path === mainNav.path);
      if (mainNavMatch) {
        mainNavMatch.selected = true;
      } else {
        return;
      }

      if (segments.length > 1 && mainNavMatch.submenu) {
        const subNav = segments[1];
        const subNavMatch = mainNavMatch.submenu.find(
          (x) => x.path === `${mainNavMatch.path}/${subNav.path}`
        );
        if (subNavMatch) {
          mainNavMatch.height =
            this.getSubNavHeight(mainNavMatch.index) +
            this.DEFAULT_NAV_ITEM_HEIGHT;
          subNavMatch.selected = true;
        }
      }
    }
  }

  private expandSubnavFor(mainNav: NavMenuItem) {
    mainNav.height =
      this.getSubNavHeight(mainNav.index) + this.DEFAULT_NAV_ITEM_HEIGHT;
  }

  private closeAllSubnavs() {
    this.menuItems.forEach((x) => {
      x.selected = false;
      x.height = this.DEFAULT_NAV_ITEM_HEIGHT;
    });
  }

  /**
   * using CSS "visibility: hidden" we can render subwrapper content but hide it. This way we know its height
   * before we try to expand its parent height. (If we used "display: none" we can't know the height until it's visible)
   * we need the height for the "CSS  animation" to work from height: 0 --> X (auto height can't be animated...)
   */
  private getSubNavHeight(navItemWrapperId: number) {
    const subNav = document.querySelector(
      `#nav-item-wrapper--${navItemWrapperId} .nav-subwrapper`
    );

    if (!subNav) {
      console.error("subnav selector didn't match");
      return 0;
    }

    return subNav.clientHeight;
  }

  public handleNavClick(event: any, navItem: NavMenuItem) {
    if (navItem.selected || !navItem.hasPerms) {
      return;
    }

    if (!navItem.submenu) {
      // subnav item
      this.router.navigate([navItem.path]);
    } else {
      // main nav item
      this.closeAllSubnavs();
      navItem.selected = true;
      this.expandSubnavFor(navItem);
    }
  }

  private mapMenuItems(routes: Route[], parent?: Route): NavMenuItem[] {
    let index = 0;
    return (
      routes
        // only render routes with a path (i.e. not the default path.)
        .filter(
          (r) =>
            !!r.path &&
            !!r.data &&
            (r.data.renderMenuItem === undefined ||
              r.data.renderMenuItem === true)
        )
        .map((route) => {
          const data = route.data;

          let isAllowed = true;
          if (data.permsRequired && data.permsRequired.length > 0) {
            isAllowed = this.permissionService.hasAny(data.permsRequired);
          }

          if (data.featureFlag && !this.featureFlagService[data.featureFlag]) {
            return undefined;
          }

          const result: NavMenuItem = {
            tagName: data.title,
            index: index++,
            path: (parent ? `${parent.path}/` : '') + route.path,
            hasPerms: isAllowed,
            icon: data.icon ? '../../../assets/images/' + data.icon : undefined,
            hide: false,
          };

          if (route.children) {
            result.height = this.DEFAULT_NAV_ITEM_HEIGHT;
            result.submenu = this.mapMenuItems(route.children, route);
            if (!result.submenu.find((subMenu) => subMenu.hasPerms)) {
              // no sub-menu has permissions, so hide the parent menu item
              // note: we hide instead of "NOT render" since we need the item in the DOM
              // for retrieving the subnav height
              result.hide = true;
            }
          }
          return result;
        })
        .filter((r) => r !== undefined)
    );
  }
}
