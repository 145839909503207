import { Pipe, PipeTransform } from '@angular/core';
import { UpdateStatusType } from '@capsa/api';

@Pipe({
  name: 'updateStatusCssClass',
})
export class UpdateStatusCssClassPipe implements PipeTransform {
  transform(value: UpdateStatusType, ...args: any[]): any {
    switch (value) {
      case UpdateStatusType.UpToDate:
        return 'text-success bold';
      case UpdateStatusType.NeedsUpdate:
        return 'text-warn';
      case UpdateStatusType.UpdatePending:
        return 'text-blue bold';
      default:
        return 'text-gray';
    }
  }
}
