import { Injectable } from '@angular/core';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractDropDownListDataSource } from '@capsa/dropdowns/async-drop-down-list/abstract-drop-down-list-data-dource';
import { DropDownItem, EnumTypes } from '@capsa/dropdowns/enum';
import { EnumService } from '@capsa/services/enum/enum.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnumDropDownDataSource extends AbstractDropDownListDataSource<DropDownItem> {
  public enumName: EnumTypes;

  private defaultValue: AsyncDropDownValue<DropDownItem>;

  protected callApi(): Observable<DropDownItem[]> {
    return this.enumService.getEnum(this.enumName);
  }

  protected map(apiModel: DropDownItem): AsyncDropDownValue<DropDownItem> {
    return {
      apiModel,
      id: apiModel.Value.toString(),
      name: this.translations.instant(apiModel.Label),
    };
  }
  protected select(
    items: AsyncDropDownValue<DropDownItem>[]
  ): AsyncDropDownValue<DropDownItem> {
    return undefined;
  }
  public get defaultItem(): AsyncDropDownValue<DropDownItem> {
    return this.defaultValue;
  }

  public setDefaultValue(value: string | number) {
    this.data.forEach((item) => {
      if (item.apiModel.Value === value) {
        this.defaultValue = item;
      }
    });
  }

  constructor(
    private enumService: EnumService,
    private translations: TranslateService
  ) {
    super();
  }
}
