import { Injectable } from '@angular/core';
import { CapsaRoleResponseDto } from '@capsa/api';
import { CapsaRoleApi } from '@capsa/api/capsa-role';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { Constants } from 'app/common/constants';
import { List as ImmutableList } from 'immutable';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  Unsubscribable,
} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CapsaRoleService implements Unsubscribable {
  // Meant to always emit the last (and only) values of roles
  private replayApiSubject = new ReplaySubject<CapsaRoleResponseDto[]>(1);
  private replayApi$ = this.replayApiSubject.asObservable();
  private subs = new Subscription();
  private _unsafeRolesList: ImmutableList<CapsaRoleResponseDto>;

  private rolesListReadyBehSub = new BehaviorSubject<boolean>(false);
  public rolesListReady$ = this.rolesListReadyBehSub.asObservable();

  /**
   * Returns the list of roles, but will be undefined if API call hasn't returned yet.
   * Must only be called when certain the API call is complete.
   * Use "rolesListReady$" to ensure it's ok to use this property
   */
  public get unsafeRolesList(): CapsaRoleResponseDto[] {
    return this._unsafeRolesList.toArray();
  }

  /**
   * Cached CLI list.
   */
  public get rolesList$(): Observable<CapsaRoleResponseDto[]> {
    // If subject hasn't emitted, then it will wait till it emits
    // once subject has a value, it will be returned immediately without
    // making any more API calls
    return this.replayApi$;
  }

  public getRoleIdsByFacilityId(facilityId: number) {
    return this.replayApi$.pipe(
      map((roles) => {
        if (facilityId === undefined || facilityId === null) {
          throw new Error(
            'FacilityId must be specified in this method (getRoleIdsByFacilityId)'
          );
        }

        // facility roles supported, so return them.
        return roles.filter((r) => r.FacilityId === facilityId);
      })
    );
  }

  /**
   * Gets the list of role ids that have a CLI_DeviceUser permission associated.
   *
   * If the facilityId parameter is excluded, it will return all the roles under
   * the users enterprise that have the CLI_DeviceUser permission.
   *
   * @param facilityId The facility to filter roles for.
   */
  public getDeviceUserRoleIds(
    facilityId?: number
  ): Observable<CapsaRoleResponseDto[]> {
    return this.replayApi$.pipe(
      map((roles) => {
        const deviceAccessRoles = roles.filter((r) =>
          r.Permissions.some(
            (p) => p.Permission === Permissions.CLI_DeviceAccess
          )
        );

        if (facilityId === undefined) {
          // return enterprise facilities.
          return deviceAccessRoles;
        }

        // facility roles supported, so return them.
        return deviceAccessRoles.filter((r) => r.FacilityId === facilityId);
      })
    );
  }

  constructor(
    private roleApi: CapsaRoleApi,
    private enterpriseService: EnterpriseService
  ) {
    // API call is made once per app load (or if a facility is added we allow calling
    // it again), and not trigger till a component that uses this service is loaded
    this.refreshRolesFromApi();
  }

  public refreshRolesFromApi() {
    this.subs.add(
      this.roleApi
        .search({
          EnterpriseId: this.enterpriseService.enterpriseId,
          IncludePermissions: true,
          PageNumber: 1,

          // TODO: Fix ridiculous page size: NS-1456
          PageSize: 1000000,
          CapsaRoleIds: undefined,
          ProductLines: Constants.supportedProductLines,
          OnlyShowStandardRoles: false,
        })
        .pipe(map((r) => (r.Success ? r.Result : [])))
        .subscribe((x) => {
          this._unsafeRolesList = ImmutableList(x);
          this.rolesListReadyBehSub.next(true);
          this.replayApiSubject.next(x);
        })
    );
  }

  public unsubscribe(): void {
    this.subs.unsubscribe();
  }
}
