import { Component, Input } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';

@Component({
  selector: 'app-cart-profile-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input()
  public value: CapsaSettingApiModel;
  get valueAsBool(): boolean {
    return this.value.SettingValue === '1';
  }

  @Input()
  public title: string;

  onChange(newValue: boolean) {
    this.value.SettingValue = newValue === true ? '1' : '0';
  }

  constructor() {}
}
