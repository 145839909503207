import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  CartPartialResponse,
  NetworkAccessPointCreateRequest,
  NetworkAccessPointResponse,
  NetworkAccessPointSearchRequest,
  NetworkAccessPointUpdateRequest,
  NetworkApImportRequest,
  throwIfApiError,
} from '..';
import { BaseApi } from '../base/base.api';

export interface NetworkAccessPointInstance {
  getById(
    networkAccessPointId: number
  ): Observable<ApiResponse<NetworkAccessPointResponse>>;

  search(
    searchRequest: NetworkAccessPointSearchRequest
  ): Observable<ApiResponse<NetworkAccessPointResponse[]>>;

  create(
    createRequest: NetworkAccessPointCreateRequest
  ): Observable<ApiResponse<number>>;

  bulkImport(
    importRequest: NetworkApImportRequest
  ): Observable<ApiResponse<void>>;

  update(
    networkAccessPointId: number,
    updateRequest: NetworkAccessPointUpdateRequest
  ): Observable<ApiResponse<void>>;

  delete(networkAccessPointId: number): Observable<ApiResponse<void>>;

  getConnectedDevices(
    networkAccessPointId: number
  ): Observable<CartPartialResponse[]>;
}

@Injectable()
export class NetworkAccessPointApi
  extends BaseApi
  implements NetworkAccessPointInstance
{
  protected endpoints = {
    getUrl: this.buildUrl('networkaccesspoint/{id}'),
    getConnectedDevices: this.buildUrl(
      'networkaccesspoint/{id}/connected-devices'
    ),
    searchUrl: this.buildUrl('networkaccesspoint/search'),
    createUrl: this.buildUrl('networkaccesspoint/create'),
    importUrl: this.buildUrl('networkaccesspoint/import'),
    updateUrl: this.buildUrl('networkaccesspoint/update/{id}'),
    deleteUrl: this.buildUrl('networkaccesspoint/{id}'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }
  public getConnectedDevices(networkAccessPointId: number) {
    return this.http
      .get<CartPartialResponse[]>(
        this.endpoints.getConnectedDevices.replace(
          '{id}',
          networkAccessPointId.toString()
        )
      )
      .pipe(throwIfApiError());
  }

  public getById(
    networkAccessPointId: number
  ): Observable<ApiResponse<NetworkAccessPointResponse>> {
    return this.http.get<ApiResponse<NetworkAccessPointResponse>>(
      this.endpoints.getUrl.replace('{id}', networkAccessPointId.toString())
    );
  }

  public search(
    searchRequest: Partial<NetworkAccessPointSearchRequest>
  ): Observable<ApiResponse<NetworkAccessPointResponse[]>> {
    return this.http.post<ApiResponse<NetworkAccessPointResponse[]>>(
      this.endpoints.searchUrl,
      searchRequest
    );
  }

  public create(
    createRequest: NetworkAccessPointCreateRequest
  ): Observable<ApiResponse<number>> {
    return this.http.post<ApiResponse<number>>(
      this.endpoints.createUrl,
      createRequest
    );
  }

  public bulkImport(
    importRequest: NetworkApImportRequest
  ): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.importUrl,
      importRequest
    );
  }

  public update(
    networkAccessPointId: number,
    updateRequest: NetworkAccessPointUpdateRequest
  ): Observable<ApiResponse<void>> {
    return this.http.post<ApiResponse<void>>(
      this.endpoints.updateUrl.replace('{id}', networkAccessPointId.toString()),
      updateRequest
    );
  }

  public delete(networkAccessPointId: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(
      this.endpoints.deleteUrl.replace('{id}', networkAccessPointId.toString())
    );
  }
}
