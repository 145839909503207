import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ApiModule } from 'app/modules/api-module/api.module';
import { CapsaDialogsModule } from 'app/modules/capsa-dialogs/capsa-dialogs.module';
import { CapsaDropdownsModule } from 'app/modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { CustomInputsModule } from 'app/modules/kendo-custom/kendo-custom.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { CartListDialogComponent } from 'app/modules/update-cart-firmware/cart-list-dialog/cart-list-dialog.component';
import { UpdateCartFirmwareAdvancedComponent } from 'app/modules/update-cart-firmware/update-advanced/update-advanced.component';
import { UpdateCartFirmwareComponent } from 'app/modules/update-cart-firmware/update-firmware.component';
import { UpdateCartFirmwareFriendlyComponent } from 'app/modules/update-cart-firmware/update-friendly/update-friendly.component';
import { UpdateStatusIconPipe } from 'app/modules/update-cart-firmware/updateStatus.pipe';
import { UpdateStatusCssClassPipe } from 'app/modules/update-cart-firmware/updateStatusCssClass.pipe';
import { UpdateStatusTooltipTagPipe } from 'app/modules/update-cart-firmware/updateStatusTooltipTag.pipe';

@NgModule({
  declarations: [
    UpdateCartFirmwareAdvancedComponent,
    UpdateCartFirmwareFriendlyComponent,
    UpdateCartFirmwareComponent,
    UpdateStatusIconPipe,
    UpdateStatusCssClassPipe,
    UpdateStatusTooltipTagPipe,
    CartListDialogComponent,
  ],
  imports: [
    CommonModule,
    CapsaDropdownsModule,
    GridModule,
    ApiModule,
    ButtonsModule,
    TranslateModule,
    LabelsModule,
    DropDownsModule,
    PipesModule,
    CapsaDialogsModule,
    DialogModule,
    InputsModule,
    FormsModule,
    CustomInputsModule,
  ],
  providers: [DeviceTypeDropDownDataSource],
})
export class UpdateCartFirmwareModule {}
