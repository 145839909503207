import { Validators } from '@angular/forms';
import { CapsaRoleResponseDto } from '@capsa/api';
import { Permissions } from '@capsa/services/permission/permissions-enum';

export class UserHelpers {
  /**
   * Checks the permissions for each role in the list, and returns true if any of
   * those permissions gives the ability to view the dashboard (the permission that every Nsight user role should have)
   * @param allCliRoles a list of CLI roles that can be given to a user (these are full response DTO's that contain
   * permissions for each role)
   */
  static roleHasNsightPermission(
    allCliRoles: CapsaRoleResponseDto[],
    selectedRoleIds: number[]
  ) {
    return this.rolesHavePermissionsByRoleId(allCliRoles, selectedRoleIds, [
      Permissions.CAP_Dashboard_Analytic_Widget_Read,
      Permissions.CAP_Dashboard_Analytic_Widget_ReadWrite,
    ]);
  }

  /**
   * Checks the permissions for each role in the list, and returns true if any of
   * those permissions gives the ability to view the dashboard (the permission that every Nsight user role should have)
   * @param allCliRoles a list of CLI roles that can be given to a user (these are full response DTO's that contain
   * permissions for each role)
   */
  static anyRoleNsightPermission(rolesToCheck: CapsaRoleResponseDto[]) {
    return this.rolesHavePermissions(rolesToCheck, [
      Permissions.CAP_Dashboard_Analytic_Widget_Read,
      Permissions.CAP_Dashboard_Analytic_Widget_ReadWrite,
    ]);
  }

  public static roleHasDeviceAccessPermission(
    allCliRoles: CapsaRoleResponseDto[],
    selectedRoleIds: number[]
  ) {
    return this.rolesHavePermissionsByRoleId(allCliRoles, selectedRoleIds, [
      Permissions.CLI_DeviceAccess,
    ]);
  }

  public static anyRoleHasDeviceAccessPermission(
    rolesToCheck: CapsaRoleResponseDto[]
  ) {
    return this.rolesHavePermissions(rolesToCheck, [
      Permissions.CLI_DeviceAccess,
    ]);
  }

  public static rolesHavePermissions(
    rolesToCheck: CapsaRoleResponseDto[],
    perms: Permissions[]
  ) {
    if (!rolesToCheck || !rolesToCheck.length) {
      return false;
    }

    // Here we prevent duplicate checks on the same role name (although it might be from a different facility)
    // since the same role name for different facilities will have an identical permissions list
    const uniqueRolesMap = new Map<string, CapsaRoleResponseDto>();
    rolesToCheck.forEach((role) => {
      if (!uniqueRolesMap.has(role.Name)) {
        uniqueRolesMap.set(role.Name, role);
      }
    });

    const roleWithMatchingPermission = Array.from(uniqueRolesMap.values()).find(
      (role) => {
        if (!role.Permissions) {
          return false;
        }

        return !!role.Permissions.find((p) => {
          return !!perms.find((x) => x === p.Permission);
        });
      }
    );

    return !!roleWithMatchingPermission;
  }

  /**
   * This method does filtering on roleId's and then the actual permissions
   * @param allCliRoles a list of roles
   * @param selectedRoleIds a list of Role ID's to check
   * @param perms list of permissions to check
   */
  private static rolesHavePermissionsByRoleId(
    allCliRoles: CapsaRoleResponseDto[],
    selectedRoleIds: number[],
    perms: Permissions[]
  ) {
    if (!allCliRoles || !allCliRoles.length) {
      return false;
    }

    const selectedRoles = allCliRoles.filter((x) =>
      selectedRoleIds.find((selected) => selected === x.CapsaRoleId)
    );

    const roleWithMatchingPermission = selectedRoles.find((role) => {
      if (!role.Permissions) {
        return false;
      }

      return !!role.Permissions.find((p) => {
        return !!perms.find((x) => x === p.Permission);
      });
    });

    return !!roleWithMatchingPermission;
  }
}

export const UsernameValidator = Validators.compose([
  Validators.minLength(3),
  Validators.required,
  Validators.pattern(/^[a-z0-9_]+$/i),
]);
