import { Injectable } from '@angular/core';
import { AuthService } from '@capsa/services/auth/auth.service';

export interface AuthGuardServiceInstance {
  isLoggedIn(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements AuthGuardServiceInstance {
  constructor(private authService: AuthService) {}

  isLoggedIn() {
    return this.authService.hasAccessTokenCookie();
  }

  saveTokenToAuthCookie(token: string) {
    const getAuthResponseJson = decodeURIComponent(token);
    this.authService.loadCurrentUser(getAuthResponseJson);
    this.authService.setAuthCookie();
  }
}
