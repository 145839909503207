import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceTypeIds } from '@capsa/api';

@Component({
  selector: 'app-trio-user-settings',
  templateUrl: './trio-user-settings.component.html',
  styleUrls: ['./trio-user-settings.component.scss'],
})
export class TrioUserSettingsComponent {
  @Output()
  public witnessAuthChanged = new EventEmitter<boolean>();

  @Input()
  public witnessAuthEnabled: boolean;

  public trioDeviceTypeId = DeviceTypeIds.CareLink_2;

  constructor() {}

  public onWitnessAuthChanged(ev: Event) {
    this.witnessAuthEnabled = !this.witnessAuthEnabled;
    this.witnessAuthChanged.emit(this.witnessAuthEnabled);
  }
}
