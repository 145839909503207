import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { PagingRequestDto } from '..';

@Injectable()
export abstract class BaseApi {
  protected abstract endpoints: { [apiName: string]: string };

  protected baseUrl: string;
  protected loginPortalBaseUrl: string;

  constructor(
    protected http: HttpClient,
    appSettingService: AppSettingsService
  ) {
    this.baseUrl = appSettingService.get('apiUrl');
    this.loginPortalBaseUrl = appSettingService.get('loginPortalUrl');
  }

  protected buildUrl(relativeUrl: string) {
    const separator =
      this.baseUrl.endsWith('/') || relativeUrl.startsWith('/') ? '' : '/';

    return `${this.baseUrl}${separator}${relativeUrl}`;
  }

  protected buildPagingParams(pageInfo: PagingRequestDto) {
    return `pageNumber=${pageInfo.PageNumber}&pageSize=${pageInfo.PageSize}`;
  }

  protected buildParamString(paramsObject: any) {
    const paramString = Object.keys(paramsObject)
      .map(
        (paramName) =>
          `${paramName}=${encodeURIComponent(paramsObject[paramName])}`
      )
      .reduce((a, b) => `${a}&${b}`);

    return paramString;
  }
}
