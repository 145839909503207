import { Injectable } from '@angular/core';
import { EnterprisePermissionTree } from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { AuthService } from '@capsa/services/auth/auth.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { UserMemoryService } from '@capsa/services/user-memory/user-memory.service';

import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userPermissions: Set<Permissions> = new Set<Permissions>();

  private isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isReady$: Observable<boolean> = this.isReady.asObservable();

  public notifyOfOrgChangeSubj = new Subject<number>();
  private orgChanged$ = this.notifyOfOrgChangeSubj.asObservable();

  public notifyOfFacilityChangeSubj = new Subject<number>();
  public facilityChanged$ = this.notifyOfFacilityChangeSubj.asObservable();

  private permissionsUpdatedSubj = new Subject<void>();
  public permissionsUpdated$ = this.permissionsUpdatedSubj.asObservable();

  private permTree: EnterprisePermissionTree;
  private isWatching = false;

  public orgId: number;
  public facilityId: number;

  constructor(
    private authService: AuthService,
    private userApi: UserApi,
    private userMemoryService: UserMemoryService
  ) {
    const isAuthSub = this.authService.isAuthenticated$.subscribe(
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.refreshFromApi(isAuthSub);
        }
      }
    );
  }

  private watchForOrgFacChanges() {
    if (this.isWatching) {
      return;
    }

    this.isWatching = true;

    this.orgChanged$.subscribe(() => {
      this.refreshPermissions();
    });
    this.facilityChanged$.subscribe(() => {
      this.refreshPermissions();
    });
  }

  public has(p: Permissions): boolean {
    return this.userPermissions.has(p);
  }

  /**
   * Returns true if current user has ANY of the provided permissions
   * note: to avoid race condition on app init, use "hasAny$" to ensure
   * user permissions have loaded
   */
  public hasAny(perms: Permissions[]): boolean {
    return perms.some((p) => this.userPermissions.has(p));
  }

  /**
   * Checks if current user has the passed in permissions, after ensuring
   * the "get permissions" call has returned
   */
  public hasAny$(perms: Permissions[]): Observable<boolean> {
    return this.isReady$.pipe(
      filter((isReady) => isReady),
      switchMap(() => of(this.hasAny(perms)))
    );
  }

  /**
   * Used when we make an action that changes the currently logged in users facility assingments and or permissions
   * e.g. a facility is added and the API automatically adds the FacilityAdmin role for the logged in user, but
   * the permissionService still has permissions cached from app start
   */
  public refreshFromApi(subToUnsubscribe?: Subscription) {
    this.isReady.next(false);
    this.permTree = null;
    this.userApi
      .getPermissionTree()
      .pipe(
        finalize(() => {
          if (subToUnsubscribe) {
            subToUnsubscribe.unsubscribe();
          }
        })
      )
      .subscribe(
        (resp) => {
          this.permTree = resp;
          this.watchForOrgFacChanges();
          this.refreshPermissions();
          this.isReady.next(true);
        },
        (error) => {
          this.authService.logout();
        }
      );
  }

  /**
   * Used when we know the selected facility has changed, doesn't make an API call
   */
  private refreshPermissions() {
    const ofMemory = this.userMemoryService.userMemory;
    let permsToSet = this.permTree.EnterprisePermissions;

    this.orgId = ofMemory.orgId;
    this.facilityId = ofMemory.facilityId;

    if (ofMemory.orgId) {
      const orgData = this.permTree.Organizations.get(ofMemory.orgId);
      if (orgData) {
        permsToSet = new Set([
          ...permsToSet,
          ...orgData.OrganizationPermissions,
        ]);

        if (ofMemory.facilityId) {
          const fData = orgData.Facilities.get(ofMemory.facilityId);
          if (fData) {
            permsToSet = new Set([...permsToSet, ...fData.FacilityPermissions]);
          }
        }
      }
    }

    this.userPermissions.clear();
    permsToSet.forEach((p) => {
      const workflowPermission: Permissions = Permissions[p];
      if (workflowPermission) {
        this.userPermissions.add(workflowPermission);
      }
    });

    this.permissionsUpdatedSubj.next();
  }

  public getCurrentUserOrgFacTreeForSinglePerm(
    perm: Permissions
  ): Map<number, Set<number>> {
    const orgsAndFacsWithPerms = new Map<number, Set<number>>();
    // If permission exists at enterprise level, then return all orgs and facilities
    if (this.permTree.EnterprisePermissions.has(perm)) {
      this.permTree.Organizations.forEach((org) => {
        orgsAndFacsWithPerms.set(
          org.OrganizationId,
          new Set<number>([...org.Facilities.keys()])
        );
      });
    } else {
      this.permTree.Organizations.forEach((org) => {
        const facIds = new Set<number>();
        org.Facilities.forEach((fac) => {
          if (fac.FacilityPermissions.has(perm)) {
            facIds.add(fac.FacilityId);
          }
        });

        if (facIds.size > 0) {
          orgsAndFacsWithPerms.set(org.OrganizationId, facIds);
        }
      });
    }

    return orgsAndFacsWithPerms;
  }
}
