import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { InputsComponent } from 'app/menu/user/set-password/inputs/inputs.component';
import { RequirementComponent } from 'app/menu/user/set-password/requirements/requirement/requirement.component';
import { RequirementsComponent } from 'app/menu/user/set-password/requirements/requirements.component';
import { SetPasswordComponent } from 'app/menu/user/set-password/set-password.component';
import { ChangePasswordComponent } from 'app/menu/user/user-profile/change-password/change-password.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Menu pages
import { FaultEventsLogComponent } from 'app/menu/carts/fault-events-log/fault-events-log.component';
import { SoftwareUpdatesSentComponent } from 'app/menu/carts/software-updates-sent/software-updates-sent.component';
import { UserProfileComponent } from 'app/menu/user/user-profile/user-profile.component';

// Capsa API's
import { LOCATION_INITIALIZED } from '@angular/common';
import { NsightErrorHandler } from '@capsa/logging/error-logger';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconSettingsService } from '@progress/kendo-angular-icons';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import { KendoIconOverrideService } from 'app/kendo-icon-override/kendo-icon-override.service';
import { AccessPointsModule } from 'app/modules/access-points/access-points.module';
import { AnalyticsModule } from 'app/modules/analytics/analytics.module';
import { CartGroupModule } from 'app/modules/cart-group/cart-group.module';
import { CartLogsModule } from 'app/modules/cart-logs/cart-logs.module';
import { CartManagementModule } from 'app/modules/cart-management/cart-management.module';
import { CartProfileModule } from 'app/modules/cart-profile/cart-profile.module';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { DeviceUpdateModule } from 'app/modules/device-update/device-update.module';
import { FacilityModule } from 'app/modules/facility/facility.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { LanguageChangeModule } from 'app/modules/language-change/language-change.module';
import { LightingModule } from 'app/modules/lighting/lighting.module';
import { LoadingIndicatorModule } from 'app/modules/loading-indicator/loading-indicator.module';
import { LoggingModule } from 'app/modules/logging-module/logging.module';
import { MessagingModule } from 'app/modules/messaging/messaging.module';
import { SupportModule } from 'app/modules/support/support.module';
import { UpdateCartFirmwareModule } from 'app/modules/update-cart-firmware/update-firmware.module';
import { UsersModule } from 'app/modules/users/users.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Observable, from } from 'rxjs';
import { Constants } from './common/constants';
import { Utils } from './common/utils';
import { ApiModule } from './modules/api-module/api.module';
import { AppHeaderModule } from './modules/app-header/app-header.module';
import { CapsaDropdownsModule } from './modules/capsa-dropdowns-module/capsa-dropdowns.module';
import { GlobalNavComponent } from './modules/global-nav/global-nav.component';
import { ServicesModule } from './modules/services-module/services.module';

// Enables delaying app init till promise is resolved
// https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerFactory(
  translate: TranslateService,
  appSettingService: AppSettingsService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve) => {
      // Set feature flags that need to be set before app init is complete
      Utils.setAppInitFeatureFlags();

      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        let langToSet = Utils.getLanguageToSet();
        translate.defaultLang = Constants.defaultLanguage;
        translate.langs = Constants.availableLangs;
        if (!Constants.availableLangs.includes(langToSet)) {
          langToSet = Constants.defaultLanguage;
        }

        Utils.updateSideBarWidthForLanguage(langToSet);

        translate.use(langToSet).subscribe(
          (translations) => {
            appSettingService.load();

            appSettingService.loaded$.subscribe(
              (loaded) => {
                if (loaded) {
                  resolve(null);
                }
              },
              (errors) => {
                console.error(
                  'Problem initializing application settings:',
                  errors
                );
              }
            );
          },
          (error) => {
            console.error(
              `Problem initializing language '${langToSet}':`,
              error
            );
          }
        );
      });
    });
}

// Cache-busts translation files: https://github.com/ngx-translate/http-loader/issues/25#issuecomment-514056865
export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    RequirementsComponent,
    InputsComponent,
    SetPasswordComponent,
    RequirementComponent,
    FaultEventsLogComponent,
    SoftwareUpdatesSentComponent,
    GlobalNavComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelsModule,
    AppHeaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    ApiModule,
    ServicesModule,
    CapsaDropdownsModule,
    CartGroupModule,
    CartProfileModule,
    DeviceUpdateModule,
    GridModule,
    TabStripModule,
    AppHeaderModule,
    UpdateCartFirmwareModule,
    CartManagementModule,
    LanguageChangeModule,
    UsersModule,
    FacilityModule,
    MessagingModule,
    CartLogsModule,
    LightingModule,
    SupportModule,
    UploadModule,
    HttpClientModule,
    TooltipModule,
    DashboardModule,
    LoadingIndicatorModule,
    AccessPointsModule,
    AnalyticsModule,
    NgxUiLoaderModule, // See: https://tdev.app/ngx-ui-loader
    DialogModule,
    LoggingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, AppSettingsService, Injector],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: NsightErrorHandler,
    },
    { provide: IconSettingsService, useClass: KendoIconOverrideService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
