import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { OrganizationMultiSelectDataSource } from '@capsa/dropdowns/organization-multi-select/organization-multi-select-data-source';

@Component({
  selector: 'app-organization-multi-select',
  templateUrl: './organization-multi-select.component.html',
  styleUrls: ['./organization-multi-select.component.css'],
})
export class OrganizationMultiSelectComponent {
  @Input()
  public dataSource: OrganizationMultiSelectDataSource;

  @Output()
  valueChanged = new EventEmitter<
    AsyncDropDownValue<NameWithLongIdApiModel>[]
  >();

  constructor() {}
}
