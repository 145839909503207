// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateInputsMainWrapper {
  min-width: 220px;
}

kendo-daterange {
  display: block;
  width: 180px;
  float: left;
}

.dateInputWrapper {
  width: 180px;
  display: flex;
}
.dateInputWrapper > span {
  text-align: right;
  padding-right: 5px;
  display: block;
  min-width: 50px;
  line-height: 30px;
}

.range-filter {
  display: block;
}

.k-button {
  display: block;
  float: left;
  margin-left: 5px;
  margin-top: 17px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/kendo-custom/date-range-filter-cell/date-range-filter-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;AAEF;AADE;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAGJ;;AAAA;EACE,cAAA;AAGF;;AADA;EACE,cAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AAIF","sourcesContent":[".dateInputsMainWrapper {\n  min-width: 220px;\n}\n\nkendo-daterange {\n  display: block;\n  width: 180px;\n  float: left;\n}\n.dateInputWrapper {\n  width: 180px;\n  display: flex;\n  > span {\n    text-align: right;\n    padding-right: 5px;\n    display: block;\n    min-width: 50px;\n    line-height: 30px;\n  }\n}\n.range-filter {\n  display: block;\n}\n.k-button {\n  display: block;\n  float: left;\n  margin-left: 5px;\n  margin-top: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
