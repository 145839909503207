import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import {
  ActivityResponse,
  ActivitySearchRequest,
  ApiActivityResponse,
  ApiActivitySearchRequest,
  ApiResponse,
  DeviceTypeIds,
  PagingRequestDto,
} from '..';
import { BaseApi } from '../base/base.api';

export interface ActivityApiInstance {
  search(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceTypeId: number,
    query: Partial<ActivitySearchRequest>
  ): Observable<ApiResponse<ActivityResponse[]>>;
}

@Injectable()
export class ActivityApi extends BaseApi implements ActivityApiInstance {
  protected endpoints = {
    activitySearchUrl: this.buildUrl('carelinkactivity/search'),
    avaloActivitySearchUrl: this.buildUrl('avaloactivity/search'),
    webActivitySearchUrl: this.buildUrl('apiactivity/search'),
  };

  constructor(
    client: HttpClient,
    private enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
  }

  public search(
    paging: PagingRequestDto,
    orgId: number,
    facilityId: number,
    deviceType: DeviceTypeIds,
    query: Partial<ActivitySearchRequest>
  ): Observable<ApiResponse<ActivityResponse[]>> {
    const searchRequest: ActivitySearchRequest = {
      ...(query as ActivitySearchRequest),
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationIds: [orgId],
      FacilityIds: [facilityId],
      PageNumber: paging.PageNumber,
      PageSize: paging.PageSize,
    };

    if (deviceType === DeviceTypeIds.Avalo) {
      return this.http.post<ApiResponse<ActivityResponse[]>>(
        this.endpoints.avaloActivitySearchUrl,
        searchRequest
      );
    }
    return this.http.post<ApiResponse<ActivityResponse[]>>(
      this.endpoints.activitySearchUrl,
      searchRequest
    );
  }
  public searchWebActivity(
    paging: PagingRequestDto,
    query: Partial<ApiActivitySearchRequest>
  ): Observable<ApiResponse<ApiActivityResponse[]>> {
    const searchRequest: ApiActivitySearchRequest = {
      ...(query as ApiActivitySearchRequest),
      EnterpriseId: this.enterpriseService.enterpriseId,
      PageNumber: paging.PageNumber,
      PageSize: paging.PageSize,
    };

    return this.http.post<ApiResponse<ApiActivityResponse[]>>(
      this.endpoints.webActivitySearchUrl,
      searchRequest
    );
  }
}
