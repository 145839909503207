// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnUpdateSubmit {
  float: right;
  margin-top: 20px;
}

.cols-wrapper {
  height: auto;
}

.controls-col {
  display: flex;
  flex-direction: column;
  flex: 0 0 115px;
}

.bold {
  font-weight: bold;
}

.dialogButtons button {
  margin-right: 15px;
}

.leftGrid {
  flex-basis: 60%;
  flex-grow: 2;
  flex-shrink: 1;
}

.rightGrid {
  flex-basis: 40%;
  flex-grow: 1;
  flex-shrink: 3;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/update-cart-firmware/update-friendly/update-friendly.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAIA;EACE,eAHqB;EAIrB,YAAA;EACA,cAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;EACA,cAAA;AADF","sourcesContent":[".btnUpdateSubmit {\n  float: right;\n  margin-top: 20px;\n}\n\n.cols-wrapper {\n  height: auto;\n}\n\n.controls-col {\n  display: flex;\n  flex-direction: column;\n  flex: 0 0 115px;\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.dialogButtons button {\n  margin-right: 15px;\n}\n\n$leftGridWidthPercent: 60%;\n\n.leftGrid {\n  flex-basis: $leftGridWidthPercent;\n  flex-grow: 2;\n  flex-shrink: 1;\n}\n\n.rightGrid {\n  flex-basis: (100 - $leftGridWidthPercent);\n  flex-grow: 1;\n  flex-shrink: 3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
