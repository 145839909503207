const menuKeys = {
  home: {
    root: 'MENU_DASHBOARD',
  },
  analytics: {
    root: 'MENU_ANALYTICS',
    access: 'MENU_ANALYTICS_ACCESS',
    power: 'MENU_ANALYTICS_POWER',
    accessPoints: 'MENU_ANALYTICS_ACCESS_POINTS',
  },
  messaging: {
    root: 'MENU_MESSAGES',
    serviceRequests: 'MENU_MESSAGES_SERVICE_REQUESTS',
    subjectPresets: 'MENU_MESSAGES_SUBJECT_PRESETS',
    newNotifications: 'MENU_MESSAGES_NEW_NOTIFICATIONS',
    notificationTemplate: 'MENU_MESSAGES_NOTIFICATION_TEMPLATE',
    sentNotifications: 'MENU_MESSAGES_SENT_NOTIFICATIONS',
    sentMessages: 'MENU_MESSAGES_SENT_MESSAGES',
  },
  facilities: {
    manage: 'MENU_FACILITIES_MANAGE',
    lighting: 'MENU_LIGHTING',
    root: 'MENU_FACILITIES',
    info: 'MENU_FACILITIES_INFO',
    profiles: 'CART_PROFILES_HEADER',
    accessPoints: 'ACCESS_POINTS',
  },
  user: {
    root: 'MENU_USERS',
    login: 'MENU_USER_LOGIN',
    management: 'MENU_USER_MANAGEMENT',
    createUser: 'MENU_USER_CREATE_USER',
    bulkAssign: 'MENU_BULK_ASSIGN_USERS',
    webActivity: 'MENU_USER_WEB_ACTIVITY',
    cartActivity: 'MENU_USER_CART_ACTIVITY',
    groupMaintenance: 'MENU_USER_GROUP_MAINTENANCE',
    importUsers: 'MENU_USER_IMPORT',
  },
  carts: {
    root: 'MENU_CARTS',
    manage: 'MENU_CARTS_MANAGE',
    groups: 'MENU_CARTS_GROUPS',
    faults: 'MENU_CARTS_FAULTS',
    updates: 'MENU_CARTS_UPDATES',
    updatesSent: 'MENU_CARTS_UPDATES_SENT',
  },
  support: {
    root: 'MENU_SUPPORT',
    addFacility: 'MENU_SUPPORT_ADD_FACILITY',
    transferCarts: 'MENU_SUPPORT_TRANSFER_CARTS',
    releases: 'MENU_SUPPORT_RELEASES',
  },
  account: {
    profile: 'ACCOUNT_PROFILE',
  },
};

const commonKeys = {
  user: 'COMMON_USERS',
};

export const TranslationKeys = {
  menu: {
    ...menuKeys,
  },
  common: {
    ...commonKeys,
  },
};
