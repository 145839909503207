// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#displayMode {
  flex: 1 1 30%;
}
#displayMode .k-label,
#displayMode input {
  cursor: pointer;
}

.radioOptionWrapper {
  float: left;
  margin-right: 15px;
  margin-top: 6px;
}
.radioOptionWrapper:last-of-type {
  margin-right: 0;
}

.dynamicControlsWrapper {
  display: flex;
  gap: 30px;
}
.dynamicControlsWrapper app-device-update-type-drop-down {
  flex: 1;
  min-width: 300px;
}
.dynamicControlsWrapper #displayMode {
  flex: 1;
}
.dynamicControlsWrapper .buttonWrapper {
  flex: 0;
  padding-top: 30px;
}
.dynamicControlsWrapper .buttonWrapper button {
  min-width: 85px;
}

app-org-facility-drop-downs {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/update-cart-firmware/update-firmware.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;;EAEE,eAAA;AAEJ;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;AAEJ;;AAEA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,OAAA;EACA,gBAAA;AACJ;AAEE;EACE,OAAA;AAAJ;AAGE;EACE,OAAA;EACA,iBAAA;AADJ;AAEI;EACE,eAAA;AAAN;;AAKA;EACE,mBAAA;AAFF","sourcesContent":["#displayMode {\n  flex: 1 1 30%;\n  .k-label,\n  input {\n    cursor: pointer;\n  }\n}\n\n.radioOptionWrapper {\n  float: left;\n  margin-right: 15px;\n  margin-top: 6px;\n  &:last-of-type {\n    margin-right: 0;\n  }\n}\n\n.dynamicControlsWrapper {\n  display: flex;\n  gap: 30px;\n\n  app-device-update-type-drop-down {\n    flex: 1;\n    min-width: 300px;\n  }\n\n  #displayMode {\n    flex: 1;\n  }\n\n  .buttonWrapper {\n    flex: 0;\n    padding-top: 30px;\n    button {\n      min-width: 85px;\n    }\n  }\n}\n\napp-org-facility-drop-downs {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
