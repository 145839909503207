// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colTitle {
  font-weight: bold;
}

.sliderVal {
  display: block;
  width: 45px;
  float: left;
  margin-top: 4px;
  font-weight: bold;
}

#btnSaveTheme {
  float: right;
}

#themeNameLabel {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/lighting/lighting-led-theme-config/lighting-led-theme-config.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".colTitle {\n  font-weight: bold;\n}\n\n.sliderVal {\n  display: block;\n  width: 45px;\n  float: left;\n  margin-top: 4px;\n  font-weight: bold;\n}\n\n#btnSaveTheme {\n  float: right;\n}\n\n#themeNameLabel {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
