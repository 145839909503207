import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DeviceProfileSearchResponseApiModel, DeviceTypeIds } from '@capsa/api';
import { CartProfileApi } from '@capsa/api/cart-profile';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { GridFilterConfiguration } from 'app/modules/cart-profile/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';

@Component({
  selector: 'app-cart-profile-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit, OnDestroy {
  constructor(
    private api: CartProfileApi,
    private permissionService: PermissionService,
    private toasterService: ToasterService
  ) {}
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Carts];

  public filters: GridFilterConfiguration = {
    deviceTypeId: undefined,
    facilityId: undefined,
    organizationId: undefined,
  };

  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<
    DeviceProfileSearchResponseApiModel[]
  >([]);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;

  get isAvalo(): boolean {
    return this.filters.deviceTypeId === DeviceTypeIds.Avalo;
  }

  @Output()
  public add = new EventEmitter<GridFilterConfiguration>();

  @Output()
  public edit = new EventEmitter<DeviceProfileSearchResponseApiModel>();

  private subs = new Subscription();
  private apiSearchSub = new Subscription();

  ngOnInit() {
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.resetPagingAndData();
          this.loadGridData();
        } else {
          this.resetPagingAndData();
          this.loadGridData();
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  public onOrgChanged(newValue: number | undefined) {
    this.filters.organizationId = newValue;
  }

  public onFacilityChanged(newValue: number | undefined) {
    this.filters.facilityId = newValue;
  }

  public onDeviceTypeChanged(newValue: number | undefined) {
    this.filters.deviceTypeId = newValue;
    this.resetPagingAndData();
    this.loadGridData();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;

    this.loadGridData();
  }

  private loadGridData() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a "deselect", we want to make sure data doesn't come in
    // after we've went back to an "unselected" filter (prevents race condition bugs)
    this.apiSearchSub.unsubscribe();

    if (
      !this.filters.organizationId ||
      !this.filters.facilityId ||
      this.filters.deviceTypeId !== DeviceTypeIds.CareLink_2 ||
      !this.canViewPage
    ) {
      return;
    }
    this.gridDataLoading = true;
    this.apiSearchSub = this.api
      .gridSearch({
        DeviceTypeId: this.filters.deviceTypeId,
        FacilityId: this.filters.facilityId,
        OrganizationId: this.filters.organizationId,
        PageNumber: this.skip / this.pageSize + 1,
        PageSize: this.pageSize,
      })
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }

          this.gridDataLoading = false;
        },
        (error) => {
          this.toasterService.showError('GENERIC_GET_LIST_FAILED');
          this.gridDataLoading = false;
        }
      );
  }

  public isRowSelected = () => false;

  public rowSelected(
    row: SelectionEvent | DeviceProfileSearchResponseApiModel
  ) {
    const selection = row as SelectionEvent;
    const apiModel = row as DeviceProfileSearchResponseApiModel;

    const toEmit =
      selection.selectedRows !== undefined
        ? (selection.selectedRows[0]
            .dataItem as DeviceProfileSearchResponseApiModel)
        : apiModel;
    this.edit.emit(toEmit);
  }

  public addClicked() {
    this.add.emit(this.filters);
  }
}
