import { Injectable } from '@angular/core';
import {
  CartFirmwareResponse,
  CartFriendlyUpdateGridItem,
  CartResponse,
  DeviceTypeIds,
  DeviceUpdateType,
  FirmwareResponse,
  FirmwareUpdateCartsResponse,
  FirmwareVersionStatusResponse,
  PagingRequestDto,
  UpdateStatusType,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { DeviceUpdateCacheService } from '@capsa/services/device-update-cache/device-update-cache.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Constants } from 'app/common/constants';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ApplyDeviceUpdateService {
  constructor(
    private firmHardWareApi: FirmHardWareApi,
    private deviceUpdateCacheService: DeviceUpdateCacheService,
    private permissionService: PermissionService,
    private cartApi: CartApi
  ) {}

  private fwList: FirmwareResponse[];

  private deviceTypeId = DeviceTypeIds.CareLink_2;

  public getCartsAndUpdatesList(
    isAdvanced = false
  ): Observable<CartFriendlyUpdateGridItem[]> {
    const paging: PagingRequestDto = { PageNumber: 1, PageSize: 20000 };

    const getCartsObs = this.cartApi.searchCarts(paging, {
      OrganizationIds: [this.permissionService.orgId],
      FacilityIds: [this.permissionService.facilityId],
      DeviceTypeId: this.deviceTypeId,
      DeviceKeyValues: this.getDeviceKeyVals(isAdvanced),
    });

    const cartsList: CartResponse[] = [];

    // First we call "get carts" and "get FW list" in parallel
    // since they don't depend on each other
    // then we call "get auto update info" because we need list
    // of cartId's to send to that API endpoint
    // forkJoin makes two calls and waits for both to return before continuing
    // switchMap essentially "switches" processing over to the
    // "auto update info" observable
    return forkJoin([
      getCartsObs,
      this.deviceUpdateCacheService.getFwList(this.deviceTypeId),
    ]).pipe(
      switchMap((resp) => {
        cartsList.push(...resp[0].Result);
        this.fwList = resp[1];

        const cartIds = cartsList.map((x) => x.CartId);
        return this.firmHardWareApi.getAutoUpdateInfo(cartIds);
      }),
      map((resp) => {
        return this.getFriendlyGridCarts(resp, cartsList);
      })
    );
  }

  private getFriendlyGridCarts(
    resp: FirmwareUpdateCartsResponse,
    cartsList: CartResponse[]
  ): CartFriendlyUpdateGridItem[] {
    const friendlyGridCarts: CartFriendlyUpdateGridItem[] =
      cartsList as CartFriendlyUpdateGridItem[];
    const updatesMap = new Map<number, CartFirmwareResponse>();

    resp.CartFirmwares.forEach((cu) => {
      if (!updatesMap.has(cu.CartId)) {
        updatesMap.set(cu.CartId, cu);
      }
    });

    friendlyGridCarts.forEach((c) => {
      const updates = updatesMap.get(c.CartId);

      if (!updates) {
        c.ccsStatus = UpdateStatusType.Unknown;
        c.isFullyUpToDate = false;
        return;
      }

      this.setStatuses(c, updates);
    });

    return friendlyGridCarts;
  }

  public isPnInWhiteList(partNumber: string, updateType: DeviceUpdateType) {
    return this.fwList
      .filter((fw) => fw.UpdateType === updateType)
      .some((fw) => fw.PartNumber === partNumber);
  }

  private getDeviceKeyVals(isAdvanced: boolean): string[] {
    let deviceKeyVals = [
      Constants.Key_DistBoardPartNumber,
      Constants.Key_LockBoardPartNumber,
      Constants.Key_TaskBoardPartNumber,
      Constants.Key_BSPVersion,
    ];

    if (isAdvanced) {
      deviceKeyVals = [
        ...deviceKeyVals,
        ...[
          Constants.Key_DistributionBoardApplicationVersion,
          Constants.Key_TaskLightBoardApplicationVersion,
          Constants.Key_LockBoard1ApplicationVersion,
          Constants.Key_LockBoard2ApplicationVersion,
          Constants.Key_LockBoard3ApplicationVersion,
        ],
      ];
    }

    return deviceKeyVals;
  }

  private setStatuses(
    c: CartFriendlyUpdateGridItem,
    updates: CartFirmwareResponse
  ) {
    const ccsBoardVersion = c.ComponentValues?.get(Constants.Key_BSPVersion);
    const distBrdPn = c.ComponentValues?.get(Constants.Key_DistBoardPartNumber);
    const taskLightBrdPn = c.ComponentValues?.get(
      Constants.Key_TaskBoardPartNumber
    );
    const lockBrdPn = c.ComponentValues?.get(Constants.Key_LockBoardPartNumber);

    // Carts don't report a CCS board part number because they ALL have a CCS board
    // and (as of 10/2023) they all have the same part number
    // To determine if the cart data is valid, we check if the cart has repored
    // its BSP Version (the CCS board firmware version)
    if (!ccsBoardVersion) {
      c.ccsStatus = UpdateStatusType.Unknown;
    } else {
      c.bspVersion = ccsBoardVersion;
      c.ccsStatus = this.getUpdateStatus(
        updates.Firmwares,
        DeviceUpdateType.Firmware
      );
    }

    if (!this.isPnInWhiteList(distBrdPn, DeviceUpdateType.AccessoryDistBoard)) {
      c.distBoardStatus = UpdateStatusType.Unknown;
    } else {
      c.distBoardStatus = this.getUpdateStatus(
        updates.Firmwares,
        DeviceUpdateType.AccessoryDistBoard
      );
    }

    if (
      !this.isPnInWhiteList(
        taskLightBrdPn,
        DeviceUpdateType.AccessoryTaskLightBoard
      )
    ) {
      c.taskLightBoardStatus = UpdateStatusType.Unknown;
    } else {
      c.taskLightBoardStatus = this.getUpdateStatus(
        updates.Firmwares,
        DeviceUpdateType.AccessoryTaskLightBoard
      );
    }

    if (
      !this.isPnInWhiteList(lockBrdPn, DeviceUpdateType.AccessoryLockingBoard)
    ) {
      c.lockBoardStatus = UpdateStatusType.Unknown;
    } else {
      c.lockBoardStatus = this.getUpdateStatus(
        updates.Firmwares,
        DeviceUpdateType.AccessoryLockingBoard
      );
    }

    const allUpdateStatuses = [
      c.ccsStatus,
      c.distBoardStatus,
      c.taskLightBoardStatus,
      c.lockBoardStatus,
    ];

    c.isFullyUpToDate = !allUpdateStatuses.some(
      (x) =>
        x === UpdateStatusType.NeedsUpdate ||
        x === UpdateStatusType.UpdatePending
    );

    // If the CCS board or dist board is "unknown" then there are data issues with the cart
    // we CANNOT determine if it's fully up to date
    if (
      c.ccsStatus === UpdateStatusType.Unknown ||
      c.distBoardStatus === UpdateStatusType.Unknown
    ) {
      c.isFullyUpToDate = false;
    }

    c.isUpdatePending = allUpdateStatuses.some(
      (x) => x === UpdateStatusType.UpdatePending
    );
  }

  private getUpdateStatus(
    firmwares: FirmwareVersionStatusResponse[],
    updateType: DeviceUpdateType
  ): UpdateStatusType {
    const update = firmwares.find((f) => f.UpdateType === updateType);

    if (update) {
      return update.UpdatePending
        ? UpdateStatusType.UpdatePending
        : UpdateStatusType.NeedsUpdate;
    }

    return UpdateStatusType.UpToDate;
  }
}
