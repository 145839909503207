import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import {
  DeviceUpdateType,
  FirmwareResponse,
  FirmwareVersionDetailsResponse,
} from '@capsa/api';
import { FirmHardWareApi } from '@capsa/api/firm-hard-ware/firm-hard-ware.api';
import { DropDownItemGeneric } from '@capsa/dropdowns/enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { SharedDeviceUpdateService } from 'app/modules/device-update/device-update.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
  providers: [SharedDeviceUpdateService],
})
export class ListPageComponent implements OnDestroy {
  constructor(
    public deviceUpdateService: SharedDeviceUpdateService,
    private toasterService: ToasterService,
    private firmHardWareApi: FirmHardWareApi
  ) {}

  public newVersionCtrl = new FormControl(
    '',
    Validators.compose([Validators.required, Validators.minLength(3)])
  );
  private refreshFirmwareListSubj = new Subject();

  public refreshFirmwareList$ = this.refreshFirmwareListSubj.asObservable();

  public isFirmwareUploadDialogOpen = false;

  private subs = new Subscription();

  public fwVersionListLoading = true;

  public pageSize = 1000;
  public skip = 0;

  public firmwareVersionGridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public fwSort: SortDescriptor[] = [
    {
      field: 'VersionNumber',
      dir: 'desc',
    },
  ];

  public dialogDeleteConfirmOpen = false;

  private idToDelete: number;

  private selectedFw: FirmwareResponse;

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public updateTypeChanged(newValue: DropDownItemGeneric<DeviceUpdateType>) {
    this.deviceUpdateService.setUpdateType(
      !newValue ? undefined : newValue.Value
    );

    this.refreshFirmwareListSubj.next();
  }

  private refreshFirmwareVersionList() {
    if (!this.deviceUpdateService.deviceTypeId || !this.selectedFw) {
      this.firmwareVersionGridData = {
        data: [],
        total: 0,
      };
      return;
    }

    this.fwVersionListLoading = true;

    this.subs.add(
      this.deviceUpdateService
        .getFwVersionList()
        .pipe(
          finalize(() => {
            this.fwVersionListLoading = false;
          })
        )
        .subscribe(
          (resp) => {
            const filteredFwVersions = resp.filter(
              (x) => x.Firmware.PartNumber === this.selectedFw.PartNumber
            );

            this.firmwareVersionGridData = {
              data: orderBy(filteredFwVersions, this.fwSort),
              total: filteredFwVersions.length,
            };
          },
          (error) => {
            this.toasterService.showError('COM_GET_LIST_FAILED');
          }
        )
    );
  }

  public removeHandler({
    dataItem,
  }: {
    dataItem: FirmwareVersionDetailsResponse;
  }) {
    this.idToDelete = dataItem.FirmwareVersionId;
    this.dialogDeleteConfirmOpen = true;
  }

  public dialogConfirmDelete(doDelete: boolean) {
    if (doDelete) {
      this.fwVersionListLoading = true;
      this.subs.add(
        this.firmHardWareApi
          .deleteFirmwareById(this.idToDelete)
          .pipe(
            finalize(() => {
              this.dialogDeleteConfirmOpen = false;
            })
          )
          .subscribe(
            (resp) => {
              this.deviceUpdateService.clearFwVersionCacheForCurrentDeviceType();
              this.refreshFirmwareVersionList();
            },
            (error) => {
              this.toasterService.showError('DELETE_FAILED');
              this.fwVersionListLoading = false;
            }
          )
      );
    } else {
      this.dialogDeleteConfirmOpen = false;
      this.idToDelete = undefined;
    }
  }

  public uploadSuccess() {
    this.isFirmwareUploadDialogOpen = false;

    this.deviceUpdateService.clearFwVersionCacheForCurrentDeviceType();
    this.refreshFirmwareVersionList();
  }

  public fwSortChange(sort: SortDescriptor[]): void {
    this.fwSort = sort;
    this.refreshFirmwareVersionList();
  }

  public onFirmwareChanged(fw: FirmwareResponse) {
    this.selectedFw = fw;
    this.refreshFirmwareVersionList();
  }
}
