import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-cart-profile-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
})
export class NameComponent {
  @Input()
  public form: UntypedFormGroup;

  get cssClassList() {
    return this.form.controls.name.valid || this.form.controls.name.pristine
      ? 'form-control'
      : 'form-control invalid-input';
  }

  constructor() {}
}
