import { DynamicTab } from 'app/modules/cart-profile/helpers';
import { CarelinkV2BatterySettingsTabComponent } from './carelink-v2-battery-settings-tab/carelink-v2-battery-settings-tab.component';
import { CarelinkV2DeviceFeaturesTabComponent } from './carelink-v2-device-features-tab/carelink-v2-device-features-tab.component';
import { CarelinkV2DisplayTabComponent } from './carelink-v2-display-tab/carelink-v2-display-tab.component';

export const CareLinkV2Tabs: DynamicTab[] = [
  {
    titleTag: 'DISPLAY_SETTINGS',
    component: CarelinkV2DisplayTabComponent,
  },
  {
    titleTag: 'BATTERY_SETTINGS',
    component: CarelinkV2BatterySettingsTabComponent,
  },
  {
    titleTag: 'DEVICE_FEATURES',
    component: CarelinkV2DeviceFeaturesTabComponent,
  },
];
