import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface CartLogSearchInputs {
  cartName: string;
  serialNumber: string;
  userName: string;
}

@Component({
  selector: 'app-cart-log-search-fields',
  templateUrl: './cart-log-search-fields.component.html',
  styleUrls: ['./cart-log-search-fields.component.scss'],
})
export class CartLogSearchFieldsComponent {
  public inputs: CartLogSearchInputs = {
    serialNumber: '',
    cartName: '',
    userName: '',
  };

  @Input()
  public includeUserSearch = false;

  @Output()
  public search = new EventEmitter<CartLogSearchInputs>();

  constructor() {}

  onTextChanged(prop: keyof CartLogSearchInputs, val: string) {
    this.inputs[prop] = val;
  }

  onSubmit() {
    this.search.emit(this.inputs);
  }
}
