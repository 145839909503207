import { Colors } from '@capsa/api';

export interface ColorCombo {
  tagName?: string;
  fgColor: number;
  bgColor: number;
}

export const validColorCombos = [
  {
    tagName: 'COM_WHITE',
    fgColor: Colors.Black,
    bgColor: Colors.White,
  },
  {
    tagName: 'COM_YELLOW',
    fgColor: Colors.Black,
    bgColor: 0xfbdf4f,
  },
  {
    tagName: 'COM_RED',
    fgColor: Colors.White,
    bgColor: 0xc11d1d,
  },
  {
    tagName: 'COM_PURPLE',
    fgColor: Colors.White,
    bgColor: 0x7d57b2,
  },
  {
    tagName: 'COM_PINK',
    fgColor: Colors.Black,
    bgColor: 0xf7ceec,
  },
  {
    tagName: 'COM_ORANGE',
    fgColor: Colors.Black,
    bgColor: 0xef8326,
  },
  {
    tagName: 'COM_GREY',
    fgColor: Colors.Black,
    bgColor: 0xd8d8d8,
  },
  {
    tagName: 'COM_GREEN',
    fgColor: Colors.White,
    bgColor: 0x2d8703,
  },
  {
    tagName: 'COM_BROWN',
    fgColor: Colors.White,
    bgColor: 0x975545,
  },
  {
    tagName: 'COM_BLUE',
    fgColor: Colors.White,
    bgColor: 0x0076a8,
  },
  {
    tagName: 'COM_BLACK',
    fgColor: Colors.White,
    bgColor: Colors.Black,
  },
];

export function isValidColorCombo(fgColor: number, bgColor: number): boolean {
  const match = validColorCombos.find(
    (x) => x.fgColor === fgColor && x.bgColor === bgColor
  );
  return !!match;
}
