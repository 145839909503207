import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  NotificationGroupCreateRequest,
  NotificationGroupDeleteRequest,
  NotificationGroupResponse,
  NotificationGroupSearchRequest,
  NotificationGroupUpdateRequest,
  NotificationType,
} from '@capsa/api';
import { BaseApi } from '@capsa/api/base';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface NotificationGroupApiInstance {
  createNotificationGroup(
    organizationId: number,
    facilityId: number,
    description: string,
    notificationType: NotificationType,
    email: string
  ): Observable<ApiResponse<NotificationGroupResponse>>;

  updateNotificationGroup(
    id: number,
    organizationId: number,
    facilityId: number,
    description: string,
    notificationType: NotificationType,
    email: string
  ): Observable<ApiResponse<NotificationGroupResponse>>;

  deleteNotificationGroup(id: number): Observable<ApiResponse<void>>;

  searchNotificationGroup(
    query: NotificationGroupSearchRequest
  ): Observable<ApiResponse<NotificationGroupResponse[]>>;
}

@Injectable()
export class NotificationGroupApi
  extends BaseApi
  implements NotificationGroupApiInstance
{
  protected endpoints = {
    create: this.buildUrl('NotificationGroup/Create'),
    update: this.buildUrl('NotificationGroup/Update'),
    delete: this.buildUrl('NotificationGroup/Delete'),
    search: this.buildUrl('NotificationGroup/Search'),
  };

  constructor(
    client: HttpClient,
    private enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
  }

  public createNotificationGroup(
    organizationId: number,
    facilityId: number,
    description: string,
    notificationType: NotificationType,
    email: string
  ): Observable<ApiResponse<NotificationGroupResponse>> {
    const body: NotificationGroupCreateRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationId: organizationId,
      FacilityId: facilityId,
      Description: description,
      NotificationType: notificationType,
      Members: [
        {
          Email: email,
          EnterpriseId: this.enterpriseService.enterpriseId,
          OrganizationId: organizationId,
          FacilityId: facilityId,
          Description: description,
        },
      ],
    };

    return this.http
      .post<ApiResponse<number>>(this.endpoints.create, body)
      .pipe(
        map((response) => {
          if (!response.Success) {
            throw response;
          }

          const mappedResponse = {
            ...(response as any),
            Result: {
              ...body,
              NotificationGroupId: response.Result,
            },
          } as ApiResponse<NotificationGroupResponse>;

          return mappedResponse;
        })
      );
  }

  public updateNotificationGroup(
    id: number,
    organizationId: number,
    facilityId: number,
    description: string,
    notificationType: NotificationType,
    email: string
  ): Observable<ApiResponse<NotificationGroupResponse>> {
    const body: NotificationGroupUpdateRequest = {
      NotificationGroupId: id,
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationId: organizationId,
      FacilityId: facilityId,
      Description: description,
      NotificationType: notificationType,
      Members: [
        {
          Email: email,
          EnterpriseId: this.enterpriseService.enterpriseId,
          OrganizationId: organizationId,
          FacilityId: facilityId,
          Description: description,
        },
      ],
    };

    return this.http.post<ApiResponse<NotificationGroupResponse>>(
      this.endpoints.update,
      body
    );
  }

  public deleteNotificationGroup(id: number): Observable<ApiResponse<void>> {
    const body: NotificationGroupDeleteRequest = {
      NotificationGroupId: id,
    };
    return this.http.post<ApiResponse<void>>(this.endpoints.delete, body);
  }

  public searchNotificationGroup(
    query: Partial<NotificationGroupSearchRequest>
  ): Observable<ApiResponse<NotificationGroupResponse[]>> {
    const body: NotificationGroupSearchRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      PageNumber: 1,
      PageSize: 100,
      OrganizationIds: [],
      FacilityIds: [],
      NotificationGroupIds: [],
      NotificationTypes: [
        NotificationType.CLI_Battery_Level_Red,
        NotificationType.CLI_Battery_Level_Yellow,
        NotificationType.CLI_Connectivity_Red,
        NotificationType.CLI_Connectivity_Yellow,
        NotificationType.CLI_Security_Red,
        NotificationType.CLI_Security_Yellow,
        NotificationType.CLI_Utilization_Red,
        NotificationType.CLI_Utilization_Yellow,
      ],
      ...query,
    };
    query.EnterpriseId = this.enterpriseService.enterpriseId;
    return this.http.post<ApiResponse<NotificationGroupResponse[]>>(
      this.endpoints.search,
      body
    );
  }
}
