import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  CareLinkCartUserType,
  DeviceTypeIds,
  ExcelColHeaderData,
  NS1UserImportRecord,
  SettingCreateRequest,
  UserCreateRequest,
  UserImportConflictResponse,
  UserImportRecord,
  UserType,
  userImportAvaloColumns,
  userImportLegacyNSTrioColumns,
  userImportTrioColumns,
} from '@capsa/api';
import { FacilityApi } from '@capsa/api/facility';
import { BlobStorageService } from '@capsa/services/blob-storage/blob-storage.service';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from '@progress/kendo-angular-upload';
import { Constants } from 'app/common/constants';
import { Utils } from 'app/common/utils';
import { ErrorBlockMessage } from 'app/modules/core/error-block/error-block.component';
import { CapsaApiError } from 'app/modules/core/rxjs-operators';
import { UsernameValidator } from 'app/modules/users/user-helpers';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WorkBook, WorkSheet, read, utils, writeFile } from 'xlsx';
@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss'],
})
export class ImportUsersComponent implements OnInit, OnDestroy {
  public data: UserImportRecord[];
  private rejects: NS1UserImportRecord[];
  private nameSet: Set<string>;
  private arrayBuffer: ArrayBuffer;

  public uploadedFiles: Array<FileInfo> = [];
  public inputCount: number;
  public convertsCount: number;
  public failureCount: number;
  public conversionFailures = false;
  private conversionBook: WorkBook;
  public imports: UserCreateRequest[] = [];
  private readonly acceptedTrueVals = [
    1,
    true,
    '1',
    'y',
    'yes',
    't',
    'true',
    'a',
    'active',
  ];

  private readonly acceptedFalseVals = [
    0,
    false,
    '0',
    'n',
    'no',
    'f',
    'false',
    'inactive',
  ];

  public organizationId: number;
  public facilityId: number;
  public deviceTypeId: DeviceTypeIds;
  public DeviceTypeIds = DeviceTypeIds;

  public validatingData: boolean;
  public canEdit = false;
  public isConvertsDialogOpen = false;
  public isImportUsersDialogOpen = false;
  public outFileName: string;

  public get isSaveButtonDisabled() {
    return (
      !this.canEdit ||
      this.loaderService.isLoading ||
      !this.data ||
      (this.data && this.data.length === 0) ||
      !this.facilityId ||
      this.hasErrors
    );
  }

  public get pinSettingType(): CapsaSettingsTypeIds {
    switch (this.deviceTypeId) {
      case DeviceTypeIds.CareLink_2:
        return CapsaSettingsTypeIds.CLI_PinLength;
      case DeviceTypeIds.Avalo:
        return CapsaSettingsTypeIds.AVO_PinLength;
      default:
        throw new Error('Device type not yet supported for PIN Length setting');
    }
  }

  public validationErrors: ErrorBlockMessage[] = [];

  public get hasErrors(): boolean {
    return this.validationErrors.length > 0;
  }

  public get getTemplateTag(): string {
    switch (this.deviceTypeId) {
      case DeviceTypeIds.CareLink_2:
        return 'GET_TEMPLATE';
      case DeviceTypeIds.Avalo:
        return 'DOWNLOAD_AVALO_USER_TEMPLATE';
      default:
        return 'Unsupported Device';
    }
  }

  public get getFileSelectTag(): string {
    switch (this.deviceTypeId) {
      case DeviceTypeIds.CareLink_2:
        if (this.importLegacyUsers) {
          return 'FILE_SELECT_CONVERT_USERS';
        } else {
          return 'FILE_SELECT_IMPORT_TRIO_USERS';
        }
      case DeviceTypeIds.Avalo:
        return 'FILE_SELECT_IMPORT_AVALO_USERS';
      default:
        return 'COM_SELECT_FILE';
    }
  }

  private subs: Subscription = new Subscription();

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv', '.xlsx'],
  };
  public generatePINs: boolean;
  public importLegacyUsers = false;

  private deviceUserRoleId: number | undefined;
  private witnessAuthRoleId: number | undefined;
  private validPINCount = 0;
  private mustSpecifyPINsError: ErrorBlockMessage = {
    titleTag: 'MUST_SPECIFY_PINS',
  };
  private previousDuplicatePinError: ErrorBlockMessage | null;
  private facilityPinLength = 0;
  private sourceFileRows: any[];
  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Users_Add];

  constructor(
    private enterpriseService: EnterpriseService,
    public blobStorageService: BlobStorageService,
    private capsaRoleService: CapsaRoleService,
    private facilityApi: FacilityApi,
    private permissionService: PermissionService,
    private toasterService: ToasterService,
    public loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loadPermissions();
    this.nameSet = new Set();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.Manage_Users_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.canEdit = this.permissionService.has(editPerm);

          if (this.facilityId) {
            this.reloadRolesForFacility(this.facilityId);
            if (this.deviceTypeId) {
              this.refreshFacilityData();
            }
          }
        } else {
          this.canViewPage = false;
        }
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.canEdit = this.permissionService.has(editPerm);
  }

  private reloadRolesForFacility(facilityId: number) {
    const sub = this.capsaRoleService
      .getRoleIdsByFacilityId(facilityId)
      .subscribe(
        (roles) => {
          const deviceUserRole = roles.find((r) => r.Name === 'CLI_DeviceUser');
          const witnessAuthRole = roles.find(
            (r) => r.Name === 'CLI_DeviceSupervisor'
          );

          if (!deviceUserRole || !witnessAuthRole) {
            this.toasterService.showError('COM_UNKNOWN_API_ERROR');
            this.deviceUserRoleId = this.witnessAuthRoleId = null;
            return;
          }

          this.deviceUserRoleId = deviceUserRole.CapsaRoleId;
          this.witnessAuthRoleId = witnessAuthRole.CapsaRoleId;
        },
        (_: CapsaApiError) => {
          this.toasterService.showError('COM_UNKNOWN_API_ERROR');
        }
      );

    this.subs.add(sub);
  }

  public orgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.deviceUserRoleId = this.witnessAuthRoleId = null;
  }

  public facilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
  }

  public onDeviceTypeChanged(newValue: number | undefined) {
    this.deviceTypeId = newValue;
    if (this.facilityId && this.deviceTypeId) {
      this.refreshFacilityData();
    }

    if (this.deviceTypeId !== DeviceTypeIds.CareLink_2) {
      this.importLegacyUsers = false;
    }
    // Clear existing pending import
    this.resetAllDataInputs();
    this.data = [];
    this.validatingData = false;
  }

  public removedFileFromFileInput(e: RemoveEvent): void {
    this.resetAllDataInputs();
    setTimeout(() => {
      // this can lock the UI, so schedule it after we trigger the loading indicator
      this.data = undefined;
      this.validatingData = false;
    }, 100);
  }

  private resetAllDataInputs() {
    this.clearValidationErrors();
    this.validatingData = true;
    this.validPINCount = 0;
    this.previousDuplicatePinError = null;
    this.sourceFileRows = null;
    this.nameSet.clear();
  }

  public fileUploaded(e: SelectEvent): void {
    this.resetAllDataInputs();

    e.files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file.rawFile);
      fileReader.onload = () => {
        this.arrayBuffer = fileReader.result as ArrayBuffer;
        const data = new Uint8Array(this.arrayBuffer);
        const arr = new Array();
        for (let i = 0; i < data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = read(bstr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // validate and convert legacy users into current ns import sheet,
        // then export the conversion and stop futher operation.
        if (
          this.importLegacyUsers &&
          this.deviceTypeId === DeviceTypeIds.CareLink_2
        ) {
          if (this.fileColumnsMatchDeviceType(worksheet, true)) {
            this.convertLegacyNS1Users(worksheet, file);
            this.resetAllDataInputs();
            this.validatingData = false;
            this.data = [];
            this.uploadedFiles = [];
            return;
          } else {
            // Show message that import doesn't match NS1 export format
            this.toasterService.showError(
              'ERR_MSG_INVALID_USER_CONVERSION_TEMPLATE',
              {
                hideAfterMs: 5000,
              }
            );
            this.resetAllDataInputs();
            this.data = [];
            this.validatingData = false;
            return;
          }
        }

        if (!this.fileColumnsMatchDeviceType(worksheet, false)) {
          // Show message that template doesn't match device type
          this.toasterService.showError(
            'ERR_MSG_INVALID_USER_IMPORT_TEMPLATE',
            {
              hideAfterMs: 5000,
            }
          );
          this.resetAllDataInputs();
          this.data = [];
          this.validatingData = false;
          return;
        }
        const records = utils.sheet_to_json(worksheet, { raw: true });
        this.data = [];

        // We save the source file data for later.
        // If the facility ddl changes, we need to re-evaluate
        // any data issues with the selected import data.
        this.sourceFileRows = records;
        const validData = this.processInputFile(records);
        this.validatingData = false;
        this.data = validData;
      };
    });
  }

  // convert the users, export the sucess and failures
  private convertLegacyNS1Users(worksheet: WorkSheet, file: FileInfo) {
    const ns1Users: NS1UserImportRecord[] = utils.sheet_to_json(worksheet, {
      raw: true,
      defval: '',
    });
    this.inputCount = ns1Users.length;
    this.rejects = [];

    const colLabels = {
      LockoutOverride: userImportLegacyNSTrioColumns.LockoutOverride.cellText,
      ITServicesMenu: userImportLegacyNSTrioColumns.ITServicesMenu.cellText,
      DrawerAccess: userImportLegacyNSTrioColumns.DrawerAccess.cellText,
    };

    // tried to use filter here, but it didn't let anything through
    const validated: NS1UserImportRecord[] = [];
    ns1Users.forEach((user) => {
      if (
        user.UserName.length >= 3 &&
        this.isBooleanValid(user.Status) &&
        this.parseValidBool(user.Status) &&
        this.isBooleanValid(user[colLabels.LockoutOverride]) &&
        this.isBooleanValid(user[colLabels.ITServicesMenu]) &&
        this.isBooleanValid(user[colLabels.DrawerAccess])
      ) {
        validated.push(user);
      }
    });

    this.rejects = this.rejects.concat(
      ns1Users.filter((x) => !validated.includes(x))
    );

    // legacy export headers
    const rejectHeaders = [
      [
        userImportLegacyNSTrioColumns.Username.cellText,
        userImportLegacyNSTrioColumns.Department.cellText,
        userImportLegacyNSTrioColumns.Status.cellText,
        userImportLegacyNSTrioColumns.LockoutOverride.cellText,
        userImportLegacyNSTrioColumns.ITServicesMenu.cellText,
        userImportLegacyNSTrioColumns.DrawerAccess.cellText,
        userImportLegacyNSTrioColumns.Comments.cellText,
        userImportLegacyNSTrioColumns.PIN.cellText,
      ],
    ];

    // current nsight export headers
    const convertedHeaders = [
      [
        userImportTrioColumns.Username.cellText,
        userImportTrioColumns.FirstName.cellText,
        userImportTrioColumns.LastName.cellText,
        userImportTrioColumns.Departments.cellText,
        userImportTrioColumns.IsActive.cellText,
        userImportTrioColumns.LockoutOverride.cellText,
        userImportTrioColumns.ITServicesMenu.cellText,
        userImportTrioColumns.DrawerAccess.cellText,
        userImportTrioColumns.Comments.cellText,
        userImportTrioColumns.PIN.cellText,
        userImportTrioColumns.Witness.cellText,
      ],
    ];

    const converts = [];

    // original author (chris) is bad at regexp, so
    // all of these use global + ignore case flags, gi
    const parenthesesPattern = / ?\(.*?\)/gi;
    const nonAsciiPattern = /[^A-Za-z0-9 '-]/gi;
    const whtSpcCharsPattern = /\s+/g;
    // splitPattern intentionally not global
    const splitPattern = / (.*)/;
    const spcHyphenSpcPattern = /\s*-\s*/g;
    const noSpacesPattern = /[^\w]/gi;

    validated.forEach((user) => {
      let name = user.UserName;
      // strip out maiden names in parentheses
      name = name.replace(parenthesesPattern, '');
      // strip out everything that is non-ascii printable
      name = name.replace(nonAsciiPattern, '');
      // strip leading, trailing, and consecutive whitespace chars
      name = name.replace(whtSpcCharsPattern, ' ').trim();

      // split into 2 substrings on first whitespace
      // limit-2 param prevents array with empty third element
      const splitted = name.split(splitPattern, 2);
      // historically, this function was ported from a tool
      // where, based on best guess/fit,
      // firstName = splitted[1] and lastName = splitted[0]
      // and we make up a first name if one wasn't parsed
      const firstName =
        splitted.length === 2 ? splitted[1].trim() : 'Given Name';
      const lastName = splitted[0].trim();

      // remove spaces adjacent to hyphens
      name = name.replace(spcHyphenSpcPattern, '-');
      // replace spaces with underscores
      name = name.replace(noSpacesPattern, '_');

      // account for, and increment, any username collisions
      // specific to the sheet being imported
      let i = 1;
      let userName = name;
      if (this.nameSet.has(userName)) {
        userName = userName + '_' + i;
        while (this.nameSet.has(userName)) {
          i++;
          userName = name + '_' + i;
        }
        this.nameSet.add(userName);
      }

      const departments = user.Department;
      const isActive = this.parseValidBool(user.Status);
      const lockoutOverride = this.parseValidBool(
        user[colLabels.LockoutOverride]
      );
      const itServicesMenu = this.parseValidBool(
        user[colLabels.ITServicesMenu]
      );
      const drawerAccess = this.parseValidBool(user[colLabels.DrawerAccess]);
      const comments = user.Comments;
      const pin = user.Pin;
      const witness = false;

      const parsedUser = [
        userName,
        firstName,
        lastName,
        departments,
        isActive,
        lockoutOverride,
        itServicesMenu,
        drawerAccess,
        comments,
        pin,
        witness,
      ];
      converts.push(parsedUser);
    });

    this.conversionFailures = this.rejects.length > 0;
    this.outFileName =
      file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
    this.conversionBook = utils.book_new();

    const convertSheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(convertSheet, convertedHeaders);
    utils.sheet_add_json(convertSheet, converts, {
      origin: 'A2',
      skipHeader: true,
    });

    utils.book_append_sheet(
      this.conversionBook,
      convertSheet,
      'Converted Users'
    );

    this.convertsCount = converts.length;

    if (this.conversionFailures) {
      this.failureCount = this.rejects.length;
      const rejectSheet = utils.json_to_sheet([]);
      utils.sheet_add_aoa(rejectSheet, rejectHeaders);
      utils.sheet_add_json(rejectSheet, this.rejects, {
        origin: 'A2',
        skipHeader: true,
      });
      utils.book_append_sheet(
        this.conversionBook,
        rejectSheet,
        'Invalid Users'
      );
    }

    this.outFileName = this.outFileName + '-conversions.xlsx';
    this.isConvertsDialogOpen = true;
  }

  public onBtnExportUserConversion() {
    if (
      this.outFileName
        .substring(this.outFileName.lastIndexOf('.') + 1)
        .toLocaleLowerCase() !== 'xlsx'
    ) {
      this.outFileName = this.outFileName + '.xlsx';
    }
    writeFile(this.conversionBook, this.outFileName, {
      bookType: 'xlsx',
    });
    this.closeConvertsDialog();
  }

  private fileColumnsMatchDeviceType(
    worksheet: WorkSheet,
    legacyUsers = false
  ): boolean {
    /* eslint-disable guard-for-in */
    switch (this.deviceTypeId) {
      case DeviceTypeIds.CareLink_2:
        const colProps = legacyUsers
          ? userImportLegacyNSTrioColumns
          : userImportTrioColumns;
        for (const prop in colProps) {
          const colData = colProps[prop] as ExcelColHeaderData;
          if (
            !worksheet.hasOwnProperty(colData.cellCoordinates) ||
            worksheet[colData.cellCoordinates].v !== colData.cellText
          ) {
            return false;
          }
        }
        return true;
      case DeviceTypeIds.Avalo:
        for (const prop in userImportAvaloColumns) {
          const colData = userImportAvaloColumns[prop] as ExcelColHeaderData;
          if (
            !worksheet.hasOwnProperty(colData.cellCoordinates) ||
            worksheet[colData.cellCoordinates].v !== colData.cellText
          ) {
            return false;
          }
        }
        return true;
      default:
        throw new Error('Unsupported device type');
    }

    /* eslint-enable */
  }

  private processInputFile(records: unknown[]): UserImportRecord[] {
    const validUsernames = new Set<string>();
    const validPINs = new Set<string>();
    const validProxCardIds = new Set<string>();
    const improperUsernames = new Set<string>();
    const usernamesNotLongEnough = new Set<string>();
    const conflictingUsernames = new Set<string>();
    const conflictingPINs = new Set<string>();
    const invalidSecondaryPINs = new Set<string>();
    const conflictingProxCardIds = new Set<string>();
    const invalidActiveStatusBools = new Set<string>();
    const invalidLockoutOverrideBools = new Set<string>();
    const invalidItServicesMenuBools = new Set<string>();
    const invalidDrawerAccessBools = new Set<string>();
    const invalidNarc1DrawerAccessBools = new Set<string>();
    const invalidNarc2DrawerAccessBools = new Set<string>();
    const invalidWitnessBools = new Set<string>();

    const validData: UserImportRecord[] = [];

    // We use this as a way to easily validate if a username meets the req's.
    const usernameCtrl = new UntypedFormControl(null, UsernameValidator);

    for (const row of records) {
      const importUserRecord: UserImportRecord = {
        Username: null,
        FirstName: null,
        LastName: null,
        Departments: null,
        IsActive: null,
        LockoutOverride: null,
        ITServicesMenu: null,
        DrawerAccess: null,
        Narc1DrawerAccess: null,
        Narc2DrawerAccess: null,
        Comments: null,
        PIN: null,
        SecondaryPIN: null,
        Witness: null,
        ProxId: null,
      };

      let pin;
      let secondaryPin;
      let proxCardId;
      let isActiveRaw;
      let isLockoutOverrideRaw;
      let isItServicesMenuRaw;
      let isDrawerAccessRaw;
      let isNarc1DrawerAccessRaw;
      let isNarc2DrawerAccessRaw;
      let isWitnessRaw;

      switch (this.deviceTypeId) {
        case DeviceTypeIds.CareLink_2:
          importUserRecord.Username =
            row[userImportTrioColumns.Username.cellText];

          importUserRecord.FirstName =
            row[userImportTrioColumns.FirstName.cellText];

          importUserRecord.LastName =
            row[userImportTrioColumns.LastName.cellText];

          importUserRecord.Departments =
            row[userImportTrioColumns.Departments.cellText];

          isActiveRaw = row[userImportTrioColumns.IsActive.cellText];

          pin = row[userImportTrioColumns.PIN.cellText];

          isLockoutOverrideRaw =
            row[userImportTrioColumns.LockoutOverride.cellText];

          isItServicesMenuRaw =
            row[userImportTrioColumns.ITServicesMenu.cellText];

          isDrawerAccessRaw = row[userImportTrioColumns.DrawerAccess.cellText];

          isWitnessRaw = row[userImportTrioColumns.Witness.cellText];

          importUserRecord.Comments =
            row[userImportTrioColumns.Comments.cellText];

          if (!this.isBooleanValid(isLockoutOverrideRaw)) {
            invalidLockoutOverrideBools.add(isLockoutOverrideRaw);
            importUserRecord.LockoutOverride = false;
          } else {
            importUserRecord.LockoutOverride =
              this.parseValidBool(isLockoutOverrideRaw);
          }

          if (!this.isBooleanValid(isItServicesMenuRaw)) {
            invalidItServicesMenuBools.add(isItServicesMenuRaw);
            importUserRecord.ITServicesMenu = false;
          } else {
            importUserRecord.ITServicesMenu =
              this.parseValidBool(isItServicesMenuRaw);
          }

          if (!this.isBooleanValid(isWitnessRaw)) {
            invalidWitnessBools.add(isWitnessRaw);
            importUserRecord.Witness = false;
          } else {
            importUserRecord.Witness = this.parseValidBool(isWitnessRaw);
          }

          if (!this.isBooleanValid(isDrawerAccessRaw)) {
            invalidDrawerAccessBools.add(isDrawerAccessRaw);
            importUserRecord.DrawerAccess = false;
          } else {
            importUserRecord.DrawerAccess =
              this.parseValidBool(isDrawerAccessRaw);
          }
          break;
        case DeviceTypeIds.Avalo:
          importUserRecord.Username =
            row[userImportAvaloColumns.Username.cellText];

          importUserRecord.FirstName =
            row[userImportTrioColumns.FirstName.cellText];

          importUserRecord.LastName =
            row[userImportTrioColumns.LastName.cellText];

          isActiveRaw = row[userImportAvaloColumns.IsActive.cellText];

          isNarc1DrawerAccessRaw =
            row[userImportAvaloColumns.Narc1DrawerAccess.cellText];

          isNarc2DrawerAccessRaw =
            row[userImportAvaloColumns.Narc2DrawerAccess.cellText];

          pin = row[userImportAvaloColumns.PIN.cellText];

          secondaryPin = row[userImportAvaloColumns.SecondaryPin.cellText];

          proxCardId = row[userImportAvaloColumns.ProxCardId.cellText];

          importUserRecord.SecondaryPIN = this.getSecondaryPinString(
            secondaryPin,
            Constants.avoSecondaryPinLength,
            invalidSecondaryPINs
          );

          if (!this.isBooleanValid(isNarc1DrawerAccessRaw)) {
            invalidNarc1DrawerAccessBools.add(isNarc1DrawerAccessRaw);
            importUserRecord.Narc1DrawerAccess = false;
          } else {
            importUserRecord.Narc1DrawerAccess = this.parseValidBool(
              isNarc1DrawerAccessRaw
            );
          }

          if (!this.isBooleanValid(isNarc2DrawerAccessRaw)) {
            invalidNarc2DrawerAccessBools.add(isNarc2DrawerAccessRaw);
            importUserRecord.Narc2DrawerAccess = false;
          } else {
            importUserRecord.Narc2DrawerAccess = this.parseValidBool(
              isNarc2DrawerAccessRaw
            );
          }

          if (
            (typeof proxCardId === 'string' && proxCardId.length > 0) ||
            !isNaN(proxCardId)
          ) {
            proxCardId = proxCardId.toString();
            if (!validProxCardIds.has(proxCardId)) {
              validProxCardIds.add(proxCardId);
            } else {
              conflictingProxCardIds.add(proxCardId);
            }
          } else {
            proxCardId = null;
          }
          importUserRecord.ProxId = proxCardId;

          break;
        default:
          throw new Error('Unsupported device type');
      }

      if (!this.isBooleanValid(isActiveRaw)) {
        invalidActiveStatusBools.add(isActiveRaw);
        importUserRecord.IsActive = false;
      } else {
        importUserRecord.IsActive = this.parseValidBool(isActiveRaw);
      }

      usernameCtrl.setValue(importUserRecord.Username);

      if (importUserRecord.Username.length < 3) {
        // username length is too short
        usernamesNotLongEnough.add(importUserRecord.Username);
      } else if (usernameCtrl.invalid) {
        // means that the username format is wrong.
        improperUsernames.add(importUserRecord.Username);
      } else if (validUsernames.has(importUserRecord.Username)) {
        // username already taken
        conflictingUsernames.add(importUserRecord.Username);
      } else {
        // valid username
        validUsernames.add(importUserRecord.Username);
      }

      validUsernames.add(importUserRecord.Username);

      importUserRecord.PIN = this.placePinToImportIntoAppropriateBucket(
        pin,
        validPINs,
        conflictingPINs,
        this.facilityPinLength
      );

      validData.push(importUserRecord);
    }

    this.validPINCount = validPINs.size;

    this.setValidationErrors(
      [...conflictingPINs],
      [...invalidSecondaryPINs],
      [...conflictingUsernames],
      [...improperUsernames],
      [...usernamesNotLongEnough],
      [...invalidActiveStatusBools],
      [...invalidLockoutOverrideBools],
      [...invalidItServicesMenuBools],
      [...invalidDrawerAccessBools],
      [...invalidNarc1DrawerAccessBools],
      [...invalidNarc2DrawerAccessBools],
      [...invalidWitnessBools],
      [...conflictingProxCardIds]
    );

    this.checkMustSpecifyPINsError(records.length, this.validPINCount);

    return validData;
  }

  private getSecondaryPinString(
    pinToImport: string,
    pinLength: number,
    invalidSecondaryPins: Set<string>,
    pinRequired: boolean = false
  ) {
    // PIN can either be a number formatted value, OR a string (dumb excel), so we just
    // do a .toString() to normalize it into a string format for easier processing.
    // It also could be a falsy value, so just make sure to always convert it safely.
    let stringPin = (pinToImport || '').toString();

    if (!pinRequired && stringPin.length === 0) {
      return '';
    }

    if (stringPin && stringPin.length < pinLength) {
      // Pad leading zeroes, Excel likes to remove them. NS-1327
      stringPin = this.padPIN(stringPin, pinLength);
    }

    if (stringPin.length !== pinLength || !this.isNumeric(stringPin)) {
      invalidSecondaryPins.add(stringPin);
    }

    return stringPin;
  }

  // testing against isNaN(parseInt(string))
  // only catches leading alphas or strings
  // containing spaces
  private isNumeric(value): boolean {
    return /^\d+$/.test(value);
  }

  private placePinToImportIntoAppropriateBucket(
    pinToImport: string,
    validPINs: Set<string>,
    conflictingPINs: Set<string>,
    pinLength: number
  ) {
    // PIN can either be a number formatted value, OR a string (dumb excel), so we just
    // do a .toString() to normalize it into a string format for easier processing.
    // It also could be a falsy value, so just make sure to always convert it safely.
    let stringPin = (pinToImport || '').toString();

    if (stringPin) {
      // Pad leading zeroes, Excel likes to remove them. NS-1327
      if (stringPin.length < pinLength) {
        stringPin = this.padPIN(stringPin, pinLength);
      }
      // invalid PIN code format or it already exists
      if (
        validPINs.has(stringPin) ||
        stringPin.length !== pinLength ||
        !this.isNumeric(stringPin)
      ) {
        conflictingPINs.add(stringPin);
      } else {
        validPINs.add(stringPin);
      }
    }

    return stringPin;
  }

  public onGeneratePINsClicked() {
    this.checkMustSpecifyPINsError(
      (this.data || []).length,
      this.validPINCount
    );
  }

  public onInportLegacyUsersClicked() {
    this.resetAllDataInputs();
    this.data = [];
    this.validatingData = false;
  }

  public onGetTemplate() {
    let filename: string;
    switch (this.deviceTypeId) {
      case DeviceTypeIds.CareLink_2:
        filename = 'Trio_Cart_User_Import_Template.xlsx';
        break;
      case DeviceTypeIds.Avalo:
        filename = 'Avalo_Cart_User_Import_Template_v2.xlsx';
        break;
      default:
        throw new Error('Unsupported device type for cart user import');
    }
    const taskId = 'getTemplate';
    this.loaderService.start(taskId);
    const sub = this.blobStorageService.getUrl(filename).subscribe(
      (url) => {
        this.loaderService.stop(taskId);
        window.open(url);
      },
      (error) => {
        this.loaderService.stop(taskId);
        this.toasterService.showError('GET_TEMPLATE_FAIL');
      }
    );
    this.subs.add(sub);
  }

  private checkMustSpecifyPINsError(
    expectedPinCount: number,
    actualPinCount: number
  ) {
    if (this.generatePINs) {
      this.clearMustSpecifyPINsError();

      const previousDuplicatePinErrorIdx = this.validationErrors.findIndex(
        (e) => e.titleTag === 'PIN_CODES_ALREADY_USED'
      );
      if (previousDuplicatePinErrorIdx > -1) {
        // we are generating PINs, so remove this error too.
        this.previousDuplicatePinError =
          this.validationErrors[previousDuplicatePinErrorIdx];
        this.validationErrors.splice(previousDuplicatePinErrorIdx, 1);
      }
    } else {
      if (this.previousDuplicatePinError) {
        this.validationErrors.push(this.previousDuplicatePinError);
      }
    }

    if (actualPinCount === expectedPinCount || this.generatePINs) {
      this.clearMustSpecifyPINsError();
    } else {
      this.setMustSpecifyPINsError();
    }
  }

  private setMustSpecifyPINsError() {
    const idx = this.validationErrors.findIndex(
      (e) => e.titleTag === this.mustSpecifyPINsError.titleTag
    );
    if (idx > -1) {
      return;
    }
    this.validationErrors.push(this.mustSpecifyPINsError);
  }

  private clearMustSpecifyPINsError() {
    const idx = this.validationErrors.findIndex(
      (e) => e.titleTag === this.mustSpecifyPINsError.titleTag
    );

    if (idx > -1) {
      this.validationErrors.splice(idx, 1);
    }
  }

  private clearValidationErrors() {
    this.previousDuplicatePinError = null;
    this.validationErrors.length = 0;
  }

  public onImport() {
    if (!this.data) {
      return;
    }

    this.imports = [];
    this.clearValidationErrors();

    this.data.forEach((record) => {
      const assignedRoles = [this.deviceUserRoleId];
      if (record.Witness) {
        assignedRoles.push(this.witnessAuthRoleId);
      }

      // The API will set a new user's "IsEmailConfirmed" to true by default (for backwards/Nexsys compatibility)
      // Users that have "IsEmailConfirmed = false" will not be able to log in.
      // Since we're creating cart users, who will not be using the GetAuth endpoint
      // we set "IsEmailConfirmed = false" to avoid confusion (potential bugs) when an
      // N-Sight role is added to those users, since PINning in is NOT restricted based
      // on a users "IsEmailConfirmed" field
      const needToConfirmEmail = true;
      const settings: SettingCreateRequest[] = [];

      switch (this.deviceTypeId) {
        case DeviceTypeIds.CareLink_2:
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.CareLink_LockoutOverrideEnabled,
              record.LockoutOverride,
              DeviceTypeIds.CareLink_2
            )
          );
          settings.push(
            this.createCartUserTypeSetting(
              CapsaSettingsTypeIds.CareLink_ITServicesMenuEnable,
              this.convertITServicesMenu(record.ITServicesMenu),
              DeviceTypeIds.CareLink_2
            )
          );
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.CareLink_DrawerAccessEnable,
              record.DrawerAccess,
              DeviceTypeIds.CareLink_2
            )
          );
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.CLI_SecondaryDrawerAccessEnabled,
              record.DrawerAccess,
              DeviceTypeIds.CareLink_2
            )
          );
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.CLI_IsCartUserActive,
              record.IsActive,
              DeviceTypeIds.CareLink_2
            )
          );
          break;
        case DeviceTypeIds.Avalo:
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.CLI_IsCartUserActive,
              record.IsActive,
              DeviceTypeIds.Avalo
            )
          );
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.AVO_NarcOneDrawerAccess,
              record.Narc1DrawerAccess,
              DeviceTypeIds.Avalo
            )
          );
          settings.push(
            this.createBooleanSetting(
              CapsaSettingsTypeIds.AVO_NarcTwoDrawerAccess,
              record.Narc2DrawerAccess,
              DeviceTypeIds.Avalo
            )
          );
          settings.push(
            this.createStringSetting(
              CapsaSettingsTypeIds.AVO_SecondaryPinCode,
              record.SecondaryPIN,
              DeviceTypeIds.Avalo
            )
          );
          break;
        default:
          throw new Error('Unsupported device type');
      }

      this.imports.push({
        Username: record.Username,
        FirstName: record.FirstName,
        LastName: record.LastName,
        DepartmentIds: [],
        IsActive: record.IsActive,
        Email: null,
        IsEmailConfirmed: !needToConfirmEmail,
        SettingOverrides: settings,
        Comment: record.Comments,
        PIN: record.PIN,
        ProxId: record.ProxId,
        EnterpriseId: this.enterpriseService.enterpriseId,
        FacilityIds: [this.facilityId],
        OrganizationIds: [this.organizationId],
        RoleIds: assignedRoles,
        JoinDateUtc: new Date().toUTCString(),
        UserType: UserType.Pharmacy,
        UserTimeZone: 'UTC',
        GeneratePinCodes: false,
      });
    });
    this.isImportUsersDialogOpen = true;
  }

  private setValidationErrors(
    invalidPins: string[],
    invalidSecondaryPins: string[],
    invalidUsernames: string[],
    improperUsernames: string[],
    usernamesNotLongEnough: string[],
    invalidActiveStatusBools: string[],
    invalidLockoutOverrideBools: string[],
    invalidItServicesMenuBools: string[],
    invalidDrawerAccessBools: string[],
    invalidNarc1DrawerAccessBools: string[],
    invalidNarc2DrawerAccessBools: string[],
    invalidWitnessBools: string[],
    conflictingProxCardIds: string[]
  ) {
    const errors: ErrorBlockMessage[] = [];

    if (invalidPins.length > 0) {
      errors.push({
        titleTag: 'PIN_CODES_ALREADY_USED',
        subtitle: invalidPins.join(', '),
      });
    }

    if (invalidSecondaryPins.length > 0) {
      errors.push({
        titleTag: 'SECONDARY_PIN_CODES_INVALID',
        subtitle: invalidSecondaryPins.join(', '),
      });
    }

    if (invalidUsernames.length > 0) {
      errors.push({
        titleTag: 'USERNAMES_ALREADY_USED',
        subtitle: invalidUsernames.join(', '),
      });
    }

    if (improperUsernames.length > 0) {
      errors.push({
        titleTag: 'USERNAME_PATTERN_INVALID',
        subtitle: improperUsernames.join(', '),
      });
    }

    if (usernamesNotLongEnough.length > 0) {
      errors.push({
        titleTag: 'USERNAME_MIN_LENGTH_3',
        subtitle: usernamesNotLongEnough.join(', '),
      });
    }

    if (this.validationErrors.length > 0) {
      const previousError = this.validationErrors.find(
        (e) => e.titleTag === 'PIN_CODES_ALREADY_USED'
      );
      this.previousDuplicatePinError = previousError;
    }

    if (invalidActiveStatusBools.length > 0) {
      errors.push({
        titleTag: 'USER_ACTIVE_STATUS_MUST_BE_BOOLEAN',
        subtitle: invalidActiveStatusBools.join(', '),
      });
    }

    if (invalidLockoutOverrideBools.length > 0) {
      errors.push({
        titleTag: 'LOCKOUT_OVERRIDE_MUST_BE_BOOLEAN',
        subtitle: invalidLockoutOverrideBools.join(', '),
      });
    }

    if (invalidItServicesMenuBools.length > 0) {
      errors.push({
        titleTag: 'IT_SERVICES_MENU_MUST_BE_BOOLEAN',
        subtitle: invalidItServicesMenuBools.join(', '),
      });
    }

    if (invalidDrawerAccessBools.length > 0) {
      errors.push({
        titleTag: 'DRAWER_ACCESS_MUST_BE_BOOLEAN',
        subtitle: invalidDrawerAccessBools.join(', '),
      });
    }

    if (invalidNarc1DrawerAccessBools.length > 0) {
      errors.push({
        titleTag: 'NARC_1_DRAWER_ACCESS_MUST_BE_BOOLEAN',
        subtitle: invalidNarc1DrawerAccessBools.join(', '),
      });
    }

    if (invalidNarc2DrawerAccessBools.length > 0) {
      errors.push({
        titleTag: 'NARC_2_DRAWER_ACCESS_MUST_BE_BOOLEAN',
        subtitle: invalidNarc2DrawerAccessBools.join(', '),
      });
    }

    if (invalidWitnessBools.length > 0) {
      errors.push({
        titleTag: 'WITNESS_MUST_BE_BOOLEAN',
        subtitle: invalidWitnessBools.join(', '),
      });
    }

    if (conflictingProxCardIds.length > 0) {
      errors.push({
        titleTag: 'PROX_CARD_IDS_MUST_BE_UNIQUE',
        subtitle: conflictingProxCardIds.join(', '),
      });
    }

    this.validationErrors = errors;
  }

  private createBooleanSetting(
    setting: CapsaSettingsTypeIds,
    value: boolean,
    deviceTypeId: DeviceTypeIds
  ): SettingCreateRequest {
    return {
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationId: this.organizationId,
      FacilityId: this.facilityId,
      CapsaUserId: '',
      SettingType: setting,
      SettingValue: value ? '1' : '0',
      IsEncrypted: false,
      AlternateKey: '',
      DeviceTypeId: deviceTypeId,
    };
  }

  private createCartUserTypeSetting(
    setting: CapsaSettingsTypeIds,
    value: CareLinkCartUserType,
    deviceTypeId: DeviceTypeIds
  ): SettingCreateRequest {
    return this.createStringSetting(setting, value.toString(), deviceTypeId);
  }

  private createStringSetting(
    setting: CapsaSettingsTypeIds,
    value: string,
    deviceTypeId: DeviceTypeIds
  ): SettingCreateRequest {
    return {
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationId: this.organizationId,
      FacilityId: this.facilityId,
      CapsaUserId: '',
      SettingType: setting,
      SettingValue: value,
      IsEncrypted: false,
      AlternateKey: '',
      DeviceTypeId: deviceTypeId,
    };
  }

  private refreshFacilityData() {
    const taskId = 'refreshFacilityData';
    this.loaderService.start(taskId);

    this.subs.add(
      this.facilityApi
        .getFacility(
          this.facilityId,
          Utils.getProductLine(this.deviceTypeId).toString()
        )
        .pipe(finalize(() => this.loaderService.stop(taskId)))
        .subscribe(
          (resp) => {
            const pinLengthSetting = resp.Result.Settings.find((s) => {
              return s.SettingType === this.pinSettingType;
            });

            if (pinLengthSetting) {
              const parsedLength = Number.parseInt(
                pinLengthSetting.SettingValue,
                10
              );
              if (this.isNumeric(pinLengthSetting.SettingValue)) {
                this.facilityPinLength = parsedLength;
              } else {
                throw new Error(
                  'Cannot parse "PIN Length" setting: ' + parsedLength
                );
              }
            }

            // changed facility, so clear validation errors,
            this.clearValidationErrors();

            if (this.sourceFileRows) {
              // had a file selected already, so re-validate data.
              // Note we don't reassign this.data, since that is already populated
              // in the grid. We merely use this method to detect any errors to display
              // to the user.
              this.processInputFile(this.sourceFileRows);
            }
          },
          (error) => {
            this.toasterService.showError('COM_UNKNOWN_API_ERROR');
          }
        )
    );
  }

  /**
   * Takes in a VALIDATED boolean string and returns true/false
   * @param input a string that's already been confirmed valid
   */
  private parseValidBool(input: string): boolean {
    if (typeof input === 'string') {
      input = input.trim().toLowerCase();
    }

    if (this.acceptedFalseVals.some((x) => x === input)) {
      return false;
    } else if (this.acceptedTrueVals.some((x) => x === input)) {
      return true;
    } else {
      throw new Error(
        'non validated input detected. Developer, please make sure boolean string is valid before passing into this method'
      );
    }
  }

  private isBooleanValid(rawBool: string | number): boolean {
    // We do this check since null and undefined can be accepteble for "false values"
    if (typeof rawBool === 'string') {
      rawBool = rawBool.trim().toLowerCase();
    }

    return [...this.acceptedFalseVals, ...this.acceptedTrueVals].some(
      (x) => x === rawBool
    );
  }

  private padPIN(pin: string, padlen: number): string {
    const pad = new Array(1 + padlen).join('0');
    return (pad + pin).slice(-pad.length);
  }

  private convertITServicesMenu(input: boolean): CareLinkCartUserType {
    if (input) {
      return CareLinkCartUserType.ITAdmin;
    }
    return CareLinkCartUserType.Nurse;
  }

  public closeConvertsDialog(): void {
    this.isConvertsDialogOpen = false;
    this.importLegacyUsers = false;
  }

  public onImportUsersDialogClose(
    dataConflicts: UserImportConflictResponse
  ): void {
    if (dataConflicts && dataConflicts.HasConflicts) {
      this.setValidationErrors(
        dataConflicts.ConflictingPINs,
        dataConflicts.ConflictingSecondaryPINs,
        dataConflicts.ConflictingUsernames,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
      );
    }
    this.isImportUsersDialogOpen = false;
  }
}
