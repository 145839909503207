import { Component, Input } from '@angular/core';
import { CapsaSettingsTypeIds, DeviceTypeIds } from '@capsa/api';
import { DataDrivenCheckBoxConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';

@Component({
  selector: 'app-carelink-user-settings',
  templateUrl: './carelink-user-settings.component.html',
  styleUrls: ['./carelink-user-settings.component.scss'],
})
export class CarelinkUserSettingsComponent {
  @Input()
  private deviceTypeId: DeviceTypeIds;

  public get itServicesMenuEnabled() {
    return this.editUserService.getSettingByDeviceType(
      this.deviceTypeId,
      CapsaSettingsTypeIds.CareLink_ITServicesMenuEnable
    );
  }

  public get primaryDrawerAccessEnabled() {
    return this.editUserService.getSettingByDeviceType(
      this.deviceTypeId,
      CapsaSettingsTypeIds.CareLink_DrawerAccessEnable
    );
  }

  public get lockoutOverrideEnabled() {
    return this.editUserService.getSettingByDeviceType(
      this.deviceTypeId,
      CapsaSettingsTypeIds.CareLink_LockoutOverrideEnabled
    );
  }

  public get isActive() {
    return this.editUserService.getSettingByDeviceType(
      this.deviceTypeId,
      CapsaSettingsTypeIds.CLI_IsCartUserActive
    );
  }

  public get pinCode() {
    return this.editUserService.getSettingByDeviceType(
      this.deviceTypeId,
      CapsaSettingsTypeIds.CLI_UserPinCode
    );
  }

  public itServicesConfig: DataDrivenCheckBoxConfig = {
    trueValue: '2',
    falseValue: '1',
  };

  constructor(private editUserService: EditUserService) {}
}
