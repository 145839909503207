import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnalyticQueryType, DashboardWidgetResponse } from '@capsa/api';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
})
export class DashboardWidgetComponent implements OnInit, OnDestroy {
  @Input()
  public iconName: string;

  @Input()
  public titleTag: string;

  @Input()
  public subtitleTag: string;

  @Input()
  private statData$: Observable<DashboardWidgetResponse>;

  @Input()
  public tooltipTag: string;

  public loading = true;

  public statNumerator: number;

  public statDenominator: number;

  public queryType: AnalyticQueryType;

  /**
   * if positive number - displayed as ([up triangle] X more)
   * if zero - displayed as ([cicle] same)
   * if negative number - displayed as ([down triangle] X less)
   */
  private statTrend: number;

  get trendNumber(): number {
    return Math.abs(this.statTrend);
  }

  get isTrendFlat(): boolean {
    return this.statTrend === 0;
  }

  get isTrendUp(): boolean {
    return this.statTrend > 0;
  }

  public data: any[];
  private subs = new Subscription();

  constructor() {}

  ngOnInit() {
    this.clearWidgetStats();

    const sub = this.statData$.subscribe((x) => {
      if (!x) {
        // if falsy stat data, clear widget data and set "loading"
        this.clearWidgetStats();
        return;
      }
      this.statNumerator = x.Numerator;
      this.statDenominator = x.Denominator;
      this.statTrend = x.Trend;
      this.queryType = x.QueryType;
      this.setDonutChartData();
      this.loading = false;
    });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private clearWidgetStats() {
    this.data = [
      { kind: '', share: 0 },
      { kind: '', share: 1 },
    ];
    this.statNumerator = undefined;
    this.statDenominator = undefined;
    this.statTrend = 0;
    this.loading = true;
  }

  private setDonutChartData() {
    // in case facility has no carts, ensure we dont' end up dividing by 0
    const denominator = this.statDenominator ? this.statDenominator : 1;
    const subjectCartFraction = this.statNumerator / denominator;
    const allRemainingCartsFraction = 1 - this.statNumerator / denominator;

    this.data = [
      {
        kind: '',
        share: subjectCartFraction,
      },
      {
        kind: '',
        share: allRemainingCartsFraction,
      },
    ];
  }
}
