import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CapsaRoleResponseDto,
  NameWithLongIdApiModel,
  UserType,
} from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DropDownItem, EnumTypes } from '@capsa/dropdowns/enum';
import { OrganizationMultiSelectDataSource } from '@capsa/dropdowns/organization-multi-select/organization-multi-select-data-source';
import { AuthService } from '@capsa/services/auth/auth.service';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { UserService } from '@capsa/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NsightCustomValidators } from 'app/common/nsightCustomValidators';
import { List as ImmutableList, Record } from 'immutable';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Constants } from '../../../common/constants';
import * as CustomValidators from '../capsaCustomValidators';
import { UserHelpers, UsernameValidator } from '../user-helpers';

export interface FacilityAndRoles {
  facility: NameWithLongIdApiModel;
  roles: CapsaRoleResponseDto[];
}

/**
 * Holds a copy of the facility/role during editing. If edit is cancelled, this
 * record is used to restore the pre-editing state
 */
const ImmutableFacilityAndRolesGridRow = Record<FacilityAndRoles>({
  facility: null,
  roles: [],
});

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public userTypeEnum: EnumTypes = EnumTypes.UserType;
  public userTypes: Array<DropDownItem>;

  public pristineFacilityRoleDataItem: Record<FacilityAndRoles>;
  public editingRowIndex: number | undefined;
  public canEdit = false;

  @ViewChild('facilitiesRolesGrid', { static: false })
  public facilitiesRolesGrid: GridComponent;

  private pristineLoggedInUserFacs: ImmutableList<NameWithLongIdApiModel>;
  private pristineLoggedInUserOrgs: ImmutableList<NameWithLongIdApiModel>;

  private loadingFacsBehSub = new BehaviorSubject<boolean>(false);
  public loadingFacs$ = this.loadingFacsBehSub.asObservable();

  private loadingRolesBehSub = new BehaviorSubject<boolean>(false);
  public loadingRoles$ = this.loadingRolesBehSub.asObservable();

  private facilitiesAndRolesBehSub = new BehaviorSubject<FacilityAndRoles[]>(
    []
  );
  public facilitiesAndRoles$ = this.facilitiesAndRolesBehSub.asObservable();

  private ddlFacilitiesBehSub = new BehaviorSubject<NameWithLongIdApiModel[]>(
    []
  );
  public ddlFacilities$ = this.ddlFacilitiesBehSub.asObservable();

  private multiSelectRolesBehSub = new BehaviorSubject<CapsaRoleResponseDto[]>(
    []
  );
  public multiSelectRoles$ = this.multiSelectRolesBehSub.asObservable();

  public roleMultiSelectValue: any;

  public gridEditForm: UntypedFormGroup;

  public usernameCtrl: UntypedFormControl;
  public emailCtrl: UntypedFormControl;
  public firstNameCtrl: UntypedFormControl;
  public lastNameCtrl: UntypedFormControl;
  public form: UntypedFormGroup;
  private organizationIdsCtrl: UntypedFormControl;

  private orgFacTree: Map<number, Set<number>>;
  private facilitiesWithAddUserPerm = new Set<number>();

  private subs = new Subscription();

  constructor(
    private userService: UserService,
    private enterpriseService: EnterpriseService,
    private router: Router,
    private userEofCacheService: UserEofCacheService,
    private capsaRoleService: CapsaRoleService,
    public organizationsDataSource: OrganizationMultiSelectDataSource,
    private translateService: TranslateService,
    private permissionService: PermissionService,
    public loaderService: LoaderService,
    private toasterService: ToasterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadPermissions();

    this.subs.add(
      this.userEofCacheService.initialized$.subscribe((eofCache) => {
        if (!eofCache) {
          return;
        }

        this.pristineLoggedInUserFacs = ImmutableList(eofCache.facilities);
        this.pristineLoggedInUserOrgs = ImmutableList(eofCache.organizations);

        this.subs.add(
          this.organizationsDataSource.load().subscribe((ddlOrgs) => {
            if (this.authService.currentUser.EnterpriseAdmin) {
              return;
            }

            this.orgFacTree =
              this.permissionService.getCurrentUserOrgFacTreeForSinglePerm(
                Permissions.CLI_MenuAccess_Users_Add
              );

            this.orgFacTree.forEach((org) => {
              org.forEach((fId) => {
                this.facilitiesWithAddUserPerm.add(fId);
              });
            });

            const orgsWithThisPerm = Array.from(this.orgFacTree.keys());

            this.organizationsDataSource.items = ddlOrgs.filter((ddlOrg) =>
              orgsWithThisPerm.some((orgId) => ddlOrg.id === orgId.toString())
            );
          })
        );
      })
    );

    this.usernameCtrl = new UntypedFormControl(null, {
      updateOn: 'blur',
      validators: UsernameValidator,
      asyncValidators: Validators.composeAsync([
        CustomValidators.uniqueUsername(this.userService),
      ]),
    });

    this.emailCtrl = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.email,
        Validators.maxLength(256),
        NsightCustomValidators.isEmailExtraChecksValid(),
      ]),
    });
    this.firstNameCtrl = new UntypedFormControl(null, {
      validators: Validators.compose([Validators.required]),
    });
    this.lastNameCtrl = new UntypedFormControl(null, {
      validators: Validators.compose([Validators.required]),
    });

    const orgIdInitialValue: number[] = [];

    if (!this.multipleOrgs) {
      const onlyOrgId = (
        this.pristineLoggedInUserOrgs.first() as NameWithLongIdApiModel
      ).Id;
      orgIdInitialValue.push(onlyOrgId);
    }
    this.organizationIdsCtrl = new UntypedFormControl(orgIdInitialValue, [
      CustomValidators.listHasItems,
    ]);

    this.form = new UntypedFormGroup({
      username: this.usernameCtrl,
      email: this.emailCtrl,
      firstName: this.firstNameCtrl,
      lastName: this.lastNameCtrl,
      organizationIds: this.organizationIdsCtrl,
    });

    this.subs.add(
      // When users facility/roles have been updated
      // determine if ANY of those roles are N-Sight
      // and mark e-mail field as required accordingly
      this.facilitiesAndRoles$.subscribe((allFacsAndRoles) => {
        let allRoles: CapsaRoleResponseDto[] = [];

        allFacsAndRoles.forEach((facRole) => {
          allRoles = allRoles.concat(facRole.roles);
        });

        const hasNsightPerms = UserHelpers.anyRoleNsightPermission(allRoles);

        if (!hasNsightPerms) {
          this.emailCtrl.setValidators([
            Validators.email,
            Validators.maxLength(256),
            NsightCustomValidators.isEmailExtraChecksValid(),
          ]);
        } else {
          // If one of the roles this new user will have, gives them access to Nsight, then an email is required
          this.emailCtrl.setValidators([
            Validators.required,
            Validators.email,
            Validators.maxLength(256),
            NsightCustomValidators.isEmailExtraChecksValid(),
          ]);
        }
        this.emailCtrl.updateValueAndValidity();
      })
    );
  }

  ngOnDestroy() {
    // Reset the datasource since we modified the .items property to shrink down the list of orgs
    this.organizationsDataSource.reset();
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([
          Permissions.CLI_Users_ReadWrite,
          Permissions.Manage_Users_ReadWrite,
        ])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  public onOrgsChanged(orgs: AsyncDropDownValue<NameWithLongIdApiModel>[]) {
    const ids = orgs.map((f) => f.apiModel.Id);
    this.organizationIdsCtrl.setValue(ids);

    // cancel grid edit row if opened
    if (this.editingRowIndex !== undefined) {
      this.closeEditor(this.facilitiesRolesGrid, this.editingRowIndex);
    }

    // Check New Orgs
    this.updateGridBasedOnSelectedOrgs();
  }

  /**
   * go through grid facilities and see if they can remain
   * in the grid, if not, then remove
   */
  private updateGridBasedOnSelectedOrgs() {
    const selectedOrgs = this.organizationIdsCtrl.value as number[];
    const newGridData = this.facilitiesAndRolesBehSub.value.filter((x) => {
      return selectedOrgs.some((orgId) => orgId === x.facility.ParentId);
    });

    this.facilitiesAndRolesBehSub.next(newGridData);
  }

  public get multipleOrgs(): boolean {
    return this.pristineLoggedInUserOrgs.count() > 1;
  }

  public get canAddFacility(): boolean {
    return (
      // If no orgs are selected, then no facilities can be added either
      this.organizationIdsCtrl.value.length > 0 &&
      // Prevent the user from adding a useless row which will
      // have and empty dropdown since all facilities have already been assigned
      this.doUnassignedFacilitiesRemain &&
      // If a row is already being edited
      // don't let the user try to add another one
      this.editingRowIndex === undefined
    );
  }

  public get isFormInvalid() {
    return !this.form.valid || this.facilitiesAndRolesBehSub.value.length === 0;
  }

  public onCreate() {
    const createTaskId = 'createUserTask';
    this.loaderService.start(createTaskId);
    const facsRoles = this.facilitiesAndRolesBehSub.value as FacilityAndRoles[];

    const facilityIds = facsRoles.map((x) => x.facility.Id);

    let roles: CapsaRoleResponseDto[] = [];

    facsRoles.forEach((x) => {
      roles = roles.concat(x.roles);
    });

    const isGeneratePin = UserHelpers.anyRoleHasDeviceAccessPermission(roles);
    const createSub = this.userService
      .createUser({
        Email: this.emailCtrl.value,
        IsEmailConfirmed: false,
        Username: this.usernameCtrl.value,
        EnterpriseId: this.enterpriseService.enterpriseId,
        FacilityIds: facilityIds,
        FirstName: this.firstNameCtrl.value,
        LastName: this.lastNameCtrl.value,
        IsActive: true,
        JoinDateUtc: new Date().toUTCString(),
        OrganizationIds: this.organizationIdsCtrl.value as number[],
        RoleIds: roles.map((x) => x.CapsaRoleId),
        UserType: UserType.Pharmacy,
        SettingOverrides: [],
        // needed to make API happy -- not really used though at this time.
        UserTimeZone: 'UTC',
        GeneratePinCodes: isGeneratePin,
      })
      .subscribe(
        (resp) => {
          this.toasterService.showSuccess('USER_CREATED');

          if (resp.userId) {
            setTimeout(() => {
              this.loaderService.stop(createTaskId);
              this.router.navigate(['user', 'management', resp.userId]);
            }, 3000);
          }
        },
        (err) => {
          this.toasterService.showError('CREATE_FAILED');
          this.loaderService.stop(createTaskId);
        }
      );
    this.subs.add(createSub);
  }

  public addHandler({ sender }) {
    sender.closeRow(0);

    this.gridEditForm = this.buildRowForm();

    this.loadFacilityDropdown(null);
    this.multiSelectRolesBehSub.next([]);

    this.subs.add(
      this.gridEditForm.get('facility').valueChanges.subscribe((x) => {
        this.loadRolesMultiSelect((x as NameWithLongIdApiModel).Id);
      })
    );

    // Setting to -1, since "adds" are considered row -1?...
    this.editingRowIndex = -1;
    sender.addRow(this.gridEditForm);
  }

  /**
   * Loads and re-loads the "roles" multi-select control
   * This ensures that any selections from a previous selection are cleared
   * as well as ensures that new roles selected are for the correct, current facility
   * @param curFacilityId Currently selected ID for facility selected in row drop down
   */
  private loadRolesMultiSelect(curFacilityId: number) {
    this.subs.add(
      this.capsaRoleService.rolesList$.subscribe((allRoles) => {
        const nonHiddenFacilityRoles = allRoles.filter(
          (role) =>
            !Constants.hiddenRoleNames.some(
              (hiddenRoleName) => hiddenRoleName === role.Name
            ) && role.FacilityId === curFacilityId
        );

        nonHiddenFacilityRoles.forEach(
          (x) =>
            (x.TranslatedDisplayName = this.translateService.instant(x.Name))
        );

        this.multiSelectRolesBehSub.next(nonHiddenFacilityRoles);

        this.gridEditForm.get('roles').reset();
        this.gridEditForm.get('roles').enable();
      })
    );
  }

  /**
   *
   * @param currentFacilityId When doing edits on a row, pass in the facility ID that is already selected
   * so that it doesn't get filtered out of the list. If doing an add, pass in null.
   */
  private loadFacilityDropdown(currentFacilityId: number | null) {
    this.loadingFacsBehSub.next(true);

    const selectedOrgIds = this.organizationIdsCtrl.value as number[];

    let visibleFacs = this.pristineLoggedInUserFacs
      .filter(
        (f) =>
          selectedOrgIds.some((o) => o === f.ParentId) &&
          (this.authService.currentUser.EnterpriseAdmin ||
            this.facilitiesWithAddUserPerm.has(f.Id))
      )
      .toArray();

    const alreadyAssignedFacs = new Set(
      this.facilitiesAndRolesBehSub.value.map((a) => a.facility.Id)
    );

    if (currentFacilityId !== null) {
      // filter out all facilities that are already assigned, but include the currently selected
      visibleFacs = visibleFacs.filter(
        (f) => f.Id === currentFacilityId || !alreadyAssignedFacs.has(f.Id)
      );
    } else {
      // filter already assigned orgs
      visibleFacs = visibleFacs.filter((f) => !alreadyAssignedFacs.has(f.Id));
    }

    this.ddlFacilitiesBehSub.next(visibleFacs);
    this.loadingFacsBehSub.next(false);
  }

  private buildRowForm(existing?: FacilityAndRoles) {
    const formData: FacilityAndRoles = existing || {
      facility: {
        Id: -1,
        Name: '',
        ParentId: undefined,
      },
      roles: [],
    };

    const DtoRequired: ValidatorFn = (
      formControl: UntypedFormControl
    ): ValidationErrors | null => {
      const facility: NameWithLongIdApiModel = formControl.value;
      if (facility.Id === -1) {
        return { required: true };
      }

      return null;
    };

    return new UntypedFormGroup({
      facility: new UntypedFormControl(formData.facility, DtoRequired),
      roles: new FormControl<CapsaRoleResponseDto[]>(
        { value: formData.roles, disabled: !existing },
        Validators.required
      ),
    });
  }

  public get doUnassignedFacilitiesRemain(): boolean {
    const selectedOrgIds = this.organizationIdsCtrl.value as number[];

    const allFacilitiesUnderSelectedOrgs = this.pristineLoggedInUserFacs
      .filter((f) => selectedOrgIds.some((o) => o === f.ParentId))
      .toArray();

    const assignedFacs = new Set(
      this.facilitiesAndRolesBehSub.value.map((a) => a.facility.Id)
    );

    return allFacilitiesUnderSelectedOrgs.length !== assignedFacs.size;
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  /**
   * Saves row (newly added, or existing edited)
   */
  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const newFacAndRoles: FacilityAndRoles = formGroup.value;

    if (isNew) {
      const savedList = this.facilitiesAndRolesBehSub.value;
      savedList.unshift(newFacAndRoles);

      this.facilitiesAndRolesBehSub.next(savedList);
    } else {
      const gridData = this.facilitiesAndRolesBehSub.value;
      gridData[rowIndex] = newFacAndRoles;
      this.facilitiesAndRolesBehSub.next(gridData);
    }

    this.closeEditor(sender, rowIndex);
  }

  /**
   * "Delete" saved row
   */
  public removeHandler({ dataItem }): void {
    const toDeleteIdx = this.facilitiesAndRolesBehSub.value.findIndex(
      (x) => x.facility.Id === dataItem.facility.Id
    );

    this.facilitiesAndRolesBehSub.value.splice(toDeleteIdx, 1);
    this.facilitiesAndRolesBehSub.next(this.facilitiesAndRolesBehSub.value);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender, rowIndex);

    const assignment: FacilityAndRoles = dataItem;

    this.pristineFacilityRoleDataItem =
      ImmutableFacilityAndRolesGridRow(assignment);

    this.gridEditForm = this.buildRowForm(assignment);
    this.loadFacilityDropdown(assignment.facility.Id);

    this.subs.add(
      this.gridEditForm.get('facility').valueChanges.subscribe((x) => {
        this.loadRolesMultiSelect((x as NameWithLongIdApiModel).Id);
      })
    );

    this.editingRowIndex = rowIndex;
    sender.editRow(rowIndex, this.gridEditForm);
  }

  private closeEditor(grid, rowIndex) {
    grid.closeRow(rowIndex);
    this.editingRowIndex = undefined;
    this.pristineFacilityRoleDataItem = undefined;
  }

  public getCsvOfRoles(roles: CapsaRoleResponseDto[]) {
    return roles.map((x) => x.TranslatedDisplayName).join(', ');
  }
}
