import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { EnterpriseDropDownDataSource } from '@capsa/dropdowns/enterprise-drop-down/enterprise-drop-down-data-source';
import { FacilityEnterpriseDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-enterprise-drop-down-data-source';
import { OrganizationEnterpriseDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-enterprise-drop-down-data-source';
import { EofInfo } from 'app/modules/support/support-transfer-carts/eof-transfer-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-transfer-cart-enterprise',
  templateUrl: './support-transfer-cart-enterprise.component.html',
  styleUrls: ['./support-transfer-cart-enterprise.component.scss'],
})
export class SupportTransferCartEnterpriseComponent implements OnDestroy {
  @Input()
  public sourceEnterpriseId: number;

  @Input()
  sourceOrgId: number;

  @Input()
  sourceFacilityId: number;

  @Input()
  areCartsSelected: boolean;

  @Output()
  transferTriggered = new EventEmitter<EofInfo>();

  public targetEnterpriseId: number | undefined;
  public targetOrgId: number | undefined;
  public targetFacilityId: number | undefined;

  private subs = new Subscription();

  constructor(
    public enterpriseDataSource: EnterpriseDropDownDataSource,
    public orgEnterpriseDataSource: OrganizationEnterpriseDropDownDataSource,
    public facilityEnterpriseDropDownDataSource: FacilityEnterpriseDropDownDataSource
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public targetEnterpriseChanged(
    ddValue: AsyncDropDownValue<NameWithLongIdApiModel>
  ) {
    this.targetOrgId = undefined;
    this.targetFacilityId = undefined;

    this.targetEnterpriseId = ddValue.apiModel
      ? ddValue.apiModel.Id
      : undefined;

    if (this.targetEnterpriseId) {
      this.loadOrganizations();
    }
  }

  private loadOrganizations() {
    this.orgEnterpriseDataSource.enterpriseId = this.targetEnterpriseId;
    this.subs.add(
      this.orgEnterpriseDataSource.resetAndLoad().subscribe((data) => {
        this.orgEnterpriseDataSource.data = data;
        const selectedId = parseInt(
          this.orgEnterpriseDataSource.selection.id,
          10
        );
        if (selectedId > 0) {
          this.targetOrgId = selectedId;
          this.loadFacilities();
        }
      })
    );
  }

  public targetOrgChanged(ddValue: AsyncDropDownValue<NameWithLongIdApiModel>) {
    this.targetFacilityId = undefined;
    this.targetOrgId = ddValue.apiModel ? ddValue.apiModel.Id : undefined;

    if (this.targetOrgId) {
      this.loadFacilities();
    }
  }

  private loadFacilities() {
    this.facilityEnterpriseDropDownDataSource.enterpriseId =
      this.targetEnterpriseId;
    this.facilityEnterpriseDropDownDataSource.organizationId = this.targetOrgId;
    this.subs.add(
      this.facilityEnterpriseDropDownDataSource
        .resetAndLoad()
        .subscribe((data) => {
          this.facilityEnterpriseDropDownDataSource.data = data;
          const selectedId = parseInt(
            this.facilityEnterpriseDropDownDataSource.selection.id,
            10
          );
          if (selectedId > 0) {
            this.targetFacilityId = selectedId;
          }
        })
    );
  }

  public targetFacilityChanged(
    newValue: AsyncDropDownValue<NameWithLongIdApiModel>
  ) {
    this.targetFacilityId = newValue.apiModel
      ? newValue.apiModel.Id
      : undefined;
  }

  public onBtnStartCartTransfer() {
    // If somehow button was clicked when it wasn't supposed to - stop things!
    if (
      !this.sourceEnterpriseId ||
      !this.sourceOrgId ||
      !this.sourceFacilityId ||
      !this.targetEnterpriseId ||
      !this.targetOrgId ||
      !this.targetFacilityId ||
      !this.areCartsSelected ||
      !this.isSourceTargetEnterpriseComboValid
    ) {
      return;
    }
    // this.loading = true;
    this.transferTriggered.emit({
      EnterpriseId: this.targetEnterpriseId,
      OrgId: this.targetOrgId,
      FacilityId: this.targetFacilityId,
    });
  }

  public get disableTransferButton(): boolean {
    return (
      !this.sourceEnterpriseId ||
      !this.sourceOrgId ||
      !this.sourceFacilityId ||
      !this.targetEnterpriseId ||
      !this.targetOrgId ||
      !this.targetFacilityId ||
      !this.areCartsSelected ||
      // don't allow attempting to transfer to the same facility
      this.targetFacilityId === this.sourceFacilityId ||
      // don't allow invalid source-target enterprise selections
      !this.isSourceTargetEnterpriseComboValid
    );
  }

  // disable transfers if source and target enterprise match
  public get isSourceTargetEnterpriseComboValid(): boolean {
    return this.targetEnterpriseId !== this.sourceEnterpriseId;
  }
}
