import { Injectable } from '@angular/core';

import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { NativeWindowService } from '@capsa/services/native-window/native-window.service';
import decodeJwt from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from '../../../../common/constants';
import { GetAuthResponse } from './user';

/**
 * Represents the methods available to an instance
 * of an AuthService.
 */
export interface AuthServiceInstance {
  currentUser: GetAuthResponse;
  logout(): void;
  hasAccessTokenCookie(): boolean;
  getDisplayName(): string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInstance {
  public currentUser: GetAuthResponse;
  private isAuthenticatedBehSub: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public isAuthenticated$: Observable<boolean> =
    this.isAuthenticatedBehSub.asObservable();

  private loadedCurrentUserBehSubj = new BehaviorSubject<void>(null);
  public loadedCurrentUser$ = this.loadedCurrentUserBehSubj.asObservable();

  constructor(
    private cookieService: CookieService,
    private windowService: NativeWindowService,
    private appSettingService: AppSettingsService
  ) {
    this.loadUserFromCookie();
  }

  public loadUserFromCookie() {
    const cookieVal = this.cookieService.get(Constants.CookieNames.chUser);
    if (cookieVal) {
      this.loadCurrentUser(cookieVal);
      this.setAuthCookie();
    }
  }

  public loadCurrentUser(getAuthResponseJson: string) {
    this.currentUser = JSON.parse(getAuthResponseJson);
    const jwt = this.currentUser.AccessToken;
    const profile = decodeJwt(jwt) as any;
    this.currentUser = {
      ...this.currentUser,
      ...profile,
      EnterpriseAdmin: profile.EnterpriseAdmin === '1' ? true : false,
    };
    if (typeof this.currentUser.EnterpriseId === 'string') {
      this.currentUser.EnterpriseId = this.currentUser.EnterpriseId * 1;
    }

    this.loadedCurrentUserBehSubj.next();
  }

  public updateIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticatedBehSub.next(isAuthenticated);
  }

  public logout(): void {
    this.currentUser = new GetAuthResponse();
    this.setAuthCookie(true);
    const authUrl =
      `${this.appSettingService.get<string>('loginPortalUrl')}?returnUrl=` +
      encodeURIComponent(this.windowService.currentOrigin());
    this.windowService.redirect(authUrl);
  }

  /**
   * Sets an auth cookie (setting a cookie that already exists would be to reset the expiration time)
   * @param deleteCookie if true, will cause the auth cookie to be deleted
   */
  public setAuthCookie(deleteCookie: boolean = false) {
    const expirationDate = (
      deleteCookie
        ? moment().subtract(1, 'days')
        : moment()
            .add(Constants.sessionTimeoutMinutes, 'minutes')

            // Add extra time to avoid "close calls" with grace period
            // (we don't want a case where the cookie has expired, but the user can still see N-Sight)
            .add(15, 'seconds')
    ).toDate();

    this.cookieService.set(
      Constants.CookieNames.chUser, // cookie name
      JSON.stringify(this.currentUser), // cookie val
      expirationDate, // expiration date
      '/', // path
      undefined, // domain
      true, // secure?
      // Using 'Lax' since 'Strict' doesn't allow setting and then READING user
      // auth cookie in the same request when coming from the Capsa Portal page in Firefox
      'Lax' // SameSite
    );
  }

  /**
   * determines if a "Token Cookie" still exists
   * note: an expired cookie is the same as "no cookie"
   */
  public hasAccessTokenCookie(): boolean {
    return !!this.cookieService.get(Constants.CookieNames.chUser);
  }

  public getDisplayName(): string {
    if (this.currentUser) {
      return this.currentUser.DisplayName;
    }
    return '';
  }
}
