import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FacilityApi } from '@capsa/api/facility';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import {
  FileRestrictions,
  FileState,
  SelectEvent,
  UploadComponent,
} from '@progress/kendo-angular-upload';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-profile-image',
  templateUrl: './facility-manage-profile-image.component.html',
  styleUrls: ['./facility-manage-profile-image.component.scss'],
})
export class FacilityManageProfileImageComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  public facilityId: number;

  @Input()
  public width: number;

  @Input()
  public height: number;

  public logoUrl: string;

  public fileUploadError: string | undefined;

  @ViewChild(UploadComponent, { static: false })
  public uploadComponentRef: UploadComponent;

  public logoRestrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
  };

  private subs: Subscription = new Subscription();

  public get dimensionsText() {
    return `${this.width} x ${this.height}`;
  }

  constructor(
    private facilityApi: FacilityApi,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadLogo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.facilityId.isFirstChange()) {
      this.uploadComponentRef.clearFiles();
      this.loadLogo();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public verifyImage(e: SelectEvent) {
    // this is set at the begining because we can only ever upload 1 image
    // at a time, and if the upload itself fails, then it is some unknown API
    // error.
    this.fileUploadError = 'UNKNOWN_ERROR';

    const image = e.files[0];
    image.validationErrors = image.validationErrors || [];

    // validation of file type happens by kendo, so this just stops us
    // from doing extra processing.
    if (image.extension.toLowerCase() !== '.png') {
      return;
    }

    // Initial code adapted from: https://stackoverflow.com/a/50567853
    // Basically create an in-memory img HTML tag so that we can
    // detect what the width/height are prior to uploading to the API.
    const fr = new FileReader();
    fr.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > this.width || img.height > this.height) {
          this.fileUploadError = 'INVALID_BRAND_LOGO_DIMENSIONS';
          // set the kendo control to render this as an error message
          this.uploadComponentRef.fileList.setFilesStateByUid(
            image.uid,
            FileState.Failed
          );
          return;
        }

        // dimension check was OK, so proceed to upload image to API.
        this.uploadComponentRef.uploadFiles();
      };

      // this line just triggers fr.onload processing above.
      img.src = fr.result.toString();
    };

    // kick off processing of image file
    fr.readAsDataURL(image.rawFile);
  }

  public get uploadUrl() {
    return this.facilityApi.uploadLogoUrl(this.facilityId);
  }

  public onUploadComplete() {
    this.loadLogo();
  }

  private loadLogo() {
    const sub = this.facilityApi.getLogo(this.facilityId).subscribe(
      (resp) => {
        this.logoUrl = resp.Result;
      },
      () => {
        this.toasterService.showError('FACILITY_MANAGEMENT_IMAGE_LOAD_FAIL');
      }
    );
    this.subs.add(sub);
  }
}
