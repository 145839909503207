import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CartFriendlyUpdateGridItem, DeviceUpdateType } from '@capsa/api';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';
import { Utils } from 'app/common/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-list-dialog',
  templateUrl: './cart-list-dialog.component.html',
  styleUrls: ['./cart-list-dialog.component.scss'],
})
export class CartListDialogComponent implements OnInit, OnDestroy {
  // Adds a class to the "host" HTML wrapper of this component
  @HostBinding('class') class = 'responsiveKendoDialog';

  @Input()
  public cartsList: CartFriendlyUpdateGridItem[] = [];

  @Input()
  public dialogTitle: string;

  @Input()
  public dialogMsg: string;

  @Input()
  /**
   * Used only for the advanced page in order to "let the dialog know" which update type is selected
   * so that it can additional relevant info like PN/Version for the selected update type
   */
  public advancedUpdateType: DeviceUpdateType;

  @Output()
  public closed = new EventEmitter();

  public DeviceUpdateType = DeviceUpdateType;

  public icons = { xIcon: xIcon };

  public readonly pageSize = 100;

  public cartListData: GridDataResult;

  public cartListState: State = Utils.getDefaultState(this.pageSize);

  private subs = new Subscription();

  public ngOnInit() {
    this.reprocess();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public saveSelection() {}

  public onClose() {
    this.closed.emit();
  }

  private reprocess() {
    this.cartListData = process(this.cartsList, this.cartListState);
  }

  public cartListStateChange(state: DataStateChangeEvent): void {
    this.cartListState = state;
    this.cartListData = process(this.cartsList, this.cartListState);
  }
}
