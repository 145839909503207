import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CapsaSettingApiModel, DataDrivenSettingDataType } from '@capsa/api';
import {
  DataDrivenCheckBoxConfig,
  DataDrivenControlStyles,
  DataDrivenNumberConfig,
  DataDrivenSliderConfig,
  DataDrivenSwitchConfig,
  DataDrivenTextConfig,
} from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-control',
  templateUrl: './data-driven-control.component.html',
  styleUrls: ['./data-driven-control.component.scss'],
})
export class DataDrivenControlComponent {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public controlStyle: DataDrivenControlStyles;

  @Input()
  public controlConfig:
    | DataDrivenSwitchConfig
    | DataDrivenSliderConfig
    | DataDrivenNumberConfig
    | DataDrivenCheckBoxConfig
    | DataDrivenTextConfig;

  @Input()
  public disabled = false;

  @Output()
  public valueChanged = new EventEmitter<any>();

  @Output()
  public validState = new EventEmitter<boolean>();

  get isCheckbox() {
    return this.setting.DataType === DataDrivenSettingDataType.Boolean;
  }
  get isDropdown() {
    return this.setting.DataType === DataDrivenSettingDataType.Dropdown;
  }
  get isText() {
    return this.setting.DataType === DataDrivenSettingDataType.String;
  }
  get isNumber() {
    return this.setting.DataType === DataDrivenSettingDataType.Integer;
  }

  public onValueChanged(newValue: any) {
    this.valueChanged.emit(newValue);
  }

  constructor() {}
}
