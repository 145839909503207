import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { EnumResponse } from '@capsa/api/enum';
import { EnumTypes } from '@capsa/dropdowns/enum';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';
import { BaseApi } from '../base/base.api';

export interface EnumApiInstance {
  getEnum(enumName: EnumTypes): Observable<EnumResponse>;
}

@Injectable()
export class EnumApi extends BaseApi implements EnumApiInstance {
  protected endpoints = {
    getEnum: this.buildUrl('common/enum/'),
  };

  constructor(http: HttpClient, appSettingService: AppSettingsService) {
    super(http, appSettingService);
  }

  public getEnum(enumName: EnumTypes): Observable<EnumResponse> {
    return this.http.get<EnumResponse>(this.endpoints.getEnum + enumName);
  }
}
