import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ActivityLogModule } from 'app/modules/activity-log/activity-log.module';
import { LabelsModule } from 'app/modules/labels/labels.module';
import { CartFaultLogsComponent } from './cart-fault-logs/cart-fault-logs.component';
import { CartLogSearchFieldsComponent } from './cart-log-search-fields/cart-log-search-fields.component';
import { CartUpdateLogsComponent } from './cart-update-logs/cart-update-logs.component';

@NgModule({
  declarations: [
    CartUpdateLogsComponent,
    CartFaultLogsComponent,
    CartLogSearchFieldsComponent,
  ],
  imports: [
    ActivityLogModule,
    CommonModule,
    ButtonModule,
    InputsModule,
    LabelsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    CartUpdateLogsComponent,
    CartFaultLogsComponent,
    CartLogSearchFieldsComponent,
  ],
})
export class CartLogsModule {}
