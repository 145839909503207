import { Component, Input } from '@angular/core';
import { questionCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-label-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
})
export class HelpIconComponent {
  @Input()
  public isInline: boolean;

  @Input()
  public helpTextTag: string;

  public readonly questionCircleIcon = questionCircleIcon;

  constructor() {}
}
