import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-set-password-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss'],
})
export class RequirementComponent {
  @Input()
  public text: string;

  @Input()
  public control: AbstractControl;

  @Input()
  public errorKey: string;

  get hasError() {
    return (
      this.control.hasError('required') || this.control.hasError(this.errorKey)
    );
  }

  constructor() {}
}
