import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CapsaSettingsTypeIds,
  CartResponse,
  UserPartialResponse,
  UserSearchRequest,
} from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { GridFilterHelperService } from '@capsa/services/grid-filter-helper/grid-filter-helper.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { Constants } from 'app/common/constants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-shared-cart-management-users-tab',
  templateUrl: './shared-cart-management-users-tab.component.html',
  styleUrls: ['./shared-cart-management-users-tab.component.scss'],
})
export class SharedCartManagementUsersTabComponent
  implements OnInit, OnDestroy
{
  constructor(
    private userApi: UserApi,
    private toasterService: ToasterService,
    private enterpriseService: EnterpriseService,
    private filterService: GridFilterHelperService,
    @Inject('device')
    public device: CartResponse,
    private router: Router
  ) {}

  public gridDataLoading = false;
  private gridDataBehSub = new BehaviorSubject<GridDataResult>(null);
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 500;
  public skip = 0;

  private query: Partial<UserSearchRequest> = {
    SortInfo: [{ ColumnName: 'FirstName', IsDescending: false }],
  };

  public Constants = Constants;
  public sort: SortDescriptor[] = [];
  public userListFilter: CompositeFilterDescriptor;

  private apiSearchSub: Subscription = new Subscription();
  private subs: Subscription = new Subscription();

  public ngOnInit() {
    this.loadGridData();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next({ data: [], total: 0 });
    this.skip = 0;
    this.gridDataLoading = false;
  }

  private loadGridData() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a 'deselect', we want to make sure data doesn't come in
    // after we've went back to an 'unselected' filter (prevents race condition bugs)
    this.apiSearchSub.unsubscribe();

    this.gridDataLoading = true;

    this.query = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      FacilityIds: [this.device.FacilityId],
      CartIds: [this.device.CartId],
      SortInfo: this.query.SortInfo,
      PageSize: this.pageSize,
      PageNumber: this.skip / this.pageSize + 1,
      SettingsDeviceTypeId: this.device.DeviceTypeId,
      Settings: [],
    };

    this.query.Settings.push(CapsaSettingsTypeIds.CLI_IsCartUserActive);

    const transformation = (fieldName: string, filter: FilterDescriptor) => {
      if (fieldName === 'FirstName') {
        this.query.FirstName = filter.value?.trim();
      } else if (fieldName === 'LastName') {
        this.query.LastName = filter.value?.trim();
      } else if (fieldName === 'UserName') {
        this.query.UserName = filter.value?.trim();
      }
    };

    this.filterService.transform(this.userListFilter, transformation);

    this.apiSearchSub = this.userApi
      .getCartAssignedUsers(this.query)
      .pipe(
        finalize(() => {
          this.gridDataLoading = false;
        })
      )
      .subscribe(
        (x) => {
          this.gridDataBehSub.next({
            data: x.Result,
            total: x.TotalRecordCount,
          });
        },
        (_error) => {
          this.toasterService.showError('USER_LIST_LOAD_FAIL');
        }
      );
  }

  public sortChange(sort: SortDescriptor[]): void {
    if (this.gridDataLoading) {
      return;
    }
    this.sort = sort;
    this.query.SortInfo = [];
    this.sort.forEach((col) => {
      if (!col.dir) {
        return;
      }
      this.query.SortInfo.push({
        ColumnName: col.field,
        IsDescending: col.dir === 'desc',
      });
    });

    this.skip = 0;
    this.loadGridData();
  }

  public pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.loadGridData();
  }

  public onUserListFilterChanged(data: CompositeFilterDescriptor) {
    this.userListFilter = data;
    this.resetPagingAndData();
    this.loadGridData();
  }

  public onSelectUser(event: Event, user: UserPartialResponse) {
    this.router.navigate(['user', 'management', user.Id]);
  }
}
