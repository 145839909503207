import {
  ApplicationRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CapsaSettingsTypeIds,
  DeviceTypeIds,
  SettingResponse,
} from '@capsa/api';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogCloseResult,
  DialogRef,
  DialogResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { AppComponent } from 'app/app.component';
import { DataDrivenDropDownComponent } from 'app/modules/data-driven-controls/data-driven-drop-down/data-driven-drop-down.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-manage-pins',
  templateUrl: './facility-manage-pins.component.html',
  styleUrls: ['./facility-manage-pins.component.scss'],
})
export class FacilityManagePinsComponent implements OnInit {
  @Input()
  settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  @Input()
  deviceTypeId: number;

  @ViewChild('pinLengthDropDown', { static: false })
  pinLengthDropDown: DataDrivenDropDownComponent;

  private pristinePinLength: string;

  /**
   * TODO: Move to using dynamic tabs instead of isAvalo boolean
   * checks like this.
   *
   * https://capsahealthcare.atlassian.net/browse/NS-1587
   */
  public get pinSettingType() {
    return this.isAvalo
      ? CapsaSettingsTypeIds.AVO_PinLength
      : CapsaSettingsTypeIds.CLI_PinLength;
  }

  /**
   * TODO: Move to using dynamic tabs instead of isAvalo boolean
   * checks like this.
   *
   * https://capsahealthcare.atlassian.net/browse/NS-1587
   */
  public get isAvalo(): boolean {
    return this.deviceTypeId === DeviceTypeIds.Avalo;
  }
  // To expose the enum to the template
  public settingTypeEnum = CapsaSettingsTypeIds;

  private subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private appRef: ApplicationRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.pristinePinLength = this.settings.get(
      this.pinSettingType
    ).SettingValue;
  }

  public onPinLengthChanged(value: any) {
    if (value !== this.pristinePinLength) {
      this.openPinLengthChangeConfirmDialog();
    }
  }

  private openPinLengthChangeConfirmDialog() {
    const appComp = this.appRef.components[0]
      .instance as unknown as AppComponent;

    const dialog = this.dialogService.open({
      // Append the dialog to near the root DOM element, otherwise z-indexing issues will cause other elements to overlap this dialog
      appendTo: appComp.vcrRootAllContent,
      title: this.translateService.instant('CHANGE_PIN_LENGTH_DIALOG_TITLE'),
      cssClass: 'dialogWarn',
      width: 450,
      actions: [
        {
          text: this.translateService.instant('COM_DISCARD_CHANGES'),
        },
        { text: this.translateService.instant('COM_KEEP_CHANGES') },
      ],
      content: this.translateService.instant('CHANGE_PIN_LENGTH_DIALOG_BODY'),
    });

    this.subs.add(
      dialog.result.subscribe((result) => {
        this.dialogConfirmPinChange(result, dialog);
      })
    );
  }

  public dialogConfirmPinChange(result: DialogResult, dialogRef: DialogRef) {
    if (
      result instanceof DialogCloseResult ||
      result.text === this.translateService.instant('COM_DISCARD_CHANGES')
    ) {
      this.pinLengthDropDown.setting.SettingValue = this.pristinePinLength;
    }

    dialogRef.close();
  }
}
