import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiResponse,
  DeviceTypeApiModel,
  DeviceTypeIds,
  NameWithLongIdApiModel,
} from '@capsa/api';
import { UserApi } from '@capsa/api/user';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';
import { FacilityUserDropDownDataSource } from '@capsa/dropdowns/facility-drop-down/facility-user-drop-down-data-source';
import { OrganizationUserDropDownDataSource } from '@capsa/dropdowns/organization-drop-down/organization-user-drop-down-data-source';
import { CapsaRoleService } from '@capsa/services/capsa-role-service/capsa-role.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { EditUserService } from 'app/modules/users/user-management/edit-user.service';
import { Subscription } from 'rxjs';
import { filter, finalize, first, map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-wrapper',
  templateUrl: './edit-user-wrapper.component.html',
  styleUrls: ['./edit-user-wrapper.component.scss'],
  providers: [EditUserService],
})
export class EditUserWrapperComponent implements OnInit, OnDestroy {
  public canEdit = false;
  public isUserLoaded = false;

  /**
   * Used to prevent clicking button multiple times
   * accidentally/intentionally till next "page load"
   */
  public emailSentSuccess = false;

  get isAvaloCart(): boolean {
    return this.deviceTypeId === DeviceTypeIds.Avalo;
  }

  public get defaultItem(): NameWithLongIdApiModel {
    return {
      Name: this.translateService.instant('COM_SELECT_A_VALUE'),
      Id: -1,
    };
  }

  @ViewChild('tabstrip', { static: false }) public tabstrip: TabStripComponent;

  public apiResponse: ApiResponse<void>;

  private subs = new Subscription();

  /* user facility config properties */
  /**
   * User "Facility Config" organizations list for drop down
   */
  public userFcOrgs: NameWithLongIdApiModel[];

  /**
   * User Facility Config Organization
   */
  public selectedUserFcOrg = this.defaultItem;

  /**
   * User "Facility Config" facilities list for drop down
   */
  public userFcFacs: NameWithLongIdApiModel[];
  public selectedUserFcFacility = this.defaultItem;

  public deviceTypeId: DeviceTypeIds;
  /* end user facility config properties */

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    public orgDataSource: OrganizationUserDropDownDataSource,
    public facilityDataSource: FacilityUserDropDownDataSource,
    public capsaRoleService: CapsaRoleService,
    private translateService: TranslateService,
    private enterpriseService: EnterpriseService,
    private permissionService: PermissionService,
    private eofCacheService: EofCacheService,
    public editUserService: EditUserService,
    public deviceTypeDataSource: DeviceTypeDropDownDataSource,
    private userApi: UserApi,
    private toasterService: ToasterService,
    public loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loadPermissions();
    this.loadDeviceTypes();

    this.facilityDataSource.enterpriseId = this.enterpriseService.enterpriseId;

    this.subs.add(
      this.editUserService.userLoaded$.subscribe(() => {
        this.refreshUserDetails();
        this.isUserLoaded = true;
      })
    );

    this.subs.add(
      this.editUserService.facAssignmentsChanged$.subscribe(() => {
        // Check how facility assignments have changed and adjust UI accordingly
        this.updateUserOrgsFacilityDropDowns();
      })
    );

    this.requestUserInfoFromApi();
    this.subs.add(
      this.editUserService.selectedFacilityChanged$
        .pipe(filter((x) => x > 0))
        .subscribe(() => {
          if (!this.tabstrip || !this.tabstrip.tabs) {
            return;
          }

          // The "Permissions" tab should always be first, and it should be selected by default
          // whenever the selected facility changes
          // this avoids us getting into a state where a tab is selected and remains selected
          // even though the newly selected facility's roles, should cause the tab to be disabled
          this.tabstrip.selectTab(0);
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([
          Permissions.CLI_Users_ReadWrite,
          Permissions.Manage_Users_ReadWrite,
        ])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  public fillUsersOrgFacilityDropDowns() {
    // Get list of distinct Orgs
    const userOrgs = this.editUserService.pristineUser.get('OrganizationList');
    this.userFcOrgs = [this.defaultItem, ...userOrgs];

    if (userOrgs.length === 1) {
      this.onConfigOrgChange(userOrgs[0]);
      return;
    }

    this.userFcFacs = [this.defaultItem];
  }

  private updateUserOrgsFacilityDropDowns() {
    // get state of stale Fac ddl (we only care about Facility being selected/not because
    // that's what actually causes the facility settings to load)
    const staleSelectedFac = this.selectedUserFcFacility;

    const newOrgsAssignmentIds =
      this.editUserService.updatedUser.get('OrganizationIds');
    const newFacAssignmentIds =
      this.editUserService.updatedUser.get('FacilityIds');

    this.setOrgDdlByIds(newOrgsAssignmentIds);

    // If no settings facility dd item was selected OR the previously selected one is NO LONGER in the new assignments list, then use "auto fill"
    if (
      staleSelectedFac.Id === this.defaultItem.Id ||
      !newFacAssignmentIds.some((fId) => fId === staleSelectedFac.Id)
    ) {
      this.autoFillAndSelectDdls(newOrgsAssignmentIds, newFacAssignmentIds);
    } else {
      // Although whatever facility was already selected is still in the Facility DDL, we need to "refresh" those list items
      this.setFacDdlByParentOrg(newFacAssignmentIds, this.selectedUserFcOrg.Id);
    }
  }

  private autoFillAndSelectDdls(
    newOrgsAssignmentIds: number[],
    newFacAssignmentIds: number[]
  ) {
    if (newOrgsAssignmentIds.length === 1 && newFacAssignmentIds.length === 1) {
      // Nothing selected in drop downs and only one org/facility available, auto-select them
      this.setSelectedOrgById(newOrgsAssignmentIds[0]);
      this.setFacDdlByParentOrg(newFacAssignmentIds, newOrgsAssignmentIds[0]);
      this.setSelectedFacilityById(newFacAssignmentIds[0]);
    } else if (newOrgsAssignmentIds.length === 1) {
      this.setSelectedOrgById(newOrgsAssignmentIds[0]);
      this.setFacDdlByParentOrg(newFacAssignmentIds, newOrgsAssignmentIds[0]);
      this.setSelectedFacility(this.defaultItem);
    } else {
      this.setDdlsToDefaults();
    }
  }

  private setDdlsToDefaults() {
    this.selectedUserFcOrg = this.defaultItem;
    this.userFcFacs = [this.defaultItem];
    this.setSelectedFacility(this.defaultItem);
  }

  private setSelectedFacility(selectedItem: NameWithLongIdApiModel) {
    this.selectedUserFcFacility = selectedItem;
    this.editUserService.reportUserFacilityConfigFacIdChanged(selectedItem.Id);
  }

  private setOrgDdlByIds(orgIds: number[]) {
    this.userFcOrgs = [
      this.defaultItem,
      ...this.eofCacheService.getDetailedOrgsFromIds(orgIds),
    ];
  }

  private setFacDdlByParentOrg(facIds: number[], parentOrgId: number) {
    let orgFacilities = this.eofCacheService.getDetailedFacsFromIds(facIds);

    orgFacilities = orgFacilities.filter((fac) => fac.ParentId === parentOrgId);

    this.userFcFacs = [this.defaultItem, ...orgFacilities];
  }

  private setSelectedOrgById(orgId: number) {
    this.selectedUserFcOrg = this.eofCacheService.getDetailedOrgsFromIds([
      orgId,
    ])[0];
  }

  private setSelectedFacilityById(facilityId: number) {
    this.setSelectedFacility(
      this.eofCacheService.getDetailedFacsFromIds([facilityId])[0]
    );
  }

  public onConfigOrgChange(newValue: NameWithLongIdApiModel) {
    this.selectedUserFcOrg = newValue;

    if (newValue.Id === -1) {
      this.userFcFacs = [this.defaultItem];
      this.setSelectedFacility(this.defaultItem);
      return;
    }

    this.setFacDdlByParentOrg(
      this.editUserService.updatedUser.get('FacilityIds'),
      newValue.Id
    );

    // If only "default" and one facility, then auto select that facility...
    if (this.userFcFacs.length === 2) {
      this.setSelectedFacilityById(this.userFcFacs[1].Id);
    } else {
      // More than one facility, ensure to deselect any previously selected facility
      this.setSelectedFacility(this.defaultItem);
    }
  }

  public onConfigFacChange(newValue: NameWithLongIdApiModel | undefined) {
    this.setSelectedFacility(newValue);
  }

  private requestUserInfoFromApi() {
    const sub = this.currentRoute.params
      .pipe(
        map((values) => values.id as string),
        first()
      )
      .subscribe((userId) => {
        this.editUserService.loadUserFromApi(userId);
      });

    this.subs.add(sub);
  }

  private refreshUserDetails() {
    const user = this.editUserService.pristineUser.toObject();
    this.facilityDataSource.userFacilityList = user.FacilityList;
    this.orgDataSource.userOrgList = user.OrganizationList;

    this.fillUsersOrgFacilityDropDowns();
  }

  public onBackClicked() {
    this.router.navigate(['user', 'management']);
  }

  public onDeviceTypeChanged(newValue: AsyncDropDownValue<DeviceTypeApiModel>) {
    this.deviceTypeId = this.deviceTypeDataSource.deviceTypeId =
      !newValue.apiModel ? undefined : newValue.apiModel.DeviceTypeId;

    if (this.deviceTypeId !== undefined) {
      this.deviceTypeDataSource.lastSelectedDeviceTypeId = this.deviceTypeId;
    }
  }

  public onPasswordResetClicked() {
    const taskId = 'onPasswordResetClicked';
    this.loaderService.start(taskId);
    const userId = this.editUserService.userId;
    this.subs.add(
      this.userApi
        .forceResetPassword(userId)
        .pipe(finalize(() => this.loaderService.stop(taskId)))
        .subscribe(
          () => {
            this.emailSentSuccess = true;
            this.toasterService.showSuccess('USER_PASSWORD_RESET_SUCCESS', {
              hideAfterMs: 8000,
            });
          },
          (error) => {
            this.toasterService.showError('USER_PASSWORD_RESET_FAIL');
          }
        )
    );
  }

  public onResendConfirmEmailBtn() {
    const taskId = 'onResendConfirmEmailBtn';
    this.loaderService.start(taskId);
    this.subs.add(
      this.userApi
        .resendNewEmailConfirmEmail(this.editUserService.userId)
        .pipe(finalize(() => this.loaderService.stop(taskId)))
        .subscribe(
          (resp) => {
            this.emailSentSuccess = true;
            this.toasterService.showSuccess(
              'RESEND_NEW_EMAIL_CONFIRMATION_EMAIL_SUCCESS',
              { hideAfterMs: 5000 }
            );
          },
          (error) => {
            this.toasterService.showError(
              'RESEND_NEW_EMAIL_CONFIRMATION_EMAIL_FAIL',
              { hideAfterMs: 8000 }
            );
          }
        )
    );
  }
  public onResetEmailAndSecurityQuestionsBtn() {
    const taskId = 'onResetEmailAndSecurityQuestionsBtn';
    this.loaderService.start(taskId);
    this.subs.add(
      this.userApi
        .sendEmailAndSecurityQuestionReset(this.editUserService.userId)
        .pipe(finalize(() => this.loaderService.stop(taskId)))
        .subscribe(
          (resp) => {
            this.emailSentSuccess = true;
            this.toasterService.showSuccess(
              'RESEND_NEW_ACCOUNT_EMAIL_SUCCESS',
              { hideAfterMs: 5000 }
            );
          },
          (error) => {
            this.toasterService.showError('RESEND_NEW_ACCOUNT_EMAIL_FAIL', {
              hideAfterMs: 5000,
            });
          }
        )
    );
  }

  private loadDeviceTypes() {
    this.subs.add(
      this.deviceTypeDataSource.load().subscribe((visibleDeviceTypes) => {
        this.deviceTypeDataSource.data = visibleDeviceTypes;

        if (this.deviceTypeDataSource.hasStoredSelection) {
          if (
            this.deviceTypeDataSource.lastSelectedDeviceTypeId ===
            DeviceTypeIds.All
          ) {
            this.autoSelectTrio(visibleDeviceTypes);
          } else {
            this.autoSelectDeviceFromLastStored(visibleDeviceTypes);
          }
        } else {
          this.autoSelectTrio(visibleDeviceTypes);
        }
      })
    );
  }

  private autoSelectTrio(
    deviceTypesList: AsyncDropDownValue<DeviceTypeApiModel>[]
  ) {
    const match = deviceTypesList.find(
      (x) => x.id === DeviceTypeIds.CareLink_2.toString()
    );

    this.autoSetDeviceType(match);
  }

  private autoSelectDeviceFromLastStored(
    deviceTypesList: AsyncDropDownValue<DeviceTypeApiModel>[]
  ) {
    const match = deviceTypesList.find(
      (x) =>
        x.id === this.deviceTypeDataSource.lastSelectedDeviceTypeId.toString()
    );

    this.autoSetDeviceType(match);
  }

  private autoSetDeviceType(newValue: AsyncDropDownValue<DeviceTypeApiModel>) {
    this.deviceTypeId = this.deviceTypeDataSource.deviceTypeId =
      !newValue.apiModel ? undefined : newValue.apiModel.DeviceTypeId;

    this.deviceTypeDataSource.selectItem(newValue);
  }
}
