// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  width: 100%;
  height: 88px;
}

kendo-datetimepicker {
  width: 100%;
}

label.bold {
  font-weight: bold;
  display: inline;
}

#btnSendMessage {
  float: right;
}

h2 {
  display: inline-block;
  width: auto;
}

.gridHeadingWrapper {
  display: flex;
  justify-content: space-between;
}

button {
  margin: 25px 0 7px 0;
  min-width: 100px;
}

app-loading-indicator-circular {
  display: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/messaging/pages/new-message/new-message.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,qBAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;AAEF;;AACA;EACE,oBAAA;EACA,gBAAA;AAEF;;AACA;EACE,gBAAA;AAEF","sourcesContent":["textarea {\n  width: 100%;\n  height: 88px;\n}\n\nkendo-datetimepicker {\n  width: 100%;\n}\n\nlabel.bold {\n  font-weight: bold;\n  display: inline;\n}\n\n#btnSendMessage {\n  float: right;\n}\n\nh2 {\n  display: inline-block;\n  width: auto;\n}\n.gridHeadingWrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\nbutton {\n  margin: 25px 0 7px 0;\n  min-width: 100px;\n}\n\napp-loading-indicator-circular {\n  display: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
