import { CapsaSettingsTypeIds, SettingResponse } from '@capsa/api';
import { DropDownItem } from '@capsa/dropdowns/enum';

export interface AllFacilitySettings {
  profile: Map<CapsaSettingsTypeIds, SettingResponse>;
  pins: Map<CapsaSettingsTypeIds, SettingResponse>;
  timeout: Map<CapsaSettingsTypeIds, SettingResponse>;
  loginRequired: Map<CapsaSettingsTypeIds, SettingResponse>;
  notifications: Map<CapsaSettingsTypeIds, SettingResponse>;
  displayAndAlerts: Map<CapsaSettingsTypeIds, SettingResponse>;
  serviceRequests: Map<CapsaSettingsTypeIds, SettingResponse>;
  battery: Map<CapsaSettingsTypeIds, SettingResponse>;
}

export interface SettingItemsAndValue {
  items: DropDownItem[];
  value: string;
}

// Allows multiple keys with any name while still enforcing their type
export interface PageSettingsContainer {
  [key: string]: SettingItemsAndValue;
}

export const FacilitySettingKeys = {
  profile: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_CustomerLogoMaxHeight,
    CapsaSettingsTypeIds.CLI_CustomerLogoMaxWidth,
  ]),
  displayAndAlerts: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_UtcOffset,
  ]),
  pins: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_PinLockoutAttempts,
    CapsaSettingsTypeIds.CLI_PinMaskingTimespan,
    CapsaSettingsTypeIds.CLI_PinLockoutTimespan,
    CapsaSettingsTypeIds.CLI_PinLength,
    CapsaSettingsTypeIds.CLI_PartialPinTimespan,
    CapsaSettingsTypeIds.AVO_PinLength,
  ]),
  timeout: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_DrawerUnlockMaxTimeSpan,
    CapsaSettingsTypeIds.CLI_CartInactiveRedirectTimespanInMinutes,
    CapsaSettingsTypeIds.CLI_LoginMaxTimeout,
  ]),
  loginRequired: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_CalculatorRequiresLogin,
    CapsaSettingsTypeIds.CLI_LightRequiresLogin,
    CapsaSettingsTypeIds.CLI_LiftRequiresLogin,
    CapsaSettingsTypeIds.CLI_NotificationRequiresLogin,
    CapsaSettingsTypeIds.CLI_HelpRequiresLogin,
    CapsaSettingsTypeIds.CLI_ServiceRequestsRequiresLogin,
    CapsaSettingsTypeIds.CLI_SettingsRequiresLogin,
    CapsaSettingsTypeIds.CLI_TimerRequiresLogin,
    CapsaSettingsTypeIds.CLI_ConversionRequiresLogin,
  ]),
  notifications: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_EnableCartMessageSettingCustomization,
    CapsaSettingsTypeIds.CLI_MaxMessageExpirationInDays,
    CapsaSettingsTypeIds.CLI_MaxAlertExpirationInHours,
    CapsaSettingsTypeIds.CLI_EnableAlertBackgroundColors,
    CapsaSettingsTypeIds.CLI_EnableAlertIconFlashing,
    CapsaSettingsTypeIds.CLI_EnableAudibleAlerts,
  ]),
  serviceRequests: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.CLI_ServiceRequestTimeout,
    CapsaSettingsTypeIds.CLI_ServiceRequestEmailAddress,
    CapsaSettingsTypeIds.CLI_AutoAcknowledgeServiceRequest,
  ]),
  macAccessPoint: new Set<CapsaSettingsTypeIds>(),
  battery: new Set<CapsaSettingsTypeIds>([
    CapsaSettingsTypeIds.DeviceProfile_BatteryRange_Red,
    CapsaSettingsTypeIds.DeviceProfile_BatteryRange_Yellow,
  ]),

  // https://github.com/Microsoft/TypeScript/issues/10574#issuecomment-242907916
  *[Symbol.iterator]() {
    const properties = Object.keys(this);
    for (const i of properties) {
      yield [i, this[i]];
    }
  },
};

export class FacilitySettingHelper {
  public static getSortedFacilitySettings(
    apiResponseSettings: SettingResponse[]
  ): AllFacilitySettings {
    const sortedSettings: AllFacilitySettings = {
      profile: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      displayAndAlerts: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      pins: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      timeout: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      loginRequired: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      notifications: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      serviceRequests: new Map<CapsaSettingsTypeIds, SettingResponse>(),
      battery: new Map<CapsaSettingsTypeIds, SettingResponse>(),
    };

    for (const category of FacilitySettingKeys) {
      const curCategoryName = category[0] as string;
      const curCategorySettings = category[1] as Set<CapsaSettingsTypeIds>;

      curCategorySettings.forEach((x) => {
        const match = apiResponseSettings.find((y) => y.SettingType === x);
        if (match && sortedSettings.hasOwnProperty(curCategoryName)) {
          (
            sortedSettings[curCategoryName] as Map<
              CapsaSettingsTypeIds,
              SettingResponse
            >
          ).set(x, match);
        }
      });
    }

    return sortedSettings;
  }
}
