import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ApiResponse,
  CartPartialResponse,
  CartTransferRequest,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { SetupApi } from '@capsa/api/setup';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { SessionTimeoutService } from '@capsa/services/session-timeout/session-timeout.service';
import { xIcon } from '@progress/kendo-svg-icons';
import { CartTransferStatus } from 'app/modules/support/support-transfer-carts/cart-transfer-status';
import { EofTransferInfo } from 'app/modules/support/support-transfer-carts/eof-transfer-info';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cart-transfer-dialog',
  templateUrl: './cart-transfer-dialog.component.html',
  styleUrls: ['./cart-transfer-dialog.component.scss'],
})
export class CartTransferDialogComponent implements OnInit, OnDestroy {
  // Adds a class to the "host" HTML wrapper of this component
  @HostBinding('class') class = 'responsiveKendoDialog';

  @Input()
  private cartsToTransfer: CartPartialResponse[];

  @Input()
  public isEnterpriseTransfer: boolean;

  @Input()
  public eofTransferInfo: EofTransferInfo;

  @Output()
  public cancel = new EventEmitter();

  public icons = { xIcon: xIcon };

  public gridSelectedEntityIds: string[];

  private gridCartsToTransferBehSub = new BehaviorSubject<
    CartPartialResponse[]
  >([]);
  public gridCartsToTransferData$ =
    this.gridCartsToTransferBehSub.asObservable();

  private gridCartsProcessedBehSub = new BehaviorSubject<CartTransferStatus[]>(
    []
  );
  public gridCartsProcessedData$ = this.gridCartsProcessedBehSub.asObservable();

  private subs = new Subscription();

  /**
   * Minimum time between "request cycles" (i.e. at least X milliseconds must elapse between beginning
   * of request and the next request)
   */
  private readonly minThrottleTimeMs = 1250;

  private throttleTimerSub: Subscription;

  // If true, no new requests should be issued. Any in progress ones will be
  // allowed to complete (not like we can cancel them anyways...)
  public isPaused = false;

  /**
   * If true, then a cart transfer request has been made to the API and hasn't returned ANY response yet.
   */
  public requestPending = false;

  /**
   * If this is true, then it's ok to issue the next transfer request, if false, then
   * some condition is preventing the next request from being issued (e.g. minimum time
   * between requests hasn't elapsed)
   */
  public isThrottleTimeElapsed = true;

  public processingCartName: string;

  public totalCartCount = 0;
  public successCartCount = 0;
  public failCartCount = 0;

  public isStarted = false;

  public get doCartsRemainInQueue() {
    return this.gridCartsToTransferBehSub.value.length > 0;
  }

  public get isAllProcessingComplete() {
    return !this.doCartsRemainInQueue && !this.requestPending;
  }

  constructor(
    private setupApi: SetupApi,
    private cartApi: CartApi,
    private enterpriseService: EnterpriseService,
    private sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit() {
    this.gridCartsToTransferBehSub.next(this.cartsToTransfer);
    this.totalCartCount = this.cartsToTransfer.length;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    if (this.throttleTimerSub) {
      this.throttleTimerSub.unsubscribe();
    }
  }

  public onClose() {
    this.cancel.emit();
  }

  private transferNextCart() {
    if (this.isPaused || !this.doCartsRemainInQueue) {
      return;
    }
    this.sessionTimeoutService.registerEmulatedUserActivity();
    this.requestPending = true;

    const nextCart = this.gridCartsToTransferBehSub.value.shift();
    this.gridCartsToTransferBehSub.next(this.gridCartsToTransferBehSub.value);

    this.processingCartName = nextCart.Name;

    let transferObs$: Observable<ApiResponse<void>>;

    if (this.isEnterpriseTransfer) {
      transferObs$ = this.transferCrossEnterpriseSingleCart(nextCart);
    } else {
      transferObs$ = this.transferSameEnterpriseCart(nextCart);
    }

    const processedCart: CartTransferStatus = {
      Name: nextCart.Name,
      SerialNumber: nextCart.SerialNumber,
      IsTransferSuccess: true, // will flip to false in case fail
    };

    this.isThrottleTimeElapsed = false;
    this.throttleTimerSub = timer(this.minThrottleTimeMs).subscribe(() => {
      if (!this.requestPending && this.doCartsRemainInQueue && !this.isPaused) {
        this.transferNextCart();
      } else {
        this.isThrottleTimeElapsed = true;
      }
    });

    transferObs$
      .pipe(
        finalize(() => {
          this.processingCartName = null;
          this.requestPending = false;

          if (
            this.doCartsRemainInQueue &&
            this.isThrottleTimeElapsed &&
            !this.isPaused
          ) {
            this.transferNextCart();
          }
        })
      )
      .subscribe(
        () => {
          this.gridCartsProcessedBehSub.next([
            processedCart,
            ...this.gridCartsProcessedBehSub.value,
          ]);
          this.successCartCount++;
        },
        (error) => {
          processedCart.IsTransferSuccess = false;
          this.gridCartsProcessedBehSub.next([
            processedCart,
            ...this.gridCartsProcessedBehSub.value,
          ]);

          this.failCartCount++;
        }
      );
  }

  private transferSameEnterpriseCart(cartToTransfer: CartPartialResponse) {
    const request: CartTransferRequest = {
      EnterpriseId: this.enterpriseService.enterpriseId,
      SourceOrganizationId: this.eofTransferInfo.source.OrgId,
      SourceFacilityId: this.eofTransferInfo.source.FacilityId,
      TargetFacilityId: this.eofTransferInfo.target.FacilityId,
      TargetOrganizationId: this.eofTransferInfo.target.OrgId,
      TargetEnterpriseId: this.eofTransferInfo.target.EnterpriseId,
      CartId: cartToTransfer.Id,
      Name: cartToTransfer.Name,
    };
    return this.cartApi.transferCart(request);
  }

  private transferCrossEnterpriseSingleCart(
    cartToTransfer: CartPartialResponse
  ) {
    // There is code in the NexysSetupController in the API (the endpoint we're using in this method)
    // that decides whether to call a "cross enterprise transfer" or a "regular transfer"  method
    // depending on if the targetEnterpriseId is 0 or not in the request
    const targetEnterpriseIdFinal =
      this.eofTransferInfo.source.EnterpriseId ===
      this.eofTransferInfo.target.EnterpriseId
        ? 0
        : this.eofTransferInfo.target.EnterpriseId;

    const request: CartTransferRequest = {
      EnterpriseId: this.eofTransferInfo.source.EnterpriseId,
      SourceOrganizationId: this.eofTransferInfo.source.OrgId,
      SourceFacilityId: this.eofTransferInfo.source.FacilityId,
      TargetFacilityId: this.eofTransferInfo.target.FacilityId,
      TargetOrganizationId: this.eofTransferInfo.target.OrgId,
      TargetEnterpriseId: targetEnterpriseIdFinal,
      CartId: cartToTransfer.Id,
      Name: cartToTransfer.Name,
    };

    return this.setupApi.enterpriseTransferCart(request);
  }

  public onTogglePauseResume() {
    if (this.isPaused) {
      this.isPaused = false;

      if (
        !this.requestPending &&
        this.doCartsRemainInQueue &&
        this.isThrottleTimeElapsed
      ) {
        this.transferNextCart();
      }
    } else {
      this.isPaused = true;
    }
  }

  public onStartTransfer() {
    this.isStarted = true;
    this.transferNextCart();
  }
}
