import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capsa/services/auth/auth.service';
import initials from 'initials';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  public displayName: string;
  public displayNameInitials: string;
  public isAvatarMenuExpanded = false;

  @ViewChild('greetingText', { static: true }) greetingTextEl: ElementRef;

  constructor(private authService: AuthService, private router: Router) {}

  public isReady = false;

  ngOnInit() {
    this.displayName = this.authService.currentUser.DisplayName;
    this.setInitials();
  }

  private setInitials() {
    let userInits = initials(this.displayName);
    if (userInits.length > 2) {
      userInits = userInits[0] + userInits[userInits.length - 1];
    }
    this.displayNameInitials = userInits;
  }

  public toggleAvatarMenu() {
    this.isAvatarMenuExpanded = !this.isAvatarMenuExpanded;
  }

  goToProfile() {
    this.router.navigate(['account', 'profile']);
    this.isAvatarMenuExpanded = false;
  }

  logout() {
    this.authService.logout();
  }

  onClickedOutside(event: Event) {
    this.isAvatarMenuExpanded = false;
  }
}
