import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { DataDrivenSliderConfig } from 'app/modules/data-driven-controls/data-driven-control-styles';

@Component({
  selector: 'app-data-driven-slider',
  templateUrl: './data-driven-slider.component.html',
  styleUrls: ['./data-driven-slider.component.scss'],
})
export class DataDrivenSliderComponent {
  @Input()
  public setting: CapsaSettingApiModel;

  @Input()
  public config: DataDrivenSliderConfig;

  @Input()
  public disabled = false;

  @Output()
  public valueChanged = new EventEmitter<number>();

  get value() {
    return Number.parseFloat(this.setting.SettingValue);
  }

  constructor() {}

  onValueChanged(value: number) {
    this.setting.SettingValue = value.toString();
    this.valueChanged.emit(value);
  }
}
