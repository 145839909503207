import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-faded-text',
  templateUrl: './faded-text.component.html',
  styleUrls: ['./faded-text.component.scss'],
})
export class FadedInfoTextComponent {
  @Input()
  public textTag: string;

  constructor() {}
}
