import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NetworkAccessPointResponse } from '@capsa/api';
import { NetworkAccessPointApi } from '@capsa/api/network-access-point';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-device-tracking-access-point-list',
  templateUrl: './device-tracking-access-point-list.component.html',
  styleUrls: ['./device-tracking-access-point-list.component.scss'],
})
export class DeviceTrackingAccessPointListComponent
  implements OnInit, OnDestroy
{
  @Output()
  public accessPointChanged = new EventEmitter<
    NetworkAccessPointResponse | undefined
  >();

  public gridDataLoading: boolean;
  private gridDataBehSub = new BehaviorSubject<NetworkAccessPointResponse[]>(
    []
  );
  public gridData$ = this.gridDataBehSub.asObservable();
  public readonly pageSize = 100;
  public totalItems = 0;
  public skip = 0;

  public accessPoints: NetworkAccessPointResponse[];

  private subs = new Subscription();
  private apiSearchSub = new Subscription();

  constructor(
    private enterpriseService: EnterpriseService,
    private networkAccessPointApi: NetworkAccessPointApi,
    private toasterService: ToasterService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.resetPagingAndData();
    this.loadGridData();

    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        this.resetPagingAndData();
        this.loadGridData();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.apiSearchSub.unsubscribe();
  }

  private resetPagingAndData() {
    this.gridDataBehSub.next([]);
    this.skip = 0;
    this.totalItems = 0;
    this.gridDataLoading = false;
    this.accessPointChanged.emit(null);
  }

  public loadMore() {
    if (
      this.gridDataLoading ||
      this.gridDataBehSub.value.length >= this.totalItems
    ) {
      // Sometimes the grid triggers this method immediately after another, causing an
      // unfinished API call to get cancelled, while attempting to make another one,
      // this causes missing data in the grid
      // Also stop trying to hit API when all items are loaded
      return;
    }

    this.skip += this.pageSize;
    this.loadGridData();
  }

  public onSelectionChange(selectionEvent: SelectionEvent) {
    const selection = selectionEvent.selectedRows[0]
      ? (selectionEvent.selectedRows[0].dataItem as NetworkAccessPointResponse)
      : undefined;

    this.accessPointChanged.emit(selection);
  }

  private loadGridData() {
    this.apiSearchSub.unsubscribe();

    if (!this.permissionService.facilityId) {
      return;
    }

    this.gridDataLoading = true;
    const sub = this.networkAccessPointApi
      .search({
        EnterpriseId: this.enterpriseService.enterpriseId,
        PageSize: this.pageSize,
        PageNumber: this.skip / this.pageSize + 1,
        FacilityIds: [this.permissionService.facilityId],
      })
      .pipe(
        tap((apiResponse) =>
          apiResponse.Result.sort((a, b) => a.Name.localeCompare(b.Name))
        ),
        finalize(() => (this.gridDataLoading = false))
      )
      .subscribe(
        (resp) => {
          this.totalItems = resp.TotalRecordCount;

          if (this.skip === 0) {
            // If skip is 0, this is the first page and we don't do any appending of data
            this.gridDataBehSub.next(resp.Result);
          } else {
            this.gridDataBehSub.next([
              ...this.gridDataBehSub.value,
              ...resp.Result,
            ]);
          }
        },
        (_error) => {
          this.toasterService.showError('ACCESS_POINT_COULD_NOT_LOAD');
        }
      );

    this.subs.add(sub);
  }
}
