import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceTypeApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { DeviceTypeDropDownDataSource } from '@capsa/dropdowns/device-type-drop-down/device-type-drop-down-data-source';

@Component({
  selector: 'app-device-type-drop-down',
  templateUrl: './device-type-drop-down.component.html',
  styleUrls: ['./device-type-drop-down.component.scss'],
})
export class DeviceTypeDropDownComponent implements OnInit {
  @Input()
  readonly: boolean;

  @Input()
  readonlyText: string;

  @Input()
  public deviceTypeId?: number;

  @Input()
  public dataSource: DeviceTypeDropDownDataSource;

  @Output()
  valueChanged = new EventEmitter<AsyncDropDownValue<DeviceTypeApiModel>>();

  constructor() {}

  ngOnInit() {
    this.dataSource.deviceTypeId = this.deviceTypeId;
  }
}
