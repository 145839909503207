import { Injectable } from '@angular/core';
import { CapsaSettingApiModel } from '@capsa/api';
import { SettingMap } from 'app/modules/cart-profile/helpers';

@Injectable()
export class ApiSettingsFormHelper {
  public displaySettings: SettingMap;
  public batteryDisplaySettings: SettingMap;
  public brightnessSettings: SettingMap;
  public cartFeatureSettings: SettingMap;
  public cliSettings: SettingMap;
  public careLinkSettings: SettingMap;
  public loaded = false;

  constructor() {
    this.displaySettings = new SettingMap('DeviceProfile_Display');
    this.batteryDisplaySettings = new SettingMap('DeviceProfile_BatteryRange');
    this.cartFeatureSettings = new SettingMap('DeviceProfile_CartFeatures');
    this.brightnessSettings = new SettingMap('DeviceProfile_Brightness');
    this.cliSettings = new SettingMap('CLI');
    this.careLinkSettings = new SettingMap('CareLink');
  }

  public load(settings: CapsaSettingApiModel[]) {
    this.loaded = false;
    settings.forEach((setting) => {
      this.displaySettings.tryAddSetting(setting.DisplayName, setting);
      this.brightnessSettings.tryAddSetting(setting.DisplayName, setting);
      this.batteryDisplaySettings.tryAddSetting(setting.DisplayName, setting);
      this.cartFeatureSettings.tryAddSetting(setting.DisplayName, setting);
      this.cliSettings.tryAddSetting(setting.DisplayName, setting);
      this.careLinkSettings.tryAddSetting(setting.DisplayName, setting);
    });
    this.loaded = true;
  }

  public flatten(): CapsaSettingApiModel[] {
    return []
      .concat(this.displaySettings.flatten())
      .concat(this.brightnessSettings.flatten())
      .concat(this.batteryDisplaySettings.flatten())
      .concat(this.cartFeatureSettings.flatten())
      .concat(this.cliSettings.flatten())
      .concat(this.careLinkSettings.flatten());
  }
}
