import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceProfileCreateRequestApiModel, DeviceTypeIds } from '@capsa/api';
import { CartProfileApi } from '@capsa/api/cart-profile';
import { SettingsApi } from '@capsa/api/settings';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';
import { GridFilterConfiguration } from 'app/modules/cart-profile/helpers';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cart-profile-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss'],
})
export class CreatePageComponent implements OnInit, OnDestroy {
  public filters: GridFilterConfiguration = {
    deviceTypeId: DeviceTypeIds.CareLink_2,
    facilityId: undefined,
    organizationId: undefined,
  };

  public saving = false;

  public form: UntypedFormGroup;

  get isReady() {
    return this.settingsFormHelper.loaded;
  }

  private subs = new Subscription();

  constructor(
    private api: CartProfileApi,
    private settingsApi: SettingsApi,
    public settingsFormHelper: ApiSettingsFormHelper,
    private router: Router,
    private toasterService: ToasterService,
    public loaderService: LoaderService
  ) {
    this.save = this.save.bind(this);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public loadDefaultSettings(newFilters: GridFilterConfiguration) {
    this.loaderService.start();
    this.settingsApi
      .getDeviceTypeSettings(DeviceTypeIds.CareLink_2)
      .pipe(
        finalize(() => {
          this.loaderService.stop();
        })
      )
      .subscribe(
        (resp) => {
          this.settingsFormHelper.load(resp.Result);
          this.filters = newFilters;
        },
        (error) => {
          this.toasterService.showError('COM_UNKNOWN_API_ERROR');
        }
      );
  }

  public save(model: DeviceProfileCreateRequestApiModel) {
    this.saving = true;
    const taskId = 'saveProfile';
    this.loaderService.start(taskId);
    this.subs.add(
      this.api.create(model).subscribe(
        (resp) => {
          this.toasterService.showSuccess('DEVICE_PROFILE_CREATED_SUCCESS');
          this.saving = false;
          this.loaderService.stop(taskId);
          this.router.navigate(['facilities', 'profiles', 'edit', resp.Result]);
        },
        (error) => {
          this.toasterService.showError('DEVICE_PROFILE_CREATE_ERROR');
          this.loaderService.stop(taskId);
          this.saving = false;
        }
      )
    );
  }
}
