import { Pipe, PipeTransform } from '@angular/core';
import { UpdateStatusType } from '@capsa/api';

@Pipe({
  name: 'updateStatusIcon',
})
export class UpdateStatusIconPipe implements PipeTransform {
  transform(value: UpdateStatusType, ...args: any[]): any {
    switch (value) {
      case UpdateStatusType.UpToDate:
        return 'check';
      case UpdateStatusType.NeedsUpdate:
        return 'update';
      case UpdateStatusType.UpdatePending:
        return 'sync_alt';
      default:
        return '-';
    }
  }
}
