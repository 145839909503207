import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, BlobFileRequest } from '@capsa/api';
import { BaseApi } from '@capsa/api/base/base.api';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { Observable } from 'rxjs';

export interface BlobStorageApiInstance {
  getFileUrl(request: BlobFileRequest): Observable<ApiResponse<string>>;
}

@Injectable()
export class BlobStorageApi extends BaseApi {
  protected endpoints = {
    getFileUrl: this.buildUrl('storage/url'),
  };

  constructor(client: HttpClient, appSettingService: AppSettingsService) {
    super(client, appSettingService);
  }

  public getFileUrl(request: BlobFileRequest): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(
      this.endpoints.getFileUrl,
      request
    );
  }
}
