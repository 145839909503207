import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MessageSubjectPresetCreateRequest,
  MessageSubjectPresetResponse,
} from '@capsa/api';
import { MessageSubjectPresetApi } from '@capsa/api/message-subject-preset';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-subject-preset-manage',
  templateUrl: './subject-preset-manage.component.html',
  styleUrls: ['./subject-preset-manage.component.scss'],
})
export class SubjectPresetManageComponent implements OnInit, OnDestroy {
  public organizationId: number | undefined;

  public gridPresetList: GridDataResult = { data: [], total: 0 };
  public loadingData = true;

  private subs: Subscription = new Subscription();
  public get hasMultipleOrgs() {
    return this.userEofCacheService.userHasMultipleOrgs;
  }

  private editedRowIndex: number;
  private backupPreset: MessageSubjectPresetResponse;

  public canEdit = false;

  constructor(
    private api: MessageSubjectPresetApi,
    private userEofCacheService: UserEofCacheService,
    private permissionService: PermissionService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (!this.hasMultipleOrgs) {
      // Because this sets public state on the component from within ngOnInit,
      // we need to run this code on the next render check cycle instead of during
      // initial rending. If we don't do this, we get a change detection error.
      setTimeout(() => {
        const availableOrg = this.userEofCacheService.getFirstOrg();
        this.organizationId = availableOrg.Id;
        this.refreshPresetList();
      }, 0);
    } else {
      this.refreshPresetList();
    }
    this.loadPermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.filtersChanged();
  }

  private loadPermissions() {
    this.subs.add(
      this.permissionService
        .hasAny$([Permissions.MessageQueueSubjectPreset_ReadWrite])
        .subscribe((hasPerms) => (this.canEdit = hasPerms))
    );
  }

  private filtersChanged() {
    this.refreshPresetList();
  }

  private refreshPresetList() {
    if (!this.organizationId) {
      return;
    }

    const sub = this.api
      .searchPreset({
        OrganizationIds: [this.organizationId],
        PageNumber: 1,
        PageSize: 1000,
      })
      .pipe(finalize(() => (this.loadingData = false)))
      .subscribe(
        (resp) => {
          this.gridPresetList = {
            data: resp.Result,
            total: resp.TotalRecordCount,
          };
        },
        (error) => {
          this.toasterService.showError('COM_UNKNOWN_API_ERROR');
        }
      );
    this.subs.add(sub);
  }

  public addHandler({ sender }) {
    this.closeEditor(sender);

    const newRow: MessageSubjectPresetCreateRequest = {
      Description: '',
      OrganizationId: this.organizationId,
    };
    sender.addRow(newRow);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.backupPreset = Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }) {
    this.loadingData = true;
    if (isNew) {
      const sub = this.api
        .createPreset({
          Description: dataItem.Description,
          OrganizationId: dataItem.OrganizationId,
        })
        .subscribe(
          (resp) => {
            this.refreshPresetList();
          },
          (error) => {
            this.toasterService.showError('CREATE_FAILED');
            this.loadingData = false;
          }
        );
      this.subs.add(sub);
    } else {
      const sub = this.api
        .updatePreset(dataItem.MessageQueueSubjectPresetId, {
          Description: dataItem.Description,
          OrganizationId: dataItem.OrganizationId,
        })
        .subscribe(
          (resp) => {
            this.refreshPresetList();
          },
          (error) => {
            this.toasterService.showError('UPDATE_FAILED');
            this.loadingData = false;
          }
        );
      this.subs.add(sub);
    }
    sender.closeRow(rowIndex);

    this.editedRowIndex = undefined;
    this.backupPreset = undefined;
  }

  public removeHandler({
    dataItem,
  }: {
    dataItem: MessageSubjectPresetResponse;
  }) {
    this.loadingData = true;
    const sub = this.api
      .deletePreset(dataItem.MessageQueueSubjectPresetId)
      .subscribe(
        (resp) => {
          this.refreshPresetList();
        },
        (error) => {
          this.toasterService.showError('DELETE_FAILED');
          this.loadingData = false;
        }
      );
    this.subs.add(sub);
  }

  private restoreBackedUpItem() {
    if (
      !this.backupPreset ||
      this.backupPreset.MessageQueueSubjectPresetId > 0
    ) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.gridPresetList.data.find(
      (item) =>
        item.MessageQueueSubjectPresetId ===
        this.backupPreset.MessageQueueSubjectPresetId
    );

    // revert changes
    Object.assign(originalDataItem, this.backupPreset);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);

    this.restoreBackedUpItem();

    this.editedRowIndex = undefined;
    this.backupPreset = undefined;
  }
}
