import { Component, Input } from '@angular/core';
import { CapsaSettingsTypeIds, SettingResponse } from '@capsa/api';

@Component({
  selector: 'app-facility-manage-login-required',
  templateUrl: './facility-manage-login-required.component.html',
  styleUrls: ['./facility-manage-login-required.component.scss'],
})
export class FacilityManageLoginRequiredComponent {
  @Input()
  settings: Map<CapsaSettingsTypeIds, SettingResponse>;

  // To expose the enum to the template
  public settingTypeEnum = CapsaSettingsTypeIds;

  constructor() {}
}
