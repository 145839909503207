import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CapsaSettingsTypeIds,
  CartGroupResponse,
  CartPartialResponse,
  Colors,
  DeviceTypeIds,
  MessageQueueDefaultColors,
  MessageQueueMessageType,
  MessageQueueUserType,
  MessageTemplateResponse,
  UserPartialResponse,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { CartGroupApi } from '@capsa/api/cart-group';
import { MessageApi } from '@capsa/api/message';
import { UserApi } from '@capsa/api/user';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import {
  ColorCombo,
  isValidColorCombo,
} from '@capsa/dropdowns/color-combo-drop-down/color-combo-helper';
import { DropDownItem } from '@capsa/dropdowns/enum';
import { MessageTemplateDropDownDataSource } from '@capsa/dropdowns/message-templates-drop-down/message-templates-drop-down-datasource';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { LoaderService } from '@capsa/services/loader/loader.service';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  State,
  process,
} from '@progress/kendo-data-query';
import { Utils } from 'app/common/utils';
import {
  GridCartSelectionState,
  GridUserSelectionState,
} from 'app/modules/capsa-dialogs/capsa-dialogs-interfaces';
import { BehaviorSubject, Subject, Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public subject = new UntypedFormControl();
  public body = '';
  public readonly defaultForeground = MessageQueueDefaultColors.Foreground;
  public readonly defaultBackground = MessageQueueDefaultColors.Background;
  public foreground: number = this.defaultForeground;
  public background: number = this.defaultBackground;
  public messageType = MessageQueueMessageType.Notification;

  public cartsLoading = false;
  public usersLoading = false;

  public selectedCartsGridData: GridDataResult;
  public selectedUsersGridData: GridDataResult;
  public cartFilter: CompositeFilterDescriptor;
  public cartSort: SortDescriptor[];
  public canEdit = false;

  public userFilter: CompositeFilterDescriptor;
  public userSort: SortDescriptor[];

  public deviceTypeId = DeviceTypeIds.CareLink_2;

  public cartsAvailable: CartPartialResponse[] = [];
  public cartsSelected: CartPartialResponse[] = [];

  public cartGroups: CartGroupResponse[];

  public cartDataLoading = false;

  public usersAvailable: UserPartialResponse[] = [];
  public usersSelected: UserPartialResponse[] = [];

  public isCartsDialogOpen = false;
  public isUsersDialogOpen = false;

  public readonly pageSize = 250;

  public selectedCartsState: State = Utils.getDefaultState(this.pageSize);

  public selectedUsersState: State = Utils.getDefaultState(this.pageSize);

  // To expose the enum to the template
  public messageQueueMessageType = MessageQueueMessageType;
  public expirationDate = new Date();
  public minDate = new Date();

  private setColorComboSubj = new BehaviorSubject<ColorCombo | undefined>(
    undefined
  );
  public setColorCombo$ = this.setColorComboSubj.asObservable();

  public organizationId: number | undefined;
  public facilityId: number | undefined;

  public get isFormValid() {
    return this.form.valid && (this.areUsersSelected || this.areCartsSelected);
  }

  private get areUsersSelected() {
    return this.usersSelected && this.usersSelected.length > 0;
  }

  private get areCartsSelected() {
    return this.cartsSelected && this.cartsSelected.length > 0;
  }

  private refreshCartsSubj = new Subject();
  private refreshCarts$ = this.refreshCartsSubj.asObservable();

  private cartsSub = new Subscription();
  private usersSub = new Subscription();

  private subs = new Subscription();

  public messageTypeValues: DropDownItem[] = [
    {
      Label: this.translations.instant('Notification'),
      Value: MessageQueueMessageType.Notification,
    },
    {
      Label: this.translations.instant('Alert'),
      Value: MessageQueueMessageType.Alert,
    },
  ];

  public canViewPage = false;
  private pagePerms: Permissions[] = [Permissions.CLI_MenuAccess_Messages];

  constructor(
    private messageApi: MessageApi,
    private cartApi: CartApi,
    private cartGroupApi: CartGroupApi,
    private userApi: UserApi,
    private translations: TranslateService,
    public templateDataSource: MessageTemplateDropDownDataSource,
    private permissionService: PermissionService,
    public loaderService: LoaderService,
    private toasterService: ToasterService,
    private enterpriseService: EnterpriseService
  ) {}

  public ngOnInit() {
    this.loadPermissions();

    this.expirationDate.setDate(this.expirationDate.getDate() + 1);

    this.subject = new UntypedFormControl(null, {
      validators: Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
      ]),
    });

    this.form = new UntypedFormGroup({
      subject: this.subject,
    });

    this.subs.add(
      this.refreshCarts$.subscribe(() => {
        this.refreshLists();
      })
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    this.cartsSub.unsubscribe();
    this.usersSub.unsubscribe();
  }

  private loadPermissions() {
    const editPerm = Permissions.CLI_Messages_ReadWrite;
    this.subs.add(
      this.permissionService.permissionsUpdated$.subscribe(() => {
        if (this.permissionService.hasAny(this.pagePerms)) {
          this.canViewPage = true;
          this.clearLists();
          this.refreshCartsSubj.next();
        } else {
          this.canViewPage = false;
        }

        this.canEdit = this.permissionService.has(editPerm);
      })
    );

    if (!this.permissionService.hasAny(this.pagePerms)) {
      return;
    }

    this.canViewPage = true;
    this.canEdit = this.permissionService.has(editPerm);
  }

  public onOrgChanged(newValue: number | undefined) {
    this.organizationId = newValue;
    this.onOrgOrFacilityChanged();
  }

  public onFacilityChanged(newValue: number | undefined) {
    this.facilityId = newValue;
    this.onOrgOrFacilityChanged();
  }

  public onTypeChanged(newValue: DropDownItem) {
    this.messageType = newValue.Value as number;

    // If switching back to notification type, reset colors
    if (this.messageType === MessageQueueMessageType.Notification) {
      this.background = MessageQueueDefaultColors.Background;
      this.foreground = MessageQueueDefaultColors.Foreground;
    }
  }

  public alertColorChanged(newValue: ColorCombo) {
    this.foreground = newValue.fgColor;
    this.background = newValue.bgColor;
  }

  public onBodyChanged(newValue: string) {
    this.body = newValue;
  }

  /**
   * Handles cases where the database is storing values that
   * are no longer supported by the colors drop down, or if the type is
   * "Notification" and has non default colors (old functionality)
   */
  private verifyAndSetValidColors(
    fgColor: number,
    bgColor: number,
    msgType: MessageQueueMessageType
  ): void {
    if (
      !isValidColorCombo(fgColor, bgColor) ||
      msgType === MessageQueueMessageType.Notification
    ) {
      this.foreground = Colors.Black;
      this.background = Colors.White;
      return;
    }

    this.foreground = fgColor;
    this.background = bgColor;
  }

  private resetColors(): void {
    this.foreground = Colors.Black;
    this.background = Colors.White;
    this.setColorComboSubj.next({
      fgColor: Colors.Black,
      bgColor: Colors.White,
    });
  }

  public onTemplateChanged(
    template: AsyncDropDownValue<MessageTemplateResponse>
  ) {
    if (template && template.apiModel) {
      this.subject.setValue(template.apiModel.Subject);
      this.body = template.apiModel.Body;
      this.messageType =
        MessageQueueMessageType[template.apiModel.MessageQueueMessageType];

      this.verifyAndSetValidColors(
        template.apiModel.ForegroundColor,
        template.apiModel.BackgroundColor,
        this.messageType
      );

      this.setColorComboSubj.next({
        fgColor: this.foreground,
        bgColor: this.background,
      });
    }
  }
  // Applies a class to each Grid row to aid overriding CSS
  public rowCallback(context: RowClassArgs) {
    return { gridRowOverride: true };
  }

  private onOrgOrFacilityChanged() {
    // if device type is set AND update type set
    // then enable/show versions list
    // if version is selected, show carts list
    this.refreshCartList();
    this.refreshUserList();
    this.refreshTemplates();
  }

  private refreshLists() {
    this.refreshCartList();
    this.refreshUserList();
  }

  private clearLists() {
    this.cartGroups = [];
    this.cartsSelected = [];
    this.cartsAvailable = [];
    this.usersAvailable = [];
    this.usersSelected = [];
    this.reprocessCarts();
    this.reprocessUsers();
  }

  private refreshCartList() {
    this.cartsSub.unsubscribe();

    // Clears selection and refresh grid
    this.cartsSelected = [];
    this.reprocessCarts();

    if (!this.permissionService.facilityId || !this.deviceTypeId) {
      return;
    }

    this.cartDataLoading = true;
    this.cartsSub = forkJoin([this.getCarts(), this.getCartGroups()])
      .pipe(
        finalize(() => {
          this.cartDataLoading = false;
        })
      )
      .subscribe(
        (responses) => {
          this.cartsAvailable = responses[0];

          // Refresh grid with latest data
          this.reprocessCarts();

          this.cartGroups = responses[1].Result;
        },
        (_error) => {
          this.toasterService.showError(
            'ERR_GETTING_LISTS_OF_CARTS_AND_GROUPS'
          );
        }
      );
  }

  private getCarts() {
    return this.cartApi.searchCartsPartial(
      { PageNumber: 1, PageSize: 20000 },
      {
        OrganizationIds: [this.permissionService.orgId],
        FacilityIds: [this.permissionService.facilityId],
        DeviceTypeId: this.deviceTypeId,
        CapsaSettings: [
          {
            Key: CapsaSettingsTypeIds.CLI_CartIsActive,
            Value: '1',
          },
        ],
      }
    );
  }

  private getCartGroups() {
    return this.cartGroupApi.search({
      EnterpriseId: this.enterpriseService.enterpriseId,
      OrganizationIds: [this.permissionService.orgId],
      FacilityIds: [this.permissionService.facilityId],
      PageNumber: 1,
      PageSize: 10000,
    });
  }

  private refreshUserList() {
    // Cancel any pending API call before making another
    // In case this refresh happened due to a "deselect", we want to make sure data doesn't come in
    // after we've went back to an "unselected" filter (prevents race condition bugs)

    this.usersSub.unsubscribe();

    // Clears selection and refresh grid
    this.usersSelected = [];
    this.reprocessUsers();

    if (!this.permissionService.facilityId || !this.deviceTypeId) {
      return;
    }

    this.usersLoading = true;

    this.usersSub = this.userApi
      .getUserList(
        this.permissionService.orgId,
        this.permissionService.facilityId,
        false, // includeInactive
        undefined, // pageSize
        undefined, // pageNumber
        [CapsaSettingsTypeIds.CLI_IsCartUserActive],
        this.deviceTypeId
      )
      .pipe(
        finalize(() => {
          this.usersLoading = false;
        })
      )
      .subscribe(
        (resp) => {
          this.usersAvailable = resp.Result.filter((u) => {
            return u.IsDeviceUser;
          }).map((r) => ({
            ...r,
          }));

          // Refresh grid with latest data
          this.reprocessUsers();
        },
        (error) => {
          this.toasterService.showError('USER_LIST_LOAD_FAIL');
        }
      );
  }

  private refreshTemplates() {
    this.templateDataSource.facilityId = this.facilityId;
    this.templateDataSource.organizationId = this.organizationId;
    if (this.facilityId && this.organizationId) {
      this.subs.add(
        this.templateDataSource.resetAndLoad().subscribe((resp) => {
          this.templateDataSource.data = resp;
          this.onTemplateChanged(undefined);
        })
      );
    } else {
      this.templateDataSource.reset();
    }
  }

  public sendMessage() {
    this.loaderService.start();
    this.subs.add(
      this.messageApi
        .sendMessage({
          MessageType: this.messageType,
          FacilityId: this.facilityId,
          OrganizationId: this.organizationId,
          SenderUserType: MessageQueueUserType.User,
          BackgroundColor: this.background,
          ForegroundColor: this.foreground,
          Body: this.body,
          Subject: this.subject.value,
          CartIds: this.cartsSelected.map((c) => c.Id),
          UserIds: this.usersSelected.map((u) => u.Id),
          SentDateTimeUtc: new Date(),
          ExpirationDateTimeUtc: this.expirationDate,
        })
        .pipe(
          finalize(() => {
            this.loaderService.stop();
          })
        )
        .subscribe(
          (_result) => {
            this.toasterService.showSuccess('MESSAGE_SUCCESS');
            this.resetFormState();
          },
          (_error) => {
            this.toasterService.showError('COM_UNKNOWN_API_ERROR');
          }
        )
    );
  }

  private resetFormState(): void {
    this.body = '';
    this.subject.reset();

    this.selectedCartsState = Utils.getDefaultState(this.pageSize);
    this.selectedUsersState = Utils.getDefaultState(this.pageSize);

    this.cartsAvailable.push(...this.cartsSelected);
    this.cartsSelected.length = 0;

    this.usersAvailable.push(...this.usersSelected);
    this.usersSelected.length = 0;

    this.reprocessCarts();
    this.reprocessUsers();

    this.resetColors();
    this.templateDataSource.selectDefaultItem();
    this.messageType = MessageQueueMessageType.Notification;
  }

  public openCartsDialog() {
    this.isCartsDialogOpen = true;
  }

  public openUsersDialog() {
    this.isUsersDialogOpen = true;
  }

  /**
   * updates the grid with latest data
   */
  private reprocessCarts() {
    this.selectedCartsGridData = process(
      this.cartsSelected,
      this.selectedCartsState
    );
  }

  /**
   * updates the grid with latest data
   */
  private reprocessUsers() {
    this.selectedUsersGridData = process(
      this.usersSelected,
      this.selectedUsersState
    );
  }
  public onSavedCartSelection(selections: GridCartSelectionState) {
    this.cartsAvailable = selections.avail;
    this.cartsSelected = selections.selected;
    this.selectedCartsState = Utils.getDefaultState(this.pageSize);
    this.isCartsDialogOpen = false;
    this.reprocessCarts();
  }

  public onSavedUserSelection(selections: GridUserSelectionState) {
    this.usersAvailable = selections.avail;
    this.usersSelected = selections.selected;
    this.selectedUsersState = Utils.getDefaultState(this.pageSize);
    this.isUsersDialogOpen = false;
    this.reprocessUsers();
  }

  public selectedCartsStateChange(state: DataStateChangeEvent): void {
    this.selectedCartsState = state;
    this.selectedCartsGridData = process(
      this.cartsSelected,
      this.selectedCartsState
    );
  }

  public selectedUsersStateChange(state: DataStateChangeEvent): void {
    this.selectedUsersState = state;
    this.selectedUsersGridData = process(
      this.usersSelected,
      this.selectedUsersState
    );
  }
}
