import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { KeyValuePair } from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-management-debug',
  templateUrl: './cart-management-debug.component.html',
  styleUrls: ['./cart-management-debug.component.scss'],
})
export class CartManagementDebugComponent implements OnInit, OnDestroy {
  @Input()
  public debugData: Map<string, string>;

  @Input()
  public cartId: number;

  public gridData: KeyValuePair<string, string>[] = [];

  private subs: Subscription = new Subscription();

  // Only let them send log requests once.
  public logRequestSent = false;

  public logRequestSending = false;

  constructor(
    private cartApi: CartApi,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.debugData.forEach((Value, Key) => {
      this.gridData.push({ Key, Value });
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onRequestLogs() {
    this.logRequestSending = true;
    const sub = this.cartApi.requestDebugLogs(this.cartId).subscribe(
      (s) => {
        this.logRequestSending = false;
        this.logRequestSent = true;
        this.toasterService.showSuccess('DEBUG_LOGS_REQUESTED');
      },
      (err) => {
        this.logRequestSending = false;
        this.toasterService.showError('DEBUG_LOGS_ERROR');
      }
    );
    this.subs.add(sub);
  }
}
