export class GetAuthResponse {
  AccessToken: string;
  UserName: string;
  DisplayName: string;
  EnterpriseId: number;
  // eslint-disable-next-line
  unique_name: string;
  UserId: string;

  EnterpriseAdmin?: boolean;
}
