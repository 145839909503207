import { Injectable } from '@angular/core';
import { GridSelectionService } from '@capsa/services/grid-selection/grid-selection.service';
import { RowArgs } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root',
})
export class GridSelectionServiceFactory {
  build(idSelector: (rows: RowArgs[]) => any[]) {
    return new GridSelectionService(idSelector);
  }
}
