import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';

export class NsightCustomValidators {
  public static dontAllowOnlyWhiteSpace(): ValidatorFn {
    return (ctl: UntypedFormControl) => {
      if (!ctl.dirty) {
        return null;
      }

      // is null or whitespace only
      if (ctl.value === null || ctl.value.trim() === '') {
        return { isNullOrWhiteSpace: true };
      }

      return null;
    };
  }

  public static isEmailExtraChecksValid(): ValidatorFn {
    return (ctl: AbstractControl) => {
      if (ctl.value) {
        // Regex focuses on things AFTER the @ symbol only
        const regex = new RegExp(/^.*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,256}$/i);

        // test the value of the control against the regexp supplied
        const isValid = regex.test(ctl.value);
        if (!isValid) {
          return {
            // Using the same error name as the angular email format invalid, since it's the same type of error
            email: true,
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  public static dateMin(minDate: string, format: string): ValidatorFn {
    return (dateControl: AbstractControl<Date>): ValidationErrors | null => {
      if (dateControl.value === null) {
        return null;
      }

      const controlDate = moment(dateControl.value, format);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(minDate);

      return controlDate.isSameOrAfter(validationDate)
        ? null
        : {
            'date-minimum': {
              'date-minimum': validationDate.format(format),
              actual: controlDate.format(format),
            },
          };
    };
  }

  public static dateMax(maxDate: string, format: string): ValidatorFn {
    return (dateControl: AbstractControl<Date>): ValidationErrors | null => {
      if (dateControl.value === null) {
        return null;
      }

      const controlDate = moment(dateControl.value, format);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(maxDate);

      return controlDate.isSameOrBefore(validationDate)
        ? null
        : {
            'date-maximum': {
              'date-maximum': validationDate.format(format),
              actual: controlDate.format(format),
            },
          };
    };
  }
}
