import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartManagementService {
  constructor() {}

  private validGeneralSettingsBehSubj = new BehaviorSubject<boolean>(true);
  public validGeneralSettings$ =
    this.validGeneralSettingsBehSubj.asObservable();

  private validDebugSettingsSubj = new Subject<boolean>();

  public validDebugSettings$ = this.validDebugSettingsSubj
    .asObservable()
    .pipe(delay(0));

  public updateDebugSettingsValidation(isValid: boolean) {
    this.validDebugSettingsSubj.next(isValid);
  }

  public updateGeneralSettingsValidation(isValid: boolean) {
    this.validGeneralSettingsBehSubj.next(isValid);
  }
}
