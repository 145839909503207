import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { EnterpriseService } from '@capsa/services/enterprise/enterprise.service';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  MessageQueueRequestTypeCreateRequest,
  MessageQueueRequestTypeResponse,
  MessageQueueRequestTypeSearchRequest,
  MessageQueueRequestTypeUpdateRequest,
} from '..';
import { BaseApi } from '../base/base.api';

export interface MessageRequestTypeApiInstance {
  searchRequestTypes(
    orgId: number,
    facilityId: number
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>>;

  createRequestType(
    organizationId: number,
    facilityId: number,
    description: string,
    acknowledgementThreshold: number
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse>>;

  updateRequestType(
    id: number,
    description: string,
    acknowledgementThreshold
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse>>;

  deleteRequestType(id: number): Observable<ApiResponse<void>>;

  getUserRequestTypes(
    userId: string
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>>;

  updateUserRequestTypes(
    userId: string,
    facilityId: number,
    requestTypeIds: number[]
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>>;
}

@Injectable()
export class MessageRequestTypeApi
  extends BaseApi
  implements MessageRequestTypeApiInstance
{
  protected endpoints = {
    createRequestType: this.buildUrl('message-queue-request-type'),
    updateRequestType: this.buildUrl('message-queue-request-type'),
    deleteRequestType: this.buildUrl('message-queue-request-type/{Id}'),
    searchRequestTypes: this.buildUrl('message-queue-request-type/search'),
    userRequestTypes: this.buildUrl('user/{Id}/service-request-types'),
  };

  private enterpriseId: number;

  constructor(
    client: HttpClient,
    enterpriseService: EnterpriseService,
    appSettingService: AppSettingsService
  ) {
    super(client, appSettingService);
    this.enterpriseId = enterpriseService.enterpriseId;
  }

  public updateRequestType(
    id: number,
    description: string,
    acknowledgementThreshold: any
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse>> {
    const body: MessageQueueRequestTypeUpdateRequest = {
      Id: id,
      Description: description,
      AcknowledgementThreshold: acknowledgementThreshold,
    };

    return this.http.patch<ApiResponse<MessageQueueRequestTypeResponse>>(
      this.endpoints.createRequestType,
      body
    );
  }

  public deleteRequestType(id: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(
      this.endpoints.deleteRequestType.replace('{Id}', id.toString())
    );
  }

  public createRequestType(
    organizationId: number,
    facilityId: number,
    description: string,
    acknowledgementThreshold: number
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse>> {
    const body: MessageQueueRequestTypeCreateRequest = {
      EnterpriseId: this.enterpriseId,
      OrganizationId: organizationId,
      FacilityId: facilityId,
      Description: description,
      AcknowledgementThreshold: acknowledgementThreshold,
    };

    return this.http.post<ApiResponse<MessageQueueRequestTypeResponse>>(
      this.endpoints.createRequestType,
      body
    );
  }

  public searchRequestTypes(
    orgId: number,
    facilityId: number
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>> {
    const body: MessageQueueRequestTypeSearchRequest = {
      Ids: [],
      Descriptions: [],
      AcknowledgementThresholds: [],
      EnterpriseId: this.enterpriseId,
      OrganizationId: orgId,
      FacilityId: facilityId,
      PageNumber: 1,
      PageSize: 1000,
    };

    return this.http.post<ApiResponse<MessageQueueRequestTypeResponse[]>>(
      this.endpoints.searchRequestTypes,
      body
    );
  }

  public getUserRequestTypes(
    userId: string
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>> {
    return this.http.get<ApiResponse<MessageQueueRequestTypeResponse[]>>(
      this.endpoints.userRequestTypes.replace('{Id}', userId.toString())
    );
  }

  public updateUserRequestTypes(
    userId: string,
    facilityId: number,
    requestTypeIds: number[]
  ): Observable<ApiResponse<MessageQueueRequestTypeResponse[]>> {
    return this.http.put<ApiResponse<MessageQueueRequestTypeResponse[]>>(
      this.endpoints.userRequestTypes.replace('{Id}', userId.toString()),
      {
        FacilityId: facilityId,
        RequestTypeIds: requestTypeIds,
      }
    );
  }
}
