import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ApiResponse,
  CapsaSettingApiModel,
  CapsaSettingsTypeIds,
  CartResponse,
} from '@capsa/api';
import { CartApi } from '@capsa/api/cart';
import { PermissionService } from '@capsa/services/permission/permission.service';
import { Permissions } from '@capsa/services/permission/permissions-enum';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-trio-cart-management-general-tab',
  templateUrl: './trio-cart-management-general-tab.component.html',
  styleUrls: ['./trio-cart-management-general-tab.component.scss'],
})
export class TrioCartManagementGeneralTabComponent
  implements OnInit, OnDestroy
{
  private subs = new Subscription();

  public settings = new Map<CapsaSettingsTypeIds, CapsaSettingApiModel>();
  public canShowAdminPin: boolean;
  public isDeviceConnected;
  public lastConnectionTime;


  public pinLoading = false;
  public adminPin = '';

  public get serialNumber() {
    return this.device.SerialNumber;
  }

  public settingTypeEnum = CapsaSettingsTypeIds;

  constructor(
    @Inject('device')
    public device: CartResponse,
    private permissionService: PermissionService,
    private cartApi: CartApi,
    private translateService: TranslateService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (this.permissionService.has(Permissions.CLI_Carts_ReadWrite)) {
      this.canShowAdminPin = true;
      this.loadAdminPin();
    }
    this.loadSettings();
    this.setIsDeviceConnected();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadSettings() {
    const settingsToUpdate = [
      CapsaSettingsTypeIds.CLI_CartIsActive,
      CapsaSettingsTypeIds.CLI_CartName,
      CapsaSettingsTypeIds.CLI_NotificationLightColor,
      CapsaSettingsTypeIds.CLI_NotificationLightIntensity,
      CapsaSettingsTypeIds.CLI_BlinkNotificationLight,
      CapsaSettingsTypeIds.CLI_IsNotificationLightOn,
      CapsaSettingsTypeIds.CLI_NotificationLightBlinkFrequency,
    ];

    this.device.Settings.forEach((s) => {
      if (settingsToUpdate.indexOf(s.SettingType) > -1) {
        this.settings.set(s.SettingType, s);
      }
    });
  }

  private loadAdminPin() {
    this.pinLoading = true;
    const sub = this.cartApi
      .getAdminPin(this.device.CartId)
      .pipe(finalize(() => (this.pinLoading = false)))
      .subscribe(
        (response: ApiResponse<string>) => {
          this.adminPin = response.Result;
        },
        (e) => {
          this.toasterService.showError('PIN_LOAD_FAILED');

          this.adminPin = this.translateService.instant(
            'COM_NOT_AVAILABLE_SHORT'
          );
        }
      );

    this.subs.add(sub);
  }

  public onDeviceProfileChanged(deviceProfileId: number) {
    this.device.DeviceProfileId = deviceProfileId;
  }

  public onNameChanged(newValue: string) {
    this.device.Name = newValue;
  }

  private setIsDeviceConnected() {
    const syncInterval = this.device.Settings.find(
      (s) =>
        s.SettingType === CapsaSettingsTypeIds.CLI_SyncIntervalTimeInMinutes
    );

    if (!syncInterval || !this.device.LastImAliveUtc) {
      return false;
    }

    const parsedSyncInterval = Number.parseInt(syncInterval.SettingValue, 10);

    // a device is considered not connected if a whole power sync interval
    // has lapsed with no communication from the device.
    const expectedConnectionTime = moment
      .utc()
      .add(parsedSyncInterval * -2, 'minutes');

    const lastConnectionTime = moment.utc(this.device.LastImAliveUtc);

    this.lastConnectionTime = lastConnectionTime.local().toDate();

    this.isDeviceConnected =
      expectedConnectionTime.isBefore(lastConnectionTime);
  }
}
