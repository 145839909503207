import { Injectable } from '@angular/core';
import { NgxUiLoaderService, Time } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  /***
   * Set just before starting a loader to display custom text below the "animation"
   */
  private _text: string;
  private textTaskId: string;
  private _isLoading: boolean;

  /**
   * Should only be used for templates to determine if the loader is active or not
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get text(): string {
    return this._text;
  }

  constructor(public ngxLoader: NgxUiLoaderService) {}

  public start(taskId?: string, time?: Time) {
    this._text = undefined;
    this._isLoading = true;
    this.ngxLoader.start(taskId, time);
  }

  public startWithText(text: string, taskId?: string, time?: Time) {
    if (this._text !== undefined) {
      // Previous text hasn't been cleared, since we don't track of more than one, we just stop all loaders and replace with this one
      this.stopAll();
    }

    this._text = text;
    this._isLoading = true;
    this.textTaskId = taskId;
    this.ngxLoader.start(taskId, time);
  }

  public stop(taskId?: string) {
    if (taskId === this.textTaskId) {
      this._text = undefined;
      this.textTaskId = undefined;
    }

    this._isLoading = false;
    this.ngxLoader.stop(taskId);
  }

  public stopAll() {
    this._isLoading = false;
    this._text = undefined;
    this.textTaskId = undefined;
    this.ngxLoader.stopAll();
  }
}
