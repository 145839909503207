import { Border, SeriesMarkers } from '@progress/kendo-angular-charts';
import { Record } from 'immutable';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * The standard details of an API response.
 */
export interface ApiResponse<TData> {
  Success: boolean;
  Message: string | undefined;
  Result?: TData;
  TotalRecordCount: number;
}

export interface ApiErrorResponse extends ApiResponse<void> {}

export function newEmptyResponse<TData>(
  defaultData?: TData
): ApiResponse<TData> {
  return {
    Success: false,
    Result: defaultData,
    TotalRecordCount: 0,
    Message: undefined,
  };
}

interface ISortingRequest {
  SortInfo?: ColumnSortInfo[];
}

export interface ColumnSortInfo {
  IsDescending: boolean;
  ColumnName: string;
}

interface NameWithLongIdResponse {
  Id: number;
  Name: string;
}

interface NameWithLongIdAndParentResponse extends NameWithLongIdResponse {
  Type: string;
  ParentId?: number;
  ParentType: string;
}

export interface ListRowIdNameWithParent extends NameWithLongIdResponse {
  ParentId: number;
  ParentName: string;
  ItemType: string;
}

/**
 * Contains the list of known Product Line Id
 * values as defined in the API.
 */
export enum ProductLineIds {
  Unknown = -1,
  CareLink = 3,
  Avalo = 4,
}

/**
 * Represents a product line
 */
export interface ProductLineApiModel {
  ProductLineId: number;
  Name: number;
}

/**
 * Represents the API model returned from
 * /api/device/DeviceTypes
 */
export interface DeviceTypeApiModel {
  DeviceTypeId: number;
  Name: string;
  ProductLineId: ProductLineIds;
  //ProductLine: ProductLineApiModel;
}

export enum CapsaSettingsTypeIds {
  /// This setting indicate the date time format to be used in title view, Notification and Alerts screen and Service Requests screen.
  DeviceProfile_Display_DateTimeFormat = 21,

  /// Specifies if the department name should be displayed.
  DeviceProfile_Display_Department = 22,

  /// This setting indicate whether or not to show the cart name on the title view when no user is logged in or a user is logged in
  /// but DeviceProfile_Display_UserName is false.
  DeviceProfile_Display_CartName = 23,

  /// This setting indicates whether or not to show the user name on the title view.
  /// If false the user name is not shown even if a user logs in to the cart.
  DeviceProfile_Display_UserName = 24,

  DeviceProfile_Display_LoginStatus = 25,

  /// The minimum level of the external battery below which the red icon on the status zone must be visible
  DeviceProfile_BatteryRange_Red = 26,

  DeviceProfile_BatteryRange_Yellow = 27,

  /// This is the intensity value to be set to keyboard light when it is turned on in Home screen.
  DeviceProfile_Brightness_Keyboard = 28,

  /// This is the intensity value to be set to ground light when it is turned on in Home screen.
  DeviceProfile_Brightness_Floor = 29,

  /// This is the intensity value to be set to surface light when it is turned on in Home screen.
  DeviceProfile_Brightness_WorkSurface = 30,

  /// This setting indicates whether or not the Calculator button is enabled in the Home screen.
  DeviceProfile_CartFeatures_Calculator = 31,

  /// This setting indicates whether ot not to allow a user to access lights related functionalities.
  DeviceProfile_CartFeatures_Lights = 32,

  /// This setting indicates whether or not the Service Requests button is enabled in the Home screen.
  DeviceProfile_CartFeatures_ServiceRequests = 33,

  /// This setting indicates whether or not the Settings button is enabled in the Home screen.
  DeviceProfile_CartFeatures_Preferences = 34,

  /// This setting along with the DeviceProfile_CartFeatures_NotificationAlerts indicates whether or not to enable the notification icon in home page.
  /// If both DeviceProfile_CartFeatures_NotificationAlerts and DeviceProfile_CartFeatures_NotificationMessages are false
  /// then the notification screen navigation button in the home screen is disabled.
  ///
  /// This also indicates whether or not to pop alerts wen they come in.
  DeviceProfile_CartFeatures_NotificationAlerts = 35,

  /// This setting along with the DeviceProfile_CartFeatures_NotificationAlerts indicates whether or not to enable the notification icon in home page.
  /// If both DeviceProfile_CartFeatures_NotificationAlerts and DeviceProfile_CartFeatures_NotificationMessages are false
  /// then the notification screen navigation button in the home screen is disabled.
  DeviceProfile_CartFeatures_NotificationMessages = 36,

  DeviceProfile_BatteryWarning_Percent = 37,

  DeviceProfile_BatteryWarning_Flashing = 38,

  /// If this setting is true for a user, the user can try to login even after the cart is locked.
  CareLink_LockoutOverrideEnabled = 41,

  /// If true, the logged in user has access to the drawers on the cart.
  CareLink_DrawerAccessEnable = 42,

  /// If true, the IT services menu is availble to the signed in user.
  CareLink_ITServicesMenuEnable = 43,

  /// This setting indicates the default brightness value of the cart. When no user is logged in,
  /// this indicates the brightness to be set. If a user logs in and doesn't have a brightness of their own,
  /// the cart's default value is set to the display.
  CareLink_ScreenBrightness = 44,

  /// This setting indicates the volume level set for a user or cart.
  CareLink_VolumeLevel = 45,

  /// This setting indicates the Lift preset standing value for the logged-in user.
  CareLink_LiftPresetStanding = 46,

  /// This setting indicates the Lift preset sitting value for the logged-in user.
  CareLink_LiftPresetSitting = 47,

  /// This setting indicate the maximum number of user login attempts that could fail before the cart disallows any further login attempt.
  CLI_PinLockoutAttempts = 53,

  /// This setting indicates the time duration in seconds for which it disallows any login attempt after the cart is locked.
  CLI_PinLockoutTimespan = 54,

  /// The timeout, in seconds, after which the partially entered user pin will be cleared.
  CLI_PartialPinTimespan = 55,

  /// The length of the PIN set by a facility
  CLI_PinLength = 56,

  /// This setting indicates whether general user (cart without any user logged in) can access the lift functions on the home screen.
  /// If true thee general user cannot access those functions.
  CLI_LiftRequiresLogin = 57,

  /// This setting indicates whether general user (cart without any user logged in) can access the light-related features on the home screen.
  /// If true the general user cannot access the features.
  CLI_LightRequiresLogin = 58,

  /// This setting indicates the time duration in seconds for which the drawers remain unlocked before being locked again.
  CLI_DrawerUnlockMaxTimeSpan = 59,

  /// This setting indicates the maximum time in minutes that should be waited before logging out while no user interacts with the cart.
  CLI_LoginMaxTimeout = 60,

  /// This setting indicates the expiration duration in days for each newly composed service request.
  CLI_ServiceRequestTimeout = 61,

  /// This setting indicates whether the Calculator button is enabled in the home screen for a general user.
  /// If this setting is true, general user cannot access Calculator.
  CLI_CalculatorRequiresLogin = 62,

  /// This setting indicates whether the Help button is enabled in the home screen for a general user.
  /// If this setting is true, general user cannot access Help.
  CLI_HelpRequiresLogin = 63,

  /// This setting indicates whether the Service Requests button is enabled in the home screen for a general user.
  /// If this setting is true, general user cannot access Service Requests.
  CLI_ServiceRequestsRequiresLogin = 64,

  /// This setting indicates whether the Settings button is enabled in the home screen for a general user.
  /// If this setting is true, general user cannot access Settings.
  CLI_SettingsRequiresLogin = 65,

  /// The intensity value set for the notification light when it is turned on.
  /// This setting is both a cart/user setting.
  CLI_NotificationLightIntensity = 66,

  /// The color to display on the notification light when it is turned on.  This value
  /// is a CareLinkLightColor enum value.
  CLI_NotificationLightColor = 67,

  /// If true, this means the notification light should be blinking when it on.
  CLI_BlinkNotificationLight = 68,

  /// This setting indicates the cart's default language as well as the language set for each user.
  CLI_SelectedLanguage = 69,

  /// This setting indicates the name of the default color theme for the cart.
  CLI_ColorTheme = 70,

  /// This setting indicates whether the Notification button in home screen is enabled or disabled based on whether a user is logged in or not.
  /// If this setting is true, notiifcation button is disabled for general users.
  CLI_NotificationRequiresLogin = 71,

  /// Represents if the cart is active or not.
  CLI_CartIsActive = 72,

  /// This is the time offset to be added to the UTC time.
  /// For example, the value is set to 330 indicating +5 hours and 30 minutes which is the UTC offset for India.
  CLI_UtcOffset = 73,

  /// The frequency used for uploading the database records to NSight  that is changed in the cart.
  /// This interval time should be in minutes.
  CLI_SyncIntervalTimeInMinutes = 97,

  /// The URL for syncing carts
  CLI_SyncUrl = 98,

  /// This setting indicates the log level based on which certain logs may or may not be written to log files.
  /// Possible values - 0, 1, 2 and 3.
  /// <remarks>
  /// - 0 indicates that no log message is written to the file.
  /// - 1 indicates that only information are written to log files.
  /// - 2 indicates that only errors and exceptions are written to log files.
  /// - 3 indicates that all kinds of log messages can be written to log files.
  /// </remarks>
  CLI_CartLogLevel = 99,

  /// Indicates the size of each log file in MB
  CLI_CartLogFileSizeInMb = 100,

  /// This setting indicates the maximum number of log files that can be created.
  /// If a new log file has to be created, the older one is deleted such that at any point of time there are
  /// only NO_OF_LOG_FILES number of log files.
  CLI_CartMaxNumberOfLogFiles = 101,

  /// The timeout after which the login fail message will disappear
  CLI_DisplayFailedLoginMessageTimeout = 102,

  /// The timeout after which a digit entered for a user pin will be masked with a black circle
  CLI_PinMaskingTimespan = 103,

  /// This setting indicates the maximum time in minutes that should be waited
  /// before revealing the home screen while no user interacts with the cart.
  CLI_CartInactiveRedirectTimespanInMinutes = 104,

  /// Indicates whether or not the Tools button is enabled in the Home screen.
  CLI_ToolsEnabled = 105,

  /// This setting indicates whether a user can access the volume adjustment of the cart.
  CLI_VolumeEnabled = 106,

  /// If true, this means the notification light should be on.
  CLI_IsNotificationLightOn = 107,

  /// This setting indicates the USB Chanrging state (enabled/disabled) for a user.
  CLI_UsbPortStatus = 108,

  /// This setting indicates whether the Timer feature in Tools screen is accessible for a general user.
  /// If true, general user cannot access this feature.
  CLI_TimerRequiresLogin = 109,

  /// This setting indicates whether the Conversion feature in Tools screen is accessible for a general user.
  /// If true, general user cannot access this feature.
  CLI_ConversionRequiresLogin = 110,

  /// This setting indicates whether or not to show the date time on the status view.
  CLI_StatusBarShowDateTime = 111,

  /// Indicates if the USB charger port feature toggles should be visible on the cart for either logged in users or general users (i.e. not logged in)
  CLI_UsbChargingPortSupported = 112,

  /// Represents a facility-specific pin code for a user.
  CLI_UserPinCode = 113,

  /// Represents the name of a cart
  CLI_CartName = 117,

  /// Represents the total amount of days prior to a message expiring when sent from N-Sight.
  /// <remarks>
  /// Maps to "AlertMessageMaxTimeout" facility column; "Max Message Expiration" UI field
  /// </remarks>
  CLI_MaxMessageExpirationInDays = 119,

  /// Represents the total amount of hours prior to an alert expiring when sent from N-Sight.
  /// <remarks>
  /// Maps to "NotificationMessageMaxTimeout" facility column; "Alert Max Expiration Option" UI field
  /// </remarks>
  CLI_MaxAlertExpirationInHours = 120,

  /// Determines if the alert icon flashes on the cart or not.
  /// <remarks>
  /// Maps to "AlertMessageFlashingEnable" facility column; "Enable Alert Flashing Icon" UI field.
  /// This _may_ be a CareLink 1.0 only setting; possibly even deprecated completely.
  /// </remarks>
  CLI_EnableAlertIconFlashing = 121,

  /// Used to determine if the users at a facility are allowed to change the display and audible options for alerts on the facility's carts.
  /// <remarks>
  /// Maps to "AlertMessageEnable" facility column; "Allow Select FMS Alert Settings" UI field.
  /// This _may_ be a CareLink 1.0 only setting; possibly even deprecated completely.
  /// </remarks>
  CLI_EnableCartMessageSettingCustomization = 122,

  /// Determines if an alert plays audio on the carts in a facility.
  /// <remarks>
  /// Maps to "AlertMessageAudibleEnable" facility column; "Enable Audible Alarms" UI field.
  /// </remarks>
  CLI_EnableAudibleAlerts = 123,

  /// Determines if an alert can have a background color set by the user.
  /// <remarks>
  /// Maps to "AlertMessageColorsEnable" facility column; "Enable Alert Background Colors" UI field.
  /// </remarks>
  CLI_EnableAlertBackgroundColors = 124,

  /// Determines if a user can pin into a cart or not.
  /// <remarks>
  /// Maps to N-Sight "Active" Users column; "Cart User -> Is Active" UI field.
  /// This is for both current functionality and legacy.
  /// </remarks>
  CLI_IsCartUserActive = 125,

  /// <summary>
  /// Sets the Email Address recipient of the Service Request
  /// </summary>
  /// <remarks>
  /// In N-Sight 1 maps to "Facility" entity "ServiceRequestEmailAddress" column
  /// This is for both current functionality and legacy.
  /// </remarks>
  CLI_ServiceRequestEmailAddress = 126,

  /// <summary>
  /// Indicates that the user can access the secondary drawer lock.
  /// </summary>
  /// <remarks>
  /// Ideally this would have just been a permission, however due to time constraints
  /// and how the Trio software currently works, this would create too much extra work
  /// and there isn't enough time for that right now. In the future many of these
  /// N-Sight 1.0 "Cart User Settings" could be moved to permissions.
  /// </remarks>
  CLI_SecondaryDrawerAccessEnabled = 128,

  // Indicates the frequency (in seconds) that the notification light is blinkking.
  CLI_NotificationLightBlinkFrequency = 129,

  /// <summary>
  /// Max height in pixels for customer logo images.
  /// </summary>
  CLI_CustomerLogoMaxHeight = 139,

  /// <summary>
  /// Max width in pixels for customer logo images.
  /// </summary>
  CLI_CustomerLogoMaxWidth = 140,

  /// <summary>
  /// Second user PIN for unlocking secure drawer on Avalo carts.
  /// </summary>
  AVO_SecondaryPinCode = 144,

  /**
   * The length of the PIN set by a facility
   */
  AVO_PinLength = 149,

  /**
   * When enabled, will cause service requests submitted from a Trio to be immediately acknowledged and cause an 'alert' message to
   * be sent back to the cart with a message that the request was received. The alert style/message can be set with a
   * message template titled 'Automated Service Request Message'.
   */
  CLI_AutoAcknowledgeServiceRequest = 155,

  /**
   * Number of drawers this cart is configured for
   */
  AVO_NumberOfDrawers = 158,

  /**
   * Volume level of alarms, Off, Low, Medium, or High
   */
  AVO_AlarmVolume = 159,

  /**
   * Security Alarm for when user fails to pin in too many times
   */
  AVO_AlarmEnable = 160,

  /**
   * Alarm for Narc drawer 1 enabled
   */
  AVO_Narc1AlarmEnable = 161,

  /**
   * Alarm for Narc drawer 2 enabled
   */
  AVO_Narc2AlarmEnable = 162,

  /**
   * Sets whether to beep after every key press
   */
  AVO_KeyBeep = 163,

  /**
   * Service Account User Name for Avalo Connector
   */
  AVO_AvaloServiceAccountUserName = 164,

  /**
   * Service Account Password for Avalo Connector
   */
  AVO_AvaloServiceAccountPassword = 165,

  /**
   * The SignalR Hub URL an Avalo device should connect to
   */
  AVO_SignalRUrl = 166,

  /**
   * Time in minutes before the drawers auto relock.
   */
  AVO_AutoSecureTime = 169,

  /**
   * Sensitivity level of "Motion Detector" which is based on an accelerometer. 0 - 10 setting. 0 = Disabled. 1 least sensitive, 10 most sensiteive.
   * Detects physical movement of the cart (i.e. bumping the cart/vibration)
   */
  AVO_MotionDetectSensitivity = 170,

  /**
   * Minutes before the gooseneck light on top of cart automatically turns off. Range 0-30 minutes, 0 = Disabled. NS-1577
   */
  AVO_LightTimeoutMinutes = 171,

  /**
   * Determines whether a user should always enter their code prior to card scan NS-1579
   */
  AVO_UserCodeAlwaysRequired = 172,

  /**
   * Indicates that the user has access to Narc Drawer 1
   */
  AVO_NarcOneDrawerAccess = 174,

  /**
   * Indicates that the user has access to Narc Drawer 2
   */
  AVO_NarcTwoDrawerAccess = 175,

  /**
   * Indicates whether the Trio cart should upload power logs.
   */
  CLI_PowerLog_IsActive = 180,
}

export enum CareLinkCartUserType {
  Unknown = 0,
  Nurse = 1,
  ITAdmin = 2,
}

export interface DeviceProfileSettingRequestDto {
  CapsaSettingId?: number;
  SettingType: CapsaSettingsTypeIds;
  SettingValue: string;
}

/**
 * Represents the API model returned from
 * /api/device/version
 */
export interface DeviceTypeVersionApiModel {
  DeviceTypeId: number;
  DeviceTypeName: string;
  UpdateVersionId: number;
  UpdateVersion: string;
  FileSize: number;
  FileName: string;
}

/**
 * Represents a dropdown api model
 */
export interface NameWithLongIdApiModel {
  Id: number;
  ParentId?: number;
  Name: string;
}

/**
 * Represents a dropdown api model
 */
export interface NameWithStringIdApiModel {
  Id: string;
  Name: string;
}

export interface PagingRequestDto {
  PageNumber: number;
  PageSize: number;
}

export interface DataDrivenListItem {
  Name: string;
  Value: number;
  IsVisible: boolean;
  NameTranslationTag: string;
}

export enum DataDrivenSettingDataType {
  Integer = 0,
  Decimal = 1,
  String = 2,
  DateTime = 3,
  Dropdown = 4,
  Boolean = 5,
}

export interface CapsaSettingApiModel extends DeviceProfileSettingRequestDto {
  EnterpriseId: number;
  SettingId?: number;
  OrganizationId?: number;
  FacilityId?: number;
  CapsaUserId?: string;
  DeviceTypeId?: DeviceTypeIds;
  DisplayName: string;
  NameTranslationTag: string;
  InfoTranslationTag: string;
  DataType: DataDrivenSettingDataType;
  ListItems: DataDrivenListItem[] | null;
  MinValue?: number;
  MaxValue?: number;
}

export interface DeviceTypeWarningApiModel {
  DeviceWarningId: number;
  FacilityId: number;
  Text: string;
  Settings: CapsaSettingApiModel[];
}

export interface DeviceWarningUpdateApiModel {
  DeviceWarningId: number;
  FacilityId: number;
  Text: string;
  Settings: DeviceProfileSettingRequestDto[];
}

export interface DeviceWarningCreateRequest {
  Text: string;
  FacilityId: number;
  Settings: DeviceProfileSettingRequestDto[];
}

export enum DeviceTypeIds {
  All = 0,
  CareLink_M38e = 5,
  CareLink_2 = 9,
  CareLink_M48 = 10,
  Avalo = 11,
}

export interface DeviceProfileApiModel {
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  DeviceProfileId: number;
  DeviceTypeId: DeviceTypeIds;

  ProfileName: string;
  FacilityName: string | undefined;
  DeviceTypeName: string;
  ProductLineName: string;

  Settings: CapsaSettingApiModel[];
}

export interface DeviceProfileSearchResponseApiModel {
  DeviceProfileId: number;
  DeviceTypeId: number;
  ProductLineId: number;
  OrganizationId: number | undefined;
  OrganizationName: string | undefined;
  FacilityId: number | undefined;
  FacilityName: string | undefined;
  ProfileName: string | undefined;
  DeviceTypeName: string;
  ProductLineName: string;
}

export interface DeviceProfileSearchRequestApiModel {
  OrganizationId: number | undefined;
  FacilityId: number | undefined;
  DeviceTypeId: number | undefined;
  PageNumber: number | undefined;
  PageSize: number | undefined;
}

export interface GetDeviceProfileSettingsRequestApiModel {
  DeviceTypeId: number;
}

export interface DeviceProfileCreateRequestApiModel {
  Name: string;
  OrganizationId: number | undefined;
  FacilityId: number | undefined;
  DeviceTypeId: number;
  Settings: DeviceProfileSettingRequestDto[];
}

export interface DeviceProfileUpdateRequestApiModel {
  Name: string;
  Settings: DeviceProfileSettingRequestDto[];
}

export interface GetDeviceProfileByIdResponse {
  Settings: CapsaSettingApiModel[];
  DeviceProfile: DeviceProfileSearchResponseApiModel;
}

export interface CapsaRoleSearchRequestDto {
  CapsaRoleIds: number[];
  EnterpriseId: number;
  OrganizationId?: number;
  FacilityId?: number;
  Name?: string;
  ProductLines?: ProductLineIds[];
  PageNumber: number;
  PageSize: number;
  IncludePermissions: boolean;
  OnlyShowStandardRoles: boolean;
}

export interface CapsaRolePermissionResponseDto {
  CapsaRoleId: number;
  CapsaRolePermissionId: number;
  Permission: string;
}

export interface CapsaRoleResponseDto {
  CapsaRoleId: number;
  CapsaRoleType: string;
  EnterpriseId: number;
  Name: string;
  OrganizationId?: number;
  FacilityId?: number;
  Permissions?: CapsaRolePermissionResponseDto[];

  // Field we create/add in N-sight to add a translated version of the role
  // without affecting the original Name from the API
  TranslatedDisplayName?: string;
}

export enum UserType {
  Facility,
  General,
  Pharmacy,
  System,
}

export interface SettingCreateRequest {
  EnterpriseId: number;
  OrganizationId?: number;
  FacilityId?: number;
  CartId?: number;
  CapsaUserId: string;
  DeviceProfileId?: number;
  SettingType: CapsaSettingsTypeIds;
  SettingValue: string;
  IsEncrypted: boolean;
  AlternateKey: string;
  DeviceTypeId?: number;
}

export interface UserCreateRequest {
  Email: string;
  IsEmailConfirmed: boolean;
  EnterpriseId: number;
  FacilityIds: number[];
  FirstName: string;
  LastName: string;
  IsActive: boolean;
  DepartmentIds?: number[];
  OrganizationIds: number[];
  Username: string;
  RoleIds: number[];
  JoinDateUtc: string;
  UserType: UserType;
  PIN?: string;
  ProxId?: string;
  Comment?: string;
  SettingOverrides: SettingCreateRequest[];
  UserTimeZone: string;
  GeneratePinCodes: boolean;
}

export interface UserRegistrationDetails {
  code: string;
  enterpriseId: number;
  username: string;
  password: string;
}

export interface UserImportRecord {
  Username: string;
  FirstName: string;
  LastName: string;
  Departments: string;
  IsActive: boolean;
  LockoutOverride: boolean;
  ITServicesMenu: boolean;
  DrawerAccess: boolean;
  Narc1DrawerAccess: boolean;
  Narc2DrawerAccess: boolean;
  Comments: string;
  Witness: boolean;
  PIN: string;
  SecondaryPIN: string;
  ProxId: string;
}

export interface NS1UserImportRecord {
  UserName: string;
  Department: string;
  Status: string;
  LockoutOverride: string;
  ITMenu: string;
  DrawerAccess: string;
  Comments: string;
  Pin: string;
}

export interface UserImportRequest {
  Users: UserCreateRequest[];
  GeneratePINs: boolean;
  DeviceTypeForUserPins: DeviceTypeIds;
}

export interface ExcelColHeaderData {
  cellText: string;
  cellCoordinates: string; // e.g. A1, G2
}

export const userImportLegacyNSTrioColumns = {
  Username: {
    cellText: 'UserName',
    cellCoordinates: 'A1',
  } as ExcelColHeaderData,
  Department: {
    cellText: 'Department',
    cellCoordinates: 'B1',
  } as ExcelColHeaderData,
  Status: {
    cellText: 'Status',
    cellCoordinates: 'C1',
  } as ExcelColHeaderData,
  LockoutOverride: {
    cellText: 'Lockout Override',
    cellCoordinates: 'D1',
  } as ExcelColHeaderData,
  ITServicesMenu: {
    cellText: 'IT Services Menu',
    cellCoordinates: 'E1',
  } as ExcelColHeaderData,
  DrawerAccess: {
    cellText: 'Drawer Access',
    cellCoordinates: 'F1',
  } as ExcelColHeaderData,
  Comments: {
    cellText: 'Comments',
    cellCoordinates: 'G1',
  } as ExcelColHeaderData,
  PIN: {
    cellText: 'PIN',
    cellCoordinates: 'H1',
  } as ExcelColHeaderData,
};

export const userImportTrioColumns = {
  Username: {
    cellText: 'User Name',
    cellCoordinates: 'A1',
  } as ExcelColHeaderData,
  FirstName: {
    cellText: 'First Name',
    cellCoordinates: 'B1',
  } as ExcelColHeaderData,
  LastName: {
    cellText: 'Last Name',
    cellCoordinates: 'C1',
  } as ExcelColHeaderData,
  Departments: {
    cellText: 'Departments',
    cellCoordinates: 'D1',
  } as ExcelColHeaderData,
  IsActive: {
    cellText: 'Status',
    cellCoordinates: 'E1',
  } as ExcelColHeaderData,
  LockoutOverride: {
    cellText: 'Lockout Override',
    cellCoordinates: 'F1',
  } as ExcelColHeaderData,
  ITServicesMenu: {
    cellText: 'IT Services Menu',
    cellCoordinates: 'G1',
  } as ExcelColHeaderData,
  DrawerAccess: {
    cellText: 'Drawer Access',
    cellCoordinates: 'H1',
  } as ExcelColHeaderData,
  Comments: {
    cellText: 'Comments',
    cellCoordinates: 'I1',
  } as ExcelColHeaderData,
  PIN: {
    cellText: 'PIN',
    cellCoordinates: 'J1',
  } as ExcelColHeaderData,
  Witness: {
    cellText: 'Witness',
    cellCoordinates: 'K1',
  } as ExcelColHeaderData,
};

export const userImportAvaloColumns = {
  Username: {
    cellText: 'User Name',
    cellCoordinates: 'A1',
  } as ExcelColHeaderData,
  FirstName: {
    cellText: 'First Name',
    cellCoordinates: 'B1',
  } as ExcelColHeaderData,
  LastName: {
    cellText: 'Last Name',
    cellCoordinates: 'C1',
  } as ExcelColHeaderData,
  IsActive: {
    cellText: 'Status',
    cellCoordinates: 'D1',
  } as ExcelColHeaderData,
  Narc1DrawerAccess: {
    cellText: 'Narc 1 Drawer Access',
    cellCoordinates: 'E1',
  } as ExcelColHeaderData,
  Narc2DrawerAccess: {
    cellText: 'Narc 2 Drawer Access',
    cellCoordinates: 'F1',
  } as ExcelColHeaderData,
  PIN: {
    cellText: 'PIN',
    cellCoordinates: 'G1',
  } as ExcelColHeaderData,
  SecondaryPin: {
    cellText: 'Secondary PIN',
    cellCoordinates: 'H1',
  } as ExcelColHeaderData,
  ProxCardId: {
    cellText: 'Prox Card ID',
    cellCoordinates: 'I1',
  } as ExcelColHeaderData,
};

export const accessPointImportColumns = {
  macAddress: 'MAC Address',
  Name: 'Name',
};

export interface AccessPointImportRecord {
  MacAddress: string;
  Name: string;
}

export interface UserExportRequest {
  UserFilters: {
    EnterpriseId: number;
    OrganizationIds: number[];
    FacilityIds: number[];
    IncludeInactiveUsers: boolean;
    RoleIds: number[];
  };
  SettingFilters: {
    EnterpriseId: number;
    SettingTypes: CapsaSettingsTypeIds[];
    DeviceTypeId: DeviceTypeIds;
  };
}

export interface UserCheckIsUserNameUniqueRequestDto {
  UserName: string;
}

export interface CartBulkAddUsersRequest {
  CartIds: number[];
  UserIds: string[];
}

export interface EmailRequestDto {
  Body: string;
  Format: 'Html';
  Type: 'None';
  Subject: string;
  RecipientList: string[];
  ProductLine: ProductLineIds;
}

export enum UserSecurityTokenType {
  Unknown = 0,
  ConfirmEmailAddress = 1,
  ResetPassword = 2,
}

export interface UserGenerateCodeRequestBody {
  CodeType: UserSecurityTokenType;
}

export interface FacilitySearchRequest {
  EnterpriseId: number;
  OrganizationIds?: number[];
  FacilityId?: number;
  IncludeCapsaSettings: false;
  PageSize: number;
  PageNumber: number;
}

export interface OrganizationSearchRequest {
  EnterpriseId: number;
  OrganizationIds?: number[];
  IncludeCapsaSettings: false;
  PageSize: number;
  PageNumber: number;
}

export interface OrganizationResponse {
  EnterpriseId: number;
  OrganizationId: number;
  Settings: SettingResponse[];
  Name: string;
}

export interface FacilityResponse {
  Address1: string;
  Address2: string;
  City: string;
  ContactEmail: string;
  ContactPerson: string;
  Country: string;
  EnterpriseId: number;
  FacilityId: number;
  InterfaceId: null;
  IsActive: boolean;
  Name: string;
  OrganizationId: number;
  Phone: string;
  State: string;
  Version: number;
  ZipCode: string;
  Settings: SettingResponse[];
  NotificationGroups: NotificationGroupResponse[];
}

export interface CartSearchRequest extends ISortingRequest {
  CartId?: number;
  EnterpriseId: number;
  FacilityIds: number[];
  CapsaUserIds?: string[];
  IsActive?: boolean;
  IsSoftwareSetup?: boolean;
  Name?: string;
  OrganizationIds?: number[];
  PageNumber: number;
  PageSize: number;
  SerialNumber?: string;
  Version?: number;
  DeviceTypeId?: number;
  ProductLines: ProductLineIds[];
  IncludeCapsaSettings: boolean;
  CapsaSettings?: Array<KeyValuePair<CapsaSettingsTypeIds, string>>;
  IncludeOnlyTheseSettings?: Array<CapsaSettingsTypeIds>;
  DeviceKeyValues?: string[];
}

export interface CartReportRequest {
  OrganizationIds: number[];
  FacilityIds: number[];
  CartIds: number[];
}

export interface KeyValuePair<TData, UData> {
  Key: TData;
  Value: UData;
}

export enum UpdateStatusType {
  Unknown,
  UpToDate,
  NeedsUpdate,
  UpdatePending,
}

export interface CartFriendlyUpdateGridItem extends CartResponse {
  ccsStatus: UpdateStatusType;
  distBoardStatus: UpdateStatusType;
  taskLightBoardStatus: UpdateStatusType;
  lockBoardStatus: UpdateStatusType;
  isFullyUpToDate: boolean;

  bspVersion: string;

  /**
   * True if at least one "pending update" status and NO "out of date" statuses
   */
  isUpdatePending: boolean;
}

export interface CartAdvancedUpdateGridItem
  extends CartFriendlyUpdateGridItem,
    CartResponse {
  distBoardVersion: string;
  distBoardPn: string;
  taskLightBoardVersion: string;
  taskLightBoardPn: string;
  lockBoard1Version: string;
  lockBoard2Version: string;
  lockBoard3Version: string;
  lockBoardPn: string;
}

export interface CartResponse {
  ActivationDate: Date;
  Settings: CapsaSettingApiModel[];
  SerialNumber: string;
  OrganizationId: number;
  Name: string;
  LastImAliveUtc: Date;
  DeactivationDate: Date;
  IsSoftwareSetup: boolean;
  FacilityId: number;
  EnterpriseId: number;
  DeviceTypeId: number;
  DeviceTypeCode: string;
  DeviceProfileId: number;
  CartLogSettings: any[];
  ComponentValues: Map<string, string>;
  CartId: number;
  IsActive: boolean;
  Version: number;
  LastKnownNetworkAccessPoint: NetworkAccessPointResponse;
  // used on FE only for carts management grid
  IsConnected?: boolean;
  // Used on the front end only for the grid to see which
  // carts are active and which are not
  CLI_CartIsActive?: boolean;
}

export interface CartRowForUpdates extends CartResponse {
  bspVersion: string;
  distBoardAppVersion: string;
  lockBoard1AppVersion: string;
  lockBoard2AppVersion: string;
  lockBoard3AppVersion: string;
  taskLightBoardVersion: string;
  distBoardPartNumber: string;
  lockBoardPartNumber: string;
  taskBoardPartNumber: string;
}

export interface GetCartByIdRequest {
  Id: number;
}

export interface CartPartialResponse {
  SerialNumber: string;
  Name: string;
  Id: number;
}

export enum DeviceUpdateType {
  NotSpecified = 0,
  Firmware = 1,
  OS = 2,
  Application = 3,
  Accessory = 4,
  Video = 5,
  AccessoryDistBoard = 6,
  AccessoryTaskLightBoard = 7,
  AccessoryLockingBoard = 8,
}

export interface DeviceUpdateRequest {
  Ids: number[];
}

export interface UserResponse {
  Id: string;
  Username: string;
  UserDisplayName: string;
  UserCartList: NameWithLongIdApiModel[];
  UserCartGroupList: NameWithLongIdApiModel[];
  FacilityList: NameWithLongIdApiModel[];
  UserNotificationGroupList: NameWithLongIdApiModel[];
  OrganizationList: NameWithLongIdApiModel[];
  UserRoleList: NameWithLongIdApiModel[];
  PhoneNumber: string;
  Pin: string;
  ProxId: string;
  FirstName: string;
  LastName: string;
  LanguageId: number;
  IsPasswordResetRequested: boolean;
  Email: string;
  EnterpriseId: number;
  IsActive: boolean;
  IsEnterpriseAdmin: boolean;
  IsEmailConfirmed: boolean;
  IsLockedOut: boolean;
  HasValidSecurityQuestions: boolean;
  JoinDateUtc: string;
  LanguageCodeType: number;
  UserTimeZone: string;
  UserType: number;
  Comment?: string;
  Version: number;
}

export interface CartTransferRequest {
  TransferUsers?: boolean;
  SourceOrganizationId: number;
  SourceFacilityId: number;
  TargetFacilityId: number;
  TargetOrganizationId: number;
  TargetEnterpriseId: number;
  EnterpriseId: number;
  Name: string;
  CartId: number;
}

export interface UserPartialResponse {
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  UserName: string;
  Comment?: string;
  IsActive: boolean;
  Settings?: CapsaSettingApiModel[];
  IsDeviceUser: boolean;
  IsWebUser: boolean;
}

export interface UserGetByIdRequest {
  Id: string;
  IncludeLists: boolean;
}

export interface UserSearchRequest extends ISortingRequest {
  // Only fields used by FE are included
  EnterpriseId: number;
  OrganizationIds: number[];
  FacilityIds: number[];
  PageSize: number;
  PageNumber: number;
  IncludeInactiveUsers: boolean;
  CartIds?: number[];

  /**
   * List of optional org/facility/device-specific user settings
   */
  Settings: CapsaSettingsTypeIds[];
  /**
   * Required to retreive device-specific user settings
   */
  SettingsDeviceTypeId?: number;
  /**
   * When true will prevent users that aren't assigned any facilities within
   * the given enterprise from being returned in the results(See API for logic)
   */
  OmitUnassignedUsers: boolean;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  UserName?: string;
}

export interface UserUpdateRequest {
  /**
   * This property should be empty UNLESS changes must be made to assigned request types
   * i.e. we should not be filling this in on every update request, uless we need to delete, add, update
   * a users assigned request types list for a given facility/facilities
   */
  AssignedRequestTypes?: MessageQueueUserRequestTypesUpdateRequest[];

  UpsertSettings?: SettingUpdateRequest[];
  Email: string;
  IsEmailConfirmed: boolean;
  EnterpriseId: number;
  FacilityIds: number[];
  FirstName: string;
  Id: string;
  IsActive: boolean;
  IsEnterpriseAdmin: boolean;
  IsPasswordResetRequested: boolean;
  JoinDateUtc: string;
  LanguageCodeType: number;
  LanguageId: number;
  LastName: string;
  NotificationGroupIds: number[];
  OrganizationIds: number[];
  PhoneNumber: string;
  Pin: string;
  ProxId: string;
  RoleIds: number[];
  UserCartGroupIds: number[];
  UserCartIds: number[];

  UserTimeZone: string;
  UserType: number;
  Username: string;
  Comment?: string;
  Version: number;
  GenerateMissingPinCodes?: boolean;
}

export enum CareLinkActivityCategories {
  CartAccessEvent = 'CartAccessEvent',
  FirmwareUpdateEvent = 'FirmwareUpdateEvent',
  RecoveryEvent = 'RecoveryEvent',
  SyncEvent = 'SyncEvent',
  OtaEvent = 'OtaEvent',
  WebAccessEvent = 'WebAccessEvent',
}

export enum CareLinkActivityTypes {
  Unknown = 0,
  CartAccessEvent_Login = 1,
  CartAccessEvent_Logout = 2,
  CartAccessEvent_DrawerLock = 3,
  CartAccessEvent_DrawerUnlock = 4,
  CartAccessEvent_Lockout = 5,
  CartAccessEvent_SerialPortLogin = 6,
  CartAccessEvent_SerialPortLogout = 7,
  CartAccessEvent_SerialPortDrawerLock = 8,
  CartAccessEvent_SerialPortInvalidCommand = 9,
  CartAccessEvent_SerialPortCartSerialNumber = 10,
  FirmwareUpdateEvent_CCSBoardUpdate = 11,
  FirmwareUpdateEvent_DistributionBoardUpdate = 12,
  FirmwareUpdateEvent_TaskLightBoardUpdate = 13,
  FirmwareUpdateEvent_LockBoardUpdate = 14,
  RecoveryEvent_PartitionCrash = 15,
  RecoveryEvent_PartitionCorrupt = 16,
  SyncEvent_SyncSuccess = 17,
  SyncEvent_SyncFailure = 18,
  OtaEvent_CCSBoardSent = 19,
  OtaEvent_AccessoryBoardSent = 20,
  OtaEvent_UpdateFailed = 21,
  OtaEvent_UpdateSucceeded = 22,
  FaultEvent_PowerSupplyFault1 = 23,
  FaultEvent_PowerSupplyFault2 = 24,
  FaultEvent_PowerSupplyFault3 = 25,
  FaultEvent_PowerSupplyFault4 = 26,
  NetworkEvent_AccessPointChanged = 27,
  CartAccessEvent_InvalidPin = 28,
  NetworkEvent_NetworkDisconnected = 29,
  NetworkEvent_NetworkConnected = 30,
  FaultEvent_SdCardFailure = 31,
}

export enum AvaloActivityTypes {
  Unknown = 0,
  InvalidCode = 1,
  CodeAccess = 2,
  KeyAccess = 3,
  ManualSecured = 4,
  KeypadSecured = 5,
  AutoSecured = 6,
  MedSecured = 7,
  PowerRestored = 8,
  MedAccess = 9,
  OvrrdAccess = 10,
  ThreeBadCodes = 11,
  MedOpenSec = 12,
  SecureFault = 13,
  MasterProg = 14,
  MasterRecall = 15,
  UserEdit = 16,
  MasterCdChg = 17,
  ModAccessCd = 18,
  ModUserLevel = 19,
  ModMedAcces = 20,
  ModMedCode = 21,
  AddUser = 22,
  DeleteUser = 23,
  ModAutoSecDl = 24,
  ModPrsnlEdit = 25,
  ModifyTime = 26,
  ModifyDate = 27,
  ModifyAlarm = 28,
  ModifyAlmLvl = 29,
  ModMedCdTp = 30,
  ModOvrrdCode = 31,
  ModGenModCd = 32,
  ModGenAccCd = 33,
  SprMastrAllowed = 34,
  Med1AccessAlwd = 35,
  Med2AccessAlwd = 36,
  ModMed1Alm = 37,
  ModMed2Alm = 38,
  ModAccsCdTp = 39,
  ModKeyBeep = 40,
  ModCustomPrt = 41,
  ModMtnDetect = 42,
  MedSolOff = 43,
  ChargerOn = 44,
  ChargerOff = 45,
  SyncSuccess = 46,
  SyncFailure = 47,
}

export interface ActivitySearchRequest extends ISortingRequest {
  // Only fields used by FE are included
  EnterpriseId: number;
  CareLinkActivityCategories?: string[];
  DateRangeUtcStart: Date;
  DateRangeUtcEnd: Date;
  Description: string;
  Message: string;
  ActivityTypeIds?: number[];
  OrganizationIds?: number[];
  FacilityIds?: number[];
  CartIds?: number[];
  UserIds?: number[];
  CartSerialNumbers?: string[];
  CartNames?: string[];

  // The API will search UserName AND UserDisplayName columns
  UserNames?: string[];

  PageSize: number;
  PageNumber: number;
}

export interface ActivityResponse {
  CareLinkActivityId: number;
  ActivityDateUtc: string;
  CareLinkActivityTypeId: number;
  ActivityTypeDescription: string;
  CartId: number;
  CartName: string;
  CartSerialNumber: string;
  Description: string;
  Message: string;
  EnterpriseId: number;
  EnterpriseName: string;
  FacilityId: number;
  FacilityName: string;
  OrganizationId: number;
  OrganizationName: string;
  UserId: string;
  UserDisplayName: string;
  UserName: string;
}

export interface ApiActivitySearchRequest extends ISortingRequest {
  // Only fields used by FE are included
  EnterpriseId: number;
  FacilityId: number;
  Controllers?: string[];
  Endpoints?: string[];
  UserIds?: number[];
  PageSize: number;
  PageNumber: number;
  UserDisplayNames?: string[];
  DateRangeUtcStart?: Date;
  DateRangeUtcEnd?: Date;
  Result?: string[];
}

export interface ApiActivityResponse {
  ApiActivityId: number;
  ActivityDateUtc: string;
  EnterpriseId: number;
  EnterpriseName: string;
  Controller: string;
  Endpoint: string;
  Result: string;
  UserId: string;
  UserDisplayName: string;
  UserName: string;
}

// Dashboard specific! (not the same as ChartQueryType)
export enum AnalyticQueryType {
  Unknown = 0,
  CartLockouts = 1,
  CartsNotSynced = 2,
  CartsUnderutilized = 3,
  CartsOnBatteryPower = 4,
  CartsNotSanitized = 5,
  CartsNoLogins = 6,
  CartsChangedWifiZones = 7,
  CartsStationary = 8,
}

export interface DashboardWidgetRequest {
  OrganizationId: number;
  FacilityId: number;
  DeviceType?: DeviceTypeIds;
  SinceHoursAgo: number;
  QueryType: AnalyticQueryType;
}

export interface DashboardWidgetResponse {
  QueryType: AnalyticQueryType;
  Numerator: number;
  Denominator?: number;
  Trend: number;
  SinceHoursAgo: number;
}

export interface FacilitySettingsRequest {
  EnterpriseId: number;
  FacilityId: number;
  ProductLine: 'CareLink';
  Version?: number;
}

export interface SettingResponse {
  MaxValue?: number;
  MinValue?: number;
  InfoTranslationTag: string;
  NameTranslationTag: string;
  ListItems: DataDrivenListItem[]; // not used?
  EnumName: string;
  DataType: DataDrivenSettingDataType;
  AlternateKey: string;
  IsEncrypted: boolean;
  IsOverridable: boolean;
  CapsaUserId: string;
  SettingValue: string;
  DisplayName: string;
  SettingType: CapsaSettingsTypeIds;
  SettingId: number;
  OrganizationId?: number;
  FacilityId?: number;
  DeviceTypeId?: number;
  EnterpriseId: number;
  CartId?: number;
  ColumnWidth: number;
  Version: number;
}

export interface FacilityUpdateRequest {
  Version: number;
  InterfaceId: string;
  ContactEmail: string;
  ContactPerson: string;
  Phone: string;
  Country: string;
  ZipCode: string;
  State: string;
  City: string;
  Address2: string;
  Address1: string;
  IsActive: boolean;
  Name: string;
  OrganizationId: number;
  FacilityId: number;
  Settings: SettingUpdateRequest[];
  UpdateSettings: boolean;
  UpdateNotificationGroups: boolean;
  NotificationGroups: NotificationGroupUpdateRequest[];
}

export interface SettingUpdateRequest {
  SettingId: number;
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  CartId?: number;

  // Ignoring this for now... make sure to send in productLine when submitting
  DeviceProfileId?: number;

  DeviceTypeId: number;
  CapsaUserId: string;
  SettingType: CapsaSettingsTypeIds;
  SettingValue: string;
  Version?: number;
  IsOverridable?: boolean;
  IsEncrypted?: boolean;
}

export interface GetUserFacilitySettingsRequest {
  DeviceType: DeviceTypeIds;
  CapsaUserId: string;
  FacilityId: number;
}

export interface GeneratePinRequest {
  FacilityId: number;
  DeviceType: DeviceTypeIds;
  SettingType: CapsaSettingsTypeIds;
}

export interface SavePinRequest {
  FacilityId: number;
  Pin: string;
  DeviceType: DeviceTypeIds;
}

export interface UserPasswordUpdateRequest {
  EnterpriseId: number;
  UserName: string;
  OldPassword: string;
  NewPassword: string;
}

export interface UserPasswordResetRequest {
  EnterpriseId: number;
  UserId: string;
}

export interface PasswordForceResetRequest {
  UserId: string;
  ReturnUrl: string;
  TranslationRequest?: TranslationRequest;
}

export interface TranslationRequest {
  ProductLine: ProductLineIds;
  Context: string;
}

export enum MessageQueueMessageType {
  Unknown = 0,
  Notification = 1,
  Alert = 2,
}

export enum MessageQueueUserType {
  Unknown = 0,
  User = 1,
  Cart = 2,
  RequestType = 3,
}

export enum MessageQueueDefaultColors {
  Background = 0xffffff,
  Foreground = 0x000000,
}

// Used for messages/alerts, text/bg color
export enum Colors {
  White = 0xffffff,
  Green = 0x00ff00,
  PowderBlue = 0x00b0ff,
  Lemon = 0xffd700,
  Plum = 0xff1493,
  DeepBlue = 0x0000ff,
  Black = 0x000000,
  Blue = 0x336699,
  Brown = 0x663300,
  Grey = 0xcccccc,
  Orange = 0xff6600,
  Pink = 0xffc3ce,
  Purple = 0x8c489f,
  Red = 0xff0000,
  Yellow = 0xffcc00,
}

/// Represents the various colors the LED's on CareLink support.
// Temporarily splitting out colors enum
// NS-648
export enum CareLinkLightColorNotificationLightOnly {
  None = 0x000000,
  White = 0xffffff,
  Green = 0x00ff00,
  PowderBlue = 0x00b0ff,
  Lemon = 0xffd700,
  Plum = 0xff1493,
  DeepBlue = 0x0000ff,
  Red = 0xff0000,
}

/// Represents the various colors the LED's on CareLink support.
export enum CareLinkLightColor {
  // Removed NS-648
  // None = 0x000000,
  White = 0xffffff,
  Green = 0x00ff00,
  PowderBlue = 0x00b0ff,
  Lemon = 0xffd700,
  Plum = 0xff1493,
  DeepBlue = 0x0000ff,
  Red = 0xff0000,
}

export interface MessageQueueCreateRequest {
  MessageType: MessageQueueMessageType;
  OrganizationId: number;
  FacilityId: number;
  CartIds: number[];
  UserIds: string[];
  Subject: string;
  Body?: string;
  BackgroundColor: number;
  ForegroundColor: number;
  SentDateTimeUtc: Date;
  ExpirationDateTimeUtc?: Date;
  SenderUserType: MessageQueueUserType;
}

export interface MessageQueueResponse {
  MessageQueueId: number;
  MessageTypeId: MessageQueueMessageType;
  MessageType: string;
  BackgroundColor: number;
  ForegroundColor: number;
  SenderId: string;
  RecipientId: string;
  AcknowledgedByUserId: string;
  Subject: string;
  Body?: string;
  SentDateTimeUtc: Date;
  ReceivedDateTimeUtc?: Date;
  ExpirationDateTimeUtc?: Date;
  AcknowledgedDateTimeUtc?: Date;
  RecipientName: string;
  SenderName: string;
  SenderUserType: MessageQueueUserType;
  RecipientUserType: MessageQueueUserType;
}

export interface MessageQueueSearchRequest {
  OrganizationIds: number[];
  FacilityIds: number[];
  MessageTypes?: MessageQueueMessageType[];
  SenderType?: MessageQueueUserType;
  RecipientType?: MessageQueueUserType;
  SenderUserIds?: string[];
  SenderCartIds?: number[];
  RecipientUserId?: string[];
  RecipientCartIds?: number[];
  AcknowledgedByUserIds?: string[];
  SentDateTimeUtcStart?: Date;
  SentDateTimeUtcEnd?: Date;
  AcknowledgedDateTimeUtcStart?: Date;
  AcknowledgedDateTimeUtcEnd?: Date;
  DateModifiedUtc?: Date;
  PageNumber: number;
  PageSize: number;
}

export enum CareLinkLightType {
  Unknown = 0,
  Notification = 1,
  WorkSurface = 2,
  Keyboard = 3,
  Ground = 4,
}

export interface LightConfigurationResponse {
  LightColorThemeId: number;
  LightConfigurationId: number;
  LightType: CareLinkLightType;
  Intensity: number;
  Color: CareLinkLightColor;
}

export interface LightColorThemeResponse
  extends NameWithLongIdAndParentResponse {
  IsDefaultTheme: boolean;
  Configurations: LightConfigurationResponse[];
}

export interface LightConfigurationUpdateRequest {
  LightConfigurationId: number;
  Intensity: number;
  Color: CareLinkLightColor;
}

export interface LightColorThemeUpdateRequest {
  ThemeName: string;
  Configurations: LightConfigurationUpdateRequest[];
}

export interface FacilityCreateRequest {
  Address1?: string;
  Address2?: string;
  City?: string;
  ContactEmail: string;
  ContactPerson: string;
  Country?: string;
  Name: string;
  Phone?: string;
  State?: string;
  ZipCode?: string;
  EnterpriseId: number;
  InterfaceId?: string;
  IsActive: boolean;
  OrganizationId: number;
  Settings?: any[];
}

export interface NetworkAccessPointResponse {
  NetworkAccessPointId: number;
  Name: string;
  MacAddress: string;
  OrganizationId?: string;
  FacilityId?: string;
}

export interface NetworkAccessPointSearchRequest
  extends PagingRequestDto,
    ISortingRequest {
  EnterpriseId: number;
  OrganizationIds: number[];
  FacilityIds: number[];
  MacAddresses: string[];
  Names: string[];
}

export interface NetworkAccessPointCreateRequest {
  Name: string;
  MacAddress: string;
  EnterpriseId: number;
  OrganizationId?: number;
  FacilityId?: number;
}

export interface NetworkAccessPointUpdateRequest {
  Name: string;
  MacAddress: string;
}

export interface NetworkAccessPointSimple {
  MacAddress: string;
  Name: string;
}

export interface NetworkApImportRequest {
  EnterpriseId: number;
  OrganizationId?: number;
  FacilityId: number;
  NetworkAccessPoints: NetworkAccessPointSimple[];
}

export interface MessageQueueRequestTypeSearchRequest {
  Ids?: number[];
  Descriptions?: string[];
  AcknowledgementThresholds?: number[];
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  ModifiedAfterUtc?: Date;
  PageNumber: number;
  PageSize: number;
}

export interface MessageQueueRequestTypeUpdateRequest {
  Id: number;
  Description: string;
  AcknowledgementThreshold: number;
}

export interface MessageQueueRequestTypeCreateRequest {
  Description: string;
  AcknowledgementThreshold: number;
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
}

export interface MessageQueueUserRequestTypesUpdateRequest {
  EnterpriseId: number;
  FacilityId?: number;
  UserId: string;
  RequestTypeIds: number[];
}

export interface MessageQueueRequestTypeResponse {
  MessageQueueRequestTypeId: number;
  Description: string;
  AcknowledgementThreshold: number;
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  DateModifiedUtc: Date;
}
export interface MessageTemplateCreateRequest {
  OrganizationId: number;
  FacilityId: number;
  Name: string;
  MessageType?: MessageQueueMessageType;
  Subject?: string;
  Body?: string;
  BackgroundColor?: number;
  ForegroundColor?: number;
}

export interface MessageTemplateUpdateRequest {
  MessageQueueTemplateId: number;
  OrganizationId: number;
  FacilityId: number;
  Name: string;
  MessageType?: MessageQueueMessageType;
  Subject?: string;
  Body?: string;
  BackgroundColor?: number;
  ForegroundColor?: number;
}

export interface MessageTemplateSearchRequest {
  OrganizationId?: number;
  FacilityId?: number;
  MessageType?: MessageQueueMessageType;
  PageNumber: number;
  PageSize: number;
}

export interface MessageTemplateResponse {
  MessageQueueTemplateId: number;
  Name: string;
  MessageQueueMessageType?: string;
  Subject?: string;
  Body?: string;
  BackgroundColor?: number;
  ForegroundColor?: number;
}

export interface MessageSubjectPresetCreateRequest {
  Description: string;
  OrganizationId: number;
}

export interface MessageSubjectPresetUpdateRequest {
  Description: string;
  OrganizationId: number;
}

export interface MessageSubjectPresetSearchRequest {
  OrganizationIds: number[];
  PageNumber: number;
  PageSize: number;
}

export interface MessageSubjectPresetResponse {
  MessageQueueSubjectPresetId: number;
  Description: string;
  OrganizationId: number;
}

export interface CartGroupCreateRequest {
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  Description: string;
}

export interface CartGroupDeleteRequest {
  Id: number;
}

export interface CartGroupGetRequest {
  Id: number;
}

export interface CartGroupSearchRequest {
  CartGroupIds?: number[];
  EnterpriseId: number;
  OrganizationIds: number[];
  FacilityIds: number[];
  PageNumber: number;
  PageSize: number;
}

export interface CartGroupUpdateRequest {
  CartGroupId: number;
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  Description: string;
}

export interface CartGroupMembersUpdateRequest {
  CartGroupId: number;
  CartIds: number[];
}

export interface CartGroupMemberCreateRequest {
  CartGroupId: number;
  CartId: number;
}

export interface CartGroupMemberDeleteRequest {
  Id: number;
}

export interface CartGroupMemberGetRequest {
  Id: number;
}

export interface CartGroupMemberSearchRequest {
  CartGroupIds: number[];
  CartIds: number[];
  PageNumber: number;
  PageSize: number;
}

export interface CartGroupResponse {
  CartGroupId: number;
  Description: string;
  EnterpriseId: number;
  OrganizationId: number;
  FacilityId: number;
  Members: CartGroupMemberResponse[];

  // Custom property added after getting response from API
  // Used to display number of carts in the group
  MemberCount?: number;
}

export interface CartGroupMemberResponse {
  CartGroupId: number;
  CartGroupMemberId: number;
  CartId: number;
  CartName: string;
}

export enum NotificationType {
  None = 0,
  BlackBoxTest = 1,
  UnLockedDrawer = 2,
  ScheduledReports = 3,
  PendingTransactionStuck = 4,
  DocutrackDispense = 5,
  OtaRequest = 6,
  BoardFailure = 7,
  ResetPcbMismatch = 8,
  DispenseDiscrepancy = 9,
  BelowMinStockout = 10,
  CartTransferFailure = 11,
  FailedTransaction = 12,
  OrderDispensedMax = 13,
  CLI_Battery_Level_Yellow = 14,
  CLI_Battery_Level_Red = 15,
  CLI_Connectivity_Yellow = 16,
  CLI_Connectivity_Red = 17,
  CLI_Utilization_Yellow = 18,
  CLI_Utilization_Red = 19,
  CLI_Security_Yellow = 20,
  CLI_Security_Red = 21,
  NEX_ExternalKey = 22,
  CLI_ServiceRequest = 23,
}

export interface NotificationGroupCreateRequest {
  EnterpriseId: number;
  OrganizationId?: number;
  FacilityId?: number;
  Description: string;
  NotificationType: NotificationType;
  Members: NotificationMemberCreateRequest[];
}

export interface NotificationGroupDeleteRequest {
  NotificationGroupId: number;
}

export interface NotificationGroupSearchRequest {
  Description?: string;
  EnterpriseId: number;
  FacilityIds: number[];
  NotificationGroupIds: number[];
  NotificationTypes: NotificationType[];
  OrganizationIds: number[];
  PageNumber: number;
  PageSize: number;
}

export interface NotificationGroupUpdateRequest {
  Description: string;
  EnterpriseId: number;
  FacilityId?: number;
  Members: NotificationMemberCreateRequest[];
  NotificationGroupId: number;
  NotificationType: NotificationType;
  OrganizationId?: number;
}

export interface NotificationGroupResponse {
  Description: string;
  EnterpriseId: number;
  FacilityId?: number;
  Members: NotificationGroupMemberResponse[];
  NotificationGroupId: number;
  NotificationType: NotificationType;
  OrganizationId?: number;
}

export interface NotificationGroupMemberResponse {
  CapsaUserId: string;
  MemberEmail: string;
  NotificationGroupId: number;
  NotificationGroupMemberId: number;
  NotificationMemberId: number;
  NotificationType: NotificationType;
}

export interface NotificationMemberCreateRequest {
  CapsaUserId?: string;
  Description: string;
  Email: string;
  EnterpriseId: number;
  FacilityId?: number;
  OrganizationId?: number;
}

export enum ChartQueryType {
  Unknown = 0,
  BatteryVoltage = 1,
  BatteryCapacity = 2,
  ExternalLoad = 3,
  ChargingModeAll = 4,
  ChargingModeAc = 5,
  ChargingModeBattery = 6,
  ChargingModeNotReporting = 7,
  AccessSuccessfulPINs = 8,
  AccessFailedPINs = 9,
  AccessLockouts = 10,
  LocationConnectivity = 11,
  TimeLoggedIn = 12,
  LoggedOutBatteryLevel = 13,
  LoggedOutLowBattery = 14,
  DCPowerUsage = 15,
}

export enum ChartEntityType {
  Unknown = 0,
  Cart = 1,
  User = 2,
  AccessPoint = 3,
}

export enum CartLabelDisplayMode {
  Name = 0,
  SerialNumber = 1,
}

export interface AnalyticsChartRequest {
  OrganizationId: number;
  FacilityId: number;
  DeviceType: DeviceTypeIds;
  ChartQuery: ChartQueryType;
  GraphyByEntity: ChartEntityType;
  Ids: string[];
  StartDateUtc: Date;
  EndDateUtc: Date;
  LocalMinutesOffset: number;
}

export interface AnalyticsChartValue {
  DateUtc: Date;
  Value: number;
}

export interface AnalyticsChartEntity {
  EntityKey: string;
  EntityName: string;
  EntitySerialNumber: string;
  SubCategory: string;
  SubCategoryName: string;
  Values: AnalyticsChartValue[];

  // These optional properties are not on the API response, but
  // used later to provide properties for Keno Charts
  TextColor?: string;

  // Should only be hexadecimal (there's code that will assume this, and
  // append two hexadecomal characters in order to control opacity)
  BgColor?: string;
  Border?: Border;
  BaseBgColor?: string;
  Hidden?: boolean;
  Opacity?: number;

  // Line charts only
  LineWidth?: number;
  ZIndex?: number;
  Markers?: SeriesMarkers;

  // true if the entity is currently in a highlight state
  // as a result of a click that makes the highlight "sticky"
  // until it's cleared with another click
  FixedHighlight?: boolean;
}

export enum ChartTimeResolutionIntervalType {
  Unknown = 0,
  QuarterHour = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
}

export interface AnalyticsChartResponse {
  DateInterval: ChartTimeResolutionIntervalType;
  Entities: AnalyticsChartEntity[];
  StartDateUtc: Date;
  EndDateUtc: Date;
  YAxisLabel: string;
}

/**
 * Used on API endpoints that omit the ApiResponse wrapper if response is a success, and only return the payload
 * This method will detect if an ApiResponse was returned (again this will only happen on this type of endpoint
 * when an error is returned) and will throw an observable error, otherwise will gracefully pass through the payload
 * @returns
 */
export function throwIfApiError<TData>(): (
  apiResultObs: Observable<TData | ApiErrorResponse>
) => Observable<TData> {
  return (apiResultObs: Observable<TData | ApiErrorResponse>) => {
    return apiResultObs.pipe(
      map((result) => {
        if (Object.keys(result).indexOf('ErrorId') > -1) {
          // api error response.
          throw result;
        }

        // must be TData since it is not an error.
        return result as TData;
      })
    );
  };
}

/**
 * Throws an error if the ApiResponse was deemed to be an "error"
 * (i.e. if the Success property was false or the Result property was === null)
 * @returns The value of the "Result" property on the ApiResonse
 */
export function throwIfBadRespReturnPayloadOnly<TData>(): (
  apiResultObs: Observable<ApiResponse<TData>>
) => Observable<TData> {
  return (apiResultObs: Observable<ApiResponse<TData>>) => {
    return apiResultObs.pipe(
      throwIfBadResp(true),
      map((resp) => resp as TData)
    );
  };
}

/**
 * Throws an error if the ApiResponse was deemed to be an "error"
 * (i.e. if the Success property was false or the Result property was === null)
 * @returns Exactly the same ApiResponse as was taken in
 */
export function throwIfBadRespFullResp<TData>(): (
  apiResultObs: Observable<ApiResponse<TData>>
) => Observable<ApiResponse<TData>> {
  return (apiResultObs: Observable<ApiResponse<TData>>) => {
    return apiResultObs.pipe(
      throwIfBadResp(false),
      map((resp) => resp as ApiResponse<TData>)
    );
  };
}

/**
 * If the ApiResponse Success property is false or the "Result" value is null, then
 * throws an exception. This works great with observables, since it will be "caught"
 * in the Observable "error" handler, and can be further handled.
 * This way you no longer need to check for success false in the subscription itself
 * @returns Only the "Result" property of the API response
 */
function throwIfBadResp<TData>(
  payloadOnly: boolean
): (
  apiResultObs: Observable<ApiResponse<TData>>
) => Observable<TData | ApiResponse<TData>> {
  return (apiResultObs: Observable<ApiResponse<TData>>) => {
    return apiResultObs.pipe(
      map((response: ApiResponse<TData>) => {
        if (!response.Success || response.Result === null) {
          const errorMsg = response.Message
            ? `Response message: ${response.Message}`
            : 'Response success is false or Result is null';
          throw new Error(errorMsg);
        }

        return payloadOnly ? response.Result : response;
      })
    );
  };
}

export interface BlobFileRequest {
  ContainerName: string;
  FileName: string;
}

export const ImmutableUserResponse = Record<UserResponse>({
  Email: '',
  EnterpriseId: 0,
  FacilityList: [],
  FirstName: '',
  Id: '',
  IsActive: true,
  IsEnterpriseAdmin: false,
  IsPasswordResetRequested: true,
  IsEmailConfirmed: false,
  IsLockedOut: false,
  HasValidSecurityQuestions: true,
  JoinDateUtc: moment.utc().toISOString(),
  LanguageCodeType: 1,
  LanguageId: 1,
  LastName: '',
  OrganizationList: [],
  PhoneNumber: '',
  Pin: '',
  ProxId: undefined,
  UserCartGroupList: [],
  UserCartList: [],
  UserDisplayName: '',
  UserNotificationGroupList: [],
  UserRoleList: [],
  UserTimeZone: '',
  UserType: UserType.Pharmacy,
  Username: '',
  Version: 1,
  Comment: '',
});

export const ImmutableUserUpdateRequest = Record<UserUpdateRequest>({
  AssignedRequestTypes: [],
  Email: '',
  IsEmailConfirmed: false,
  EnterpriseId: 0,
  FacilityIds: [],
  FirstName: '',
  Id: '',
  IsActive: true,
  IsEnterpriseAdmin: false,
  IsPasswordResetRequested: true,
  JoinDateUtc: moment.utc().toISOString(),
  LanguageCodeType: 1,
  LanguageId: 1,
  LastName: '',
  OrganizationIds: [],
  PhoneNumber: '',
  Pin: '',
  ProxId: undefined,
  NotificationGroupIds: [],
  RoleIds: [],
  UpsertSettings: [],
  UserCartGroupIds: [],
  UserCartIds: [],
  UserTimeZone: '',
  UserType: UserType.Pharmacy,
  Username: '',
  Version: 1,
  Comment: '',
});

export const ImmutableNetworkAccessPointResponse =
  Record<NetworkAccessPointResponse>({
    MacAddress: '',
    Name: '',
    NetworkAccessPointId: -1,
    FacilityId: undefined,
    OrganizationId: undefined,
  });

export interface EnterpriseSearchRequest {
  EnterpriseIds: number[];
}

export const ImmutabeMessageQueueRequestTypeResponse =
  Record<MessageQueueRequestTypeResponse>({
    AcknowledgementThreshold: 15,
    DateModifiedUtc: moment.utc().toDate(),
    Description: '',
    EnterpriseId: -1,
    FacilityId: -1,
    MessageQueueRequestTypeId: -1,
    OrganizationId: -1,
  });

export interface UserImportConflictResponse {
  ConflictingUsernames: string[];
  ConflictingPINs: string[];
  ConflictingSecondaryPINs: string[];
  HasConflicts: boolean;
}

export function getProductLine(
  deviceTypeId: number | DeviceTypeIds
): ProductLineIds {
  switch (deviceTypeId) {
    case DeviceTypeIds.Avalo:
      return ProductLineIds.Avalo;
    case DeviceTypeIds.CareLink_2:
    case DeviceTypeIds.CareLink_M38e:
    case DeviceTypeIds.CareLink_M48:
      return ProductLineIds.CareLink;
    default:
      return ProductLineIds.Unknown;
  }
}

export interface EnterprisePermissionTree {
  EnterpriseId: number;
  EnterprisePermissions: Set<string>;
  Organizations: Map<number, OrganizationPermissionTree>;
}

export interface OrganizationPermissionTree {
  OrganizationId: number;
  OrganizationPermissions: Set<string>;
  Facilities: Map<number, FacilityPermissionTree>;
}

export interface FacilityPermissionTree {
  FacilityId: number;
  FacilityPermissions: Set<string>;

  // Exists in API object but not yet implemented
  //Devices: Map<number, DevicePermissionTree>;
}

/**
 * See NS-2307 and subtask NS-2356
 * Used to
 */
export interface FacilityBatterySettingsStateResponse {
  hasAnyNonDefault: boolean;
}

export interface FirmwareResponse {
  FirmwareId: number;
  PartNumber: string;
  Description: string;
  DeviceTypeId: DeviceTypeIds;
  MonthYearReleaseDate: Date;
  UpdateType: DeviceUpdateType;
}

export interface FirmwareVersionDetailsResponse {
  ActiveDate: Date;
  FirmwareVersionId: number;
  Firmware: FirmwareResponse;
  VersionNumber: string;
  Commands: string;
  Description: string;
  FileName: string;
  FileSizeBytes: number;
  UpdateType: DeviceUpdateType;
}

export interface FirmwareVersionDetailsResponseGridItem
  extends FirmwareVersionDetailsResponse {
  PartNumber: string;
}

export interface DeviceTypeFirmwareVersionResponse {
  DeviceTypeId: DeviceTypeIds;
  FirmwareVersions: FirmwareVersionDetailsResponse[];
}

export interface CartFirmwareResponse {
  CartId: number;
  Firmwares: FirmwareVersionStatusResponse[];
}

export interface FirmwareUpdateCartsResponse {
  CartFirmwares: CartFirmwareResponse[];
}

export interface FirmwareVersionStatusResponse {
  FirmwareVersionId: number;
  UpdatePending: boolean;
  UpdateType: DeviceUpdateType;
  VersionNumbner: string;
}
