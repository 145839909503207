import { Injectable } from '@angular/core';
import { NameWithLongIdApiModel } from '@capsa/api';
import { AsyncDropDownValue } from '@capsa/dropdowns/abstract-async-drop-down/abstract-async-drop-down.directive';
import { AbstractAsyncMultiSelectDataSource } from '@capsa/dropdowns/async-multi-select-list/abstract-async-multi-select-data-source';
import { AuthService } from '@capsa/services/auth/auth.service';
import { EofCacheService } from '@capsa/services/eof-cache';
import { UserEofCacheService } from '@capsa/services/user-eof-cache';
import { Observable, of } from 'rxjs';

@Injectable()
export class OrganizationMultiSelectDataSource extends AbstractAsyncMultiSelectDataSource<NameWithLongIdApiModel> {
  public organizationIds: number[] = [];

  public get canLoad(): boolean {
    return true;
  }
  public get defaultItem(): AsyncDropDownValue<NameWithLongIdApiModel>[] {
    return [];
  }
  protected callApi(): Observable<NameWithLongIdApiModel[]> {
    const data = this.authService.currentUser.EnterpriseAdmin
      ? this.eofCacheService.organizations
      : this.userEofCacheService.organizations;

    return of(data);
  }

  protected map(
    apiModel: NameWithLongIdApiModel
  ): AsyncDropDownValue<NameWithLongIdApiModel> {
    return {
      apiModel,
      id: apiModel.Id.toString(),
      name: apiModel.Name,
    };
  }

  protected select(
    items: AsyncDropDownValue<NameWithLongIdApiModel>[]
  ): AsyncDropDownValue<NameWithLongIdApiModel>[] {
    return items.filter((o) =>
      this.organizationIds.find((id) => id.toString() === o.id)
    );
  }

  constructor(
    private authService: AuthService,
    private eofCacheService: EofCacheService,
    private userEofCacheService: UserEofCacheService
  ) {
    super();
  }
}
