import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  DateInputsModule,
  DateRangeModule,
} from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateRangeFilterCellComponent } from 'app/modules/kendo-custom/date-range-filter-cell/date-range-filter-cell.component';
import { NumberRangeComponent } from 'app/modules/kendo-custom/number-range/number-range.component';

@NgModule({
  declarations: [DateRangeFilterCellComponent, NumberRangeComponent],
  imports: [
    CommonModule,
    DateRangeModule,
    ButtonsModule,
    DateInputsModule,
    InputsModule,
    TranslateModule.forChild(),
  ],
  exports: [DateRangeFilterCellComponent, NumberRangeComponent],
})
export class CustomInputsModule {}
