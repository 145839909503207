import { Component, Inject } from '@angular/core';
import { ApiSettingsFormHelper } from 'app/modules/cart-profile/api-settings-form-helper';
import { SettingsHelperToken } from 'app/modules/cart-profile/helpers';
@Component({
  selector: 'app-cart-profile-carelink-v1-display-tab',
  templateUrl: './carelink-v1-display-tab.component.html',
  styleUrls: ['./carelink-v1-display-tab.component.scss'],
})
export class CarelinkV1DisplayTabComponent {
  constructor(
    @Inject(SettingsHelperToken)
    public settingsFormHelper: ApiSettingsFormHelper
  ) {}
}
